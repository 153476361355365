import { useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";

export default function AddCrate(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        serialNumber: "",
        agentId: "",
        purchasedDate: "",
        life: 0,
        purchasedPrice:0

    });

    const updateAgentId = (code) => {

        updateState({ ...thisComponentState, agentId: code });

    }

    const add = () => {

        var isConfirm = window.confirm("Are you sure you want to add this crate?");

        if (!isConfirm) {

            return;
        };

        let postBody = {

            serialNumber: thisComponentState.serialNumber,
            agentId: thisComponentState.agentId,
            purchasedDate:  thisComponentState.purchasedDate,
            life: parseFloat(thisComponentState.life),
            purchasedPrice: parseFloat(thisComponentState.purchasedPrice)
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Crates/AddCrate", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({...thisComponentState,isPerformingOperation:false})

                return alert(response.message);

            });
    }




    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>
            </div>

 
            <div className="row">

                <div className="col">
                    Serial Number
                </div>

                <div className="col">
                    <input value={thisComponentState.serialNumber} className="form-control" type="input" onChange={(e) => updateState({ ...thisComponentState, serialNumber: e.currentTarget.value })} />
                </div>

            </div>


            <div className="row">

                <div className="col">
                    Agent
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.agentId} clear={() => updateState({...thisComponentState,agentId:""}) } url="/Agents/GetAgents" updateValue={updateAgentId} />
                </div>

            </div>

            <div className="row">
                <div className="col">
                    Purchased Date
                </div>
                <div className="col">
                    <input type="date" value={thisComponentState.purchasedDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState,purchasedDate: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Life
                </div>
                <div className="col">
                    <input type="number" value={thisComponentState.life} className="form-control" type="input" onChange={(e) => updateState({ ...thisComponentState, life: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Purchase Price
                </div>
                <div className="col">
                    <input type="number" value={thisComponentState.purchasedPrice} className="form-control" type="input" onChange={(e) => updateState({ ...thisComponentState,purchasedPrice: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
              
                </div>
                <div className="col">
                    <input type="button" value="Save" className="form-control" onClick={add} />
                </div>
            </div>


        </div>


    )
}