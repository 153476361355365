import { useEffect } from "react";
import { useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";

export default function EditCrate(props)
{

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        crateId: props.crateId,
        serialNumber:props.serialNumber,
        newAgentId:"",
        newSerialNumber:""
    });

    const updateAgentId = (code) => {
        updateState({ ...thisComponentState, newAgentId: code });
    }


    const update = () => {

        var isConfirm = window.confirm("Are you sure you want to edit this crate?");

        if (!isConfirm) {

            return;
        };

        let params = {};

        params.item1 = parseInt(thisComponentState.crateId);

        if (thisComponentState.agentId !== "") {

            params.item2 = thisComponentState.newAgentId;
        }

        if (thisComponentState.serialNumber !== "") {

            params.item3 = thisComponentState.newSerialNumber;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true })

        let postBody =
        {
            value: params
        }

        fetch("/Crates/EditCrate", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {
                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false });
                }

                return alert(response.message);

            });
    }

   


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>
            </div>

            <hr/>


            <div className="row">
                <div className="col">
                    Serial Number
                </div>

                <div className="col">
                    <input value={thisComponentState.serialNumber} className="form-control" type="input" disabled />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    New Agent
                </div>
                <div className="col">
                    <StandardAutoComplete value={thisComponentState.agentId } url="/Agents/GetAgents" updateValue={updateAgentId} />
                </div>
            </div>


            <div className="row">
                <div className="col">
                    New Serial Number
                </div>
                <div className="col">
                    <input value={thisComponentState.newSerialNumber} className="form-control" type="input" onChange={(e) => updateState({ ...thisComponentState, newSerialNumber: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col"> 
                </div>
                <div className="col">
                    <input type="button" value="Update" className="form-control" onClick={update} />
                </div>
            </div>

        </div>
    )
}