import React, { useState } from 'react';



export default function PureInput(props) {

    const [value, updateValue] = useState(props.value);

    return <input
        type={props.type}
        value={value}
        name={props.name}
        className="form-control"
        style={props.style}
        onChange={(e) => updateValue(e.currentTarget.value)}
        onBlur={(e) => props.updateValue(props.code,e.currentTarget.name, value)}
    />

}


