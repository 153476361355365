import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import uuid from 'react-uuid';
import { TransactionContext } from '../TransactionContext';


export default function OpDrafts(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState([]);

    const [cleared] = useState(0);

    useEffect(() => {

        var grPoDrafts = localStorage.getItem("grPoDrafts");

        if (grPoDrafts != null) {

            updateState(JSON.parse(grPoDrafts));
        }
    }, [cleared]);


    const select = (val) => {

        var tempData = { ...data.metaData };

        tempData.parentProperties = val.parentProperties
        tempData.detailProperties = val.detailProperties;
        tempData.preceedingTransactionGuids = val.preceedingTransactionGuids;

        updateMetaData({ metaData: tempData });

        props.back();
    }

    const deleteItem =(guidId) => {

        let savedDrafts = localStorage.getItem("grPoDrafts");

        if (savedDrafts !== null) {

            let drafts = JSON.parse(savedDrafts);

            let draftsToRemain = drafts.filter(val => val.guidId !== guidId);

            localStorage.setItem("grPoDrafts",JSON.stringify(draftsToRemain));

            updateState(draftsToRemain);
        }
    }

    return (

        <div>
            <div>
              
                <div className="row">
                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={() => props.back()} />
                    </div>
               </div>

                <hr />

                <div className='row'>                                 
                    <div className='col'>
                        <table className='table table-bordered'>

                            <thead>

                                <tr>
                                    <th colSpan="2">Action</th>
                                    <th>Date</th>
                                    <th>Vendor</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    thisComponentState != null && thisComponentState.map((val) =>

                                        <tr key={uuid()}>

                                            <td>
                                                <input type='button' value='Delete' className='form-control' onClick={()=>deleteItem(val.guidId)}/>
                                            </td>

                                            <td>
                                                <input type='button' value='Select' className='form-control' onClick={() => select(val)} />
                                            </td>

                                            <td>{val.parentProperties.date}</td>
                                            <td>{val.vendor} </td>

                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>

                  
                </div>

               
            </div>
        </div>  
        
        
  )

}