import React, { useContext } from 'react';
import { useState } from 'react';
import { TransactionContext } from '../Transactions/TransactionContext';
import uuid from 'react-uuid';
import StandardAutoComplete from '../StandardAutocomplete';
import getPropertyValueFromAutoCompleteSuggestions from '../Functions/getPropertyValue';



export default function OnHandInventory(props) {

 
    const [thisComponentState, updateState] = useState({

        data: {},
        asOfDate:"",
        itemId: "",
        categoryId: "",
        warehouseId :""

    });

    const getOnHandQty = () => {

        updateState({ ...thisComponentState, retrieving: true });

        let params = {};

        if (thisComponentState.asOfDate === "") {

            return alert("As of date is required.");
        }

        params.asOfDate = thisComponentState.asOfDate;

        if (thisComponentState.itemId !== "") {

            params.itemId = thisComponentState.itemId;
        };

        if (thisComponentState.categoryId !== "") {

            params.categoryId = thisComponentState.categoryId;
        };


        if (thisComponentState.warehouseId !== "") {

            params.warehouseId = thisComponentState.warehouseId;
        };
 
        fetch("Reports/GetOnHandQty?" + new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {
                updateState({ ...thisComponentState, data: response, retrieving: false })
            });
    };

    const getWareHouseQty = (warehouseQtys, wareHouse) => {

        var wareHouseQty = warehouseQtys.find(x => x.wareHouse === wareHouse);

        if (typeof wareHouseQty !== 'undefined') {

            return wareHouseQty.quantity.toLocaleString() + " " + wareHouseQty.uom;

        } else {

            return 0;

        }
    }

    const updateInventoryId = (code) => {

        updateState({...thisComponentState, itemId: code });

    };

    const updateCategoryId = (code) => {

        updateState({ ...thisComponentState, categoryId: code });

    };


    const updateWarehouseId = (code) => {

        updateState({ ...thisComponentState, warehouseId: code });

    };


    if (thisComponentState.retrieving) {

      return (<div>Loading</div>)

    }


    return (

        <div>

            {
                typeof props.back !=='undefined' && 
                <div className="row">

                    <div className="col">
                    
                    </div>

                    <div className="col">

                        <input className="form-control" value="Back" type="button" onClick={()=>props.back()} />

                    </div>

                    <div className="col">

                    </div>
                </div>
              

            }

            <hr/>

            <div className="row">

                <div className="col">
                   As of Date
                </div>

                <div className="col">
                
                    <input className="form-control" type="date" value={thisComponentState.asOfDate} onChange={(e) => updateState({...thisComponentState,asOfDate:e.currentTarget.value })}/>

                </div>

            </div>

            <div className="row">

                <div className="col">
                    Item 
                </div>

                <div className="col">
                    <StandardAutoComplete key={200} url="/Inventories/GetInventories" value={thisComponentState.itemId} clear={ ()=>updateState({...thisComponentState,itemId:""}) } updateValue={updateInventoryId} placeholder="Retrieve by Item" />
                </div>
     
            </div>


            <div className="row">
                <div className="col">
                    Category
                </div>

                <div className="col">
                    <StandardAutoComplete key={200} url="/Categories/GetCategories" value={thisComponentState.categoryId} clear={()=> updateState({ ...thisComponentState, categoryId: "" })}  updateValue={updateCategoryId} placeholder="Retrieve by Category" />
                </div>
            </div>

            <div className="row">

                <div className="col">
                   Warehouse
                </div>

                <div className="col">
                    <StandardAutoComplete key={200} url="/Warehouses/GetWarehouses" value={thisComponentState.warehouseId} clear={()=> updateState({ ...thisComponentState, warehouseId: "" })}  updateValue={updateWarehouseId} placeholder="Retrieve by Warehouse" />
                </div>

            </div>


            <div className="row">

                <div className="col">
                   
                </div>

                <div className="col">
                    <input value="Retrieve" onClick={getOnHandQty} type="button" className="form-control"/>
                </div>

            </div>



            <br />

            <table className="table table-bordered">

                <thead className="thead-dark">

                    <th style={{ position: 'sticky', top: 0 }}>
                        ItemCode
                    </th>

                    <th style={{ position: 'sticky', top: 0 }}>
                        ItemName
                    </th>

                    {
                         typeof thisComponentState.data.warehouses !=='undefined' &&  thisComponentState.data.warehouses.map(warehouse =>

                            <th key={uuid} style={{ position: 'sticky', top: 0 }}>
                                {
                                    warehouse
                                }
                            </th>

                        ) 
                    }

                </thead>

                <tbody>

                    {
                        typeof thisComponentState.data.onHandInventories !=='undefined' &&  thisComponentState.data.onHandInventories.map(detail =>

                            <tr key={uuid}>

                                <td> {detail.itemCode} </td>
                                <td> {detail.itemName} </td>

                                {
                                    thisComponentState.data.warehouses.map(warehouse =>

                                        <td key={uuid}>{getWareHouseQty(detail.details, warehouse)}</td>

                                    )
                                }
                         </tr>

                        ) 
                    }
                </tbody>

            </table>

        </div>
    
        )
}