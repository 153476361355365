
export default function computeProductionDate(parentProperties,settings) {

    let setting = settings.find(x => x.code === "ForeCastPeriod");

    let forecastPeriod = setting === null || setting === undefined ? 0 : setting.value;
  
    let orderDate =new Date(parentProperties["date"]);
  
    let productionDate = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate()+forecastPeriod);

    return productionDate.toLocaleDateString('en-CA');


}