import { faDiagramNext, faDiagramProject } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { TransactionContext } from "../TransactionContext";
import Canvas from "./Canvas";


export default function EnterCanvases(props) {

    const { data } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        data: data.metaData.detailsFromDb.details,
        selectedGuidId: "",
        enterCanvas : false

    });

    const enterCanvas = (guidId) => {

        updateState({ ...thisComponentState, enterCanvas: true,selectedGuidId:guidId});
    }


    if (thisComponentState.enterCanvas) {

        return (

            <Canvas guidId={thisComponentState.selectedGuidId} back={() => updateState({...thisComponentState, enterCanvas:false })}/>
        )
    }


    return (

        <div>
            <div className="row">
                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                </div>
            </div>

            <hr/>
            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>UomDesc</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.map(val =>
                                    <tr>

                                        <td>
                                            <FontAwesomeIcon icon={faDiagramProject} title="Enter canvas for this item" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => enterCanvas(val.guidId)} />
                                        </td>
                                        <td>{val.description}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>
                                        <td>{val.quantity * val.price}</td>

                                    </tr>
                                )
                            }

                        </tbody>

                    </table>
                </div>
            </div>


        </div>
)
}