
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import StandardAutoComplete from '../../StandardAutocomplete';
import MissingCrates from './MissingCrates';
import WashedCrates from './WashedCrates';


export default function WashedList(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        values: [],
        showMissingCrates: false,
        showWashedCrates: false,
        toReload:0,
        selectWashedHistoryId: 0,
        agentId:"-1"
    });

    useEffect(() => {

        fetch("/Crates/GetWashedList?" + new URLSearchParams({

            agentId: thisComponentState.agentId

        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    values: response,
                    isLoading: false

                });

            });
    }, [thisComponentState.toReload]);

    const updateValue = (agentId) => {

        updateState({ ...thisComponentState, agentId: agentId, toReload: thisComponentState.toReload + 1 });

    }


    const deleteItem =(id) => {

        let confirm = window.confirm("Are you sure you want to delete this? This action cannot be undone.\nPlease click ok to continue.");

        if (!confirm) {

            return;

        }

        fetch("/Crates/DeleteWashed", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                value:id
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, toReload: thisComponentState.toReload + 1 });

                return alert(response.message);

            });
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.showMissingCrates) {

        return (<MissingCrates washedHistoryId={thisComponentState.selectWashedHistoryId } back={() => updateState({...thisComponentState,showMissingCrates:false})}/>)
    }

    if (thisComponentState.showWashedCrates) {

        return (<WashedCrates washedHistoryId={thisComponentState.selectWashedHistoryId } back={() => updateState({ ...thisComponentState, showWashedCrates: false })} />)

    }

    return (

        <div>
            <div>

                <div className="row">                  
                    <div className="col">
                        <input value="Back" className="form-control" type="button" onClick={() => props.back()} />
                    </div>                  
                </div>

                <hr/>

                <div className="row">
                    <div className="col">
                        <div> Agent </div>
                    </div>
                    <div className="col">
                        <StandardAutoComplete value={thisComponentState.agentId} url="/Agents/GetAgents" updateValue={updateValue} />
                     

                    </div>
                </div>

                <hr/>

                <div className='row'>

                    <div className='col'>
                        <table className='table table-bordered'>

                            <thead>

                                <tr>
                                    <th colSpan="3">Action</th>
                                    <th>Agent</th>
                                    <th>Washed Date</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    thisComponentState.values.map((val) =>

                                        <tr key={uuid()}>
                                          
                                            <td>
                                                <input type='button' value='Delete' className='form-control' onClick={() => deleteItem(val.id)} />
                                            </td>

                                            <td>
                                                <input type='button' value='Missing' className='form-control' onClick={() => updateState({...thisComponentState,showMissingCrates:true,selectWashedHistoryId:val.id}) }  />
                                            </td>

                                            <td>
                                                <input type='button' value='Washed' className='form-control' onClick={() => updateState({...thisComponentState,showWashedCrates:true,selectWashedHistoryId:val.id }) } />
                                            </td>


                                            <td>{val.name}</td>
                                            <td>{val.washedDate} </td>

                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>

                  
                </div>

               
            </div>
        </div>  
        
        
  )

}