
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import uuid from 'react-uuid';
import NoticeOfPersonnelAction from './NoticeOfPersonnelAction';


export default function EvaluationResult(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: false,
        isGeneratingPDF: false,
        verdict: {},
        data: {}
      
    });


    useEffect(() => {

        updateState({ ...thisComponentState, isLoading: true })

        fetch("PerformanceEvaluations/GetEvaluationResult?" + new URLSearchParams({ id: props.evaluationCycleId }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, data: response.info, verdict: response.verdict, isLoading: false, isInitialized: true });

            });

    }, []);


    const print = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/PerformanceEvaluations/Print?id=";

        fetch(url + thisComponentState.data.evaluationCycleId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isGeneratingPDF: false });

            });
    }

    const finalized = () => {

        var isConfirm = window.confirm("Are you sure you want to finalized this evaluation? This action is irreversible.");

        if (!isConfirm) {

            return;
        };

        //Project finalized evaluation

        let finalizedEvaluation = {
            evaluationCycleId: thisComponentState.data.evaluationCycleId,
            employeeId:thisComponentState.data.employeeId
        }

        let finalizedEvaluationDetails = [];

        for (let i = 0; i < thisComponentState.data.details.length; i++)
        {
            let finalizedMainFactorEvaluationDetail = {};

            finalizedMainFactorEvaluationDetail.factorId = thisComponentState.data.details[i].mainFactorId;
            finalizedMainFactorEvaluationDetail.score = thisComponentState.data.details[i].mainFactorScore;
            finalizedMainFactorEvaluationDetail.isMainFactor = true;

            finalizedEvaluationDetails.push(finalizedMainFactorEvaluationDetail);

            for (let j = 0; j < thisComponentState.data.details[i].details.length; j++) {


                let finalizedSubFactorEvaluationDetail = {};

                finalizedSubFactorEvaluationDetail.factorId = thisComponentState.data.details[i].details[j].subFactorId;
                finalizedSubFactorEvaluationDetail.score = thisComponentState.data.details[i].details[j].rating;
                finalizedSubFactorEvaluationDetail.isMainFactor = false;

                finalizedEvaluationDetails.push(finalizedSubFactorEvaluationDetail);
            }
        }

        finalizedEvaluation.finalizedEvaluationDetails = finalizedEvaluationDetails;

        fetch("/PerformanceEvaluations/Finalized", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }



    const getRating = (mainFactorId, evaluatorId, subFactorId) => {

        let mainFactor = thisComponentState.data.details.find(val => val.mainFactor === mainFactorId);

        let evaluator = mainFactor.details.find(val => val.evaluator === evaluatorId);

        let subFactor = evaluator.details.find(val => val.name === subFactorId);

        if (typeof subFactor !== 'undefined') {
            
            return subFactor.rating;
        }

        return 0;
    }



    const getRatingPerSubFactor = (mainPeformanceFactorPerId, subFactor) => {

        let mainFactor = thisComponentState.data.details.find(val => val.mainFactor === mainPeformanceFactorPerId);

        let value = mainFactor.details.find(x => x.details).details.find(x => x.name === subFactor);

        return (value.perSubFactorRating * 100).toFixed(0) + "%";

    }



 

    const computeSum = (items, name) => {

        let grandTotal = 0;

        for (let i = 0; i < items.length; i++) {

            grandTotal += items[i][name];
        }

        return grandTotal;
    }

    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    if (thisComponentState.isGeneratingPDF) {

        return (<div>Loading...</div>)
    }

    if (thisComponentState.showNopa)
    {
        return (<NoticeOfPersonnelAction employeeId={thisComponentState.data.employeeId} performanceRating={thisComponentState.data.performanceRating} evaluationCycleId={thisComponentState.data.evaluationCycleId} back={() => updateState({...thisComponentState,showNopa:false})}/>)
    }


    return (

                <div>

                    <div className="row">
                        <div className="col">
                            <input type="button" value="Back" className="form-control" onClick={()=>props.back()}/>
                        </div>

                        <div className="col">
                            <input type="button" value="Finalize" className="form-control" onClick={finalized} />
                        </div>

                        <div className="col">
                    <input type="button" value="Nopa" className="form-control" onClick={() => updateState({...thisComponentState,showNopa:true})} />
                        </div>

                        <div className="col">
                            <input type="button" value="Print" className="form-control" onClick={print} />
                        </div>
                      
                     </div>

                     <hr/>

                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Employee Id
                                </div>

                                <div className="col">
                                    {thisComponentState.data.employeeId}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Employee Name
                                </div>

                                <div className="col">
                                    {thisComponentState.data.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Position
                                </div>

                                <div className="col">
                                    {thisComponentState.data.position}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Date Hired
                                </div>

                                <div className="col">
                                    {thisComponentState.data.dateHired}
                                </div>
                            </div>

                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Department
                                </div>

                                <div className="col">
                                    {thisComponentState.data.department}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Appraisal Date
                                </div>

                                <div className="col">
                                    {thisComponentState.appraisalDate}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    Purpose
                                </div>

                                <div className="col">
                                    {thisComponentState.purpose}
                                </div>
                            </div>
                        </div>
                    </div>


                    <hr />
                    <div className="row">

                        <div className="col">
                            <table className="table table-bordered">
                                <thead>

                                </thead>


                                {
                                    typeof thisComponentState.data.details !== 'undefined' && thisComponentState.data.details.map(mainFactor =>
                                        <tbody>
                                            <tr>
                                                <td colSpan={mainFactor.details.length + 2} className="text-center">{mainFactor.mainFactor}


                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Item</td>
                                                {mainFactor.details.map(val => <td> {val.evaluator}</td>)}
                                                <td>
                                                    Score
                                                </td>
                                                <td>Weighted Rate</td>
                                            </tr>

                                            {
                                                mainFactor.subFactors.map((subFactor, index) =>
                                                    <tr>
                                                        <td>{subFactor}</td>
                                                        {
                                                            mainFactor.details.map(detail => <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{getRating(mainFactor.mainFactor, detail.evaluator, subFactor)}</td>)
                                                        }
                                                        <td className="text-center">{getRatingPerSubFactor(mainFactor.mainFactor, subFactor)}</td>

                                                        {
                                                            index === 0 && <td style={{ textAlign: 'center', verticalAlign: 'middle' }} rowSpan={mainFactor.subFactors.length + 3}> <h2>{((mainFactor.mainFactorScore * mainFactor.mainFactorWeight) * 100).toFixed(0) + "%"}</h2>  </td>
                                                        }


                                                    </tr>)
                                            }

                                            <tr>
                                                <td>Score</td>
                                                {
                                                    mainFactor.details.map(val => <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{val.score}</td>)
                                                }
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    {
                                                        computeSum(mainFactor.details, "score")
                                                    }
                                                </td>

                                            </tr>

                                            <tr>
                                                <td>Highest Posssible Score</td>
                                                {
                                                    mainFactor.details.map(val => <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{val.highestPossibleScore}</td>)
                                                }
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    {computeSum(mainFactor.details, "highestPossibleScore")}
                                                </td>

                                            </tr>
                                            <tr>

                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>Percentage Score</td>
                                                {
                                                    mainFactor.details.map(val => <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{(val.grade * 100).toFixed(0) + "%"}</td>)
                                                }
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    <h3>
                                                        {(mainFactor.mainFactorScore * 100).toFixed(0) + "%"}
                                                    </h3>
                                                </td>


                                            </tr>

                                        </tbody>
                                    )}

                                <tfoot>
                                    <tr>
                                        <td>Performance Rating</td>

                                        <td colSpan={typeof thisComponentState.data.details !== 'undefined' && thisComponentState.data.details.map(x => x.details)[0].length + 1}>
                                            <h1 style={{ textAlign: 'center', verticalAlign: 'middle' }}>  {thisComponentState.verdict.quality} </h1>
                                        </td>

                                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            <h1>{(thisComponentState.data.performanceRating * 100).toFixed(0) + "%"}</h1>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>

                </div>


    )
}