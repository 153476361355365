
import React from 'react';
import { useState } from 'react';

export default function Register() {

    const [thisComponentState, updateState] = useState({

        userName:"",
        email: "",
        password: "",
        confirmedPassword: "",
        phoneNumber: "",
        toVerifyPhoneNumber: false,
        verifyingPhoneNumber: false,
        token: "",
        registering:false
        
    });

    const Register = () => {

        if (thisComponentState.password !== thisComponentState.confirmedPassword) {

            return alert("Password does not match.");

        };

        if (thisComponentState.phoneNumber ==="") {

            return alert("Phone number is required.");

        };

        updateState({...thisComponentState,registering:true})


        fetch('/Identity/Register', {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response =>  response.json())
            .then(response => {

                if (response.success) {

                    updateState({
                        ...thisComponentState,
                        registering: false,
                        toVerifyPhoneNumber: true
                    });

                } else {

                    updateState({
                        ...thisComponentState,
                        registering: false
                    });

                    return alert(response.message);
                }

            })
     
    }


    const onKeyUp = (pToken) => {

    
        if (pToken.length === 6) {

                updateState({...thisComponentState,token:pToken,verifyingPhoneNumber:true})

                fetch('/Identity/VerifyPhoneNumber', {

                    // Adding method type
                    method: "POST",

                    // Adding body or contents to send
                    body: JSON.stringify({

                        email: thisComponentState.email,
                        password: thisComponentState.password,
                        token : pToken

                    }),

                    // Adding headers to the request
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                }).then(response => response.json())
                    .then(content => {

                        if (content.success) {

                            alert(content.message);

                            window.location = "/login";

                        } else {

                            updateState({ ...thisComponentState, verifyingPhoneNumber: false })

                            return alert(content.message);
                        }
                    
                    })

               
        }
    }



    if (thisComponentState.verifyingPhoneNumber) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Verifying phone number...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.registering) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Registering...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.toVerifyPhoneNumber) {

        return (
            <section className="vh-100" style={{ backgroundColor: "#eee" }}>
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-lg-12 col-xl-11">
                            <div className="card text-black" style={{ borderRadius: "25px" }}>
                                <div className="card-body p-md-5">
                                    <div className="row">
                                       Enter OTP.                                   
                                    </div>

                                    <div className="row">

                                        <input type="text" className="form-control" onKeyUp={(e)=>onKeyUp(e.currentTarget.value)} />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )

    }

  
    return (
        <section className="vh-100" style={{ backgroundColor: "#eee" }}>
            <div className="container h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-11">
                        <div className="card text-black" style={{ borderRadius: "25px" }}>
                            <div className="card-body p-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                        <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Register</p>

                                        <form className="mx-1 mx-md-4">


                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="email" id="form3Example3c" className="form-control" onChange={(e) => updateState({ ...thisComponentState, userName: e.currentTarget.value })} />
                                                    <label className="form-label" >Your Username</label>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="email" id="form3Example3c" className="form-control" onChange={(e) => updateState({ ...thisComponentState, email: e.currentTarget.value })} />
                                                    <label className="form-label" >Your Email</label>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="password" id="form3Example4c" className="form-control" onChange={(e) => updateState({ ...thisComponentState, password: e.currentTarget.value })} />
                                                    <label className="form-label" >Password</label>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="password" id="form3Example4cd" className="form-control" onChange={(e) => updateState({ ...thisComponentState, confirmedPassword: e.currentTarget.value })} />
                                                    <label className="form-label" >Confirm your password</label>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="text" id="form3Example4cd" className="form-control" placeholder="Your eleven digit phone number" onChange={(e) => updateState({ ...thisComponentState, phoneNumber: e.currentTarget.value })} />
                                                    <label className="form-label" >Phone Number</label>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                <button type="button" className="btn btn-primary btn-lg" onClick={Register} >Register</button>
                                            </div>

                                        </form>

                                    </div>
                                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                        <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-registration/draw1.png" className="img-fluid" alt="Sample image" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}