
function isAutocomplete(property, metaData) {


    var result = false;

    for (var i = 0; i < metaData.length; i++) {

        if (metaData[i].name.toLowerCase() === property.toLowerCase()) {

            result = metaData[i].isAutocomplete;

            break;

        }
    }

    return result;  

}

export default isAutocomplete;