
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faSpinner} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';
import uuid from 'react-uuid';
import exportToCSV from '../../Functions/exportToCSV';
import { toCsvString } from '../../Functions/toCsvString';


export default  function StockRequisitionListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        documentId: "",
        code: "",
        fromDate: "",
        toDate: "",
        requestDate: "",
        requiredDate: "",
        transactionStatus: "",
        branchId: "",
        requestType: "",
        itemCode:""

    });


    async function search() {

        updateState({ ...thisComponentState,isLoading:true })

        let params = {};

        if (thisComponentState.fromDate!=="" && thisComponentState.toDate!="") {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (thisComponentState.documentId !== "" ) {
            params.documentId = thisComponentState.documentId;
        }

        if (thisComponentState.code !== "") {

            params.code = thisComponentState.code;
        }

        if (thisComponentState.transactionStatus !=="") {

            params.transactionStatus = thisComponentState.transactionStatus;

        }

        if ( thisComponentState.requestDate !=="") {

            params.requestDate = thisComponentState.requestDate;
        }

        if (thisComponentState.requiredDate !=="") {

            params.requiredDate = thisComponentState.requiredDate;
        }

        if (thisComponentState.branchId !=="") {

            params.branchId = thisComponentState.branchId;
        }

        if (typeof thisComponentState.requestType !== "") {

            params.requestType = thisComponentState.requestType;
        }

        if (typeof thisComponentState.itemCode !== "") {

            params.itemCode = thisComponentState.itemCode;
        }

        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params))
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {
                const dataFromServer = response;
                var tempData = { ...data.metaData };
                tempData.listData = dataFromServer;
                updateMetaData({ metaData: tempData });
                updateState(prevState => ({
                    ...prevState,
                    isLoading: false
                }));
            });
    };




    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/StockRequisitions/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>

        );

    };

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, code: code });

    };

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });

    };

    const updateRequestType = (code) => {

        updateState({ ...thisComponentState, requestType: code });

    };

    const updateItemCode = (code) => {
        updateState({ ...thisComponentState, itemCode: code });
    };

    const clear = () => {
        updateState({
            ...thisComponentState,
            isLoading: false,
            documentId: "",
            employeeId: "",
            fromDate:"",
            toDate:"",
            requestDate: "",
            transactionStatus: "",
            branchId: "",
            itemCode:""
        })
    }


    const exportItems = () => {

        if (data.metaData.listData.length === 0) {
            return alert("No items queried.");
        }

        exportToCSV("data",toCsvString(data.metaData.listData))

    }
  

    return (

         <div >

            <div className="row">

                <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
                <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search} type="button" />
                <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />
                <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Export" onClick={exportItems} type="button" />

            </div>

          
            <br />
            <br />

            <div className="row">
                <div className="col">
                    <table className='table table-bordered'>

                        <tbody>
                            <tr>
                                <td>
                                    <div>From</div>
                                </td>
                                <td>

                                    <input className="form-control" title="From Date" value={thisComponentState.fromDate} type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>To</div>
                                </td>
                                <td>
                                    <input className="form-control" title="To Date" value={thisComponentState.toDate} type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Request Date</div>
                                </td>
                                <td>

                                    <input className="form-control" title="From Date" value={thisComponentState.requestDate} type="date" onChange={(e) => updateState({ ...thisComponentState, requestDate: e.currentTarget.value })} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Required Date</div>
                                </td>
                                <td>

                                    <input className="form-control" title="From Date" value={thisComponentState.requiredDate} type="date" onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} />

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Document Id</div>
                                </td>
                                <td>
                                    <input className="form-control" title="This will override all other criterias." value={thisComponentState.documentId} onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Enter Document Id" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Employee</div>
                                </td>
                                <td>
                                    <StandardAutoComplete key={200} url="/Employees/GetEmployees" value={thisComponentState.code} updateValue={updateEmployeeId} placeholder="Enter Employee" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Branch</div>
                                </td>
                                <td>
                                    <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Enter Branch" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Request Type</div>
                                </td>
                                <td>
                                    <StandardAutoComplete key={200} url="/Generics/GetRequestTypes" value={thisComponentState.branchId} updateValue={updateRequestType} placeholder="Enter Request Type" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Status</div>
                                </td>
                                <td>
                                    <select className="form-control"  defaultValue={thisComponentState.transactionStatus} onChange={(e) => updateState({ ...thisComponentState, transactionStatus: e.currentTarget.value })}>
                                        <option value="">All</option>
                                        <option value="UnServed">UnServed</option>
                                        <option value="Served">Served</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Item Code</div>
                                </td>
                                <td>
                                    <StandardAutoComplete key={uuid()} url="/Inventories/GetInventories" value={thisComponentState.itemCode} updateValue={updateItemCode} placeholder="All transactions that has this item" />
                                </td>
                            </tr>


                        
                        </tbody>
                    </table>
                </div>
            </div>   
            <br />
            <br />

        </div>

    )

}

