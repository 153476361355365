import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import createDetailProperties from "../../Functions/createDetailProperties";
import createParentProperties from "../../Functions/createParentProperties";
import { TransactionContext } from "../TransactionContext";



export default function SrDetails(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: {
            requestor: null,
            requestDate: null,
            requiredDate: null,
            documentId: null,
            details: []
        }
    });



    useEffect(() => {

        pullPurchaseRequisition();

    }, []);


    const pullPurchaseRequisition = () => {

        fetch("/PurchaseRequisitions/GetSrDetails?" + new URLSearchParams({

            guidId: props.guidId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })
    };

    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }



    const move = () => {

        fetch("/PurchaseRequisitions/GetRequest?" + new URLSearchParams({

            guidId: props.guidId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);
                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                   // updateState({ ...thisComponentState, stockRequestGuidId: stockRequisitionGuidId });

                    return;
                }

                return alert(dataFromServer.message);

            })

        props.doubleBack();

    }

    return (

        <div>

            <div className="row">
                <div className="col">

                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />

                </div>

                <div className="col">
                    <input type="button" value="Move" className="form-control" onClick={move} />

                </div>
                <div className="col"></div>
                <div className="col"></div>

            </div>

            <br />

            <div className="row">
                <div className="col">
                    <div>Requestor</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestor}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Request Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requiredDate}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.note}</div>
                </div>
            </div>
            <br />

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Pr Qty</th>
                                <th>Served Quantity</th>
                                <th>UomDesc</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.details.map(val =>
                                    <tr key={val.guidId}>
                                        <td>{val.description}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.prQty}</td>
                                        <td>{val.servedQty}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>
                                        <td>{val.amount}</td>

                                    </tr>
                                )

                            }

                        </tbody>

                    </table>
                </div>
            </div>
        </div>




    )
}