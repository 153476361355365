
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';


export default function DayOff(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: true,
        selectedId: 0,
        showDetails: false,
        toCreateCycleCount:false,
        values: [],
        isChangingDayOff: false,
        showAsTable: false,
        stringWeekDays :
        [
             "Monday",
             "Tuesday",
             "Wenesday",
             "Thursday",
             "Friday",
             "Saturday",
             "Sunday"
        ],
        intWeekDays :[1,2,3,4,5,6,0]

    });



    useEffect(() => {

        fetch("/Crates/GetDayOffs")

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    values: response,
                    isLoading:false
                   
                });

            });
    }, []);



    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.isChangingDayOff) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                       Changing Day Off...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const onChange = (agentId,value) => {

        updateState({ ...thisComponentState, isChangingDayOff: true });

       let postBody = {
            item1: agentId,
            item2: parseInt(value)
        };

        fetch("/Crates/ChangeDayOff", {

            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                value:postBody
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (!response.success)
                {
                    alert(response.message);
                }

                let tempDatas = [...thisComponentState.values];

                let tempData = tempDatas.find(x => x.code === agentId);

                tempData.dayOff = value;

                updateState({...thisComponentState,values:tempDatas, isChangingDayOff: false });

                return;

            });
    }


    const getState = (weekOfDay,dayOff) => {

        if (weekOfDay === dayOff)
        {
            return (
                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCheck} color="red" />
                </div>
            );

        } else {

            return (<div></div>);

        }
    }

    const getCount = (weekDay) => {

        return thisComponentState.values.filter(x => x.dayOff === weekDay).length;

    }
    
    if (thisComponentState.showAsTable) {

        return (

            <div>

                <div className="row">

                    <div className="col">

                        <input type='button' className="form-control" value="Back" onClick={() => updateState({ ...thisComponentState, showAsTable: false })} />

                    </div>

                </div>

                <hr/>
                
                <div className="row">

                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Agent</th>
                                    {
                                        thisComponentState.stringWeekDays.map(val => <th> {val} </th>)
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    thisComponentState.values.map(val =>
                                        <tr>
                                            <td> {val.name} </td>
                                            {
                                                thisComponentState.intWeekDays.map(weekDay => <td> {getState(weekDay, val.dayOff)}</td>)
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>

                            <tfoot>

                                <tr>
                                    <td></td>
                                    {
                                        thisComponentState.intWeekDays.map(val =>


                                            <td>
                                                <div style={{ textAlign: "center" }}>
                                                    {getCount(val)}
                                                </div>
                                            </td>


                                        )
                                    }
                                </tr>
                            </tfoot>
                        </table>

                        </div>

                   
                </div>
            </div>


         
       )
    }


    return (

        <div>
            <div>
                <div className="row">
                    <div className="col">
                        <input type='button' className="form-control" value="Back" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input type='button' className="form-control" value="Table" onClick={() => updateState({ ...thisComponentState, showAsTable: true })} />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th> Agents </th>
                                    <th> Day Off </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    thisComponentState.values.map(val =>
                                        <tr>
                                            <td>
                                                <div>{ val.name }</div>
                                            </td>

                                            <td>
                                                <select className="form-control" value={val.dayOff} onChange={(e)=>onChange(val.code,e.currentTarget.value)} >
                                                    <option value="1"> Monday </option>
                                                    <option value="2"> Tuesday </option>
                                                    <option value="3"> Wenesday </option>
                                                    <option value="4"> Thursday </option>
                                                    <option value="5"> Friday </option>
                                                    <option value="6"> Saturday </option>
                                                    <option value="0"> Sunday </option>
                                                </select>
                                            </td>

                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        
   )
}