import React, { useEffect, useState } from 'react';
import createParentProperties from '../Functions/createParentProperties';
import MasterfileContextProvider from '../Masterfiles/MasterfileContextProvider';
import MasterfileForm from '../Masterfiles/MasterfileForm';



export default function IdentityClaims() {

    const postUrl = "/Identity/CreateClaim";
    const listUrl = "/Identity/Claims";

    const [data, updateMetaData] = useState({

        metaData: {}

    });

    const [isLoading, updateLoadingStatus] = useState(true);

    useEffect(() => {

        getMetaData("/Identity/CreateClaim");

    }, []);


    async function getMetaData(url) {

        await fetch(url)

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(data => {


                var tempMetaData = { ...data.metaData };

                tempMetaData.parentMetaData = data.parentMetaData;
                tempMetaData.parentProperties = createParentProperties(data.parentMetaData);
                tempMetaData.postUrl = postUrl;
                tempMetaData.detailsUrl = listUrl;

                updateMetaData({ metaData: tempMetaData});

                updateLoadingStatus(false);


            });
    }


    if (isLoading) {

        return (<div>Loading data...</div>)

    } else {

        return (

            <MasterfileContextProvider metaData={data.metaData} postUrl={postUrl} listUrl={listUrl}>

                <MasterfileForm />

               
            </MasterfileContextProvider>

         )

    }
}


