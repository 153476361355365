import { faAward, faChartArea, faCircleDot, faList, faPen, faPeopleArrows, faPerson, faTable, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useState } from "react"
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';

import DefineEvauationSheet from './DefineEvaluationSheet';
import Evaluate from './Evaluate';
import ListOfCycles from './ListOfCycles';
import MainFactors from './MainFactors';
import DefineSubfactors from './DefineSubfactors';
import CreateCycle from './CreateCycle';
import EvaluatorTypes from './EvaluatorTypes';
import EvaluatorMatrix from './EvaluatorMatrix';
import EvaluationResult from './EvaluationResult';
import EvaluationsPerEmployee from './EvaluationsPerEmployee';
import NoticeOfPersonnelAction from './NoticeOfPersonnelAction';
import Analytics from './Analytics';



export default function PerformanceHome() {

    const [thisComponentState, updateState] = useState({
        toDefineCriteria: false,
        toDefineEvaluationSheet: false,
        toEvaluate: false,
        showMainFactors:false,
        showListOfCycles: false,
        toDefineEvaluatorTypes: false,
        toDefineMatrix: false,
        showEvaluationResult: false,
        showListOfEvaluationsPerEmployee: false,
        showNopa :false
    });


    useEffect(() => {


    }, []);

    if (thisComponentState.toDefineCriteria) {

        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <DefineSubfactors back={() => updateState({ ...thisComponentState, toDefineCriteria: false })}/>
                    
                </div>  

        </div>)
    }

    if (thisComponentState.toDefineEvaluationSheet) {

        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <DefineEvauationSheet back={() => updateState({...thisComponentState,toDefineEvaluationSheet:false}) } />

                </div>

            </div>)
    }

    if (thisComponentState.toEvaluate) {

        return (
            <div>

                <div>

                    < Evaluate back={() => updateState({ ...thisComponentState, toEvaluate: false })} />

                </div>

            </div>)
    }

    if (thisComponentState.showListOfCycles) {

        return (
       
            <ListOfCycles back={() => updateState({ ...thisComponentState, showListOfCycles: false })} />
        )
    }


    if (thisComponentState.showMainFactors)
    {
        return (
         
             <MainFactors back={() => updateState({ ...thisComponentState,showMainFactors: false })} />
       )
    }


    if (thisComponentState.toDefineCycle)
    {
        return (<CreateCycle back={() => updateState({ ...thisComponentState,toDefineCycle: false })}  />)
    }


    if (thisComponentState.toDefineEvaluatorTypes) {
        return (<EvaluatorTypes back={() => updateState({...thisComponentState,toDefineEvaluatorTypes:false}) }/>)
    }


    if (thisComponentState.toDefineEvaluatorMatrix)
    {
        return (<EvaluatorMatrix back={() => updateState({ ...thisComponentState, toDefineEvaluatorMatrix: false })} />)
    }


    if(thisComponentState.showListOfEvaluationsPerEmployee)
    {
        return (<EvaluationsPerEmployee back={() => updateState({...thisComponentState,showListOfEvaluationsPerEmployee:false})}/>)
    }

    if (thisComponentState.showNopa) {

        return (<NoticeOfPersonnelAction back={() => updateState({...thisComponentState,showNopa:false})} />)
    }

    if (thisComponentState.showAnalytics) {
        return(<Analytics/>)
    }

    return (
            <div>
                <hr />
                    <div className="row">
                        <div className="col">
                            <FontAwesomeIcon icon={faPen} title="Define main factors details" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, toDefineCriteria: true })} />
                        </div>
                        <div className="col">
                            <FontAwesomeIcon icon={faList} title="List of Evaluations" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showListOfCycles: true })} />
                        </div>

                        <div className="col">
                            <FontAwesomeIcon icon={faPerson} title="Define an evaluator type" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, toDefineEvaluatorTypes: true })} />
                        </div>


                        <div className="col">
                            <FontAwesomeIcon icon={faTable} title="Define maxtrix" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, toDefineEvaluatorMatrix: true })} />
                        </div>

                        <div className="col">
                            <FontAwesomeIcon icon={faCircleDot} title="Define evaluation for a an employee" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState,toDefineCycle: true })} />
                        </div>
                        {/*<div className="col">*/}
                        {/*    <FontAwesomeIcon icon={faPeopleArrows} title="Perform evaluation" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, toEvaluate: true })} />*/}
                        {/*</div>*/}
                        <div className="col">
                            <FontAwesomeIcon icon={faUser} title="Evaluations per employee" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfEvaluationsPerEmployee: true })} />
                        </div>
                        <div className="col">
                            <FontAwesomeIcon icon={faAward} title="Notice of personnel action" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showNopa: true })} />
                        </div>
                        <div className="col">
                            <FontAwesomeIcon icon={faChartArea} title="Analytics" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showAnalytics: true })} />
                        </div>

                    </div>
                <hr />
            </div>
        )}