import React, { useEffect, useState } from 'react';
import setMetaData from '../../Functions/setMetaData';
import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import CreateInvoiceNavigator from './CreateInvoiceNavigator';
import DetailsInvoiceNavigator from './DetailsInvoiceNavigator';
import InvoiceListNavigator from './InvoiceListNavigator';



function InvoiceForm() {

    const postUrl = "/Invoices/Create";
    const detailsUrl = "/Invoices/Details/?id=";
    const editDetailUrl = "/Invoices/EditItem";
    const listUrl = "/Invoices/Index";
    const validatorUrl = "/Invoices/Validate";



    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['99','107','108','109','110','100'],
        type: "S"

    };


    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, [])



    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<InvoiceListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsInvoiceNavigator />} />);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl } context={context } metaData={data.metaData} listUrl={listUrl} postUrl={postUrl} detailsUrl={detailsUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList} >

                <TransactionForm detailsNavigator={<DetailsInvoiceNavigator />} transactionList={transactionList} createNavigator={<CreateInvoiceNavigator/> } />

            </TransactionContextProvider>

        )
    }

}

export default InvoiceForm