import React, {Fragment, useEffect, useState } from 'react';
import setMetaData from '../../Functions/setMetaData';
import DetailsStockRequisitionNavigator from '../GoodsIssue/DetailsGoodsIssueNavigator';
import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import CreatePurchaseOrderNavigator from './CreatePurchaseOrderNavigator';
import DetailsPurchaseOrderNavigator from './DetailsPurchaseOrderNavigator';
import PurchaseOrderListNavigator from './PurchaseOrderListNavigator';

function PurchaseOrderForm(props) {

    const transactionCode = "PurchaseOrder";
    const postUrl = "/PurchaseOrders/Create";
    const detailsUrl = "/PurchaseOrders/GetDetailsWithCookie/?id=";
    const editDetailUrl = "/PurchaseOrders/EditItem";
    const listUrl = "/PurchaseOrders/Index";
    const validatorUrl = "/PurchaseOrders/Validate";

    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['100', '102', '103', '104', '105', '106', '107', '111', '112', '113', '114', '115', '116', '117', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132'],
        type: "P"
    };

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<PurchaseOrderListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsPurchaseOrderNavigator />} />);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, []);


    const additionalDetail = (<td> <input type="button" className="btn btn-danger" style={{ width: "100px" }} value="LPP"  /> </td>);



    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl} transactionCode={transactionCode} context={context} metaData={data.metaData} postUrl={postUrl} detailsUrl={detailsUrl} listUrl={listUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList}>

                <TransactionForm createNavigator={<CreatePurchaseOrderNavigator previousTransactionGuidId={props.location.previousTransactionGuidId} />} detailsNavigator={<DetailsPurchaseOrderNavigator />} transactionList={transactionList} additionalDetail={additionalDetail } />

            </TransactionContextProvider>

        )
    }

}

export default PurchaseOrderForm;