
import { useState } from "react"

export default function CreateNewUom(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        code: "",
        name:""
      
    });



    const save = () => {


        let confirm = window.confirm("Make sure your data are correct. Please click ok to continue saving.")

        if (!confirm) {
            return;
        }

        if (thisComponentState.code === "") {

            return alert("Code is required.");
        };

      
        if (thisComponentState.name === "") {

            return alert("Name is required.");
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Inventories/CreateNewUom", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);
            });
    }


    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>

            <hr />

            <div className="row">
                <div className="col">
                </div>
                <div className="col">
                    <input type="button" value="Back" onClick={()=>props.back()} className="form-control" />
                </div>
                <div className="col">
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col">

                    <div className="row">

                        <div className="col">
                            <div title="Choose prefix to generate the value of this field">
                                Code
                            </div>
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.code} onChange={(e) => updateState({...thisComponentState,code:e.currentTarget.value})} className="form-control" />

                        </div>

                    </div>

                    <br />

                    <div className="row">

                        <div className="col">
                            <div>
                                Name
                            </div>
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.name} onChange={(e) => updateState({ ...thisComponentState, name: e.currentTarget.value })} className="form-control" />

                        </div>

                    </div>

                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col" >

                    <input type="button" value="Save" onChange={save} className="form-control" />

                </div>
            </div>

       
        </div>
    )

}
