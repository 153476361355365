import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import { TransactionContext } from '../TransactionContext';
import { uuid } from 'react-uuid';
import convertToDataType from '../../Functions/convertToDataType';

export default function ReceivedByTallMarks(props)
{
    const styledOl = {

        height: "20px",
        padding: "1em 0"
    }

    const styledLi = {

        display: "inline-block",
        height: "20px",
        border: "1px solid #000",
        marginRight: "4px"

    }

    const rotatedLi = {

        transform: "rotate(300deg)",
        height: "30px",
        position: "relative",
        left: "-15px",
        top: "5px",
        marginRight: "1em",
        marginTop: "-10px",
        display: "inline-block",
        border: "1px solid #000",

    }

    const [thisComponentState, updateData] = useState({

        rawDetails : props.rawDetails

    });

    const {data, guidId,updateMetaData,updateGuidId} = useContext(TransactionContext);

    const [isReady, updateReadiness] = useState(false);

    useEffect(() => {

        thisComponentState.rawDetails.forEach(item => {

            if (!item.hasOwnProperty("tallyCount")) {

                item.tallyCount = []
            }
        });

        updateReadiness(true);

    }, []);


    const retrieve = () => {

        var rawDetails = localStorage.getItem(guidId);

        if (rawDetails == null) {

            return alert("There are not items to retrieve.");
        }

        var tempData = [...thisComponentState.rawDetails];

        tempData = JSON.parse(rawDetails).rawDetails;

        updateData({ ...thisComponentState, rawDetails: tempData });

    }

 
    const add = (index) => {

       var tempData = [...thisComponentState.rawDetails];

        tempData[index].tallyCount.push(1);
     
        updateData({...thisComponentState,rawDetails:tempData});

        var tempMetaData = { ...data.metaData };

        tempMetaData.detailProperties[index].quantity = tempData[index].tallyCount.length.toString();

        updateMetaData({ metaData: tempMetaData });

        localStorage.removeItem(guidId);

        localStorage.setItem(guidId,JSON.stringify(thisComponentState));

    }

    const minus = (index) => {

        var tempData = [...thisComponentState.rawDetails];

        tempData[index].tallyCount.splice(0, 1);

        updateData({ ...thisComponentState, rawDetails: tempData });

        var tempMetaData = { ...data.metaData };

        tempMetaData.detailProperties[index].quantity = tempData[index].tallyCount.length.toString();

        updateMetaData({ metaData: tempMetaData });

        localStorage.removeItem(guidId);

        localStorage.setItem(guidId,JSON.stringify(thisComponentState));
    }


    const saved = () =>
    {
        var isConfirm = window.confirm("Make sure all your data are correct.");

        if (!isConfirm) {

            return;
        }

        if (data.metaData.detailProperties.length === 0) {

            return alert("There are no data to save.")
        };

        //Ensure that everything has correct data type
        for (var property in data.metaData.parentProperties) {

            var value = data.metaData.parentProperties[property];

            data.metaData.parentProperties[property] = convertToDataType(data.metaData.parentMetaData, property, value);

        
        };

        for (var i = 0; i < data.metaData.detailProperties.length; i++) {

            for (var property in data.metaData.detailProperties[i]) {

                var value = data.metaData.detailProperties[i][property];

                data.metaData.detailProperties[i][property] = convertToDataType(data.metaData.detailMetaData, property, value);

            };
        };

        //Remove details that has empty code

        data.metaData.detailProperties = data.metaData.detailProperties.filter(detailProperty => detailProperty.hasOwnProperty("code") ? detailProperty.code !== "" : false);

        if (data.metaData.detailProperties)

            if (data.metaData.detailProperties.length === 0) {

                var tempData = { ...data.metaData };

                tempData.detailProperties = [];

                updateMetaData({ ...data, metaData: tempData });

                return alert("There are no data to save.")

            };

        //check uom entry

        data.metaData.detailProperties.forEach(detail => {

            if (detail.hasOwnProperty("uomEntry")) {

                if (detail.uomEntry === "" || typeof detail.uomEntry === 'undefined') {

                    return alert("Invalid uom entry");

                }
            }
        });

        data.metaData.parentProperties.Details = data.metaData.detailProperties;

        //Make sure that preceeding guids has correct value

        let preceedingGuids = [];

        preceedingGuids.push(guidId);

        var dataToPost = {

            Transaction: data.metaData.parentProperties,
            PreceedingTransactionGuids: preceedingGuids
        };

        //Validate model

        fetch(data.metaData.validatorUrl, {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(json => {

                if (json.length > 0) {

                    //updateErrors({ ...errors, hasErrors: true, showErrors: true, errors: json });

                    console.log(json);

                    return alert(json);

                } else {

                    fetch(data.metaData.postUrl, {

                        // Adding method type
                        method: "POST",

                        // Adding body or contents to send
                        body: JSON.stringify(dataToPost),

                        // Adding headers to the request
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                        .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                        .then(json => {

                            if (json.success) {
                           
                                updateGuidId(json.guidId);

                                const updatedData = { ...data.metaData };

                                updateMetaData({ metaData: updatedData });

                                props.back();

                            } else {
    
                                alert(json.message);
                            }

                        });
                }
            });   
    }

    if (isReady) {

        return (

            <table className="table table-bordered">

                <thead className="thead-dark">

                    <tr>
                        <td colSpan="4">
                            <input defaultValue="Back" className="btn btn-success" style={{ width:"150px"}} onClick={props.back}/>
                            <input defaultValue="Retrieve" style={{ marginLeft :"5px",width:"150px" }} className="btn btn-success" onClick={retrieve} />
                            <input defaultValue="Save" style={{ marginLeft: "5px", width: "150px" }} className="btn btn-success" onClick={saved} />

                        </td>
                      
                    </tr>

                    <tr>
                        <td> Action </td> 
                        <td> Name </td>
                        <td> Count </td>
                        <td> Value </td>
                    </tr>

                </thead>

                <tbody>
                    {thisComponentState.rawDetails.map((item, index) =>

                        <tr key={uuid }>
                            <td className="col-sm-1">
                                <FontAwesomeIcon icon={faPlus} title="Add tally marks" style={{ cursor: 'pointer',float:'left' }} size="2x" color="green" onClick={()=>add(index)} />
                                <FontAwesomeIcon icon={faMinus} title="Add tally marks" style={{ cursor: 'pointer', float: 'right' }} size="2x" color="green" onClick={() => minus(index)} />
                            </td>

                            <td className="col-sm-3"> {item.name} </td>
                            <td className="col-sm-6">
                                <ol style={styledOl}>

                                    {item.tallyCount.map((content, index) =>

                                        ((index + 1) % 5) !== 0 ? <li style={styledLi} key={uuid} > </li> : <li style={rotatedLi}></li>

                                    )}

                                </ol>

                            </td>

                            <td className="col-sm-2">

                                {
                                    item.tallyCount.length
                                }

                            </td>

                        </tr>
                    )}
                </tbody>
            </table>
        )
    } else {

        return (<div> Loading... </div>)
    }
}