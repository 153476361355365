import { useEffect, useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import JobOrderDetails from "./JobOrderDetails";

export default function PerProjectManager(props)
{

    const [thisComponentState, updateState] = useState({

        isLoading: true,
        isPerformingOperation:false,
        showDetails: false,
        reload:0,
        selectedJobOrderId : 0,
        list : []
    })

    useEffect(() => {

        fetch("/StockRequisitions/GetListPerProjectManager")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, list: response.list, isLoading: false });

                } else {

                    alert(response.message);
                }

            });
    },[thisComponentState.reload]);

    if (thisComponentState.showDetails) {

        return (<JobOrderDetails jobOrderId={thisComponentState.selectedJobOrderId} back={() => updateState({...thisComponentState,showDetails:false})} />);

    }


    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }


    const modifyEditingStatus = (jobOrderId,val) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/StockRequisitions/ModifyJobOrderEditingStatus",
            {
                method: "post",
                body: JSON.stringify({
                    item1: jobOrderId,
                    item2: val
                }),
                headers: {

                    "content-type": "application/json; charset:UTF-8"
                }

            }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false,reload:thisComponentState.reload+1});

                    return alert(response.message);

                } else {

                    updateState({ ...thisComponentState, isPerformingOperation: false });

                    return alert(response.message);

                }
            });
    }



    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (
        <div style={getModalStyle()}>
            <div style={getModalContentStyle() }>
                <div className="row">

                    <div className="col">
                        <input value="Back" type="button" onClick={()=>props.back()} className="form-control"/>
                    </div>

                </div>

                <br/>

                <div className="row">

                    <div className="col">

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td colSpan="2">Action</td>
                                    <td>Job Order Id</td>
                                    <td>General Description</td>
                                    <td>Request Date</td>
                                    <td>Requestor</td>
                                    <td>ProjectManager</td>
                                    <td>Expected Due Date</td>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    thisComponentState.list.map(val =>

                                        <tr key={val.jobOrderId}>
                                            <td>
                                                <input type="button" className="form-control" value="Details" onClick={() => updateState({ ...thisComponentState, selectedJobOrderId: val.jobOrderId ,showDetails:true})} />

                                              
                                                
                                            </td>

                                            <td>
                                                {
                                                    val.canBeEdited ? <input type="button" className="form-control" value="Disable Editing" onClick={() => modifyEditingStatus(val.jobOrderId,false)} /> : <input type="button" className="form-control" value="Enable Editing" onClick={() => modifyEditingStatus(val.jobOrderId,true)} />
                                                }
                                            </td>

                                            <td>{val.jobOrderId}</td>
                                            <td>{val.generalDescription}</td>
                                            <td>{val.requestDate}</td>
                                            <td>{val.requestor}</td>
                                            <td>{val.projectManager}</td>
                                            <td>{val.expectedDueDate}</td>
                                        </tr>)
                                }

                            </tbody>

                        </table>
                    </div>

                </div>

              

            </div>
        </div>   
    )

}