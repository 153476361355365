
import React, { useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import StandardAutoComplete from '../../StandardAutocomplete';
import ApplyPayment from "./ApplyPayment";



export default function IncomingPaymentForSyncBranch(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: false,
        from: "",
        to:"",
        selectedGuidId: "",
        selectedName: "",
        selectedDate: "",
        cardCode: "",
        docEntry:"",
        toApplyPayment:false,
        values: [],
        branchId:""

    });


    const retrieve = () => {

        updateState({ ...thisComponentState, isLoading: true });

        let params = {
            from: thisComponentState.from,
            to:thisComponentState.to
        };

        if (thisComponentState.branchId !== "") {

            params.branchId = thisComponentState.branchId;

        }


        fetch("/Invoices/GetBranchInvoicesForPaymentApplication?" + new URLSearchParams(params)).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.length === 0) {

                   alert("No data found.");

                }

                updateState({
                    ...thisComponentState,
                    isLoading:false,
                    values : response
                })
            });
    }


    const modifyBalance = (guidId,sumApplied) => {

        let tempDatas = [...thisComponentState.values];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.sumApplied = tempData.sumApplied + sumApplied;

        updateState({ ...thisComponentState, values: tempDatas,toApplyPayment:false });
    }

   
    if (thisComponentState.toApplyPayment)
    {
        return (<ApplyPayment modifyBalance={modifyBalance} guidId={ thisComponentState.selectedGuidId } back={() => updateState({ ...thisComponentState, toApplyPayment: false })} cardCode={thisComponentState.cardCode} docEntry={thisComponentState.docEntry } name={thisComponentState.selectedName} invoiceDate={thisComponentState.selectedDate }  />)
    }


    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });

    }




    return (

        <div style={getModalStyle()} >

            <div style={getModalContentStyle()}>

                <div className="row">

                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={()=> props.back()}/>
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" onChange={(e) => updateState({...thisComponentState,from:e.currentTarget.value})}/>
                    </div>
                    <div className="col">
                        <input type="date" className="form-control" onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} />
                    </div>


                    <div>
                        <StandardAutoComplete url="/Branches/GetBranches" clear={() => updateState({...thisComponentState,branchId:""}) } updateValue={updateBranchId}/>
                    </div>
                    <div className="col">
                        <input value="Retrieve" type="button" onClick={retrieve } className="form-control"/>
                    </div>
                  
                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                        <table className="table table-bordered">

                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Date</th>
                                    <th>Sap Id</th>
                                    <th>Document Id</th>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Agent</th>
                                    <th>Gross</th>
                                    <th>Sum Applied</th>
                                    <th>Amount</th>                            
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.values !== 'undefined' && thisComponentState.values.map((val) =>

                                        <tr key={val.guidId}>

                                            <td>
                                                <input type="button" className="form-control" value="Apply Payment" onClick={() => updateState({...thisComponentState,selectedGuidId:val.guidId,selectedName:val.name,selectedDate:val.date,cardCode:val.code,docEntry:val.docEntry,toApplyPayment:true})}/>
                                            </td>

                                            <td> {val.date}</td>
                                            <td> {val.sapId}</td>
                                            <td> {val.documentId}</td>
                                            <td> {val.code}</td>
                                            <td> {val.name}</td>
                                            <td> {val.agent}</td>
                                            <td> {val.gross}</td>
                                            <td> {val.sumApplied}</td>
                                            <td> {val.gross-val.sumApplied}</td>
                                         
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>  
                </div>
            </div>
        </div>      
   )
}