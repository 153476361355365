import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFile, faFolderOpen, faList12, faMoneyBill, faPercent, faPlus, faRedo, faTable, faTimes } from '@fortawesome/free-solid-svg-icons';

import React, {  useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import uuid from 'react-uuid';
import GrPoDrafts from './ApDrafts';
import OpenItems from './OpenItems';
import OpenServices from './OpenServices';
import WithholdingTaxComputer from './WithholdingTaxComputer';


export default function CreateAccountsPayableNavigator(props) {

    const { data, updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        rawDetails: [],
        showDrafts: false,
        showListOfOpenItems: false,
        showListOfOpenServices: false,
        showWithholdingTax:false

    });


   
    const draftIt = () => {

        if (typeof data.metaData.parentProperties.code === 'undefined' || data.metaData.parentProperties.code === null) {

            return alert("Please select a vendor.");
        }

        var toSave = window.confirm("Are you sure you want to save this as draft?");

        if (!toSave)
        {
            return;
        }

        var draft = {};

        draft.guidId = uuid();
        draft.preceedingTransactionGuids = data.metaData.preceedingTransactionGuids;

        draft.parentProperties = data.metaData.parentProperties;
        draft.detailProperties = data.metaData.detailProperties;

        var drafts = localStorage.getItem("grPoDrafts");

        var masterfiles = localStorage.getItem("masterfiles");

        if (masterfiles !== null) {

            var pMasterfiles = JSON.parse(masterfiles);

            var vendor = pMasterfiles.vendors.find(val => val.code === draft.parentProperties.code);

            draft.vendor =vendor.name;
        }

        if (drafts !== null) {

            var tempDrafts = JSON.parse(drafts);

            tempDrafts.push(draft);

            localStorage.setItem("grPoDrafts", JSON.stringify(tempDrafts));

            return;
        }

        drafts = [];

        localStorage.setItem("grPoDrafts", JSON.stringify(drafts));

    }


    const retrieveItemContents = (guids, toComputeWithholdingTax) => {

        fetch("/AccountsPayables/RetrieveItemContents?" + new URLSearchParams({

            pGuids: guids,
            toComputeWithholdingTax : toComputeWithholdingTax

            })).then(response => response===401 ? window.location="/login" : response.json())
            .then(response => {

                let tempData = { ...data.metaData };

                tempData.parentProperties.date = response.date;
                tempData.parentProperties.code = response.code;
                tempData.parentProperties.docDiscount = response.docDiscount;
                tempData.parentProperties.note = "From ERP Sytem.";
                tempData.parentProperties.siNumber = response.siNumber;
                tempData.parentProperties.ewt = response.ewt;
                tempData.detailProperties = response.details;

                //toShowProperty

                var presentationValue = {

                    toShow: true,
                    categoryId: ""
                };

                tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                updateMetaData({ ...data, metaData: tempData });

                updateState({ ...thisComponentState, showListOfOpenItems: false, showListOfOpenServices: false });

            })
    }



    const retrieveServiceContents = (guids,toComputeWithholdingTax) => {

        fetch("/AccountsPayables/RetrieveServiceContents?" + new URLSearchParams({

            pGuids: guids,
            toComputeWithholdingTax : toComputeWithholdingTax

        })).then(response => response === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...data.metaData };

                tempData.parentProperties.date = response.date;
                tempData.parentProperties.code = response.code;
                tempData.parentProperties.docDiscount = response.docDiscount;
                tempData.parentProperties.note = "From ERP Sytem.";
                tempData.parentProperties.siNumber = response.siNumber;
                tempData.parentProperties.ewt = response.ewt;
                tempData.detailProperties = response.details;

                //toShowProperty

                var presentationValue = {

                    toShow: true,
                    categoryId: ""
                };

                tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                updateMetaData({ ...data, metaData: tempData });

                updateState({ ...thisComponentState, showListOfOpenItems: false, showListOfOpenServices: false });

            })
    }


    if (thisComponentState.showDrafts) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <GrPoDrafts back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )
    }

    if (thisComponentState.showListOfOpenItems) {
        
        return (<OpenItems retrieveContents={retrieveItemContents} back={() => updateState({ ...thisComponentState, showListOfOpenItems: false })}/>)
    };

    if (thisComponentState.showListOfOpenServices) {

        return (<OpenServices retrieveContents={retrieveServiceContents} back={() => updateState({ ...thisComponentState,showListOfOpenServices: false })} />)
    };

    if (thisComponentState.showWithholdingTax) {

        return (<WithholdingTaxComputer back={() => updateState({ ...thisComponentState, showWithholdingTax: false })} />)
    }

    return (
        <div style={{ float:'left', whiteSpace:'nowrap'}}>
            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faList12} title="List of Open Items" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfOpenItems: true })} />
            <FontAwesomeIcon icon={faList12} title="List of Open Service" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfOpenServices: true })} />
            <FontAwesomeIcon icon={faPercent} title="Withholding Tax" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, showWithholdingTax: true })} />
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
        </div>
    )
}

