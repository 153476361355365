import React, { useState } from 'react';
import uuid from 'react-uuid';
import StandardAutoComplete from '../components/StandardAutocomplete';

const GroupChat = () => {
    const [groupName, setGroupName] = useState('');
    const [userList, setUserList] = useState([]);
    const [newUser, setNewUser] = useState('');

    const containerStyle = {
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '10px',
    };

    const inputStyle = {
        width: '75%',  // Adjusted width to take 75% of the space
        padding: '8px',
        marginTop: '5px',
        marginRight: '5px',  // Added margin-right to create space between input and button
    };

    const buttonStyle = {
        width: '25%',  // Adjusted width to take 25% of the space
        backgroundColor: '#4caf50',
        color: 'white',
        padding: '8px 12px',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };

    const ulStyle = {
        listStyleType: 'none',
        padding: '0',
    };

    const liStyle = {
        marginBottom: '5px',
    };

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    const handleNewUserChange = (event) => {
        setNewUser(event.target.value);
    };

    const handleAddUser = (code) => {
        setUserList([...userList, code]);
        setNewUser('');
    };

    const handleCreateGroup = () => {
        // Perform any necessary actions with groupName and userList
        console.log(`Group Name: ${groupName}`);
        console.log('User List:', userList);

        // Reset the form after creating the group
        setGroupName('');
        setUserList([]);
    };



    return (
        <div style={containerStyle}>

            <div className="row">
                <div className="col-9">
                    Create a Group
                </div>

            </div>

            <br/>

            <label style={labelStyle}>
             
                <input type="text" value={groupName} placeholder="Group Name" onChange={handleGroupNameChange} className="form-control" />
            </label>
            <br />
            <label style={labelStyle}>              
                <div className="row">
                    <div className="col-9">
                        <input type="text" placeholder="Select memembers" value={newUser} onChange={handleNewUserChange} className="form-control" />

                        <StandardAutoComplete key={uuid()} url="/Identity/GetUsersForAutocomplete" value={newUser} clear={() =>setNewUser('')} updateValue={handleAddUser} placeholder="Employee Search" />
         
                    </div>
                    <div className="col-3">
                        <button onClick={handleAddUser} className="form-control">
                            Add
                        </button>
                    </div>
                </div>

               
            </label>
            <br />
            <ul style={ulStyle}>
                {userList.map((user, index) => (
                    <li key={index} style={liStyle}>{user}</li>
                ))}
            </ul>
           
        </div>
    );
};

export default GroupChat;
