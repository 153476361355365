import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { Fragment } from 'react';
import uuid from 'react-uuid';
import StandardAutoComplete from '../../StandardAutocomplete';
import { TransactionContext } from '../TransactionContext';
import SrDetailAutocomplete from './SrDetailAutocomplete';


export default function SrOwnCapture(props) {

    const { data,updateMetaData} = useContext(TransactionContext);

    const [thisComponentState,updateState] =useState({
        isLoading: true,
        categoryId: "",
        guidId: uuid(),
        inventoryMounter: uuid(),
        inventorySuggestions: [],
        uomSuggestions: [],
        details: [
           
        ]

    });


    useEffect(() => {

        fetch("/StockRequisitions/GetRequiredAutocompletes" )
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    inventorySuggestions: response.inventories,
                    uomSuggestions: response.uomValues,
                    isLoading : false               
                })
            });
       
    }, [thisComponentState.categoryId]);


    const updateQuantity = (guidId, quantity) => {

        if (isNaN(quantity)) {

            return;
        }

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.quantity = parseFloat(quantity);

        updateState({ ...thisComponentState, details: tempDatas });

    }



    const updatePrice = (guidId, price) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        var initVal = parseFloat(price);

        if (initVal >= 0) {

            tempData.price = initVal;

            updateState({ ...thisComponentState, details: tempDatas });
        }
    }


    const updateUomEntry = (uomEntry, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.uomEntry = uomEntry;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateBranch = (branchId, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.branchId = branchId;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateDepartment = (departmentId, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.deptId = departmentId;

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const updateWarehouse = (warehouseCode, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.warehouseCode = warehouseCode;

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const updateCode = (code, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var inventory = data.metaData.inventories.find(val => val.itemCode === code);

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.code = code;

        tempData.ugpEntry = inventory.ugpEntry;

        tempData.uomEntry = inventory.purchaseUomEntry;

        updateState({ ...thisComponentState, details: tempDatas, guidId: uuid() });
      
    }

  
    const updateDescription = (guidId, description) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.description = description;

        updateState({ ...thisComponentState, details: tempDatas });
        
    }


    const addRow = () => {







        var tempData = {

            guidId :uuid(),
            branchId: data.metaData.parentProperties.branchId,
            deptId: data.metaData.parentProperties.deptId,
            warehouseCode: data.metaData.parentProperties.warehouseCode,
            code: null,
            description: null,
            uomEntry: 0,
            quantity: 0,
            price: 0,
            lineTotal: function () {
                return typeof this !=='undefined' &&  typeof this.quantity !=='undefined' && typeof this.price !=='undefined' && this.quantity * this.price
            }
        }
        
        var tempDatas = [...thisComponentState.details];

        tempDatas.push(tempData);

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const deleteRow = (guidId) => {

        var tempDatas = [...thisComponentState.details];

        var filtered = tempDatas.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, details: filtered });
    }


    const back = () => {

        var tempData = { ...data.metaData };

        for (var i = 0; i < thisComponentState.details.length; i++) {

            var detail = {};

            detail.code = thisComponentState.details[i].code;
            detail.description = thisComponentState.details[i].description;
            detail.quantity = thisComponentState.details[i].quantity;
            detail.uomEntry = thisComponentState.details[i].uomEntry;
            detail.price = thisComponentState.details[i].price;
          
            tempData.detailProperties.push(detail);

        }

        //toShowProperty

        var presentationValue = {

            toShow: true,
            categoryId: ""
        };

        tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

        updateMetaData({ metaData: tempData });

        updateState({ ...thisComponentState, details: [] });

        props.back();
    }

    const getLastPurchasePrice = (item) =>
    {

        ////Fetch last purchase price

        fetch("/PurchaseOrders/GetLastPurchasePrice?" + new URLSearchParams({

            cardCode: data.metaData.parentProperties.code,
            itemCode: item.code,
            uomEntry: item.uomEntry

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                var tempDatas = [...thisComponentState.details];

                var tempData = tempDatas.find(val => val.guidId ===item.guidId);

                tempData.price =response.lastPurchasePrice;

                updateState({ ...thisComponentState, details: tempDatas });

            });
    }



    const clear = (guidId,property) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData[property] = "";

        updateState({ ...thisComponentState, details: tempDatas, guidId: uuid() });
    }


    const updateCategoryId = (code) => {

        updateState({ ...thisComponentState, categoryId: code });

    }

    if (thisComponentState.isLoading) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Initializing data...
                    </button>
                </div>
            </div>
        </div>)

    }


    return (

            <div>
                <div className ='row'>
   
                    <div className='col'>
                        <input value="Back" type="button" className="form-control" onClick={back}/>
                    </div>

                    <div className="col">
           
                    <StandardAutoComplete url="/Categories/GetCategories" value={thisComponentState.categoryId} clear={()=>updateState({ ...thisComponentState, categoryId: ""})} updateValue={updateCategoryId} placeholder="Category Search" />

                    </div>

                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div>
            
                </div>

                <br/>

                 <div className='row'>
                    <table className='table table-bordered'>

                        <thead>

                            <tr >
                                <td>  Action </td>
                                <td>  Item </td>
                                <td>  Display </td>
                                <td>  Quantity </td>
                                <td>  Price </td>
                                <td>  Uom </td>
                                <td>  Amount </td>
                            </tr>

                        </thead>


                        <tbody>
                        {
                            thisComponentState.details.length > 0 && thisComponentState.details.map(val =>

                                <tr key={uuid()} >

                                            <td className="col-lg-1">

                                                <div className="row">

                                                    <div className='col'>
                                                        <FontAwesomeIcon icon={faPlus} id="addRow" size='1x' color='green' onClick={addRow} style={{ cursor: 'pointer' }} />
                                                    </div>

                                                    <div className='col'>
                                                        <FontAwesomeIcon icon={faTrash} id="addRow" size='1x' color='red' onClick={() => deleteRow(val.guidId)} style={{ cursor: 'pointer' }} />
                                                    </div>

                                                </div>                                
                                            </td>

                                            <td className="col-lg-3">
                                            <SrDetailAutocomplete url="Inventories/GetInventories" value={val.code} updateValue={updateCode} clear={() => clear(val.guidId, "code")} suggestions={thisComponentState.inventorySuggestions} />
                                            </td>

                                            <td className="col-lg-1">
                                                <input value={val.description} onChange={(e) => updateDescription(val.guidId, e.currentTarget.value)} className="form-control" />
                                            </td>

                                            <td className="col-lg-1">
                                                <input value={val.quantity} type="number" onChange={(e) => updateQuantity(val.guidId, e.currentTarget.value)} className="form-control" />
                                            </td>

                                            <td className="col-lg-1">
                                                <input value={val.price} type="number" onDoubleClick={()=>getLastPurchasePrice(val)} onChange={(e) => updatePrice(val.guidId, e.currentTarget.value)} className="form-control" />
                                            </td>

                                            <td className="col-lg-1">
                                                <StandardAutoComplete url="UomValues/GetUomValues" clear={()=>clear(val.guidId,"uomEntry")} code={val.guidId} params={{ ugpEntry: val.ugpEntry }} mountCounter={val.code} value={val.uomEntry} updateValue={updateUomEntry} />
                                            </td>

                                            <td className="col-lg-1">
                                                <input className='form-control' value={val.lineTotal()} disabled />
                                            </td>

                                        </tr>

               
                                )
                            }

                    </tbody>

                        <tfoot>
                            <tr>
                                <td colSpan="7">

                                     <FontAwesomeIcon icon={faPlus} id="addRow" size='1x' color='green' onClick={addRow} style={{ cursor: 'pointer' }} />
                                   
                                    
                                </td>

                            </tr>
                        </tfoot>

                    </table>
                </div>
            </div>         
)}