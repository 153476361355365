
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";


export default function PoDetailsForGrReview(props)
{
     
    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: {}

    });

    useEffect(() => {

        pullPurchaseRequisition();

    },[]);

    const pullPurchaseRequisition = () => {

        fetch("/PurchaseRequisitions/GetPoDetails?" + new URLSearchParams({

            guidId: props.guidId

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(response => {

                if (response.details.length===0) {

                    updateState({ ...thisComponentState, isLoading: false });

                    return alert("There are no details available for PO.");
                }

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })
    };



    const print = () => {

        //let confirm =  window.confirm("You account is only allowed to print once.\nPlease make a copy of the pdf file that will be generated for future used.\nMake sure that you are only printing approved copies of Purchase Order.\nClick ok to continue.")

        //if (!confirm) {
        //    return;
        //}

        updateState({ ...thisComponentState, isLoading: true });

        let url = "/PurchaseRequisitions/Print?id=";

        fetch(url + props.guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {


                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState,isLoading: false });

            });
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle() }>
                <div className="row">

                    <div className="col">
                        <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                    </div>

                    <div className="col"></div>

                    <div className="col">
                       
                    </div>

                    <div className="col">
                        <input type="button" value="Print" className="form-control" onClick={print} />
                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <div>Document Id</div>
                    </div>

                    <div className="col">
                        <div> {thisComponentState.data.documentId}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div>Sap Id</div>
                    </div>

                    <div className="col">
                        <div> {thisComponentState.data.sapId}</div>
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        <div>Vendor</div>
                    </div>

                    <div className="col">
                        <div> {thisComponentState.data.vendor}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div>Request Date</div>
                    </div>

                    <div className="col">
                        <div> {thisComponentState.data.date}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div>Required Date</div>
                    </div>

                    <div className="col">
                        <div> {thisComponentState.data.requiredDate}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div>Note</div>
                    </div>

                    <div className="col">
                        <div> {thisComponentState.data.note}</div>
                    </div>
                </div>

                <br />

                <div className="row">

                    <div className="col">

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>UomDesc</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>

                            <tbody>


                                {
                                    thisComponentState.data.details.map(val =>
                                        <tr key={uuid()}>
                                            <td>{val.description}</td>
                                            <td>{val.quantity}</td>
                                            <td>{val.uomDesc}</td>
                                            <td>{val.price}</td>
                                            <td>{val.quantity*val.price}</td>

                                        </tr>
                                    )

                                }

                            </tbody>
                        </table>
                    </div>
                </div>



            </div>
        </div>


        
        
   )
}