
import React from 'react';
import { useState } from "react";


export default function SetTrip(props) {

    function formatTime(floatingNumber) {
        // Split the floating number into hours and minutes
        const hours = Math.floor(floatingNumber);
        const minutes = Math.round((floatingNumber - hours) * 60);

        // Convert hours to 24-hour format
        const formattedHours = hours < 10 ? '0' + hours : hours;

        // Add leading zeros if necessary
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Construct the time string
        const timeStr = `${formattedHours}:${formattedMinutes}`;

        return timeStr;
    }
    const [thisComponentState, updateState] = useState({

        destinations: "",
        passengers: "",
        purpose: "",
        tripDate: "",
        start: "",
        end: ""
    });

    const save = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Trips/Create", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({

                tripDate: thisComponentState.tripDate,
                start: parseFloat(thisComponentState.start),
                end: parseFloat(thisComponentState.end),
                purpose: thisComponentState.purpose,
                passengers: thisComponentState.passengers,
                destinations: thisComponentState.destinations
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {
                if (!response.ok) {
                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })
            .then(response => {

                updateState({ ...thisComponentState, position: response.position, isPerformingOperation: false });

                if (response.success) {

                    props.back();
                }

                return alert(response.message);
            });
    }


   

    const getStartOption = () => {

        let items = [];

        for (let i = 7; i <= 24; i++) {

            items.push(i);

            i = i - 1;
            i = i + .25;
        };

        return (
            <select className="form-control" onChange={(e) => updateState({ ...thisComponentState, start: e.currentTarget.value })}>
                {
                    items.map(val => <option value={val}> {formatTime(val)} {val >= 12 ? "PM" : "AM"}  </option>)
                }

            </select>
        )
    }


    const getEndOption = () => {

        let items = [];

        for (let i = 7; i <= 24; i++) {

            items.push(i);

            i = i - 1;
            i = i + .25;

        };

        return (
            <select className="form-control" onChange={(e) => updateState({ ...thisComponentState, end: e.currentTarget.value })}>
                {
                    items.map(val =>

                        <option value={val}> {formatTime(val)} {val >= 12 ? "PM" : "AM"}  </option>
                    )
                }

            </select>
        )
    }


    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div>
            <div>

                    <div className="row">

                        <div className="col">
                           
                        </div>

                        <div className="col">
                        <input value="Back" type="button" onClick={()=>props.back() } className="form-control text-center"/>
                        </div>

                        <div className="col">

                        </div>

                    </div>

                     <hr/>
         
                    <div className="row">

                        <div className="col">
                            <div>Plate Number</div>
                        </div>

                        <div className="col">
                            <input disabled className="form-control" placeholder="To be set by logistics" />
                        </div>
                    </div>


                    <div className="row">

                        <div className="col">

                            <div>Trip Date</div>

                        </div>

                        <div className="col">

                            <input type="date" className="form-control" onChange={(e) => updateState({ ...thisComponentState, tripDate: e.currentTarget.value })} value={thisComponentState.tripDate} />

                        </div>

                    </div>


                    <div className="row">
                        <div className="col">
                            <div>Destination/s</div>
                        </div>
                        <div className="col">

                            <input title="Only commas are allowed" pattern="^[,]*$" placeholder="Separate by comma if multiple" className="form-control" onChange={(e) => updateState({ ...thisComponentState, destinations: e.currentTarget.value })} value={thisComponentState.destinations} />

                        </div>
                    </div>

                    <div className="row">

                        <div className="col">

                            <div>Passenger/s</div>

                        </div>

                        <div className="col">
                         
                            <input pattern="^[,]*$" placeholder="Separate by comma if multiple" className="form-control" onChange={(e) => updateState({ ...thisComponentState, passengers: e.currentTarget.value })} value={thisComponentState.passengers} />

                        </div>

                    </div>


                    <div className="row">

                        <div className="col">

                            <div>Start Time</div>

                        </div>

                        <div className="col">

                            {getStartOption()}

                        </div>

                    </div>

                    <div className="row">

                        <div className="col">

                            <div>End Time</div>

                        </div>

                        <div className="col">

                            {getEndOption()}

                        </div>

                    </div>

                    <div className="row">

                        <div className="col">

                            <div>Purpose</div>

                        </div>

                        <div className="col">

                            <input  className="form-control" onChange={(e) => updateState({ ...thisComponentState,purpose: e.currentTarget.value })} value={thisComponentState.purpose} />

                        </div>

                    </div>

                <hr/>

                    <div className="row">

                        <div className="col">

                         
                        </div>

                        <div className="col">
                            <input type="button" value="Save" className="form-control" onClick={save }/>
                        </div>

                    </div>

        


            </div>
        </div>
    )

}