import { faCamera, faEdit, faHourglassEnd, faLockOpen, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { replace } from "connected-react-router";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import UploadFile from "../../../UploadFile";
import CloseJobOrder from "./CloseJobOrder";
import EditJobOrder from "./EditJobOrder";


export default function JobOrderDetails(props)
{
    const [thisComponentState, updateState] = useState({

        beforeState: [],
        afterState:[],
        requestedBy: "",
        projectManager: "",
        startDate: "",
        requestDate: "",
        estimatedDaysToComplete: "",
        generalDescription: "",         
        projectUserId: "",
        jobOrderId: props.jobOrderId,
        takePictureOfFinishedJob: false,
        completed: false,
        isLoading: true,
        editJobOrder: false,
        closeJobOrder: false,
        toRefresh: 0,
        uploadUrl:"",
        uploadFile:false

    })

    const createPR = () => {

        localStorage.removeItem("stockRequisitionGuidId");

        localStorage.setItem("jobOrderId", thisComponentState.jobOrderId.toString());

        window.location = "/PurchaseRequisition";
    }

    const close = (jobOrderId) => {

        const confirm = window.confirm("Are you sure you want to mark this as complete?");

        if (!confirm) {

            return;
        }

        updateState({ ...thisComponentState, isClosing: true });

        fetch("/StockRequisitions/CloseJobOrderTask",
            {
                method: "post",
                body: JSON.stringify({
                    value: jobOrderId
                }),
                headers: {
                    "content-type": "application/json; charset:UTF-8"
                }
            }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState.tasksToBeExecuted];

                    let tempData = tempDatas.find(val => val.id === jobOrderId);

                    tempData.completed = true;

                    updateState({ ...thisComponentState, isClosing: false, tasksToBeExecuted: tempDatas });

                    return alert("Closing successful.");

                } else {

                    updateState({ ...thisComponentState, isClosing: false });

                    return alert(response.message);
                }

            });
    }

    const getDetails = (projectUserId, generalDescription, jobOrderId) => {

        updateState({ ...thisComponentState, isGettingDetails: true });

        fetch("/StockRequisitions/GetJobOrderDetails?" + new URLSearchParams({

            jobOrderId: thisComponentState.jobOrderId

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    projectUserId: response.projectUserId,
                    deptInCharge:response.deptInCharge,
                    jobOrderId: response.jobOrderId,
                    requestDate: response.requestDate,
                    startDate: response.startDate,
                    expectedDueDate: response.expectedDueDate,
                    dueDate: response.dueDate,
                    completionDate:response.completionDate,
                    generalDescription: response.generalDescription,
                    afterState : response.afterImages,
                    beforeState: response.beforeImages,
                    tasksToBeExecuted: response.definedTasks,
                    projectManager: response.projectManager,
                    isApproved: response.isApproved,
                    approvedBy : response.approvedBy,
                    completed: response.completed,
                    showJobOrderFilter: false,
                    isLoading:false

                })
            });
    }


    useEffect(() => {

        getDetails();

    },[thisComponentState.toRefresh]);

    if (thisComponentState.isLoading) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Retrieving...
                    </button>
                </div>
            </div>
        </div>)

    }



    const canEdit = () => {

        fetch("/StockRequisitions/CanEditJobOrder?" + new URLSearchParams({

            jobOrderId: thisComponentState.jobOrderId

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (!response) {

                    alert("Editing of this job order is disabled. Please contact assigned project manager to allow editing.");

                    return;

                } else {

                    updateState({ ...thisComponentState, editJobOrder: true });           
                }
            });
    }


    if (thisComponentState.editJobOrder)
    {

        let jobOrder = {
            jobOrderId: thisComponentState.jobOrderId,
            projectUserId: thisComponentState.projectUserId,
            deptInCharge: thisComponentState.deptInCharge,
            expectedDueDate: thisComponentState.expectedDueDate,           
            generalDescription: thisComponentState.generalDescription
        };

        return (<EditJobOrder back={() => updateState({ ...thisComponentState, editJobOrder: false })} jobOrder={jobOrder} />)
    }

    if (thisComponentState.closeJobOrder) {

        return (<CloseJobOrder  jobOrderId={thisComponentState.jobOrderId} back={() => updateState({...thisComponentState,closeJobOrder:false,toRefresh:thisComponentState.toRefresh+1}) }/>)
    }

    if (thisComponentState.uploadFile) {

        return (<UploadFile fileLimit={3} isSeparateUpload={true} identifier={props.jobOrderId} uploadUrl={thisComponentState.uploadUrl} back={() => updateState({ ...thisComponentState, uploadFile: false })} />)
    }

    
    return (

        <div>
            <hr />

            <div className="row">

                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={()=>props.back()} />
                </div>

                <div className="col">
                    <input type="button" className="form-control" value="Create PR" onClick={createPR} />
                </div>

            </div>

            <hr />

            <div className="row">

                <div className="col">
                    Summary of Job Order:
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faUpload} title="Before state condition" style={{ cursor:'pointer' }} onClick={() => updateState({ ...thisComponentState, uploadFile: true, uploadUrl: "/StockRequisitions/UploadOnBeforeImages" })}  size="2x" color="red" />

                </div>

                <div className="col">

                    <FontAwesomeIcon icon={faUpload} title="After state condition" style={{ cursor: 'pointer' }} onClick={() => updateState({ ...thisComponentState, uploadFile: true, uploadUrl: "/StockRequisitions/UploadOnAfterImages" })} size="2x" color="yello" />

                </div>


                <div className="col">
                    <FontAwesomeIcon icon={faLockOpen} title="Close Job Order" style={{ cursor: 'pointer' }} onClick={() => updateState({ ...thisComponentState, closeJobOrder : true })}  size="2x" color="blue" />

                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faEdit} title="Edit Job Order" style={{ cursor: 'pointer' }}  onClick={canEdit} size="2x" color="blue" />
                </div>


            </div>


            <hr />

            <div>
                <div className="row">
                    <div className="col">
                        Job Order Id
                    </div>
                    <div className="col">
                        {thisComponentState.jobOrderId}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        General Description
                    </div>
                    <div className="col">
                        {thisComponentState.generalDescription}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Project Manager
                    </div>
                    <div className="col">
                        {thisComponentState.projectManager}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Request Date
                    </div>
                    <div className="col">
                        {thisComponentState.requestDate}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Start Date
                    </div>
                    <div className="col">
                        {thisComponentState.startDate !==null ? thisComponentState.startDate : "Not Defined"}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Expected Due Date
                    </div>
                    <div className="col">
                        {thisComponentState.expectedDueDate !==null ? thisComponentState.expectedDueDate : "Not Defined" }
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        Due Date
                    </div>
                    <div className="col">
                        {thisComponentState.dueDate !== null ? thisComponentState.dueDate : "Not Defined"}
                    </div>
                </div>


                {
                    thisComponentState.completed &&

                    <div className="row">
                        <div className="col">
                           Completion Date
                        </div>
                        <div className="col">
                            {thisComponentState.completionDate}
                        </div>
                    </div>

                }




                {
                   thisComponentState.isApproved && 

                    <div className="row">
                        <div className="col">
                            Approved By
                        </div>
                        <div className="col">
                            {thisComponentState.approvedBy}
                        </div>
                    </div>
                }


                <hr />


                <div className="row">
                    <div className="col">
                        Before State
                    </div>
                    <div className="col">
                    </div>
                </div>

                <hr />

                <div>
                    <div style={{ overflowX: 'scroll' }}  >
                        {
                            thisComponentState.beforeState.map(val => <img key={uuid()} className="img-fluid" style={{ display: "inline-block" }} src={val} alt="no" />)
                        }
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col">
                        After State
                    </div>
                    <div className="col">
                    </div>
                </div>

                <hr />

                <div>
                    <div style={{ overflowX: 'scroll' }}  >
                        {
                            thisComponentState.afterState.map(val => <img key={uuid()} className="img-fluid" style={{ display: "inline-block" }} src={val} alt="no" />)
                        }
                    </div>
                </div>

                <hr />

              
                <div className="row">
                    <div className="col">
                        Total Estimated Budget
                    </div>
                    <div className="col">
                        {
                            thisComponentState.estimatedBudget
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Total Estimated Days to complete
                    </div>
                    <div className="col">

                        {
                            thisComponentState.estimatedDaysToComplete
                        }

                    </div>

                </div>
                <hr />
            </div>
        </div>
    )

}