import { faForward, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionContext } from '../TransactionContext';
import ProductionForm from './ProductionForm';
import ReleasedForm from './ProductionPlanned';


function DetailsProductionNavigator(props) {

    const {guidId} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

         released:false

    });


    if (thisComponentState.released) {

        return <ReleasedForm updateState={updateState} thisComponentState={thisComponentState}/>
    };


    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/Productions/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }



    return (
            <div style={{ float: "left" }}>
           
                    <input type="button" className="btn btn-success" style={{width:"120px"}} onClick={refresh} value="Create New"/>
   
                    <Link to={{ pathname: "/Production"}}>
                        <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" />
                    </Link>

                   <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={forward} />

            </div>
    )
    
}


export default DetailsProductionNavigator;