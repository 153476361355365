import React from 'react';
import FlexmonsterReact from 'react-flexmonster';


const MyPivotTable = () => (
    <div>
        <FlexmonsterReact.Pivot
            toolbar={true}
            width="100%"
            height="600"
            report={{
                dataSource: {
                    data: [
                        { name: "Jury", age: 60 },
                        { name: "Janet", age: 20 }
                    ]
                }
            }}
        />
    </div>
);

export default MyPivotTable;
