import React from 'react';

const Details = ({ cashAdvance,back }) => {


    const preview = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/CashAdvances/Print?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    const createAP = () => {

        var yes = window.confirm("Are you sure you want to create AP for this?");

        if (!yes)
        {
            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/CashAdvances/CreateAP", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }



    if (!cashAdvance) return <div style={styles.noData}>No data available</div>;

    return (
        <div style={styles.container}>

            <div className="row">
                <div className="col">
                    <input value="Back" type="button" onClick={()=> back()} className="form-control" />
                </div>
                <div className="col">
                    <input value="Print" type="button" onClick={preview} className="form-control" />
                </div>

                <div className="col">
                    <input value="To AP" type="button" onClick={preview} className="form-control" />
                </div>
            </div>

            <hr/>

            <h2 style={styles.heading}>Cash Advance Information</h2>
            <ul style={styles.list}>
                {Object.entries(cashAdvance).map(([key, value]) => (

                    key!=='guidId' &&

                    <li key={key} style={styles.listItem}>
                        <strong>{formatKey(key)}:</strong> {formatValue(key, value)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const formatKey = (key) => {
    // Convert camelCase or PascalCase to readable format
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

const formatValue = (key, value) => {
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (key.toLowerCase().includes('date')) return new Date(value).toLocaleDateString();
    if (typeof value === 'object' && value !== null) return value.Name || 'N/A';
    return value;
};

const styles = {
    container: {
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        maxWidth: '600px',
        margin: '20px auto',
        fontFamily: 'Arial, sans-serif',
    },
    heading: {
        textAlign: 'center',
        color: '#333',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
    },
    listItem: {
        margin: '10px 0',
        padding: '10px',
        borderBottom: '1px solid #ddd',
    },
    lastItem: {
        borderBottom: 'none',
    },
    noData: {
        textAlign: 'center',
        color: '#888',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
};

export default Details;
