
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';
import uuid from 'react-uuid';



export default  function StockTransferListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        documentId: "",
        sapId:"",
        code: "",
        branchId:"",
        fromDate: "",
        toDate: "",
        needDate:"",
        acceptanceStatus:"",
        documentStatus: "",
        itemCode: "",
        toWarehouse: "",
        fromWarehouse:""

    });

    async function search() {

        updateState({ ...thisComponentState, isLoading: true })

        let params = {};

        if ( thisComponentState.fromDate !== "" && thisComponentState.toDate !== "") {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (thisComponentState.needDate !== "")
        {
            params.needDate = thisComponentState.needDate;
        }

        if ( thisComponentState.code !== "") {

            params.code = thisComponentState.code;
        }

        if (thisComponentState.documentId !== "") {

            params.documentId = thisComponentState.documentId;
        }

        if (thisComponentState.branchId != "") {

            params.branchId = thisComponentState.branchId;
        }

        if (thisComponentState.acceptanceStatus != "") {

            params.acceptanceStatus = thisComponentState.acceptanceStatus;
        }

        if (thisComponentState.needDate != "") {

            params.needDate = thisComponentState.needDate;
        }

        if (thisComponentState.documentStatus !== "") {

            params.documentStatus = thisComponentState.documentStatus;
        }

        if (thisComponentState.itemCode !== "") {

            params.itemCode = thisComponentState.itemCode;
        }

        if (thisComponentState.toWarehouse !== "") {

            params.toWarehouse = thisComponentState.toWarehouse;
        }

        if (thisComponentState.fromWarehouse !== "") {

            params.fromWarehouse = thisComponentState.fromWarehouse;
        }

        if (thisComponentState.sapId !== "") {

            params.sapId = thisComponentState.sapId;
        }

        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params)).then(response =>

            response.status == 401 ? window.location = "/Login" : response.json()

        ).then(response => {

            const dataFromServer = response;

            var tempData = { ...data.metaData };

            tempData.listData = dataFromServer;

            updateMetaData({ metaData: tempData });

            updateState({ ...thisComponentState, isLoading: false });

        });

    };


   
    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            documentId: "",
            sapId:"",
            code: "",
            branchId: "",
            fromDate: "",
            toDate:"",
            needDate : "",
            acceptanceStatus: "",
            documentStatus: "",
            toWarehouse: "",
            fromWarehouse: "",
            itemCode:""
            
        })
    }


    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/StockTransfers/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>

        );

    };

    const updateEmployeeId = (code) => {
        updateState({ ...thisComponentState, code: code });
    };


    const updateBranchId = (code) => {
        updateState({ ...thisComponentState, branchId: code });
    };

    const updateFromWarehouseId = (code) => {
        updateState({ ...thisComponentState, fromWarehouse: code });
    };

    const updateToWarehouseId = (code) => {
        updateState({ ...thisComponentState,toWarehouse: code });
    };

    const updateItemCode = (code) => {
        updateState({ ...thisComponentState,itemCode: code });
    };




    return (

          <div style={{ textAlign: 'center' }}>

            <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search}type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />

            <br />
            <br />

            <table className='table table-bordered'>


                <tbody>
                    <tr>
                        <td>
                            <div>From</div>
                        </td>
                        <td>

                            <input className="form-control" title="From Date" value={thisComponentState.fromDate} type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>To</div>
                        </td>
                        <td>
                            <input className="form-control" title="To Date" value={thisComponentState.toDate} type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Need Date</div>
                        </td>
                        <td>
                            <input className="form-control" title="Need Date" value={thisComponentState.needDate} type="date" onChange={(e) => updateState({ ...thisComponentState, needDate: e.currentTarget.value })} />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Document Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." value={thisComponentState.documentId} onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Enter Document Id" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Sap Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." value={thisComponentState.sapId} onChange={(e) => updateState({ ...thisComponentState,sapId: e.currentTarget.value })} placeholder="Enter Sap Id" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Employee</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Employees/GetEmployees" value={thisComponentState.code} updateValue={updateEmployeeId} placeholder="Employee Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Branch</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Branch Search" />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Acceptance Status</div>
                        </td>
                        <td>

                            <select className="form-control" value={thisComponentState.acceptanceStatus} onChange={(e) => updateState({ ...thisComponentState,acceptanceStatus: e.currentTarget.value })}>
                                <option value="" selected>All</option>
                                <option value="UnAccepted">UnAccepted</option>
                                <option value="Accepted">Accepted</option>
                            </select>

                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Document Status</div>
                        </td>
                        <td>

                            <select className="form-control" defaultValue={thisComponentState.documentStatus} onChange={(e) => updateState({ ...thisComponentState,documentStatus: e.currentTarget.value })}>
                                <option value="">All</option>
                                <option value="Closed">Closed</option>
                                <option value="Unclosed">Unclosed</option>
                            </select>

                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>From Warehouse</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Warehouses/GetWarehouses" value={thisComponentState.fromWarehouse} updateValue={updateFromWarehouseId} placeholder="From Warehouse" />
                          
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>To Warehouse</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Warehouses/GetWarehouses" value={thisComponentState.toWarehouse} updateValue={updateToWarehouseId} placeholder="To Warehouse" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Item Code</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Inventories/GetInventories" value={thisComponentState.itemCode} updateValue={updateItemCode} placeholder="All transactions that has this item" />
                        </td>
                    </tr>



                    </tbody>

             
            </table>

            <br />
            <br />

        </div>   
    )
}

