import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCross, faX } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import StandardAutoComplete from '../../StandardAutocomplete';
import { Link } from 'react-router-dom';



export default function WashedStatus(props) {

    const [thisComponentState, updateState] = useState({

        isLoading:false,
        chargedDate: null,
        date: null,
        issuedCrates: [],
        firstWashedDate:"",
        secondWashedDate:"",
        thirdWashedDate:"",
        missingCratesOnFirstWashed: [],
        missingCratesOnSecondWashed: [],
        missingCratesOnThirdWashed: [],
        chargesWithValue:[],
        cratesForCharging: [],
        totalCharged:0,
        agentName: "",
        agentId:""

    });


 
    const onFirstWashed = (missingCratesOnFirstWashed,crateId) => {

        if (thisComponentState.firstWashedDate === "")
        {
            return (

                <div style={{ textAlign: "center" }}>
                   
                </div>
            );
        }

        if (!missingCratesOnFirstWashed.includes(crateId)) {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            );

        } else {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faX} color="red" />
                </div>
            );

        }
    }


    const onSecondWashed = (missingCratesOnSecondWashed,crateId) => {


        if (thisComponentState.secondWashedDate==="") {
            return (

                <div style={{ textAlign: "center" }}>

                </div>
            );
        }

       
        if (!missingCratesOnSecondWashed.includes(crateId)) {

            return (

                <div style={{textAlign:"center"}}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>   
           );

        } else {

            return (

                    <div style={{textAlign:"center"}}>
                        <FontAwesomeIcon icon={faX} color="red" />
                    </div>
            );
        }
    }


    const onThirdWashed = (missingCratesOnThirdWashed, crateId) => {


        if (thisComponentState.thirdWashedDate==="") {
            return (

                <div style={{ textAlign: "center" }}>
                </div>
            );
        }

        if (thisComponentState.thirdWashedDate === null) {
            return (
                <div style={{ textAlign: "center" }}>      
                </div>
            );
        }
       
        if (!missingCratesOnThirdWashed.includes(crateId)) {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            );

        } else {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faX} color="red" />
                </div>
            );
        }
    }



    const updateAgentId = (agentId) => {

        updateState({ ...thisComponentState,isLoading:true});

        fetch("/Crates/GetStatus?" + new URLSearchParams({

            agentId: agentId
        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    isLoading: false,
                    issuedCrates: response.issuedCrates,
                    missingCratesOnFirstWashed: response.missingCratesOnFirstWashed,
                    missingCratesOnSecondWashed: response.missingCratesOnSecondWashed,
                    missingCratesOnThirdWashed: response.missingCratesOnThirdWashed,
                    firstWashedDate: response.firstWashedDate,
                    secondWashedDate: response.secondWashedDate,
                    thirdWashedDate: response.thirdWashedDate,
                    agentId:agentId
                });

            });
        

    }



    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" id="backButton" className="form-control" type="button" onClick={()=>props.back()} />
                </div>

            </div>

            <hr/>


            <div className="row">
                <div className="col">
                    <div> Agent </div>
                </div>

                <div className="col">

                    <StandardAutoComplete value={thisComponentState.agentId} url="/Agents/GetAgents" updateValue={updateAgentId} />


                </div>

            </div>

            <br />

            <div className="row">
                <div className="col-sm-12">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <td>SerialNumber</td>
             
                                <td>
                                    <p>First Washed</p>
                                    <br/>
                                    <p>{thisComponentState.firstWashedDate}</p>

                                </td>
                                <td>
                                    <p>Second Washed</p>
                                    <br/>
                                    <p>{thisComponentState.secondWashedDate}</p>
                                </td>
                                <td>
                                    <p>Third Washed</p>
                                    <br/>
                                    <p>{thisComponentState.thirdWashedDate}</p>
                                </td>
                            
                            </tr>

                        </thead>

                        <tbody>

                            {
                                thisComponentState.issuedCrates.map(data =>
                                 
                                    <tr>
                                        <td> {data.description}  </td>
                               
                                        <td> {onFirstWashed(thisComponentState.missingCratesOnFirstWashed,data.id)}  </td>
                                        <td> {onSecondWashed(thisComponentState.missingCratesOnSecondWashed, data.id)}  </td>
                                        <td> {onThirdWashed(thisComponentState.missingCratesOnThirdWashed, data.id)}  </td>
                                
                                    </tr>)


                            }

                        </tbody>

                      

                    </table>

                </div>
            </div>
        </div>

        
        
        
        
 )
}

