import React, { useEffect, useRef } from 'react';

const ColumnChart = () => {
    const chartRef = useRef(null);
    let chart; // Declare chart variable outside the drawChart function

    useEffect(() => {
        // Load Google Charts library
        const script = document.createElement('script');
        script.src = 'https://www.gstatic.com/charts/loader.js';
        script.onload = () => {
            google.charts.load('current', { packages: ['corechart'] });
            google.charts.setOnLoadCallback(drawChart);
        };
        document.body.appendChild(script);

        // Cleanup function
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const drawChart = () => {
        const data = google.visualization.arrayToDataTable([
            ['Y_Values', 'X_Values', { role: 'style' }],
            ['Copper', 75, '#b87333'],
            ['Silver', 85, 'silver'],
        ]);
        const formatter = new google.visualization.NumberFormat({ pattern: '#.#%' });
        formatter.format(data, 1); // Format the values in the second column (density)

        const view = new google.visualization.DataView(data);
        view.setColumns([
            0,
            1,
            {
                calc: (dataTable, rowIndex) => {
                    const value = dataTable.getValue(rowIndex, 1);
                    return {
                        v: value.toFixed(0) + '%', // Append percentage sign to annotation
                        f: value.toFixed(0) + '%', // Display formatted value with percentage sign
                    };
                },
                type: 'string',
                role: 'annotation',
            },
            2
        ]);
        const options = {
            title: 'Technical Competencies',
            width: 600,
            height: 400,
            bar: { groupWidth: '95%' },
            legend: { position: 'none' },
            vAxis: {
                format: '#\'%\'',
                gridlines: {
                    count: Math.ceil((1 - 0.25) / 5)
                },
                minValue: 0.25
            },
        };

        chart = new google.visualization.ColumnChart(chartRef.current);
        chart.draw(view, options);
    };

    return <div ref={chartRef} style={{ width: '900px', height: '300px' }} />;
};

export default ColumnChart;
