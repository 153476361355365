import { useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";

export default function Recoveries(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        crateId : "",
        recoveredCrates:[]
     
    });


    const updateCrateId = (code,rowIdentifier,description) => {

        let detail = {
            crateId: code,
            serialNumber: description
        };

        let tempData = [...thisComponentState.recoveredCrates];

        if (tempData.map(val => val.crateId).includes(code)) {

            return alert("This item is already included.")
        }

        tempData.push(detail);

        updateState({ ...thisComponentState,recoveredCrates:tempData});

    }

    const recover = () => {

        var isConfirm = window.confirm("Are you sure you want to recover all this crates?");

        if (!isConfirm) {

            return;
        };

  
        let postBody = {

            washedHistoryId:props.washedHistoryId,
            recoveredCrates: thisComponentState.recoveredCrates.map(val =>  parseInt(val.crateId))
        }


        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Crates/Recoveries", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({...thisComponentState,isPerformingOperation:false})

                return alert(response.message);

            });
    }




    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const deleteRow = (crateId) => {

        let tempData = [...thisComponentState.recoveredCrates];

        tempData = tempData.filter(val => val.crateId !== crateId);

        updateState({ ...thisComponentState, recoveredCrates: tempData})

    }


    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>

                <div className="col">
                    <input value="Save" onClick={recover} className="form-control" type="button" />
                </div>

            </div>

            <hr/>
 
            <div className="row">

                <div className="col">
                    Serial Number
                </div>

                <div className="col">

                    <StandardAutoComplete value={thisComponentState.crateId} clear={() => updateState({ ...thisComponentState, crateId: "" })} url="/Crates/GetCrates" updateValue={updateCrateId} />

                </div>

            </div>

            <hr/>


            <div className="row">
                <div className="col">


                    <table className="table table-bordered">

                        <thead>
                            <th> Action </th>
                            <th>Crate Id</th>
                            <th>Serial Number</th>

                        </thead>

                        <tbody>
                            {
                                thisComponentState.recoveredCrates.length > 0 && thisComponentState.recoveredCrates.map(val =>
                                    <tr>
                                        <td>
                                            <input type="button" className="form-control" value="Delete" onClick={()=>deleteRow(val.crateId)}/>
                                        </td>
                                        <td>{val.crateId}</td>
                                        <td>{val.serialNumber}</td>
                                    </tr>)

                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    )
}