import React, { Fragment, useEffect, useState } from 'react';
import getPropertyValueFromAutoCompleteSuggestions from '../../Functions/getPropertyValue';

export default function CrateWashedAutoComplete(props)
{
  
    const [value, updateValue] = useState({
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: props.value,

        index: props.index,

        dataType: props.dataType,

        code: props.code,

        name: props.name,

        value: props.value,

        data: props.data,

        url: props.url,

        isLoaded: true,

        hierachyType: props.hierachyType,

        suggestions:[]
    });

    useEffect(() => {

        fetchData();

    }, [props.mountCounter]);

    async function fetchData()
    {
            await fetch(props.url)
                .then(response => response.status === 401 ? window.location = "/login" : response.json())
                .then(response => {

                    updateValue({ ...value, isLoaded: true, suggestions: response });

                    if (typeof props.changeState !== 'undefined' && props.changeState != null) {

                        props.changeState();

                    }
                });
    }


    const onChange = (e) => {

        const userInput = e.currentTarget.value;
        // Filter our suggestions that don't contain the user's input

        const filteredSuggestions =value.suggestions.filter(suggestion=> !props.washedCrates.includes(suggestion.code)).filter(
            (suggestion) =>
                suggestion.description.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        updateValue({
            ...value,
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    const onClick = (e) => {

        const code = e.target.getAttribute("code");

        updateValue({
            ...value,
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });

        props.updateValue(code);

        if (typeof props.onChangeCallBack !== 'undefined') {

            props.onChangeCallBack(() => updateValue({ ...value, value: "", userInput: "" }));

        }
    };

    const onKeyDown = (e) => {

        const { activeSuggestion, filteredSuggestions } = { ...value };

        // User pressed the enter key
        if (e.keyCode === 13) {
            updateValue({
                ...value,
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38)
        {
            if (activeSuggestion === 0) {
                return;
            }
            updateValue({ ...value, activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {

            if (activeSuggestion - 1 === filteredSuggestions.length) {

                return;

            }

            updateValue({ ...value, activeSuggestion: activeSuggestion + 1 });
        }
    };

    if (!value.isLoaded) {
        return <div>Loading data</div>;
    } else {

        let suggestionsListComponent;

        if (value.showSuggestions && value.userInput) {
            if (value.filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {typeof value.filteredSuggestions !=="undefined" && value.filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === value.activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li
                                    className={className}
                                    key={suggestion.code}
                                    onClick={onClick}
                                    code={suggestion.code}
                                >
                                    {suggestion.description}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No data Available...</em>
                    </div>
                );
            }
        }

        return (

            <Fragment>

                {/*<input type="button" value="test" onClick={()=>console.log(props.url)}/>*/}

                <input
                    type="text"
                    className="form-control"
                    style={{ minWidth: "250px" }}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    name={value.name}
                    onDoubleClick={() => updateValue({ ...value, value: "", userInput: "" })}
                    value={typeof value.value !== 'undefined' && value.value !== "" && value.value !== null ? getPropertyValueFromAutoCompleteSuggestions(value.value, value.suggestions) : value.userInput}
                    style={props.style}
                    placeholder={props.placeholder}

                />

                {suggestionsListComponent}

            </Fragment>

        );
    }







}