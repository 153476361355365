import { faAdd, faL, faNoteSticky, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import StandardAutoComplete from "../../StandardAutocomplete";
import Evaluators from "./EvaluatorMatrix";




export default function Evaluate(props) {

    const [thisComponentState, updateState] = useState({
        employeeId: "",
        employeeName: "",
        dateHired: "",
        department: "",
        evaluatorId: "",
        evaluator:"",
        showEvaluators:false,
        positionId: "",
        purpose: "",
        comments: "",
        keyStrenghts: "",
        areasForDevelopment: "",
        developmentPlans: "",
        recommendations:"",
        mainFactors: []
    });

    const save = () => {

        var isConfirm = window.confirm("Are you sure you want to save this?");

        if (!isConfirm) {

            return;
        };

        let details = [];

        for (let i = 0; i < thisComponentState.mainFactors.length; i++) {

            for (let j = 0; j < thisComponentState.mainFactors[i].details.length; j++) {

                let data = {
                    PerformanceEvaluatorDetailId: thisComponentState.mainFactors[i].details[j].performanceEvaluatorDetailId,
                    subPerformanceFactorId: thisComponentState.mainFactors[i].details[j].subPerformanceFactorId,
                    value: thisComponentState.mainFactors[i].details[j].score.find(val=>val.isSelected).value
                }

                details.push(data);
            }
        }

        let postBody = {
            employeeId: thisComponentState.employeeId,
            evaluator: thisComponentState.evaluator,
            purpose: thisComponentState.purpose,
            overallComments: thisComponentState.comments,
            keyStrenghts: thisComponentState.keyStrenghts,
            areasForDevelopment: thisComponentState.areasForDevelopment,
            developmentPlans: thisComponentState.developmentPlans,
            recommendations: thisComponentState.recommendations,
            evaluatorId: parseInt(thisComponentState.evaluatorId),
            performanceEvaluationDetails: details
        }


        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/SavePerformanceEvaluation", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }



    const getDataForEvaluation = (code) => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/PerformanceEvaluations/GetDataForEvaluation?" + new URLSearchParams({

            positionId: code

        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    isLoading: false,
                    mainFactors: response,
                    positionId:code
                });

            });
    }

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/PerformanceEvaluations/GetEmployeeData?" + new URLSearchParams({

            employeeId: code

        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,

                    isLoading: false,
                    employeeId: code,
                    employeeName: response.employeeName,
                    dateHired: response.dateHired,
                    department: response.department

                });

            });

    }

    const updatePositionId = (code) => {

        getDataForEvaluation(code);

    }

    const  updateEvaluatorId = (code) => {

        updateState({ ...thisComponentState, evaluatorId: code });
        
    }


    const modifyScore = (mainFactor, guidId) => {

        console.log(thisComponentState);

        let mainFactors = [...thisComponentState.mainFactors];

        let factor = mainFactors.find(val=> val.mainFactor===mainFactor);

        let tempDatas = [...factor.details];

        for (let i = 0; i < tempDatas.length; i++) {

            for (let j = 0; j < tempDatas[i].score.length; j++) {

                if (tempDatas[i].score[j].guidId === guidId) {

                    tempDatas[i].score[j].isSelected = !tempDatas[i].score[j].isSelected;

                    //Make other check boxes to false if this is being selected. 

                    if (tempDatas[i].score[j].isSelected) {

                        tempDatas[i].score.forEach(val => {

                            if (val.guidId !== guidId) {
                                val.isSelected = false;
                            }
                        });
                    }
                }
            }
        };

        updateState({ ...thisComponentState, mainFactors: mainFactors });
    }



    return (
        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Back" onClick={() => props.back()} className="form-control" />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">

                  
                    <div className="row">
                        <div className="col">
                            Employee Id
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.employeeId} className="form-control" disabled />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Employee Name
                        </div>

                        <div className="col">
                            <StandardAutoComplete url="Employees/GetEmployees" value={thisComponentState.employeeId} updateValue={updateEmployeeId} />
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col">
                            Position
                        </div>
                        <div className="col">
                            <StandardAutoComplete url="PerformanceEvaluations/GetPositions" value={thisComponentState.positionId} updateValue={updatePositionId}/>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            Date Hired
                        </div>
                        <div className="col">
                            <input type="date" className="form-control" disabled />
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col">
                            Evaluator Type
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-sm-10">
                                    <StandardAutoComplete url="PerformanceEvaluations/GetEvaluatorTypes" value={thisComponentState.evaluatorId} updateValue={updateEvaluatorId} />
                                </div>

                                <div className="col-sm-2">
                                    <FontAwesomeIcon icon={faAdd} title="Add main factors" style={{ cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showEvaluators: true })} />                               
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            Deparment
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.department} className="form-control" disabled />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Appraisal Period
                        </div>

                        <div className="col">

                            <StandardAutoComplete url="Employees/GetEmployees" value={thisComponentState.employeeId} updateValue={updateEmployeeId} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Appraisal Date
                        </div>

                        <div className="col">
                            <input type="date" className="form-control" disabled />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Appraisal Date
                        </div>

                        <div className="col">

                            <select value={thisComponentState.purpose} className="form-control" onChange={(e) => updateState({ ...thisComponentState, purpose: e.currentTarget.value })} >

                                <option>Regularization</option>
                                <option>Quarterly Evaluation</option>
                                <option>Others</option>

                            </select>

                        </div>
                    </div>

                    <br/>

                    <div className="row">
                        <div className="col">
                           
                        </div>

                        <div className="col">

                            <input value="Save" type="button" onClick={save} className="form-control"/>

                        </div>
                    </div>

                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col">

                    <div>
                        5 = Exceptional
                    </div>
                    <div>
                        4 = Exceeds Expectation
                    </div>
                    <div>
                        3 = Meets Expectation
                    </div>
                    <div>
                        2 = Needs Improvement
                    </div> <div>
                        1 = Poor, Exhibits little or no level of proficiency
                    </div>

                </div>
            </div>

            <hr />




            <div>


                {
                    thisComponentState.mainFactors.length > 0 && thisComponentState.mainFactors.map(val =>

                        <div>

                            <div className="row">
                                <div className="col">
                                    <div className="text-center">
                                        <h2>
                                            {val.mainFactor}
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            { val.details.length > 0 && val.details.map((detail,index) =>
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-md-8">
                                                {index+1}. {detail.name}
                                            </div>

                                            <div className="col-md-4">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="text-center">1</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="text-center">2</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="text-center">3</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="text-center">4</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="text-center">5</div>
                                                    </div>
                                                    <div className="col">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-md-8">
                                                {">"}  {detail.description}
                                            </div>

                                            <div className="col-md-4">
                                                <div className="row">

                                                    {
                                                        detail.score.map(score =>

                                                            <div className="col">
                                                                <input type="checkbox" checked={score.isSelected} onChange={() => modifyScore(val.mainFactor, score.guidId)} className="form-control" />
                                                            </div>
                                                        )

                                                    }

                                                    <div className="col">
                                                        <FontAwesomeIcon icon={faNoteSticky} title="Write Note" style={{ cursor: 'pointer' }} size="2x" color="blue" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                            </div>

                            )
                            }


                        </div>
                    )
                }

            </div>

            <div className="row">
                <div className="col">
                    <div>
                        Comments
                    </div>

                    <textarea className="form-control" onChange={(e) => updateState({...thisComponentState,comments:e.currentTarget.value})}>


                    </textarea>
                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div>
                        Key Strengths
                    </div>

                    <textarea className="form-control" onChange={(e) => updateState({ ...thisComponentState, keyStrenghts: e.currentTarget.value })}>


                    </textarea>
                </div>
                <div className="col">
                    <div>
                        Areas for development
                    </div>

                    <textarea className="form-control">


                    </textarea>
                </div>
                <div className="col">
                    <div>
                        Developments Plan
                    </div>

                    <textarea className="form-control" onChange={(e) => updateState({ ...thisComponentState,developmentPlans: e.currentTarget.value })}>


                    </textarea>
                </div>
            </div>

            <hr/>

            <div className="row" style={{marginBottom:"50px"}} >
                <div className="col">
                    <div>
                        Recommendations
                    </div>

                    <textarea className="form-control" style={{ marginBottom: "50px" }} onChange={(e) => updateState({ ...thisComponentState, recommendations: e.currentTarget.value })}>
                    </textarea>

                </div>

            </div>


        </div>)
}