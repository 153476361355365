import { faBookJournalWhills, faCheck, faInfo, faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import uuid from "react-uuid";


export default function IncentiveList(props) {

    const [thisComponentState, updateState] = useState({
        isLoading:true,
        values: []
    });


    useEffect(() => {

        fetch("/Invoices/GetIncentiveList?")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, values: response, isLoading: false });

            });
    }, []);

    if (thisComponentState.isLoading) {

        return (<div>Computating...</div>)
    }


    return (

        <div>
            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                </div>
               
            </div>

            <hr />



            <div>

                <table className="table table-bordered">
                    <thead>
                        <th>Id</th>
                        <th>Year</th>
                        <th>Month</th>
                        <th>Total</th>
                        <th>Is Audited</th>
                        <th>Is Approved </th>                      
                        <th>Is Journalized</th>
                        <th colSpan="4" className="text-center">Actions</th>
                    </thead>

                    <tbody>

                        {
                            thisComponentState.values.map(data =>
                                <tr>
                                    <td>{data.id}</td>
                                    <td>{data.year }</td>
                                    <td>{data.month }</td>
                                    <td>{data.total}</td>                                  
                                    <td>{data.auditedStat}</td>
                                    <td>{data.approvedStat}</td>
                                    <td>{data.journalizedStat }</td>

                                    <td>
                                        <FontAwesomeIcon icon={faInfoCircle} title="Details" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, showIncentive: true })} />
                                    </td>

                                    <td>
                                        <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, showIncentive: true })} />                                  
                                    </td>

                                    <td>
                                        <FontAwesomeIcon icon={faCheck} title="Audit" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, showIncentive: true })} />
                                    </td>

                                    <td>
                                        <FontAwesomeIcon icon={faBookJournalWhills} title="Journalize" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, showIncentive: true })} />
                                    </td>

                                </tr>)
                        }
                    </tbody>

                </table>


             
            </div>

        </div>

    )

}