import computeProductionDate from "./computeProductionDate";
import searchPrice from "./searchPrice";
import searchUomName from "./searchUomName";

export default function searchFunction(functionName) {

    switch (functionName) {

        case "searchUomName":

            return searchUomName;

        case "searchPrice":

            return searchPrice;

        case "computeProductionDate":

            return computeProductionDate;
    }

    alert("no function found");


}