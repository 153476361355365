
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp,faAddressBook, faSpinner, faRemoveFormat} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';

 
export default function BadOrderListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        approvedTransaction:false,
        documentId: null,
        agentId: null,
        branchId :null,
        fromDate: null,
        toDate: null,
        docStatus:null
    });


    async function search() {

        updateState({ ...thisComponentState,isLoading:true })

        let params = {};

        if (typeof thisComponentState.fromDate !== 'undefined' && thisComponentState.fromDate !== "" && thisComponentState.fromDate !== null && typeof thisComponentState.toDate !== 'undefined' && thisComponentState.toDate !== "" && thisComponentState.toDate !== null) {

            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (typeof thisComponentState.documentId !== 'undefined' && thisComponentState.documentId !== "" && thisComponentState.documentId !== null) {
            params.documentId = thisComponentState.documentId;
        }

        if (typeof thisComponentState.agentId !== 'undefined' && thisComponentState.agentId !== "" && thisComponentState.agentId !== null) {

            params.agentId = thisComponentState.agentId;
        }

        if (typeof thisComponentState.branchId !== 'undefined' && thisComponentState.branchId !== "" && thisComponentState.branchId !== null) {

            params.branchId = thisComponentState.branchId;
        }

        if (typeof thisComponentState.docStatus !== 'undefined' && thisComponentState.docStatus !== "" && thisComponentState.docStatus !== null) {

            params.docStatus = thisComponentState.docStatus;
        }

        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params)).then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(dataFromServer => {

                var tempData = { ...data.metaData };

                tempData.listData = dataFromServer;

                updateMetaData({ metaData: tempData });

                updateState({ ...thisComponentState, isLoading: false })

            });
    };


    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });

    };

    const updateAgentId = (code) => {

        updateState({ ...thisComponentState, agentId: code });
    };

    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            approvedTransaction: false,
            documentId: null,
            agentId: null,
            branchId :null,
            fromDate: null,
            toDate: null,
            docStatus:null
        })
    }


    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/Orders/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>

        );

    };




    return (


          <div style={{ textAlign: 'center' }}>

            <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search}type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />

            <br />
            <br />

            <table className='table table-bordered'>
                <tr>
                    <td>
                        <div>From</div>
                    </td>
                    <td>
                        <input className="form-control" title="From Date" type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    </td>
                </tr>

                <tr>
                    <td>
                        <div>To</div>
                    </td>
                    <td>
                        <input className="form-control" title="To Date" type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                    </td>
                </tr>


                <tr>
                    <td>
                        <div>Document Id</div>
                    </td>
                    <td>
                        <input className="form-control" title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Enter Document Id" />
                    </td>
                </tr>

                <tr>
                    <td>
                        <div>Agents</div>
                    </td>
                    <td>
                        <StandardAutoComplete key={200} url="/Agents/GetAgents" value={thisComponentState.agentId} updateValue={updateAgentId} placeholder="Enter Agents" />
                    </td>
                </tr>

                <tr>
                    <td>
                        <div>Branch</div>
                    </td>
                    <td>
                        <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Branch Search" />
                    </td>
                </tr>

                <tr>
                    <td>
                        <div>Doc Status</div>
                    </td>
                    <td>
                        <select className="form-control" onChange={(e) => updateState({...thisComponentState,docStatus:e.currentTarget.value})}>
                            <option value="" selected>All</option>
                            <option value="O">Open</option>
                            <option value="C">Cancelled</option>
                        </select>
                    </td>
                </tr>
            
              
                <tr>
                    <td>

                    </td>

                    <td>

                    </td>

                </tr>

            </table>

            <br />
            <br />

        </div>



       

    )

}


