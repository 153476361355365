import { useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";

export default function EditWashedHistory(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        agentId: "",
        washedHistoryId:"",
        crateId: "",
        washedHistoryMountCounter: 0,
        cratesHistoryMountCounter:0
    });

    const updateAgentId = (code) => {

        updateState({ ...thisComponentState, agentId: code,washedHistoryMountCounter:thisComponentState.washedHistoryMountCounter+1});

    }

    const updateWashedHistoryId = (code) => {

        updateState({ ...thisComponentState, washedHistoryId: code,cratesHistoryMountCounter:thisComponentState.cratesHistoryMountCounter +1});
    }

    const updateCrateId = (code) => {

        updateState({ ...thisComponentState, crateId: code });

    }


   

    const addItem = () => {

        var isConfirm = window.confirm("Are you sure you want to add this item as Washed?");

        if (!isConfirm) {

            return;

        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        fetch("/Crates/AddItem", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                value: {
                    item1: thisComponentState.agentId,
                    item2: parseInt(thisComponentState.washedHistoryId),
                    item3:parseInt(thisComponentState.crateId)
                }
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);            
            });
    }

    const deleteItem = () => {

        var isConfirm = window.confirm("Are you sure you want to edit this crate?");

        if (!isConfirm) {

            return;

        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        fetch("/Crates/DeleteItem", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                value: {
                    item1: thisComponentState.agentId,
                    item2: parseInt(thisComponentState.washedHistoryId),
                    item3: parseInt(thisComponentState.crateId)
                }
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);
            });
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" type="button" className="form-control" onClick={() => props.back()} />
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col">
                    Agent
                </div>
                <div className="col">
                    <StandardAutoComplete value={thisComponentState.agentId} clear={() => updateState({...thisComponentState,agentId:""})} url="/Agents/GetAgents" updateValue={updateAgentId} />
                </div>
            </div>


            <div className="row">
                <div className="col">
                     Washed Date
                </div>
                <div className="col">
                    <StandardAutoComplete mountCounter={thisComponentState.washedHistoryMountCounter} url="/Crates/GetWashedHistoriesPerAgent" params={{ agentId : thisComponentState.agentId }} updateValue={updateWashedHistoryId} />
                </div>
            </div>


            <div className="row">
                <div className="col">
                    Crate
                </div>
                <div className="col">
                    <StandardAutoComplete mountCounter={thisComponentState.cratesHistoryMountCounter} url="/Crates/GetCrates" params={{ agentId: thisComponentState.agentId}} updateValue={updateCrateId} />
                </div>
            </div>

          
            <br/>

            <div className="row">
                <div className="col">
                    
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            <input type="button" value="Delete" className="form-control" onClick={deleteItem} />
                        </div>

                        <div className="col">
                            <input type="button" value="Add" className="form-control" onClick={addItem} />
                        </div>
                    </div>                   
                </div>
            </div>
        </div>


    )
}