import { useState } from "react";

export default function SourceVariance(props) {
    const [data] = useState(props.data);

    return (
        <div>

            <div className="row">
                <div className="col">
                    <input className="form-control text-center" type="button"  value="Back" onClick={ ()=>props.back()}/>
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Source of Variance
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    {Object.entries(data).map(([key, value]) => (
                        <div key={key} className="row">
                            <div className="col">{key}</div> 
                            <div className="col">{ value< 0? `(${Math.abs(value)})` : value}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
