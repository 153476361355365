import { useEffect } from "react";
import { useState } from "react";
import SrDetails from "../PurchaseRequisition/SrDetails";



export default function ApproveStockRequest(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        selectedGuidId: "",
        list: [],
        showDetails:false

    });

    useEffect(() => {

        retrieve();

    }, []);

    const retrieve = () => {

        fetch("/StockRequisitions/GetStockRequestForApproval?")

            .then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(response => {

                if (response.success) {

                    updateState({
                        ...thisComponentState,
                        list: response.value,
                        isLoading:false
                    })

                    return;

                } else {

                    updateState({...thisComponentState,
                        isLoading: false
                    })

                    return alert(response.message);
                }
            });
    }

    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.showDetails) {

        return (
            <div>
                <div>

                    return (<SrDetails guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false})} />)
                </div>
            </div>
               
        )
    }

    return (

        <div>

            <div className="row">

                <div className="col">
                    <input type="button" className="form-control" onClick={() => props.back()} value="Back" />
                </div>

                <div className="col">

                </div>

            </div>

            <br />

            <div className="row">
                <table className="table table-bordered">

                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Stock Request Id</th>
                            <th>Requestor</th>
                            <th>Request Date</th>
                            <th>Branch</th>
                            <th>Note</th>
                            
                        </tr>

                    </thead>

                    <tbody>

                        {
                            typeof thisComponentState.list !== 'undefined' && thisComponentState.list.map(val =>

                                <tr key={val.guidId}>

                                    <td >
                                        <input type="button" className="form-control" value="Details" onClick={() => updateState({ ...thisComponentState, selectedGuidId: val.guidId, showDetails: true })} />
                                    </td>

                                    <td >
                                        {val.documentId}
                                    </td>

                                    <td >
                                        {val.requestor}
                                    </td>

                                    <td >
                                        {val.requestDate}
                                    </td>

                                    <td >
                                        {val.branch}
                                    </td>

                                    <td >
                                        {val.note}
                                    </td>
                                </tr>

                            )
                        }

                    </tbody>
                </table>
            </div>

        </div>
    )



}