import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";






export default function OpenInventoryFilter(props)
{
    const [thisComponentState, updateState] = useState({

        type:"",
        categoryId: "",
        itemCode: ""

    })

    useEffect(() => {

        props.clearFilter();

    }, []);
   
    const updateCategoryId = (code) => {
        updateState({ ...thisComponentState, categoryId: code });
    }

    const updateInventoryId = (code) => {

        updateState({ ...thisComponentState, itemCode: code });

    }


    const back =() => {

        let criterias = {};

        if (thisComponentState.categoryId !== "") {
            criterias.categoryId = thisComponentState.categoryId;
        };

        if (thisComponentState.itemCode !== "") {
            criterias.itemCode = thisComponentState.itemCode;
        };

        props.clearFilter();
     
        props.filter(criterias);

    }


    return (
        <div>
            <br/>
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("500px", "500px")}>


                    <div className="row">
                        <div className="col">
                            Filter By Category
                        </div>
                        <div className="col">
                            <StandardAutoComplete key={200} url="/Categories/GetCategories" value={thisComponentState.categoryId} clear={() => updateState({ ...thisComponentState, categoryId: "" })} updateValue={updateCategoryId} placeholder="Category Search" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Filter By Category
                        </div>
                        <div className="col">
                            <StandardAutoComplete key={200} url="/Inventories/GetInventories" value={thisComponentState.itemCode} clear={() => updateState({ ...thisComponentState, itemCode: "" })} updateValue={updateInventoryId} placeholder="Item Code Search" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">

                        </div>

                        <div className="col">
                            <input value="Back" type="button" className="form-control" onClick={back} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
   )
}