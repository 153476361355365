import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react"
import { useContext } from "react";
import { useState } from "react"
import uuid from "react-uuid";
import PureInput from "../../PureInput";
import { TransactionContext } from "../TransactionContext";



export default function Canvas(props) {


    const [thisComponentState, updateState] = useState({

        isPerformingOperation: true,
        isUpdating: false,
        data:[]

    })

    useEffect(() => {
 
        fetch("/PurchaseOrders/GetCanvases?" + new URLSearchParams({

            guidId: props.guidId

        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    data: response,
                    isPerformingOperation:false

                });

            });
    },[]);


    const addRow = () => {

        let tempDatas = [...thisComponentState.data];

        tempDatas.push({

            guidId: uuid(),
            parentId:props.guidId,
            supplier: "",
            price: 0,
            remarks:""
        });

        updateState({ ...thisComponentState, data: tempDatas });
    }


    const change = (guidId,name,value) => {

        let tempDatas = [...thisComponentState.data];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData[name] = name=== "price" ? parseFloat(value): value ;

        fetch("/PurchaseOrders/SaveCanvas", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            //body: JSON.stringify(dataToPost),
            body: JSON.stringify(tempData),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (!response.success) {
                    return alert(response.message);
                }

        });
    }


    const deleteItem = (guidId) => {


        fetch("/PurchaseOrders/DeleteCanvas", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            //body: JSON.stringify(dataToPost),
            body: JSON.stringify({
                 value:guidId
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (!response.success) {
                    return alert(response.message);
                }

            });
    }




    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.isUpdating) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Updating...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={()=>props.back()} />
                </div>
            </div>

            <hr/>

            <div className="row">

                <div className="col"> 
                    <table className="table table-bordered">

                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Supplier</th>
                                <th>Price</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>


                        <tbody>

                            {
                                thisComponentState.data.length && thisComponentState.data.map(val =>

                                    <tr>
                                        <td>
                                            <FontAwesomeIcon icon={faTrash} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => deleteItem(val.guidId)} />

                                        </td>
                                        <td>
                                            <PureInput name="supplier" code={val.guidId} updateValue={change} value={val.supplier} />
                                        </td>

                                        <td>
                                            <PureInput name="price" code={val.guidId} updateValue={change} value={val.price} />
                                        </td>

                                        <td>
                                            <PureInput name="remarks" code={val.guidId} updateValue={change} value={val.remarks} />
                                        </td>
                                    </tr>
                                )
                            }


                        </tbody>

                        <tfoot>
                            <tr>
                                <td>
                                    <input type="button" className="form-control" value="Add" onClick={addRow} />
                                </td>
                                <td>

                                </td>
                                <td></td>
                            </tr>

                        </tfoot>

                    </table>
                </div>

               
            </div>

        </div>  


      

    )




}