import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import UserSelection from "../../../Chat/UserSelection";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";



export default function PrDetailsForAuditReview(props)
{
    const [thisComponentState, updateState] = useState({
        isLoading: true,
        showChat: false,
        toAudit:false,
        data: {
            requestor: "",
            requestDate: "",
            requiredDate: "",
            documentId: "",
            note:"",
            details: [],
            revertNote: "",           
            isPerformingOperation:false
        }
    });

    useEffect(() => {

        pullPurchaseRequisition();

    },[]);

    const pullPurchaseRequisition = () => {

        fetch("/PurchaseOrders/GetPrDetails?" + new URLSearchParams({

            guidId: props.guidId

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })
    };


    if (thisComponentState.isLoading) {

        return(<div>Loading...</div>)
    }

    const tagAsAudited = () => {

        var isConfirm = window.confirm("This Purchase Requisition will be tag as audited? Click ok to continue.");

        if (!isConfirm) {

            return;
        };

        if (thisComponentState.auditNote === "")
        {
            return alert("Audit note is required to inform the Pr owner for next step.");
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        var postBody = {
            value : {
                item1: props.guidId,
                item2: thisComponentState.auditNote
            }
        }

    
        fetch("/PurchaseOrders/TagAsAudited", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }

 


    if (thisComponentState.toAudit) {


        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <div className="row">
                        <div className="col">
                            <input value="Back" type="button" className="form-control" onClick={() => updateState({...thisComponentState,toAudit:false}) }/>
                        </div>
                    </div>

                    <hr/>

                    Audit Note

                    <hr/>

                    <div className="row">
                        <div className="col">

                            <textarea className="form-control" value={thisComponentState.auditNote} onChange={(e) => updateState({...thisComponentState,auditNote: e.currentTarget.value }) }>

                                
                            </textarea>

                        </div>  
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col">
                            <input value="Save" type="button" className="form-control" onClick={tagAsAudited} />
                        </div>
                    </div>
                </div>
            </div>
        )

    }


    if (thisComponentState.isPerformingOperation) {


        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }


    return (

        <div>

            <div className="row">
                <div className="col">

                    <input type="button" value="Back" className="form-control" onClick={()=>props.back()}/>

                </div>

                <div className="col">


                    <input type="button" value="Tag As Audited" className="form-control" onClick={() => updateState({...thisComponentState,toAudit:true})} />

         
                </div>

             
            </div>

            <br/>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Requestor</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestor }</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Request Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requiredDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.note}</div>
                </div>
            </div>

            <div className="row">

                <div className="col">
                    <div>Is Reverted </div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.isReverted ? "Yes" : "No" }</div>
                </div>

            </div>

            <br/>

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                             
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Served Quantity</th>
                                <th>UomDesc</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Is Hold</th>
                                <th>Is Cancelled</th>

                            </tr>
                        </thead>

                        <tbody>


                            {
                                thisComponentState.data.details.map(val =>
                                    <tr key={uuid()}>

                                        <td>{val.description}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.servedQuantity}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>
                                        <td>{val.amount}</td>
                                        <td>{val.isHold ? "Y" : "N"}</td>
                                        <td>{val.isCancelled ? "Y":"N"}</td>
                                    </tr>
                                )

                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
   
   )
}