import { Link } from "react-router-dom"

export default function SelectConference() {

    return (


        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Link to={{ pathname: '/ConferenceBooking/Pico', state: { type: "Janitorial" } }} style={{ textDecoration: "none", fontSize: "100px", fontFamily: "Arial", marginBottom: "20px" }}>
                Pico Conference
            </Link>
            <Link to={{ pathname: '/ConferenceBooking/Motorpool' }} style={{ textDecoration: "none", fontSize: "100px", fontFamily: "Arial" }}>
                Motorpool Conference
            </Link>
        </div>

    )



}