import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import StandardAutoComplete from "../../StandardAutocomplete";
import { TransactionContext } from "../TransactionContext";




export default function SelectApPerVendor(props)
{

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        code: "",
        apSapId :0

    });

    useEffect(() => {



    }, [thisComponentState.code]);

    const updateCode = (code) => {

        updateState({ ...thisComponentState, code: code });
    }

    const updateApNumber = (id) => {

        updateState({ ...thisComponentState, apSapId: id });

    }

    const back = () => {

        let tempData = { ...data.metaData };

        tempData.parentProperties.code = thisComponentState.code;
        tempData.parentProperties.apSapId = thisComponentState.apSapId;

        updateMetaData({ ...data, metaData: tempData });
            
        props.back();
    }


    return (

        <div>
            <div className="row">
                <div className="col">
                    Vendor
                </div>

                <div className="col">

                    <StandardAutoComplete url="Vendors/GetVendors" updateValue={updateCode } />

                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col">
                    Ap Number
                </div>

                <div className="col">

                    <StandardAutoComplete url="ApCreditMemos/GetApNumbers" mountCounter={thisComponentState.code} params={{code: thisComponentState.code }} updateValue={updateApNumber} />

                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                </div>

                <div className="col">

                    <input type="button" className="form-control" value="Ok" onClick={back} />

                </div>
            </div>

        </div>
        
        
        
   )



}