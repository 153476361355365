import { faFile, faFolderOpen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useContext, useState } from 'react';
import uuid from 'react-uuid';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import ReturnDrafts from './ReturnDrafts';


function CreateReturnNavigator(props) {

    const { data } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState ({
        showDrafts:false
    });

    const draftIt = () => {

        if (typeof data.metaData.parentProperties.agentCode === 'undefined' || data.metaData.parentProperties.agentCode === null) {

            return alert("Please select an agent.");
        }

        var toSave = window.confirm("Are you sure you want to save this as draft?");

        if (!toSave) {

            return;

        }

        var draft = {};

        draft.guidId =uuid();
        draft.preceedingTransactionGuids = data.metaData.preceedingTransactionGuids;

        draft.parentProperties = data.metaData.parentProperties;
        draft.detailProperties = data.metaData.detailProperties;

        var drafts = localStorage.getItem("returnDrafts");

        var masterfiles = localStorage.getItem("masterfiles");

        if (masterfiles !== null) {

            var pMasterfiles = JSON.parse(masterfiles);

            var agent = pMasterfiles.agents.find(val => val.code === draft.parentProperties.agentCode);

            draft.agent = agent.name;

        }

        if (drafts !== null) {

            var tempDrafts = JSON.parse(drafts);

            tempDrafts.push(draft);

            localStorage.setItem("returnDrafts", JSON.stringify(tempDrafts));

            return;
        }

        drafts = [];

        drafts.push(draft);

        localStorage.setItem("returnDrafts", JSON.stringify(drafts));
    }


    if (thisComponentState.showDrafts) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <ReturnDrafts back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )

    }



  
    return (

        <div style={{ float: "left" }}>
        
            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />     

            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
           

        </div>

    )

}


export default CreateReturnNavigator;