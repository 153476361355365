
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';


export default function WashedCratesDrafts(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        values: [],
        toReload:0
    });

    useEffect(() => {
        fetch("/Crates/GetDrafts")

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    values: response,
                    isLoading: false

                });

            });
    }, [thisComponentState.toReload]);

    const select = (val) =>
    {
        props.onSelect(val);
    }

    const deleteItem =(id) => {

        let confirm = window.confirm("Are you sure you want to delete this? Click ok to continue...");

        if (!confirm) {
            return;
        }

        fetch("/Crates/DeleteDraft", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                value:id
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {
                updateState({ ...thisComponentState, toReload: thisComponentState.toReload + 1 });
                return alert(response.message);
            });
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>
            <div>

                <div className="row">

                    <div className="col">
                    </div>

                    <div className="col">
                        <input value="Back" className="form-control" type="button" onClick={() => props.back()} />
                    </div>

                    <div className="col">
                    </div>

                </div>

                <br/>

                <div className='row'>

                    <div className='col'>
                        <table className='table table-bordered'>

                            <thead>

                                <tr>
                                    <th colSpan="2">Action</th>
                                    <th>Agent</th>
                                    <th>Washed Date</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    thisComponentState.values.map((val) =>

                                        <tr key={uuid()}>
                                          
                                            <td>
                                                <input type='button' value='Delete' className='form-control' onClick={() => deleteItem(val.id)} />
                                            </td>

                                            <td>
                                                <input type='button' value='Select' className='form-control' onClick={() => select(val)} />
                                            </td>

                                            <td>{val.agent.name}</td>
                                            <td>{val.presentableDate} </td>

                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>

                  
                </div>

               
            </div>
        </div>  
        
        
  )

}