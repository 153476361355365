import React, { useEffect, useState } from 'react';
import createParentProperties from '../Functions/createParentProperties';
//import createParentProperties from '../../Functions/createParentProperties';
import MasterfileContextProvider from './MasterfileContextProvider';
import MasterfileForm from './MasterfileForm';


function Branches() {

    const postUrl = "/Branches/Create";
    const listUrl = "/Branches/Index";


    const [data, updateMetaData] = useState({

        metaData: {}

    });

    const [isLoading, updateLoadingStatus] = useState(true);

    useEffect(() => {

        getMetaData("/Branches/Create");

    }, []);


    async function getMetaData(url) {

        await fetch(url)

            .then(response => response.json())
            .then(data => {


                var tempMetaData = { ...data.metaData };

                tempMetaData.parentMetaData = data.parentMetaData;
                tempMetaData.parentProperties = createParentProperties(data.parentMetaData);
                tempMetaData.postUrl = postUrl;
                tempMetaData.detailsUrl = listUrl;

                updateMetaData({ metaData: tempMetaData, isLoading: false });

                updateLoadingStatus(false);

            });
    }




    if (isLoading) {

        return (<div>Loading data...</div>)

    } else {

        return (

            <MasterfileContextProvider metaData={data.metaData} postUrl={postUrl} listUrl={listUrl}>

                <MasterfileForm />

            </MasterfileContextProvider>


        )

    }
}


export default Branches;