
import { useState } from "react";
import uuid from "react-uuid";
import getModalStyle from "../../Functions/getModalStyle";
import getModalContentStyle from "../../Functions/getModalContentStyle";


import PoOwnInput from "./PoOwnInput"
import StandardAutoComplete from "../../StandardAutocomplete";

export default function DieselRequestDetails(props)
{
    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        toSelectVendor: false,
        selectedVendor:"",
        purchaseRequisition: {
            requestorId:props.value.code,
            requestor: props.value.requestor,
            requestDate:props.value.requestDate,
            requiredDate: props.value.requiredDate,
            prequestDate : props.value.prequestDate,
            prequiredDate :props.value.prequiredDate,
            details: props.value.details,
            documentId: props.value.documentId,
            note:props.value.note
        },
        dataToPost: {}
    });

    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }

    const onChangeQty = (guidOfPrDetail, value) => {

        let purchaseRequisition = { ...thisComponentState.purchaseRequisition };

        let tempData = purchaseRequisition.details.find(val => val.guidOfPrDetail ===guidOfPrDetail);

        tempData.quantity = parseFloat(value);

        updateState({ ...thisComponentState, purchaseRequisition: purchaseRequisition });

    }


    const onChangePrice = (guidOfPrDetail, value) => {

        let purchaseRequisition = { ...thisComponentState.purchaseRequisition };

        let tempData = purchaseRequisition.details.find(val => val.guidOfPrDetail === guidOfPrDetail);

        tempData.price = parseFloat(value);

        updateState({ ...thisComponentState, purchaseRequisition: purchaseRequisition });

    }

    const updateVendorCode = (code) => {

        updateState({ ...thisComponentState,selectedVendor:code});

    }

    const createPo = () => {

        updateState({ ...thisComponentState,isPerformingOperation:true });

        let dataToPost = thisComponentState.dataToPost;

        dataToPost.transaction.code = thisComponentState.selectedVendor;

        fetch("/PurchaseOrders/Validate", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            //body: JSON.stringify(dataToPost),
            body: JSON.stringify(dataToPost),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(json => {

                if (json.length > 0) {

                    updateErrors({ ...errors, hasErrors: true, showErrors: true, errors: json });

                    updateSaving(false);

                    return;

                } else {

                    fetch("/PurchaseOrders/Create", {

                        // Adding method type
                        method: "POST",

                        // Adding body or contents to send
                        body: JSON.stringify(dataToPost),

                        // Adding headers to the request
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                        .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                        .then(json => {

                            updateState({ ...thisComponentState, isPerformingOperation: false,toSelectVendor:false });

                            if (json.success) {

                                let tempData = { ...thisComponentState.purchaseRequisition };

                                tempData.details.forEach(val => {

                                    if (val.guidOfPrDetail == json.guidOfPrDetail) {

                                        val.poGuid = json.guidId;
                                        val.isPoCreated = true;
                                    }
                                });

                                updateState({ ...thisComponentState, purchaseRequisition: tempData});
                            }

                            return alert(json.message);
                        });
                }
            });
    }

    const onSelect = (val) => {

        let details = [];

        details.push(val);

        let purchaserOrder = {

            date: thisComponentState.purchaseRequisition.requestDate,
            requestDate: thisComponentState.purchaseRequisition.requestDate,
            requiredDate: thisComponentState.purchaseRequisition.requiredDate,
            branchId: "HO",
            note :"Daily_Diesel "+val.description,
            reviewedBy: "Melonie Del Rosario",
            approvedBy: "Eric Dion Espadero",
            requestedBy: thisComponentState.purchaseRequisition.requestor,
            details: details
        }

        let dataToPost = {
            transaction: purchaserOrder
        };

        updateState({ ...thisComponentState, dataToPost: dataToPost,toSelectVendor:true});
    }

    const print = (guidId) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/PurchaseOrders/PreviewWithCookie?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    if (thisComponentState.toSelectVendor)
    {
        return (
            <div style={getModalStyle() }>
                <div style={getModalContentStyle("500px", "400px")}>

                    <div className="row">
              
                        <div className="col">
                            <input value="Back" className="form-control" onClick={() => updateState({...thisComponentState, toSelectVendor:false })} type="button" />
                        </div>
                    </div>

                    <hr />

                    <div className="row">

                        <div className="col">
                            Vendor
                        </div>

                        <div className="col">
                            <StandardAutoComplete url="/Vendors/GetVendors" value={thisComponentState.selectedVendor} updateValue={updateVendorCode} />
                        </div>

                    </div>

                    <hr />

                    <div className="row">
                        <div className="col">      
                        </div>
                        <div className="col">
                            <input value="Create" className="form-control" onClick={createPo} type="button"/>
                        </div>
                    </div>

                </div>
        </div>)

    }


    return (

        <div>

            <div className="row">
                <div className="col">

                    <input type="button" value="Back" className="form-control" onClick={()=>props.back()}/>

                </div>

            </div>

            <br/>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.purchaseRequisition.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Requestor</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.purchaseRequisition.requestor }</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Request Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.purchaseRequisition.prequestDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.purchaseRequisition.prequiredDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.purchaseRequisition.note}</div>
                </div>
            </div>

            <br/>

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Description</th>
                                <th>Quantity</th>                      
                                <th>UomDesc</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.purchaseRequisition.details.map(val =>
                                    <tr key={uuid()}>
                                        <td>{val.code}</td>
                                        <td>{val.description}</td>
                                        <td>                    
                                            <PoOwnInput identifier={val.guidOfPrDetail} value={val.quantity} capture={onChangeQty} />
                                        </td>
                                        <td>{val.uomDesc}</td>
                                        <td>
                                            
                                            <PoOwnInput identifier={val.guidOfPrDetail} value={val.price} capture={onChangePrice} />

                                        </td>

                                        <td>{val.quantity * val.price}</td>


                                        <td>
                                            {
                                                val.isPoCreated ? <input type="button" value="Print" onClick={() => print(val.poGuid)} className="form-control" /> : <input type="button" value="Create" onClick={() => onSelect(val)} className="form-control" />
                                            }
                                           
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
   
   )
}