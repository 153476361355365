
import React, {useContext, useEffect, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import { faBuilding, faCoffee, faFile, faFileExcel, faFolderOpen, faHand, faHandPointUp, faInfo, faInfoCircle, faLevelUp, faLink, faListAlt, faMessage, faPencil, faPlus, faRedo, faShare, faUpload, faWarehouse, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SrForClosure from './SrForClosure';
import ApproveStockRequest from './ApproveStockRequest';
import SrOwnCapture from './SrOwnCapture';
import UploadPromptOption from '../../../UploadPromptOption';
import RequestForNewInventory from '../../Masterfiles/Inventory/RequestForNewInventory';
import Drafts from '../Drafts';
import ListOfRequestForApproval from './ListRequestForApproval';
import Messages from './Messages';
import { useParams } from 'react-router';
import { toCsvString } from '../../Functions/toCsvString';
import exportToCSV from '../../Functions/exportToCSV';
import OnHandInventory from '../../Reports/OnHandInventory';
import ListOfUnAcceptedSt from './ListOfUnAcceptedSt';

export default function CreateStockRequisitionNavigator(props) {

    const { data,updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        showOnHand: false,
        requiredDate: "",
        isPerformingOperation:false,
        data: {},
        retrieving: false,
        showRawMatSummary: false,
        showSrForClosure: false,
        showSrForApproval: false,
        showSrOwnCapture: false,
        showDrafts:false,
        isGeneratingPDF:false,
        previewCommissary: false,
        previewWarehouse: false,
        uploadFile: false,
        toApprove:false,
        section: "VB",
        requestItemForCoding: false,
        showUnacceptedSt:false

    });


    let { parameter } = useParams();

    useEffect(() => {

        let tempData = { ...data.metaData };
      
        tempData.parentProperties.requestType = parameter;

        updateMetaData({...data,metaData:tempData});

    }, [])
    
    const previewCommissary = () => {

        updateState({ ...thisComponentState,isGeneratingPDF: true });

        fetch("/StockRequisitions/PrintRequest?date=" + thisComponentState.requiredDate+"&division=commissary&" +"section="+thisComponentState.section,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);

                window.open(file);

            });
    }


    const previewWarehouse = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        fetch("/StockRequisitions/PrintRequest?date=" + thisComponentState.requiredDate + "&division=warehouse",
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);

                window.open(file);

            });
    }

    const draftIt = () => {

        let value = data.metaData.parentProperties;

        value.details = data.metaData.detailProperties;

        let draft = {
            type: 0,
            value: JSON.stringify(value)
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Base/SaveDraft", {
            method: "post",
            body: JSON.stringify(draft),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    const action = (files) => {
        let container = [];

        // FileReader Object
        let reader = new FileReader();

        // Load event
        reader.onload = function (event) {

            // Read file data
            let csvdata = event.target.result;

            // Split by line break to gets rows Array
            let rowData = csvdata.split('\n');

            // Loop on the row Array (change row=0 if you also want to read 1st row)
            for (var row = 0; row < rowData.length; row++) {

                // Split by comma (,) to get column Array
                let rowColData = rowData[row].split(',');

                let object = {};

                let insert = true;
                // Loop on the row column Array
                for (var col = 0; col < rowColData.length; col++) {

                    if (row > 0) {

                        object["'" + rowData[0].split(',')[col] + "'"] = rowColData[col];
                    }
                }

                if (row > 0 && rowData[row].split(',').length > 1) {
                    container.push(object);
                }
            }
        };

        // Read file as string 
        reader.readAsText(files[0]);
    }




    if (thisComponentState.previewCommissary) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle("500px","500px")}>

                    <div className="row">
                        <div className="col">
                            Date
                        </div>
                        <div className="col">
                            <input type="date" className="form-control" value={thisComponentState.requiredDate} onChange={(e) => updateState({...thisComponentState,requiredDate:e.currentTarget.value}) }/>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            Section
                        </div>

                        <div className="col">
                            <select value={thisComponentState.section} className="form-control" onChange={(e) => updateState({...thisComponentState, section:e.currentTarget.value })}>
                                    <option value="VB">VB</option>
                                    <option value="JW">JW</option>                  
                            </select>
                        </div>
                    </div>

                    <br/>
                    <div className="row">
                        <div className="col">
                            <input type="button" className="form-control" value="Back" onClick={() => updateState({...thisComponentState,previewCommissary:false }) }/>
                        </div>
                        <div className="col">
                            <input type="button" className="form-control" value="Print" onClick={previewCommissary} />
                        </div>
                    </div>

                </div>
            </div>
       )
    }




    if (thisComponentState.previewWarehouse) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle("500px", "500px")}>

                    <div className="row">

                        <div className="col">
                            Date
                        </div>

                        <div className="col">
                            <input type="date" className="form-control" value={thisComponentState.requiredDate} onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} />
                        </div>

                    </div>

                    <br />

                    <div className="row">

                        <div className="col">
                            <input type="button" className="form-control" value="Back" onClick={() => updateState({ ...thisComponentState,previewWarehouse: false })} />
                        </div>

                        <div className="col">
                            <input type="button" className="form-control" value="Print" onClick={previewWarehouse} />
                        </div>

                    </div>

                </div>
            </div>
        )
    }



    if (thisComponentState.retrieving) {
        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Retrieving...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.showOnHand)
    {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>  
                    <OnHandInventory
                        back={() => updateState({ ...thisComponentState, showOnHand: false })}
                    />
               </div>     
            </div>
        )
    }


    if (thisComponentState.showSrForClosure) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <SrForClosure back={() => updateState({ ...thisComponentState, showSrForClosure: false })} />

                </div>
            </div>

        )
    }



    if (thisComponentState.showSrForApproval) {

        return (
            
                <div style={getModalStyle()}>
                    <div style={getModalContentStyle()}>                  

                        <ApproveStockRequest back={() => updateState({ ...thisComponentState, showSrForApproval: false })} />

                    </div>
                </div>

            )
    }



    if (thisComponentState.showSrOwnCapture) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <SrOwnCapture back={() => updateState({ ...thisComponentState, showSrOwnCapture: false })} />
                    
                </div>
            </div>
        )
    }

    if (thisComponentState.uploadFile) {

        return (

            <div style={getModalStyle()}>

                <div style={getModalContentStyle() }>

                    <UploadPromptOption back={() => updateState({...thisComponentState,uploadFile:false})} action={action} fileName="StockRequisitionTemplate" csvString="Code,Description,Quantity,UomEntry,Price" />

                </div>
            </div>  
        )
    }


    if (thisComponentState.showDrafts)
    {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Drafts type={0} back={() => updateState({ ...thisComponentState, showDrafts: false })} />                
                </div>
            </div>
        )
    }


    if (thisComponentState.requestItemForCoding) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <RequestForNewInventory back={() => updateState({...thisComponentState,requestItemForCoding:false})}/>
                </div>
            </div>
       )
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.toApprove) {


        return (         
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ListOfRequestForApproval back={() => updateState({...thisComponentState,toApprove:false})}/>

                </div>
            </div> )  
    }

    if (thisComponentState.showMessages) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <Messages back={() => updateState({ ...thisComponentState,showMessages: false })} />

                </div>
            </div>)
    }

    if (thisComponentState.showUnacceptedSt) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ListOfUnAcceptedSt back={() => updateState({...thisComponentState,showUnacceptedSt:false}) }/>

                </div>
            </div>)
    }



    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailProperties);

            exportToCSV("data", csvString);
        };
    }

    

    return (

        <div style={{ float: "left" }}>
                
                <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
                <FontAwesomeIcon icon={faBuilding} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({...thisComponentState,showOnHand:true})} />
                <FontAwesomeIcon icon={faWindowRestore} title="Enter data" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, showSrOwnCapture: true })} />         
                <FontAwesomeIcon icon={faInfo} title="Materials Requirement Planning" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => { updateGoToMRP(true); data.detailProperties = []; }} />
                <FontAwesomeIcon icon={faLink} title="Relationship Map" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" />
                <FontAwesomeIcon icon={faListAlt} title="List of Sr to Close" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showSrForClosure: true })} />
                <FontAwesomeIcon icon={faListAlt} title="List of Unaccepted St" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showUnacceptedSt: true })} />
                <FontAwesomeIcon icon={faHandPointUp} title="Stock Request for Approval" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, showSrForApproval: true })} />
                <FontAwesomeIcon icon={faCoffee} title="Summary request for commissary" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="pink" onClick={() => updateState({...thisComponentState,previewCommissary:true})} />
                <FontAwesomeIcon icon={faWarehouse} title="Summary request for warehouse" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState,previewWarehouse: true })} />
                <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="brown" onClick={draftIt} />
                <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({...thisComponentState,showDrafts:true})} />
                <FontAwesomeIcon icon={faUpload} title="Upload CSV" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="brown" onClick={() => updateState({ ...thisComponentState, uploadFile: true })} />
                <FontAwesomeIcon icon={faPencil} title="Request item for coding" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, requestItemForCoding: true })} />
                <FontAwesomeIcon icon={faShare} title="List of items for approval" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, toApprove: true })} />
                <FontAwesomeIcon icon={faMessage} title="Messages from warehouse" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showMessages: true })} />
                <FontAwesomeIcon icon={faFileExcel} title="Download excel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

            <br />

        </div>

    )
    
}

