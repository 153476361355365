import { useEffect } from "react";
import { useState } from "react";



export default function RevertedPrDetails(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: {
            requestor: null,
            requestDate: null,
            requiredDate: null,
            documentId: null,
            details: []
        }
    });


    useEffect(() => {

        pullPurchaseRequisition();

    }, []);


    const pullPurchaseRequisition = () => {

        fetch("/PurchaseRequisitions/GetPrDetails?" + new URLSearchParams({

            guidId: props.guidId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })

    };

    const reforward = (guidId) => {

        const confirm = window.confirm("Are you sure you want to reforward this document to purchasing deparment?");

        if (!confirm) {

            return;
        }

      
        fetch("/PurchaseRequisitions/ReForward", {
            method: "post",
            body: JSON.stringify({
                value:props.guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

            });
    }


    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    return (

        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                </div>

                <div className="col">
                    <input type="button" value="Re-forward" className="form-control" onClick={reforward} />

                </div>

                <div className="col">                    
                </div>

                <div className="col"></div>

            </div>

            <br />

            <div className="row">
                <div className="col">
                    <div>Requestor</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestor}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Request Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requiredDate}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.revertDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.note}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.revertNote}</div>
                </div>
            </div>
            <br />

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Served Quantity</th>
                                <th>UomDesc</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.details.map(val =>
                                    <tr>
                                        <td>{val.description}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.servedQuantity}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>
                                        <td>{val.amount}</td>

                                    </tr>
                                )

                            }

                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    )
}