import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import PureInput from "../../PureInput";
import StandardAutoComplete from "../../StandardAutocomplete";
import MainFactors from "./MainFactors";
import Positions from "./Positions";



export default function DefineSubfactors(props) {

    const [thisComponentState, updateState] = useState({

        isSaving: false,
        positionId: "",
        mainPerformanceFactorId: "",
        weight:0,
        subFactors: [],
        showMainFactors: false,
        showPositions: false,
        reload:0

    });

    useEffect(() => {



    }, [thisComponentState.reload]);

    const disallowedSpecialCharsRegex = /[!@#$%^&*()_+=[\]{};:'"<>/?\\|]+/;

    const isInputValid = (input) => {
        return !disallowedSpecialCharsRegex.test(input);
    }


    const onUpdate = (guidId, name, value) => {

        let tempDatas = [...thisComponentState.subFactors];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        if (!isInputValid(value)) {

            return alert("Invalid character detected.")
        }

        tempData[name] = value;

        updateState({ ...thisComponentState, subFactors: tempDatas });
    }



    const add = () => {

        let tempDatas = [...thisComponentState.subFactors];

        tempDatas.push({ id:0,guidId: uuid(), name: "",description:""});

        updateState({ ...thisComponentState, subFactors: tempDatas });

    }



    const deleteItem = (guidId) => {

        let tempDatas = [...thisComponentState.subFactors];

        tempDatas = tempDatas.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, subFactors: tempDatas });

    }



    const handleFileSelect = (e) => {

        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = function (e) {

            const contents = e.target.result;
            processCsv(contents);
        };

        reader.readAsText(file);
    }


    const processCsv = (csv) => {

        const lines = csv.split('\n');

        let tempDatas = [...thisComponentState.subFactors];

        for (let i = 0; i < lines.length; i++) {

            const value = lines[i].split(",");

            const entry = {
                guidId: uuid(),
                name:value[0],
                description:value[1]
            };

            tempDatas.push(entry);
        }

        tempDatas = tempDatas.filter(val => val.name !== '');

        updateState({ ...thisComponentState, subFactors: tempDatas });

    }

    const updatePositionId = (code) => {

        updateState({ ...thisComponentState, positionId: code });

    }

    const updateMainPerformanceFactorId = (code) => {

        updateState({ ...thisComponentState, mainPerformanceFactorId: code });

    }


    const save = () => {

        var isConfirm = window.confirm("Are you sure you want to save this?");

        if (thisComponentState.positionId === "") {
            return alert("Please define position.");
        };

        if (thisComponentState.mainPerformanceFactorId === "") {
            return alert("Please define main factor.");
        }


        if (!isConfirm) {

            return;
        };

        let postBody = {

            positionId: parseInt(thisComponentState.positionId),
            mainPerformanceFactorId: parseInt(thisComponentState.mainPerformanceFactorId),
            weight: parseFloat(thisComponentState.weight),
            subPerformanceFactors: thisComponentState.subFactors.map(val => ({

                positionId: parseInt(thisComponentState.positionId),
                mainPerformanceFactorId: parseInt(thisComponentState.mainPerformanceFactorId),
                name: val.name,
                description: val.description

            }))
        }

        updateState({ ...thisComponentState, isSaving: true });

        fetch("/PerformanceEvaluations/SavePerformanceFactorPerPosition", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {


                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isSaving: false })

                return alert(response.message);
           
            });
    }

    

    if (thisComponentState.showMainFactors)
    {

        return (<MainFactors back={() => updateState({...thisComponentState,showMainFactors:false,reload:thisComponentState.reload+1})}/>);
    }

    if (thisComponentState.showPositions) {

        return (<Positions back={() => updateState({...thisComponentState,showPositions:false,reload:thisComponentState.reload+1})} />);
    }

    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (
        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                </div>
                <div className="col">
                    <input type="file" className="form-control" onChange={handleFileSelect}/>
                </div>
            </div>

            <hr />
            <div className="row">
                <div className="col">
                    Position
                </div>
                <div className="col">
                    <StandardAutoComplete url="PerformanceEvaluations/GetPositions" value={thisComponentState.positionId} updateValue={updatePositionId} />
                </div>
                <div className="col">
                    <div className="text-left">
                        <FontAwesomeIcon icon={faAdd} title="Add positions" style={{ cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showPositions: true })} />
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col">
                    Main Factor
                </div>
                <div className="col">              
                    <StandardAutoComplete url="PerformanceEvaluations/GetMainFactors" value={thisComponentState.positionId} updateValue={updateMainPerformanceFactorId} />
                </div>
                <div className="col">
                    <div className="text-left">
                        <FontAwesomeIcon icon={faAdd} title="Add main factors" style={{ cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState,showMainFactors: true })} />
                    </div>
                </div>

            </div>



            <div className="row">
                <div className="col">
                    Main Factor Weight
                </div>
                <div className="col">
                    <input type="number" className="form-control" value={thisComponentState.weight} onChange={(e) => updateState({...thisComponentState,weight: e.currentTarget.value})} />
                </div>
                <div className="col">
                    <div className="text-left">
                       
                    </div>
                </div>

            </div>

            <hr />
            <div className="row">
                <div className="col-md-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="col-md-1">Action</th>
                                <th className="col-md-1">Id</th>
                                <th className="col-md-1">Name</th>
                                <th className="col-md-8">Description</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.subFactors.length > 0 && thisComponentState.subFactors.map((val, index) => <tr>
                                    <td >
                                        <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => deleteItem(val.guidId)} />
                                    </td>

                                    <td >
                                        <div>
                                            {index + 1}
                                        </div>
                                    </td>

                                    <td >
                                        <div>
                                            <PureInput type="text" value={val.name} code={val.guidId} name="name" updateValue={onUpdate} />
                                        </div>
                                    </td>
                                    <td >
                                        <div>
                                            <PureInput type="text" value={val.description} code={val.guidId} name="description" updateValue={onUpdate} />
                                        </div>
                                    </td>

                                </tr>)
                            }


                        </tbody>

                        <tfoot>
                            <tr>

                                <td>
                                    <input type="button" value="Add" className="form-control" onClick={add} />
                                </td>

                                <td>
                                    <input type="button" value="Save" className="form-control" onClick={save} />
                                </td>

                                <td>

                                </td>
                                <td>

                                </td>
                            </tr >

                        </tfoot>

                    </table>

                </div>
            </div>

        </div>


    )
}