import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';


export default  function ApCreditMemoListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        documentId:"",
        code: "",
        docStatus: "",
        fromDate: "",
        toDate: "",
        sapId:""
      
    });


    async function search() {
     
        if (thisComponentState.fromDate > thisComponentState.toDate)
        {
            return alert ("From date should be less than to date");
        };
    
        let params = {};

        if (thisComponentState.fromDate !== "" && thisComponentState.toDate !== "")
        {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (thisComponentState.documentId !== "" ) {
            params.documentId = thisComponentState.documentId;
        }

        if (thisComponentState.code !== "") {

            params.code = thisComponentState.code;
        }

        if (thisComponentState.docStatus != "") {

            params.docStatus = thisComponentState.docStatus;
        }

        if (thisComponentState.sapId != "") {
            params.sapId = thisComponentState.sapId;
        }

        updateState({ ...thisComponentState, isLoading: true })

        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params))
        .then(response =>

            response.status == 401 ? window.location = "/Login" : response.json()

        ).then(response => {

            const dataFromServer = response;

            var tempData = { ...data.metaData };

            tempData.listData = dataFromServer;

            updateMetaData({ metaData: tempData });

            updateState({ ...thisComponentState, isLoading: false });

        });

    };


    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            documentId:"",
            code: "",
            fromDate: "",
            toDate: "",
            docStatus: "",
            sapId:""
        })
    }


    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    const updateCodeId = (code) => {

        updateState({ ...thisComponentState, code: code });

    };


    return (

        <div style={{ textAlign : 'center' }}>

            <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />

            <br/>
            <br />

            <table className='table table-bordered'>

                <tbody>
                    <tr>
                        <td>
                            <div>From</div>
                        </td>
                        <td>
                            <input className="form-control" title="From Date" type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>To</div>
                        </td>
                        <td>
                            <input className="form-control" title="To Date" type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Document Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Doc Id search" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>Sap Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState, sapId: e.currentTarget.value })} placeholder="Sap Id search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Vendor</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={200} url="/Vendors/GetVendors" value={null} updateValue={updateCodeId} placeholder="Vendor Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Doc Status</div>
                        </td>
                        <td>
                            <select className="form-control">
                                <option value="" selected>All</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="NotCancelled">Not Cancelled</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td>

                        </td>

                        <td>

                        </td>

                    </tr>
                    </tbody>
            </table>

            <br />
            <br/>
               
        </div>
    )
}

