import React from 'react';
import { faArrowRight, faVolumeLow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState } from 'react';
import { useEffect } from 'react';


import SchedulerViewer from '../../../SchedulerViewer';
import SetMeetingSchedule from './SetMeetingSchedule';
import { useParams } from 'react-router';


export default function ConferenceBooking(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: true,
        setSchedule: false,
        toReload :0

    });

    let { parameter } = useParams();

    useEffect(() => {


    }, [thisComponentState.toReload]);

 
    if (thisComponentState.setSchedule)
    {
        return (<SetMeetingSchedule back={() => updateState({ ...thisComponentState, setSchedule: false, toReload: thisComponentState.toReload + 1 })} location={parameter} />)
    }


    return (

        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div style={{ marginRight: '10px' }}>
                    <input value={parameter +" Schedules"} type="button" style={{ width: "150px" }} className="form-control" onClick={() => props.back()} disabled />
                </div>

                <div style={{ marginLeft: '10px' }}>
                    <input value="Set Schedule" type="button" style={{ width: "150px" }} className="form-control" onClick={() => updateState({ ...thisComponentState, setSchedule: true })} />
                </div>
            </div>

           <br/>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto' }}>
                <div style={{ width:'100%', height: '800px', overflow: 'auto' }}>
                    <SchedulerViewer type="conference" url={"/bookings/create?resource=" + parameter} toReload={() => updateState({ ...thisComponentState, toReload: thisComponentState.toReload + 1 })} resource={parameter} />
                </div>
            </div>

        </div>  

    )
}