import { useEffect, useState } from "react";
import getTotal from "../../Functions/getTotal";

export default function Overall(props) {

    const [items, setItems] = useState([]);

    const [data, updateData] = useState(props.data);

    useEffect(() => {

        if (props.data && props.data.length > 0) {

            const uniqueItemCodes = Array.from(new Set(props.data.map(val => val.itemCode)));
            const uniqueItems = uniqueItemCodes.map(itemCode => {
                const item = props.data.find(val => val.itemCode === itemCode);
                return { itemCode: item.itemCode, itemName: item.itemName };
            });

            setItems(uniqueItems);
        }
    }, [data]);

    const getBegInv = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && (val.identifier === "BegInventory" || val.identifier==="YesterdaysReturns")).map(val=>val.quantity); 

        return getTotal(result);
    }

    const getProduction = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "Production").map(val => val.quantity);

        console.log(result);

        return getTotal(result);
    }

    const getGoodIssues = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "GoodsIssue").map(val => val.quantity);

        return getTotal(result);
    }
    const getTransfer = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "BreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getEndInv = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && (val.identifier === "EndingInventory" || val.identifier==="Returns")).map(val => val.quantity);

        return getTotal(result);
    }
    const getNetTransfer = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "NetBreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getInvoice = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "Invoice").map(val => val.quantity);

        return getTotal(result);
    }


    const getVariance = (itemCode) => {

        return getEndInv(itemCode) - (getBegInv(itemCode) + getProduction(itemCode) - getGoodIssues(itemCode) - getInvoice(itemCode));


    }

    const computeWithVarianceOnly = () => {

        let tempData = [...data];

        let withVarianceOnly = tempData.filter(function (val) {

            return getVariance(val.itemCode)!==0;

        });

        const uniqueItemCodes = Array.from(new Set(withVarianceOnly.map(val => val.itemCode)));

        const uniqueItems = uniqueItemCodes.map(itemCode => {
            const item = props.data.find(val => val.itemCode === itemCode);
            return { itemCode: item.itemCode, itemName: item.itemName };
        });

        setItems(uniqueItems);

        updateData(withVarianceOnly);

    }


    return (
        <div>
            <table className="table table-bordered">
                <thead>
                    <tr onClick={()=>computeWithVarianceOnly()}>
                        <th>Item Code</th>
                        <th>Item Name </th>
                        <th>Beg Inv </th>
                        <th>Production </th>
                        <th>Goods Issues </th>
                        <th>Transfer </th>
                        <th>End Inv </th>
                        <th>Net Transfer </th>
                        <th>Invoice</th>
                        <th>Variance</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item =>
                        <tr key={item.itemCode}>
                            <td>{item.itemCode}</td>
                            <td>{item.itemName}</td>
                            <td>{getBegInv(item.itemCode) }</td>
                            <td>{getProduction(item.itemCode)}</td>
                            <td>{getGoodIssues(item.itemCode)}</td>
                            <td>{getTransfer(item.itemCode) }</td>
                            <td>{getEndInv(item.itemCode) }</td>
                            <td>{getNetTransfer(item.itemCode) }</td>
                            <td>{getInvoice(item.itemCode) }</td>
                            <td>{getVariance(item.itemCode)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
