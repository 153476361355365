import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';




export default function AuthorizationCode() {


    const [thisComponentState, updateState] = useState(
        {
            users: [],
            transactionCodes: [],
            datas: []
        }
    );

    useEffect(() => {

        getData();

    }, []);


    async function getData(url) {

        await fetch("/Generics/GetAuthMasterfiles")

            .then(response => response.json())
            .then(data => {

                updateState({ ...thisComponentState, users: data.users, transactionCodes: data.transactionCodes,datas:data.datas})

            });
    }

    const add = () => {

        var object = {

            code: "",
            transactionCode: "",
            codeForAuthorization: ""
        }

        var tempDatas = [...thisComponentState.datas];

        tempDatas.push(object);

        updateState({ ...thisComponentState, datas: tempDatas });

    }

    const onChange = (trIndex, name, value) => {

        var tempDatas = [...thisComponentState.datas];

        if (name === 'transactionCode' || name ==='codeForAuthorization') {

            tempDatas[trIndex][name] = parseInt(value);

        } else {

            tempDatas[trIndex][name] = value;
        }

      
        updateState({ ...thisComponentState, datas: tempDatas });

    }

    const save = () => {

        var toPost = thisComponentState.datas.filter(data => data.user !== '');

        console.log(toPost);

        fetch("/Generics/SaveAuthCode", {
            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(toPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...")
                }
                else {
                    return response.json();
                }

            })

            // Displaying results to console
            .then(json => {

                updateState({ ...thisComponentState, datas: json });

            });

    }

    return (

        <table className="table table-bordered">
            <thead>
               
                <th>User</th>
                <th>Transaction</th>
                <th>Code</th>
            </thead>

            <tbody>

                {
                    thisComponentState.datas.map((data, trIndex) =>

                        <tr>
                            <td>

                                <select className="form-control" onChange={(e) => onChange(trIndex, "code", e.currentTarget.value)}>

                                    {
                                        thisComponentState.users.map(user =>

                                            data.code == user.code ? <option value={user.code} selected>{user.description}</option> : <option value={user.code}>{user.description}</option>

                                        )
                                    }
                                </select>
                            </td>

                            <td>
                                <select className="form-control" onChange={(e) => onChange(trIndex, "transactionCode", e.currentTarget.value)}>

                                    {
                                        thisComponentState.transactionCodes.map(transactionCode =>

                                            data.transactionCode == transactionCode.code ? <option value={transactionCode.code} selected>{transactionCode.description}</option> : <option value={transactionCode.code} >{transactionCode.description}</option>

                                        )
                                    }
                                </select>

                            </td>

                            <td>
                                <input className="form-control" value={data.codeForAuthorization} onChange={(e) => onChange(trIndex, "codeForAuthorization", e.currentTarget.value)} />
                            </td>

                        </tr>


                    )
                }


            </tbody>


            <tfoot>

                <td>
                    <input type="button" value="Add" onClick={add} />
                </td>
                <td>
                    <input type="button" value="Save" onClick={save} />
                </td>

            </tfoot>

        </table>

    )

}

