import { faArrowLeft, faArrowRight, faFilter, faListAlt, faRotate, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import OpenSrFilter from "./OpenSrFilter";
import SrDetails from "./SrDetails";


export default function ListOfRequestForApproval(props) {


    const [thisComponentState, updateState] = useState({
        from: "",
        to:"",
        details: [],
        pickItems: [],
        showDetails: false,
        selectedGuidId:null,
        isLoading:false,
        retrieve: 0,
        multipleGuidIds: [],
        showSummary: false,
        filterDetails: false,
        originalValues: [],
        params: {}
    });

    useEffect(() => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/StockRequisitions/GetRequestForImmediateHeadApproval?"+new URLSearchParams(thisComponentState.params))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.originalValues = response;

                updateState(tempData);

            });

         
    }, [thisComponentState.retrieve]);



    const pick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = true;

        updateState({ ...thisComponentState, details: items });

    }



    const unPick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = false;

        updateState({ ...thisComponentState, details: items });

    }



    
    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    const approvedOne = (guidId) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/StockRequisitions/ApprovalByImmediateHead", {
            method: "post",
            body: JSON.stringify({
                value:guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempData = [...thisComponentState.originalValues];

                    tempData = tempData.filter(u => u.guidId !== response.guidId);

                    updateState({ ...thisComponentState, retrieve :thisComponentState.retrieve+1, isPerformingOperation: false });

                    return alert("Approval Successful...");

                } else {

                    updateState({ ...thisComponentState,  isPerformingOperation: false });

                    return alert(response.message);
                }
            });

    }



    if (thisComponentState.showDetails) {

        return (<SrDetails toApprove={true} approvedOne={approvedOne} guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })} />)
    }



 

    const filter = (criterias) => {

        let tempParam = { ...thisComponentState.params };

        if (typeof criterias.requestorId !=='undefined')
        {     
            tempParam["code"] = criterias.requestorId;
        };

        if (typeof criterias.departmentId !== 'undefined') {

            tempParam["departmentId"] = criterias.departmentId;
        };

        if (typeof criterias.requestDate !== 'undefined') {

            tempParam["requestDate"] = criterias.requestDate;
        };

        if (typeof criterias.requiredDate !== 'undefined') {

            tempParam["requiredDate"] = criterias.requiredDate;
        };

        if (typeof criterias.documentId !== 'undefined') {

            tempParam["documentId"] = criterias.documentId;
        };

        if (typeof criterias.branchId !== 'undefined') {

            tempParam["branchId"] = criterias.branchId;
        };


        if (Object.keys(tempParam).length > 0) {

            updateState({ ...thisComponentState, params: tempParam, retrieve: thisComponentState.retrieve + 1, filterDetails: false });

        } else {

            updateState({ ...thisComponentState, filterDetails: false });

        }
    }




    const clearFilter = () => {

        updateState({ ...thisComponentState, params: {}, retrieve: thisComponentState.retrieve + 1 });

    }

    if (thisComponentState.filterDetails) {

        return (
            <div>
                <OpenSrFilter filter={filter} />
             </div>
     
         )
    }


    const approved = () => {

        var yes = window.confirm("Are you sure you want to approve this requests?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let toPost = {
            value : thisComponentState.pickItems
        };

        fetch("/stockRequisitions/ApprovalByImmediateHead", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(toPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }


    return (

        <div >
            <div >
                <div className="row">
                  
                    <div className="col">
                        <div style={{ float: 'left' }}>
                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
                            <FontAwesomeIcon icon={faFilter} title="show filter criteria" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer', transform: [{rotateY:'90deg'}] }} size="2x" color="orange" onClick={() => updateState({...thisComponentState,filterDetails:true})} />
                            <FontAwesomeIcon icon={faX} title="clear filter" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer'}} size="2x" color="green" onClick={clearFilter} />                                                     
                        </div>                       
                    </div>

                    <div className="col">
                       {/* <input className="form-control" type="button" value="Approved" onClick={approved} />*/}
                    </div>
                 
                    <div className="col">
                       
                    </div>
                    <div className="col">
                       
                    </div>


                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="1"> Action</th>
                                    <th> Request Date </th>
                                    <th> Required Date </th>
                                    <th> Age</th>  
                                    <th> Doc Id </th>
                                    <th> Requestor </th>
                                    <th> Branch</th>
                                    <th> Department</th>
                                    <th> Remarks </th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={val.guidId}>

                                            {/*<td>                                                                                           */}
                                            {/*    <input value={val.isPick ? "UnPick" : "Pick"} className="form-control" type="button" onClick={() => val.isPick   ? unPick(val.guidId) : pick(val.guidId)} />*/}
                                            {/*</td>*/}

                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedGuidId:val.guidId,showDetails:true}) } />
                                            </td>

                                            <td> {val.requestDate}</td>
                                            <td> {val.requiredDate}</td>
                                            <td> {val.age}</td>
                                            <td> {val.documentId}</td>
                                            <td> {val.requestor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.department}</td>
                                            <td> {val.remarks}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
