import React, {Fragment, useContext, useEffect, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';




function ViewOnHand(props) {

  
    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: []
    });



    useEffect(() => {

        viewOnHand();


    },[]);


    async function viewOnHand() {

        await fetch("/Orders/GetOnHand?" + new URLSearchParams({

            guidId: props.guidId

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                updateState({ ...thisComponentState, data: responseData,isLoading:false });

            });

    };



    if (thisComponentState.isLoading) {

        return (

            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <div>             
                        Please wait....
                    </div>
                </div>

            </div>


        );

    } else {

        return (

            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                 
                    <table className="table table-bordered">
                        <thead>
                            <tr key={Math.random() }>
                               

                               {         
                                    Object.entries(thisComponentState.data[0]).map((valueObject, tdIndex) =>

                                        <td key={Math.random()}>

                                            {
                                                valueObject[0].toString()

                                            }

                                        </td>

                                    ) 

                                } 


                            </tr>

                        </thead>

                        <tbody>

                            {
                               thisComponentState.data.map((valueArray, trIndex) =>

                                    <tr key={Math.random()}>

                                        <td> <input type="button" className="btn btn-success" style={{ width: "100px" }} value="Details" /> </td>

                                        {
                                           Object.entries(valueArray).map((valueObject, tdIndex) =>

                                                <td key={Math.random()}>

                                                    {
                                                        valueObject[1]
                                                  
                                                    }

                                                </td>

                                            )
                                        }

         
                                    </tr>

                                )
                            }

                        </tbody>
                    </table>

                </div>

            </div>
        )
    }

}

export default ViewOnHand;

