


function getPropertyValueFromAutoCompleteSuggestions(code,data) {

    var value = "";

    if (data !== undefined) {

        for (var i = 0; i < data.length; i++) {

            if (data[i].code === code.toString()) {
    
                value = data[i].description;

                break;
            };

        };

    }

    return value;

}

export default getPropertyValueFromAutoCompleteSuggestions;