import { faArrowLeft, faArrowRight, faFilter, faListAlt, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import MultiplePrDetailsForPoReview from "./MulitplePrDetailsForPoReview";
import OpenPrFilter from "./OpenPrFilter";
import PrDetailsForAuditReview from "./PrDetailsForAuditReview";


export default function ListOfOpenPrForAudit(props) {


    const [thisComponentState, updateState] = useState({

        details: [],
        pickItems: [],
        showDetails: false,
        selectedGuidId:null,
        isLoading: false,
        retrieve: 0,
        multipleGuidIds: [],
        showSummary: false,
        originalValues: [],
        showFilter: false,
        counter:1

    });

   

    const generate = (params) => {
 
        updateState({ ...thisComponentState, isLoading: true });

        fetch("/PurchaseOrders/GetListOfOpenPrForAudit?"+new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.showFilter = false;
         
                updateState(tempData);

            });
    }


    const pick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = true;

        updateState({ ...thisComponentState, details: items });

    }

    const unPick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = false;

        updateState({ ...thisComponentState, details: items });
    }


    const showSummary = () => {

        let selectedGuidIds = thisComponentState.details.filter(x => x.isPick).map(y => y.guidId);

        let items = [...thisComponentState.multipleGuidIds];

        items = selectedGuidIds;

        updateState({ ...thisComponentState, multipleGuidIds: items,showSummary:true});
    }

    
    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)

    }


    if (thisComponentState.showDetails) {

        return (<PrDetailsForAuditReview guidId={thisComponentState.selectedGuidId} back={() => updateState({...thisComponentState,showDetails:false,retrieve:1})}/>)
    }




    if (thisComponentState.showFilter) {
        return (
            <div>
                <OpenPrFilter generate={generate}  />
            </div>
        )
    }



    return (


        <div style={{paddingTop:"5px"} }>

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <br />

                    <div className="row">
                        <div className="col">

                            <div style={{ float: 'left' }}>
                                <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
                                <FontAwesomeIcon icon={faListAlt} title="show summary" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={showSummary} />
                                <FontAwesomeIcon icon={faFilter} title="show filter criteria" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer', transform: [{ rotateY: '90deg' }] }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showFilter: true })} />
                            </div>

                        </div>

                        <div className="col">



                        </div>

                        <div className="col">

                        </div>


                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th> Series</th>
                                        <th colSpan="2"> Action</th>
                                        <th> Request Date </th>
                                        <th> Required Date </th>
                                        <th> Doc Id </th>
                                        <th> Requestor </th>
                                        <th> Branch</th>
                                        <th> Remarks </th>

                                    </tr>

                                </thead>

                                <tbody>

                                    {
                                        typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                            <tr>
                                                <td>
                                                    {thisComponentState.counter++}
                                                </td>

                                                <td>
                                                    <input value={val.isPick ? "UnPick" : "Pick"} className="form-control" type="button" onClick={() => val.isPick ? unPick(val.guidId) : pick(val.guidId)} />
                                                </td>

                                                <td>
                                                    <input value="Details" className="form-control" type="button" onClick={() => updateState({ ...thisComponentState, selectedGuidId: val.guidId, showDetails: true })} />
                                                </td>

                                                <td> {val.requestDate}</td>
                                                <td> {val.requiredDate}</td>
                                                <td> {val.docId}</td>
                                                <td> {val.requestor}</td>
                                                <td> {val.branch}</td>
                                                <td> {val.remarks}</td>

                                            </tr>)
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            </div>

      
    )

}
