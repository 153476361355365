import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import StandardAutoComplete from "../../StandardAutocomplete";
import { TransactionContext } from "../TransactionContext";

export default function SetDefaultPurchasingUom(props) {

    const { data, updateMetaData } = useContext(TransactionContext);







    const [thisComponentState, updateState] = useState({

        code: "",
        uomEntry: ""

    });


    const updateCode = (code) => {
        updateState({ ...thisComponentState, code: code});
    }

    const updateUomEntry = (code) => {
        updateState({ ...thisComponentState, uomEntry: code });
    }


    const save = () => {

        var isConfirm = window.confirm("Are you sure you want to make this as  default purchasing uom?");

        if (!isConfirm)
        {
            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        var postBody = {

            item1: thisComponentState.code,
            item2: thisComponentState.uomEntry
        };

        fetch("/PurchaseOrders/SetDefaultPurchasingUom", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
               value : postBody
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                if (response.success) {

                    let tempData = { ...data.metaData };

                    console.log(tempData);

                    let inventories = tempData.inventories;

                    let inventory = inventories.find(item => item.itemCode == thisComponentState.code);

                    inventory.purchaseUomEntry = thisComponentState.uomEntry;

                    updateMetaData({ ...data, metaData: tempData });

                }

                return alert(response.message);

            });
    }


    return (

        <div>


            <div className="row">
                <div className="col">
                    <input value="Back" type="button" className="form-control" onClick={() => props.back()} />
                </div>
            </div>


           <hr/>

            <div className="row">
                <div className="col">
                    Item
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.code} clear={() => updateState({ ...thisComponentState, code: "" })} url="/Inventories/GetInventories" updateValue={updateCode} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Default Purchasing Uom
                </div>

                <div className="col">
                    <StandardAutoComplete mountCounter={thisComponentState.code} value={thisComponentState.uomEntry} clear={() => updateState({ ...thisComponentState, uomEntry: "" })} url="/UomValues/GetUomValuesByCode" params={{code:thisComponentState.code}} updateValue={updateUomEntry} />

                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                   
                </div>

                <div className="col">
                    <input value="Save" type="button" className="form-control" onClick={save}/>
                </div>
            </div>

        </div>

    )
}




