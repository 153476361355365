import React, {Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import setMetaData from '../../Functions/setMetaData';
import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import CreateStockRequisitionNavigator from './CreateStockRequisitionNavigator';
import DetailsStockRequisitionNavigator from './DetailsStockRequisitionNavigator';
import StockRequisitionListNavigator from './StockRequisitionListNavigator';



export default function StockRequisitionForm(props) {

    const transactionCode = "StockRequisition";
    const postUrl = "/StockRequisitions/Create";
    const detailsUrl = "/StockRequisitions/Details/?id=";
    const editDetailUrl = "/StockRequisitions/EditItem";
    const deleteDetailUrl = "/StockRequisitions/DeleteItem";
    const listUrl = "/StockRequisitions/Index";
    const validatorUrl = "/StockRequisitions/Validate";
    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);
    const detailsNavigator = (<DetailsStockRequisitionNavigator />);
    const [categories, updateCategories] = useState([]);


    useEffect(() => {

        const { state } = props.location;
        const { type } = state;

        if (type === "Grocery") {

            updateCategories(['100']);

        } else if (type === "Auto&Electro")
        {
            updateCategories(['114']);
        }
        else if (type === "JamesWrightCommissary")
        {
            updateCategories(['132']);
        }
        else if (type === "ValleyBreadCommissary")
        {
            updateCategories(['129']);
        }
        else if (type === "Office") {

            updateCategories(['104']);
        }
        else if (type === "Janitorial") {

            updateCategories(['112']);
        }
        else {

            updateCategories(['110', '138', '139', '140', '141', '142', '143', '145', '146', '102', '103', '104', '105', '106', '107', '111', '112', '113', '115', '116', '117', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131']);
        }



        setMetaData(postUrl, updateStatus, updateMetaData, props.detailProperties);


    }, [])

    let context = {
   
        categories: categories ,
        type: "SR",
        formDiscriminator: "StockRequisition",
        transactionCode: "StockRequisition",
        toReloadDetails:0

    };




    const transactionList = (<TransactionList state={{ listUrl: listUrl,deleteDetailUrl:deleteDetailUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<StockRequisitionListNavigator listUrl={ listUrl }/> } detailsNavigator={<DetailsStockRequisitionNavigator />} />);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider  validatorUrl={validatorUrl} transactionCode={transactionCode} context={context} metaData={data.metaData} postUrl={postUrl} deleteDetailUrl={deleteDetailUrl} listUrl={listUrl} detailsUrl={detailsUrl} editDetailUrl={editDetailUrl} detailsNavigator={detailsNavigator} transactionList={transactionList} functions={[]}>

                <TransactionForm  createNavigator={<CreateStockRequisitionNavigator />} detailsNavigator={<DetailsStockRequisitionNavigator />} transactionList={ transactionList} />

            </TransactionContextProvider>
        )
    }
}



