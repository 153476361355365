import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react"
import Agent from "./Agent";
import Overall from "./Overall";
import PerAgent from "./PerAgent";
import PerItem from "./PerItem";
import VarianceMaxtrix from "./VarianceMatrix";


export default function ReconciliationViewer(props) {

    const [selectBy, updateSelection] = useState("");

    const [data, updateData] = useState([]);

    const [filteredData, setFiltered] = useState([])

    const [isLoading, setLoadingState] = useState(false);

    const [dateRange, setDateRange] = useState({
        from: "",
        to :""
    });

    // Function to update the 'from' value
    const handleFromChange = (event) => {
        const newFromDate = event.target.value;
        // Updating the state with the new 'from' value
        setDateRange(prevState => ({
            ...prevState,
            from: newFromDate
        }));
    };

    const handleToChange = (event) => {
        const newFromDate = event.target.value;
        // Updating the state with the new 'from' value
        setDateRange(prevState => ({
            ...prevState,
            to: newFromDate
        }));
    };


    const filter = (value) => {

        let tempData = [...data];

        if (value === "Non-Commissary") {

            let filteredData = tempData.filter(val => val.memo==="Agent");

            setFiltered(filteredData);

            return;
        }

        if (value === "Commissary") {

            let filteredData = tempData.filter(val => val.memo === "Commissary").filter(val => val.itemCode.substring(0, 2) === "JW" || val.itemCode.substring(0, 2) === "KM"  );

            setFiltered(filteredData);

            return;
        }

    }

    const generate = () => {

        setLoadingState(true);

        fetch("/Productions/GetReconciliationModel?" + new URLSearchParams({
            from: dateRange.from,
            to:dateRange.to
        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateData(response);
                setLoadingState(false);

            });
    }

    const getInfo = () => {

        if (selectBy === "Overall") {

            return (<Overall data={filteredData}/>);
        };

        if (selectBy === "ByAgent") {

            return (<Agent data={filteredData} />);
        };

        if (selectBy === "PerAgent") {

            return (<PerAgent data={filteredData} />);
        };

        if (selectBy === "PerItem") {
            return (<PerItem data={filteredData} />);
        };

        if (selectBy === "ByMatrix")
        {
            return (<VarianceMaxtrix data={filteredData} from={dateRange.from} to={dateRange.to} />);
        };

    }

    if (isLoading) {

        return (<div>Loading...</div>);
    }

   
    return (

        <div>

            <div className="row">
                
                <div className="col">
                    <input type="button" value="Back"  className="form-control" onClick={()=>props.back()}/>
                </div>
                <div className="col">
                    <input type="date" value={dateRange.from} className="form-control" onChange={handleFromChange }  />
                </div>
                <div className="col">
                    <input type="date" value={dateRange.to} className="form-control" onChange={handleToChange} />
                </div>
                <div className="col">
                    <input type="button" value="Initialize" className="form-control" onClick={generate} />
                </div>

                <div className="col">

                    <select className="form-control" onChange={(e) => filter(e.currentTarget.value)}>
                        <option value="">Please select type</option>
                        <option value="Non-Commissary"> Non-Commissary</option>
                        <option value="Commissary">Commissary</option>
                    </select>

                </div>
                <div className="col">
                    <select className="form-control" onChange={(e)=>updateSelection(e.currentTarget.value)}>
                        <option value="">Please select State</option>
                        <option value="Overall">By Overall</option>
                        <option value="ByAgent">By Agent</option>
                        <option value="PerAgent">By Per Agent</option>
                        <option value="PerItem">By Per Item</option>
                        <option value="ByMatrix">Variance Matrix</option>

                    </select>
                </div>
                <div className="col">

                    <FontAwesomeIcon icon={faCalculator} title="With variance only" style={{ cursor: 'pointer' }} size="2x" color="blue"  />
                 
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">

                    {
                       getInfo()
                    }

                </div>
            </div>
        </div>
       
    )
}