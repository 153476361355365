import React, { Fragment, useContext } from 'react';
import isAutocomplete from '../Functions/isAutocomplete';
import AutoCompleteInput from './AutocompleteInputForTransaction';
import getUrl from '../Functions/getUrl';
import getHtmlType from '../Functions/getHtmlType';
import StandardInput from '../StandardInput';
import getDisplayName from '../Functions/getDisplayName';
import { TransactionContext } from './TransactionContext';
import isBasedOnOtherProperty from '../Functions/isBasedOnOtherProperty';
import searchFunction from '../Functions/searchFunction';
import getFunctionName from '../Functions/getFunctionName';
import uuid from 'react-uuid';


export default function Parent() {

    const { data,updateParentValue } = useContext(TransactionContext);

    var parentProperties = Object.entries(data.metaData.parentProperties);

  
    return (
        <div>
           

            {parentProperties.map((value, index) =>

                    <div  key={uuid()} >

                        <label>{getDisplayName(value[0], data.metaData.parentMetaData)}</label>

                        {
                           value[0]!=="details"  &&  (isAutocomplete(value[0], data.metaData.parentMetaData) ? <AutoCompleteInput value={value[1]} name={value[0]} code={value[0] === "code" ? value[1] : ""} url={getUrl(value[0], data.metaData.parentMetaData)} hierachyType="parent" /> : <StandardInput htmlType={getHtmlType(value[0], data.metaData.parentMetaData)} name={value[0]} value={isBasedOnOtherProperty(value[0], data.metaData.parentMetaData) ? searchFunction(getFunctionName(value[0], data.metaData.parentMetaData))(data.metaData.parentProperties, data.metaData.settings) : value[1]} isParent={true} updateValue={updateParentValue} />)
                        }

                    </div>

                )}
          

        </div>
       
        )
}

