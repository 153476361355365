
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';

import { TransactionContext } from '../TransactionContext';


export default function ReferenceEntry(props) {

    const { data,updateMetaData} = useContext(TransactionContext);

    console.log(data);

    const [thisComponentState,updateState] =useState({

        details: data.metaData.detailProperties

    });


    const updateRrNumber = (id, rrNumber) => {

        
        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.id === id);

        tempData.rrNumber = rrNumber;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateVendorRef = (id,vendorRef) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.id === id);

        tempData.vendorRef = vendorRef;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateQuantity = (id, quantity) => {

        if (isNaN(quantity)) {

            return;
        }

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.id === id);

        tempData.quantity = parseFloat(quantity);

        updateState({ ...thisComponentState, details: tempDatas });

    }


    const updatePrice = (id, price) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.id === id);

        var initVal = parseFloat(price);

        if (initVal >= 0) {

            tempData.price = initVal;

            updateState({ ...thisComponentState, details: tempDatas });
        }
    }


    const back = () => {

        let tempData = { ...data.metaData };

        tempData.detailProperties.forEach(item => {

            let detail = thisComponentState.details.find(x => x.id === item.id);

            item.rrNumber = detail.rrNumber;
            item.vendorRef = detail.vendorRef;

        });

        updateMetaData({ metaData: tempData });

        props.back();
    }

   


    return (

            <div>
                <div className ='row'>
                    
                    <div className='col'>
                        <input value="Back" type="button" className="form-control" onClick={back}/>
                    </div>
                </div>

                <br/>

                 <div className='row'>
                    <table className='table table-bordered'>

                        <thead>

                            <tr >
                                <td>  Code </td>
                                <td>  Item </td>
                                <td>  Vendor Ref </td>
                                <td>  RR Number </td>
                                <td>  Quantity </td>
                                <td>  Price </td>

                            </tr>

                        </thead>


                        <tbody>
                            {
                                thisComponentState.details.length > 0 && thisComponentState.details.map(val =>
                                
                                        <tr >
                                                                                
                                            <td className="col">
                                                <input value={val.code} className="form-control" readOnly />
                                            </td>

                                            <td className="col">
                                                <input value={val.description} className="form-control" readOnly />
                                            </td>

                                            <td className="col">
                                                <input value={val.vendorRef}  onChange={(e) => updateVendorRef(val.id, e.currentTarget.value)} className="form-control" />
                                            </td>

                                            <td className="col">
                                                <input value={val.rrNumber} onChange={(e) => updateRrNumber(val.id, e.currentTarget.value)} className="form-control" />
                                            </td>

                                            <td className="col">
                                                <input value={val.quantity} onChange={(e) => updateQuantity(val.id, e.currentTarget.value)} className="form-control" />
                                            </td>
                                            <td className="col">
                                                <input value={val.price} onChange={(e) => updatePrice(val.id, e.currentTarget.value)} className="form-control" />
                                            </td>

                                        </tr>

                                )
                            }

                        </tbody>
                    </table>
                </div>
            </div>         
)}