import { faArrowDown,faFile,faFileExcel,faFolderOpen,faHand,faListAlt,faPlus, faUpload} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState} from 'react';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import OnHandQty from '../../OnHandQty';
import Drafts from '../Drafts';
import { TransactionContext } from '../TransactionContext';
import UploadFile from './UploadFile';
import ListOfOpenSR from './ListOfOpenSr';
import { toCsvString } from '../../Functions/toCsvString';
import exportToCSV from '../../Functions/exportToCSV';



export default function CreateGoodsIssueNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        showOnHand: false,
        uploadFile: false,
        showOpenSr: false

    });


    const pullStockRequisition = () => {

        const guidId = JSON.parse(localStorage.getItem("stockRequisitionGuidId"));

        if (guidId === "" || typeof guidId === 'undefined' || guidId === null) {

            return alert("Please move a request.")
        };


        fetch("/GoodsIssues/GetRequest?" + new URLSearchParams({

            guidId:guidId

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);                   
                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                    return;
                }

                return alert("No data available.");

            })
    }

    const draftIt = () => {

        let value = data.metaData.parentProperties;

        value.details = data.metaData.detailProperties;

        let draft = {
            type: 5,
            value: JSON.stringify(value)
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Base/SaveDraft", {
            method: "post",
            body: JSON.stringify(draft),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    if (thisComponentState.showOnHand) {

        <div style={getModalStyle()}>
            <div style={getModalContentStyle() }>
                <OnHandQty back={() => updateState({...thisComponentState,showOnHand:false}) } />
            </div>
        </div>  
    }

    if (thisComponentState.showDrafts) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Drafts type={5} back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )
    }

    if (thisComponentState.uploadFile) {

        return (<UploadFile back={() => updateState({...thisComponentState,uploadFile:false})}/>)
    }


    if (thisComponentState.showOpenSr) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ListOfOpenSR back={() => updateState({ ...thisComponentState, showOpenSr: false })} />

                </div>
            </div>
        )
    }

    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailProperties);

            exportToCSV("data", csvString);
        };
    }




    return (

          <div style={{ float: "left" }}>
             
                <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

                <FontAwesomeIcon icon={faHand} title="On Hand" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, showOnHand: true })} />

                <FontAwesomeIcon icon={faArrowDown} title="pull stock request" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={pullStockRequisition} />

                <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />

                <FontAwesomeIcon icon={faUpload} title="Upload" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={()=>updateState({ ...thisComponentState, uploadFile: true })} />

                <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />

                <FontAwesomeIcon icon={faListAlt} title="List of Open Sr" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showOpenSr: true })} />

                <FontAwesomeIcon icon={faFileExcel} title="Download to excel" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />


          </div>
           
    )

}

