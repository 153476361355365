import { useEffect, useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import getTotal from "../../Functions/getTotal";
import StandardAutoComplete from "../../StandardAutocomplete";

export default function PerAgent(props) {

    const [items, setItems] = useState([]);
    const [agentId, setAgentId] = useState("");

    const [data, updateData] = useState(props.data);

    const filter = (agentId) => {

        if (props.data && props.data.length > 0) {

            let parseAgentId = parseInt(agentId);

            let filteredData = props.data.filter(val => val.slpCode === parseAgentId);

            updateData(filteredData);

            const uniqueItemCodes = Array.from(new Set(props.data.map(val => val.itemCode)));

            const uniqueItems = uniqueItemCodes.map(itemCode => {
                const item = props.data.find(val => val.itemCode === itemCode);
                return { itemCode: item.itemCode, itemName: item.itemName };
            });

            setItems(uniqueItems);
        }
    }

    useEffect(() => {

        filter(agentId);

    }, [agentId])



    const getBegInv = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && (val.identifier === "BegInventory" || val.identifier === "YesterdaysReturns")).map(val => val.quantity);

        return getTotal(result);
    }


    const getTransfer = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "BreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getEndInv = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && (val.identifier === "EndingInventory" || val.identifier === "Returns")).map(val => val.quantity);

        return getTotal(result);
    }
    const getNetTransfer = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "NetBreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getInvoice = (itemCode) => {

        let result = data.filter(val => val.itemCode === itemCode && val.identifier === "Invoice").map(val => val.quantity);

        return getTotal(result);
    }

    const getVariance = (itemCode) => {

        return getEndInv(itemCode) - (getBegInv(itemCode) + getTransfer(itemCode) - getInvoice(itemCode));
    }

    const updateAgentId = (code) => {

        setAgentId(code);

    }
    const computeWithVarianceOnly = () => {

        let tempData = [...data];

        let withVarianceOnly = tempData.filter(function (val) {

            let variance = getVariance(val.itemCode);

            return variance !== 0;

        });

        const uniqueItemCodes = Array.from(new Set(withVarianceOnly.map(val => val.itemCode)));

        const uniqueItems = uniqueItemCodes.map(itemCode => {
            const item = props.data.find(val => val.itemCode === itemCode);
            return { itemCode: item.itemCode, itemName: item.itemName };
        });

        setItems(uniqueItems);

        updateData(withVarianceOnly);

    }
   

    return (
        <div>

            <div className="row">
                <div className="col">
                    <StandardAutoComplete placeholder="Select agent" value={agentId} url="/Agents/GetAgents" updateValue={updateAgentId} />
                </div>
            </div>

            <div className="row">
                <div className="col">

                    <table className="table table-bordered">
                        <thead>
                            <tr onClick={()=>computeWithVarianceOnly()}>
                                <th>Item Code </th>
                                <th>Item Name </th>
                                <th>Beg Inv </th>
                                <th>Transfer </th>
                                <th>End Inv </th>
                                <th>Net Transfer </th>
                                <th>Invoice</th>
                                <th>Variance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item =>
                                <tr key={item.itemCode}>
                                    <td>{item.itemCode}</td>
                                    <td>{item.itemName}</td>
                                    <td>{getBegInv(item.itemCode)}</td>
                                    <td>{getTransfer(item.itemCode)}</td>
                                    <td>{getEndInv(item.itemCode)}</td>
                                    <td>{getNetTransfer(item.itemCode)}</td>
                                    <td>{getInvoice(item.itemCode)}</td>
                                    <td>{getVariance(item.itemCode)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
