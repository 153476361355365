import React, {useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import computeGrandTotal from '../Functions/computeGrandTotal';


 export default function DailyInvoiceSummary(props) {
 
    const [thisComponentState, updateState] = useState({

        data: [],
        isInitial:true,
        isLoading: false,
        date: ""
    });


    let grandTotal = 0;

    async function generate(criteria) {

        var date = thisComponentState.date;

        if (date === "") {

            return alert("Please defined a date...");
        }

        await fetch("/Invoices/DailyInvoiceSummary?" + new URLSearchParams({

            date: date,
            criteria : criteria

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.length > 0) {

                    updateState({ ...thisComponentState, data: responseData, isLoading: false, isInitial: false });

                } else {

                    return alert("No data available.");
                };
                
            });

    };


     if (thisComponentState.isInitial) {


         return (
             <div style={{ float: "left", display: "inline-block" }}>

                 <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.date}  onChange={(e) => updateState({ ...thisComponentState,date: e.currentTarget.value })} placeholder="Production Date" />

                 <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=> generate("Agent")} value="By Agent"/>

                 <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=> generate("Customer")} value="By Customer" />


                 <br />
                 <br />

             </div>
         )
     }

    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {

      
                return (


                    <div>
                        <div style={{ float: "left", display: "inline-block" }}>

                            <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.date} onChange={(e) => updateState({ ...thisComponentState, date: e.currentTarget.value })} placeholder="Production Date" />

                            <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={() => generate("Agent")} value="By Agent" />

                            <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={() => generate("Customer")} value="By Customer" />


                            <br />
                            <br />

                        </div>


                        {
                            thisComponentState.data.map(list =>

                                <table className="table table-bordered">

                                    <thead className="thead-dark text-center"> 

                                        <tr>
                                            <th colSpan="4">{list.group}  </th>
                                        </tr>

                                        <tr>
                                            <th> Product  </th>
                                            <th> Quantity  </th>
                                            <th> Price </th>
                                            <th> Line Total </th>
                                        </tr>


                                    </thead>

                                            <tbody>

                                                     {
                                                        list.details.map(prod =>
                                                               
                                                            <tr>
                                                                <td>{prod.product } </td>
                                                                <td>{prod.quantity} </td>
                                                                <td>{prod.price} </td>
                                                                <td>{prod.lineTotal} </td>

                                                            </tr>

                                                             
                                                        )

                                                    }                                                  
                                            </tbody>

                                            <tfoot>
                                                    <tr>
                                                        <td>Grand Total</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{ computeGrandTotal(list.details)}</td>
                                                    </tr>
                                            
                                            </tfoot>

                                    </table>
                                           
                                )


                        }



                    </div>


                 
                )


    }
}

