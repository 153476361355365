
import { useEffect, useState } from "react"

export default function ItemTransactionHistory(props)
{

    const [data, updateData] = useState([]);

    const [from, updateFrom] = useState(props.from);
    const [to, updateTo] = useState(props.to);

    const [isLoading, updateLoadingState] = useState(false);

    useEffect(() => {

        getItemTransactionHistory();

    }, [from,to])


    const getItemTransactionHistory = () => {

        updateLoadingState(true);

        fetch("/Productions/GetItemTransactionHistory?" + new URLSearchParams({
            itemCode: props.itemCode,
            from:from,
            to:to
        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.length !== 0) {

                    updateData(responseData);

                    updateLoadingState(false);

                    return;

                } else {

                    updateLoadingState(false);

                    return alert("No records found.");
                }


            });
    };

    if (isLoading) {
        <div>Computating...</div>
    }


    return (
        <div>
            <div className="row">
                <div className="col">
                    <input type="button" value="Back" onClick={()=>props.back()} className="form-control" />
                </div>
                <div className="col">
                    {props.itemName}
                </div>
                <div className="col">
                    <input type="date" value={from} onChange={(e)=>updateFrom(e.currentTarget.value)} className="form-control"/>
                </div>
                <div className="col">
                    <input type="date" value={to} onChange={(e) => updateTo(e.currentTarget.value)} className="form-control" />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>SapId</th>
                                <th>TransType</th>
                                <th>Warehouse</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                data && data.length > 0 && data.map(val => <tr>
                                    <td>{val.sapId}</td>
                                    <td>{val.transType}</td>
                                    <td>{val.warehouse}</td>
                                    <td>{val.quantity}</td>
                                </tr>)
                            }

                        </tbody>
                    </table>
                </div>
            </div>

            </div>
        
        )


}