import React, { useContext } from 'react';

import { useState } from "react";
import { useZxing } from "react-zxing";
import { TransactionContext } from '../TransactionContext';

export const BarcodeScanner = (props) => {

    const [result, setResult] = useState("");

    const { guidId, updateGuidId } = useContext(TransactionContext);

    const { ref } = useZxing({

        onResult(result) {

            setResult(result.getText());
            updateGuidId(guidId);
            props.pullFunction(result);

        }
    });

    const back = () => {

        props.back();

    };

    return (
         <>
         
             <video ref={ref}  onDoubleClick={back} />

          </>
        );
};


