import React, { useState } from 'react';



export default function PureTextArea(props) {

    const [value, updateValue] = useState(props.value);

    return <textarea
        value={value===null?"":value}
        name={props.name}
        className="form-control"
        style={props.style}
        onChange={(e) => updateValue(e.currentTarget.value)}
        onBlur={(e) => props.updateValue(props.code,e.currentTarget.name, value)}
    /> 

   

}