import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import uuid from 'react-uuid';
import ChatBox from './ChatBox';
import UserSelection from './UserSelection';

const ChatBoxContainer = () => {

    const [selectedUsersToChat, setSelectedUsersToChat] = useState([]);

    const [allUsers, setAllUsers] = useState([]);

    const [ShowUserSelection, setUserSelection] = useState(false);

    useEffect(() => {
        fetch("/Identity/GetAllUsers"
        )
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                setAllUsers(response);

                setUserSelection(true);

            })
    }, []);

    useEffect(() => {

    }, [selectedUsersToChat])



    useEffect(() => { }, [allUsers]);


    const addUsersToChat = (user) => {

        let tempData = [...selectedUsersToChat];

        tempData.push(user);

        setSelectedUsersToChat(tempData);

        setUserSelection(false);
        
    }

    const updateConnectedUsers = (connectedUsers) => {

        let tempData = [...allUsers];

        tempData.map(user => {

            let connectedUser = connectedUsers.find(val => val.userId === user.userId);

            if (typeof connectedUser !== 'undefined') {

                user.connectionId = connectedUser.connectionId;
                user.isConnected = connectedUser.isConnected;
            }
        });
        
        setAllUsers(tempData);
    }


    if (ShowUserSelection) {

        return (<UserSelection addUsersToChat={addUsersToChat} users={allUsers} />)
    }


    return (
        <>
            {
                selectedUsersToChat.map((user, index) =>

                    <ChatBox index={uuid()} user={user} users={allUsers} addUsersToChat={addUsersToChat} updateConnectedUsers={updateConnectedUsers} />
                )
            }
        </>
    );
};

export default ChatBoxContainer;
