import { useState } from "react";


export default function PoOwnInput(props)
{
    const [thisComponentState, updateState] = useState({
        value:props.value
    })

    console.log(props);

    return (

        <input type="number" className="form-control" onBlur={()=>props.capture(props.identifier,thisComponentState.value)} value={thisComponentState.value} onChange={(e) => updateState({...thisComponentState,value:e.currentTarget.value })}/>
     )
}