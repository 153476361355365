import { useContext, useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import { TransactionContext } from "../TransactionContext";



export default function MultiplePrDetailsForPoReview(props)
{

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: []
    });

    useEffect(() => {

        pullPurchaseRequisition();

    },[]);

    const pullPurchaseRequisition = () => {

        if (typeof props.guidIds === 'undefined' || props.guidIds.length===0) {

            updateState({ ...thisComponentState, isLoading: false });

            return alert("No selected purchase requisitions...");
        };

        fetch("/PurchaseOrders/GetMultiplePrDetails?" + new URLSearchParams({

            guidIds: props.guidIds.join(",")

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })
    };

    if (thisComponentState.isLoading) {

        return(<div>Loading...</div>)
    }



    const move = () => {

        let tempData = { ...data.metaData };

        tempData.detailProperties = thisComponentState.data;

        //toShowProperty

        var presentationValue = {

            toShow: true,
            categoryId: ""
        };

        tempData.detailProperties = tempData.detailProperties.filter(item=> !item.isHold && !item.isCancelled)

        tempData.detailProperties.forEach(item => { delete item.isHold; delete item.isDeleted;})

        tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

        updateMetaData({ ...data, metaData: tempData });

        props.doubleBack();

    }


    const getUomDesc = (uomEntry) => {

        for (let i = 0; i < data.metaData.uomValues.length; i++) {

            for (let j = 0; j < data.metaData.uomValues[i].uomEntryDTOs.length; j++)
            {
                if (data.metaData.uomValues[i].uomEntryDTOs[j].uomEntry === uomEntry) {

                    return data.metaData.uomValues[i].uomEntryDTOs[j].uomName;
                }

            }

        }

    }


    return (

        <div>
            <div>

                <div className="row">

                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={()=> props.back()}/>
                    </div>

                    <div className="col">
                        <input value="Move" type="button" className="form-control" onClick={move} />
                    </div>
              
                </div>


                <br/>

                <div className="row">

                    <div className="col">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Code </th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>UomDesc</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Is Hold</th>
                                    <th>Is Cancelled</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    thisComponentState.data.map(val =>
                                        <tr key={uuid()}>

                                            <td>{val.code}</td>
                                            <td>{val.description}</td>
                                            <td>{val.quantity}</td>
                                            <td>{getUomDesc(val.uomEntry)}</td>
                                            <td>{val.price}</td>
                                            <td>{val.amount}</td>
                                            <td>{val.isHold ? "Y" :"N"}</td>
                                            <td>{val.isDeleted ? "Y" :"N"}</td>

                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

              
            </div>
        </div>   
   )
}