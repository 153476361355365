import { useEffect } from "react";
import { useState } from "react"



export default function InventoryVariation(props) {

    const [thisComponentState, updateState] = useState({

        name:props.definition.name,
        brand:props.definition.brand,
        size:props.definition.size,
        shape: props.definition.shape,
        color:props.definition.color,
        length: props.definition.length,
        width: props.definition.width,
        height:props.definition.height,
        diameter:props.definition.diameter,
        getFullName: function () {

            let name = this.size + this.length + this.width + this.height + this.shape + this.color + this.name + this.diameter+ this.brand;

            return name;
        }

    });




    return (
        <div>
            <div className="row">
                <div className="col">
                </div>
                <div className="col">
                    <input value="Back" type="" className="form-control text-center" onClick={() => props.set(props.selectedGuidId,"name",thisComponentState.getFullName(),thisComponentState)} />
                </div>
                <div className="col">
                </div>
            </div>

            <hr/>


            <div className="row">
                <div className="col">
                  Final  Name
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.getFullName()} onChange={(e) => updateState({...thisComponentState,name:e.currentTarget.value })} />
                </div>
            </div>
            <br />

            <div className="row">
                <div className="col">
                  Vary by  Brand
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.brand} onChange={(e) => updateState({ ...thisComponentState, brand: e.currentTarget.value })} />
                </div>
            </div>

            <br />


            <div className="row">
                <div className="col">
                    Vary by  Size
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.size} onChange={(e) => updateState({ ...thisComponentState, size: e.currentTarget.value })} />
                </div>
            </div>

            <br />


            <div className="row">
                <div className="col">
                 Vary By Shape
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.shape} onChange={(e) => updateState({ ...thisComponentState, shape: e.currentTarget.value })} />
                </div>
            </div>

            <br /> 
            <div className="row">
                <div className="col">
                  Vary By  Color
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.color} onChange={(e) => updateState({ ...thisComponentState, color: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                  Vary by  Length
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.length} onChange={(e) => updateState({ ...thisComponentState, length: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                 Vary By   Width
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.width} onChange={(e) => updateState({ ...thisComponentState, width: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                 Vary by Height
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.height} onChange={(e) => updateState({ ...thisComponentState, height: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                Vary By Diameter
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.diameter} onChange={(e) => updateState({ ...thisComponentState, diameter: e.currentTarget.value })} />
                </div>
            </div>


        </div>

    )

}