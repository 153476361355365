
import React, { useState } from 'react';
import { useContext } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import StandardAutoComplete from '../../StandardAutocomplete';
import { TransactionContext } from '../TransactionContext';


export default function OpenItems(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isPerformingOperation:false,
        list: [],
        code: "",
        type: "",
        toComputeWithholding:false

    })

    const retrieve = () => {


        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/AccountsPayables/RetrieveOpenItems?" + new URLSearchParams({

            code: thisComponentState.code,
            toComputeWithholding:thisComponentState.toComputeWithholding

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.length > 0) {

                    response.forEach(val => val.selected = false);

                    updateState({ ...thisComponentState, list: response, showSelection: true, isPerformingOperation: false });

                    return;
                }

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    const selected = (guidId) => {

        let tempDatas = [...thisComponentState.list];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.selected = !tempData.selected;

        updateState({ ...thisComponentState, list: tempDatas });
    }


    const move = () =>
    {
       
        let selectedDocEntries = thisComponentState.list.filter(val => val.selected).map(val => val.guidId);

        if (selectedDocEntries.length == 0) {
            return alert("Selections cannot be empty.")
        }

        let joined = selectedDocEntries.join(",");

        props.retrieveContents(joined,thisComponentState.toComputeWithholding);
    }

    const updateValue = (code) => {

        updateState({ ...thisComponentState, code: code });
    }

    return (

        <div style={getModalStyle()}>

            <div style={getModalContentStyle()}>

                <div className="row">
                    <div className="col">
                        <input className="form-control" type="button" value="Back" onClick={() => props.back()} />
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col">
                       Vendor
                    </div>
                    <div className="col">
                        <StandardAutoComplete value={thisComponentState.code} url="AccountsPayables/GetVendors" updateValue={updateValue}/>
                    </div>
                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                      
                    </div>

                    <div className="col">
                        <input className="form-control" type="button" value="Generate" onClick={retrieve}/>
                    </div>
                </div>

                <br/>

                <div className="row">

                    <div className="col">
                    </div>

                    <div className="col">
                        <input className="form-control" type="button" value="Move" onClick={move} />
                    </div>
                </div>



                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th> Action</th>
                                    <th> Date </th>
                                    <th> Doc Id </th>
                                    <th> Sap Id </th>
                                    <th> Doc Total </th>
                                    <th> Remarks </th>

                                </tr>

                            </thead>
                            <tbody>

                                {
                                  thisComponentState.list.length > 0 &&  thisComponentState.list.map(val =>

                                        <tr>

                                            <td>
                                                <input type="checkbox" value={val.selected} onChange={() => selected(val.guidId)} className="form-control" />
                                            </td>
                                            <td> {val.date}</td>
                                            <td> {val.docId}</td>
                                            <td> {val.sapId}</td>
                                            <td> {val.docTotal}</td>
                                            <td> {val.note}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

  
}