
import React, {  Fragment, useContext, useState } from 'react';

import { Link } from 'react-router-dom';

import getModalContentStyle from '../../Functions/getModalContentStyle';

import getModalStyle from '../../Functions/getModalStyle';

import { TransactionContext } from '../TransactionContext';

import uuid from 'react-uuid';
import Detail from '../Detail';
import createDetailProperties from '../../Functions/createDetailProperties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faClone, faEdit, faEye, faFileExcel, faForward, faPlus, faReceipt, faRedo, faStopCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { BarcodeScanner } from './ScanPO';
import { toCsvString } from '../../Functions/toCsvString';
import exportToCSV from '../../Functions/exportToCSV';

export default function DetailsGoodsReceiptNavigator(props) {

    const {data, guidId,updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        
        send: false,
        isEmailed: false,
        isCancelling: false,
        isApproving: false,
        isGeneratingPDF: false,
        isPerformingOperation:false,
        showOnHand: false,
        isShowingOnHand: false,
        toEdit:false,
        data: [],
        toScan:false
 
    });
   
    const email = () => {

        if (thisComponentState.isEmailed) {

            updateState(prevState => ({
                ...prevState,
                send: false,
                isEmailed: false

            }));

        } else {


            fetch("/PurchaseOrders/Send", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify({ guidId: guidId}),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => {

                    if (!response.ok) {
                        response.text().then(text => console.log(text))
                    }
                    else {
                        return response.json();
                    }

                })

                .then(response => {

                    alert("Purchase order has been sent.")

                    updateState(prevState => ({
                        ...prevState,
                        isEmailed: true

                    }));

                });
        }
    }


    if (thisComponentState.toScan) {

        return <BarcodeScanner/>
    }


    const cancel = () => {

        var isConfirm = window.confirm("Are you sure you want to cancel this Goods Receipts?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isCancelling: true })

        fetch("/GoodsReceipts/Cancel", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({ value: guidId }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isCancelling: false })

                return alert(response.message);

            });
    }


    const close = () => {

        var isConfirm = window.confirm("Are you sure you want to close this Goods Receipt?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isCancelling: true })

        var postBody = {

            value: guidId
        }

        fetch("/GoodsReceipts/Close", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isCancelling: false })

                return alert(response.message);

            });
    }



    const duplicate = () => {

        localStorage.setItem("toDuplicateId", guidId);

        window.location.reload();

    };

    const preview = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/PurchaseOrders/PreviewWithCookie?id=";
       // let url = "https://testerpsystems.azurewebsites.net/PurchaseOrders/Test?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);
                window.open(file);

            });
    }

    if (thisComponentState.isGeneratingPDF) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Generating PDF...
                    </button>
                </div>
            </div>
        </div>)

    }



    if (thisComponentState.isShowingOnHand) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.isCancelling) {
    
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }

    const getWareHouseQty = (warehouseQtys, wareHouse) => {

        var wareHouseQty = warehouseQtys.find(x => x.wareHouse === wareHouse);

        if (typeof wareHouseQty !== 'undefined') {

            return wareHouseQty.quantity.toLocaleString() + " " + wareHouseQty.uom;

        } else {

            return 0;

        }
    }


    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/GoodsReceipts/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }



    const getOnHandQty = () => {

        updateState({ ...thisComponentState, isShowingOnHand: true });

        var arrayOfcodes = [];

        for (var i = 0; i < data.metaData.detailsFromDb.details.length; i++) {

            arrayOfcodes.push(data.metaData.detailsFromDb.details[i]["code"]);
        }

        var codes = arrayOfcodes.join(":");

        var dataToPost = {

            Value: codes
        }

        fetch("PurchaseOrders/GetOnHandQty",
            {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(dataToPost),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }

            })
            .then(response => response.status === 401 ? window.location = "/Loging" : response.json())
            .then(response => {

                //Before presenting get requested quantity.

                for (var i = 0; i < response.onHandInventories.length; i++) {

                    for (var j = 0; j < data.metaData.detailsFromDb.details.length; j++) {

                        if (response.onHandInventories[i].itemCode === data.metaData.detailsFromDb.details[j].code) {

                            response.onHandInventories[i].reqQty = data.metaData.detailsFromDb.details[j].invQty;

                            break;
                        }
                    }
                }

                updateState({ ...thisComponentState, data: response, showOnHand: true,isShowingOnHand: false })
            });
    };

    if (thisComponentState.showOnHand) {

        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <div>

                        <div style={{ float: "left" }}>
                            <input value="Back" onClick={() => updateState({ ...thisComponentState, showOnHand: false })} className="btn btn-primary" style={{ width: "150px" }} type="button" />
                        </div>
                    </div>

                    <br />
                    <br />

                    <table className="table table-bordered">

                        <thead className="thead-dark">

                            <th style={{ position: 'sticky', top: 0 }}>
                                ItemCode
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                ItemName
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                ReqQty
                            </th>


                            {
                                thisComponentState.data.wareHouses.map(warehouse =>
                                    <th key={uuid} style={{ position: 'sticky', top: 0 }}>
                                        {
                                            warehouse
                                        }
                                    </th>

                                )
                            }

                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.onHandInventories.map(detail =>

                                    <tr key={uuid}>

                                        <td> {detail.itemCode} </td>
                                        <td> {detail.itemName} </td>
                                        <td> {detail.reqQty} </td>
                                        {
                                            thisComponentState.data.wareHouses.map(warehouse =>

                                                <td key={uuid}>{getWareHouseQty(detail.details, warehouse)}</td>

                                            )
                                        }

                                    </tr>
                                )
                            }
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }

    const getEditableData = () => {

        fetch("/PurchaseOrders/GetEditableData?" + new URLSearchParams({

            guidId: guidId

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };
                  
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);

                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""

                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, toEdit: false });

                    return;
                }

                return alert("No data available.");
            })
    }



    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const downLoadToExcel = () => {

        var csvString = toCsvString(data.metaData.detailsFromDb.details);
  
        exportToCSV("purchaseRequisitionDetails", csvString);
    }



    return (

        <div style={{ float: "left" }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

            <FontAwesomeIcon icon={faBox} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={getOnHandQty} />

            <FontAwesomeIcon icon={faStopCircle} title="Close" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={close} />

            <FontAwesomeIcon icon={faWindowClose} title="Cancel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={cancel} />

            <FontAwesomeIcon icon={faClone} title="Duplicate" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={duplicate}/>

            <FontAwesomeIcon icon={faEye} title="Preview" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={preview} />

            <FontAwesomeIcon icon={faEdit} title="Edit" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={getEditableData} />

            <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={forward} />
          
            <Link to={{ pathname: "/GoodsReceipt", previousTransactionGuidId: guidId }}>
                <FontAwesomeIcon icon={faReceipt} title="receive" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" />
            </Link>

            {
                thisComponentState.toEdit ?

                    <div style={getModalStyle()}>

                        <div style={getModalContentStyle()}>

                            <input className='btn btn-primary' onClick={getEditableData} value='Back' />

                            <br />
                            <br />

                            <Detail />
                        </div>
                    </div>

                   : <Fragment />
            }

            <FontAwesomeIcon icon={faFileExcel} title="Download to excel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />


           
        </div>

    )

}


