
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp,faAddressBook, faSpinner, faForward, faRemoveFormat} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';
import exportToCSV from '../../Functions/exportToCSV';
import { toCsvString } from '../../Functions/toCsvString';
import AssignedAuditors from './AssignedAuditors';


export default  function PurchaseRequisitionListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        documentId: "",
        employeeId: "",
        branchId:"",
        needDate:"",
        fromDate: "",
        toDate: "",
        approvalStatus: "",
        documentStatus: "",
        itemCode: "",
        isAudited: "",
        note: "",
        assignedAuditors: false,
        forAudit:""

    });


    async function search() {

        updateState({ ...thisComponentState,isLoading:true })

        let params = {};

        if (thisComponentState.fromDate !== "" && thisComponentState.toDate !== "") {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (thisComponentState.documentId !== "" ) {
            params.documentId = thisComponentState.documentId;
        }

        if ( thisComponentState.employeeId !== "") {

            params.employeeId = thisComponentState.employeeId;
        }


        if (thisComponentState.needDate !== "") {

            params.needDate = thisComponentState.needDate;
        }

        if( thisComponentState.branchId !== "") {

            params.branchId = thisComponentState.branchId;
        }

        if ( thisComponentState.approvalStatus != "") {

            params.approvalStatus = thisComponentState.approvalStatus;
        }

        if (thisComponentState.documentStatus != "") {

            params.documentStatus = thisComponentState.documentStatus;
        }

        if (thisComponentState.itemCode !== "") {
            params.itemCode = thisComponentState.itemCode;
        }

        if (thisComponentState.isAudited !== "")
        {
            params.isAudited = thisComponentState.isAudited;
        }

        if (thisComponentState.note !== "") {

            params.note = thisComponentState.note;

        }

        if (thisComponentState.forAudit !== "") {

            params.forAudit = thisComponentState.forAudit;
        }


        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params))

        .then(response =>

            response.status === 401 ? window.location = "/Login" : response.json()

        ).then(response => {

            const dataFromServer = response;

            var tempData = { ...data.metaData };

            tempData.listData = dataFromServer;

            updateMetaData({ metaData: tempData });

            updateState({ ...thisComponentState, isLoading: false });

        });

    };




    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            isLoading: false,
            documentId: "",
            employeeId: "",
            branchId:"",
            needDate: "",
            fromDate: "",
            toDate: "",
            documentStatus: "",
            itemCode: "",
            isAudited: "",
            note:""
        })
    }


    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/StockRequisitions/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>

        );

    };

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, employeeId: code });

    };

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState,branchId: code });

    };

    const updateItemCode = (code) =>
    {
        updateState({ ...thisComponentState,itemCode: code });
    }


    const exportItems = () => {

        if (data.metaData.listData.length === 0)
        {
            return alert("No items queried.");
        }

        exportToCSV("data", toCsvString(data.metaData.listData))
    }

    if (thisComponentState.assignedAuditors) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle("500px","500px")}>

                    <AssignedAuditors back={() => updateState({...thisComponentState,assignedAuditors:false})}/>

                    </div>
                </div>
                    
            )

    }

   

    return (

         <div style={{ textAlign: 'center' }}>

            <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search}type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Export" onClick={exportItems} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Assigned Auditors" onClick={() => updateState({...thisComponentState,assignedAuditors:true})} type="button" />

            <br />
            <br />

            <table className='table table-bordered'>


                <tbody>
                    <tr>
                        <td>
                            <div>From</div>
                        </td>
                        <td>

                            <input className="form-control" title="From Date" value={thisComponentState.fromDate} type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>To</div>
                        </td>
                        <td>
                            <input className="form-control" title="To Date" value={thisComponentState.toDate} type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Need Date</div>
                        </td>
                        <td>
                            <input className="form-control" title="Need Date" value={thisComponentState.needDate} type="date" onChange={(e) => updateState({ ...thisComponentState, needDate: e.currentTarget.value })} />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Document Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." value={thisComponentState.documentId} onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Enter Document Id" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Employee</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={200} url="/Employees/GetEmployees" value={thisComponentState.employeeId} updateValue={updateEmployeeId} placeholder="Employee Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Branch</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Branch Search" />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Approval Status</div>
                        </td>
                        <td>

                            <select className="form-control" value={thisComponentState.approvalStatus} onChange={(e) => updateState({ ...thisComponentState, approvalStatus: e.currentTarget.value })}>
                                <option value="">All</option>
                                <option value="Approved">Approved</option>
                                <option value="UnApproved">UnApproved</option>
                            </select>

                        </td>
                    </tr>



                    <tr>
                        <td>
                            <div>Document Status</div>
                        </td>
                        <td>

                            <select className="form-control" defaultValue={thisComponentState.documentStatus} onChange={(e) => updateState({ ...thisComponentState, documentStatus: e.currentTarget.value })}>
                                <option value="">All</option>
                                <option value="Closed">Closed</option>
                                <option value="Unclosed">Unclosed</option>
                            </select>

                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Is Audited</div>
                        </td>

                        <td>
                            <select className="form-control" value={thisComponentState.isAudited} onChange={(e) => updateState({ ...thisComponentState,isAudited: e.currentTarget.value })}>
                                <option value="">All</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>For Audit</div>
                        </td>

                        <td>
                            <select className="form-control" value={thisComponentState.forAudit} onChange={(e) => updateState({ ...thisComponentState, forAudit: e.currentTarget.value })}>
                                <option value="">All</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </td>
                    </tr>

                    <tr>

                        <td>Note</td>

                        <td>
                            <input value={thisComponentState.note} onChange={(e) => updateState({ ...thisComponentState, note: e.currentTarget.value })} className="form-control" />
                        </td>

                    </tr>

                    <tr>
                        <td>
                            <div>Item Code</div>
                        </td>
                        <td>
                            <StandardAutoComplete url="/Inventories/GetInventories" value={thisComponentState.itemCode} updateValue={updateItemCode} placeholder="All transactions that has this item" />
                        </td>
                    </tr>                 
                </tbody>
            </table>

            <br />
            <br />

        </div>


     
    )

}

