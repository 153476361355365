
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp,faAddressBook, faSpinner, faForward, faRemoveFormat} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';
import uuid from 'react-uuid';
import exportToCSV from '../../Functions/exportToCSV';
import { toCsvString } from '../../Functions/toCsvString';


export default  function GoodsIssueListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        documentId: "",
        branchId:"",
        sapId:"",
        empId: "",
        depId: "",
        fromDate: "",
        toDate: "",
        itemCode:""

    });


    async function search() {

        if (thisComponentState.fromDate > thisComponentState.toDate) {
            return alert("From date should be less than to date");
        };

        updateState({ ...thisComponentState, isLoading: true })

        let params = {};

        if ( thisComponentState.fromDate !== "" && thisComponentState.toDate!=="") {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (thisComponentState.documentId !== "") {
            params.documentId = thisComponentState.documentId;
        }

        if (thisComponentState.empId !== "") {

            params.empId = thisComponentState.empId;
        }

        if (thisComponentState.depId !== "" ) {

            params.depId = thisComponentState.depId;
        }

        if (thisComponentState.branchId !== "") {

            params.branchId = thisComponentState.branchId;
        }

        if (thisComponentState.itemCode !== "") {

            params.itemCode = thisComponentState.itemCode;
        }

        if (thisComponentState.sapId !== "") {

            params.sapId = thisComponentState.sapId;
        }

        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params)).then(response =>

            response.status == 401 ? window.location = "/Login" : response.json()

        ).then(response => {

            const dataFromServer = response;

            var tempData = { ...data.metaData };

            tempData.listData = dataFromServer;

            updateMetaData({ metaData: tempData });

            updateState({ ...thisComponentState, isLoading: false });

        });

    };




    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            documentId: "",
            sapId:"",
            empId: "",
            depId : "",
            fromDate:"",
            toDate: "",
            itemCode:""
        })
    }


    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/StockRequisitions/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>

        );

    };

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, empId: code });
    };


    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });

    };


    const updateDepartmentId = (code) => {

        updateState({ ...thisComponentState, depId: code });
    };


    const updateItemCode = (code) => {

        updateState({ ...thisComponentState, itemCode: code });
    };


    const exportItems = () => {

        if (data.metaData.listData.length === 0) {
            return alert("No items queried.");
        }

        exportToCSV("data", toCsvString(data.metaData.listData))

    }

   

    return (

        <div style={{ textAlign: 'center' }}>

            <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Export" onClick={exportItems} type="button" />

            <br />
            <br />

            <table className='table table-bordered'>

                <tbody>

                    <tr>
                        <td>
                            <div>From</div>
                        </td>
                        <td>
                            <input className="form-control" title="From Date" type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>To</div>
                        </td>
                        <td>
                            <input className="form-control" title="To Date" type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Document Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Doc Id search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Sap Id</div>
                        </td>
                        <td>
                            <input className="form-control" title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState,sapId: e.currentTarget.value })} placeholder="Sap Id search" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>Employee</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={200} url="/Employees/GetEmployees" value={thisComponentState.empId} updateValue={updateEmployeeId} placeholder="Employee Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Department</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={200} url="/Departments/GetDepartments" value={thisComponentState.depId} updateValue={updateDepartmentId} placeholder="Department Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Branch</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Branch Search" />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Item Code</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Inventories/GetInventories" value={thisComponentState.itemCode} updateValue={updateItemCode} placeholder="All transactions that has this item" />
                        </td>
                    </tr>


                </tbody>
            </table>

            <br />
            <br />

        </div>
    )
}

