import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCross, faX } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import StandardAutoComplete from '../../StandardAutocomplete';
import { Link } from 'react-router-dom';
import getModalStyle from '../../Functions/getModalStyle';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import CratesToCharge from './CratesToCharge';
import uuid from 'react-uuid';



export default function AgentsToCharge(props) {

    const [thisComponentState, updateState] = useState({

        isLoading: false,
        washedId:0,
        values: [],
        toCharge:false
    });

    useEffect(() => {

        updateState({...thisComponentState,isLoading:true})

        fetch("/Crates/GetAgentsWithCharges")

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                 updateState({...thisComponentState,values:response,isLoading:false})
              
            });

    }, []);


    useEffect(() => { }, [thisComponentState.agentId,thisComponentState.chargedDate]);

  
    if (thisComponentState.toCharge) {
        return (
           <CratesToCharge washedId={thisComponentState.washedId} back={() => updateState({...thisComponentState,toCharge:false})} />            
       )
    }

    return (

        <div>

            <div className="row">
                <div className="col">
                    <div className="col">
                        <input value="Back" className="form-control" type="button" onClick={() => props.back()} />
                    </div>
                </div>
            </div>
       
            <br />

            <div className="row">
                <div className="col-sm-12">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <td>Action</td>
                                <td>Washed Id</td>
                                <td>Name</td>
                                <td>Washed Date</td>                             
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.values.map(data =>


                                    <tr key={uuid()}>
                                        <td>
                                            <input value="Details" type="button" className="form-control" onClick={() => updateState({...thisComponentState,washedId:data.washedId,toCharge:true})}/>
                                        </td>

                                        <td> {data.washedId}  </td>
                                        <td> {data.name} </td>
                                        <td> {data.washedDate} </td>
                                     
                                    </tr>)
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>     
 )
}

