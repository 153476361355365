import React, { useEffect, useState, useContext, Fragment } from "react";
import { MasterfileContext } from "./MasterfileContext";
import "../style.css"
import getPropertyValueFromAutoCompleteSuggestions from "../Functions/getPropertyValue";

function AutoCompleteInputForMaterfile(props) {

    const { updateParentValue } = useContext(MasterfileContext);

    const [value, updateValue] = useState({
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: props.value,

        dataType: props.dataType,

        url:props.url,

        name: props.name,

        isLoaded: true,

        code: props.code,

        value: props.value,

        suggestions: props.suggestions
    });

    useEffect(() => {

        fetchData();

    }, []);

    async function fetchData() {

        console.log("what is url value", value.url);

        await fetch(value.url)
            .then(response => response.status ===401 ? window.location = "/login" : response.json())
            .then(response => {

                console.log("response is ", response);

                updateValue({ ...value, isLoaded: true, suggestions: response });

            });

    }

    const onChange = (e) => {
        const userInput = e.currentTarget.value;
        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = value.suggestions.filter(
            (suggestion) =>
                suggestion.description.toLowerCase().indexOf(userInput.toLowerCase()) >
                -1
        );

        updateValue({
            ...value,
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    const onClick = (e) => {

        var text = e.target.getAttribute("text");

        updateValue({
            ...value,
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });

        updateParentValue(props.name,text);
      
    };

    const onKeyDown = (e) => {

        const { activeSuggestion, filteredSuggestions } = { ...value };

        // User pressed the enter key
        if (e.keyCode === 13) {
            updateValue({
                ...value,
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            updateValue({ ...value, activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            // this.setState({ activeSuggestion: activeSuggestion + 1 });

            updateValue({ ...value, activeSuggestion: activeSuggestion + 1 });
        }
    };

    if (!value.isLoaded) {
        return <div>Loading data</div>;
    } else {
        let suggestionsListComponent;

        if (value.showSuggestions && value.userInput) {
            if (value.filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {value.filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === value.activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li
                                    className={className}
                                    key={suggestion.code}
                                    onClick={onClick}
                                    text={suggestion.code}
                                >
                                    {suggestion.description}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions, you're on your own!</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    key={props.key}
                    type="text"
                    className="form-control"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={value.value !==null && value.value !== ""  ? getPropertyValueFromAutoCompleteSuggestions(value.value, value.suggestions) : value.userInput}

                    
                  
                />


                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default AutoCompleteInputForMaterfile;