
function convertToDataType(metaData,property,value) {
   
    var htmlType = "";

    for (var i = 0; i < metaData.length; i++) {

        if (metaData[i].name.toLowerCase() === property.toLowerCase()) {

            htmlType = metaData[i].htmlType;

            break;

        }
    };

    switch (htmlType) {

        case "number":
        case "enum":

            return parseFloat(value);

        case "date":
         
            return value;

        case "checkbox":

            if (value === null) {

                return false;

            } else {
                return value;
            }

         default :

            return value;

    }

}

export default convertToDataType;