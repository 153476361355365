import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import store from './Store'
import {PersistGate} from 'redux-persist/integration/react'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');




ReactDOM.render(

    <BrowserRouter basename={baseUrl}>

        <Provider store={store().store}>
            <PersistGate loading={null} persistor={store().persistor}>
                <App />
            </PersistGate>
        </Provider>

     </BrowserRouter>
,
  rootElement);

registerServiceWorker();

