import { faArrowLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import DynamicInput from "../../../DynamicInput";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";

export default function ItemPriceHistory(props) {


    const [thisComponentState, updateState] = useState({

        itemCode: "",
        isLoading: false,
        from: "",
        to:"",
        details: [],
        captureMultipleItems:false

    });


    const generate = (itemCode) => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/PurchaseOrders/GetItemPriceHistory?" + new URLSearchParams({
            itemCode :itemCode
            }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.itemCode = itemCode;
                tempData.captureMultipleItems = false;

                updateState(tempData);

                return;
            });
    }


    const updateItemCode = (itemCode) => {

        generate(itemCode);

    }

    const back = (inputs) => {

     
        generate(inputs.join(","));


    }

    if (thisComponentState.captureMultipleItems) {

        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <DynamicInput back={back} />

                </div>

            </div>
            
            )
    }


    return (


        <div style={{paddingTop:"5px"} }>

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <br />

                    <div className="row">
                        <div className="col">

                            <div style={{ float: 'left' }}>
                                <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />  
                            </div>

                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="col-sm-7">
                                    <StandardAutoComplete key={200} url="/Inventories/GetInventories" value={thisComponentState.itemCode} clear={() => updateState({ ...thisComponentState, itemCode: "" })} updateValue={updateItemCode} placeholder="Item Search" />
                                </div>

                                <div className="col-sm-5">
                                    
                                    <input value="Multiple" type="button" className="form-control" onClick={() => updateState({ ...thisComponentState, captureMultipleItems: true })}/>

                                </div>
                                
                            </div>  


                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.from} onChange={(e) => updateState({...thisComponentState,from:e.currentTarget.value})} className="form-control"/>
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.to} onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} className="form-control" />
                        </div>


                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>                                     
                                        <th > Action</th>
                                        <th> Sap Id </th>
                                        <th> Doc Id </th>
                                        <th> Date </th>
                                        <th> Code</th>
                                        <th> Name</th>

                                        <th> Quantity </th>
                                        <th> Uom</th>
                                        <th> Price </th>

                                    </tr>

                                </thead>

                                <tbody>

                                    {
                                        typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                            <tr>
                                              
                                                <td>
                                                    <input value="Details" className="form-control" type="button" onClick={() => updateState({ ...thisComponentState, selectedGuidId: val.guidId, showDetails: true })} />
                                                </td>

                                                <td> {val.sapId}</td>
                                                <td> {val.documentId}</td>
                                                <td> {val.date}</td>
                                                <td> {val.code}</td>
                                                <td> {val.name}</td>


                                                <td> {val.quantity}</td>
                                                <td> {val.uomDesc}</td>
                                                <td> {val.price}</td>

                                            </tr>)
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
