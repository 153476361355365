import { faArrowLeft, faArrowRight, faFilter, faForward, faListAlt, faLock, faRefresh, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import MultiplePrDetailsForPoReview from "./MulitplePrDetailsForPoReview";
import OpenPrFilter from "./OpenPrFilter";
import PrDetailsForPoReview from "./PrDetailsForPoReview";

export default function RequestedPrForClosure(props) {


    const [thisComponentState, updateState] = useState({

        details: [],      
        showDetails: false,
        selectedGuidId:null,
        isLoading: true,
        retrieve: 0,
        selectedDocId: 0,
        isFilterApplied:false

    });

    useEffect(() => {

        fetch("/PurchaseOrders/GetListOfOpenPRforClosure?")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
               
                updateState(tempData);

            });

    }, [thisComponentState.retrieve]);



    
    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)

    }


    if (thisComponentState.showDetails) {

        return (<PrDetailsForPoReview guidId={thisComponentState.selectedGuidId} back={() => updateState({...thisComponentState,showDetails:false,retrieve:1})}/>)
    }

    const close = (guidId) => {

        const isConfirm = window.confirm("Are you sure you want to close this Pr? This action is irreversible.");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })
   
        fetch("/PurchaseOrders/ClosePr", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                value: guidId
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false,retrieve:thisComponentState.retrieve+1 })

                return alert(response.message);

            });
    }

    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Closing...
                    </button>
                </div>
            </div>
        </div>)
    }

    const filter = () => {

        let tempDatas = [...thisComponentState.details];

        tempDatas = tempDatas.filter(val => val.docId === parseInt(thisComponentState.selectedDocId));

        updateState({ ...thisComponentState, details: tempDatas });
    }





    return (
        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">

                    <div className="col">

                        <div style={{ float: 'left' }}>

                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
                            <FontAwesomeIcon icon={faRefresh} title="refresh items" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({...thisComponentState,retrieve:thisComponentState.retrieve+1})} />

                        </div>
                       
                    </div>

                    <div className="col">
                       
                        <input className="form-control" placeholder="Search by Doc Id" onChange={(e) => updateState({...thisComponentState,selectedDocId:e.currentTarget.value })} onBlur={filter}/>

                    </div>

                    <div className="col">

                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th> Series</th>
                                    <th colSpan="2"> Action</th>
                                    <th> Requisition Date </th>
                                    <th> Required Date </th>
                                    <th> Doc Id </th>
                                    <th> Requestor </th>
                                    <th> Branch</th>
                                    <th> Remarks </th>
                                    <th> Request to close by</th>
                                    <th> Reason </th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map((val,index) =>

                                        <tr key={uuid()}>

                                           <td>
                                               {index+1}
                                           </td>

                                           <td>
                                               <FontAwesomeIcon icon={faForward} title="Close this PR" style={{ cursor: 'pointer' }} size="1x" color="cyan" onClick={() => close(val.guidId)} />
                                           </td>

                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedGuidId:val.guidId,showDetails:true}) } />
                                            </td>

                                            <td> {val.requestDate}</td>
                                            <td> {val.requiredDate}</td>
                                            <td> {val.docId}</td>
                                            <td> {val.requestor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.remarks}</td>
                                            <td> {val.requestToCloseBy}</td>
                                            <td> {val.reason}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
