import { faForward, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab } from 'bootstrap';
import React, { Fragment, useContext, useState } from 'react';
import { TransactionContext } from '../TransactionContext';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';

function DetailsInvoiceNavigator(props) {

    const { guidId } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        data: [],
        toSync: false,
        date:null
    })

    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/Invoices/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }


    const generate = () => {

        fetch("/Invoices/generate?" + new URLSearchParams({

            date: thisComponentState.date

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.length>0) {

                    updateState({ ...thisComponentState, data: dataFromServer, toSync: true });

                    return;
                }

                return alert("No data available.");

            })
    }




    const sync=()=> {


        for (let i = 0; i < thisComponentState.data.length; i++) {
 
                var dataToPost = {

                    value: thisComponentState.data[i].guidId
                };

                fetch("/Invoices/Sync", {

                    // Adding method type
                    method: "POST",

                    // Adding body or contents to send
                    body: JSON.stringify(dataToPost),

                    // Adding headers to the request
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                    .then(response => {

                         if (response.success) {

                             var tempData = [...thisComponentState.data];

                             tempData[i].message = response.message;

                             updateState({...thisComponentState, data: tempData });
                             
                        }

                    }); 
        }
    }



    if (thisComponentState.toSync) {

        return (

            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>
                    <input value="Sync" onClick={sync }/>

                    <table className='table table-bordered'>

                        <thead className='thead-dark'>
                            <th> Guid Id </th>
                            <th> Message </th>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.map(invoice => <tr> <td>{invoice.guidId}</td> <td>{invoice.message}</td>  </tr>)
                            }


                        </tbody>


                    </table>
                </div>

            </div>

           
                    
            
            
            )
        



    }



            return (

                    <div style={{ float: "left" }}>
           
                        <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

                        <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={forward} />

                    </div>
        
            )
    
}


export default DetailsInvoiceNavigator;