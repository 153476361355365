import React from 'react';
import { useState } from 'react';
import getModalContentStyle from '.././Functions/getModalContentStyle';
import getModalStyle from '.././Functions/getModalStyle';
import {useSelector, useDispatch } from 'react-redux';
import { incNum, zeroIt } from '../../ActionCreator';
import Home from '../Home';
import ChangePassword from './ChangePassword';
import { useEffect } from 'react';

export default function Login() {

    const [thisComponentState, updateState] = useState({

        email: "",
        password: "",
        userToken: "",
        phoneToken : "",
        requiresTwoFactor: false,
        isLoggingIn: false,
        RequireChangePasswordOnNextLogIn: false,
        errorEmailClass: {},
        errorPasswordClass: {}
    });


    const mystate = useSelector((state) => state.action);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(zeroIt());

        document.addEventListener("keyup", (e) => {

            if (e.keyCode === 13) {

                var logInButton = document.getElementById("login");

                if (logInButton !== null) {

                    logInButton.click();
                }
            }
        });

        localStorage.removeItem("purchaseRequisitionGuidId");
        localStorage.removeItem("stockRequisitionGuidId");
        localStorage.removeItem("jobOrderId");
        localStorage.removeItem("purchaseOrderGuidId");

    }, []);

  
    const Login = () => {

        if (thisComponentState.email === "") {
             
            updateState({ ...thisComponentState,errorEmailClass: {border:"1px solid red"}})

            return;
        }
        if (thisComponentState.password === "") {

            updateState({ ...thisComponentState, errorPasswordClass: { border: "1px solid red" } })

            return;
        }

        localStorage.removeItem("masterfiles");

        updateState({ ...thisComponentState, isLoggingIn:true});

        fetch('/Identity/Login', {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status ===401 ? window.location ="/login" : response.json())
          .then(content => {

              if (content.success) {

                  if (content.requireChangePasswordOnNextLogIn) {

                      updateState({ ...thisComponentState, requireChangePasswordOnNextLogIn: true, isLoggingIn: false });

                      return;
                  }

                  if (content.requiresTwoFactor && !content.twoFactorPassed) {

                      updateState({ ...thisComponentState, requiresTwoFactor: true, userToken: content.userToken, isLoggingIn: false });

                  } else {

                      dispatch(incNum());

                      if (typeof content.informUserOfMultipleLogin !== 'undefined' && content.InformUserOfMultipleLogin) {

                          return alert(content.message);

                      };
                  }

              } else {

                  alert(content.message);
              }

              updateState({ ...thisComponentState, isLoggingIn: false });
          })
    }


    const Verify = () => {

        updateState({ ...thisComponentState, isLoggingIn: true });

        var dataToPost = {

            email: thisComponentState.email,
            userToken: thisComponentState.userToken,
            phoneToken: thisComponentState.phoneToken
        };

        fetch('/Identity/LogInWithTwoTactor', {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (response.success) {

                    window.location = "/Home";

                } else {

                    updateState({ ...thisComponentState, isLoggingIn: false });

                    return alert("Invalid Credentials");
                };

            })
    }



    if (thisComponentState.isLoggingIn) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Authenticating...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.requireChangePasswordOnNextLogIn) {

        return (<ChangePassword email={thisComponentState.email}/>)

    }

    if (thisComponentState.requiresTwoFactor) {

        return (
            <section className="vh-100" style={{ backgroundColor: "#eee" }} >
                <div className="container h-100" style={getModalStyle()}  >
                    <div className="row d-flex justify-content-center align-items-center h-100" style={getModalContentStyle() } >
                        <div className="col-lg-12 col-xl-11">
                            <div className="card text-black" style={{ borderRadius: "25px" }}>
                                <div className="card-body p-md-5">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                            <form className="mx-1 mx-md-4">
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input type="email" id="form3Example3c" className="form-control" value={thisComponentState.phoneToken} onChange={(e) => updateState({ ...thisComponentState,phoneToken: e.currentTarget.value })} />
                                                        <label className="form-label" >Enter Security Code</label>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                    <button type="button" className="btn btn-primary btn-lg" onClick={Verify} >Verify</button>
                                                </div>                                        
                                            </form>

                                        </div>
                                        <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )

    }


    if (mystate === 0) {

        return (
                <section className="vh-100" style={{ backgroundColor: "#eee" }}>
                    <div className="h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-lg-12 col-xl-11">
                                <div className="card text-black" style={{ borderRadius: "25px" }}>
                                    <div className="card-body p-md-5">
                                    <div className="row justify-content-center align-items-center">
                                            <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Login</p>

                                                <form className="mx-1 mx-md-4">

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                        <input type="email" id="email" className="form-control" style={thisComponentState.errorEmailClass} onChange={(e) => updateState({ ...thisComponentState, email: e.currentTarget.value, errorEmailClass: {}})} />
                                                            <label className="form-label" >Your Email</label>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                        <input type="password" id="password" className="form-control" style={thisComponentState.errorPasswordClass} autoComplete="new-password" onChange={(e) => updateState({ ...thisComponentState, password: e.currentTarget.value, errorPasswordClass: {}})} />
                                                            <label className="form-label" >Password</label>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">

                                                            <button type="button" id="login" className="btn btn-primary btn-lg" onClick={Login} >Login</button>

                                                          
                                                    </div>

                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">

                                                    <a href="#"> Forgot Password  </a>    


                                                </div>

                                                </form>

                                            </div>
                                            <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    
     } else {

        return (<Home/>)

    }

                 
}