
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp,faAddressBook, faSpinner, faRemoveFormat, faFileExcel} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';


function OrderListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        approvedTransaction:false,
        documentId: 0,
        agentId: "",
        customerId :"",
        fromDate: null,
        toDate: null,
        transactionStatus:null

    });


    async function Search() {

        updateState({ ...thisComponentState,isLoading:true })

        await fetch(data.metaData.listUrl + "?" + new URLSearchParams({

            documentId: thisComponentState.documentId===null || thisComponentState.documentId===undefined || thisComponentState.documentId==="" ? 0 : thisComponentState.documentId,
            agentId: thisComponentState.agentId === null || thisComponentState.agentId === undefined || thisComponentState.agentId === "" ? "" : thisComponentState.agentId,
            customerId:thisComponentState.customerId===null || thisComponentState.customerId===undefined || thisComponentState.customerId==="" ? "":thisComponentState.customerId,
            fromDate: thisComponentState.fromDate === null || thisComponentState.fromDate === undefined || thisComponentState.fromDate === null ? 0 : thisComponentState.fromDate,
            toDate: thisComponentState.toDate === null || thisComponentState.fromDate === undefined || thisComponentState.toDate === null ? 0 : thisComponentState.toDate,
            transactionStatus :thisComponentState.transactionStatus

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

        .then(dataFromServer => {

            var tempData = { ...data.metaData };

            tempData.listData = dataFromServer;

            updateMetaData({ metaData: tempData });

            updateState({ ...thisComponentState, isLoading: false })

       })

    };


    const updateCustomerId = (code) => {

        updateState({ ...thisComponentState, customerId: code })
    };

    const updateAgentId = (code) => {

        updateState({ ...thisComponentState, agentId: code })
    };

    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            approvedTransaction: false,
            documentId: 0,
            agentId: "",
            customerId: "",
            fromDate: null,
            toDate: null,
            transactionStatus:null
        })
    }

    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/Orders/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>

        );

    };




    return (

        <div style={{ float: "left", display: "inline-block" }}>

            <input className="form-control" title="This will override all other criterias." style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Doc Id search" />

      
            <StandardAutoComplete key={100} url="/Customers/GetCustomers" updateValue={updateCustomerId} style={{ width: "150px", float: "left", marginLeft: "15px" }} placeholder="Customer Search"/>

            <StandardAutoComplete key={200} url="/Agents/GetAgents" updateValue={updateAgentId} style={{ width: "150px", float: "left", marginLeft: "15px" }} placeholder="Agent Search"/>


            <input className="form-control" title="From Date" style={{ width: "150px", float: "left", marginLeft: "1px" }} type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />

            <input className="form-control" title="To Date" style={{ width: "150px", float: "left", marginLeft: "1px" }} type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

            <FontAwesomeIcon icon={faRemoveFormat} style={{ marginLeft: "10x", cursor: 'pointer' }} onClick={clear} size="2x" color="green" />

            <FontAwesomeIcon icon={faThumbsUp} style={{ marginLeft: "10px", cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, transactionStatus: 1 })} />

            <FontAwesomeIcon icon={faThumbsDown} style={{ marginLeft: "10px", cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, transactionStatus: 0 })} />

          

            <FontAwesomeIcon icon={faAddressBook} style={{ marginLeft: "10px", cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, approvedTransaction: true })} title="List of Transactions for Approval"  />

            <FontAwesomeIcon icon={faFileExcel} style={{ marginLeft: "10px", cursor: 'pointer' }} size="2x" color="green" />



            <input className="form-control" style={{ width: "80px", float: "left", marginLeft: "10px" }} value="Go" onClick={Search} type="button"/>
            <input className="form-control" style={{ width: "80px", float: "left", marginLeft: "10px",marginRight:"10px" }} value="New" onClick={()=> window.location.reload()} type="button" />

      
            <br />
            <br />

        </div>

    )

}


export default OrderListNavigator;