import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react"
import uuid from "react-uuid";


export default function IncentiveBasedSchedules(props) {

    const textStyle = {
        display: 'inline-block',
        transform: 'rotate(-45deg)',
        transformOrigin: 'left bottom',
        position: 'absolute',
        bottom: '0'
    };


    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: {},
        from: "",
        to: ""
    });

    useEffect(() => {

        fetch("/Invoices/GetIncentiveBasedSchedule?" + new URLSearchParams({
            code: props.code,
            from: props.from,
            to: props.to
        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, data:response, isLoading: false });

            });

    }, []);


    if (thisComponentState.isLoading) {

        return (<div>Please wait...</div>)
    }


    return (

        <div>
            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                </div>
               
            </div>

            <hr />

            <div>

                <table className="table table-bordered">
                    <thead >

                        <th>Employee</th>

                        {
                            thisComponentState.data.values.map(data => <th> { data.day } </th>)
                        }

                        <th>Total</th>
                      
                    </thead>

                    <tbody>
                        <tr>

                            <td>As Agent</td>

                            {
                                thisComponentState.data.values.map(data =>
                                    <td>
                                        {
                                            data.isAgent ? <div style={{ textAlign: "center" }}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            
                                            </div> : <div></div>
                                        }
                                    </td>
                                )
                            }

                            <td>
                                {thisComponentState.data.agentTotal}
                            </td>

                        </tr>

                        <tr>
                            <td>As Driver</td>

                            {

                                thisComponentState.data.values.map(data =>
                                    <td>
                                        {
                                            data.isDriver ? <div style={{ textAlign: "center" }}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </div> : <div></div>
                                        }
                                    </td>
                                )
                            }


                            <td>
                                {thisComponentState.data.driverTotal}
                            </td>

                        </tr>

                        <tr>
                            <td>As Helper</td>

                            {
                                thisComponentState.data.values.map(data =>
                                    <td>
                                        {
                                            data.isHelper ? <div style={{ textAlign: "center" }}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </div> : <div></div>
                                        }
                                    </td>
                                )
                            }

                            <td>
                                {thisComponentState.data.helperTotal}
                            </td>

                        </tr>

                        <tr>
                            <td>Total</td>

                            {
                                thisComponentState.data.values.map(data =>
                                    <td>
                                        {data.total}
                                    </td>
                                )
                            }

                            <td>
                                {thisComponentState.data.agentTotal+thisComponentState.data.driverTotal+thisComponentState.data.helperTotal}
                            </td>
                        </tr>

                    </tbody>

                </table>


             
            </div>

        </div>

    )

}