
import React, { useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';




export default function SyncProduction(props)
{

    const [thisComponentState, updateState] = useState({

        isLoading: false,
        isSyncing:false,
        date:null,
        values: []
    });




    const retrieve = () => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/Productions/GetProductionForForwarding?" + new URLSearchParams({

            date: thisComponentState.date

            })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.length === 0) {

                    return alert("No data found.");
                }

                updateState({
                    ...thisComponentState,
                    isLoading:false,
                    values : response
                })
            });
    }

    const sync = (guidId) => {

        updateState({ ...thisComponentState, isSyncing: true });

        fetch("/Productions/Forward", {
            method: "post",
            body: JSON.stringify({

                value: guidId
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState.values];

                    let tempData = tempDatas.find(val => val.guidId === guidId);

                    tempData.sapId = response.docNum;

                    updateState({ ...thisComponentState, values: tempDatas, isSyncing: false });

                    return;

                } else {

                    updateState({ ...thisComponentState, isSyncing: false });

                   return alert(response.message);

                }
         });

    }

    if (thisComponentState.isSyncing) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Syncing...
                    </button>
                </div>
            </div>
        </div>)

    }


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>

                <div className="row">

                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={()=> props.back()}/>
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" onChange={(e) => updateState({...thisComponentState,date:e.currentTarget.value})}/>
                    </div>

                    <div className="col">
                        <input value="Retrieve" type="button" onClick={retrieve } className="form-control"/>
                    </div>
                  
                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                        <table className="table table-bordered">

                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Item Code</th>
                                    <th>Sap Id</th>  
                                    <th>Description</th>
                                    <th>Good</th>
                                    <th>Reject</th>
                                    <th>Bargain</th>
                                </tr>
                            </thead>


                            <tbody>
                                {
                                    typeof thisComponentState.values !== 'undefined' && thisComponentState.values.map((val) =>

                                        <tr key={val.guidId}>

                                            <td>
                                                <input type="button" className="form-control" value="Sync" onClick={()=>sync(val.guidId)}/>
                                            </td>

                                            <td> {val.itemCode}</td>
                                            <td> {val.sapId}</td>
                                            <td> {val.name}</td>
                                            <td> {val.good}</td>
                                            <td> {val.reject}</td>
                                            <td> {val.bargain}</td>

                                        </tr>)
                                }
                            </tbody>

                        </table>
                    </div>  
                </div>
            </div>
        </div>
        
        
   )
}