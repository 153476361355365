import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";



export default function PoAttachments(props) {

    const [thisComponentState, updateState] = useState({

        isLoading: true,
        guidId: props.guidId,
        attachments: [],
        isPerformingOperation: false

    });

    useEffect(() => {

        fetch("/PurchaseOrders/GetAttachments?" + new URLSearchParams({

            guidId: thisComponentState.guidId

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                var tempData = { ...thisComponentState };

                tempData.attachments = response;
                tempData.isLoading = false;

                updateState(tempData);

            });

    }, []);

    const deleteAttachment = (id) => {

        var yes = window.confirm("Are you sure...You want to delete this?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: parseInt(id)
        };

        fetch("/PurchaseOrders/deleteAttachment", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (!response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false });

                    return alert(response.message);
                }

                let tempData = [...thisComponentState.attachments];

                tempData = tempData.filter(val => val.id !== id);

                updateState({ ...thisComponentState, attachments: tempData, isPerformingOperation: false });

                return alert(response.message);

            })

    }


    if (thisComponentState.isLoading) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)


    }


    const preview = (id) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/PurchaseOrders/PreviewAttachment?id=";

        fetch(url + id,
            {
                method: "GET",
                headers: {
                    "Accept": "application/octet-stream"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    return (

        <div style={getModalStyle()}>

         

            <div style={getModalContentStyle()}>
                <div className="row">
                    <div className="col">
                        <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>

                </div>
                <hr/>

                <div className="row">

                    <div className="col">
                        Action
                    </div>

                    <div className="col">
                        File Name
                    </div>
                    <div className="col">
                        View
                    </div>

                </div>



                {
                    thisComponentState.attachments.map(val =>

                        <div className="row">

                            <div className="col">
                                <input type="button" className="form-control" value="Delete" onClick={() => deleteAttachment(val.id)} />
                            </div>

                            <div className="col">

                                <div className="form-control">
                                    {val.name}
                                </div>
                               
                            </div>
                            <div className="col">
                                <input type="button" className="form-control" value="View" onClick={() => preview(val.id)} />
                            </div>

                        </div>
                    )
                }


            </div>


        </div>
    )
}