import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";





export default function SourceWarehouse(props)
{
    const [thisComponentState, updateState] = useState({
        sourceWarehouse: ""
    })


    const updateWarehouseId = (id) => {

        updateState({ ...thisComponentState, sourceWarehouse: id });
    }

    const back = () => {


        props.move(thisComponentState.sourceWarehouse);

    }


    return (
        <div>
            <br/>
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("500px", "500px")}>

                    <div className="row">
                    
                        <div className="col">
                            <input type="button" value="Back" className="form-control" onClick={back} />
                        </div>
                    </div>


                    <hr/>

                    <div className="row">
                        <div className="col">
                            Source Warehouse
                        </div>
                        <div className="col">
                            <StandardAutoComplete key={200} url="/Warehouses/GetWarehouses" value={thisComponentState.requestorId} clear={() => updateState({ ...thisComponentState, requestorId: "" })} updateValue={updateWarehouseId}  />
                        </div>
                    </div>
                </div>


            </div>
        </div>

   )
}