
import { faAward, faHandHoldingDollar, faRedo, faSnowflake, faSync, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import UploadFile from '../../../UploadFile';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import Incentive from './Incentive';
import IncomingPaymentForSyncBranch from './IncomingPaymentForBranch';
import Service from './Service';
import SmSales from './SmSales';
import SyncBranch from './SyncBranch';
import SyncInfo from './SyncInfo';


export default function CreateInvoiceNavigator() {

    const { data, updateMetaData } = useContext(TransactionContext);

    let customerGuidId = data.metaData.parentProperties.code;

    const [thisComponentState, updateState] = useState({

        calledCategories: [],
        showOrders: false,
        showSyncInfo: false,
        syncBranch: false,
        applyPayment: false,
        uploadSmSales: false,
        showIncentive: false,
        showServiceCapture:false

    });

    const getProducts = (e) => {

   
        let categoryId = parseInt(e.currentTarget.getAttribute("categoryid"));

        if (thisComponentState.calledCategories.includes(categoryId)) {

            var tempMetaData = { ...data.metaData };

            tempMetaData.detailProperties.forEach(detailProperty => {

                if (detailProperty.presentationValue.categoryId === categoryId) {

                    detailProperty.presentationValue.toShow = true;

                } else {

                    detailProperty.presentationValue.toShow = false;
                }

            });

            updateMetaData({ metaData: tempMetaData });


        } else {

            fetch("/Base/GetFilteredProducts" + "?" + new URLSearchParams({

                categoryId: categoryId,
                customerId: customerGuidId

            }))

                .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                .then(response => {


                    if (response.length === 0) {

                        return;
                    };

                    var tempMetaData = { ...data.metaData };

                    var detailProperties = createDetailProperties(response);

                    //defined presentation object

                    var presentationValue = {

                        toShow: false,
                        categoryId: categoryId

                    };

                    detailProperties.forEach(detailProperty => detailProperty.presentationValue = presentationValue);

                    detailProperties.map(detailProperty => tempMetaData.detailProperties.push(detailProperty));

                    tempMetaData.detailProperties.forEach(detailProperty => {

                        if (detailProperty.presentationValue.categoryId === categoryId) {

                            detailProperty.presentationValue.toShow = true;

                        } else {

                            detailProperty.presentationValue.toShow = false;
                        }

                    });

                    updateMetaData({ metaData: tempMetaData });

                    let calledCategories = [...thisComponentState.calledCategories];

                    if (!calledCategories.includes(parseInt(categoryId))) {

                        calledCategories.push(parseInt(categoryId));

                    };

                    updateState({ ...thisComponentState, calledCategories: calledCategories });

                });
        }
    }


   
    if (thisComponentState.showSyncInfo) {

        return (<SyncInfo back={() => updateState({ ...thisComponentState, showSyncInfo: false })}/>)
    }


    if (thisComponentState.syncBranch) {
        return (<SyncBranch back={() => updateState({...thisComponentState,syncBranch:false })} />)
    }

    if (thisComponentState.applyPayment) {
        return (<IncomingPaymentForSyncBranch back={() => updateState({ ...thisComponentState, applyPayment: false })} />)
    }

    if (thisComponentState.uploadSmSales) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <SmSales back={() => updateState({uploadSmSales:false})} />
                </div>
            </div>
       )
    }

    if (thisComponentState.showIncentive)
    {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Incentive back={() => updateState({ ...thisComponentState, showIncentive: false })} />
                </div>
            </div>

        )
    }

    if (thisComponentState.showServiceCapture) {

        return (

             <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Service back={() => updateState({...thisComponentState,showServiceCapture:false})} />
                </div>
            </div>
        )

    }

    return (

         <div style={{ float: "left" }}>                 
            <FontAwesomeIcon icon={faRedo} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faSync} title="Sync Branch Sales" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({...thisComponentState,syncBranch:true})} />
            <FontAwesomeIcon icon={faUpload} title="Upload SM sales" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orang" onClick={() => updateState({ ...thisComponentState, uploadSmSales: true })} />
            <FontAwesomeIcon icon={faHandHoldingDollar} title="Apply payments" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, applyPayment: true })} />
            <FontAwesomeIcon icon={faAward} title="Show Incentive" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showIncentive: true })} />
            <FontAwesomeIcon icon={faSnowflake} title="Service Types" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showServiceCapture: true })} />

        </div>

    )

}

