import { faArrowLeft, faArrowRight, faL, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import uuid from 'react-uuid';
import { UncontrolledCollapse } from 'reactstrap';

import StandardAutoComplete from '../../StandardAutocomplete';
import { TransactionContext } from '../TransactionContext';
import StandardAutoCompleteForPOSplit from './StandardAutocompleteForPOSplit';


export default function SplitPO(props) {

    const { data,guidId,updateGuidId} = useContext(TransactionContext);



    const [thisComponentState, updateState] = useState({

        purchaseRequisitionId: null,
        code: null,
        requiredDate: null,
        date: null,
        requestDate:null,
        branchId: null,
        departmentId: null,
        purchaseRequisitionDetails: [],
        details: [],
        requestedBy:null,
        reviewedBy: null,
        notedBy: null,
        approvedBy: null,
        isSaving: false,
        isGeneratingPDF: false,
        unCollapsed:true

    });

    useEffect(() => {

        pullPurchaseRequistion();

    }, []);


    const pullPurchaseRequistion = () => {

        var purchaseRequisitionGuidId = localStorage.getItem("purchaseRequisitionGuidId");

        
        fetch("/PurchaseOrders/GetRequestForSplittingPO?" + new URLSearchParams({

            guidId: purchaseRequisitionGuidId

        })).then(response => response.status === 401 ? window.location = "/Login" : response.json())

            .then(response => {

                updateState({
                    ...thisComponentState,
                    purchaseRequisitionId: response.purchaseRequisitionId,
                    branchId: response.branchId,
                    departmentId: response.departmentId,
                    requestedBy: response.requestor,
                    departmentName: response.departmentName,
                    purchaseRequisitionDetails: response.purchaseRequisitionDetails,
                    reviewedBy: localStorage.getItem("reviewedBy"),
                    notedBy: localStorage.getItem("notedBy"),
                    approvedBy: localStorage.getItem("approvedBy"),
                    requestDate: response.requestDate,
                    requiredDate: response.requiredDate,
                    details: []
                });
            })
    }


    const move = (item) => {

        let tempDataPO = [...thisComponentState.details];

        let tempDataPRdetails = [...thisComponentState.purchaseRequisitionDetails];

        let remainingPRdetails = tempDataPRdetails.filter(val => val.guidId !== item.guidId);

        item.guidIdOfPrDetail = item.guidId;

        item.guidId = uuid();

        tempDataPO.push({...item});

        console.log(thisComponentState.details);

        updateState({ ...thisComponentState, details: tempDataPO,purchaseRequisitionDetails:remainingPRdetails});

    }


    const moveBack = (item) => {

        let tempDataPO = [...thisComponentState.details];

        let tempDataPR = [...thisComponentState.purchaseRequisitionDetails];

        let filteredPO = tempDataPO.filter(val=> val.guidId !== item.guidId);

        tempDataPR.push({...item });

        updateState({ ...thisComponentState, details: filteredPO,purchaseRequisitionDetails:tempDataPR});

    }

    const setDefault = (label) => {

        var itemToSave = thisComponentState[label];

        if (typeof itemToSave === 'undefined' || itemToSave === null || itemToSave === "") {

            return alert("Invalid value");

        }

        var toConfirm = window.confirm("This will be set as default as " + label + "\n Click ok to continue.");

        if (toConfirm)
        {
            localStorage.setItem(label, itemToSave);
        };

    }



    const updateQuantity = (guidId,quantity) => {

        if (isNaN(quantity)) {

            return;
        }

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.quantity =parseFloat(quantity);

        updateState({ ...thisComponentState, details: tempDatas });

    }


    const updatePrice = (guidId, price) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        var initVal = parseFloat(price);

        if (initVal >= 0) {

            tempData.price = initVal;

            updateState({ ...thisComponentState, details: tempDatas });
        }
    }




    const updateUomEntry = (uomEntry, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.uomEntry = uomEntry;

        updateState({ ...thisComponentState, details: tempDatas });

    }


    const updateBranch = (branchId, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.branchId =branchId;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateDepartment = (departmentId, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.deptId = departmentId;

        updateState({ ...thisComponentState, details: tempDatas });
    }


    const updateWarehouse = (warehouseCode,guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.warehouseCode = warehouseCode;

        updateState({ ...thisComponentState, details: tempDatas });
    }

  
    const updateVendor = (pCode) => {

        updateState({...thisComponentState,code:pCode})

    }


    const save = () => {

        if (thisComponentState.details.length === 0) {

            return alert("Details are required...");
        };

        var dataToPost = {

            Transaction: thisComponentState
        };

        updateState({ ...thisComponentState, isSaving: true });

        fetch(data.metaData.validatorUrl, {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(json => {


                if (json.length > 0) {

                    updateErrors({ ...errors, hasErrors: true, showErrors: true, errors: json });

                    updateSaving(false);

                    return;

                } else {

                    fetch(data.metaData.postUrl, {

                        // Adding method type
                        method: "POST",

                        // Adding body or contents to send
                        body: JSON.stringify(dataToPost),

                        // Adding headers to the request
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                        .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                        .then(json => {


                            if (json.success) {

                                updateState({ ...thisComponentState, isSaving: false, details:[]})

                                updateGuidId(json.guidId);
                              
                            } else {

                                alert(json.message);

                                updateState({ ...thisComponentState, isSaving: false })

                            }

                        });
                }
            });

    }




    const preview = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/PurchaseOrders/PreviewWithCookie?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);

                window.open(file);

            });
    }


    if (thisComponentState.isGeneratingPDF) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div >

                <div className="row">

                    <div className="col">                   
                        <input type="button" value="Back" onClick={()=> props.back()} className="form-control" />
                    </div>

                   <div className="col">
                        <div className="row">

                            <div className="col">
                            <input type="button" value="Save" onClick={save } className="form-control" />
                            </div>

                            <div className="col">
                            <input type="button" onClick={preview} value="Print" className="form-control" />
                            </div>


                        </div>

                           
                    </div>

                </div>

            <br />

            <div style={{ borderStyle: 'solid',padding:'10px'}}>

                <div className="row">
                    <div className="col">
                        <FontAwesomeIcon icon={faPlus} id="addRow" size='1x' color='green' onClick={() => updateState({ ...thisComponentState, unCollapsed: !thisComponentState.unCollapsed })} style={{ cursor: 'pointer' }} />

                    </div>  


                </div>


                {
                    thisComponentState.unCollapsed && <div>

                        <div className="row">

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="align-middle">
                                            Requestor
                                        </div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.requestor} disabled className="form-control" />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="label">
                                            Supplier
                                        </div>

                                    </div>

                                    <div className="col">

                                        <StandardAutoComplete url="PurchaseOrders/GetVendors" updateValue={updateVendor} />

                                    </div>

                                </div>

                            </div>

                        </div>
                        <br />

                        <div className="row">

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div>  Branch Id</div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.branchId} disabled className="form-control" />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="label">
                                            Request Date
                                        </div>

                                    </div>

                                    <div className="col">


                                        <input type="date" value={thisComponentState.requestDate} onChange={(e) => updateState({ ...thisComponentState, requestDate: e.currentTarget.value })} className="form-control" />

                                    </div>

                                </div>

                            </div>

                        </div>


                        <br />

                        <div className="row">

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div>  Department </div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.departmentName} disabled className="form-control" />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="label">
                                            Date
                                        </div>

                                    </div>

                                    <div className="col">

                                        <input type="date" value={thisComponentState.date} onChange={(e) => updateState({ ...thisComponentState, date: e.currentTarget.value })} className="form-control" />


                                    </div>

                                </div>

                            </div>
                        </div>

                        <br />

                        <div className="row">

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="label">
                                            Purchase Requisition Id
                                        </div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.purchaseRequisitionId} disabled className="form-control" />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="label">
                                            Required Date
                                        </div>

                                    </div>

                                    <div className="col">

                                        <input type="date" value={thisComponentState.requiredDate} onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} className="form-control" />


                                    </div>

                                </div>

                            </div>

                        </div>


                        <br />

                        <div className="row">

                            <div className="col">



                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div className="label">
                                            Purchase Requisition Id
                                        </div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.purchaseRequisitionId} className="form-control" />
                                    </div>

                                </div>

                            </div>

                        </div>
                        <br />

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div>  Branch Id</div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.branchId} disabled className="form-control" />
                                    </div>

                                </div>


                            </div>

                        </div>

                        <br />

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div>  Department </div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.departmentName} className="form-control" />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <br />

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div > Requested By</div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.requestor} disabled className="form-control" />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <br />

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div onDoubleClick={() => setDefault("reviewedBy")}> Reviewed By</div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.reviewedBy} onChange={(e) => updateState({ ...thisComponentState, reviewedBy: e.currentTarget.value })} className="form-control" />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <br />

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div onDoubleClick={() => setDefault("notedBy")}> Noted By </div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.notedBy} onChange={(e) => updateState({ ...thisComponentState, notedBy: e.currentTarget.value })} className="form-control" />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <br />

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">

                                <div className="row">

                                    <div className="col">

                                        <div onDoubleClick={() => setDefault("approvedBy")}>  Approved By</div>

                                    </div>

                                    <div className="col">
                                        <input type="text" value={thisComponentState.approvedBy} onChange={(e) => updateState({ ...thisComponentState, approvedBy: e.currentTarget.value })} className="form-control" />
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                }

            </div>

          
                <br/>   

                <div className="row">

                        <div className="col">

                            <table className="table table-bordered">

                                <thead>

                                <tr>
                                        <td>Action</td>
                                        <td>Item Code</td>
                                        <td>Description</td>
                                        <td>Quantity</td>
                                 
                                    </tr>

                                </thead>

                                <tbody>
                            {
                                typeof thisComponentState.purchaseRequisitionDetails !== 'undefined' && thisComponentState.purchaseRequisitionDetails.map(val =>

                                    <tr style={{height:"30px"}}>
                                                <td className="align-middle">
                                                    <FontAwesomeIcon icon={faArrowRight} title="Move" style={{ cursor: 'pointer' }}  color="blue" onClick={()=> move(val)} />
                                                </td>
                                                <td className="align-middle">{val.code}</td>
                                                <td className="align-middle">{val.description}</td>
                                                <td className="align-middle">{val.quantity}</td>

                                            </tr>)


                                    }

                                </tbody>

                            </table>

                        </div>

                        
                <div className="col">


                    <div>

                        <table className="table table-bordered">

                            <thead>

                                <tr>
                                    <td>Action</td>
                                    <td>Item Code</td>
                                    <td>Description</td>
                                    <td>Quantity</td>
                                    <td>Price</td>
                                    <td>Uom</td>
                                    <td>Branch</td>
                                    <td>Department</td>
                                    <td>Warehouse</td>

                                </tr>

                            </thead>

                            <tbody>
                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr style={{ height: "30px" }} key={uuid()}>

                                            <td className="align-middle">
                                                <FontAwesomeIcon icon={faArrowLeft} title="move back" style={{ cursor: 'pointer' }} color="blue" onClick={() => moveBack(val)} />
                                            </td>

                                            <td className="align-middle">{val.code}</td>
                                            <td className="align-middle">{val.description}</td>
                                            <td className="align-middle">
                                                <input value={val.quantity}  onChange={(e)=>updateQuantity(val.guidId,e.currentTarget.value)} className="form-control" />
                                            </td>
                                            <td className="align-middle">
                                                <input value={val.price}  onChange={(e)=>updatePrice(val.guidId,e.currentTarget.value)} className="form-control" />
                                            </td>

                                            <td className="align-middle">
                                                <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetUomValues"  code={val.guidId}   params={{ugpEntry: val.ugpEntry }} value={val.uomEntry} updateValue={updateUomEntry} />
                                            </td>

                                            <td className="align-middle">
                                                <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetBranches"   code={val.guidId }   value={val.branchId} updateValue={updateBranch} />
                                            </td>

                                            <td className="align-middle">
                                                <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetDepartments" code={val.guidId } value={val.deptId} updateValue={updateDepartment}/>
                                            </td>

                                            <td className="align-middle">
                                                <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetWarehouses" code={val.guidId} value={val.warehouseCode} updateValue={updateWarehouse}/>
                                            </td>
                                           

                                     
                                        </tr>)


                                }

                            </tbody>

                        </table>

                    </div>

                </div>





                    </div>

            </div>
      
              
     )
}