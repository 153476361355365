import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Fragment } from "react";
import { useContext, useState } from "react";
import uuid from "react-uuid";
import StandardAutoCompleteForPOSplit from "../PurchaseOrder/StandardAutocompleteForPOSplit";
import { TransactionContext } from "../TransactionContext";





export default function PrOwnCapture(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        guidId: uuid(),
        details: props.data
        
    });

    useEffect(() => {

    }, [thisComponentState.guidId]);


    const updateQuantity = (guidId, quantity) => {

        if (isNaN(quantity)) {

            return;
        };


        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.quantity = parseFloat(quantity);

        updateState({ ...thisComponentState, details: tempDatas });

    }



    const updatePrice = (guidId, price) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        var initVal = parseFloat(price);

        if (initVal >= 0) {

            tempData.price = initVal;

            updateState({ ...thisComponentState, details: tempDatas });
        }
    }


    const updateUomEntry = (uomEntry, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.uomEntry = uomEntry;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateBranch = (branchId, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.branchId = branchId;

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const updateDepartment = (departmentId, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.deptId = departmentId;

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const updateWarehouse = (warehouseCode, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.warehouseCode = warehouseCode;

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const updateCode = (code, guidId) => {

        var tempDatas = [...thisComponentState.details];

        var inventory = data.metaData.inventories.find(val => val.itemCode === code);

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.code = code;

        tempData.ugpEntry = inventory.ugpEntry;

        tempData.uomEntry = inventory.purchaseUomEntry;

        updateState({ ...thisComponentState, details: tempDatas, guidId: uuid() });
    }


    const updateDescription = (guidId, description) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.description = description;

        updateState({ ...thisComponentState, details: tempDatas });

    }


    const addRow = () => {

        var tempData = {

            guidId: uuid(),
            branchId: data.metaData.parentProperties.branchId,
            deptId: data.metaData.parentProperties.deptId,
            warehouseCode: data.metaData.parentProperties.warehouseCode,
            code: null,
            description: null,
            uomEntry: 0,
            quantity: 0,
            price: 0,
            lineTotal: function () {
                return typeof this !== 'undefined' && typeof this.quantity !== 'undefined' && typeof this.price !== 'undefined' && this.quantity * this.price
            }
        }

        var tempDatas = [...thisComponentState.details];

        tempDatas.push(tempData);

        updateState({ ...thisComponentState, details: tempDatas });

    }

    const deleteRow = (guidId) => {

        var tempDatas = [...thisComponentState.details];

        var filtered = tempDatas.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, details: filtered });
    }


    const back = () => {

        var tempData = { ...data.metaData };

        for (var i = 0; i < thisComponentState.details.length; i++) {

            var detail = {};
        
            detail.code = thisComponentState.details[i].code;
            detail.description = thisComponentState.details[i].description;
            detail.quantity = thisComponentState.details[i].quantity;          
            detail.price = thisComponentState.details[i].price;
            detail.uomEntry = thisComponentState.details[i].uomEntry;
            detail.branchId = thisComponentState.details[i].branchId;
            detail.warehouseCode = thisComponentState.details[i].warehouseCode;
            detail.guidId = thisComponentState.details[i].guidId;

            tempData.detailProperties.push(detail);
        }

        //toShowProperty

        var presentationValue = {

            toShow: true,
            categoryId: ""
        };

        tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

        updateMetaData({ metaData: tempData });

        updateState({ ...thisComponentState, details: [] });

        props.back();

    }

   


    const clear = (guidId) => {

        var tempDatas = [...thisComponentState.details];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.code = "";

        tempData.ugpEntry = "";

        tempData.uomEntry = "";

        updateState({ ...thisComponentState, details: tempDatas, guidId: uuid() });
    }



    return (

        <div>
            <div className='row'>


                <div className='col'>
                    <input value="Back" type="button" className="form-control" onClick={back} />
                </div>

                <div className="col">

                  
                </div>

                <div className='col'></div>
                <div className='col'></div>
                <div className='col'></div>


            </div>

            <br />

            <div className='row'>
                <table className='table table-bordered'>

                    <thead>

                        <tr >
                            <td>  Action </td>
                            <td>  Item </td>
                            <td>  Display </td>
                            <td>  Quantity </td>
                            <td>  Price </td>
                            <td>  Uom </td>
                            <td>  Branch </td>
                            <td>  Warehouse </td>
                            <td>  Amount </td>
                        </tr>

                    </thead>


                    <tbody>
                        {
                            typeof thisComponentState.details !== 'undefined' && thisComponentState.details.length > 0 && thisComponentState.details.map(val =>

                                <Fragment key={uuid ()} >
                                   <tr >
                                        <td className="col-lg-1">

                                            <div className="row">

                                                <div className='col'>
                                                    <FontAwesomeIcon icon={faPlus} id="addRow" size='1x' color='green' onClick={addRow} style={{ cursor: 'pointer' }} />
                                                </div>

                                                <div className='col'>
                                                    <FontAwesomeIcon icon={faTrash} id="addRow" size='1x' color='red' onClick={() => deleteRow(val.guidId)} style={{ cursor: 'pointer' }} />
                                                </div>

                                            </div>
                                        </td>

                                        <td className="col-lg-3">
                                            <StandardAutoCompleteForPOSplit url="PurchaseRequisitions/GetInventories"  code={val.guidId}  value={val.code} updateValue={updateCode} clear={() => clear(val.guidId)} />
                                        </td>
                                       
                                        <td className="col-lg-1">
                                            <input value={val.description} onChange={(e) => updateDescription(val.guidId, e.currentTarget.value)} className="form-control" />
                                        </td>

                                        <td className="col-lg-1">
                                            <input value={val.quantity} type="number" onChange={(e) => updateQuantity(val.guidId, e.currentTarget.value)} className="form-control" />
                                        </td>

                                        <td className="col-lg-1">
                                            <input value={val.price} type="number" onDoubleClick={() => getLastPurchasePrice(val)} onBlur={(e) => updatePrice(val.guidId, e.currentTarget.value)} className="form-control" />
                                        </td>

                                        <td className="col-lg-1">
                                           <StandardAutoCompleteForPOSplit url="PurchaseRequisitions/GetUomValues" code={val.guidId} params={{ ugpEntry: val.ugpEntry }} mountCounter={val.code} value={val.uomEntry} updateValue={updateUomEntry} />
                                        </td>

                                        <td className="col-lg-1">
                                           <StandardAutoCompleteForPOSplit url="PurchaseRequisitions/GetBranches" code={val.guidId} value={val.branchId} updateValue={updateBranch} />
                                       </td>

                                        <td className="col-lg-1">
                                           <StandardAutoCompleteForPOSplit url="PurchaseRequisitions/GetWarehouses" code={val.guidId} value={val.warehouseCode} updateValue={updateWarehouse} />
                                        </td>

                                        <td className="col-lg-1">
                                            <input className='form-control' value={val.quantity*val.price} disabled />
                                        </td>

                                    </tr>

                                </Fragment>

                            )
                        }

                    </tbody>

                </table>
            </div>

        </div>
    )
}

