import React from 'react';
import ColumnChart from '../../../Charts/ColumnChart';


export default function Analytics()
{
    const data = {
        months: [1, 2],
        values: [0.75, 0.85],
    };


    return (
        <div>
          
            <ColumnChart data={data} />

            
          
        </div>

    )

}