import { useState } from "react";

import UploadFile from './UploadFile'

export default function  UploadPromptOption(props) {


    const [thisComponentState, updateState] = useState({

        uploadFile : false

    });

    const downLoad = () => {

        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' +props.csvString;
        a.target = '_blank';
        a.download = props.fileName + '.csv';

        document.body.appendChild(a);

        a.click();
    }


    if (thisComponentState.uploadFile) {

        return (<UploadFile action={props.action} isSeparateUpload={false} fileLimit={3} back={() => props.back()} />);
    }


    return (

        <div>
            <div className="row">

                <div className="col">
                  
                </div>

                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={()=>props.back()} />
                </div>

                <div className="col">

                </div>

            </div>

                <hr/>

            <div className="row">
                <div className="col">
                    <input type="button" value="Download Template" className="form-control" onClick={downLoad} />
                </div>

                <div className="col">
                    <input type="button" value="Upload" className="form-control" onClick={() => updateState({...thisComponentState,uploadFile:true})} />
                </div>
            </div>

        </div>
        )
}