
import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import StandardAutoComplete from '../../StandardAutocomplete';
import { TransactionContext } from '../TransactionContext';


export default function OutgoingPaymentComponents(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        list: [],
        code: ""
      
    })

    useEffect(() => {

        if (thisComponentState.code !== "") {

            fetch("/OutgoingPayments/Retrieve?" + new URLSearchParams({

                code: thisComponentState.code

            }))
                .then(response => response.status === 401 ? window.location = "/login" : response.json())
                .then(response => {

                    updateState({ ...thisComponentState, list: response, showSelection: true });

                });
        }

      
    }, [thisComponentState.code]);

    const selected = (guidId) => {

        var tempDatas = [...thisComponentState.list];

        var tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.selected = !tempData.selected;

        updateState({ ...thisComponentState, list: tempDatas });
    }

    const move = () =>
    {
        var selectedItems = thisComponentState.list.filter(val => val.selected).map(x => ({
            boRcptInvTypes: x.boRcptInvTypes,
            baseEntry: x.baseEntry,
            code:x.code,
            description: x.references,
            quantity: x.quantity,
            price: x.price,
            guidId: x.guidId,

        }));

        if (selectedItems.length === 0) {

            return alert("Please select at least one payables");
        }

        let tempData = { ...data.metaData };
        tempData.parentProperties.code =thisComponentState.code;
        tempData.parentProperties.note = "From ERP Sytem.";
        tempData.parentProperties.date = thisComponentState.list[0].currentDate;
        tempData.parentProperties.ewt = 0;
        tempData.detailProperties = selectedItems;

        //toShowProperty

        var presentationValue = {

            toShow: true,
            categoryId: ""
        };

        tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

        updateMetaData({ ...data, metaData: tempData });

        props.back();
    }

    const updateValue = (code) => {

        updateState({ ...thisComponentState, code: code })
            
    }

    return (

        <div style={getModalStyle()}>

            <div style={getModalContentStyle()}>




                <div className="row">

                    <div className="col">
                        <input className="form-control" type="button" value="Back" onClick={() => props.back()} />
                    </div>

                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                        Vendor
                    </div>

                    <div className="col">
                        <StandardAutoComplete url="OutgoingPayments/GetVendors" updateValue={updateValue}  />
                    </div>

                </div>



                <div className="row">
                    <div className="col">
                       
                    </div>

               
                    <div className="col">
                        <input className="form-control" type="button" value="Move" onClick={move} />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="2"> Action</th>
                                    <th> Date </th>
                                    <th> Type </th>
                                    <th> References</th>
                                    <th> Ap Doc Id </th>
                                    <th> Ap Sap Id </th>
                                    <th> Doc Total</th>
                                    <th> Remarks </th>
                                </tr>

                            </thead>
                            <tbody>

                                {
                                    thisComponentState.list.map(val =>

                                        <tr>

                                            <td>
                                                <input type="checkbox" value={val.selected} onChange={() => selected(val.guidId)} className="form-control" />
                                            </td>
                                            <td>
                                                <input type="button" value="Details"   className="form-control" />
                                            </td>

                                            <td> {val.date}</td>
                                            <td> {val.boRcptInvTypes}</td>
                                            <td> {val.references}</td>
                                            <td> {val.apDocId}</td>
                                            <td> {val.apSapId}</td>
                                            <td> {val.docTotal}</td>
                                            <td> {val.remarks}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

  
}