import { faArrowCircleLeft, faArrowLeft, faList, faQuestionCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";
import NameDimension from "./NameDimension";
import SliceDefinitions from "./SliceDefinitions"
import getModalStyle from '../../Functions/getModalStyle';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import ItemRequestList from "./ItemRequestList";

export default function RequestForNewInventory(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        categoryId: "",
        ugpEntry:"",
        type:"NONE",
        code: "",
        name: "",
        saleUomEntry: "",
        purchaseUomEntry: "",
        inventoryUomEntry: "",
        sellItem: "Y",
        purchaseItem: "Y",
        inventoryItem: "Y",
        defaultAccountCode: "",
        shortCode: "",
        area: "",
        expenseCode: "",
        isManufactured: "N",
        isService: "N",
        isForecastable: "N",
        isBom:"N",
        sliceDefinitions: [],
        showSliceDefinition: false,
        showNameDimension: false,
        nameDimension: {

            description: "",
            brand: "",
            shape: "",
            color: "",
            size: "",
            length: "",
            width: "",
            height: "",
            diameter: "",
            getFullName: function () {

                let brand = this.brand !== "" ? " " + this.brand : "";
                let shape = this.shape !== "" ? " " + this.shape : "";

                let color = this.color !== "" ? " " + this.color : "";

                let length = this.length !== "" ? " " + this.length : "";
                let width = this.width !== "" ? " " + this.width : "";
                let height = this.height !== "" ? " " + this.height : "";

                let size = length + width + height;


                let diameter = this.diameter !== "" ? " " + this.diameter : "";

                let name = this.description + brand + shape + color + size + diameter;

                return name;
            }
        }
    });


    useEffect(() => {

        fetch("/Inventories/GetNextInventoryCode?" + new URLSearchParams({
            type: thisComponentState.type

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, code:response.value });

            });

    }, [thisComponentState.type]);



    const save = () => {

        let confirm = window.confirm("Make sure your data are correct. Please click ok to continue saving.")

        if (!confirm) {
            return;
        }

        if (thisComponentState.code === "") {

            return alert("Code is required.");
        };

        if (thisComponentState.categoryId === "") {

            return alert("Category group required.");
        };

        if (thisComponentState.ugpEntry === "") {

            return alert("Uom group is required.");
        };

        if (thisComponentState.name === "") {

            return alert("Name is required.");
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let postObject = {
            value : JSON.stringify(thisComponentState)
        };

        fetch("/StockRequisitions/CreateItemRequest", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(postObject),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

         
                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });

    }

    const updateSaleUomEntry = (code) => {
        updateState({ ...thisComponentState, saleUomEntry: code });
    }

    const updatePurchaseUomEntry = (code) => {

        updateState({ ...thisComponentState, purchaseUomEntry: code });
    }

    const updateInventoryUomEntry = (code) => {

        updateState({ ...thisComponentState,inventoryUomEntry: code });

    }

    const setNameDefinition = (data) => {

        updateState({ ...thisComponentState, name: data.getFullName(), showNameDimension: false,nameDimension:data});

    }

    const setSliceDefinitions = (data) => {

        updateState({ ...thisComponentState, sliceDefinitions: data,showSliceDefinition:false });
    }



    const updateCategoryId = (id) => {


        updateState({ ...thisComponentState, categoryId: id });

    }

    const updateUgpEntry = (entry) => {

        updateState({ ...thisComponentState,ugpEntry: entry });
    }



    if (thisComponentState.showNameDimension) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle() }>
                    <NameDimension nameDimension={thisComponentState.nameDimension} set={setNameDefinition} />
                </div>
            </div>

           
        )

    }


    if (thisComponentState.showSliceDefinition) {

        return (<SliceDefinitions set={setSliceDefinitions} definitions={thisComponentState.sliceDefinitions}/>)

    }


    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>

            <hr />

            <div className="row">

                <div className="col">

                    <FontAwesomeIcon icon={faArrowLeft} title="Back" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={()=>props.back()} />
                    <FontAwesomeIcon icon={faSave} title="click to save" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={save} />


                </div>

                <div className="col">      
                </div>

                <div className="col">
                </div>

            </div>



            <hr />

            <div className="row">
                <div className="col">

                    <div className="row">
                        <div className="col">
                            <div>
                                Type
                            </div>
                        </div>
                        <div className="col">
                            <select value={thisComponentState.type} onChange={(e) => updateState({ ...thisComponentState, type: e.currentTarget.value })} className="form-control" >
                                <option value="NONE">Select Prefix</option>
                                <option value="RM">RM</option>
                                <option value="OT">OT</option>
                                <option value="GR">GR</option>
                                <option value="JW">JW</option>
                                <option value="KM">KM</option>
                            </select>
                        </div>
                    </div>

                    <hr />

                    <div className="row">

                        <div className="col">
                            <div>
                                Code
                            </div>
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.code } disabled className="form-control" />
                        </div>

                    </div>

                    <hr />

                    <div className="row">

                        <div className="col">
                            <div>
                                Name
                            </div>
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.name} onClick={() => updateState({...thisComponentState,showNameDimension:true}) } className="form-control" />
                        </div>

                    </div>
                    <hr/>

                </div>

                <div className="col">

                    <div className="row">
                        <div className="col">
                            <div>
                                Sale Uom Entry
                            </div>
                        </div>

                        <div className="col">
                            <StandardAutoComplete url="/UomValues/GetUomValues" updateValue={updateSaleUomEntry} />
                        </div>

                    </div>

                    <hr />

                    <div className="row">
                        <div className="col">
                            <div>
                                Purchase Uom Entry
                            </div>
                        </div>

                        <div className="col">
                            <StandardAutoComplete url="/UomValues/GetUomValues" updateValue={updatePurchaseUomEntry} />
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col">
                            <div>
                                Inventory Uom Entry
                            </div>
                        </div>

                        <div className="col">

                            <StandardAutoComplete url="/UomValues/GetUomValues" updateValue={updateInventoryUomEntry} />

                        </div>
                    </div>

                    <hr />
                </div>
            </div>

            <hr/>
        
            <div className="row">
                <div className="col">
                    <div>
                        Sell Item 
                    </div>
                </div>

                <div className="col">
         
                    <select defaultValue="Y" onChange={(e) => updateState({ ...thisComponentState, sellItem: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>                        
                    </select>  

                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col">
                    <div>
                        Purchase Item
                    </div>
                </div>
                <div className="col">

                    <select defaultValue="Y" onChange={(e) => updateState({ ...thisComponentState, purchaseItem: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>


                </div>
            </div>

            <br/>

            <div className="row">

                <div className="col">
                    <div>
                        Inventory Item
                    </div>
                </div>

                <div className="col">
                    <select defaultValue="Y" onChange={(e) => updateState({ ...thisComponentState, inventoryItem: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Service
                    </div>
                </div>

                <div className="col">
                    <select defaultValue="N" onChange={(e) => updateState({ ...thisComponentState, isService: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Forecastable
                    </div>
                </div>

                <div className="col">
                    <select defaultValue="N" onChange={(e) => updateState({ ...thisComponentState, isForecastable: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>

            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Manufactured
                    </div>
                </div>

 
                <div className="col">

                    <div className="row">
                        <div className="col">

                            <select defaultValue="N" onChange={(e) => updateState({ ...thisComponentState, isManufactured: e.currentTarget.value })} className="form-control" >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>

                        <div className="col">
                            <input type="button" value="Slice Definition" className="form-control" onClick={() => updateState({...thisComponentState,showSliceDefinition:true})}/>
                        </div>
                    </div>
       
                </div>
             
            </div>


            <hr/>

            <div className="row">

                <div className="col">
                    <div>
                        Is Bill of Materials
                    </div>
                </div>


                <div className="col">
                    <select defaultValue="N" onChange={(e) => updateState({ ...thisComponentState, isBom: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>

                </div>

            </div>


            <hr/>

            <div className="row">
                <div className="col">
                    <div>
                        Category Group
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.categoryId} url="/Categories/GetCategories" updateValue={updateCategoryId} />
                </div>
            </div>

            <hr/>

            <div className="row">

                <div className="col">
                    <div title="The base uom for this item.">
                        Uom Group
                    </div>
                </div>
               
                <div className="col">
                    <StandardAutoComplete value={thisComponentState.ugpEntry} url="/UomValues/GetUgpEntries" updateValue={updateUgpEntry} />
                </div>
            </div>

        </div>
    )

}
