
export default function getWidth(property, metaData) {

    for (var i = 0; i < metaData.length; i++) {

        if (metaData[i].name.toLowerCase() === property.toLowerCase()) {

            return metaData[i].width;
        }
    }

}

