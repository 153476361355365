import { useState } from "react"

export default function MainFactors(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        value: ""
    });

 

    const add = () => {

        if (thisComponentState.value === "") {
            return alert("Value cannot be empty.");
        }

        var isConfirm = window.confirm("Are you sure you want to save this?");

        if (!isConfirm) {

            return;
        };

        let postBody =
        {
           value: thisComponentState.value  
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/SaveMainFactors", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({...thisComponentState,isPerformingOperation:false})

                return alert(response.message);

            });
    }




    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>
                <div className="col">
                    <input value="List" onClick={() => props.back()} className="form-control" type="button" />
                </div>

            </div>

            <hr/>

            <div className="row">

                <div className="col">
                    Name
                </div>

                <div className="col">
                    <input value={thisComponentState.value} className="form-control" type="input" onChange={(e) => updateState({ ...thisComponentState,value: e.currentTarget.value })} />
                </div>

            </div>

            <br/>

            <div className="row">
                <div className="col">              
                </div>
                <div className="col">
                    <input type="button" value="Save" className="form-control" onClick={add} />
                </div>
            </div>

        </div>


    )
}