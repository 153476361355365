import { faBookJournalWhills, faCheck, faInfo, faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import uuid from "react-uuid";
import StandardAutoComplete from "../../StandardAutocomplete";


export default function  IncentiveComputationByEmployee(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: false,
        code: "",
        from: "",
        to:"",
        values: []
    });


    const generate = () => {

        updateState({ ...thisComponentState,isLoading: true});

        fetch("/Invoices/GetIncentiveByEmployee?" + new URLSearchParams({
            code: thisComponentState.code,
            start: thisComponentState.from,
            end:thisComponentState.to
        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, values: response, isLoading: false });

            });

    }

    const updateCode = (code) => {

        updateState({ ...thisComponentState, code: code });
    }

    if (thisComponentState.isLoading) {

        return (<div>Computating...</div>)
    }


    return (

        <div>
            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                </div>
               
            </div>

            <hr />


            <div className="row">
                <div className="col">
                    Employee
                </div>
                <div className="col">
                    <StandardAutoComplete value={thisComponentState.code} url="/Employees/GetEmployees" updateValue={updateCode} />
                </div>

            </div>

            <div className="row">
                <div className="col">
                    From
                </div>
                <div className="col">
                    <input type="date" className="form-control" value={thisComponentState.from} onChange={(e) => updateState({ ...thisComponentState, from: e.currentTarget.value })} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    To
                </div>
                <div className="col">
                    <input type="date" className="form-control" value={thisComponentState.to} onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                   
                </div>
                <div className="col">

                    <input type="button" className="form-control" value="Generate" onClick={generate} />

                </div>
            </div>

            <hr/>

            <div>

                <table className="table table-bordered">
                    <thead>
                        <th>Name</th>
                        <th>Agent</th>
                        <th>As</th>
                        <th>Days</th>
                        <th>Total Count</th>
                        <th>Incentive</th>
                    </thead>

                    <tbody>

                        {
                            thisComponentState.values.map(data =>
                                <tr>
                                    <td>{data.name}</td>
                                    <td>{data.agent}</td>
                                    <td> {data.as }</td>
                                    <td>{data.days }</td>
                                    <td>{data.totalCount}</td>                                  
                                    <td>{data.incentive}</td>

                                </tr>)
                        }
                    </tbody>

                </table>


             
            </div>

        </div>

    )

}