import { faInfo, faInfoCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import StandardAutoComplete from "../../StandardAutocomplete";
import CycleCountDetails from "../Crates/CycleCountDetails";
import CycleDetails from "./CycleDetails";
import EvaluationResult from "./EvaluationResult";

export default function EvaluationsPerEmployee(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: false,
        list: [],
        seeResult:false,
        seeDetails: false,
        evaluationCycleId:"",
        detailId: "",
        employeeId:""
   
    });

    useEffect(() => {
   
        if (thisComponentState.employeeId !== "") {

            updateState({ ...thisComponentState, isLoading: true });

            fetch("/PerformanceEvaluations/GetListOfCycles?" + new URLSearchParams({

                employeeId: thisComponentState.employeeId
                
            }))
                .then(response => response.status === 401 ? window.location = "/login" : response.json())
                .then(response => {

                    if (!response.success) {

                        updateState({ ...thisComponentState, isLoading: false });

                        return alert(response.message);
                    }
                    updateState({ ...thisComponentState, list: response.model, isLoading: false });
                });
        };
    },[thisComponentState.employeeId])

    const deleteItem = (id) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/DeleteCycle", {
            method: "post",
            body: JSON.stringify({
                value:id
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, employeeId: code });
    }


    if (thisComponentState.seeDetails) {

        return (<CycleDetails back={() => updateState({ ...thisComponentState, seeDetails: false })} id={thisComponentState.detailId}/>)
    }

    if (thisComponentState.seeResult) {

        return (<EvaluationResult back={() => updateState({ ...thisComponentState, seeResult: false })} evaluationCycleId={thisComponentState.evaluationCycleId}/>)
    }



    if (thisComponentState.isLoading)
    {
        return (<div>Loading...</div>)
    }

    return (
        <div >
            <div >
                <div className="row">
                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={()=>props.back()}/>
                    </div>
                </div>

                <br/>

                <div className="row">
                    <div className="col">
                        Employee
                    </div>
                    <div className="col">
                        <StandardAutoComplete url="Employees/GetEmployees" value={thisComponentState.employeeId} clear={() => updateState({ ...thisComponentState, employeeId: "" })} updateValue={updateEmployeeId} />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="3"> Action</th>
                                    <th> Cylce Id</th>
                                    <th> Evaluee</th>
                                    <th> Position</th>
                                    <th> Purpose </th>
                                    <th> Created By </th>
                                    <th> Create Date</th>  
                                </tr>
                            </thead>

                            <tbody>

               

                                {
                                   thisComponentState.list.length > 0 && thisComponentState.list.map(val =>

                                        <tr>                
                                            <td>                                                                                           
                                               <FontAwesomeIcon icon={faTrash} title="Delete" style={{ cursor: 'pointer' }} size="2x" color="red" onClick={() => deleteItem(val.id)} />
                                           </td>
                                           <td>
                                               <FontAwesomeIcon icon={faInfoCircle} title="See evaluators" style={{ cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({...thisComponentState,detailId:val.id,seeDetails:true})} />
                                           </td>
                                           <td>
                                               <FontAwesomeIcon icon={faInfoCircle} title="See Result" style={{ cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState,evaluationCycleId: val.id, seeResult: true })} />
                                           </td>
                                            <td> {val.id}</td>
                                            <td> {val.evaluee}</td>
                                            <td> {val.position}</td>
                                            <td> {val.purpose}</td>
                                            <td> {val.createdBy}</td>
                                            <td> {val.createDate}</td>
                                       
                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
