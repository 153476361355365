
import React from 'react';
import { useState } from 'react';
import Login from './Login';

export default function ChangePassword(props) {

    const [thisComponentState, updateState] = useState({

        oldPassword :"",
        newPassword: "",
        confirmedNewPassword: "",
        isChangedSuccessful: false,
        isChangingPassword:false

    });

    const Change = () => {

        if (thisComponentState.newPassword !== thisComponentState.confirmedNewPassword)
        {
            return alert("Password does not match.");
        };

        let postBody = {

            oldPassword: thisComponentState.oldPassword,
            newPassword: thisComponentState.newPassword

        }

        updateState({ ...thisComponentState, isChangingPassword: true });

        fetch('/Identity/ChangePassword', {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          }).then(response=>response.status===401? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    alert("You password has been changed.")

                    updateState({ ...thisComponentState, isChangingPassword: false, isChangedSuccessful: true });

                    return;

                } else {

                    updateState({ ...thisComponentState, isChangingPassword: false });

                    return alert(response.message);                  
                }
            })      
    }

    if (thisComponentState.isChangedSuccessful) {

        return (<Login />);
    }

    if (thisComponentState.isChangingPassword) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (
        <section className="vh-100" style={{ backgroundColor: "#eee" }}>
            <div className="container h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-11">
                        <div className="card text-black" style={{ borderRadius: "25px" }}>
                            <div className="card-body p-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                        <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Change Password</p>

                                        <form className="mx-1 mx-md-4">
                                          
                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="password" id="form3Example3c" className="form-control" autoComplete="new-password" onChange={(e) => updateState({ ...thisComponentState,oldPassword: e.currentTarget.value })} />
                                                    <label className="form-label" >Old Password</label>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="password" id="form3Example4c" className="form-control" onChange={(e) => updateState({ ...thisComponentState, newPassword: e.currentTarget.value })} />
                                                    <label className="form-label" >New Password</label>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                                <div className="form-outline flex-fill mb-0">
                                                    <input type="password" id="form3Example4cd" className="form-control" onChange={(e) => updateState({ ...thisComponentState, confirmedNewPassword: e.currentTarget.value })} />
                                                    <label className="form-label" >Confirm New Password</label>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                <button type="button" className="btn btn-primary btn-lg" onClick={Change} >Change</button>
                                            </div>

                                        </form>

                                    </div>
                                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                        <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-registration/draw1.png" className="img-fluid" alt="Sample image" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}