import { useEffect, useState } from "react";
import getTotal from "../../Functions/getTotal";

export default function Agent(props) {

    const [items, setItems] = useState([]);

    const [data, updateData] = useState(props.data);

    useEffect(() => {

        if (props.data && props.data.length > 0) {

            const uniqueSlpCodes = Array.from(new Set(props.data.map(val => val.slpCode)));
            const uniqueItems = uniqueSlpCodes.map(slpCode => {
                const item = props.data.find(val => val.slpCode === slpCode);
                return { slpCode: item.slpCode, slpName: item.slpName };
            });

            setItems(uniqueItems);
        }
    }, [data]);

    const getBegInv = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && (val.identifier === "BegInventory" || val.identifier==="YesterdaysReturns")).map(val=>val.quantity); 

        return getTotal(result);
    }

    const getTransfer = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.identifier === "BreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getEndInv = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && (val.identifier === "EndingInventory" || val.identifier==="Returns")).map(val => val.quantity);

        return getTotal(result);
    }
    const getNetTransfer = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.identifier === "NetBreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getInvoice = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.identifier === "Invoice").map(val => val.quantity);

        return getTotal(result);
    }

    const getVariance = (slpCode) => {

        return getEndInv(slpCode)-(getBegInv(slpCode) + getTransfer(slpCode) - getInvoice(slpCode));
    }

    const computeWithVarianceOnly = () => {

        let tempData = [...data];

        let withVarianceOnly = tempData.filter(function (val) {

            return getVariance(val.slpCode) !== 0;

        });

        const uniqueItemCodes = Array.from(new Set(withVarianceOnly.map(val => val.slpCode)));

        const uniqueItems = uniqueItemCodes.map(slpCode => {
            const item = data.find(val => val.slpCode ===slpCode);
            return { slpCode: item.slpCode,slpName: item.slpName };
        });

        setItems(uniqueItems);

        updateData(withVarianceOnly);

    }


    return (
        <div>
            <table className="table table-bordered">
                <thead>
                    <tr onClick={()=>computeWithVarianceOnly()}>
                        <th>Slp Code </th>
                        <th>Slp Name </th>
                        <th>Beg Inv</th>
                        <th>Transfer </th>
                        <th>End Inv </th>
                        <th>Net Transfer </th>
                        <th>Invoice</th>
                        <th>Variance</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item =>
                        <tr key={item.slpCode}>
                            <td>{item.slpCode}</td>
                            <td>{item.slpName}</td>
                            <td>{getBegInv(item.slpCode) }</td>
                            <td>{getTransfer(item.slpCode) }</td>
                            <td>{getEndInv(item.slpCode) }</td>
                            <td>{getNetTransfer(item.slpCode) }</td>
                            <td>{getInvoice(item.slpCode) }</td>
                            <td>{getVariance(item.slpCode)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
