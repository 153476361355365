import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';


export default function PushFormWithCustomer(props) {


    const [thisComponentState, updateState] = useState({

        data: [],
        isInitial: true,
        isLoading: false,
        deliveryDate: "",
        area:"ALL"
    });


    async function generate() {

        var deliveryDate = thisComponentState.deliveryDate;

        if (deliveryDate === "") {

            return alert("Please defined a valid date.");
        };

        await fetch("/BreadOuts/OrderSummary?" + new URLSearchParams({

            deliveryDate: deliveryDate,
            area: thisComponentState.area

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.data.length > 0) {

                    updateState({ ...thisComponentState, data: responseData, isLoading: false, isInitial: false });

                } else {

                    updateState({ ...thisComponentState, data: [] });

                    return alert("No available data.");
                }
            });

    };

    const getQty = (array, name) => {

        var filtered = array.find(x => x.shortCode == name);

        if (typeof (filtered) !== 'undefined') {

            return filtered.quantity;
        }

    }


    const getTotalPerBread = (shortCode) => {

        var total = 0;

        thisComponentState.data.data.map(perAgent => {

            perAgent.customers.map(customer => {

                customer.details.map(detail => {

                    if (detail.shortCode === shortCode) {

                        var parseValue = parseFloat(detail.quantity);

                        if (!isNaN(parseValue)) {

                            total += parseValue;
                        };
                    };
                });
            })
        });


        return total;

    }

    if (thisComponentState.isInitial) {


        return (
            <div style={{ float: "left", display: "inline-block" }}>
                <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Delivery Date</div>
                <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.deliveryDate} onChange={(e) => updateState({ ...thisComponentState, deliveryDate: e.currentTarget.value })} />


                <select class="form-control" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, area: e.currentTarget.value })}  >
                    <option selected value="ALL">ALL</option>
                    <option value="OLD">Old Building</option>
                    <option value="NEW">New Building</option>
                </select>

                <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={() => generate()} value="Generate" />

                <br />
                <br />

            </div>
        )
    }

    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {


        if (typeof (thisComponentState.data) != 'undefined' && typeof (thisComponentState.data.data) != 'undefined' && thisComponentState.data.data.length != 0) {

            return (

                <div>
                    <div style={{ float: "left", display: "inline-block" }}>

                        <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Delivery Date</div>

                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.deliveryDate} onChange={(e) => updateState({ ...thisComponentState, deliveryDate: e.currentTarget.value })} />


                        <select class="form-control" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, area: e.currentTarget.value })}  >
                            <option selected value="ALL">ALL</option>
                            <option value="OLD">Old Building</option>
                            <option value="NEW">New Building</option>
                        </select>

                        <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={() => generate()} value="Generate" />


                        <br />
                        <br />

                    </div>

                    <br />

                    <div>


                        <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                            <thead className="thead-dark">

                                <th>Agent</th>

                                <th>Customer</th>

                                {
                                    Object.entries(thisComponentState.data.shortCodes).map(y =>

                                        <th> {(y[1])}</th>
                                    )
                                }

                            </thead>

                            <tbody>

                                {


                                    thisComponentState.data.data.map(agent =>

                                        <Fragment>

                                            {
                                                agent.customers.map((customer, index) =>

                                                    <tr>

                                                        {
                                                            index === 0 ? <td rowSpan={agent.customers.length}> {agent.agentName}</td> : ""
                                                        }


                                                        <td > {customer.customer} </td>


                                                        {
                                                            thisComponentState.data.shortCodes.map(shortCode =>

                                                                <td> {getQty(customer.details, shortCode)}



                                                                </td>

                                                            )
                                                        }



                                                    </tr>
                                                )

                                            }


                                        </Fragment>


                                    )




                                }

                            </tbody>


                            <tfoot>
                                <tr className='thead-dark'>
                                    <td colSpan="2">Grand Total</td>

                                    {

                                        thisComponentState.data.shortCodes.map(shortCode =>

                                            <td> {getTotalPerBread(shortCode)} </td>

                                        )
                                    }


                                </tr>


                            </tfoot>

                        </table>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ float: "left", display: "inline-block" }}>
                    <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Delivery Date</div>
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.deliveryDate} onChange={(e) => updateState({ ...thisComponentState, deliveryDate: e.currentTarget.value })} />

                    <select class="form-select" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, area: e.currentTarget.value })}  >
                        <option selected value="ALL">ALL</option>
                        <option value="OLD">Old Building</option>
                        <option value="NEW">New Building</option>        
                    </select>

                    <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={() => generate()} value="Generate" />

                  
                    <br />
                    <br />

                </div>
            )



        }


    }
}

