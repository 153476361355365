import { useEffect, useState } from "react"
import uuid from "react-uuid";
import StandardAutoComplete from "../../StandardAutocomplete";
import IncentiveComputationByEmployee from "./IncentiveComputationByEmployee";
import IncentiveList from "./IncentiveList";
import IncentiveSummary from "./IncentiveSummary";


export default function Incentive(props) {

    const [thisComponentState, updateState] = useState({

        isLoading: false,
        agentId:"",
        values: [],
        from: "",
        to: "",
        showIncentiveSummary: false,
        showIncentiveList: false,
        showComputationByEmployee:false
    });



    const generate = () => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/Invoices/GenerateIncentiveComputations?" + new URLSearchParams({
            agentId:thisComponentState.agentId,
            start: thisComponentState.from,
            end: thisComponentState.to
        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, values: response, isLoading: false });

            });
    }

    const getComponent = (data, count, index, datas, label) => {
        let total = 0;
        let days = 0;

        datas.forEach(val => total = total + val.incentive);
        datas.forEach(val => days = days + val.days);


        if (count !== (index + 1)) {

            return (

                <div key={uuid()}>
                    <div className="row">
                        <div className="col-md-4">
                            {data.name}
                        </div>
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-1">
                            {data.days} Days
                        </div>
                        <div className="col-md-3">
                            {data.incentive.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}

                        </div>
                    </div>

                </div>
            )

        } else {

            return (

                <div>
                    <div key={uuid()}>
                        <div className="row">
                            <div className="col-md-4">
                                {data.name}
                            </div>
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-1">
                                {data.days} Days
                            </div>
                            <div className="col-md-3">
                                {data.incentive.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}

                            </div>
                        </div>
                    </div>

                    <div key={uuid()} style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <div className="row">
                            <div className="col-md-4">
                                {label}
                            </div>
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-1">
                                {days} Days
                            </div>
                            <div className="col-md-3">
                                {total.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    };




    if (thisComponentState.isLoading) {

        return (<div>Computating...</div>)
    }

    if (thisComponentState.showIncentiveSummary) {

        return (<IncentiveSummary back={() => updateState({...thisComponentState,showIncentiveSummary:false})}/>)

    }


    if (thisComponentState.showIncentiveList) {

        return (<IncentiveList back={() => updateState({ ...thisComponentState, showIncentiveList: false })} />)

    }



    if (thisComponentState.showComputationByEmployee) {

        return (<IncentiveComputationByEmployee back={() => updateState({ ...thisComponentState, showComputationByEmployee: false })} />)

    }

    const updateAgentId = (code) => {

        updateState({ ...thisComponentState, agentId: code });

    }


    return (

        <div>
            <div className="row">

                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                </div>
                <div className="col">
                    <input type="button" className="form-control" value="Calculate" onClick={() => updateState({...thisComponentState,showIncentiveSummary:true})} />
                </div>

                <div className="col">
                    <input type="button" className="form-control" value="By Employee" onClick={() => updateState({ ...thisComponentState, showComputationByEmployee: true })} />
                </div>
                <div className="col">
                    <input type="button" className="form-control" value="List" onClick={() => updateState({ ...thisComponentState, showIncentiveList: true })} />
                </div>

            </div>
            <hr />

            <div className="row">

                <div className="col">
                   Agent
                </div>
                <div className="col">
                    <StandardAutoComplete url="/Agents/GetAgents" updateValue={updateAgentId} value={thisComponentState.agentId }/>
                </div>
             
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    From
                </div>
                <div className="col">
                    <input type="date" className="form-control" value={thisComponentState.from} onChange={(e) => updateState({ ...thisComponentState, from: e.currentTarget.value })} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    To
                </div>
                <div className="col">
                    <input type="date" className="form-control" value={thisComponentState.to} onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">

                </div>
                <div className="col">
                    <input type="button" className="form-control" value="Generate" onClick={generate} />
                </div>
            </div>

            <hr />

            <div>

                {
                    thisComponentState.values.map(val =>

                        <div key={uuid()}>
                            <div className="row">
                                <div className="col-md-4">
                                    Net Sales
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.netSales.toLocaleString('en-US', {   
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    Bad Order
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.badOrder.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    Bad Order Rate
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {((val.badOrder/val.netSales)*100).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}%
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-md-4">
                                    Net Sales
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.netSales.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </div>
                            </div>
                            <div className="row" style={{ borderBottom: "1px solid black" }}>
                                <div className="col-md-4">
                                    Rate
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.rate}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    Incentive
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.initialIncentive.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    Additional Incentive
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.additionalIncentive.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </div>
                            </div>



                            <div className="row" style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                                <div className="col-md-4">
                                    Total Incentive
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-3">
                                    {val.totalIncentive.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </div>
                            </div>

                            <hr />

                            {
                                val.agents.map((data, index) => getComponent(data, val.agents.length, index, val.agents, "Incentive as Agent"))
                            }

                            <br />

                            {
                                val.drivers.map((data, index) => getComponent(data, val.drivers.length, index, val.drivers, "Incentive as Driver"))
                            }

                            <br />

                            {
                                val.helpers.map((data, index) => getComponent(data, val.helpers.length, index, val.helpers, "Incentive as Helper"))
                            }

                            <br />

                            <div style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                                <div className="row" >

                                    <div className="col-md-4" >
                                        Total Incentive
                                    </div>
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-1" >

                                    </div>
                                    <div className="col-md-3"  >
                                        {val.totalIncentive.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </div>

                                </div>
                            </div>

                        </div>
                    )
                }

            </div>
        </div>
    )
}