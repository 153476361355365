import { faArrowLeft, faArrowRight, faFilter, faL, faListAlt, faRotate, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import OpenSrFilter from "./OpenSrFilter";
import SrDetails from "./SrDetails";


export default function ListOfOpenSR(props) {


    const [thisComponentState, updateState] = useState({
        from: "",
        to:"",
        details: [],
        pickItems: [],
        showDetails: false,
        selectedGuidId:null,
        isLoading:false,
        retrieve: 0,
        multipleGuidIds: [],
        showSummary: false,
        filterDetails: false,
        originalValues: []
    });

    useEffect(() => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/StockTransfers/GetDefaultDateRange?")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.from = response.from;
                tempData.to = response.to;
                tempData.isLoading = false;
                
                updateState(tempData);

            });
       
        
    }, [thisComponentState.retrieve]);



    const pick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = true;

        updateState({ ...thisComponentState, details: items });

    }

    const unPick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = false;

        updateState({ ...thisComponentState, details: items });

    }


    const showSummary = () => {

        let selectedGuidIds = thisComponentState.details.filter(x => x.isPick).map(y => y.guidId);

        let items = [...thisComponentState.multipleGuidIds];

        items = selectedGuidIds;

        updateState({ ...thisComponentState, multipleGuidIds: items,showSummary:true});
    }

    
    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

 
    const doubleBack = () => {

        //The previous
        props.back();

        //This component

        updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })

    }


    if (thisComponentState.showDetails) {

        return (<SrDetails doubleBack={doubleBack} guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })} />)
    }


    if (thisComponentState.showSummary) {

        return (<MultiplePrDetailsForPoReview doubleBack={ doubleBack } guidIds={thisComponentState.multipleGuidIds} back={() => updateState({ ...thisComponentState, showSummary: false, retrieve: 1 })} />)
    }

    const compareDate = (paramA,paramB) => {

        let paramAYear = paramA.getFullYear();
        let paramAMonth = paramA.getMonth();
        let paramADay = paramA.getDate();

        let paramBYear = paramB.getFullYear();
        let paramBMonth = paramB.getMonth();
        let paramBDay = paramB.getDate();

        return paramAYear === paramBYear && paramAMonth === paramBMonth && paramADay === paramBDay;

    }

    const generate = () => {


        if (thisComponentState.from === "" || thisComponentState.to === "") {

            return alert("Defined a valid date range.")
        }

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/StockTransfers/GetListOfOpenSr?" + new URLSearchParams({

            type: "ST",
            from: thisComponentState.from,
            to: thisComponentState.to

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.originalValues = response;

                updateState(tempData);

            });
    }


    const filter = (criterias) => {
    
        let tempDatas = [...thisComponentState.originalValues];

        if (typeof criterias.documentId !== 'undefined') {
            tempDatas = tempDatas.filter(val => val.documentId === criterias.documentId);
        };

        if (typeof criterias.requestorId !=='undefined')
        {
            tempDatas = tempDatas.filter(val => val.requestorId === criterias.requestorId);
        };

        if (typeof criterias.departmentId !== 'undefined') {

            tempDatas = tempDatas.filter(val => val.departmentId === criterias.departmentId);
        };

        if (typeof criterias.requestDate !== 'undefined') {

            let requestDate = new Date(criterias.requestDate);

            tempDatas = tempDatas.filter(val => compareDate(new Date(val.requestDate), requestDate ));
        };

        if (typeof criterias.requiredDate !== 'undefined') {

            let requiredDate = new Date(criterias.requiredDate);

            tempDatas = tempDatas.filter(val => compareDate(new Date(val.requiredDate),requiredDate));
        };

        updateState({ ...thisComponentState, details: tempDatas,filterDetails:false});
    }


    const clearFilter = () => {

        let tempDatas = [...thisComponentState.details];

        tempDatas = thisComponentState.originalValues;

        updateState({ ...thisComponentState, details: tempDatas});
      
    }

    if (thisComponentState.filterDetails) {

        return (
            <div>
     
                <OpenSrFilter filter={filter} />
                
             </div>
     
         )
    }


    const onChangeFrom = (e) => {

        updateState({ ...thisComponentState, from: e.currentTarget.value });
 
    }

    const onChangeTo = (e) => {

        updateState({ ...thisComponentState, to: e.currentTarget.value});

    }



    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                  
                    <div className="col">
                        <div style={{ float: 'left' }}>
                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
                            <FontAwesomeIcon icon={faListAlt} title="show summary" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={showSummary} />
                            <FontAwesomeIcon icon={faFilter} title="show filter criteria" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer', transform: [{rotateY:'90deg'}] }} size="2x" color="orange" onClick={() => updateState({...thisComponentState,filterDetails:true})} />
                            <FontAwesomeIcon icon={faX} title="clear filter" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer'}} size="2x" color="green" onClick={clearFilter} />                                                     
                        </div>                       
                    </div>

                    <div className="col">
                        <input className="form-control" type="date" value={thisComponentState.from} onChange={onChangeFrom} />
                    </div>
                 
                    <div className="col">
                        <input className="form-control" type="date" value={thisComponentState.to} onChange={onChangeTo} />
                    </div>
                    <div className="col">
                        <input className="form-control" type="button" value="Retrieve" onClick={generate} />
                    </div>


                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="2"> Action</th>
                                    <th> Request Date </th>
                                    <th> Required Date </th>
                                    <th> Age</th>  
                                    <th> Doc Id </th>
                                    <th> Requestor </th>
                                    <th> Branch</th>
                                    <th> Department</th>
                                    <th> Remarks </th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={val.guidId}>

                                            <td>                                                                                           
                                                <input value={val.isPick ? "UnPick" : "Pick"} className="form-control" type="button" onClick={() => val.isPick   ? unPick(val.guidId) : pick(val.guidId)} />
                                            </td>

                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedGuidId:val.guidId,showDetails:true}) } />
                                            </td>

                                            <td> {val.requestDate}</td>
                                            <td> {val.requiredDate}</td>
                                            <td> {val.age}</td>
                                            <td> {val.documentId}</td>
                                            <td> {val.requestor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.department}</td>
                                            <td> {val.remarks}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
