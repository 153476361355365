import React, { useEffect, useState, useContext, Fragment, useRef } from "react";
import { TransactionContext } from "./TransactionContext";
import "../style.css";
import getPropertyValueFromAutoCompleteSuggestions from "../Functions/getPropertyValue";
import getFunctionName from "../Functions/getFunctionName";

export default function   AutoCompleteInput(props) {

    const { data,updateParentValue, updateDetailValue } = useContext(TransactionContext);

    const [value, updateValue] = useState({
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: typeof  props.value === 'undefined' || props.value===null ? "" : props.value,

        index: props.index,

        dataType: props.dataType,

        code: props.code,

        name: props.name,

        value: typeof props.value ==='undefined' || props.value ===null ? "" : props.value,

        data: props.data,

        url: props.url,

        isLoaded: false,

        hierachyType : props.hierachyType,

        suggestions: props.suggestions
    });

    useEffect(() => {

        if (value.hierachyType === "parent") {

            var functionName = getFunctionName(value.name, data.metaData.parentMetaData);

            if (typeof functionName !== 'undefined') {
        
                var result = data.metaData.getAutoComplete(functionName);

                if (typeof result !== 'undefined') {

                    updateValue({ ...value, isLoaded: true, suggestions: result });
                }
            };

        } else {

            var functionName = getFunctionName(value.name, data.metaData.detailMetaData);

            if (typeof functionName !== 'undefined') {

                var itemCode = data.metaData.detailProperties[value.index].code;

                var result = data.metaData.getAutoComplete(functionName,itemCode);

                if (typeof result !== 'undefined') {
           
                    updateValue({ ...value, isLoaded: true, suggestions: result });

                }
            };

        };

  
    }, [data]);


    const onChange = (e) => {

        const userInput = e.currentTarget.value;
        // Filter our suggestions that don't contain the user's input

        const filteredSuggestions = value.suggestions.filter(
            (suggestion) =>
                suggestion.description.toLowerCase().indexOf(userInput.toLowerCase()) >
                -1
        );

        updateValue({
            ...value,
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    const onClick = (e) => {

        var code = e.target.getAttribute("code");

        updateValue({
            ...value,
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });

        if (value.hierachyType === "parent") {

            let result = code.split("=");

            if (result[0] === "Flag") {

                localStorage.setItem("flagCategory", JSON.stringify(result[1]));

            }

            updateParentValue(value.name,code);

        } else {

            updateDetailValue(value.index, value.name, code);

           

        };  
    };

    const getWidth = () => {

        return typeof props.width !== 'undefined' && props.width !== null ? props.width : "150px";

    }

    const onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = { ...value };

        // User pressed the enter key
        if (e.keyCode === 13) {
            updateValue({
                ...value,
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            updateValue({ ...value, activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {

            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            // this.setState({ activeSuggestion: activeSuggestion + 1 });

            updateValue({ ...value, activeSuggestion: activeSuggestion + 1 });
          
        }
    };

    const onFocus = (e) => {

        console.log('Input focused');
    };

    if (!value.isLoaded) {

        return <div>Loading data</div>;

    } else {

        let suggestionsListComponent;

        if (value.showSuggestions && value.userInput) {
            if (value.filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions"  >

                        {value.filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === value.activeSuggestion) {

                                className = "suggestion-active";
                            }

                            return (
                                <li
                                    className={className}
                                    key={suggestion.code}
                                    onClick={onClick}
                                    code={suggestion.code}
                                >
                                    {suggestion.description}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>Invalid data...</em>
                    </div>
                );
            }
        }

        return (

            <div>
                <input
                 /*   ref={props.innerRef}*/
                    type="text"
                    className="form-control"
                    style={{ minWidth: getWidth() }}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    name={value.name}
                    onDoubleClick={() => updateValue({ ...value, value: "", userInput: "" })}
                    value={value.value !== "" && value.value !== null ? getPropertyValueFromAutoCompleteSuggestions(value.value, value.suggestions) : value.userInput}
                    onFocus={onFocus}
                />
                
                {suggestionsListComponent}

            </div>

        );
    }
}



