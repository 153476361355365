
import { useEffect, useState } from "react";

import CycleCountDetails from './CycleCountDetails';

export default function CreateCycleCount(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        showCycleCountDetails: false,
        cycleCountId:0,
        start: "",
        end:""
    });


    
    useEffect(() => {

        fetch("/Crates/GetNextCycleCount")

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    start: response.start,
                    end:response.end

                });

            });
    }, []);

  


    const save = () => {

        var isConfirm = window.confirm("Are you sure you want to create this cylce?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Crates/CreateCycleCount", {

            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                value: thisComponentState.start
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false, cycleCountId: response.cycleCountId });

                    props.back(true);

                    return;

                }

                updateState({ ...thisComponentState, isPerformingOperation: false });


                return alert(response.message);

            });
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.showCycleCountDetails) {

        return (<CycleCountDetails cycleCountId={thisComponentState.cycleCountId} back={() => updateState({...thisComponentState,showCycleCountDetails:false})}/>)
       
    }


    return (

        <div>
            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Cycle Count Id
                </div>
                <div className="col">
                    <input type="text" disabled value="To be computed" className="form-control" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Start
                </div>
                <div className="col">
                    <input value={thisComponentState.start} className="form-control" type="date" onChange={(e) => updateState({ ...thisComponentState, start: e.currentTarget.value })} />
                </div>
            </div>
         
            <div className="row">
                <div className="col">
                    End
                </div>
                <div className="col">
                    <input value={thisComponentState.end} className="form-control" type="date" onChange={(e) => updateState({...thisComponentState,end:e.currentTarget.value}) }/>
                </div>
            </div>

            <div className="row">
                <div className="col">
          
                </div>
                <div className="col">
                    <input type="button" value="Save" className="form-control" onClick={save} />
                </div>
            </div>

        </div>
    )
}