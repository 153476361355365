import React, { useState } from 'react';

 export default function PushForm(props) {

    const [thisComponentState, updateState] = useState({

        data: [],
        isInitial:true,
        isLoading: false,
        deliveryDate :""
    });


     async function generate(area) {

         var deliveryDate = thisComponentState.deliveryDate;

         if (deliveryDate === "") {

             return alert("Please defined a valid date.");

         };

        await fetch("/BreadOuts/PushSheet?" + new URLSearchParams({

            deliveryDate: deliveryDate,
            area : area

        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.data.length > 0) {

                    updateState({ ...thisComponentState, data: responseData, isLoading: false, isInitial: false });

                } else {

                    updateState({ ...thisComponentState, data: [] });

                    return alert("No available data.");
                }
            });
    };

     const getQty = (array, name) => {


         var filtered = array.find(x => x.shortCode === name);

         if (typeof (filtered) !== 'undefined') {

             return filtered.quantity;
         }
         
     }


     if (thisComponentState.isInitial) {

         return (
             <div style={{ float: "left", display: "inline-block" }}>
                 <div style={{ width: "150px", float: "left", marginLeft: "15px"}} className='form-control'>Delivery Date</div>
                 <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.deliveryDate} onChange={(e) => updateState({ ...thisComponentState,deliveryDate: e.currentTarget.value })} />
               
                 <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=>generate('NEW')} value="New Building" />

                 <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=>generate('OLD')} value="Old Building" />

                 <br />
                 <br />

             </div>
         )
     }

    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {


        if (typeof(thisComponentState.data) !='undefined' && typeof(thisComponentState.data.data)!='undefined' && thisComponentState.data.data.length != 0) {

            return (

                <div>
                    <div style={{ float: "left", display: "inline-block" }}>

                        <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Delivery Date</div>

                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.deliveryDate} onChange={(e) => updateState({ ...thisComponentState, deliveryDate: e.currentTarget.value })} />
                      
                        <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=>generate('NEW')} value="New Building" />

                        <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=>generate('OLD')} value="Old Building" />

                        <br />
                        <br />

                    </div>

                    <br />

                    <div>


                        <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                            <thead className="thead-dark">

                                <th>Agent</th>
                                {
                                    Object.entries(thisComponentState.data.shortCodes).map(y =>

                                        <th> {(y[1])}</th>
                                    )
                                }
                            </thead>

                            <tbody>

                                {                              
                                    thisComponentState.data.data.map(agent =>

                                        <tr>
                                          
                                                <td> {agent.agent} </td>

                                                {
                                                    thisComponentState.data.shortCodes.map(a =>

                                                        <td> {getQty(agent.details, a)} </td>

                                                    )

                                                }
                                          
                                        </tr>

                                   
                                    )
                                }

                            </tbody>

                        </table>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ float: "left", display: "inline-block" }}>

                    <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Delivery Date</div>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.deliveryDate} onChange={(e) => updateState({ ...thisComponentState, deliveryDate: e.currentTarget.value })} />
                  
                    <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=>generate('NEW')} value="New Building" />

                    <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={()=>generate('OLD')} value="Old Building" />

                    <br />
                    <br />

                </div>
            )



        }

             


    }
}

