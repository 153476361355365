import { faPlus, faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';

import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';

import { TransactionContext } from '../TransactionContext';
import Service from './Service';


export default function CreateArCreditMemoNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState ({

        showServiceCapture:false

    });

    const pullOrder = () => {

        var tempData = { ...data.metaData };

        tempData.parentProperties = createParentProperties(tempData.modifiedParentProperties);
        tempData.detailProperties = createDetailProperties(tempData.modifiedDetailProperties);
        
        updateMetaData({ metaData: tempData });
    }


    if (thisComponentState.showServiceCapture) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Service back={() => updateState({ ...thisComponentState, showServiceCapture: false })} />

                  

                </div>
            </div>
        )

    }


  
    return (

        <div style={{ float: "left" }}>
        
                <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

                <FontAwesomeIcon icon={faSnowflake} title="Service Types" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showServiceCapture: true })} />
            <br />

        </div>

    )

}


