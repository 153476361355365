import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import UploadFile from "../../../UploadFile";
import TakePictureOfFinishedJob from "./TakePictureOfFinishedJob";


export default function CloseJobOrder(props)
{
    const [thisComponentState, updateState] = useState({

        jobOrderId: props.jobOrderId,
        toTakePicture:false,
        completionDate: "",
        uploadFile:false,
        jobOrderAfterImages: [],
        files:[]

    });

    const close = () => {

        if (thisComponentState.completionDate === "") {

            return alert("Completion date is required.");
        }

        const confirm = window.confirm("Are you sure you want to close this job order?");

        if (!confirm) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/StockRequisitions/CloseJobOrder",
            {
                method: "post",
                body: JSON.stringify({
                    jobOrderId: thisComponentState.jobOrderId,
                    images: thisComponentState.jobOrderAfterImages,
                    completionDate:thisComponentState.completionDate
                }),
                headers: {

                    "content-type": "application/json; charset:UTF-8"
                }

            }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false });

                    props.back();

                    return alert(response.message);

                } else {

                    updateState({ ...thisComponentState, isPerformingOperation: false });

                    props.back();

                    return alert(response.message);

                }
            });
    }

    const back = (arrayOfImages) => {

        if (arrayOfImages.length != 0) {

            updateState({ ...thisComponentState, jobOrderAfterImages: arrayOfImages,toTakePicture:false});
        }  
    }


    if (thisComponentState.toTakePicture)
    {
        return (<TakePictureOfFinishedJob back={back} />)
    }



    return (

        <div>
            <hr />


            <div className="row">

                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                </div>

                <div className="col">                   
                    <input type="button" className="form-control" value="Take Picture" onClick={() => updateState({ ...thisComponentState, toTakePicture: true })}  />
                </div>


            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Completion Date   
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.completionDate}  className="form-control" onChange={(e) => updateState({...thisComponentState,completionDate:e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
              
                </div>
                <div className="col">
                    <input type="button" className="form-control" value="Close" onClick={close} />
                </div>
            </div>


            <div className="row">
                <div className="col">
                     Pictures of Completed Job Orders
                </div>
                <div className="col">
                </div>
            </div>

            <hr />

            <div>

                <div style={{ overflowX: 'scroll' }}  >
                    {
                        thisComponentState.jobOrderAfterImages.map(val => <img className="img-fluid" style={{ display: "inline-block" }} src={val} alt="no" />)
                    }
                </div>
            </div>

            <hr/>

        </div>
    )
}