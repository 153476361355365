import { useEffect, useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";

export default function SyncInfo()
{

    const [thisComponentState, updateState] = useState([]);

    const getDataToSync = (date) => {


        fetch("/Invoices/GetDataForToSync?" + new URLSearchParams({

            date: date

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState(response);

            });


    };


    const sync = () => {




    }

    return (

        <div style={getModalStyle() }>
            <div style={getModalContentStyle() }>

                <div className='row'>
                    <div className='col'>
                        <input type='date' className='form-control' onChange={(e)=> getDataToSync(e.currentTarget.value)} />
                    </div>
                    <div className='col'>
                        <input type='button' value='Back' className='form-control' />
                    </div>
                    <div className='col'>
                        <input type='button' value='Sync' className='form-control' onClick={sync} />
                    </div>
                </div>

                <br/>
                <br />


                <div className='row'>
                    <div className='col'>
                        <table className='table table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Action</th>
                                    <th>Agent</th>
                                    <th>Items Not Sync</th>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    thisComponentState.map(val =>

                                        <tr>
                                            <td>

                                                {
                                                    val.success ? <input type='checkbox' checked /> : <input type='checkbox' />
                                                }

                                            </td>

                                            <td>{val.name}</td>

                                            <td>{val.itemsNotSync}</td>

                                        </tr>)
                                }

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
       </div>     
   )
}
