import createProperties from "./createProperties";

function createDetailProperties(metaData) {


    var array = [];

    if (Array.isArray(metaData[0])) {

        for (var i = 0; i < metaData.length; i++)
        {

            var tempObject = createProperties(metaData[i]);

            array.push(tempObject);

        }

    } else {

        var object = createProperties(metaData);

        array.push(object);

    }

    return array;

}

export default createDetailProperties;