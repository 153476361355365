import { useEffect, useState } from "react"
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";



export default function ApplyPayment(props)
{
    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        cardCode: props.cardCode,
        docEntry: props.docEntry,
        accountCode:"",
        name: props.name,
        invoiceDate: props.invoiceDate,
        guidId:props.guidId,
        postDate: "",
        dueDate: "",
        docDate: "",
        sequence:"First",
        note:"",
        amount: 0,
        totalAmountApplied:0
    });


    const save = () => {

        if (thisComponentState.postDate === "") {

            return alert("Posting date is required.");
        };

        if (thisComponentState.dueDate === "") {

            return alert("Doc due date is required.");
        };

        if (thisComponentState.docDate === "") {

            return alert("Document date is required.");
        };

        var toPost = {
            cardCode : thisComponentState.cardCode,
            postDate : thisComponentState.postDate,
            docDate: thisComponentState.docDate,
            dueDate: thisComponentState.dueDate,
            note: thisComponentState.sequence + " : " + thisComponentState.note,
            accountCode: thisComponentState.accountCode,
            guidId:thisComponentState.guidId,
            incomingPaymentDetailModels: [
                {
                    docEntry: thisComponentState.docEntry,
                    incomingPaymentType: 0,
                    sumApplied:parseFloat(thisComponentState.amount)
                }
            ]
        }


        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Invoices/ApplyPayment", {
            method: "post",
            body: JSON.stringify(toPost),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, totalAmountApplied: thisComponentState.totalAmountApplied + thisComponentState.amount });

                } else {
                   
                    alert(response.message);
                }

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });

    }

    const updateAccountCode = (code) => {

        updateState({ ...thisComponentState, accountCode: code });

    }

    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }

    const onPostDateChange = (e) => {

        updateState({
            ...thisComponentState,
            postDate: e.currentTarget.value,
            docDate: e.currentTarget.value,
            dueDate: e.currentTarget.value
        })
    }

    const back = () => {

        props.modifyBalance(thisComponentState.guidId, parseFloat(thisComponentState.totalAmountApplied));

        props.back()
    }


    return (
        <div>
            <br />
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("600px","800px")}>


                    <div className="row">

                        <div className="col">
                            <input value="Back" type="button" className="form-control" onClick={back} />
                        </div>

                  
                    </div>

                    <div className="row">
                        <div className="col">
                            Name
                        </div>

                        <div className="col">

                            <div>{ thisComponentState.name}</div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Invoice Date
                        </div>

                        <div className="col">

                            <div>{thisComponentState.invoiceDate}</div>

                        </div>
                    </div>
                    
                    <div className="row">

                        <div className="col">
                            Posting Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.postDate} className="form-control" onChange={(e) =>onPostDateChange(e)} />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col">
                            Due Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.dueDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, dueDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Document Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.docDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, docDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                          Sequence
                        </div>

                        <div className="col">                           
                            <select defaultValue={thisComponentState.sequence} className="form-control" onChange={(e) => updateState({...thisComponentState,sequence:e.currentTarget.value})}>
                                <option value="First">First</option>
                                <option value="Second">Second</option>
                                <option value="Third">Third</option>
                            </select>         
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Account Code
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Invoices/GetChartOfAccounts" value={thisComponentState.accountCode} clear={() => updateState({...thisComponentState,accountCode:""})} updateValue={updateAccountCode} placeholder="Account Code Search" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Note
                        </div>

                        <div className="col">
                      
                            <textarea value={thisComponentState.note} className="form-control" onChange={(e) => updateState({ ...thisComponentState, note: e.currentTarget.value })}>
                            </textarea>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                           Amount
                        </div>

                        <div className="col">
                            <input value={thisComponentState.amount} className="form-control" onChange={(e) => updateState({ ...thisComponentState,amount: e.currentTarget.value })} />
                        </div>
                    </div>

                    <br/>

                    <div className="row">

                        <div className="col">                     
                           
                        </div>

                        <div className="col">
                            <input value="Save" type="button" className="form-control" onClick={save} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}