import React, {useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import exportToCSV from '../Functions/exportToCSV';
import getModalContentStyle from '../Functions/getModalContentStyle';
import getModalStyle from '../Functions/getModalStyle';
import CategorySelection from '../Transactions/Producing/CategorySelection';


 export default function ProductionSummary(props) {

     
    const [thisComponentState, updateState] = useState({

        data: [],
        isInitial:true,
        isLoading: false,
        fromDate :"",
        toDate: "",
        isExporting:false

    });


     const [selectedCategories, updateSelectedCategories] = useState([]);

     const [showCategorySelection, updateShowCategorySelection] = useState(false);


     async function generate() {


         var fromDate = thisComponentState.fromDate;
         var toDate = thisComponentState.toDate;

         if (fromDate === "" || toDate==="") {

            return alert("Please defined a valid date range.");
         };

        await fetch("/Productions/ProductionSummary?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate,
            pCategories: selectedCategories.join(":")

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.length > 0) {

                    updateState({ ...thisComponentState, data: responseData, isLoading: false, isInitial: false });

                } else {

                    updateState({ ...thisComponentState, data: [] });

                    return alert("No available data.");
                }
            });

     };


     const exports = () => {

         var fromDate = thisComponentState.fromDate;

         var toDate = thisComponentState.toDate;

         if (fromDate === "" || toDate === "") {

             return alert("Please defined a valid date.");
         };

         updateState({ ...thisComponentState, isExporting: true });

         fetch("/Productions/Export?" + new URLSearchParams({

             fromDate: fromDate,
             toDate: toDate,
             pCategories: selectedCategories.join(":")

         }))

             .then(response => response.status == 401 ? window.location = "/Login" : response.json())
             .then(responseData => {

                 if (responseData.success) {

                     exportToCSV("ProductionSummary", responseData.value)

                     updateState({ ...thisComponentState, isExporting: false });

                 } else {

                     updateState({ ...thisComponentState, isExporting: false });

                     return alert("No available data.");
                 }
             });

     }


     if (showCategorySelection) {

         return (<div style={getModalStyle(2)} >
             <div style={getModalContentStyle()}>

                 <CategorySelection selectedCategories={selectedCategories} updateSelectedCategories={updateSelectedCategories} updateShowCategorySelection={updateShowCategorySelection} />

             </div>
         </div>)

     }

     if (thisComponentState.isInitial) {


         return (
             <div style={{ float: "left", display: "inline-block" }}>

                 <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState,fromDate: e.currentTarget.value })} />
                 <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                 <div style={{ float: "left" }}>
                     <input value="Category" onClick={() => updateShowCategorySelection(true)} className="btn btn-primary" style={{ width: "120px", marginLeft: "15px" }} type="button" />
                 </div>

                 <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={generate} value="Generate"/>
                 <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />


                 <br />
                 <br />

             </div>
         )
     }




    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {


        if (thisComponentState.data.length != 0) {

            return (

                <div>
                    <div style={{ float: "left", display: "inline-block" }}>

                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                        <div style={{ float: "left" }}>
                            <input value="Category" onClick={() => updateShowCategorySelection(true)} className="btn btn-primary" style={{ width: "120px", marginLeft: "15px" }} type="button" />
                        </div>

                        <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={generate} value="Generate" />

                        <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />
     
                        <br />
                        <br />

                    </div>

                    <br />

                    <div>

                        <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                            <thead className="thead-dark">

                                {
                                    Object.entries(thisComponentState.data[0]).map(y =>

                                        y[0] !== "guidId" ? <td>{capitalizeFirstLetter(y[0])}  </td> : <td> Actions  </td>
                                    )
                                }

                            </thead>

                            <tbody>

                                {
                                    thisComponentState.data.map(x =>
                                        <tr>

                                            {
                                                Object.entries(x).map(y =>

                                                    y[0] !== "guidId" ? <td>{y[1]}</td> : <td> <input className="btn btn-success" style={{ marginLeft: "15px", marginRight: "15px", width: "120px" }} value="Details" type="button" /> </td>

                                                )
                                            }
                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ float: "left", display: "inline-block" }}>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                    <div style={{ float: "left" }}>
                        <input value="Category" onClick={() => updateShowCategorySelection(true)} className="btn btn-primary" style={{ width: "120px", marginLeft: "15px" }} type="button" />
                    </div>

                    <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={generate} value="Generate" />

                    <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                    <br />
                    <br />

                </div>
            )
        }
    }
}

