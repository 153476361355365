import React from 'react';
import { useState } from 'react';
import getModalStyle from '../../Functions/getModalStyle';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import StandardAutoComplete from '../../StandardAutocomplete';
import RelationshipMap from './RelationshipMap';



export default function FindPOByVendor(props)
{
    
    const [thisComponentState, updateState] = useState({

            supplierId :null,
            details: [],        
    });

    const updateSupplierId = (code) => {

        updateState({ ...thisComponentState, supplierId: code });

        retrieve();
    };



    const retrieve = () => {

          fetch( "PurchaseOrder/PoListBySupplier?supplierId="+thisComponentState.supplierId)
            .then(response =>

                response.status == 401 ? window.location = "/Login" : response.json()

            ).then(response => {

                const dataFromServer = response;

                var tempData = { ...thisComponentState.data};

                tempData.details = dataFromServer;

                updateState({ ...thisComponentState, data:tempData});

                
            });
    }

   
    return (

        //<div style={getModalStyle()}>
        //    <div style={getModalContentStyle()}>

        //        <div>
        //            <StandardAutoComplete url="/PurchaseOrders/GetVendors" value={thisComponentState.supplierId} updateValue={updateSupplierId} placeholder="Enter Vendor" />

        //            <input className='btn btn-success' onClick={() => props.back()} />

        //        </div>

        //        <table className='table table-bordered'>

        //            <thead className='thead-dark'>
        //                <th> Date </th>
        //                <th> Doc Num </th>
        //                <th> Doc Entry </th>
        //                <th> Gross </th>
        //                <th> Discount </th>
        //                <th> Net </th>
        //            </thead>

        //            <tbody>
        //                {
        //                    thisComponentState.details.map(detail=> 

        //                        <tr>
        //                            <td> {detail.date }</td>
        //                            <td> {detail.docNum }</td>
        //                            <td> {detail.donEntry }</td>
        //                            <td> {detail.gross }</td>
        //                            <td> {detail.docDiscount}</td>
        //                            <td> {detail.net}</td>

        //                        </tr>
        //                    )


        //                }
                        

        //            </tbody>

        //        </table>

        //     </div>
        //</div>

        <RelationshipMap/>
       
        
    )
}