import React, {useState,useContext } from 'react';
import convertToDataType from '../Functions/convertToDataType';
import getDisplayName from '../Functions/getDisplayName';
import getHtmlType from '../Functions/getHtmlType';
import getUrl from '../Functions/getUrl';
import isAutocomplete from '../Functions/isAutocomplete';
import StandardInput from '../StandardInput';
import AutoCompleteInputForMaterfile from './AutoCompleteInputForMasterfile';
import { MasterfileContext } from './MasterfileContext';
import MasterfileList from './MasterfileList';





export default function MasterfileForm(props) {

    const { data,updateMetaData } = useContext(MasterfileContext);

    const [thisComponentState, updateState] = useState({

        showList: false,
        isSaving:false
    });

    function updateValue(name, value) {

        const tempData = { ...data.metaData };

        tempData.parentProperties[name] = convertToDataType(data.metaData.parentMetaData, name, value);

        updateMetaData({ metaData: tempData });

    };


    function submitForm() {

        updateState({ ...thisComponentState, isSaving: true });

        for (var property in data.metaData.parentProperties) {

            var value = data.metaData.parentProperties[property];

            data.metaData.parentProperties[property] = convertToDataType(data.metaData.parentMetaData, property, value);

        };

        console.log(data.metaData.parentProperties);

        fetch(data.metaData.postUrl, {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(data.metaData.parentProperties),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })

            // Converting to JSON
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())

            // Displaying results to console
            .then(response =>
            {

                if (response) {

                    updateState({ ...thisComponentState, isSaving: false });

                    window.location.reload();

                    return alert("Data has been saved...");

                };

                updateState({ ...thisComponentState, isSaving: false });

                return alert("Something went wrong....");

            }).catch(e => {

                updateState({ ...thisComponentState, isSaving: false });

                return alert("Something went wrong....");

            });
    }

    if (thisComponentState.isSaving) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }


    if (thisComponentState.showList) {

        return (<MasterfileList url={data.metaData.listUrl} />);

    };


    if (data.metaData.isFormSubmitted) {

        return (<MasterfileList url={data.metaData.listUrl}/> )

    } else {

       return (

           <form>

           {
               
                Object.entries(data.metaData.parentProperties).map((valueObject) =>

                    <div className="form-group">

                        <label>{getDisplayName(valueObject[0], data.metaData.parentMetaData)}   </label>

                        {
                                isAutocomplete(valueObject[0], data.metaData.parentMetaData) ?
                                <AutoCompleteInputForMaterfile key={Math.random} value={valueObject[1]} name={valueObject[0]} url={getUrl(valueObject[0], data.metaData.parentMetaData)} /> :
                                <StandardInput key={Math.random} htmlType={getHtmlType(valueObject[0], data.metaData.parentMetaData)} name={valueObject[0]} value={valueObject[1]} updateValue={updateValue} isParent={true}  className="form-control" />
                        }
                     
                    </div>

                )
           }
             
               <input type="button" onClick={submitForm} value="Save" style={{ width: "100px" }} className="btn btn-primary" />

               <input type="button" onClick={() => updateState({...thisComponentState,showList:true})} value="List" style={{ width: "100px",marginLeft:"15px" }} className="btn btn-primary" />

       </form>

        )
    }

}

