import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import UploadFile from "../../../UploadFile";




export default function SmSales(props)
{
    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        uploadFile:false,
        sales:[]       
    });



    const processXmlInvoices = (files) => {

        let formData = new FormData();

        files.forEach(val => formData.append("files", val));

        updateState({ ...thisComponentState, isPerformingOperation: true, uploadFile: false})

        fetch("/Invoices/ProcessXmlInvoices",
            {
                method: "post",
                body: formData

            }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {
         
                if (response.success) {

                    updateState({ ...thisComponentState, sales: response.sales, isPerformingOperation: false})

                    return alert("Click back to see process invoices.")
                }
                
                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }


    const invoice = (data) => {

        fetch("/Invoices/PostSmInvoice", {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState.sales];

                    let tempData = tempDatas.filter(val => val.guidId !== data.guidId);

                    updateState({ ...thisComponentState, sales: tempDatas, isPerformingOperation: false });

                    return;

                } else {

                    updateState({ ...thisComponentState, isPerformingOperation: false});

                    return alert(response.message);

                }
            });
    }


    if (thisComponentState.uploadFile) {

        return (<UploadFile action={processXmlInvoices} fileLimit={3} isSeparateUpload={false} identifier={""} uploadUrl="/Invoices/ProcessXmlInvoices" back={() => updateState({ ...thisComponentState, uploadFile: false })} />)
    }

    if (thisComponentState.isPerformingOperation)
    {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing XML invoices...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (
        <div>

            <div className="row">

                <div className="col">

                    <input type="button" value="Back" onClick={()=>props.back()} className="form-control"/>

                </div>  

                <div className="col">

                
                    <input type="button" value="Upload" onClick={() => updateState({ uploadFile: true })} className="form-control" />

                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>                       
                        </thead>

                        <tbody>
                            {
                               typeof thisComponentState.sales !=='undefined' && thisComponentState.sales.map(val =>
                                   <tr>
                                        <td>
                                           <input type="button" className="form-control" value="Invoice" onClick={() => invoice(val)} />
                                        </td>
                                        <td>{val.code}</td>
                                        <td>{val.name}</td>
                                        <td>{val.date.toString("MM-dd-yyyy")}</td>
                                        <td>{val.docTotal}</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    )
}