
import  React, { Fragment } from 'react';
import { useEffect } from "react";
import { useState } from "react"
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import uuid from 'react-uuid'
import StandardAutoComplete from '../../StandardAutocomplete';
import PureInputByGuid from '../../PureInputByGuidId';
import DailyProductionPlanned from '../../Reports/DailyProductionPlanned';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function NotGood(props) {

    const [thisComponentState, updateState] = useState({
        data: {
            items :[],
            descriptions:[]
        },
        isSaving:false
    });

    useEffect(() => {

        getSavedData();

    }, []);


    async function getSavedData() {

        updateState({ ...thisComponentState, isLoading: true });

        await fetch("/Productions/GetNotGood?"+ new URLSearchParams({

                date: props.date,
                code: props.itemCode

             }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState.data };

                let notGoodContainers = {
                    guidId: uuid(),
                    date: props.date,
                    code: props.itemCode,
                    factor: null,
                    quantity: 0
                };

                tempData.items.push(notGoodContainers);

                props.descriptions.unshift("Please select factor");

                tempData.descriptions = props.descriptions;
                tempData.items = response;

                updateState({ ...thisComponentState, data:tempData, isLoading: false });
            });
    };


    const add = () => {

        let notGoodContainers = {
            guidId :uuid(),
            date: props.date,
            code: props.itemCode,
            factor: null,
            quantity: 0
        };

        var tempData = { ...thisComponentState.data };

        tempData.items.push(notGoodContainers);

        updateState({ ...thisComponentState, data: tempData });

    }

    const updateItem = (index,name,value) => {

        let tempData = { ...thisComponentState.data };

        if (name === "quantity") {

            value = parseFloat(value);
        }

        tempData.items[index][name] = value;

        updateState({ ...thisComponentState, data: tempData });

    }

    const saved = () => {

       //validate 

        for (var i = 0; i < thisComponentState.data.items.length; i++) {

            if (typeof thisComponentState.data.items[i].factor === 'undefined' || thisComponentState.data.items[i].factor === '' || thisComponentState.data.items[i].factor ===null|| thisComponentState.data.items[i].factor === "Please select factor") {

                return alert("Factor needs to be selected.");

                break;
            }

            if (thisComponentState.data.items[i].quantity === 0   ||
                thisComponentState.data.items[i].quantity === ""  ||
                thisComponentState.data.items[i].quantity === "0" )
            {

                return alert("Quantity cannot be zero.");

                break;
            }
        }

        updateState({ ...thisComponentState, isSaving: true });

        fetch("/Productions/PostNotGood",
            {
                method: "post",
                body: JSON.stringify(thisComponentState.data.items),
                headers: {
                    'content-type': 'application/json; charset:UTF-8'
                }
            })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {


                alert(response.message);

                updateState({ ...thisComponentState, isSaving:false });

            });
    }
    

    const updateValue = (guidId,value) => {

        let tempData = { ...thisComponentState.data };

        tempData.items.forEach(item => {

            if (item.guidId === guidId) {
                item.quantity = parseFloat(value);
            };

        });

        updateState({ ...thisComponentState, data: tempData });
    }

    const deleteRow = (guidId) => {

        let tempData = { ...thisComponentState.data };

        tempData.items = tempData.items.filter(x => x.guidId !== guidId);

        updateState({ ...thisComponentState, data: tempData });
    }


    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }
    return (

        <div style={getModalStyle(2) }>

            <div style={getModalContentStyle()}>

                <table className='table table-bordered'>

                    <thead>
                        <tr>

                            <th colSpan="3"> {props.name} </th>

              
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th colSpan="3"><input type="button" value="Back" className="form-control" onClick={()=> props.goBack()}/></th>
                        
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Factor</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            thisComponentState.data.items.map((item, index) =>

                                <tr key={uuid()}>

                                    <td>

                                        <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={()=>deleteRow(item.guidId)}/>

                                    </td>

                                    <td>
                                        <select className='form-control' value={ item.factor } onChange={(e) => updateItem(index, "factor", e.currentTarget.value)}>

                                            {thisComponentState.data.descriptions.map(desc =>

                                                <option key={uuid()} value={item.desc} > {desc} </option>
                                            )}
                                        </select>

                                    </td>

                                    <td>
                                        <PureInputByGuid value={item.quantity} name="quantity" guidId={item.guidId } updateValue={updateValue} />
                                    </td>

                                </tr>
                            )
                        }

                    </tbody>

                    <tfoot>
                             <tr>
                                <td>
                                    <input type='button' value='Add' onClick={add } className='form-control'/>
                                </td>
                              <td>

                                <input type='button' value='Save' onClick={saved} className='form-control' />
                                </td>

                                <td></td>

                            </tr>
                    </tfoot>

                </table>

                </div>

            </div>
             
        )
}