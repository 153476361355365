import React, { useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';

import { TransactionContext } from '../TransactionContext';
import PureInputByGuid from '../../PureInputByGuidId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faForward, faHandHolding, faPlus, faPrint, faWindowClose} from '@fortawesome/free-solid-svg-icons';


export default function DetailsStockTransferNavigator(props) {
   
    const { guidId, data, updateMetaData,reloadDetails} = useContext(TransactionContext);

    
    const [thisComponentState, updateState] = useState({

        send: false,
        acceptedQtys: [],
        isAccepting: false,
        isAuthorizingReleased: false,
        authorizationCode:"",
        isPerformingOperation: false,
        isCancelling : false

    });


    const accept = () =>
    {
        if (data.metaData.detailsFromDb.isAccepted === "Y" && data.metaData.detailsFromDb.isClosed === "Y") {

            return alert("This stock transfer has already been accepted and closed.");

        };

        let acceptedQtys = [...data.metaData.detailsFromDb.details ];

        for (var i = 0; i < acceptedQtys.length; i++) {
         
            acceptedQtys[i].receivedQty = acceptedQtys[i].quantity;           
        };

        acceptedQtys = acceptedQtys.filter(val => val.receivedQty !== 0);

        updateState({ acceptedQtys: acceptedQtys,isAccepting:true});     
    }

    const updateQty = (guidId,acceptedQty) => {

        let finalValue = parseFloat(acceptedQty);

        let temp = [...thisComponentState.acceptedQtys]

        for (var i = 0; i < temp.length; i++) {

            if (temp[i].guidId === guidId) {

                temp[i].receivedQty = finalValue;

                return;          
            }
        };

        updateState({ ...thisComponentState, acceptedQtys: temp });

    }

    const update = () => {

        updateState({ ...thisComponentState, isUpdating: true });

        let acceptedQtys = [];

        thisComponentState.acceptedQtys.forEach(x => {

            let newObject = {};

            newObject.guidId = x.guidId;
            newObject.quantity = x.receivedQty;

            acceptedQtys.push(newObject);

        });


        var toPost = {

            value: guidId,
            acceptedModels: acceptedQtys
        };

        fetch("/StockTransfers/Accept", {

            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(toPost),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())

            .then(json => {

                if (json.success) {
                
                    reloadDetails();
                
                    alert(json.message);

                } else {

                    updateState({ ...thisComponentState, isAccepting: false, isUpdating: false });

                    return alert(json.message);
      
                }

            });
    };

    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/StockTransfers/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }



    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const cancel = () => {

        var isConfirm = window.confirm("Are you sure you want to cancel this transaction?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isCancelling: true })

        fetch("/StockTransfers/Cancel", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({ value: guidId }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
          }).then(response => response.json())
            .then(response => {

                 alert(response.message);
                 
                 updateState({ ...thisComponentState, isCancelling: false })

            });
    }

    const preview = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/StockTransfers/Print?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {


                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }




    const authorizedReleased = () => {
    
        var isConfirm = window.confirm("Are you sure you want to authorize the release of this stock transfer?");

        if (!isConfirm) {

            return;
        };

        if (thisComponentState.authorizationCode === "") {

            return alert("Authorization code cannot be empty.");

        }

        updateState({ ...thisComponentState,isPerformingOperation: true })

        fetch("/StockTransfers/AuthorizeReleaseOfStockTransfer", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                item1: guidId,
                item2: thisComponentState.authorizationCode
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json())
            .then(response => {

                if (response.success) {

                    alert(response.message);

                    reloadDetails();

                } else {

                    alert(response.message);

                }
         
                updateState({ ...thisComponentState, isPerformingOperation: false,isAuthorizingReleased:false })
            });
    }


    if (thisComponentState.isAuthorizingReleased)
    {
        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <div className="row">

                        <div className="col">
                            Authorization Code
                        </div>

                        <div className="col">
                            <input type="password" value={thisComponentState.authorizationCode} className="form-control" onChange={(e) => updateState({...thisComponentState,authorizationCode:e.currentTarget.value})}/>
                        </div>

                    </div>

                    <br/>

                    <div className="row">

                        <div className="col">
                           
                        </div>

                        <div className="col">
                            <div className="row">

                                <div className="col">
                                    <input type="button" className="form-control" value="Back" onClick={() => updateState({ ...thisComponentState, isAuthorizingReleased: false })} />
                                </div>

                                <div className="col">
                                    <input type="button" className="form-control" value="Released" onClick={authorizedReleased} />
                                </div>

                            </div>
                        </div>

                      
                    </div>

                </div>
            </div>
        )
    }


    if (thisComponentState.isAccepting) {

        return (

            <div>

                <div style={{ float: "left" }}>

                    <input type="button" className="btn btn-success" style={{ width: "120px" }} onClick={() => window.location.reload()} value="Create New" />
                   
                    <input type="button" className="btn btn-success" style={{ width: "120px" }} onClick={accept} value="Accept" />
                       
                </div>

                <div style={getModalStyle()}>
                    <div style={getModalContentStyle()}>
                        <div>
                            <input type="button" className="btn btn-success" style={{ width: "120px" }} onClick={() => updateState({ isAccepting: false })} value="Back" />
                         
                            <input type="button" className="btn btn-success" style={{ width: "120px", marginLeft: "15px" }} onClick={update} value="Update" />
                                                      
                        </div>

                        <br />

                        <table className="table table-bordered">
                          
                            <thead className="thead-dark">
                                <th style={{ position: 'sticky', top: 0 }}>Code</th>
                                <th style={{ position: 'sticky', top: 0 }}>Description</th>
                                <th style={{ position: 'sticky', top: 0 }}>Transferred</th>
                                <th style={{ position: 'sticky', top: 0 }}>Accepted</th>
                            </thead>

                            <tbody>

                                {
                                    thisComponentState.acceptedQtys.map(data =>

                                        <tr>
                                            <td>{data.code}</td>
                                            <td>{data.description}</td>
                                            <td>{data.quantity}</td>
                                            <td>         
                                                <PureInputByGuid type="number"  style={{ width: "150px" }} value={data.receivedQty} guidId={data.guidId}  updateValue={updateQty} />
                                            </td>
                                        </tr>

                                    )
                                }

                            </tbody>

                        </table>

                    </div>
                </div>

            </div>

             )

    } else {

        return (

            <div style={{ float: "left" }}>

                <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => window.location.reload()} />
                <FontAwesomeIcon icon={faHandHolding} title="Receive Deliveries" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="pink" onClick={accept} />
                <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={forward} />
                <FontAwesomeIcon icon={faWindowClose} title="Cancel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={cancel} />
                <FontAwesomeIcon icon={faArrowRight} title="Authorize release" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({...thisComponentState,isAuthorizingReleased:true})} />
                <FontAwesomeIcon icon={faPrint} title="To pdf" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={preview} />

            </div>

        )
    }
}
