import React, {  useEffect, useState } from 'react';

import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import BadOrderListNavigator from './BadOrderListNavigator';
import CreateBadOrderNavigator from './CreateBadOrderNavigator';
import DetailsBadOrderNavigator from './DetailsBadOrderNavigator';


function BadOrderForm() {

    const transactionCode = "InventoryTransfer";
    const postUrl = "/BadOrders/Create";
    const detailsUrl = "/BadOrders/Details/?id=";
    const editDetailUrl = "/BadOrders/EditItem";
    const listUrl = "/BadOrders/Index";
    const validatorUrl = "/BadOrders/Validate";


    const [data, updateMetaData] = useState({ metaData: {} });

    const [isLoaded, updateStatus] = useState(false);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, [])

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<BadOrderListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsBadOrderNavigator/>} />);

    var context = {
        categories: ['107', '108', '109', '110','129','132'],
        type: "S",
        formDiscriminator: "BadOrder"
    };

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl } context={context} transactionCode={transactionCode} metaData={data.metaData} postUrl={postUrl} listUrl={listUrl} detailsUrl={detailsUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList } >

                <TransactionForm createNavigator={<CreateBadOrderNavigator/>} detailsNavigator={<DetailsBadOrderNavigator/>} transactionList={transactionList }/>

            </TransactionContextProvider>

        )
    }

}

export default BadOrderForm;