import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionContext } from '../TransactionContext';


 export default function DetailsArCreditMemoNavigator(props) {

    const { guidId } = useContext(TransactionContext);

            return (

                    <div style={{ float: "left" }}>
           
                            <input type="button" className="btn btn-success" style={{width:"120px"}} onClick={()=>window.location.reload()} value="Create New"/>

                            <Link to={{ pathname: "/Invoice", previousTransactionGuidId: guidId }}>
                                <button style={{ marginLeft: "15px", marginRight: "15px", width: "120px" }} type="button" className="btn btn-success">
                                    Invoice
                                </button>
                            </Link>

                    </div>
        
            )
    
}


