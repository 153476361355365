import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';


export default function RelationshipMap(props) {

    const [thisComponentState, updateState] = useState({

        boxes: []

    });

    const canvasRef = useRef(null);

    useEffect(() => {
        fetch("PurchaseRequisitions/GetRelationshipMap?guidId="+props.guidId)
            .then(respsone => respsone.status === "401" ? window.login = "/login" : respsone.json())
            .then(response => {

                if (canvasRef.current) {

                    let boxes = [...thisComponentState.boxes];

                    const canvasCoordinate = canvasRef.current.getBoundingClientRect();

                    //Determine the presence of stock Requisitions.

                    let xStart = 5;
                    //variable for obtaining the line start 
                    let mainBoxStart = {
                        x: xStart + 100,
                        y: 0
                    };

                    if (response.stockRequisitions.length > 0) {

                        let multiplePrecedentLines = [];
                        let parentId = "";

                        for (let i = 0; i < response.stockRequisitions.length; i++) {

                            let stockRequisition = { ...response.stockRequisitions[i] };

                            //Provide needed coordinates

                            //If only one.
                            if (response.stockRequisitions.length === 1) {

                                stockRequisition.box.x = xStart;

                                stockRequisition.box.y = (canvasCoordinate.bottom - canvasCoordinate.top) / 2;

                                stockRequisition.box.lineStart = {
                                    x: xStart + 100,
                                    y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2
                                };

                                stockRequisition.box.id = uuidv4();

                                stockRequisition.box.shouldNotifyChild = true;
       
                                response.stockRequisitions[i] = stockRequisition;

                                boxes.push(stockRequisition.box);

                                mainBoxStart.y = stockRequisition.box.y + 50;

                                parentId = stockRequisition.box.id;

                            }

                            //If more than one and even
                            if (response.stockRequisitions.length > 1 && (i % 2) === 0) {

                                stockRequisition.box.x = xStart;

                                stockRequisition.box.y = (canvasCoordinate.bottom - canvasCoordinate.top) / 2 - 105 //add 50 since the height of the box will always be 100;

                                stockRequisition.box.lineStart = {
                                    x: xStart + 100,
                                    y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2 - 50
                                };

                                stockRequisition.box.id = uuidv4();

                                stockRequisition.box.shouldNotifyChild = true;

                                response.stockRequisitions[i] = stockRequisition;

                                boxes.push(stockRequisition.box);

                                mainBoxStart.y = stockRequisition.box.y + 50;

                                multiplePrecedentLines.push({
                                    id: stockRequisition.box.id,
                                    lineStart: {
                                        x: stockRequisition.box.x + 100,
                                        y: stockRequisition.box.y + 50
                                    },
                                    lineEnd: {
                                        x: +5 + 100 + 50,
                                        y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2 + 50
                                    }
                                });

                            }

                            //If more than one and odd
                            if (response.stockRequisitions.length > 1 && (i % 2) !== 0) {
                                stockRequisition.box.x = xStart;

                                stockRequisition.box.y = (canvasCoordinate.bottom - canvasCoordinate.top) / 2 + 105 //add 50 since the height of the box will always be 100;

                                stockRequisition.box.lineStart = {
                                    x: xStart + 100,
                                    y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2 + 50
                                };

                                stockRequisition.box.id = uuidv4();

                                stockRequisition.box.shouldNotifyChild = true;

                                response.stockRequisitions[i] = stockRequisition;

                                boxes.push(stockRequisition.box);

                                mainBoxStart.y = stockRequisition.box.y + 50;

                                multiplePrecedentLines.push({
                                    id: stockRequisition.box.id,
                                    lineStart: {
                                        x: stockRequisition.box.x + 100,
                                        y: stockRequisition.box.y + 50
                                    },
                                    lineEnd: {
                                        x: +5 + 100 + 50,
                                        y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2 + 50
                                    }
                                });
                            }
                        }

                        if (multiplePrecedentLines.length > 0) {

                            response.box.multiplePrecedentLines = multiplePrecedentLines;

                        } else {

                            response.box.parentId = parentId;
                            response.box.multiplePrecedentLines = multiplePrecedentLines;
                        };


                        response.box.shouldNotifyChild = false;

                        //Since there is presence of stock requisitions. Give line end to the purchase requisition.

                        response.box.lineStart = mainBoxStart;

                        response.box.lineEnd = {
                            x: xStart + 100 + 50,
                            y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2 + 50
                        };

                        xStart = 5 + 100 + 50;

                    } else {

                        response.box.lineEnd = {
                            x: xStart,
                            y: (canvasCoordinate.bottom - canvasCoordinate.top) / 2 + 50
                        };
                        response.box.lineStart = response.box.lineEnd;
                    };

                    let mainBox = { ...response.box };

                    mainBox.x = xStart;
                    mainBox.y = (canvasCoordinate.bottom - canvasCoordinate.top) / 2;
                    mainBox.id = uuidv4();

                    response.box = mainBox;

                    boxes.push(response.box);

                    //Create a variable for y purchase order coordinate starting at 5.

                    xStart = xStart + 100 + 50;

                    let yPurchaseOrderCoordinate = 5;

                    for (let i = 0; i < response.purchaseOrders.length; i++) {

                        yPurchaseOrderCoordinate = yPurchaseOrderCoordinate + 115;

                        response.purchaseOrders[i].box = {

                            x: xStart,
                            y: yPurchaseOrderCoordinate,
                            lineStart: {
                                x: response.box.lineEnd.x + 100,
                                y: response.box.lineEnd.y
                            },
                            lineEnd: {
                                x: xStart,
                                y: yPurchaseOrderCoordinate + 50
                            },
                            id: uuidv4(),
                            parentId: response.box.id,
                            documentLabel: response.purchaseOrders[i].box.documentLabel,
                            documentDate: response.purchaseOrders[i].box.documentDate,
                            stat: response.purchaseOrders[i].box.stat,
                            shouldNotifyChild: false,
                            stat : response.box.stat
                        };

                        boxes.push(response.purchaseOrders[i].box);

                        let yGoodsReceiptCoordinate = 5;
                        let xGoodsReceiptStart = xStart + 100 + 50;

                        for (let j = 0; j < response.purchaseOrders[i].goodsReceipts.length; j++) {

                            yGoodsReceiptCoordinate = yGoodsReceiptCoordinate + 115;

                            response.purchaseOrders[i].goodsReceipts[j].box = {

                                x: xGoodsReceiptStart,
                                y: yGoodsReceiptCoordinate,
                                lineStart: {
                                    x: response.purchaseOrders[i].box.lineEnd.x + 100,
                                    y: response.purchaseOrders[i].box.lineEnd.y
                                },
                                lineEnd: {
                                    x: xGoodsReceiptStart,
                                    y: yGoodsReceiptCoordinate + 50
                                },
                                id: uuidv4(),
                                documentLabel: response.purchaseOrders[i].goodsReceipts[j].box.documentLabel,
                                documentDate: response.purchaseOrders[i].goodsReceipts[j].box.documentDate,
                                stat: response.purchaseOrders[i].goodsReceipts[j].box.stat,

                                parentId: response.purchaseOrders[i].box.id,
                                shouldNotifyChild: false
                            };

                            boxes.push(response.purchaseOrders[i].goodsReceipts[j].box);
                        }
                    }

                    console.log(boxes);

                    draw(boxes);

                    updateState({ ...thisComponentState, boxes: boxes });
                }
            });

    }, []);



    const handleMouseClick = (e) => {

        if (canvasRef.current) {

            const canvasCoordinate = canvasRef.current.getBoundingClientRect();

            for (let i = 0; i < thisComponentState.boxes.length; i++) {

                let box = thisComponentState.boxes[i];

                let x = e.clientX - canvasCoordinate.left;
                let y = e.clientY - canvasCoordinate.top;

                if (x > box.x && y > box.y && x < (box.x + 100) && y < (box.y + 100)) {

                    box.isClick = true;

                }
            }
        }
    }

    const handleMouseUp = (e) => {

        if (canvasRef.current) {

            let tempDatas = [...thisComponentState.boxes];

            for (let i = 0; i < tempDatas.length; i++) {

                let box = tempDatas[i];

                if (box.isClick) {
                    const canvasCoordinate = canvasRef.current.getBoundingClientRect();

                    let x = e.clientX - canvasCoordinate.left;
                    let y = e.clientY - canvasCoordinate.top;

                    box.x = x;
                    box.y = y;

                    if (typeof box.lineEnd !== 'undefined') {
                        box.lineEnd.x = x;
                        box.lineEnd.y = y + 50;
                    };

                    //Inform change of line start to other boxes.

                    for (let j = 0; j < tempDatas.length; j++) {
                        let childBox = tempDatas[j];

                        if (box.id === childBox.parentId) {

                            childBox.lineStart.x = x + 100;
                            childBox.lineStart.y = y + 50;
                        };
                    };

                    if (typeof box.multiplePrecedentLines !== 'undefined') {

                        for (let k = 0; k < box.multiplePrecedentLines.length; k++) {

                            let item = box.multiplePrecedentLines[k];

                            item.lineEnd.x = x;
                            item.lineEnd.y = y + 50;
                        }
                    };

                    if (box.shouldNotifyChild) {

                        for (let j = 0; j < tempDatas.length; j++) {

                            let childBox = tempDatas[j];

                            if (typeof childBox.multiplePrecedentLines !== 'undefined' && childBox.multiplePrecedentLines.length > 0) {

                                for (let l = 0; l < childBox.multiplePrecedentLines.length; l++) {

                                    if (childBox.multiplePrecedentLines[l].id === box.id) {

                                        childBox.multiplePrecedentLines[l].lineStart.x = x + 100;
                                        childBox.multiplePrecedentLines[l].lineStart.y = y + 50

                                    }
                                }

                            }
                        };
                    }



                    box.isClick = false;

                    draw(tempDatas);
                }
            }
        }
    }

    const draw = (boxes) => {

        if (canvasRef.current) {

            let ctx = canvasRef.current.getContext("2d");

            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

            for (let i = 0; i < boxes.length; i++) {

                ctx.font = "15px Arial";

                let box = boxes[i];

                ctx.beginPath();
                ctx.rect(box.x, box.y, 100, 100);
                ctx.fillText(box.documentLabel, box.x + 5, box.y + 15);
                ctx.fillText(box.documentDate, box.x + 5, box.y + 40);
                ctx.fillText(box.stat, box.x + 5, box.y + 70);

                if (typeof box.multiplePrecedentLines !== 'undefined') {

                    if (box.multiplePrecedentLines.length > 0) {

                        box.multiplePrecedentLines.forEach(val => {

                            ctx.moveTo(val.lineStart.x, val.lineStart.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                            ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y + 7);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);

                        });

                    } else {

                        if (typeof box.lineEnd !== 'undefined') {

                   
                            ctx.moveTo(box.lineStart.x, box.lineStart.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                            ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x-7, box.lineEnd.y+7);
                            ctx.lineTo(box.lineEnd.x-7, box.lineEnd.y-7);

                                     
                        }
                    }
                } else {

                    if (typeof box.lineEnd !== 'undefined') {

                        ctx.moveTo(box.lineStart.x, box.lineStart.y);
                        ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y);
                        ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                        ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                        ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y + 7);
                        ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                    }
                }

                ctx.stroke();
            }
        }
    }

    return (
        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                    <div className="col">
                        <input type="button" value="Back" className="form-control" onClick={()=>props.back()}/>
                    </div>
                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                        <canvas
                            onMouseDown={handleMouseClick}
                            onMouseUp={handleMouseUp}
                            style={{ backgroundColor: "lightblue" }}
                            ref={canvasRef}
                            height={window.innerHeight - 25}
                            width={window.innerWidth - 25}
                        >
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    )
}