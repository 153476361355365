import React, {  useContext, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { TransactionContext } from '../TransactionContext';

import getModalStyle from '../../Functions/getModalStyle';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFileExcel, faHand, faInfoCircle, faLink, faPlus, faPlusCircle, faRedo, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import OnHandQty from '../../OnHandQty';
import RelationshipMap from './RelationshipMap';
import { toCsvString } from '../../Functions/toCsvString';
import exportToCSV from '../../Functions/exportToCSV';

export default function DetailsStockRequisitionNavigator(props) {

    const { guidId, data,updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        showOnHand: false,
        showRelationshipMap:false,
        data: {}

    });

    const location = useLocation();

    const deleteStockRequest = () => {

        var isConfirm = window.confirm("Are you sure you want to delete this stock requisition?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState,isPerformingOperation: true })

        var postBody = {

            value: guidId
        };

        fetch("/StockRequisitions/DeleteStockRequest", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {
        
                if (response.success) {

                    let tempData = { ...data.metaData };

                    tempData.parentProperties.docStatus = "Deleted";
                    tempData.reloadDetails = tempData.reloadDetails + 1;

                    updateMetaData({ metaData: tempData });
                }

                 alert(response.message);

                 updateState({ ...thisComponentState, isPerformingOperation: false })
            });
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.showOnHand) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <OnHandQty
                        back={() => updateState({ ...thisComponentState, showOnHand: false })}
                        wareHouses={thisComponentState.data.wareHouses}
                        onHandInventories={thisComponentState.data.onHandInventories}
                    />
                </div>
            </div>
        )
    }


    if (thisComponentState.showRelationshipMap) {

        return (<RelationshipMap guidId={guidId} back={() => updateState({ ...thisComponentState, showRelationshipMap: false })} />)
    }

    const move = () => {

        localStorage.setItem("stockRequisitionGuidId", JSON.stringify(guidId));
    }



    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailsFromDb !== 'undefined' && typeof data.metaData.detailsFromDb.details !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailsFromDb.details);

            exportToCSV("data", csvString);
        }
    }


    return (

        <div style={{ float: "left" }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faHand} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({...thisComponentState,showOnHand:true})} />           
            <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={deleteStockRequest} />
            <FontAwesomeIcon icon={faLink} title="Relationship Map" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showRelationshipMap: true, })} />
            <Link to={{ pathname: "/PurchaseRequisition", state : {previousTransactionGuidId: guidId }}}>
                <FontAwesomeIcon icon={faArrowRight} onClick={move } title="Move to purchase requisition" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" />
                PR
            </Link>
            <Link to={{ pathname: "/StockTransfer", previousTransactionGuidId: guidId }}>
                <FontAwesomeIcon icon={faArrowRight} onClick={move } title="Move to stock transfer" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" />
                ST
            </Link>
            <Link to={{ pathname: "/GoodsIssue", previousTransactionGuidId: guidId }}>
                <FontAwesomeIcon icon={faArrowRight} onClick={move} title="Move to goods issue" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" />
                GI
            </Link>
            <FontAwesomeIcon icon={faFileExcel} title="Download to excel" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

        </div>

    )

}


