import React, { useState } from 'react';
import { useEffect } from 'react';
import uuid from 'react-uuid';

export default function UserSelection (props) {

    const [thisComponentState, updateState] = useState({

        users: props.users,
        connectedUsers:props.connectedUsers,
        filteredUsers:props.users,
        searchTerm:""

    });


    useEffect(() => {




    }, [props.users])


    const setSearchTerm = (value) => {

        let tempData = { ...thisComponentState };

        tempData.searchTerm = value;

        tempData.filteredUsers = thisComponentState.users.filter(user => user.userName.toLowerCase().includes(value.toLowerCase()));

        updateState(tempData);
    }


    const handleUserClick = (user) => {

        props.addUsersToChat(user);

    };



    return (
        <div>
            <h3>Select a Person to Chat With</h3>
            <input
                type="text"
                placeholder="Search users..."
                value={thisComponentState.searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul>
                {thisComponentState.filteredUsers.map((user) => (
                    <li key={uuid()} onClick={() => handleUserClick(user)}>
                        {user.userName}
                    </li>
                ))}
            </ul>
        </div>
    );
};

