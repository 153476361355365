import React from 'react';

const initialState = 0;

const action = (state = initialState, action) => {

    switch (action.type) {
        case 'INCREMENT':
            return state + 1;
        case 'DECREMENT':
            return state - 1;
        case 'ZEROIT' :
            return 0;
        default:
            return state;
    }
};

export default action;