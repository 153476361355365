import React from 'react';


export const incNum = () => {
    return { type: "INCREMENT" }
}


export const decNum = () => {
    return { type: "DECREMENT" }
}

export const zeroIt = () => {
    return { type: "ZEROIT" }
}