
import React from 'react';
import { Fragment } from 'react';
import { useState } from 'react';

export default function DailyProductionPlanned(props) {

    const [thisComponentState, updateState] = useState({

        productionDate: "",
        details: []
      
    });

    const getProductionPlanned = () => {

        var productionDate = thisComponentState.productionDate;

        if (productionDate === "" || productionDate === undefined) {

            return alert("Please defined a date.");

        };

        fetch("/Productions/GetProductionPlannedReport?" + new URLSearchParams({

            productionDate: productionDate

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.length !== 0) {

                    updateState({ ...thisComponentState, details: responseData });

                    return;

                } else {

                    return alert("No data available.");

                }
            });
    }



    const send = () => {

        var productionDate = thisComponentState.productionDate;

        var postBody = {

            Value : productionDate
        }

            fetch("/Productions/SendByPhone", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(postBody),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => response.status == 401 ? window.location = "/Login" : response.json())

                .then(response => {

                    alert("This production planned has been send to appropriate recipient.");

                    return;
                });

    }

    

    return (

        <div>

            <div>

                <div>

                    <div>
                        <div style={{ float: "left" }}>
                            <input type="date" className="btn btn-primary" onChange={(e) => updateState({ ...thisComponentState, productionDate: e.currentTarget.value })} style={{ width: "180px" }} />
                        </div>
                        <div style={{ float: "left" }}>
                            <input value="Generate" onClick={getProductionPlanned} className="btn btn-primary" style={{ width: "100px", marginLeft: "15px" }} type="button" />
                        </div>
                        <div style={{ float: "left" }}>
                            <input value="Send" onClick={send} className="btn btn-primary" style={{ width: "100px", marginLeft: "15px" }} type="button" />
                        </div>
                    </div>

                    <br />
                    <br />

                    <table className="table table-bordered">

                        <thead className="thead-dark">
                            <th>
                                Item Code
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Planned
                            </th>
                          
                            <th>
                                Yield Per mix
                            </th>
                            <th>
                                Standard Mix
                            </th>
                            <th>
                                Proposed Mix
                            </th>

                            <th>
                                Remarks
                            </th>

                        </thead>

                        <tbody>


                            {
                                thisComponentState.details.map(category =>

                                    <Fragment>

                                        <tr>
                                            <td>
                                                {category.category}
                                            </td>

                                        </tr>

                                        {category.details.map((detail, trIndex) =>

                                            <tr>

                                                <td>  <div style={{ marginLeft: "25px" }}> {detail.itemCode}  </div>         </td>
                                                <td> { detail.name}       </td>
                                                <td> {detail.plannedQty}  </td>
                                               
                                                <td> { detail.yieldPerMix}  </td>
                                                <td> { detail.standardMix.toFixed(2)} </td>
                                                <td> {detail.proposedMix.toFixed(2)} </td>
                                                <td> {detail.remarks} </td>

                                            </tr>


                                        )}

                                    </Fragment>

                                )

                            }


                        </tbody>

                        <tfoot>

                           

                            <tr>
                            <td>
                                   
                            </td>
                            <td>
                                   
                            </td>
                           

                            <td>
                            </td>

                            </tr>


                        </tfoot>

                    </table>

                </div>
            </div>
        </div>
    )

}

