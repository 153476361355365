import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";





export default function OpenPoFilter (props)
{
    const [thisComponentState, updateState] = useState({

        code: "",
        branchId: "",
        departmentId: "",
        itemCode: "",
        date:"",
        requiredDate: "",
        sapId: "",
        documentId: "",
        from: "",
        to:""
       
       
    })

    const updateCode = (code) => {

        updateState({ ...thisComponentState, code: code });
    }

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });
    }


    const updateDepartmentId = (code) => {

        updateState({ ...thisComponentState,departmentId: code });
    }

    const updateItemCode = (code) => {

        updateState({ ...thisComponentState, itemCode: code });
    }



    const back =() => {

        let criterias = {};

        if (thisComponentState.code !== "") {
            criterias.code = thisComponentState.code;
        };

        if (thisComponentState.branchId !== "") {
            criterias.branchId = thisComponentState.branchId;
        };


        if (thisComponentState.departmentId !== "") {
            criterias.departmentId = thisComponentState.departmentId;
        };

        if (thisComponentState.itemCode !== "") {
            criterias.itemCode = thisComponentState.itemCode;
        };


        if (thisComponentState.date !== "") {
            criterias.date = thisComponentState.date;
        };

        if (thisComponentState.requiredDate !== "") {
            criterias.requiredDate = thisComponentState.requiredDate;
        };

        if (thisComponentState.sapId !== "") {
            criterias.sapId =parseInt (thisComponentState.sapId);
        };

        if (thisComponentState.documentId !== "") {
            criterias.documentId =parseInt(thisComponentState.documentId);
        };

        if (thisComponentState.from !== "") {
            criterias.from   = thisComponentState.from;
        };

        if (thisComponentState.to !== "") {
            criterias.to = thisComponentState.to;
        };

        props.generate(criterias);
    }


    return (
        <div>
            <br/>
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("500px", "500px")}>

                    <div className="row">
                        <div className="col">
                            Filter By Sap Id
                        </div>

                        <div className="col">
                            <input type="number" value={thisComponentState.sapId} className="form-control" onChange={(e) => updateState({ ...thisComponentState,sapId: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Document Id
                        </div>

                        <div className="col">
                            <input type="number" value={thisComponentState.documentId} className="form-control" onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} />
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            Filter By Vendor
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Vendors/GetVendors" value={thisComponentState.code} clear={() => updateState({ ...thisComponentState,code: "" })} updateValue={updateCode} placeholder="Vendor Search" />

                        </div>
                    </div>

               

                    <div className="row">
                        <div className="col">
                            Filter By Branch
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} clear={() => updateState({ ...thisComponentState, branchId: "" })} updateValue={updateBranchId} placeholder="Branch Search" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Filter By Department
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Departments/GetDepartments" value={thisComponentState.departmentId} clear={() => updateState({ ...thisComponentState, departmentId: "" })} updateValue={updateDepartmentId} placeholder="Department Search" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Item 
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Inventories/GetInventories" value={thisComponentState.itemCode} clear={() => updateState({ ...thisComponentState, itemCode: "" })} updateValue={updateItemCode} placeholder="Item Search" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Filter By Po Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.date} className="form-control" onChange={(e) => updateState({ ...thisComponentState,date: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Required Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.requiredDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                          From
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.from} className="form-control" onChange={(e) => updateState({ ...thisComponentState, from: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                           To
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.to} className="form-control" onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">

                        </div>

                        <div className="col">
                            <input value="Back" type="button" className="form-control" onClick={back} />
                        </div>
                    </div>

                </div>
            </div>
            </div> 
   )
}