import React, { useEffect } from 'react'
import { useState } from 'react'

export default function NotGoodSummary(){

    const [thisComponentState, updateState] = useState({
        data: {
            factors: [],
            items: [],
            details: [],
            cachedFactors: []
        },
        fromDate: null,
        toDate: null,
        selectedFactor: "All",
        selectedItem :"ALL"
    })


    function getData() {

        if (thisComponentState.fromDate == null || thisComponentState.fromDate == '') {

            return alert("Invalid from date");
        }


        updateState({ ...thisComponentState, isLoading: true });

        fetch("/Productions/GetNotGoodSummary?" + new URLSearchParams({

            fromDate:thisComponentState.fromDate,
            toDate: thisComponentState.toDate,

        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState.data };

                //Add ALL in factors and Items

                tempData.items = response.items;
                tempData.factors = response.factors;
                tempData.details = response.details;
                tempData.cachedFactors = response.factors;

                updateState({ ...thisComponentState, data: tempData,isLoading: false });

            });
    };


    const getQuantity= (factor,name) => {

        let factorData = thisComponentState.data.details.find(item => item.factor === factor);

        if (factorData !== null) {

            let detail = factorData.details.find(item => item.key == name);

            if (detail != null) {
                return detail.quantity.toFixed(2);
            }
        }

        return 0;
    }

    const filterByFactor = (pfactor) => {

        let tempData = { ...thisComponentState.data };

        if (pfactor !== "ALL") {

            tempData.factors = thisComponentState.data.cachedFactors.filter(factor => factor === pfactor);

        } else {
            tempData.factors = thisComponentState.data.cachedFactors;

        }

       

        updateState({ ...thisComponentState, data: tempData});

    }



    return (

        <div>

        
            <div className="row">
                <div className="col">
                    <input type="date" className="form-control"  value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <input type="date" className="form-control"  value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                </div>
            </div>


            <div className="row">
                <div className="col">
                    <input value="Generate" type="button"  className='form-control' onClick={getData} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <select class="form-control" onChange={(e)=> filterByFactor(e.currentTarget.value)}>

                        <option style={{ whiteSpace: 'nowrap' }} value="ALL" selected>ALL</option>

                        {thisComponentState.data.cachedFactors.map(factor =>

                            <option style={{ whiteSpace: 'nowrap' }}  value={factor}>{factor}</option>

                        )}

                    </select>
                </div>
           </div>

                
            

              


                
                

              
              
   

            <br />
            <br/>

            <table className='table table-bordered'>

                <thead>

                    <tr>
                        <th>Item </th>
                        {thisComponentState.data.factors.map(factor => <th style={{whiteSpace:'nowrap'}}>{factor}</th>)}
                    </tr>

                </thead>

                <tbody>
                    {
                        thisComponentState.data.items.map(item =>
                            <tr>
                                <td style={{ whiteSpace: 'nowrap' }}>{item}</td>
                                {
                                    thisComponentState.data.factors.map(factor =>

                                            <td> {getQuantity(factor,item)} </td>
                                            
                                        )
                                }

                            </tr>
                        )

                    }
                </tbody>


            </table>
            </div>


        
   )

}

