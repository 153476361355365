import { Link } from "react-router-dom"

export default function Administration() {

    return (

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Link to={{ pathname: '/TripBooking'}} style={{ textDecoration: "none", fontSize: "100px", fontFamily: "Arial", marginBottom: "20px" }}>
                Trip Ticketing
            </Link>
            <Link to={{ pathname: '/Conference' }} style={{ textDecoration: "none", fontSize: "100px", fontFamily: "Arial" }}>
                Conference Booking
            </Link>
        </div>

    )

}