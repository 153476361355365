import { Link } from "react-router-dom";
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';


export default function SelectTypeRequest(props) {


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle("400px")}>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to="/Home" style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                 Back
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/0', state: { type: "Main" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Main Warehouse Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/1', state: { type: "Cafe" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Cafe Daily Market Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/3', state: { type: "MixMan" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Mixman Request for Daily Raw Mats
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/4', state: { type: "Grocery" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Outlet Grocery Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/5', state: { type: "Auto&Electro" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Automotive Parts Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/6', state: { type: "Auto&Electro" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Electro Parts Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/0', state: { type: "Office" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Office Supplies Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/0', state: { type: "Janitorial" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Janitorial Supplies Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>




                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/2', state: { type: "JamesWrightCommissary" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                               Request for Commisary Production - James Wright
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/2', state: { type: "ValleyBreadCommissary" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                             Request for Commisary Production - Valley Bread
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>




                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to="/JobOrder" style={{textDecoration:"none"}} >
                            <div className="text-center">
                               Job Order Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>


                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to="/DieselRequest" style={{ textDecoration: "none" }} >
                            <div className="text-center">
                                Diesel Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/7', state: { type: "" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Construction Material Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <Link to={{ pathname: '/StockRequisition/0', state: { type: "" } }} style={{ textDecoration: "none" }}>
                            <div className="text-center">
                                Other Stock Request
                            </div>
                        </Link>
                    </div>
                    <div className="col"></div>
                </div>

            </div>
        </div>
    )
}

