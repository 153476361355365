import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import uuid from "react-uuid";
import StandardAutoComplete from "../../StandardAutocomplete";


export default function EmployeePosition(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,     
        employeeId: props.employeeId,
        positionId:""

    });



    const save = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/SaveEmployeePositionId", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                value: {
                    item1: thisComponentState.employeeId,
                    item2: parseInt(thisComponentState.positionId)
                }
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {
                if (!response.ok) {
                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })
            .then(response => {

                updateState({ ...thisComponentState, position: response.position, isPerformingOperation: false });

                return alert(response.message);
            });
    }

    const updateEmployeeId = (id) => {

           updateState({ ...thisComponentState, employeeId: id });
    }

    const updatePositionId = (id) => {

        updateState({ ...thisComponentState, positionId: id });
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.showList) {

        return (<ListOfCycles back={() => updateState({ ...thisComponentState, showList: false })} />);
    }

    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={() => props.back()} className="form-control" type="button" />
                </div>
                <div className="col">
                    <input value="List" onClick={() => updateState({...thisComponentState,showList:true})} className="form-control" type="button" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Employee
                </div>
                <div className="col">
                    <StandardAutoComplete url="Employees/GetEmployees" value={thisComponentState.employeeId} updateValue={updateEmployeeId} />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Position
                </div>
                <div className="col">
                    <StandardAutoComplete url="PerformanceEvaluations/GetPositions" value={thisComponentState.positionId} updateValue={updatePositionId} />
                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col">
                   
                </div>
                <div className="col">
                    <input value="Save" onClick={save} className="form-control" type="button" />
                </div>
            </div>
        </div>
    )
}