
import React, { useEffect, useRef, useState } from "react";
import getModalContentStyle from "./components/Functions/getModalContentStyle";
import getModalStyle from "./components/Functions/getModalStyle";
import ShowEvent from "./components/Transactions/ConferenceBooking/ShowEvent";
import ShowTrip from "./components/Transactions/TripBooking/ShowTrip";

export default function  ScheduleViewer(props) {

    const canvasRef = useRef(null);

    const [toReload, updateToReload] = useState(0);
    const [length, updateLength] = useState(0);
    const [showEvent,updateEvent] = useState(false);
    const [time, updateTime] = useState("");
    const [description, updateDescription] = useState("");
    const [bookBy, updateBookBy] = useState("");
    const [id, updateId] = useState(0);

    const [timeValue, updateTimeValue] = useState({
    });

    useEffect(() => {

        fetch(props.url).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(data => {

                updateLength(data.length);

                const canvas = canvasRef.current;
                const ctx = canvas.getContext("2d");

                const defaultFont = ctx.font;
                const defaultFillStyle = ctx.fillStyle;

                const cellWidth = 200;
                const cellHeight = 50;

                // Clear canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Draw time intervals vertically starting from 06:00
                for (let i = 7; i <= 24; i++) {

                    let j = i;

                    ctx.fillText(Number.isInteger(j) ? `${j}:00` : `${Math.trunc(j)}:30`, 0, (j - 6) * cellHeight);

                    i = i - 0.5;
                }

                ctx.font = defaultFont;

                // Draw dates horizontally and shade cells accordingly
                data.forEach((item, index) => {
                    const xCoordinate = index * cellWidth + 50;

                    let dateY = cellHeight - 10; // Adjust for padding

                    ctx.font = "15px Arial";
                    // Write date
                    ctx.strokeText(item.date, xCoordinate, dateY);
                    ctx.font = defaultFont;

                    // Shade cells for scheduled and unscheduled intervals
                    item.schedules.forEach((schedule) => {
                        const startHour =parseFloat(schedule.timeStart);
                        const endHour =parseFloat(schedule.timeEnd);
                        const startX = xCoordinate;

                        console.log(startHour);

                        // Scheduled interval - shade in red
                        ctx.fillStyle = schedule.color; // Red shade
                        let jStartX = 0;
                        let jCellWidth = 0;
                        if (index === 0) {
                            jStartX = startX;
                            jCellWidth = cellWidth - 5;
                        } else {
                            jStartX = startX - 5;
                            jCellWidth = cellWidth;
                        }

                        let jStartY = (startHour - 6) * cellHeight - 5;
                        let jEndY = (endHour - startHour) * cellHeight;

                        const shadowColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent black shadow
                        ctx.shadowColor = shadowColor;
                        ctx.shadowBlur = 10;
                        ctx.fillRect(jStartX,jStartY, jCellWidth,jEndY );

                        ctx.font = "15px Arial";


                        let start = schedule.startInInt <= 11 ? schedule.start + " AM" : schedule.start + " PM";
                        let end = schedule.endInInt <= 11 ? schedule.end + " AM" : schedule.end + " PM";

                        ctx.strokeText("   " + start + " to " + end, jStartX, jStartY + jEndY * .50);
                        

                        //wrapText(ctx, schedule.description, startX, (startHour - 6) * cellHeight + 5, cellWidth, cellHeight);
                    });

                    // Draw vertical line
                    if (index > 0) {
                        const startX = xCoordinate - 5;
                        const startY = 0;
                        const endY = canvas.height;

                        // Draw vertical line
                        ctx.beginPath();
                        ctx.moveTo(startX, startY);
                        ctx.lineTo(startX, endY);
                        ctx.strokeStyle = "black";
                        ctx.stroke();
                    }


                    ctx.fillStyle = defaultFillStyle;

                });

              

                // Add event listener to the canvas
                canvas.addEventListener("click", (event) => {
                    const rect = canvas.getBoundingClientRect();
                    const mouseX = event.clientX - rect.left;
                    const mouseY = event.clientY - rect.top;

                    // Loop through the scheduled intervals to check if the click is inside any of them
                    data.forEach((item, index) => {
                        const xCoordinate = index * cellWidth + 50;
                        item.schedules.forEach((schedule) => {
                            const startHour = parseFloat(schedule.timeStart);
                            const endHour = parseFloat(schedule.timeEnd);
                            const startX = xCoordinate;


                            let jStartX = 0;
                            let jCellWidth = 0;
                            if (index === 0) {
                                jStartX = startX;
                                jCellWidth = cellWidth - 5;
                            } else {
                                jStartX = startX - 5;
                                jCellWidth = cellWidth;
                            }

                            // Check if the click is inside the shaded portion of the schedule
                            if (mouseX >= jStartX && mouseX <= jStartX + jCellWidth && mouseY >= (startHour - 6) * cellHeight - 5 && mouseY <= (endHour - 6) * cellHeight)
                            {
                                updateId(schedule.id);
                                updateTime(schedule.start+" to "+schedule.end);
                                updateDescription(schedule.description);
                                updateBookBy(schedule.bookBy);

                                updateTimeValue({ start: schedule.timeStart, end:schedule.timeEnd });

                                updateEvent(true);
                            }
                        });
                    });
                });

            });

    }, [toReload]);


    const back = () => {

        let counter = toReload + 1;

        updateToReload(counter);

        updateEvent(false);
    }

    if (showEvent) {

        if (props.type === "conference") {

            return (
                <div style={getModalStyle()}>
                    <div style={getModalContentStyle("400px", "500px")}>

                        <ShowEvent id={id} bookBy={bookBy} timeValue={timeValue} time={time} description={description} back={back} />

                    </div>
                </div>)

        } else {

            return (
                <div style={getModalStyle()}>
                    <div style={getModalContentStyle("800px", "1000px")}>

                        <ShowTrip id={id} bookBy={bookBy} time={time}  description={description} back={back} />
                        
                    </div>
                </div>)

        }

    
    }


 
    return <canvas ref={canvasRef} width={length * 200} height={18 * 50} style={{ border: "2px solid blue", padding: "5px" }} />;
};


