
export function toCsvString(data) {

    if (typeof data === 'undefined') {

        return;
    }

    const csvRows = [];

    // Get the headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    // Iterate over each object
    data.forEach(obj => {
        const values = headers.map(header => {
            const value = obj[header];
            return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
        });
        csvRows.push(values.join(','));
    });

    // Join rows with newlines
    return csvRows.join('\n');
}