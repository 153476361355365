import React, { useEffect, useState } from 'react';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';


function MasterfileList(props) {

    const [thisComponentState, updateState] = useState({

        isLoading:true,
        details: []

    });

    useEffect(() => {

        getList();

    },[getList]);


    async function getList() {

        await fetch(props.url)

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(data => {


                var tempMetaData = [...thisComponentState.details];

                tempMetaData = data;

                updateState({...thisComponentState, isLoading: false,details: tempMetaData});
           
            });
    }




    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {


        if (typeof thisComponentState.details ==='undefined' || thisComponentState.details.length === 0) {


            return (<div>No data available </div>)

        } else {

            return (

                <div>

                    <br />

                    <div>

                        <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                            <thead className="thead-dark">

                                {
                                    Object.entries(thisComponentState.details[0]).map(y =>

                                        y[0] !== "guidId" ? <td>{capitalizeFirstLetter(y[0])}  </td> : <td> Actions  </td>
                                    )
                                }

                            </thead>

                            <tbody>

                                {
                                    thisComponentState.details.map(x =>

                                        <tr>

                                            {
                                                Object.entries(x).map(y =>

                                                    <td>{y[1]}</td>

                                                )
                                            }

                                        </tr>

                                    )
                                }

                            </tbody>

                        </table>
                    </div>


                </div>
            )


        }
    }
}

export default MasterfileList;