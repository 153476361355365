import React from 'react'
import { useState } from "react";



export default function ProductionBreadOutReconciliation()
{
    const [thisComponentState, updateState] = useState({

        fromDate: "",
        toDate: "",
        isLoading:false,
        data :[]


    });


    async function generate(criteria) {

        var fromDate = thisComponentState.fromDate;
        var toDate = thisComponentState.toDate;

        if (fromDate === "") {

            return alert("Please defined a from date...");
        };

        if (toDate === "") {

            return alert("Please defined a to date...");
        };


        await fetch("/Productions/ProductionBreadReconcliation?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                updateState({ ...thisComponentState, data: responseData, isLoading: false});

            });

    };

    if (thisComponentState.isLoading) {
        return (<div>Please wait...</div>)
    }


    return (

        <div>

            <div>

                <input type="date"    className="btn" value={thisComponentState.fromDate} onChange={(e)=>updateState({...thisComponentState,fromDate: e.currentTarget.value})} />
                <input type="date"    className="btn" value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState,toDate: e.currentTarget.value})} />

                <input type="button"  className="btn" value="Generate" onClick={generate} />

                <br />
                <br />

            </div>


            <table className="table table-bordered">

                <thead className="thead-dark">
                    <th>Name</th>
                    <th>BegInventory</th>
                    <th>Return</th>
                    <th>Good</th>
                    <th>Bargain</th>
                    <th>Reject</th>
                    <th>Bread Out</th>
                    <th>Variance</th>
                </thead>

                {
                    thisComponentState.data.map(data =>
                        <tr>

                            <td>{data.name}</td>
                            <td>{data.begInventory}</td>
                            <td>{data.return}</td>
                            <td>{data.good}</td>
                            <td>{data.bargain}</td>
                            <td>{data.reject}</td>
                            <td>{data.breadOut}</td>
                            <td>{data.variance}</td>

                        </tr>
                    )

                }
            </table>
        </div>

      

     
   )

}