import { data } from 'jquery';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Chart from "react-google-charts";

export default function ProductionComparer() {

    const [thisComponentState, updateState] = useState({

        isDataLoaded: false,
        productionDate:"",
        data:null

    })

    const getData = () => {

        fetch("/Dashboard/productioncomparer?" + new URLSearchParams({

            productionDate:thisComponentState.productionDate

        }))
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, data: response,isDataLoaded:true })

            });
    }
    

    if (!thisComponentState.isDataLoaded) {

        return (

            <div>

                <input type="date" value={thisComponentState.productionDate} onChange={(e) => updateState({...thisComponentState,productionDate:e.currentTarget.value }) } />

                <input type="button" value="Go" onClick={getData}  />

            </div>
                
       )

    }


    return (

        <div style={{ display: 'flex', maxWidth: 2000 }}>

            <Chart
                width={1000}
                height={1000}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={thisComponentState.data}
                options={{
                    title: 'Transactions Comparer',
                    chartArea: { width: '60%' },
                    hAxis: {
                        title: 'Products',
                        minValue: 0,
                    },
                    vAxis: {
                        title: 'Quantity',
                    },
                }}
                legendToggle
            />
       </div>

         
   )

}
