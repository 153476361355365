import { useContext } from "react";
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import { TransactionContext } from "../TransactionContext";



export default function UploadFile(props) {

    const { data, updateMetaData } = useContext(TransactionContext);


    const handleFileSelect = (e) => {

        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = function (e) {

            const contents = e.target.result;
            processCsv(contents);
        };

        reader.readAsText(file);
    }


    const processCsv = (csv) => {

        //toShowProperty

        let presentationValue = {

            toShow: true,
            categoryId: ""
        };

        const lines = csv.split('\n');

        let tempData = { ...data.metaData };

        let containers = [];

        for (let i = 0; i < lines.length; i++) {

            const value = lines[i].split(",");

            const entry = {
                guidId: uuid(),
                code: value[0],
                description: value[1],
                quantity: value[2],
                uomEntry: value[3],
                presentationValue: presentationValue
            };

            containers.push(entry);
        }



        tempData.detailProperties = containers.filter(val => val.name !== '');

        updateMetaData({ metaData: tempData });

    }


    return (
        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>

                <div className="row">
                    <div className="col">
                        <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input type="file" className="form-control" onChange={handleFileSelect} />
                    </div>
                </div>

            </div>
        </div>)

}