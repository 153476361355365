import React from 'react';

import { useEffect, useState } from "react";


export default function EndingInventory() {

    const [thisComponentState, updateState] = useState({

        isLoading: false,
        data: [],
        fromDate: "",
        toDate: "",
        isLoaded:false

    });

  

    async function generate(criteria) {

        var fromDate = thisComponentState.fromDate;
        var toDate = thisComponentState.toDate;

        updateState({ ...thisComponentState, isLoading: true,isLoaded:false });

        if (fromDate === "") {

            return alert("Please defined a from date...");
        };

        if (toDate === "") {

            return alert("Please defined a to date...");
        };

        await fetch("/Productions/Balancing?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                updateState({ ...thisComponentState, data: responseData, isLoading: false ,isLoaded:true });

            });

    };

    if (thisComponentState.isLoading) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }

    if (!thisComponentState.isLoaded) {

        return (
            

            <div>

                <div style={{ float: "left", display: "inline-block" }}>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                    <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={generate} value="Generate" />

                    <br />

                </div>

            </div>
            
            )

    }



    if (thisComponentState.isLoaded) {

        return (

            <div>

                <div style={{ float: "left", display: "inline-block" }}>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                    <input type="button" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} onClick={generate} value="Generate" />

                    <br />
                    <br />
                </div>


                <table className="table table-bordered">


                    <thead>

                        <th></th>

                        {
                            thisComponentState.data.map(item =>

                                <td style={{ overflow: 'hidden', whiteSpace:'nowrap' }}> {item.shortCode} </td>
                            )
                        }

                    </thead>


                    <tbody>

                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Beg From Returns </td>
                            {
                                thisComponentState.data.map(item =>
      
                                    <td> { item.begReturn } </td>
                                    
                                )
                            }
                       </tr>    
                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>  Beg From Left Overs</td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.begLeft} </td>

                                )
                            }
                        </tr>

                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Total Beginning Inventory</td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.begInventory} </td>

                                )
                            }
                        </tr>

                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Production </td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.production} </td>

                                )
                            }
                        </tr>
                        <tr>
                            <td> Sales </td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> ({item.sales}) </td>

                                )
                            }
                        </tr>
                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Expected Ending Inventory </td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.endingInventory} </td>

                                )
                            }
                        </tr>

                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Counted Ending Inventory</td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.countedEndingInventory} </td>

                                )
                            }
                        </tr>

                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Ending from Return</td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.endReturn} </td>

                                )
                            }
                        </tr>
                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Ending from Left Over</td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {item.endLeft} </td>

                                )
                            }
                        </tr>

                        <tr>
                            <td style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}> Variance </td>
                            {
                                thisComponentState.data.map(item =>

                                    <td> {parseFloat(item.endingInventory)-parseFloat(item.countedEndingInventory)} </td>

                                )
                            }
                        </tr>



                    </tbody>


                </table>


            </div>
        )
    }
 

    





}

