
import React, { useEffect,useRef,useState } from 'react';
import convertToDataType from '../Functions/convertToDataType';
import createProperties from '../Functions/createProperties';
import { TransactionContext } from './TransactionContext'; 



export default function TransactionContextProvider(props) {

    const [data, updateMetaData] = useState({

        metaData: {

            transactionCode:props.transactionCode,
            parentMetaData: props.metaData.parentMetaData,
            detailMetaData: props.metaData.detailMetaData,
            parentProperties: props.metaData.parentProperties,
            detailProperties: props.metaData.detailProperties,
            postUrl: props.postUrl,
            detailsUrl: props.detailsUrl,
            editDetailUrl: props.editDetailUrl,
            deleteDetailUrl:props.deleteDetailUrl,
            listUrl:props.listUrl,
            initialDetailsUrl: props.initialDetailsUrl,
            isFormSubmitted: false,
            preceedingTransactionGuids: [],
            modifiedDetailProperties:   [],
            modifiedParentProperties: [],
            uomValues: props.metaData.uomValues,
            inventories: props.metaData.inventories,
            agents: props.metaData.agents,
            customers: props.metaData.customers,
            vendors: props.metaData.vendors,
            branches: props.metaData.branches,
            wareHouses:props.metaData.wareHouses,
            settings: props.metaData.settings,
            employees: props.metaData.employees,
            departments: props.metaData.departments,
            categories : props.metaData.categories,
            trips: props.metaData.trips,
            requestTypes:props.metaData.requestTypes,
            salesType : props.metaData.salesType,
            agentId: props.metaData.agentId,
            transactionTypes: props.metaData.transactionTypes,
            machines : props.metaData.machines,
            detailsFromDb: {},
            listData: [], 
            functions: props.functions,
            transactionList: props.transactionList,
            context: props.context,
            validatorUrl: props.validatorUrl,
            categories: props.categories,
            refresh: false,
            reloadDetails: 0,
            chosenCategory:null,
            getInventories: function () {

                if (typeof this.inventories !== 'undefined') {

                    return this.inventories.filter(x => props.context.categories.includes(x.categoryId)).map(x => x = { code: x.itemCode, description: x.name });

                } else {

                    return [];
                }

            },
            getUomValues: function (param) {

                let inventory = [];

                if (typeof this.inventories !== 'undefined') {

                   inventory = this.inventories.find(x => x.itemCode === param);
                }
        
                let currentUomValues = [];

                if (typeof inventory !== 'undefined' && inventory !==null && typeof this.uomValues!=='undefined' ) {

                    currentUomValues = this.uomValues.filter(x => x.ugpEntry === inventory.ugpEntry);

                };


                if (typeof currentUomValues !== 'undefined') {

                    var flatennedUomValues = [];

                    currentUomValues.forEach(x => {

                        x.uomEntryDTOs.forEach(y => {

                            if (!flatennedUomValues.find(z => z.uomEntry === y.uomEntry)) {
                                flatennedUomValues.push(y);
                            };
                        });
                    });

                    return flatennedUomValues.map(x => x = { code: x.uomEntry, description: x.uomName });

                } else {

                    return [];
                }

            },
            getAgents: function () {

                if (typeof this.agents !== 'undefined') {

                    return this.agents.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }
                
            },
            getCustomers: function () {

                if (typeof this.customers !== 'undefined') {

                    if (typeof props.metaData.agentId ==='string') {

                        return this.customers.filter(x => x.agentId === props.metaData.agentId).map(x => x = { code: x.code, description: x.name });

                    } else {

                        return this.customers.map(x => x = { code: x.code, description: x.name });
                    }

                } else {

                    return [];
                }
    
            },

            getVendors: function () {

                if (typeof this.vendors !== 'undefined') {

                    return this.vendors.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }

             
            },

            getCategories: function () {

                if (typeof this.categories !== 'undefined') {

                
                    return this.categories.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }

            },

            getBranches: function () {

                if (typeof this.branches !== 'undefined') {

                    return this.branches.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }
  
            },

            getWareHouses: function () {

                if (typeof this.wareHouses !== 'undefined') {

                    return this.wareHouses.map(x => x = { code: x.code, description: x.name });


                } else {

                    return [];
                }

            },

            getSalesType: function() {

                if (typeof this.salesType !== 'undefined') {

                    return this.salesType.map(x => x = { code: x.code, description: x.name });


                } else {

                    return [];
                }

            },

            getTransferTypes: function () {

                if (typeof this.transferTypes !== 'undefined') {

                    return this.transferTypes.map(x => x = { code: x.code, description: x.description });

                } else {

                    return [];
                }

            },
            getEmployees: function () {

                if (typeof this.employees !== 'undefined') {

                    return this.employees.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }

            },
            getDepartments: function () {

                if (typeof this.departments !== 'undefined') {

                    return this.departments.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }

            },
            getTrips: function () {

                if (typeof this.trips !== 'undefined') {

                    return this.trips.map(x => x = { code: x.code, description: x.name });

                } else {

                    return [];
                }
            },
            getRequestTypes: function () {

                if (typeof this.requestTypes !== 'undefined') {

                    return this.requestTypes.map(x => x = { code: x.code, description: x.description });

                } else {

                    return [];
                }
            },

            getTransactionTypes: function () {

                if (typeof this.transactionTypes !== 'undefined') {

                    return this.transactionTypes.map(x => x = { code: x.code, description: x.description });
                }

                return [];
            },

            getMachines: function () {

                if (typeof this.machines !== 'undefined') {

                    return this.machines.map(x => x = { code: x.code, description: x.name });
                }

                return [];
            },
            getAutoComplete: function (functionName, param) {

                switch (functionName) {

                    case "getInventories":

                        return this.getInventories();

                    case "getUomValues":


                        return this.getUomValues(param);

                    case "getAgents":

                        return this.getAgents();

                    case "getCustomers":

                        return this.getCustomers();

                    case "getVendors":

                        return this.getVendors();

                    case "getBranches":

                        return this.getBranches();

                    case "getWareHouses":

                        return this.getWareHouses();

                    case "getSalesType":

                        return this.getSalesType();

                    case "getTransferTypes":

                        return this.getTransferTypes();

                    case "getEmployees":

                        return this.getEmployees();

                    case "getDepartments":

                        return this.getDepartments();

                    case "getTrips":

                        return this.getTrips();

                    case "getCategories":

                        return this.getCategories();

                    case "getRequestTypes":

                        return this.getRequestTypes();

                    case "getTransactionTypes":

                        return this.getTransactionTypes();

                    case "getMachines":

                        return this.getMachines();

                    default:

                        return [];

                }
            }
        }


    });


    useEffect(() => {

        //Ensure that presentation object is present in details
        var presentationValue = {
            toShow: true,
            categoryId: ""
        };

        data.metaData.detailProperties.forEach(detailProperty => detailProperty.presentationValue = presentationValue);

        setMasterfiles();

    }, []);

    async function setMasterfiles(){

        var masterfiles = localStorage.getItem("masterfiles");

        if (masterfiles !== null) {

            masterfiles = JSON.parse(masterfiles);

            var tempData = { ...data.metaData };

            tempData.inventories = masterfiles.inventories;
            tempData.uomValues = masterfiles.uomValues;
            tempData.agents = masterfiles.agents;
            tempData.customers = masterfiles.customers;
            tempData.vendors = masterfiles.vendors;
            tempData.branches = masterfiles.branches;
            tempData.wareHouses = masterfiles.wareHouses;
            tempData.salesType = masterfiles.salesType;
            tempData.transferTypes = masterfiles.transferTypes;
            tempData.employees = masterfiles.employees;
            tempData.departments = masterfiles.departments;
            tempData.trips = masterfiles.trips;
            tempData.requestTypes = masterfiles.requestTypes;
            tempData.crates = masterfiles.crates;
            tempData.categories = masterfiles.categories;
            tempData.transactionTypes = masterfiles.transactionTypes;
            tempData.machines = masterfiles.machines;

            updateMetaData({ metaData: tempData });

            return;

        }

        await fetch("/Generics/GetMasterfiles?" + new URLSearchParams({

                context: typeof data.metaData.context != 'undefined' ? data.metaData.context.type : "SP"

             }))
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                var tempData = { ...data.metaData };

                tempData.inventories = responseData.inventories;
                tempData.uomValues = responseData.uomValues;
                tempData.agents = responseData.agents;
                tempData.customers = responseData.customers;
                tempData.vendors = responseData.vendors;
                tempData.branches = responseData.branches;
                tempData.wareHouses = responseData.wareHouses;
                tempData.salesType = responseData.salesType;
                tempData.transferTypes = responseData.transferTypes;
                tempData.employees = responseData.employees;
                tempData.departments = responseData.departments;
                tempData.trips = responseData.trips;
                tempData.requestTypes = responseData.requestTypes;
                tempData.crates = responseData.crates;
                tempData.categories = responseData.categories;
                tempData.transactionTypes = responseData.transactionTypes;
                tempData.machines = responseData.machines;

                updateMetaData({ metaData: tempData });

                localStorage.setItem("masterfiles", JSON.stringify(responseData));

        });
    }


    const refreshConnection = () => {

        fetch("Generics/RefreshConnection").then(response => response.status === 401 ? window.location = "/login" : response.json());

    }

    const [guidId, updateGuidId] = useState("");

    const [goToList, updateGoToList] = useState(false);

    function updateParentValue(name,value) {

        const tempData = { ...data.metaData };

        tempData.parentProperties[name] = convertToDataType(data.metaData.parentMetaData, name, value);

        if (tempData.context.formDiscriminator === "BreadOut") {
              
                if (tempData.parentProperties.hasOwnProperty("transferType")) {

                    var type = tempData.parentProperties.transferType;

                    if (type === "0" || type===0) {

                        if (typeof data.metaData.agents !== 'undefined') {

                            let agent = data.metaData.agents.find(x => x.code == value);

                            if (typeof agent !== 'undefined') {

                                tempData.parentProperties["toWhsCode"] = agent.warehouseId;
                                tempData.parentProperties["fromWhsCode"] = "NEW";
                            };
                        }
                    };

                    if (type === "5" || type === 5) {

                        if (typeof data.metaData.agents !== 'undefined') {

                            let agent = data.metaData.agents.find(x => x.code == value);        

                            if (typeof agent !== 'undefined') {

                                tempData.parentProperties["toWhsCode"] = "NEW";
                                tempData.parentProperties["fromWhsCode"] = agent.warehouseId;
                               
                            };
                        }
                    };
                };        
        }


        if (tempData.context.formDiscriminator === "GoodsIssue") {

            if (tempData.parentProperties.hasOwnProperty("departmentId")) {

                if (typeof data.metaData.employees !== 'undefined') {

                    let employee = data.metaData.employees.find(x => x.code == value);

                    if (typeof employee !== 'undefined') {

                        if (typeof data.metaData.departments !== 'undefined') {

                            let department = data.metaData.departments.find(x => x.code == employee.departmentId);

                            if (typeof department !== 'undefined') {

                                tempData.parentProperties["departmentId"] = department.code;

                            };
                        }
                    }
                }
            }
        }


        if (tempData.context.formDiscriminator === "Return") {

            if (tempData.parentProperties.hasOwnProperty("fromWhsCode")) {

                if (typeof data.metaData.agents !== 'undefined') {

                    let agent = data.metaData.agents.find(x => x.code == value);

                    if (typeof agent !== 'undefined') {

                        tempData.parentProperties["fromWhsCode"] = agent.warehouseId;
                    };

                }
            };
        }




        if (tempData.context.formDiscriminator === "BadOrder") {

            if (tempData.parentProperties.hasOwnProperty("fromWhsCode")) {

                if (typeof data.metaData.agents !== 'undefined') {

                    let agent = data.metaData.agents.find(x => x.code == value);

                    if (typeof agent !== 'undefined') {

                        tempData.parentProperties["fromWhsCode"] = agent.warehouseId;
                    };

                }


            };

            if (tempData.parentProperties.hasOwnProperty("toWhsCode")) {

                if (typeof data.metaData.agents !== 'undefined') {

                    let agent = data.metaData.agents.find(x => x.code == value);

                    if (typeof agent !== 'undefined') {

                        tempData.parentProperties["toWhsCode"] = "BO";
                    };
                }

            };
        }

        if (tempData.transactionCode === "Ordering") {

            if (name === "date") {

                //For production date
                let prodForecastPeriod = tempData.settings.find(x => x.settingName === 0);

                let prodForecastPeriodValue = prodForecastPeriod === null || typeof prodForecastPeriod === 'undefined' ? 0 : prodForecastPeriod.value;

                let orderDate = new Date(value);

                let productionDate = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate() +parseInt(prodForecastPeriodValue));

                tempData.parentProperties.productionDate = productionDate.toLocaleDateString('en-CA');

                //For delivery date
                let deliveryForecastPeriod =tempData.settings.find(x => x.settingName === 1);

                let deliveryForecastPeriodValue = deliveryForecastPeriod === null || typeof deliveryForecastPeriod === 'undefined' ? 0 : deliveryForecastPeriod.value;

                let deliveryDate = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate() +parseInt(deliveryForecastPeriodValue));

                tempData.parentProperties.deliveryDate = deliveryDate.toLocaleDateString('en-CA');

            }
        }

        updateMetaData({ metaData: tempData });

    };


    //function updateDetailValue(index,name,value) {
    //    return new Promise((resolve, reject) => {

    //        const tempData = { ...data.metaData };

    //        tempData.detailProperties[index][name] = convertToDataType(data.metaData.detailMetaData, name, value);

    //        var detail = tempData.detailProperties[index];

    //        if (detail.hasOwnProperty("uomEntry")) {

    //            let inventory = [];

    //            if (typeof data.metaData.inventories !== 'undefined') {

    //                inventory = data.metaData.inventories.find(x => x.itemCode == value);
    //            }

    //            if (typeof inventory !== 'undefined') {

    //                tempData.detailProperties[index].uomEntry = data.metaData.context.type === "P" || data.metaData.context.type === 'ALL' ? inventory.purchaseUomEntry : inventory.saleUomEntry;

    //                if (data.metaData.context.type === "SR") {

    //                    tempData.detailProperties[index].uomEntry = inventory.requestUomEntry;
    //                    tempData.detailProperties[index].defaultWarehouse = inventory.defaultWarehouse;
    //                };

    //            };
    //        };

    //        updateMetaData({ metaData: tempData });

    //        resolve();
    //    });
    //}


    function updateDetailValue(index, name,value) {
      
        const tempData = { ...data.metaData };

        tempData.detailProperties[index][name] = convertToDataType(data.metaData.detailMetaData, name, value);

        var detail = tempData.detailProperties[index];

        if (detail.hasOwnProperty("uomEntry")) {

            let inventory = [];

            if (typeof data.metaData.inventories !== 'undefined') {

                inventory = data.metaData.inventories.find(x => x.itemCode == value);
            }

            if (typeof inventory !== 'undefined') {

                tempData.detailProperties[index].uomEntry = data.metaData.context.type === "P" || data.metaData.context.type ==='ALL' ? inventory.purchaseUomEntry : inventory.saleUomEntry;

                if (data.metaData.context.type === "SR") {

                    tempData.detailProperties[index].uomEntry = inventory.requestUomEntry;
                    tempData.detailProperties[index].defaultWarehouse = inventory.defaultWarehouse;
                };

            };
        };

        updateMetaData({ metaData: tempData });
    };



    function addRow() {

        const tempData = { ...data.metaData };

        var detailProperties = createProperties(data.metaData.detailMetaData);

        //toShowProperty

        var presentationValue = {
            toShow: true,
            categoryId: ""
        };

        tempData.detailProperties.push(detailProperties);

        tempData.detailProperties.forEach(detailProperty => {

            detailProperty.presentationValue = presentationValue;

        });

        updateMetaData({ metaData: tempData });

        refreshConnection();

    };

    function deleteRow(id) {

        const tempData = { ...data.metaData };

        tempData.detailProperties = tempData.detailProperties.filter((data) => data.id != id);

        updateMetaData({ metaData: tempData });

    };

    const refreshForm = () => {

        const tempData = { ...data.metaData };

        tempData.refresh = true;

        updateMetaData({ metaData: tempData });

    }

    const reloadDetails = () => {

        const tempData = { ...data.metaData };

        tempData.reloadDetails = tempData.reloadDetails + 1;

        updateMetaData({ metaData: tempData });

    }




    return (

        <TransactionContext.Provider value={{data,updateMetaData,guidId,updateGuidId,goToList,updateGoToList, updateParentValue, updateDetailValue, addRow, deleteRow,refreshForm, reloadDetails}}>

            {props.children}

        </TransactionContext.Provider>
        
    )

}


