import React from 'react';
import { Fragment } from 'react';

import { useEffect, useState } from "react";


export default function BranchSelection(props) {


    const [thisComponentState, updateState] = useState({

        branches: [],
        selectedBranches: props.selectedBranches,
        isLoading: true,
        selectAll: false

    });

    useEffect(() => {

        fetch("Productions/GetBranches")
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isLoading: false, branches: response });

            })

    }, []);


    const onChange = (value) => {

        var tempData = [...thisComponentState.selectedBranches]

        if (tempData.includes(value)) {

            tempData = tempData.filter(x => x !== value);

            updateState({ ...thisComponentState, selectedBranches: tempData });

            props.updateSelectedBranches(tempData);

        } else {

            tempData.push(value);

            updateState({ ...thisComponentState, selectedBranches: tempData });

            props.updateSelectedBranches(tempData);

        }   
    }



    const back = () => {

        props.updateShowBranchSelection(false);
    }

   

    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>);
    }


    return (

        <table className="table table-bordered">

            <thead>

                <tr>
                    <td colSpan="2">
                        <input className='btn btn-success' style={{width:'100px'}} value="Back" type='button' onClick={back} />
                    </td>              
                </tr>

                <tr>
                    <td>Action</td>
                    <td>Description</td>
                 </tr>

          </thead>

            <tbody>
                {
                    thisComponentState.branches.map((data,trIndex) =>

                        <tr key={trIndex+"tr"}>
                            <td>
                                <input type="checkbox" defaultChecked={props.selectedBranches.includes(data.code)} onChange={() => onChange(data.code)} />
                            </td>
                            <td>
                                {data.description}
                            </td>
                        </tr>

                    )
                }
                </tbody>

           
            </table>

       
        
        
   )

}