import {  faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import EvaluatorComment from "./EvaluatorComment";



export default function EvaluationForm(props) {

    const [thisComponentState, updateState] = useState({
        evaluationCycleId:"",
        evalueeId: "",
        evalueeName: "",
        dateHired: "",
        department: "",
        evaluatorId: "",
        evaluator: "",
        positionId: "",
        purpose: "",
        comments: "",
        keyStrenghts: "",
        areasForDevelopment: "",
        developmentPlans: "",
        recommendations: "",
        mainPerformanceFactors: [],
        isLoading: true,
        mainPerformanceFactorId: "",
        subPerformanceFactorId: "",
        showComment: false,
        commentAssociatedValue: 0,
        isSaving:false
    });

    const { id } = useParams();

    useEffect(() => {

        fetch("/PerformanceEvaluations/GetEvaluationCycleDetail?" + new URLSearchParams({

            id: id

        }))
            .then(response => response.json())
            .then(response =>
            {
                if (response.hasOwnProperty("success")) {

                    updateState({ ...thisComponentState, isLoading: false });

                    window.location = "/home";

                    return alert(response.message);
                }
        
                updateState({
                    ...thisComponentState,
                    evaluationCycleId: response.evaluationCycleId,
                    performanceEvaluatorType: response.performanceEvaluatorType,
                    evaluatorId:response.evaluatorId,
                    evalueeId: response.evalueeId,
                    evalueeName: response.evalueeName,
                    department: response.department,
                    position: response.position,
                    appraisalDate: response.appraisalDate,
                    evaluatorType: response.evaluatorType,
                    mainPerformanceFactors: response.mainPerformanceFactors,
                    isLoading: false
                });
            });
    }, []);


    const someSubFactorsHasNoScore = () => {

        let tempData = [...thisComponentState.mainPerformanceFactors];

        for (let i = 0; i < tempData.length; i++)
        {
            for (let j = 0; j < tempData[i].performanceEvaluationDetailModels.length; j++) {

                if (tempData[i].mainFactorId === 1)
                {
                    if (tempData[i].performanceEvaluationDetailModels[j].scoreModels.every(val => !val.isSelected)) {

                        tempData[i].performanceEvaluationDetailModels[j].hasError = true;

                        updateState({ ...thisComponentState, mainPerformanceFactors: tempData });

                        return true;
                    }
                }
            };
        };

    }


    const save = () => {

        if (someSubFactorsHasNoScore())
        {
            return alert("Blank score is not allowed.")
        }

        var isConfirm = window.confirm("Are you sure you want to save this?");

        if (!isConfirm) {

            return;
        };

    
        let performanceEvaluationDetailModels = [];


        for (let i = 0; i < thisComponentState.mainPerformanceFactors.length; i++) {

            for (let j = 0; j < thisComponentState.mainPerformanceFactors[i].performanceEvaluationDetailModels.length; j++) {

                let state = thisComponentState.mainPerformanceFactors[i].performanceEvaluationDetailModels[j].scoreModels.find(val => val.isSelected);

                if (typeof state !== 'undefined')
                {
                    let data = {
                        mainFactorId : thisComponentState.mainPerformanceFactors[i].mainFactorId,
                        PerformanceEvaluatorDetailId: thisComponentState.mainPerformanceFactors[i].performanceEvaluationDetailModels[j].performanceEvaluatorDetailId,
                        subPerformanceFactorId: thisComponentState.mainPerformanceFactors[i].performanceEvaluationDetailModels[j].subPerformanceFactorId,
                        value: state.value,
                        comments: thisComponentState.mainPerformanceFactors[i].performanceEvaluationDetailModels[j].comments
                    }

                    performanceEvaluationDetailModels.push(data);
                }
            }
        }

        let mainPerformanceFactorOtherThanCore = performanceEvaluationDetailModels.find(val => val.mainFactorId === 2);

        if (typeof mainPerformanceFactorOtherThanCore === 'undefined') {

            return alert("You did not rate any technical competencies");
        }
        

    

        let postBody = {
            evaluationCycleId:thisComponentState.evaluationCycleId,
            evalueeId:  thisComponentState.evalueeId,
            evaluatorId: thisComponentState.evaluatorId,
            evaluator: thisComponentState.evaluator,
            purpose: thisComponentState.purpose,
            overallComments: thisComponentState.comments,
            keyStrengths: thisComponentState.keyStrenghts,
            areasForDevelopment: thisComponentState.areasForDevelopment,
            developmentPlans: thisComponentState.developmentPlans,
            recommendations: thisComponentState.recommendations,
            appraisalDate: thisComponentState.appraisalDate,
            performanceEvaluatorType: thisComponentState.performanceEvaluatorType,
            performanceEvaluationDetails: performanceEvaluationDetailModels
        }


        updateState({ ...thisComponentState, isSaving: true });

        fetch("/PerformanceEvaluations/SavePerformanceEvaluation", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isSaving:false })

                return alert(response.message);

            });
    }



    const modifyScore = (mainPerformanceFactorId, subPerformanceFactorId, guidId) => {

        let mainPerformanceFactors = [...thisComponentState.mainPerformanceFactors];

        let factor = mainPerformanceFactors.find(val => val.mainFactorId === mainPerformanceFactorId);

        let tempDatas = [...factor.performanceEvaluationDetailModels];

        let value = 0;

        for (let i = 0; i < tempDatas.length; i++) {

            for (let j = 0; j < tempDatas[i].scoreModels.length; j++) {

                if (tempDatas[i].scoreModels[j].guidId === guidId) {

                    tempDatas[i].scoreModels[j].isSelected = !tempDatas[i].scoreModels[j].isSelected;

                    //Make other check boxes to false if this is being selected. 

                    if (tempDatas[i].scoreModels[j].isSelected)
                    {
                        if (tempDatas[i].hasOwnProperty("hasError"))
                        {
                            delete tempDatas[i].hasError;
                        }

                        tempDatas[i].scoreModels.forEach(val => {

                            if (val.guidId !== guidId) {

                                val.isSelected = false;

                            } else {

                                value = val.value;
                            }
                        });
                    }
                }
            }
        };

        updateState({ ...thisComponentState, mainPerformanceFactorId: mainPerformanceFactorId, subPerformanceFactorId: subPerformanceFactorId, mainPerformanceFactors: mainPerformanceFactors, showComment: value === 2 || value===1 || value===5,commentAssociatedValue:value});
    }

    if (thisComponentState.isLoading)
    {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Initializing Data...
                    </button>
                </div>
            </div>
        </div>
        )
    }

    const onChange = (mainPerformanceFactorId, subPerformanceFactorId, value) => {

        let mainPerformanceFactors = [...thisComponentState.mainPerformanceFactors];

        let mainfactor = mainPerformanceFactors.find(val => val.mainFactorId === mainPerformanceFactorId);

        let subFactor = mainfactor.performanceEvaluationDetailModels.find(val => val.subPerformanceFactorId === subPerformanceFactorId);

        subFactor.comments = value;

        updateState({ ...thisComponentState, mainPerformanceFactors: mainPerformanceFactors });
    }

    if (thisComponentState.showComment) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle("500px")}>
                    <EvaluatorComment commentAssociatedValue={thisComponentState.commentAssociatedValue} back={() => updateState({ ...thisComponentState, showComment: false })} onChange={onChange} mainPerformanceFactorId={thisComponentState.mainPerformanceFactorId} subPerformanceFactorId={thisComponentState.subPerformanceFactorId} />
                </div>
            </div>
        )
    }




    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (
        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Refresh" onClick={() => window.location.reload()} className="form-control" />
                </div>
                <div className="col">
                    <input type="button" value="Save" onClick={save} className="form-control" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            Employee Id
                        </div>
                        <div className="col">
                            <input className="form-control" disabled value={thisComponentState.evalueeId} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Employee Name
                        </div>
                        <div className="col">
                            <input className="form-control" disabled value={thisComponentState.evalueeName} />
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col">
                            Position
                        </div>
                        <div className="col">
                            <input className="form-control" disabled value={thisComponentState.position} />
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            Date Hired
                        </div>
                        <div className="col">

                            <input className="form-control" disabled value={thisComponentState.dateHired} type="date" />

                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col">
                            Evaluator Type
                        </div>
                        <div className="col">

                            <input className="form-control" disabled value={thisComponentState.evaluatorType} />


                        </div>
                    </div>


                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            Deparment
                        </div>

                        <div className="col">


                            <input className="form-control" disabled value={thisComponentState.department} />

                        </div>
                    </div>
                    <br />
                   
              
                    <div className="row">
                        <div className="col">
                            Appraisal Date
                        </div>

                        <div className="col">
                            <input className="form-control" disabled value={thisComponentState.appraisalDate} type="date" />
                        </div>

                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Purpose
                        </div>

                        <div className="col">

                            <select value={thisComponentState.purpose} disabled className="form-control" onChange={(e) => updateState({ ...thisComponentState, purpose: e.currentTarget.value })} >

                                <option>Regularization</option>
                                <option>Quarterly Evaluation</option>
                                <option>Others</option>

                            </select>

                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col">

                        </div>

                        <div className="col">


                        </div>
                    </div>

                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        5 = Exceptional
                    </div>
                    <div>
                        4 = Exceeds Expectation
                    </div>
                    <div>
                        3 = Meets Expectation
                    </div>
                    <div>
                        2 = Needs Improvement
                    </div> <div>
                        1 = Poor, Exhibits little or no level of proficiency
                    </div>
                </div>
            </div>

            <hr />

            <div>

                {
                    thisComponentState.mainPerformanceFactors.length > 0 && thisComponentState.mainPerformanceFactors.map(val =>

                        <div>

                            <div className="row">
                                <div className="col">
                                    <div className="text-center">
                                        <h2>
                                            {val.mainFactorName}
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {val.performanceEvaluationDetailModels.length > 0 && val.performanceEvaluationDetailModels.map((detail, index) =>
                                <div style={{backgroundColor:detail.hasOwnProperty("hasError") ?'red':'transparent'}}>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    {index + 1}. {detail.subFactorName}
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-center">1</div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="text-center">2</div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="text-center">3</div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="text-center">4</div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="text-center">5</div>
                                                        </div>
                                                        <div className="col">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-8">
                                                    {">"}  {detail.description}
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row">

                                                        {
                                                            detail.scoreModels.map(scoreModels =>

                                                                <div className="col">
                                                                    <input type="checkbox" checked={scoreModels.isSelected} onChange={() => modifyScore(val.mainFactorId, detail.subPerformanceFactorId, scoreModels.guidId)} className="form-control" />
                                                                </div>
                                                            )

                                                        }

                                                        <div className="col">
                                                            <FontAwesomeIcon icon={faNoteSticky} title="Write Note" onClick={() => updateState({...thisComponentState,showComment:true})} style={{ cursor: 'pointer' }} size="2x" color="blue" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <hr />

                                </div>

                            )
                            }

                        </div>
                    )
                }
            </div>

            <div className="row">
                <div className="col">
                    <div>
                        Comments
                    </div>
                    <textarea className="form-control" onChange={(e) => updateState({ ...thisComponentState, comments: e.currentTarget.value })}>
                    </textarea>
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Key Strengths
                    </div>
                    <textarea className="form-control" onChange={(e) => updateState({ ...thisComponentState, keyStrengths: e.currentTarget.value })}>
                    </textarea>
                </div>
                <div className="col">
                    <div>
                        Areas for development
                    </div>
                    <textarea className="form-control" onChange={(e) => updateState({ ...thisComponentState, areasForDevelopment: e.currentTarget.value })} >
                    </textarea>
                </div>
                <div className="col">
                    <div>
                        Developments Plan
                    </div>
                    <textarea className="form-control" onChange={(e) => updateState({ ...thisComponentState, developmentPlans: e.currentTarget.value })}>

                    </textarea>
                </div>
            </div>

            <hr />

            <div className="row" style={{ marginBottom: "50px" }} >
                <div className="col">
                    <div>
                        Recommendations
                    </div>
                    <textarea className="form-control" style={{ marginBottom: "50px" }} onChange={(e) => updateState({ ...thisComponentState, recommendations: e.currentTarget.value })}>
                    </textarea>
                </div>
            </div>
        </div>)
}