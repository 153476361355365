import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";



export default function StatusType(props)
{


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle("300px","300px")}>


                <div className="row">
                    <div className="col text-center">

                        <input type="button" value="Back" onClick={() => props.back()} className="form-control" />

                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col text-center">

                        Status By:

                    </div>
                </div>

               <hr/>

                <div className="row">
                    <div className="col">
                        <input type="button" value="Washes" onClick={()=>props.washStatus()} className="form-control" />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <input type="button" value="Crates" onClick={()=>props.crateStatus()} className="form-control" />
                    </div>
                </div>

            </div>
        </div>
       
 )}