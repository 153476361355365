
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CreateCycleCount from './CreateCycleCount';
import CycleCountDetails from './CycleCountDetails';
import DayOff from './DayOff';


export default function CycleCounts(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: true,
        isPerformingOperation:false,
        selectedId: 0,
        showDetails: false,
        toCreateCycleCount: false,
        changeDayOff:false,
        values: [],
        toReload:0
    });

    useEffect(() => {
        fetch("/Crates/GetCycleCounts")
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    values: response,
                    isLoading:false                   
                });
            });
    }, [thisComponentState.toReload]);



    const deleteCycleCount = (cycleCountId) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Crates/DeleteCycleCount", {

            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                value: parseInt(cycleCountId)
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (response.success)
                {
                    let tempDatas = [...thisComponentState.values];

                    tempDatas = tempDatas.filter(x => x.id !== cycleCountId);

                    updateState({ ...thisComponentState, values: tempDatas, isPerformingOperation: false });

                    return;
                }

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message)
            });
    }






    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const back = (toReload) => {

        if (toReload) {

            updateState({ ...thisComponentState, toReload: thisComponentState.toReload + 1,toCreateCycleCount:false });

        } else {

            updateState({ ...thisComponentState, toCreateCycleCount: false })
        }
    }

    if (thisComponentState.showDetails) {
        return (<CycleCountDetails cycleCountId={thisComponentState.selectedId} back={() => updateState({...thisComponentState,showDetails:false})}/>)
    }

    if (thisComponentState.toCreateCycleCount)
    {
        return (<CreateCycleCount back={back} />);

    }

    if (thisComponentState.changeDayOff) {
        return (<DayOff back={() => updateState({ ...thisComponentState,changeDayOff: false })} />)
    }


    return (

        <div>
            <div>
                <div className="row">

                    <div className="col">
                        <input type='button' className="form-control" value="Back" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input type='button' className="form-control" value="Create" onClick={() => updateState({...thisComponentState,toCreateCycleCount:true})} />
                    </div>

                    <div className="col">
                        <input type='button' className="form-control" value="DayOff" onClick={() => updateState({ ...thisComponentState, changeDayOff: true })} />
                    </div>

                </div>

                <br />

                <div className="row">

                    <div className="col">
                        <table className="table table-bordered">

                            <thead className="thead-dark">
                                <tr>
                                    <th colSpan="2"> Action </th>
                                    <th> Id </th>
                                    <th> Start Date </th>
                                    <th> End Date </th>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    thisComponentState.values.map(val =>

                                        <tr>
                                            <td> <input type="button" className="form-control" value="Details" onClick={() => updateState({ ...thisComponentState, selectedId: val.id, showDetails: true })} />  </td>
                                            <td> <input type="button" className="form-control" value="Delete" onClick={() => deleteCycleCount(val.id)} /></td>
                                            <td> {val.id}</td>
                                            <td> {val.start}</td>
                                            <td> {val.end}    </td>
                                        </tr>)
                                }

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>
        
   )
}