import React, { useState } from 'react';
import StandardAutoComplete from './components/StandardAutocomplete';

function DynamicInput(props) {

    const[inputs, setInputs] = useState(['']); // State to store input values

    const [itemCode, updateItemCode] = useState("");

    // Function to handle adding a new input field
    const handleAddInput = () => {
        setInputs([...inputs, '']);
    };

    // Function to handle deleting an input field
    const handleDeleteInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    // Function to handle changing input value
    const handleChangeInput = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);
    };

    const back = () => {

        console.log(inputs);

        const filteredInputs = inputs.filter(input => input.trim() !== '');
        // Join the filtered inputs with commas
         filteredInputs.join(',');

        props.back(filteredInputs);

    }

    const addToArray = (code) => {

        const newInputs = [...inputs];

        newInputs.push(code);

        setInputs(newInputs);

    }

 

    return (
        <div>
            <button className="form-control" onClick={back}>Back</button>

            <hr/>

            {/* Render input fields */}
            {inputs.map((input, index) => (
                <div key={index} className="row">

                    <div className="col-sm-4">
                        <button className="form-control" onClick={() => handleDeleteInput(index)}>Delete</button>
                    </div>

                    <div className="col-sm-8">

                        <StandardAutoComplete value={itemCode} url="Inventories/GetInventories" clear={() => updateItemCode("")} updateValue={addToArray}/>


                    </div>
                
                </div>
            ))}



            {/* Button to add a new input field */}

            <hr/>

            <button className="form-control" onClick={handleAddInput}>Add</button>



        </div>
    );
}

export default DynamicInput;
