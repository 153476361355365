import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";


export default function PrintBalanceSheet(props)
{

    const [thisComponentState, updateState] = useState({

        productionDate: "",
        location: "NEW",
        isPerformingOperation:false
    });

    const print = () => {

        updateState({ ...thisComponentState,isPerformingOperation: true });

        
        fetch("/Productions/PrintBalanceSheet?" + new URLSearchParams({

                productionDate: thisComponentState.productionDate,
                location :thisComponentState.location

            }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState,isPerformingOperation: false });

            });
    }

    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (

        <div style={getModalStyle() }>
            <div style={getModalContentStyle("500px","500px")}>
                <div className="row">
                    <div className="col">
                        <input type="button" value="Back" className="form-control" onClick={()=>props.back()} />
                    </div>              
                </div>

                <hr/>

                <div className="row">
                    <div className="col">
                        <div>Date</div>
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" value={thisComponentState.productionDate} onChange={(e) => updateState({...thisComponentState,productionDate:e.currentTarget.value}) }/>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div>Location</div>
                    </div>

                    <div className="col">
                        <select className="form-control" value={thisComponentState.location } onChange={(e) => updateState({...thisComponentState,location: e.currentTarget.value })}>
                            <option value="NEW"> NEW BUILDING </option>
                            <option value="OLD"> OLD BUILDLING </option>
                            <option value="COMMISSARY"> OLD BUILDLING </option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                    </div>
                    <div className="col">
                        <input type="button" value="Print" className="form-control" onClick={print}/>
                    </div>
                </div>

            </div>
        </div>
   )
}