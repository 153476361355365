import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container } from 'reactstrap';
import ChatBox from '../Chat/ChatBox';
import ChatBoxContainer from '../Chat/ChatBoxContainer';

import NavMenu from './NavMenu';

class Layout extends Component {

    static displayName = Layout.name;

    render() {
        // Access the action prop from Redux state
        const { action } = this.props;

        return (
            <div>
                <NavMenu />

                <Container style={{width:"100%"}} className="col">

                    {this.props.children}

                    {/*{*/}
                    {/*    action === 1 && <ChatBoxContainer/>*/}
                    {/*}*/}

                </Container>
            </div>
        );
    }
}

// Map state to props
const mapStateToProps = (state) => {
    return {
        action: state.action,
    };
};

// Connect the component to the Redux store
export default connect(mapStateToProps)(Layout);
