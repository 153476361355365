import React, {  Fragment, useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import Detail from '../Detail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faClone, faCompass, faDiagramProject, faDiagramSuccessor, faEdit, faEnvelope, faEye, faFileArchive, faFileExcel, faFileUpload, faForward, faHand, faPlus, faReceipt, faRedo, faStickyNote, faStopCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import EditPurchaseOrder from './EditPurchaseOrder';
import PoDeliveryStatus from './PoDeliveryStatus';
import UploadFile from '../../../UploadFile';
import PoAttachments from './PoAttachments';
import EnterCanvases from './EnterCanvases';
import exportToCSV from '../../Functions/exportToCSV';
import { toCsvString } from '../../Functions/toCsvString';


export default function DetailsPurchaseOrderNavigator(props) {

    const { data, guidId,updateMetaData} = useContext(TransactionContext);

    

    const [thisComponentState, updateState] = useState({

        sending: false,
        showOnHand: false,
        toEdit: false,
        data: [],
        toScan: false,
        isPerformingOperation: false,
        seeStatus: false,
        uploadFile: false,
        showAttachments: false,
        showCanvases:false

 

    });

    const email = () => {

            var postBody = {

                Value: guidId
            }

            fetch("/PurchaseOrders/Send", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(postBody),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => {

                    if (!response.ok) {

                        response.text().then(text => console.log(text));
                    }
                    else {

                        return response.json();
                    }

                })

                .then(response => {

                    alert("Purchase order has been sent.")

                    updateState(prevState => ({

                        ...prevState,
                        sending: false,
                        isEmailed: true

                    }));
                });
    }


    const cancel = () => {

        var isConfirm = window.confirm("Are you sure you want to cancel this PO?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        var postBody = {

            value: guidId
        }

        fetch("/PurchaseOrders/CancelByCookie", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }


    const close = () => {

        var isConfirm = window.confirm("Are you sure you want to close this PO?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState,isPerformingOperation: true })

        var postBody = {

            value: guidId
        }

        fetch("/PurchaseOrders/Close", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }


    const duplicate = () => {

        localStorage.setItem("toDuplicateId", guidId);

        window.location.reload();

    };



    const preview = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/PurchaseOrders/PreviewWithCookie?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

             
                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }

   

    const getOnHandQty = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var arrayOfcodes = [];

        for (var i = 0; i < data.metaData.detailsFromDb.details.length; i++) {

            arrayOfcodes.push(data.metaData.detailsFromDb.details[i]["code"]);
        }

        var codes = arrayOfcodes.join(":");

        var dataToPost = {

            Value: codes
        }

        fetch("PurchaseOrders/GetOnHandQty",
            {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(dataToPost),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }

            })
            .then(response => response.status === 401 ? window.location = "/Loging" : response.json())
            .then(response => {

                //Before presenting get requested quantity.

                for (var i = 0; i < response.onHandInventories.length; i++) {

                    for (var j = 0; j < data.metaData.detailsFromDb.details.length; j++) {


                        if (response.onHandInventories[i].itemCode === data.metaData.detailsFromDb.details[j].code) {

                            response.onHandInventories[i].reqQty = data.metaData.detailsFromDb.details[j].invQty;

                            break;
                        }
                    }
                }

                updateState({ ...thisComponentState, data: response, showOnHand: true,isPerformingOperation: false })

            });

    };

    

    const editPO = () => {

        updateState({ ...thisComponentState, toEdit: true });
      
    }

    const note = () => {

        var isConfirm = window.confirm("Are you sure you want to note this PO?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        var postBody = {

            value: guidId
        }

        fetch("/PurchaseOrders/Note", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                if (response.success) {

                    let tempData = { ...data.metaData };

                    tempData.listData = tempData.listData.filter(item => item.guidId !== guidId);

                    updateMetaData({ ...data, metaData: tempData });
                    
                }


                return alert(response.message);

            });
    }



    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/PurchaseOrders/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }


    if (thisComponentState.uploadFile) {

        return (<UploadFile isSeparateUpload={true} identifier={guidId} uploadUrl="/PurchaseOrders/UploadFile" fileLimit={3} back={() => updateState({...thisComponentState,uploadFile:false}) }/>)
    }

    if (thisComponentState.showAttachments) {

        return (<PoAttachments  back={() => updateState({...thisComponentState,showAttachments:false}) } />)
    }

    if (thisComponentState.sending) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <div>Note to supplier : </div>

                    <textarea style={{ width: "250px", height: "250px" }}>

                    </textarea>

                    <div style={{ textAlign: "center" }}> <input className="btn btn-success" onClick={() => email()} style={{ width: "120px" }} type="button" value={thisComponentState.isEmailed ? "Close" : "Email"} /></div>
                </div>
            </div>

        )
    }

    if (thisComponentState.toEdit) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <EditPurchaseOrder guidId={guidId} back={() => updateState({ ...thisComponentState, toEdit: false })} />

                </div>
            </div>
        )
    }



    if (thisComponentState.seeStatus) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <PoDeliveryStatus guidId={guidId} back={() => updateState({ ...thisComponentState, seeStatus: false })} />

                </div>
            </div>
        )
    }


    if (thisComponentState.showCanvases) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <EnterCanvases back={() => updateState({...thisComponentState,showCanvases:false}) }/>

                </div>
            </div>
        )
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailsFromDb !== 'undefined' && typeof data.metaData.detailsFromDb.details !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailsFromDb.details);

            exportToCSV("data", csvString);
        }  
    }


    return (

        <div style={{ float: "left" }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faHand} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={getOnHandQty} />
            <FontAwesomeIcon icon={faBox} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={getOnHandQty} />
            <FontAwesomeIcon icon={faStopCircle} title="Close" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={close} />
            <FontAwesomeIcon icon={faWindowClose} title="Cancel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={cancel} />
            <FontAwesomeIcon icon={faClone} title="Duplicate" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={duplicate}/>
            <FontAwesomeIcon icon={faEye} title="Preview" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={preview} />
            <FontAwesomeIcon icon={faEdit} title="Edit" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={editPO} />
            <FontAwesomeIcon icon={faEnvelope} title="Send to supplier" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="cyan" onClick={email} />
            <FontAwesomeIcon icon={faStickyNote} title="Noter" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={note} />
            <FontAwesomeIcon icon={faCompass} title="See PO status" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({...thisComponentState,seeStatus:true})} />
            <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={forward} />
            <FontAwesomeIcon icon={faFileUpload} title="Upload File" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, uploadFile: true })} />
            <FontAwesomeIcon icon={faFileArchive} title="List of attchments" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, showAttachments: true })} />
            <FontAwesomeIcon icon={faDiagramSuccessor} title="Enter canvases" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="pink" onClick={() => updateState({ ...thisComponentState, showCanvases: true })} />
            <FontAwesomeIcon icon={faFileExcel} title="Download file" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

            {
                thisComponentState.toEdit ?

                    <div style={getModalStyle()}>

                        <div style={getModalContentStyle("250px","500px")}>

                            <input className='btn btn-primary' onClick={getEditableData} value='Back' />

                                <br />
                                <br />

                            <Detail />

                        </div>

                    </div>

                   : <div></div>
            }



        </div>

    )

}


