export default function getModalContentStyle(height,width) {

    let modalContent = {

        backgroundColor: "#fefefe",
        margin: "5% auto", /* 15% from the top and centered */
        padding: "20px",
        border: "1px solid #888",
        width: width === "" || typeof width === 'undefined' ? "90%" : width, /* Could be more or less, depending on screen size */
        height: height === "" || typeof height === 'undefined' ? "90%" : height,
        overflow: "auto",
        top: '50%',
        left: '50%',
        transform: 'translate("-50%","-50%")'

    }

    return modalContent;


}