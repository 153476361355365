import React, { useEffect, useState } from 'react';
import setMetaData from '../../Functions/setMetaData';
import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import AccountsPayableListNavigator from './AccountsPayableListNavigator';
import DetailsAccountsPayableNavigator from './DetailsAccountsPayableNavigator';
import CreateAccountsPayableNavigator from './CreateAccountsPayableNavigator';


export function AccountsPayableForm(props) {

    const transactionCode = "AccountsPayable";
    const postUrl = "/AccountsPayables/Create";
    const detailsUrl = "/AccountsPayables/GetDetails?id=";
    const editDetailUrl = "/AccountsPayables/EditItem";
    const listUrl = "/AccountsPayables/Index";
    const validatorUrl = "/AccountsPayables/Validate";

    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['100', '102', '103', '104', '105', '106', '107', '111', '112', '113', '114', '115', '116', '117', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132'],
        type: "P"
    };

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<AccountsPayableListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsAccountsPayableNavigator />} />);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, []);

    const additionalDetail = (<td> <input type="button" className="btn btn-danger" style={{ width: "100px" }} value="LPP"  /> </td>);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl} transactionCode={transactionCode} context={context} metaData={data.metaData} postUrl={postUrl} detailsUrl={detailsUrl} listUrl={listUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList}>

                <TransactionForm createNavigator={<CreateAccountsPayableNavigator previousTransactionGuidId={props.location.previousTransactionGuidId} />} detailsNavigator={<DetailsAccountsPayableNavigator />} transactionList={transactionList} additionalDetail={additionalDetail } />

            </TransactionContextProvider>

        )
    }

}

