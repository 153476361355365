import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import uuid from 'react-uuid';


export default function RelationshipMap(props) {

    const [thisComponentState, updateState] = useState({

        boxes: []

    });

    const canvasRef = useRef(null);

    useEffect(() => {
        fetch("StockRequisitions/GetRelationshipMap?guidId="+props.guidId)
            .then(respsone => respsone.status === "401" ? window.login = "/login" : respsone.json())
            .then(response => {

                if (canvasRef.current) {

                    let boxes = [...thisComponentState.boxes];

                    const canvasCoordinate = canvasRef.current.getBoundingClientRect();

                    let mainBoxXstart = 5;
                   
                    let mainBox = { ...response.box };

                    //The main box will start at the middle of the canvas.

                    mainBox.x = mainBoxXstart;
                    mainBox.y = (canvasCoordinate.bottom - canvasCoordinate.top) / 2-50;//Deduct 50 to center the box.
                    mainBox.id = uuidv4();

                    response.box = mainBox;

                    boxes.push(response.box);

                    //Let us create variables for positionings

                    let pRstartX = 205; //All pr will have the same starting in x.
                    let pRstartY = 5; //Will increment by 100 to position them as stack.

                    let pOstartX =405;
                    let pOstartY = 5;

                    let gRpOstartX =605;
                    let gRpOstartY = 5;

                    let aPstartX = 805;
                    let aPstartY = 5;

                    let oPstartX = 1005;
                    let oPstartY = 5;

                    let aPcMstartX = 1205;
                    let aPcMstartY = 5;


                    for (let i = 0; i < response.purchaseRequisitions.length; i++) {

           
                        response.purchaseRequisitions[i].box = {

                            x: pRstartX,
                            y: pRstartY,
                            lineStart: {
                                x: response.box.x + 100,
                                y: response.box.y + 50
                            },
                            lineEnd: {
                                x: pRstartX,
                                y: pRstartY + 50
                            },
                            id: uuidv4(),
                            parentId: response.box.id,
                            documentLabel: response.purchaseRequisitions[i].box.documentLabel,
                            documentDate: response.purchaseRequisitions[i].box.documentDate,
                            stat: response.purchaseRequisitions[i].box.stat,
                            reverse: response.purchaseRequisitions[i].box.reverse,
                            shouldNotifyChild: true,
                    
                        };

                        boxes.push(response.purchaseRequisitions[i].box);

                        //Increment pr
                        pRstartY = pRstartY + 200;

                      
                        for (let j = 0; j < response.purchaseRequisitions[i].purchaseOrders.length; j++) {

                       
                            response.purchaseRequisitions[i].purchaseOrders[j].box = {

                                x: pOstartX,
                                y: pOstartY,
                                lineStart: {
                                    x: response.purchaseRequisitions[i].box.lineEnd.x + 100,
                                    y: response.purchaseRequisitions[i].box.lineEnd.y
                                },
                                lineEnd: {
                                    x: pOstartX,
                                    y: pOstartY + 50
                                },
                                id: uuidv4(),
                                documentLabel: response.purchaseRequisitions[i].purchaseOrders[j].box.documentLabel,
                                documentDate: response.purchaseRequisitions[i].purchaseOrders[j].box.documentDate,
                                stat: response.purchaseRequisitions[i].purchaseOrders[j].box.stat,
                                reverse: response.purchaseRequisitions[i].purchaseOrders[j].box.reverse,
                                parentId: response.purchaseRequisitions[i].box.id,
                                shouldNotifyChild: true
                            };

                            boxes.push(response.purchaseRequisitions[i].purchaseOrders[j].box);

                            //Increment Po
                            pOstartY = pOstartY + 200;
                           
                            for (let k = 0; k < response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts.length; k++) {

                            
                                response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box = {

                                    x: gRpOstartX,
                                    y: gRpOstartY,
                                    lineStart: {
                                        x: response.purchaseRequisitions[i].purchaseOrders[j].box.lineEnd.x + 100,
                                        y: response.purchaseRequisitions[i].purchaseOrders[j].box.lineEnd.y
                                    },
                                    lineEnd: {
                                        x: gRpOstartX,
                                        y: gRpOstartY + 50
                                    },
                                    id: uuidv4(),
                                    documentLabel: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.documentLabel,
                                    documentDate: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.documentDate,
                                    stat: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.stat,
                                    reverse: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.reverse,
                                    parentId: response.purchaseRequisitions[i].purchaseOrders[j].box.id,
                                    shouldNotifyChild:true
                                };

                                boxes.push(response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box);

                                //Increment grpo

                                gRpOstartY = gRpOstartY + 200;

                                for (let m = 0; m < response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables.length; m++) {

                        
                                    response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box = {

                                        x: aPstartX,
                                        y: aPstartY,
                                        lineStart: {
                                            x: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.lineEnd.x + 100,
                                            y: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.lineEnd.y
                                        },
                                        lineEnd: {
                                            x: aPstartX,
                                            y: aPstartY + 50
                                        },
                                        id: uuidv4(),
                                        documentLabel: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.documentLabel,
                                        documentDate: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.documentDate,
                                        stat: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.stat,
                                        reverse: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.reverse,
                                        parentId: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].box.id,
                                        shouldNotifyChild: true
                                    };

                                    boxes.push(response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box);

                                    //Increment ap

                                    aPstartY = aPstartY + 200;
                                    
                                    //For outgoing payments

                                    for (let n = 0; n < response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments.length; n++) {

                                
                                        response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box = {

                                            x: oPstartX,
                                            y: oPstartY,
                                            lineStart: {
                                                x: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.lineEnd.x + 100,
                                                y: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.lineEnd.y
                                            },
                                            lineEnd: {
                                                x: oPstartX,
                                                y: oPstartY + 50
                                            },
                                            id: uuidv4(),
                                            documentLabel: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.documentLabel,
                                            documentDate: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.documentDate,
                                            stat: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.stat,
                                            reverse: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.reverse,
                                            parentId: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].box.id,
                                            shouldNotifyChild: true
                                        };

                                        boxes.push(response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box);

                                        //For credit memos
                                        oPstartY = oPstartY + 200;

                                        for (let s = 0; s < response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos.length; s++) {

                                            response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos[s].box = {

                                                x: aPcMstartX,
                                                y:aPcMstartY,
                                                lineStart: {
                                                    x: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.lineEnd.x + 100,
                                                    y: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.lineEnd.y
                                                },
                                                lineEnd: {
                                                    x: aPcMstartX,
                                                    y: aPcMstartY + 50
                                                },
                                                id: uuidv4(),
                                                documentLabel: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos[s].box.documentLabel,
                                                documentDate: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos[s].box.documentDate,
                                                stat: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos[s].box.stat,
                                                reverse: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos[s].box.reverse,
                                                parentId: response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].box.id,
                                                shouldNotifyChild: true
                                            };

                                            boxes.push(response.purchaseRequisitions[i].purchaseOrders[j].goodsReceipts[k].accountsPayables[m].outgoingPayments[n].apCreditMemos[s].box);

                                            //Increment ap cm
                                            aPcMstartY = aPcMstartY + 200;
                                        }
                                    }              
                                }
                            }
                        }
                    }

                    //Draw boxes for stock Transfers.

                    if (response.stockTransfers.length > 0) {

                        
                        for (let t = 0; t < response.stockTransfers.length; t++) {

                            response.stockTransfers[t].box = {

                                x: pRstartX,
                                y: pRstartY,
                                lineStart: {
                                    x: response.box.x + 100,
                                    y: response.box.y+50
                                },
                                lineEnd: {
                                    x: pRstartX,
                                    y: pRstartY+50
                                },
                                id: uuidv4(),
                                documentLabel: response.stockTransfers[t].box.documentLabel,
                                documentDate: response.stockTransfers[t].box.documentDate,
                                stat: response.stockTransfers[t].box.stat,
                                reverse: response.stockTransfers[t].box.reverse,
                                parentId: response.box.id,
                                shouldNotifyChild: false
                            };

                            boxes.push(response.stockTransfers[t].box);

                            pRstartY = pRstartY + 200;
                        }
                    }

                    let modified = mergeSameBox(boxes);

                    draw(modified);

                    console.log(modified);

                    updateState({ ...thisComponentState, boxes: modified });
                }
            });

    }, []);


    const mergeSameBox = (boxes) => {

        let tempData = [...boxes];

        let idOfDuplicatedBoxes = [];

        tempData.forEach(box => {

            let sameBoxes = tempData.filter(val => val.documentLabel === box.documentLabel);
            
            if (sameBoxes.length > 1)
            {
                let boxToRemain = { ...sameBoxes[0] };

                let multiplePreceedingBoxes = [];

                for (let b = 0; b < sameBoxes.length; b++) {
                
                    let parent = tempData.find(val => val.id === sameBoxes[b].parentId);
                   
                    if (typeof parent !== 'undefined')
                    {
                        let duplicates = tempData.filter(val => val.documentLabel === parent.documentLabel);

                        if (duplicates.length === 1)
                        {
                            let tempParent = { ...parent};

                            tempParent.lineEnd = {
                                x:boxToRemain.x,
                                y:boxToRemain.y
                            };

                            tempParent.lineStart = {
                                x: tempParent.lineEnd.x + 100,
                                y: tempParent.lineEnd.y
                            }

                            multiplePreceedingBoxes.push(tempParent);
                        };
                    };

                    if (boxToRemain.id !== sameBoxes[b].id) {
                        tempData = tempData.filter(val => val.id !== sameBoxes[b].id);
                    }
                }

                boxToRemain.multiplePrecedentLines = multiplePreceedingBoxes;

                tempData.push(boxToRemain);            
            }
        });

        idOfDuplicatedBoxes.forEach(id => {

            tempData = tempData.filter((val) => val.id!==id);

           
        });

        console.log(idOfDuplicatedBoxes);

        return tempData;

    }

    const handleMouseClick = (e) => {

        if (canvasRef.current) {

            const canvasCoordinate = canvasRef.current.getBoundingClientRect();

            for (let i = 0; i < thisComponentState.boxes.length; i++) {

                let box = thisComponentState.boxes[i];

                let x = e.clientX - canvasCoordinate.left;
                let y = e.clientY - canvasCoordinate.top;

                if (x > box.x && y > box.y && x < (box.x + 100) && y < (box.y + 100)) {

                    box.isClick = true;

                }
            }
        }
    }

    const handleMouseUp = (e) => {

        if (canvasRef.current) {

            let tempDatas = [...thisComponentState.boxes];

            for (let i = 0; i < tempDatas.length; i++) {

                let box = tempDatas[i];

                if (box.isClick) {

                    const canvasCoordinate = canvasRef.current.getBoundingClientRect();

                    let x = e.clientX - canvasCoordinate.left;
                    let y = e.clientY - canvasCoordinate.top;

                    box.x = x;
                    box.y = y;

                    if (typeof box.lineEnd !== 'undefined')
                    {
                        box.lineEnd.x = x;
                        box.lineEnd.y = y + 50;
                    };

                    //Inform change of line start to other boxes.

                    for (let j = 0; j < tempDatas.length; j++) {

                        let childBox = tempDatas[j];

                        if (box.id === childBox.parentId) {

                            childBox.lineStart.x = x + 100;
                            childBox.lineStart.y = y + 50;
                        };
                    };

                    if (typeof box.multiplePrecedentLines !== 'undefined') {

                        for (let k = 0; k < box.multiplePrecedentLines.length; k++) {

                            let item = box.multiplePrecedentLines[k];

                            item.lineEnd.x = x;
                            item.lineEnd.y = y + 50;
                        }
                    };

                    if (box.shouldNotifyChild) {

                        for (let j = 0; j < tempDatas.length; j++) {

                            let childBox = tempDatas[j];

                            if (typeof childBox.multiplePrecedentLines !== 'undefined' && childBox.multiplePrecedentLines.length > 0) {

                                for (let l = 0; l < childBox.multiplePrecedentLines.length; l++) {

                                    if (childBox.multiplePrecedentLines[l].id === box.id) {

                                        childBox.multiplePrecedentLines[l].lineStart.x = x + 100;
                                        childBox.multiplePrecedentLines[l].lineStart.y = y + 50

                                    }
                                }

                            }
                        };
                    }



                    box.isClick = false;

                    draw(tempDatas);
                }
            }
        }
    }

    const draw = (boxes) => {

        // Define the colors for the gradient
      

        if (canvasRef.current) {

            let ctx = canvasRef.current.getContext("2d");

            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

            for (let i = 0; i < boxes.length; i++) {

                ctx.font = "15px Arial";

                let box = boxes[i];

                ctx.beginPath();

                ctx.fillStyle = "lightgreen";
                ctx.fillRect(box.x, box.y, 100, 100);
                ctx.fillStyle = "black";
                ctx.fillText(box.documentLabel, box.x + 5, box.y + 15);
                ctx.fillText(box.documentDate, box.x + 5, box.y + 40);
                ctx.fillText(box.stat, box.x + 5, box.y + 70);

                if (typeof box.multiplePrecedentLines !== 'undefined') {

                    if (box.multiplePrecedentLines.length > 0) {

                        box.multiplePrecedentLines.forEach(val => {

                            ctx.moveTo(val.lineStart.x, val.lineStart.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                            ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y + 7);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);

                        });

                    } else {

                        if (typeof box.lineEnd !== 'undefined') {

                            if (!box.reverse) {

                                ctx.moveTo(box.lineStart.x, box.lineStart.y);
                                ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y);
                                ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                                ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                                ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y + 7);
                                ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);

                            } else {

                                ctx.moveTo(box.lineStart.x, box.lineStart.y);
                         
                                ctx.lineTo(box.lineEnd.x, box.lineEnd.y);

                            }                        
                                     
                        }
                    }
                } else {

                    if (typeof box.lineEnd !== 'undefined') {

                        if (!box.reverse) {

                            ctx.moveTo(box.lineStart.x, box.lineStart.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);
                            ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y + 7);
                            ctx.lineTo(box.lineEnd.x - 7, box.lineEnd.y - 7);

                        } else {

                            ctx.moveTo(box.lineStart.x, box.lineStart.y);
                            ctx.lineTo(box.lineStart.x+7,box.lineStart.y-7);
                            ctx.lineTo(box.lineStart.x + 7, box.lineStart.y + 7);
                            ctx.lineTo(box.lineStart.x, box.lineStart.y);
                            ctx.moveTo(box.lineStart.x+7, box.lineStart.y);
                            ctx.lineTo(box.lineEnd.x, box.lineEnd.y);
                        }                    
                    }
                }

                ctx.stroke();
            }
        }
    }

    return (
        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                    <div className="col">
                        <input type="button" value="Back" className="form-control" onClick={()=>props.back()}/>
                    </div>
                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                        <canvas
                            onMouseDown={handleMouseClick}
                            onMouseUp={handleMouseUp}
                            style={{ backgroundColor: "lightblue" }}
                            ref={canvasRef}
                            height={window.innerHeight - 25}
                            width={window.innerWidth - 25}
                        >
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    )
}