
import { useState } from "react";




export default function TakePictureOfFinishedJob(props) {

    const [thisComponentState, updateState] = useState({
        jobOrderId: props.jobOrderId,
        afterState: [],
        
    });

    const initializeVideo = () => {

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

            let video = document.getElementById('videoFeed');

            (function (video) {
                navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: mode } } }).then(function (stream) {
                    video.srcObject = stream;
                  
                    video.play();
                });

            })(video)

        }
    }


    const capture = () => {

        let video = document.getElementById('videoFeed');

        let canvas = document.createElement("canvas");

        canvas.width = 500;
        canvas.height = 500;

        let context = canvas.getContext('2d');

        context.drawImage(video, 0, 0, 500, 500);

        let base64Value = canvas.toDataURL();

        let tempData = [...thisComponentState.afterState];

        if (tempData.length >= 4) {

            return alert("Only 4 pictures are allowed.")
        }

        tempData.push(base64Value);

        updateState({ ...thisComponentState, afterState: tempData });
    }

   

    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    const clear = () => {

        updateState({ ...thisComponentState, afterState: [] });

    }

    return (
        <div>

            <div>
                <div>
                    <input type="button" onClick={() => props.back(thisComponentState.afterState)} className="form-control" value="Back" />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <video id="videoFeed" style={{ width: "100%", height: "100%" }} />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col text-center">
                    <input onClick={initializeVideo} type="button" className="form-control" value="Initialize" />
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col text-center">
                    <input onClick={capture} type="button" className="form-control" value="Add" />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col text-center" >
                    {thisComponentState.afterState.map((imageBase, index) => <img key={index} src={imageBase} alt="Trulli" width="500" height="500" />)}
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col text-center" >
                    <input onClick={clear} type="button" className="form-control" value="Clear" />
                </div>
            </div>

            <br />
        </div>
    )
}