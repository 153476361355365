import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCamera, faClone, faFile, faFileExcel, faFolderOpen, faHand, faList12, faPlus, faTable, faThumbtack, faTimes } from '@fortawesome/free-solid-svg-icons';

import React, {  useContext, useState } from 'react';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import { BarcodeScanner } from './ScanPO';
import ReceivedByTallMarks from './ReceivedByTallyMarks';
import ListOfOpenPO from './ListOfOpenPO';
import OnHandQty from '../../OnHandQty';
import Drafts from '../Drafts';
import { toCsvString } from '../../Functions/toCsvString';
import exportToCSV from '../../Functions/exportToCSV';
import ReferenceEntry from './ReferenceEntry';


export default function CreateGoodsReceiptNavigator(props) {

    const { data, updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        reviewPrice: false,
        isShowingPurchaseRequisitions: false,
        markForApproval: false,
        rawDetails: [],
        byTallyMarks:false,
        toScan: false,
        showListOfOpenPO: false,
        showDrafts: false,
        showOnHand: false,
        showReferenceEntry:false
    });


    const pullPurchaseOrder = (guidId) => {

        fetch("/GoodsReceipts/GetRequest?" + new URLSearchParams({

            guidId: guidId
          
        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);
          
                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, toScan: false, rawDetails: dataFromServer.rawDetails });

                   localStorage.removeItem("purchaseOrderGuidId");

                    return;
                }

                updateState({ ...thisComponentState, toScan: false });
                
                localStorage.removeItem("purchaseOrderGuidId");

                return alert("No data available.");

            })
    }



    const pullDuplicate = () => {

        var goodsReceiptGuidId = localStorage.getItem("goodsReceiptGuidId");

        if (goodsReceiptGuidId === null) {

            return alert("No goods receipt has been moved for duplication");
        }

        fetch("/GoodsReceipts/duplicate?" + new URLSearchParams({

            guidId: goodsReceiptGuidId


        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);

                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, rawDetails: dataFromServer.rawDetails });

                    return;
                }

                return alert("No data available.");

            })
    };


    const back = () => {

        updateState({ ...thisComponentState, toScan: false });
    }


    if (thisComponentState.toScan) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle('90%', '90%')}>
                    < BarcodeScanner pullFunction={pullPurchaseOrder} back={back} />
                </div>
            </div>
        )

    };


    const backToMainFromTallyMarks = () => {

        updateState({ ...thisComponentState, byTallyMarks: false })
    }

    const showTallyMarks = () => {

        updateState({ ...thisComponentState, byTallyMarks: true })
    }

    if (thisComponentState.byTallyMarks) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle('90%', '90%')}>
                    <ReceivedByTallMarks back={backToMainFromTallyMarks} rawDetails={thisComponentState.rawDetails} />
                </div>
            </div>
        )
    }





    const draftIt = () => {

        let value = data.metaData.parentProperties;

        value.details = data.metaData.detailProperties;

        let draft = {
            type: 4,
            value: JSON.stringify(value)
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Base/SaveDraft", {
            method: "post",
            body: JSON.stringify(draft),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    if (thisComponentState.showDrafts) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Drafts type={4} back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )
    }


    if (thisComponentState.showListOfOpenPO) {
        
        return (<ListOfOpenPO back={() => updateState({ ...thisComponentState, showListOfOpenPO: false })}/>)
    }

    if (thisComponentState.showOnHand) {

        <div style={getModalStyle()}>
            <div style={getModalContentStyle() }>

                <OnHandQty back={() => updateState({...thisComponentState,showOnHand:false}) }/>

            </div>
        </div>

    }


    const downLoadToExcel = () => {

        var csvString = toCsvString(data.metaData.detailProperties);
     
        exportToCSV("purchaseRequisitionDetails", csvString);
    }


    if (thisComponentState.showReferenceEntry)
    {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ReferenceEntry back={() => updateState({...thisComponentState,showReferenceEntry:false})}/>

                </div>
            </div>
                 
      )

    }

    return (
  
        <div style={{ float:'left', whiteSpace:'nowrap'}}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faHand} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({...thisComponentState,showOnHand:true})} />
            <FontAwesomeIcon icon={faClone} title="Duplicate" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={pullDuplicate} />
            <FontAwesomeIcon icon={faCamera} title="Scan" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={() => updateState({ ...thisComponentState, toScan: true })} />
            <FontAwesomeIcon icon={faTable} title="Received by tally marks" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={showTallyMarks}/>
            <FontAwesomeIcon icon={faArrowDown} title="Pull purchase orders" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={pullPurchaseOrder} />
            <FontAwesomeIcon icon={faTimes} title="Cancel goods receipts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={pullPurchaseOrder} />
            <FontAwesomeIcon icon={faList12} title="List of Open PO" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfOpenPO: true })} />
            <FontAwesomeIcon icon={faList12} title="List of PO to receive" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfOpenPO: true })} />
            <FontAwesomeIcon icon={faThumbtack} title="Reference entry" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState,showReferenceEntry: true })} />
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
            <FontAwesomeIcon icon={faFileExcel} title="Download to excel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

        </div>

    )
}


