import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = (props) => {

    const sigCanvas = useRef({});

    const clear = () => {
        sigCanvas.current.clear();
    };

    const save = () => {

        var isConfirm = window.confirm("Are you sure you want to save this cash advance?");

        if (!isConfirm) {

            return;
        };

        const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

        props.add(dataURL);

    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px'
    };

    const canvasStyle = {
        border: '1px solid #000',
        width: '300px',
        height: '200px'
    };

    const buttonStyle = {
        marginTop: '10px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer'
    };

    return (
        <div style={containerStyle}>

            <button onClick={()=>props.back()} style={buttonStyle}>Back</button>

           <br/>

            <SignatureCanvas
                penColor="black"
                canvasProps={{ style: canvasStyle }}
                ref={sigCanvas}
            />
            <button onClick={clear} style={buttonStyle}>Clear</button>
            <button onClick={save} style={buttonStyle}>Save</button>
        </div>
    );
};

export default SignaturePad;
