
import React from 'react';
import { useState } from 'react';
import uuid from 'react-uuid';
import exportToCSV from '../../Functions/exportToCSV';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';

export default function CommissaryProduction(props) {

    const [csvData, setCsvData] = useState([]);

    const [thisComponentState, updateState] = useState({

        productionDate: "",
        showMessages: false,
        messages:[]
     
    });


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const content = e.target.result;
                const rows = content.split('\n');

                if (rows.length > 0) {
                    const headers = rows[0].split(',');

                    const transformedData = rows.slice(1).map((row) => {
                        const values = row.split(',');

                        // Ensure the row has the expected number of values
                        if (values.length === headers.length) {
                            const obj = {};

                            headers.forEach((header, index) => {
                                obj[header.trim()] = values[index].trim();
                            });

                            return obj;
                        }

                        return null; // Ignore malformed rows
                    }).filter(Boolean); // Remove null entries

                    // Update state with the transformed data
                    setCsvData(transformedData);

                }
            };

            reader.readAsText(file);
        }
    };




    const save = async () => {

        let confirmation = window.confirm("Are you sure with your entries?");

        if (!confirmation)
        {
            return;
        }

        if (csvData.filter(val => val.itemCode!=="").map(val=>val.itemCode).length === 0) {

            return alert("No data to upload");
        }

  
        try {

        
            if (thisComponentState.productionDate === "") {
                return alert("Please select date.");
            }



            let toPost = {
                dateTime: thisComponentState.productionDate,
                value: csvData
            }


            updateState({ ...thisComponentState, isSaving: true });

             const response = await fetch("/Productions/SaveCommissaryProduction", {
                 method: "post",
                 body: JSON.stringify(toPost),
                 headers: {
                     'content-type': 'application/json; charset:UTF-8'
                 }
             });

            const data = await response.json();

            if (data.success) {

                updateState({ ...thisComponentState, isSaving: false, showMessage: true, messages: data.message });

            } else {

                updateState({ ...thisComponentState, isSaving: false});

                return alert(data.message);

            }

          
        } catch (error) {
            console.error('Error:', error);
        }
    };




    if (thisComponentState.showMessages) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    {
                     typeof thisComponentState.messages !=='undefined' &&  thisComponentState.messages.map(val =>

                            <div className="row">
                                <div className="col"> {val.key}   </div>
                                <div className="col"> {val.value}  </div>
                            </div>)
                    }

                </div>

            </div>
           
            )
    }

    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...This me take a while...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (


        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={()=> props.back()} />
                </div>

                <div className="col">
                    <input type="file" className="form-control" accept=".csv" onChange={handleFileChange} />
                </div>

                <div className="col">
                    <input type="button" className="form-control" value="Save" onClick={save}/>
                </div>

                <div className="col">
                    <input type="button" className="form-control" value="Template" onClick={()=> exportToCSV("ProductionUpload","itemCode,good,reject,bargain")} />
                </div>

             </div>

            <hr/>

            <div className="row">
                <div className="col">
                    Production Date
                </div>
                <div className="col">
                    <input type="date" value={thisComponentState.productionDate} onChange={(e) => updateState({...thisComponentState,productionDate:e.currentTarget.value})} className="form-control"/>
                </div>
            </div>


            <hr/>

            {csvData.length > 0 && (
     
                <table className='table table-bordered'>
                    <thead>
                        <th>Item Code</th>
                        <th>Good</th>
                        <th>Reject</th>
                        <th>Bargain</th>
                    </thead>

                    <tbody>

                        {csvData.map((item, index) => (
                            <tr key={uuid()} >
                                  <td>{item.itemCode }</td>
                                  <td>{item.good }</td>
                                  <td>{item.reject}</td>
                                  <td>{item.bargain}</td>
                              </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </div>
)}