
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function CycleCountDetails(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading:true,
        values: [],
        isChangingSchedule:false

    });


    useEffect(() => {

        fetch("/Crates/GetCycleCountDetails?" + new URLSearchParams({

            cycleCountId:parseInt(props.cycleCountId)
                
        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    values: response,
                    isLoading:false
                   
                });

            });
    }, []);



    const print = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/Crates/PrintSchedule?cycleCountId=";

        fetch(url+props.cycleCountId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isGeneratingPDF: false });

            });
    }

    const printCratesToWash = (id) => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/Crates/PrintCratesToWatch?id=";

        fetch(url +id,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isGeneratingPDF: false });

            });
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.isChangingSchedule) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Changing schedule...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const edit = (code) => {

        let tempDatas = [...thisComponentState.values];

        let tempData = tempDatas.find(x => x.code === code);

        tempData.toEdit = true;

        updateState({ ...thisComponentState, values: tempDatas });

    }


    const onChange = (id, value) => {

        updateState({ ...thisComponentState, isChangingSchedule: true });

        let postBody = {
            item1: id,
            item2: value
        };

        fetch("/Crates/ChangeSchedule", {

            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                value: postBody
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (!response.success) {

                    alert(response.message);

                }

                let tempDatas = [...thisComponentState.values];

                let tempData = tempDatas.find(x => x.id === id);

                tempData.formattedWashedDate = value;

                updateState({ ...thisComponentState, values: tempDatas,  isChangingSchedule: false });

                return;

            });
    }




    return (

        <div>

            <div>
                <div className="row">
                    <div className="col">
                        <input type='button' className="form-control" value="Back" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input type='button' className="form-control" value="Print" onClick={print} />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">

                            <thead className="thead-dark">
                                <tr>
                                    <th> Agent </th>
                                    <th> Washed Date </th>
                                    <th colSpan="2"> Action </th>

                                </tr>
                            </thead>

                            <tbody>

                                {
                                    thisComponentState.values.map(val =>
                                        <tr>
                                            <td> {val.agent}</td>

                                            <td>
                                                {
                                                    val.toEdit ? <input type="date" value={val.formattedWashedDate} onChange={(e)=>onChange(val.id,e.currentTarget.value)} className="form-control"/> : val.washedDate
                                                }
                                            </td>

                                            <td>
                                                <input value="Edit" type="button" className="form-control" onClick={()=> edit(val.code)}/>
                                            </td>

                                            <td>
                                                <input value="Print" type="button" className="form-control" onClick={() => printCratesToWash(val.id)} />
                                            </td>

                                        </tr>)
                                }

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>
        
   )
}