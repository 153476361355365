import React from 'react';
import { faArrowRight, faVolumeLow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState } from 'react';
import { useEffect } from 'react';

import SetTrip from './SetTrip'
import SchedulerViewer from '../../../SchedulerViewer';

export default function TripBooking(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: true,
        setSchedule: false,
        toReload :0

    });

    useEffect(() => {


    }, [thisComponentState.toReload]);

  
    if (thisComponentState.setSchedule)
    {
        return (<SetTrip back={() => updateState({ ...thisComponentState, setSchedule: false,toReload:thisComponentState.toReload+1})} />)
    }




    return (

        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div style={{ marginRight: '10px' }}>
                    <input className="form-control" disabled value="Trip Schedules" />
                </div>
                <div>
                    <input value="Set Schedule" type="button" style={{ width: "150px" }} className="form-control" onClick={() => updateState({ ...thisComponentState, setSchedule: true })} />
                </div>
            </div>


           <br/>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div >
                    <SchedulerViewer type="trip" url={"/trips/create?resource=vehicle"} toReload={() => updateState({...thisComponentState,toReload:thisComponentState.toReload+1})} />
                 </div>    
            </div>
        </div>  

    )
}