
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function MissingCrates(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading:true,
        values: [],
        isChangingSchedule:false

    });


    useEffect(() => {

        fetch("/Crates/GetMissingCratesPerWashed?" + new URLSearchParams({

           washedHistoryId:props.washedHistoryId
                
        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    values: response,
                    isLoading:false
                   
                });

            });
    }, []);



    const print = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/Crates/PrintMissingCrates?washedHistoryId=";

        fetch(url+props.washedHistoryId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isGeneratingPDF: false });

            });
    }

  

    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (

        <div>

            <div>
                <div className="row">
                    <div className="col">
                        <input type='button' className="form-control" value="Back" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input type='button' className="form-control" value="Print" onClick={print} />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">

                            <thead className="thead-dark">
                                <tr>
                                    <th> Crate Id </th>
                                    <th> Serial Number </th>
                       

                                </tr>
                            </thead>

                            <tbody>

                                {
                                    thisComponentState.values.map(val =>
                                        <tr>

                                            <td>{ val.crateId }</td>
                                            <td>{ val.serialNumber }</td>


                                        </tr>)
                                }

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>
        
   )
}