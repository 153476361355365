
import { left } from '@popperjs/core';
import React from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import PureInput from '../../PureInput';
import StandardAutoComplete from '../../StandardAutocomplete';
import BranchSelection from './BranchSelection';
import CategorySelection from './CategorySelection';


export default function Released(props) {

    const [thisComponentState, updateState] = useState({

        productionDate:(new Date()).toString("YYYY-MM-DD"),
        details: [],
        isSaving: false,
        saved: false,
        productionGuidIds: [],
        isGeneratingPDF: false,
        isGenerating:false,
        branchId:null
      
    });

    const [selectedCategories, updateSelectedCategories] = useState([]);

    const [selectedBranches, updateSelectedBranches] = useState([]);

    const [showCategorySelection, updateShowCategorySelection] = useState(false);

    const [showBranchSelection, updateShowBranchSelection] = useState(false);


    const getProductionPlanned = () => {

        var productionDate = thisComponentState.productionDate;

        if (productionDate === "" || typeof productionDate === 'undefined') {

            return alert("Please defined a date.");
        };

        if (selectedBranches.length == 0) {

            return alert("Please select atleast one branch.");
        }

        updateState({ ...thisComponentState, details: [],isGenerating:true });

        fetch("/Productions/GetProductionPlanned?" + new URLSearchParams({

            productionDate: productionDate,
            pCategories: selectedCategories.join(":"),
            pBranches: selectedBranches.join(":")

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.success) {

                    if (responseData.data.length == 0) {

                        updateState({ ...thisComponentState, isGenerating: false });

                        return alert ("No avaible data to produce.")
                    }

                    updateState({ ...thisComponentState, details: responseData.data,productionGuidIds:responseData.productionGuidIds,isGenerating:false });

                    return;

                } else {
   
                    updateState({ ...thisComponentState, isGenerating: false });

                    return;
                }
            });
    }


    const saved = () => {
        var reponse = window.confirm("Are you sure?");

        if (!reponse) {

            return;
        }

        let modifiedDetails = [];

        thisComponentState.details.forEach(category => {

            category.details.forEach(detail => {

                var newDetail = {

                    productionDate : thisComponentState.productionDate,
                    itemCode : detail.itemCode,
                    plannedQty: detail.plannedQty,
                    yieldPerMix:detail.yieldPerMix,
                    standardMix: detail.standardMix,
                    proposedMix: detail.proposedMix,
                    branchId : category.branchId
                }

                modifiedDetails.push(newDetail);

            });
        });

        var toPost = {

            productionPlanneds: modifiedDetails,
            productionGuidIds: thisComponentState.productionGuidIds

        };

        updateState({ ...thisComponentState, isSaving: true });

        fetch("/Productions/CreateProductionPlanned", {

            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(toPost),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(json => {

                updateState({...thisComponentState, isSaving: false,saved:true });

                
            });


    }

    const updateValue = (code, name, value) => {

        let tempData = [...thisComponentState.details];

        let finalValue = parseFloat(value);

        tempData.forEach(category => {

            category.details.forEach(data => {

                if (data.itemCode === code) {

                    data[name] = finalValue

                    var qty = data["quantity"];
                    var proposed = data["proposedMix"];
                    var standard = data[" standardMix"];

                    var parseQty = parseFloat(qty);
                    var parseProposed = parseFloat(proposed);
                    var parseStandard = parseFloat(standard);

                    data["quantity"] = parseProposed!==0 ? parseProposed*parseStandard : 
                    data["proposedMix"];

                    return;
                }

            })

        });

        updateState({ ...thisComponentState, details: tempData })

    }


    const preview = () => {

        var productionDate = thisComponentState.productionDate;

        if (selectedBranches.length == 0) {

            return alert("Please select atleast one branch.");
        }

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        fetch("/Productions/Preview?" + new URLSearchParams({

            productionDate: productionDate,
            pCategories: selectedCategories.join(":"),
            pBranches: selectedBranches.join(":")

        }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);

                window.open(file);

        });
    }
    

    const saving = () => {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }


    if (thisComponentState.isSaving) {

        return saving();
    }

    if (thisComponentState.isGenerating) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (showCategorySelection) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <CategorySelection selectedCategories={selectedCategories} updateSelectedCategories={updateSelectedCategories} updateShowCategorySelection={updateShowCategorySelection }/>

            </div>
        </div>)

    }


    if (showBranchSelection) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <BranchSelection selectedBranches={selectedBranches} updateSelectedBranches={updateSelectedBranches} updateShowBranchSelection={updateShowBranchSelection} />

            </div>
        </div>)

    }





    return (

        <div>

            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>


                    <div>

                        <div className="row">

                            <div className="col">
                                <input type="date" value={thisComponentState.productionDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, productionDate: e.currentTarget.value })}  />
                            </div>

                            <div className="col">
                               
                                <input value="Branch" onClick={() => updateShowBranchSelection(true)} className="form-control"  type="button" />

                            </div>

                            <div className="col">
                                <input value="Generate" onClick={getProductionPlanned} className="form-control"  type="button" />
                            </div>

                            <div className="col">
                                <input value="Back" onClick={() => props.updateState({ ...props.thisComponentState, createProductionPlanned: false })} className="form-control" type="button" />
                            </div>

                            <div className="col">
                                <input type='button' className="form-control"  value="Save" onClick={() => saved()} />
                            </div>

                            <div className="col">
                                <input type='button' className="form-control"  value="Preview" onClick={() => preview()} />
                            </div>

                        </div>

                    </div>

                    <br />
                    <br />


                    <div>

                        <table className="table table-bordered">

                            <thead className="thead-dark">
                     
                                <th style={{ position: 'sticky', top:0 }}>
                                    Description
                                </th>
                                <th style={{ position: 'sticky', top: 0 }}>
                                    Planned Qty
                                </th>

                                <th style={{ position: 'sticky', top: 0 }}>
                                    Yield Per mix
                                </th>
                                <th style={{ position: 'sticky', top: 0 }}>
                                    Standard Mix
                                </th>
                                <th style={{ position: 'sticky', top: 0 }}>
                                    Proposed Mix
                                </th>

                            </thead>

                            <tbody>

                                {
                                    thisComponentState.details.map(category =>

                                        <Fragment>

                                            <tr>
                                                <td>
                                                    {category.category}
                                                </td>
                                            </tr>

                                            {category.details.map((detail, trIndex) =>

                                                <tr>
                                                    <td style={{paddingLeft:'20px'}}>   {detail.name}       </td>
                                                    <td> {detail.plannedQty}</td>
                                                    <td> {detail.yieldPerMix} </td>
                                                    <td> {detail.standardMix.toFixed(2)} </td>
                                                    <td> {thisComponentState.saved ? detail.proposedMix : <PureInput type="number" style={{ width: "150px" }} value={detail.proposedMix} code={detail.itemCode} trIndex={trIndex} name="proposedMix" updateValue={updateValue} />} </td>                                              
                                                </tr>

                                            )}

                                        </Fragment>

                                    )

                                }


                            </tbody>

                          

                        </table>

                    </div>

                  
                </div>
            </div>
        </div>
    )

}

