import React from 'react';
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';


export default function NavMenu() {

    const [state, updateState] = useState({

        collapsed: true,
        liData: [],
        liContent: ["one", "two", "three"],
        isAuthenticated: false,
        showTest: false
    });

    useEffect(() => {

    }, []);

    const toggleNavbar = () => {
        updateState({ ...state, collapsed: !state.collapsed })
    };

    const mystate = useSelector((state) => state.action);

    const GetUserUi = () => {

        if (mystate === 0) {

            return (

                <NavItem>

                    <div className="dropdown">
                        <NavLink tag={Link} className="text-dark dropbtn" to="/" >
                            <div style={{ width: "100px", textAlign: "center" }}>
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                        </NavLink>

                        <div className="dropdown-content">
                            <NavLink tag={Link} className="text-dark" to="/LogIn">

                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Log In
                                </span>

                            </NavLink>
                        </div>

                    </div>

                </NavItem>
            )
        } else {

            return (

                <NavItem className="dropdown">
                    <NavLink tag={Link} className="text-dark dropbtn" to="/" >
                        <div style={{ width: "100px", textAlign: "center" }}>
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                    </NavLink>

                    <div className="dropdown-content">

                        <NavLink tag={Link} className="text-dark" to="/LogOut"> <span style={{ whiteSpace: 'nowrap' }}>Log Out</span> </NavLink>
                        <NavLink tag={Link} className="text-dark" to="/ChangePassword"> <span style={{ whiteSpace: 'nowrap' }}>Change Password</span>  </NavLink>
                        <NavLink tag={Link} className="text-dark" to="/ChatBoxContainer"> <span style={{ whiteSpace: 'nowrap' }}>Go Chat</span>  </NavLink>


                    </div>
                </NavItem>


            )
        }
    }




    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>

                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!state.collapsed} navbar>

                        <ul className="navbar-nav flex-grow">

                            <NavItem>
                                <NavLink tag={Link} className="text-dark dropbtn" to="/">Home</NavLink>
                            </NavItem>

                            <NavItem className="dropdown">
                                <NavLink tag={Link} className="text-dark dropbtn" to="/">Reports</NavLink>

                                <div className="dropdown-content">

                                    <NavLink tag={Link} className="text-dark" to="/PeriodOrderSummary">Period Order Summary</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/DailyInvoiceSummary">Daily Invoice Summary</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PeriodOrderSummary"> <span style={{ whiteSpace: 'nowrap' }}>Period Order Summary</span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/DailyInvoiceSummary">  <span style={{ whiteSpace: 'nowrap' }}> Daily Invoice Summary</span> </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PeriodInvoiceSummary">  <span style={{ whiteSpace: 'nowrap' }}> Period Invoice Summary  </span></NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/DailyProductionPlan">  <span style={{ whiteSpace: 'nowrap' }}>Daily Production Plan </span> </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/ProductionSummary">  <span style={{ whiteSpace: 'nowrap' }}>Production Summary   </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PushFormWithCustomer">  <span style={{ whiteSpace: 'nowrap' }}>Push Sheet </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/OrderSummary">  <span style={{ whiteSpace: 'nowrap' }}>Order Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/EndingInventory">     <span style={{ whiteSpace: 'nowrap' }}>Ending Inventory </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/BreadOutSummary">  <span style={{ whiteSpace: 'nowrap' }}>Bread Out Summary</span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/InventoryTransferSummary">   <span style={{ whiteSpace: 'nowrap' }}>Inventory Transfer Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/OnHandInventory">   <span style={{ whiteSpace: 'nowrap' }}>On Hand Inventory</span>   </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/ProdBreadOutRecon">  <span style={{ whiteSpace: 'nowrap' }}>Production Bread Out Reconciliation</span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/NotGoodSummary">   <span style={{ whiteSpace: 'nowrap' }}>Not Good Summary</span> </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/CratesToCharge">  <span style={{ whiteSpace: 'nowrap' }}>Crates </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PrSummary">  <span style={{ whiteSpace: 'nowrap' }}>Pr Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PrDetailSummary">  <span style={{ whiteSpace: 'nowrap' }}>Pr Detail Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PoSummary">  <span style={{ whiteSpace: 'nowrap' }}>Po Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/PoDetailSummary">  <span style={{ whiteSpace: 'nowrap' }}>Po Detail Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/ReturnSummary">  <span style={{ whiteSpace: 'nowrap' }}>Return Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/ReturnDetailSummary">  <span style={{ whiteSpace: 'nowrap' }}>Return Detail Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/BoSummary">  <span style={{ whiteSpace: 'nowrap' }}>Bad Order Summary </span>  </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/BoDetailSummary">  <span style={{ whiteSpace: 'nowrap' }}>Bad Order Detail Summary </span>  </NavLink>



                                </div>

                            </NavItem>

                            <NavItem>
                                <NavLink tag={Link} className="text-dark dropbtn" to="/">DashBoard</NavLink>
                            </NavItem>

                            <div className="dropdown">

                                <NavLink tag={Link} className="text-dark dropbtn" to="/">Masterfile</NavLink>

                                <div className="dropdown-content">

                                    <NavLink tag={Link} className="text-dark" to="/Categories">Categories</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Inventories">Inventories</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Agents">Agents</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Customers">Customers</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Vendors">Vendors</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Users">Users</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Warehouses">Warehouses</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Departments">Departments</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Employees">Employees</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Approvers">Approvers</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Branches">Branches</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/RecipientAssets">Recipient Assets</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/BillOfMaterials">Bill Of Materials</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/CauseOfBO">BO causes</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/UomGroups">Uom Groups</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/Uoms">Unit of Measures </NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/UomValues">Uom Values</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/IdentityClaim">Claims</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/RoleClaim">Roles</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/RoleAssignment">Role Assignments</NavLink>
                                    <NavLink tag={Link} className="text-dark" to="/CreateCrates">Crates</NavLink>

                                </div>
                            </div>
                            <NavItem>

                                <div className="dropdown">

                                    <NavLink tag={Link} className="text-dark dropbtn" to="/" >Transactions</NavLink>

                                    <div className="dropdown-content">
                                        <NavLink tag={Link} className="text-dark" to="/Administration"  >Administration & Logistics</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/PerformanceHome" >Performance Evaluation</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/Order">Order</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/Production">Production</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/BreadOut">Bread Out</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/Invoice"  >Invoice</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/Return" >Returns</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/BadOrder" > Bad Order</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/ArCreditMemo"  >Ar Credit Memo</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/AccountsPayable"> <span style={{ whiteSpace: 'nowrap' }} >Accounts Payable </span>    </NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/ApCreditMemo"> <span style={{ whiteSpace: 'nowrap' }} >Ap Credit Memo </span>    </NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/OutgoingPayment"> <span style={{ whiteSpace: 'nowrap' }} >Outgoing Payments </span>    </NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/SelectTypeRequest">Request</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/StockTransfer"  >Stock Transfer</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/PurchaseRequisition"  >Purchase Requisition</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/PurchaseOrder" >Purchase Order</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/GoodsReceipt"  >Goods Receipt</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/GoodsIssue"  >Goods Issue</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/Replenishment"  >Replenishment</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/WashedCrates"  >Washed Crates</NavLink>
                                        <NavLink tag={Link} className="text-dark" to="/CashAdvance"  >Cash Advance</NavLink>
                                    </div>
                                </div>

                            </NavItem>

                            {GetUserUi()}

                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );

}


