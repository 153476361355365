import React, {Fragment, useEffect, useState } from 'react';

import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import CreatePurchaseRequisitionNavigator from './CreatePurchaseRequisitionNavigator';
import DetailsPurchaseRequisitionNavigator from './DetailsPurchaseRequisitionNavigator';
import PurchaseRequisitionListNavigator from './PurchaseRequisitionListNavigator';

export default function PurchaseRequisitionForm(props) {

    const transactionCode = "PurchaseRequisition";
    const postUrl = "/PurchaseRequisitions/Create";
    const detailsUrl = "/PurchaseRequisitions/Details?id=";
    const editDetailUrl = "/PurchaseRequisitions/EditItem";
    const deleteDetailUrl = "/PurchaseRequisitions/DeleteItem";
    const listUrl = "/PurchaseRequisitions/Index";
    const validatorUrl = "/PurchaseRequisitions/Validate";
    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['100', '102', '103', '104', '105', '106', '107', '111', '112', '113', '114', '115', '116', '117', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132'],
        type: "PR"

    };

    const transactionList = (<TransactionList state={{ listUrl: listUrl,deleteDetailUrl:deleteDetailUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<PurchaseRequisitionListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsPurchaseRequisitionNavigator />} />);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData, props.location.previousTransactionGuidId);

    }, [])

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl} transactionCode={transactionCode} context={context} metaData={data.metaData} postUrl={postUrl} deleteDetailUrl={deleteDetailUrl} detailsUrl={detailsUrl} listUrl={listUrl} transactionList={transactionList} editDetailUrl={editDetailUrl} functions={[]} >

                <TransactionForm createNavigator={<CreatePurchaseRequisitionNavigator/>} detailsNavigator={<DetailsPurchaseRequisitionNavigator />}  transactionList={transactionList } />

            </TransactionContextProvider>

        )
    }

}

