
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decNum } from '../../ActionCreator';
import Home from '../Home';

export default function Logout() {

    const [thisComponentState, updateState] = useState({

        isLoggingOut : false
           
    });

    const mystate = useSelector((state) => state.action);

    const dispatch = useDispatch();

    useEffect(() => {

        updateState({...thisComponentState,isLoggingOut:true})

        fetch('/Identity/Logout', {

            // Adding method type
            method: "POST",

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(ok => {

                if (ok) {

                    updateState({ ...thisComponentState, isLoggingOut: false })

                    dispatch(decNum());

                    window.location.href = "/Home"

                } else {

                    return alert("Something wrong happen while logging out.");
                };

            })

    }, []);


    if (thisComponentState.isLoggingOut) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Logging out...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (<Home/>)

}