import { useEffect } from "react";
import { useState } from "react";




export default function InvoiceDetails(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: {}
    });


    useEffect(() => {

        fetch("/Invoices/Details?" + new URLSearchParams({

            id: props.guidId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })

    }, []);



    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    return (

        <div>

            <div className="row">

                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                </div>

                <div className="col">
                </div>

                <div className="col">                 
                </div>

                <div className="col">
                </div>

            </div>

            <br />

            <div className="row">
                <div className="col">
                    <div>Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.date}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Branch</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.branch}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Doc Total</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.docTotal}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Created By</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.createdBy}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Upload Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.uploadDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.note}</div>
                </div>
            </div>

          
            <br />

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>

                            <tr>

                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Uom Desc</th>
                                <th>Price</th>
                                <th>Amount</th>

                            </tr>

                        </thead>

                        <tbody>

                            {
                              typeof thisComponentState.data !=='undefined' &&  thisComponentState.data.details.map(val =>
                                    <tr key={val.guidId}>

                                        <td>{val.name}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>
                                        <td>{val.total}</td>

                                    </tr>
                                )
                            }

                        </tbody>

                        <tfoot>

                            <tr>
                                <td>Grand Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{thisComponentState.data.docTotal }</td>


                            </tr>

                        </tfoot>



                    </table>
                </div>
            </div>
        </div>

    )
}