import React, { Children, isValidElement, useEffect, useState } from 'react';
import { MasterfileContext } from './MasterfileContext';


function MasterfileContextProvider(props) {

    const [data, updateMetaData] = useState({

        metaData: {

            parentMetaData: props.metaData.parentMetaData,
            parentProperties: props.metaData.parentProperties,
            multipleParentProperties:props.metaData.multipleParentProperties,
            details: [],
            isDetailsLoaded: false,
            deleteUrl : "",
            editUrl : "",
            postUrl: props.postUrl,
            listUrl: props.listUrl,
            initialDetailsUrl: props.initialDetailsUrl,
            isFormSubmitted: false
        }
    });


    function updateParentValue(name,value) {

        const tempData = { ...data.metaData };

        tempData.parentProperties[name] = value;

        updateMetaData({ metaData: tempData });

    };


        function deleteItem(guidId) {

            fetch(data.metaData.deleteUrl, {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: guidId,

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })

                // Converting to JSON
                .then(response => response.status ===401 ? window.location = "/login" : response.json())

                // Displaying results to console
                .then(json => {

                    if (json.success) {

                        const tempData = { ...data.metaData };

                        tempData.details =json;

                        updateMetaData({ metaData: tempData });

                    } else {

                        alert("something went wrong....");
                    }
                });
        }



    return (

        <MasterfileContext.Provider value={{ data, updateMetaData, updateParentValue, deleteItem}}>

            {props.children}

        </MasterfileContext.Provider>

       
    )

}


export default MasterfileContextProvider;