
import React, {  useState } from 'react';
import uuid from 'react-uuid';
import StandardAutoComplete from '../../StandardAutocomplete';
import DailyDiesel from './DailyDiesel';


export default function ListOfDieselRequest(props) {

    const [thisComponentState, updateState] = useState({
        values: props.values,
        showDetails: false,
        showDailyDiesel:false
        
    });


    if (thisComponentState.showDailyDiesel) {

        return (<DailyDiesel back={() => updateState({...thisComponentState,showDailyDiesel:false}) }/>)


    }


    const deleteItem = () => {



    }


    return (

        <div>
            <div>

                <div className="row">
                    <div className="col">
                        <input value="Back" className="form-control" type="button" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input value="Daily Diesel" className="form-control" type="button" onClick={() => updateState({...thisComponentState,showDailyDiesel:true})  } />
                    </div>
                </div>

                <hr />

                <div className='row'>
                    <div className='col'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th colSpan="2">Action</th>
                                    <th>Purchase Requisition Id</th>
                                    <th>Requestor</th>
                                    <th>Request Date</th>
                                    <th>Required Date</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    thisComponentState.values.map((val) =>

                                        <tr key={uuid()}>

                                            <td>
                                                <input type='button' value='Delete' className='form-control' onClick={() => deleteItem(val.documentId)} />
                                            </td>

                                            <td>
                                                <input type='button' value='Details' className='form-control' onClick={() => deleteItem(val.documentId)} />
                                            </td>
                                            <td>{val.documentId}</td>
                                            <td>{val.requestor} </td>
                                            <td>{val.requestDate}</td>
                                            <td>{val.requiredDate} </td>
                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}



