
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import Details from './Details';

export default function ListCashAdvances(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: true,
        values: [],
        showDetails: false,
        cashAdvance: {}
       
    });

    useEffect(() => {

        fetch("/CashAdvances/GetList")
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    values: response,
                    isPerformingOperation: false,
                    showDetails:false

                });

            });
    }, []);

  

    const deleteItem =(id) => {

        let confirm = window.confirm("Are you sure you want to delete this? This action cannot be undone.\nPlease click ok to continue.");

        if (!confirm) {
            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/CashAdvances/Delete", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                value:id
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation:false});

                return alert(response.message);

            });
    }


    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }




    if (thisComponentState.showDetails) {

        return (
            <Details guidId={thisComponentState.guidId} cashAdvance={thisComponentState.cashAdvance} back={() => updateState({...thisComponentState,showDetails:false})}/>
            )
    }



    return (

        <div>
            <div>

                <div className="row">                  
                    <div className="col">
                        <input value="Back" className="form-control" type="button" onClick={() => props.back()} />
                    </div>

                    <div className="col">

                    </div>
                </div>

                <hr/>


                <div className='row'>

                    <div className='col'>
                        <table className='table table-bordered'>

                            <thead>

                                <tr>
                                    <th style={{ whiteSpace: 'nowrap' }} className="text-center" colSpan="2">Action</th>
                                    <th style={{whiteSpace:'nowrap'}}>Document Id </th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Request Date</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Employee</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Department</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Amount</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Purpose</th>
                                    <td style={{ whiteSpace: 'nowrap' }}>Approved By</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>Approval Date</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>Liquidation Date</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>Liquidated</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>Is Cancelled</td>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    thisComponentState.values.map((val) =>

                                        <tr key={uuid()}>
                                          
                                            <td>
                                                <input type='button' value='Delete' className='form-control' onClick={() => deleteItem(val.guidId)} />
                                            </td>
                                            <td>
                                                <input type='button' value='Details' className='form-control' onClick={() => updateState({ ...thisComponentState, showDetails: true,cashAdvance:val})}/>
                                            </td>
                                            <td>{val.documentId}</td>
                                            <td>{val.requestDate}</td>
                                            <td>{val.employeeName} </td>
                                            <td>{val.department}</td>
                                            <td>{val.amount} </td>
                                            <td>{val.purpose}</td>
                                            <td>{val.approvedBy}</td>                                                
                                            <td>{val.approvalDate}</td>
                                            <td>{val.liquidationDate}</td>
                                            <td>{val.liquidated}</td>
                                            <td>{val.isDeleted ? "Y" : "N"}</td>
                                            
                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>  
        
        
  )

}