import React, { useEffect, useState } from 'react';
import setMetaData from '../../Functions/setMetaData';
import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import OutgoingPaymentListNavigator from './OutgoingPaymentListNavigator';
import DetailsOutgoingPaymentNavigator from './DetailsOutgoingPaymentNavigator';
import CreateOutgoingPaymentNavigator from './CreateOutgoingPaymentNavigator';


export function OutgoingPaymentForm(props) {

    const transactionCode = "OutgoingPayment";
    const postUrl = "/OutgoingPayments/Create";
    const detailsUrl = "/OutgoingPayments/GetDetails?id=";
    const editDetailUrl = "/OutgoingPayments/EditItem";
    const listUrl = "/OutgoingPayments/Index";
    const validatorUrl = "/OutgoingPayments/Validate";

    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['100', '102', '103', '104', '105', '106', '107', '111', '112', '113', '114', '115', '116', '117', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132'],
        type: "P"
    };

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<OutgoingPaymentListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsOutgoingPaymentNavigator />} />);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, []);

    const additionalDetail = (<td> <input type="button" className="btn btn-danger" style={{ width: "100px" }} value="LPP"  /> </td>);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl} transactionCode={transactionCode} context={context} metaData={data.metaData} postUrl={postUrl} detailsUrl={detailsUrl} listUrl={listUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList}>

                <TransactionForm createNavigator={<CreateOutgoingPaymentNavigator previousTransactionGuidId={props.location.previousTransactionGuidId} />} detailsNavigator={<DetailsOutgoingPaymentNavigator />} transactionList={transactionList} additionalDetail={additionalDetail } />

            </TransactionContextProvider>

        )
    }

}

