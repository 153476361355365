import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react"
import uuid from "react-uuid";
import StandardAutoCompleteForPOSplit from "./StandardAutocompleteForPOSplit"

const { useState } = require("react")

export default function EditPurchaseOrder(props)
{
    const [thisComponentState, updateState] = useState({

        guidId: props.guidId,
        docEntry:null,
        lineItems: [],
        isSaving: false,
        reload:0,
        grandTotal: function () {

            let grandTotal = 0;

            this.lineItems.forEach(val => {

                grandTotal += val.quantity*val.price 

            });

            return grandTotal;

        }

    })

    useEffect(() => {

        fetch("/PurchaseOrders/GetEditableData?" + new URLSearchParams({

            guidId: thisComponentState.guidId

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                var tempData = { ...thisComponentState };

                tempData.guidId = response.guidId;
                tempData.docEntry = response.docEntry;

                tempData.lineItems = response.lineItems;

                updateState(tempData);

            });

    }, []);

    const updateBranch = (branchId, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.branchId = branchId;

        updateState({ ...thisComponentState, lineItems: tempDatas });

    }

    const updateDepartment = (departmentId, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.deptId = departmentId;

        updateState({ ...thisComponentState, lineItems: tempDatas });
    }


    const updateWarehouse = (warehouseCode, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.warehouseCode = warehouseCode;

        updateState({ ...thisComponentState, lineItems: tempDatas });
    }

    const updateItem = (itemCode, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.code = itemCode;

        updateState({ ...thisComponentState, lineItems: tempDatas, reload: thisComponentState.reload + 1 });

    }

    const updateQuantity = (quantity, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        if (Number.isNaN(parseFloat(quantity))) {

            return;
        }

        tempData.quantity =parseFloat(quantity);

        updateState({ ...thisComponentState, lineItems: tempDatas });
    }

    const updateDescription = (description, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData.description = description;

        updateState({ ...thisComponentState, lineItems: tempDatas });
    }

    const updateUomEntry = (uomEntry, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        if (Number.isNaN(parseInt(uomEntry))) {

            return;
        }

        tempData.uomEntry =parseInt(uomEntry);

        updateState({ ...thisComponentState, lineItems: tempDatas });
    }

    const updatePrice = (price, guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        if (Number.isNaN(parseFloat(price))) {

            return;
        }

        tempData.price =parseFloat(price);

        updateState({ ...thisComponentState, lineItems: tempDatas });
    }


    const clear = (guidId,name) => {

        var tempDatas = [...thisComponentState.lineItems];

        var tempData = tempDatas.find(item => item.guidId === guidId);

        tempData[name] = "";

        updateState({ ...thisComponentState, lineItems: tempDatas});
    }


    const save = () => {

        if (thisComponentState.lineItems.length === 0) {

            return alert("Details are required...");
        }

        //modify some properties

        thisComponentState.lineItems.forEach(val => {

            val.uomEntry = parseInt(val.uomEntry);
            val.quantity = parseFloat(val.quantity);
            val.price = parseFloat(val.price);

        });

        updateState({ ...thisComponentState, isSaving: true });

        fetch("/PurchaseOrders/EditPO", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(json => {

                return alert(json.message);

            });
    }


    const addRow = () => {

        let tempDatas = [...thisComponentState.lineItems];


        let toAdd = {
            branchId: "",
            deptId: "",
            warehouseCode: "",
            code: "",
            description: "",
            quantity: 0,
            uomEntry: "",
            price: 0,
            guidId:uuid()

        }


        tempDatas.push(toAdd);

        updateState({ ...thisComponentState, lineItems: tempDatas });

    }

    const deleteRow = (guidId) => {

        var tempDatas = [...thisComponentState.lineItems];

        var filtered = tempDatas.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, lineItems: filtered });
    }


    return(

        <div>

            <div className='row'>
                
                <div className='col'>
                    <input value='Back'  type="button" className='form-control' onClick={()=>props.back()} />
                </div>

                <div className='col'>
                    <input value='Save' type="button" className='form-control' onClick={save} />
                </div>

                <div className='col'>
                
                </div>
                <div className='col'>
                   
                </div>


            </div>

            <br/>

            <div className='row'>

                <div className='col'>

                    <table className='table table-bordered'>

                        <thead>

                            <tr>
                                <td>Action</td>

                                <td>Branch</td>

                                <td>Department</td>

                                <td>Warehouse</td>

                                <td>Item</td>

                                <td>Description</td>

                                <td>Quantity</td>

                                <td>Uom</td>

                                <td>Price</td>

                                <td>Amount</td>

                            </tr>

                        </thead>

                        <tbody>

                            {
                                thisComponentState.lineItems.map(val =>

                                    <tr>

                                        <td className="col-lg-1">

                                            <div className="row">

                                                <div className='col'>
                                                    <FontAwesomeIcon icon={faPlus}  size='1x' color='green' onClick={addRow} style={{ cursor: 'pointer' }} />
                                                </div>

                                                <div className='col'>
                                                    <FontAwesomeIcon icon={faTrash} size='1x' color='red' onClick={() => deleteRow(val.guidId)} style={{ cursor: 'pointer' }} />
                                                </div>
                                             
                                            </div>

                                        </td>


                                        <td className="align-middle">
                                            <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetBranches" clear={()=> clear(val.guidId,"branchId") }  code={val.guidId} value={val.branchId} updateValue={updateBranch} />
                                        </td>

                                        <td className="align-middle">
                                            <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetDepartments" clear={()=>clear(val.guidId,"deptId") } code={val.guidId} value={val.deptId} updateValue={updateDepartment} />
                                        </td>

                                        <td className="align-middle">
                                            <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetWarehouses" clear={()=>clear(val.guidId,"warehouseCode") } code={val.guidId} value={val.warehouseCode} updateValue={updateWarehouse} />
                                        </td>

                                        <td className="align-middle">
                                            <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetInventories" clear={()=>clear(val.guidId,"code") } code={val.guidId} value={val.code} updateValue={updateItem} />
                                        </td>

                                        <td>
                                            <input type='text' className="form-control" value={val.description} onChange={(e) => updateDescription(e.currentTarget.value, val.guidId)} />
                                        </td>

                                        <td>
                                            <input  type="number" className="form-control" value={val.quantity} onChange={(e) => updateQuantity(e.currentTarget.value, val.guidId)}  />
                                        </td>

                                        <td className="align-middle">
                                            <StandardAutoCompleteForPOSplit url="PurchaseOrders/GetUomValuesByCode" mountCounter={thisComponentState.reload} clear={()=>clear(val.guidId,"uomEntry") } code={val.guidId} params={{ code: val.code }} value={val.uomEntry} updateValue={updateUomEntry} />
                                        </td>

                                        <td>
                                            <input type="number" className="form-control" value={val.price} onChange={(e) => updatePrice(e.currentTarget.value, val.guidId)} />
                                        </td>

                                        <td>
                                            {
                                              !isNaN(val.quantity) && !isNaN(val.price) && val.quantity*val.price
                                            }
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>


                        <tfoot>
                            <tr>
                                <td>Grand Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    {
                                        thisComponentState.grandTotal()
                                    }
                                </td>

                            </tr>

                        </tfoot>


                    </table>

                </div>
                
            </div>

       </div>

   
   )

}