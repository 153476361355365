import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import PureInput from "../../PureInput";
import StandardAutoComplete from "../../StandardAutocomplete";
import { TransactionContext } from "../TransactionContext";

export default function Service(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isSaving: false,
        details: [],
        reload:0

    });

    useEffect(() => {

        fetch("/Invoices/GetChartOfAccountsAsInventories")
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                let tempData = { ...data.metaData };

                tempData.inventories = response;

                updateMetaData({ metaData: tempData });

            });


    }, [thisComponentState.reload]);


    const updateCode = (value, guidId) => {

        let tempDatas = [...thisComponentState.details];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.code = value;

        updateState({ ...thisComponentState, details: tempDatas});

    }



    const onUpdate = (guidId, name, value) => {

        let tempDatas = [...thisComponentState.details];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        if (name === "price") {

            value = parseFloat(value);
        }

        tempData[name] = value;

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const add = () => {

        let tempDatas = [...thisComponentState.details];

        tempDatas.push({ id:0,guidId: uuid(), code: "",quantity:1,price:0});

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const deleteItem = (guidId) => {

        let tempDatas = [...thisComponentState.details];

        tempDatas = tempDatas.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, details: tempDatas });
    }

    const save = () => {

        var isConfirm = window.confirm("Are you sure you want to save this?");

        if (thisComponentState.positionId === "") {
            return alert("Please define position.");
        };

        if (thisComponentState.mainPerformanceFactorId === "") {
            return alert("Please define main factor.");
        }


        if (!isConfirm) {

            return;
        };

        let postBody = {

            positionId: parseInt(thisComponentState.positionId),
            mainPerformanceFactorId: parseInt(thisComponentState.mainPerformanceFactorId),
            weight: parseFloat(thisComponentState.weight),
            subPerformanceFactors: thisComponentState.details.map(val => ({

                positionId: parseInt(thisComponentState.positionId),
                mainPerformanceFactorId: parseInt(thisComponentState.mainPerformanceFactorId),
                name: val.name,
                description: val.description

            }))
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/SavePerformanceFactorPerPosition", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {


                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                if (!response.success) {

                    return alert(response.message);
                };

                updateState({ ...thisComponentState, isPerformingOperation: false })

           
            });
    }


    const ok = () => {

        var tempData = { ...data.metaData };

        for (var i = 0; i < thisComponentState.details.length; i++) {

            var detail = {};

            detail.code = thisComponentState.details[i].code;
            detail.quantity = thisComponentState.details[i].quantity;
            detail.uomEntry = "-1";
            detail.price = thisComponentState.details[i].price;

            tempData.detailProperties.push(detail);

        }

        //Set as service

        tempData.parentProperties.isService = true;

        //toShowProperty

        var presentationValue = {

            toShow: true,
            categoryId: ""
        };

        tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

        updateMetaData({ metaData: tempData });

        props.back();

    }


    return (
        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />
                </div>
              
            </div>

          

            <hr />
            <div className="row">
                <div className="col-md-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="col-md-1" colSpan="2">Action</th>
                                <th className="col-md-1">Account</th>
                                <th className="col-md-1">Value</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.details.length > 0 && thisComponentState.details.map((val, index) =>
                                    <tr key={val.guidId}>
                                        <td >
                                            <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => deleteItem(val.guidId)} />
                                        </td>

                                        <td >
                                            <div>
                                                {index + 1}
                                            </div>
                                        </td>

                                        <td >
                                            <div>
                       
                                                <StandardAutoComplete url="Generics/GetChartOfAccounts" rowIdentifier={val.guidId} value={val.code} updateValue={updateCode} />

                                            </div>
                                        </td>
                                        <td >
                                            <div>
                                                <PureInput type="text" value={val.price} code={val.guidId} name="price" updateValue={onUpdate} />
                                            </div>
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>

                        <tfoot>
                            <tr>

                                <td>
                                    <input type="button" value="Add" className="form-control" onClick={add} />
                                </td>

                                <td>
                                    <input type="button" value="Ok" className="form-control" onClick={ok} />
                                </td>

                                <td>

                                </td>
                                <td>

                                </td>
                            </tr >

                        </tfoot>

                    </table>

                </div>
            </div>

        </div>


    )
}