import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnchor,  faCut, faFile, faFileExcel, faFolderOpen, faGasPump, faHand, faHistory, faList12, faMoneyBill, faPlus, faSearch, faTasks, faUserPlus, faWindowRestore, faWrench } from '@fortawesome/free-solid-svg-icons';
import React, {  useContext,  useState } from 'react';

import { TransactionContext } from '../TransactionContext';
import ReviewPrice from './ReviewPrice';
import FindPOByVendor from './FindPOByVendorjs';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import RawMatSummaryRequest from './RawMatSummaryRequest';
import SplitPO from './SplitPO';
import PoOwnCapture from './PoOwnCapture';
import ListOfOpenPR from './ListOfOpenPR';
import AssignTask from './AssignTask';
import CreatePoForDieselRequest from './CreatePoForDieselRequest';
import OnHandQty from '../../OnHandQty';
import Drafts from '../Drafts';
import RequestedPrForClosure from './RequestedPrForClosure';
import ItemPriceHistory from './ItemPriceHistory';
import SetDefaultPurchasingUom from './SetDefaultPurchasingUom';
import ListOfOpenPrForAudit from './ListOfOpenPrForAudit';
import { toCsvString } from '../../Functions/toCsvString';
import exportToCSV from '../../Functions/exportToCSV';
import OnHandInventory from '../../Reports/OnHandInventory';



export default function CreatePurchaseOrderNavigator(props) {

    const { data,updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        reviewPrice: false,
        isShowingPurchaseRequisitions: false,
        isPerformingOperation:false,
        markForApproval: false,
        searchBySupplier: false,
        showRawMatSummary: false,
        splitPO: false,
        showDrafts: false,
        showPOOwnCapture: false,
        showListOfOpenPR: false,
        showDieselRequest:false,
        assignTask: false,
        uploadFile: false,
        showOnHand: false,
        showListOfOpenPRForClosure: false,
        showListOfOpenPRForAudit:false,
        showItemPriceHistory: false,
        SetDefaultPurchasingUom: false,
        showPgRm:false
        
    });

    const draftIt = () => {

        let value = data.metaData.parentProperties;

        value.details = data.metaData.detailProperties;

        let draft = {
            type: 3,
            value: JSON.stringify(value)
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Base/SaveDraft", {
            method: "post",
            body: JSON.stringify(draft),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }

    const openDrafts = () => {

        updateState({ ...thisComponentState, showDrafts: true });
    }

    if (thisComponentState.reviewPrice)
    {
        return <ReviewPrice updateState={updateState} />
    };

    if (thisComponentState.searchBySupplier)
    {
        return <FindPOByVendor back={() => updateState({...thisComponentState,searchBySupplier:false})} />
    }

    if (thisComponentState.showRawMatSummary) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <RawMatSummaryRequest back={() => updateState({...thisComponentState,showRawMatSummary:false}) } />
                </div>
            </div>
        )
    }


    if (thisComponentState.splitPO) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <SplitPO values={data.metaData.detailProperties} back={() => updateState({...thisComponentState,splitPO:false})} />
                </div>            
            </div>
           )
    }


    if (thisComponentState.showDrafts) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Drafts type={3} back={() => updateState({ ...thisComponentState, showDrafts: false })} />

                 

                </div>
            </div>
        )
    }


    if (thisComponentState.showPOOwnCapture) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle() }>
                        <PoOwnCapture back={() => updateState({...thisComponentState, showPOOwnCapture:false})}/>
                </div>
            </div>
            )
    }

    if (thisComponentState.showListOfOpenPR) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <ListOfOpenPR back={() => updateState({ ...thisComponentState, showListOfOpenPR: false })} />
                </div>
            </div>
        )
    }

    if (thisComponentState.assignTask) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <AssignTask back={() => updateState({ ...thisComponentState,assignTask: false })} />
                </div>
            </div>
        )
    }

    if (thisComponentState.showDieselRequest) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle() }>
                    <CreatePoForDieselRequest back={() => updateState({ ...thisComponentState, showDieselRequest: false })} />
                    </div>
                
                </div>           
        )
    }

    if (thisComponentState.showOnHand) {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <OnHandInventory
                        back={() => updateState({ ...thisComponentState, showOnHand: false })}
                    />
                </div>
            </div>
        )
    }


    if (thisComponentState.showListOfOpenPRForClosure) {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <RequestedPrForClosure back={() => updateState({...thisComponentState,showListOfOpenPRForClosure:false})}/>
                    
                </div>
            </div>
        )
    }

    if (thisComponentState.showListOfOpenPRForAudit) {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ListOfOpenPrForAudit back={() => updateState({ ...thisComponentState, showListOfOpenPRForClosure: false })} />

                </div>
            </div>
        )
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }

    if (thisComponentState.showItemPriceHistory) {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ItemPriceHistory back={() => updateState({ ...thisComponentState, showItemPriceHistory: false })} />

                </div>
            </div>
        )
    }

    if (thisComponentState.SetDefaultPurchasingUom) {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <SetDefaultPurchasingUom back={() => updateState({ ...thisComponentState, SetDefaultPurchasingUom: false })} />

                </div>
            </div>
        )
    }


    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailProperties);

            exportToCSV("data", csvString);
        };
    }

    const compare = () =>
    {
        fetch("PurchaseOrders/Compare?date="+data.metaData.parentProperties.date).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = [...data.metaData.detailProperties];

                let itemWithNoRequest = [];

                for (let i = 0; i < tempData.length; i++) {

                    //Get the item 
                    let item = response.find(val => val.code === response[i].code && val.branchId === response[i].branchId);

                    if (item.warehouseId !== response[i].warehouseId) {

                        return alert("Your entry " + response[i].code + " : " + response[i].description + " has a wrong warehouse.");
                    };

                    if (typeof item === 'undefined') {

                        itemWithNoRequest.push(response[i]);
                    }
                };

                let csvString = "";

                if (itemWithNoRequest.length > 0) {

                    itemWithNoRequest.forEach(val => val.status = "No Purchase");

                    csvString = toCsvString(itemWithNoRequest)

                    exportToCSV("data", csvString);

                } else {

                    response.forEach(val => val.status = "No Purchase");

                    csvString = toCsvString(response)

                    exportToCSV("data", csvString);

                }


              
            });
    }




    return (

        <div style={{ float: 'left' }}>
    
            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faHand} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({...thisComponentState,showOnHand:true})} />
            <FontAwesomeIcon icon={faWindowRestore} title="Enter data" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="cyan" onClick={() => updateState({ ...thisComponentState, showPOOwnCapture: true })} />
            <FontAwesomeIcon icon={faMoneyBill} title="Price Review" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, reviewPrice: true })} />
            <FontAwesomeIcon icon={faSearch} title="Find by Supplier" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({...thisComponentState,searchBySupplier:true})} />
            <FontAwesomeIcon icon={faTasks} title="Raw Mat Summary" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showRawMatSummary: true })} />
            <FontAwesomeIcon icon={faCut} title="Disect Pr" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="purple" onClick={() => updateState({ ...thisComponentState,splitPO: true })} />
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={openDrafts} />
            <FontAwesomeIcon icon={faList12} title="List of Open PR" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfOpenPR: true })} />
            <FontAwesomeIcon icon={faList12} title="Requested Pr for Closure" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState,showListOfOpenPRForClosure: true })} />
            <FontAwesomeIcon icon={faList12} title="Pr for Audit" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState,showListOfOpenPRForAudit: true })} />
            <FontAwesomeIcon icon={faUserPlus} title="Assign tasks" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, assignTask: true })} />
            <FontAwesomeIcon icon={faGasPump} title="Diesel Requests" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="cyan" onClick={() => updateState({...thisComponentState,showDieselRequest:true}) } />
            <FontAwesomeIcon icon={faHistory} title="Item Price History" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, showItemPriceHistory: true })} />
            <FontAwesomeIcon icon={faAnchor} title="Set default purchasing uom" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, SetDefaultPurchasingUom: true })} />
            <FontAwesomeIcon icon={faFileExcel} title="Download excel" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

            <FontAwesomeIcon icon={faWrench} title="If this is daily request of the cafe raw materials. Please click this to relate stock requisitions from what is purchase." style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={compare} />


        </div>

    )
}

