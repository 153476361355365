import React, {Fragment, useEffect, useState } from 'react';

import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import CreateOrderNavigator from './CreateOrderNavigator';
import DetailsOrderNavigator from './DetailsOrderNavigator';
import OrderListNavigator from './OrderListNavigator';



function OrderForm() {

    const transactionCode = "Ordering";
    const postUrl = "/Orders/Create";
    const detailsUrl = "/Orders/Details/?id=";
    const editDetailUrl = "/Orders/EditItem";
    const listUrl = "/Orders/Index";
    const validatorUrl = "/Orders/Validate";

    var context = {

        categories: ['107', '108', '109', '110'],
        type: "S"

    };


    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);


    }, [])

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<OrderListNavigator listUrl={ listUrl}/>} detailsNavigator={<DetailsOrderNavigator />}/>);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl } context={context} transactionCode={transactionCode} metaData={data.metaData} postUrl={postUrl} detailsUrl={detailsUrl} listUrl={listUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList} >

                <TransactionForm createNavigator={<CreateOrderNavigator />} detailsNavigator={<DetailsOrderNavigator />} transactionList={transactionList} />

            </TransactionContextProvider>

        )
    }

}

export default OrderForm;