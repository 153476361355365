import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import uuid from 'react-uuid';
import { TransactionContext } from './TransactionContext';


export default function Drafts(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [isLoading, updateLoading] = useState(true);

    const [details, updateDetails] = useState({
        showDetails: false,
        details: {}

    });

    const [thisComponentState, updateState] = useState([]);

    useEffect(() => {
        fetch("/Base/GetDraftList?" + new URLSearchParams({
                type: props.type
        }))
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {
                updateState(response);
                updateLoading(false);
            })
    }, []);


    const select = (val) => {

        let tempData = { ...data.metaData };

        let value = JSON.parse(val.value);

        tempData.parentProperties = value;
        tempData.detailProperties = value.details;

        updateMetaData({ metaData: tempData });

        props.back();
    }

    const deleteItem = (guidId) => {

        updateLoading(true);

        fetch("/Base/DeleteDrafts", {
            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState];

                    tempDatas = tempDatas.filter(u => u.guidId !== guidId);

                    updateLoading(false);

                    updateState(tempDatas);

                    return alert(response.message);

                } else {

                    return alert(response.message);
                }
            });
    }


    if (isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (details.showDetails) {

        return (

            <div>

                <div className="row">
                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={() => updateDetails({...thisComponentState,showDetails:false})}/>
                    </div>
                </div>

                <hr/>
                <div>
                    <div>
                        {
                            Object.entries(details.details).map(val =>

                                val[0] !== "details" &&

                                <div className="row" key={uuid()}>
                                    <div className="col">
                                        {val[0]}
                                    </div>

                                    <div className="col">
                                        {val[1]}
                                    </div>
                                </div>


                            )
                        }

                    </div>
                </div>

                
               
            </div>
            
            
    )}

    return (

        <div>
            <div>
              
                <div className="row">
                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={() => props.back()} />
                    </div>
               </div>

                <hr />

                <div className='row'>                                 
                    <div className='col'>
                        <table className='table table-bordered'>

                            <thead>

                                <tr>
                                    <th>Id</th>
                                    <th colSpan="3">Action</th>
                                   
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    thisComponentState.length > 0 && thisComponentState.map((val) =>

                                        <tr key={uuid()}>

                                            <td>{val.id} </td>

                                            <td>
                                                
                                                <input type='button' value='Details' className='form-control' onClick={() => updateDetails({...details,showDetails:true,details:JSON.parse(val.value)})} />

                                            </td>

                                            <td>
                                                <input type='button' value='Delete' className='form-control' onClick={()=>deleteItem(val.guidId)}/>
                                            </td>

                                            <td>
                                                <input type='button' value='Select' className='form-control' onClick={() => select(val)} />
                                            </td>

                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>   
                </div>
            </div>
        </div>  
       
  )

}