import { useEffect } from "react";
import { useState } from "react";
import StandardAutoComplete from '../../StandardAutocomplete';

export default function EditJobOrder(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation:false,
        jobOrderId: props.jobOrder.jobOrderId,
        projectUserId: props.jobOrder.projectUserId,
        deptInCharge:props.jobOrder.deptInCharge,   
        expectedDueDate:props.jobOrder.expectedDueDate,
        generalDescription: props.jobOrder.generalDescription

    })

   
    const save = () => {

        if (thisComponentState.generalDescription === "") {

            return alert("General Description is required.");
        }
 
        if (thisComponentState.projectUserId === "") {

            return alert("Project Manager is required.")
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/StockRequisitions/EditJobOrder", {

            method: "post",
            body:JSON.stringify({
                jobOrderId: thisComponentState.jobOrderId,
                expectedDueDate: thisComponentState.expectedDueDate,
                deptInCharge:thisComponentState.deptInCharge,
                projectUserId:thisComponentState.projectUserId,
                generalDescription: thisComponentState.generalDescription
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false });

                    return alert(response.message);

                } else {

                    updateState({ ...thisComponentState,isPerformingOperation: false });

                    return alert(response.message);
                }
              
            });
    }


    const updateProjectUserId = (code) => {

        updateState({ ...thisComponentState, projectUserId: code });

    };

    const clearProjectUserId = () => {

        updateState({ ...thisComponentState, projectUserId:""});

    }


    const updateDeptInCharge = (code) => {

        updateState({ ...thisComponentState,deptInCharge: code });

    };

    const clearDeptInCharge = () => {

        updateState({ ...thisComponentState,deptInCharge: "" });

    }



    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (

        <div>
             <hr/>

                <div className="row">
                    <div className="col">
                        <input type="button" className="form-control" value="Save" onClick={save} />
                    </div>

                     <div className="col">
                        <input type="button" className="form-control" value="Back" onClick={() =>props.back()} />
                     </div>                             
                </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div >
                        Project Manager
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete key={200} url="/Employees/GetEmployees" value={thisComponentState.projectUserId} clear={clearProjectUserId} updateValue={updateProjectUserId} placeholder="Employee Search" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div >
                        Department In Charge
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete key={200} url="/Departments/GetDepartments" value={thisComponentState.deptInCharge} clear={clearDeptInCharge} updateValue={updateDeptInCharge} placeholder="Deparment Search" />
                </div>
            </div>

            <hr/>
   
            <div className="row">
                <div className="col">
                    <div >
                        Expected Due Date
                    </div>
                </div>
                <div className="col">
                    <input type="date" onChange={(e) => updateState({ ...thisComponentState, expectedDueDate: e.currentTarget.value })} className="form-control" />
                </div>
            </div>


            <hr/>

            <div className="row">
                <div className="col">
                    <div >
                        General Description
                    </div>
                </div>

                <div className="col">
                    <input type="text" value={thisComponentState.generalDescription} onChange={(e) => updateState({ ...thisComponentState, generalDescription: e.currentTarget.value })} className="form-control" />
                </div>
            </div>
        </div>
    )
}