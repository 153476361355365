
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import capitalizeFirstLetter from '../../Functions/capitalizedFirstLetter';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import CategorySelection from './CategorySelection';


export default function ProduceOrder(props) {

  
    const [thisComponentState, updateState] = useState({

        data: [],
        guids: [],
        summaryData : [],
        currentlyApproving:false,
        isLoading: false,
        showSummary: false,
        productionDate : ""
    });

    useEffect(() => {

        getData();

    },[]);

    const [selectedCategories, updateSelectedCategories] = useState([]);

    const [showCategorySelection, updateShowCategorySelection] = useState(false);


    const selectAll = () => {

            var tempData = [];

            for (var i = 0; i < thisComponentState.data.length; i++) {

                var guidId = thisComponentState.data[i].guidId;

                tempData.push(guidId);

            };

            updateState({ ...thisComponentState, guids: tempData });     
    }


    const unSelectAll = () => {

        var tempData = [];

        updateState({ ...thisComponentState, guids: tempData });
    }

    const onChange = (e, guidId) => {
        
        let isChecked = e.target.checked;

        let tempData = [];

        if (isChecked) {

            tempData = [...thisComponentState.guids];

            if (!tempData.some(x => x === guidId)) {

                tempData.push(guidId);
                
                updateState({ ...thisComponentState, guids: tempData });
            };

        } else {

             tempData = [...thisComponentState.guids];

            if (tempData.some(x => x === guidId)) {

                tempData = tempData.filter(x => x !== guidId);

                updateState({ ...thisComponentState, guids: tempData });
            };

        }
    }

    async function getData() {

        updateState({ ...thisComponentState, isLoading: true });

        await fetch("/Productions/ViewOrders")
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });

            });

    };

    const produce = (guidId) => {

        if (thisComponentState.guids.length === 0) {

            return alert ("Nothing to produce");
        };
    
        //Build data to post

        var dataToPost = {

            guids: thisComponentState.guids
        }

        updateState({ ...thisComponentState, currentlyApproving: true })

            fetch("/Productions/Produce", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(dataToPost),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => response.status === 401 ? window.location = "/Login" : response.json())

                .then(response => {

                    getData();

                    updateState({ ...thisComponentState, currentlyApproving: false });

                });

    }


    if (thisComponentState.showSummary) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <div>
                        <input type="button" className="form-control" value="Back" onClick={() => updateState({ ...thisComponentState, showSummary: false })}/>
                    </div>

                    <table className="table table-bordered">

                        <thead>

                            <th>Code</th>
                            <th>Name</th>
                            <th>Quantity</th>

                        </thead>

                        <tbody>
                            {
                                thisComponentState.summaryData.map(item =>
                                    <tr>

                                        <td> {item.itemCode} </td>
                                        <td> {item.itemName}  </td>
                                        <td> {item.quantity}</td>

                                    </tr>
                                )
                            }
                        </tbody>

                        <tfoot>

                            </tfoot>
                      
                    </table>

                </div>
            </div>

        )
    }

    const showSummary = () => {

            var date = thisComponentState.productionDate;

            if (date === "") {

                return alert("Invalid date.");
            }

            fetch("/Productions/OrderSummary?" + new URLSearchParams({

                date: date

            }))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, summaryData: response, isLoading: false,showSummary:true});

            });

    }


    if (showCategorySelection) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <CategorySelection selectedCategories={selectedCategories} updateSelectedCategories={updateSelectedCategories} updateShowCategorySelection={updateShowCategorySelection} />
            </div>
        </div>)

    }

    if (thisComponentState.currentlyApproving) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                       Please wait while orders are being transferred for productions...
                    
                    </div>
            </div>
           
         )
    }

        return (

            <div>

                <div className="row">

                    <div className="col">
                        <input type="button" className="form-control" value="Back" onClick={() => props.updateState({ ...thisComponentState, approvedTransaction: false })} />
                    </div>

                    <div className="col">
                        <input type="button" className="form-control" value="Produce" onClick={produce} />
                    </div>

                    <div className="col">
                        <input type="button" className="form-control" value="Select All" onClick={selectAll} />
                    </div>

                    <div className="col">
                        <input type="button" className="form-control" value="UnSelect All" onClick={unSelectAll} />
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" onChange={(e) => updateState({ ...thisComponentState, productionDate: e.currentTarget.value })} />
                    </div>

                    <div className="col">
                        <input type="button"  className="form-control" value="Show Summary" onClick={showSummary} />
                    </div>

                </div>

                        
                <br />
                <br />

                <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                    <thead className="thead-dark">

                        {
                           thisComponentState.data.length> 0 ? 

                            Object.entries(thisComponentState.data[0]).map(y =>

                                y[0] !== "guidId" ? <td>  {capitalizeFirstLetter(y[0])}  </td> : <td> Actions  </td>

                                ) :
                                <td>
                                    {thisComponentState.isLoading ? "Please wait..." : "Nothing to produce... "}
                                </td>
                        }

                    </thead>

                    <tbody>

                        {

                           thisComponentState.data.length > 0 ?

                           thisComponentState.data.map(x =>

                                <tr>

                                    {
                                        Object.entries(x).map(y =>

                                            y[0] !== "guidId" ? <td> {y[1]} </td> :

                                                    <td>
                                                  
                                                    {
                                                        thisComponentState.guids.includes(y[1]) ? <input type="checkbox" key={y[1]} defaultChecked={true} onChange={(e) => onChange(e, y[1])} /> : <input  defaultChecked={false} type="checkbox" onChange={(e) => onChange(e, y[1])} />
                                                    }
                                                     
                                                    </td>                                          
                                        )
                                    }

                                </tr>

                                ) : <Fragment />

                        }

                    </tbody>
                </table>
            </div>            
        )
    
}

