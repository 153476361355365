import toCamelCase from "./toCamelCase";


function createProperties(metaData) {

    var object = {};

    metaData.map(data => {

        if (data.htmlType === "checkbox") {

            if (data.defaultValue !== "") {

                object[toCamelCase(data.name)] = data.defaultValue;

            } else {

                object[toCamelCase(data.name)] = false;

            };

        } else if (data.htmlType === "number") {

            if (data.defaultValue !== "") {

                object[toCamelCase(data.name)] = data.defaultValue;

            } else {

                object[toCamelCase(data.name)] = 0;

            };

           
        } else if (data.htmlType === "date") {

            if (data.defaultValue !== "") {

                object[toCamelCase(data.name)] = data.defaultValue;

            } else {

                object[toCamelCase(data.name)] = "";

            };
    
        } else {

          

            if (data.defaultValue !== "") {

              
                object[toCamelCase(data.name)] = data.defaultValue;

            } else {

                object[toCamelCase(data.name)] = "";

            };

        }
    })  

    return object;
}


export default createProperties;
