
import { faL } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react"
import uuid from "react-uuid";
import PureInput from "../../PureInput";
import StandardAutoComplete from "../../StandardAutocomplete";

export default function EvaluatorMatrix(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        positionId:"",
        mainPerformanceFactors: [],
        evaluators: [],
        details: [],
        toReload:0
    });

    useEffect(() => {

        if (thisComponentState.positionId !== "") {

            fetch("PerformanceEvaluations/GetMatrix?" + new URLSearchParams({ positionId: thisComponentState.positionId }))

                .then(response => response.status === 401 ? window.location = "/Login" : response.json())
                .then(response => {
                    updateState({
                        ...thisComponentState,
                        mainPerformanceFactors: response.mainPerformanceFactors,
                        evaluators: response.evaluators,
                        details: response.details,
                        isPerformingOperation: false
                        
                    });
                });
        }

    }, [thisComponentState.toReload]);

    const save = (positionId, mainPerformanceFactorId,evaluatorId,value) => {

        var isConfirm = window.confirm("Are you sure you value is correct?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/SaveMatrixChange", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                positionId:positionId,
                mainPerformanceFactorId: mainPerformanceFactorId,
                performanceEvaluatorId: evaluatorId,
                weight :value
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {
    

                updateState({ ...thisComponentState, isPerformingOperation: false,toReload:thisComponentState.toReload+1})

                return alert(response.message);

            });
    }




    const getRowSum = (mainPerformanceFactorId) => {

        let details = thisComponentState.details.filter(item => item.mainPerformanceFactorId === mainPerformanceFactorId);

        let rowSum = 0;

        details.forEach(item => {
            rowSum = rowSum + item.weight
        });

        return rowSum;
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait....
                    </button>
                </div>
            </div>
        </div>)
    }


    const getValue = (mainFactorId, evaluatorId) => {

        let item = thisComponentState.details.find(item => item.mainPerformanceFactorId === mainFactorId && item.evaluatorId === evaluatorId);

        if (typeof item !== 'undefined') {
            return item.weight;
        };

        return 0;
    }

    const updateValue = (mainPerformanceFactorId,evaluatorId,value) => {

        let parsedPositionId = parseInt(thisComponentState.positionId);
        let parsedMainPerformanceFactorId = parseInt(mainPerformanceFactorId);
        let parsedEvaluatorId =parseInt(evaluatorId);
        let parsedValue = parseFloat(value);

        save(parsedPositionId, parsedMainPerformanceFactorId, parsedEvaluatorId, parsedValue);

    }

    const updatePositionId = (id) =>
    {
        updateState({ ...thisComponentState, positionId: id.toString(),toReload:thisComponentState.toReload+1 });

    }

    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>
                <div className="col">
                    <input value="List" onClick={() => props.back()} className="form-control" type="button" />
                </div>
            </div>
           
            <hr/>

            <div className="row">

                <div className="col">
                   Position
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.positionId.toString()} url="PerformanceEvaluations/GetPositions"  updateValue={updatePositionId} />
                    
                </div>
            </div>

            <hr/>
            <div className="row">
   
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                      
                                   
                                    <th >Main Factor</th>
                                
                                    {
                                        thisComponentState.evaluators.length > 0 && thisComponentState.evaluators.map(item => <th> <div style={{textAlign:"center"}}>{item.name}</div> </th>)
                                    }

                                    <th>Total</th>
                                </tr>
                            </thead>

                            <tbody>

                            {
                                thisComponentState.mainPerformanceFactors.length > 0 && thisComponentState.mainPerformanceFactors.map(mainFactor => 

                                    <tr key={uuid()}>
                                        <td>
                                            {mainFactor.name}
                                        </td>

                                        {
                                            thisComponentState.evaluators.length > 0 && thisComponentState.evaluators.map(evaluator =>

                                                <td key={ uuid()}>
                                      
                                                    <PureInput value={getValue(mainFactor.mainPerformanceFactorId, evaluator.evaluatorId)} code={mainFactor.mainPerformanceFactorId} name={evaluator.evaluatorId} updateValue={updateValue}  />

                                                </td>
                                              
                                            )
                                        }

                                        <td>
                                            {
                                                getRowSum(mainFactor.mainPerformanceFactorId)
                                            }
                                        </td>

                                    </tr>
                                                                     
                                 )
                            }
                                                          
                            </tbody>

                       
                        </table>

                    </div>
           
            </div>

        </div>
    )
}