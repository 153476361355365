import React, { useState } from 'react';



export default function PoChangeValue(props) {

    const [thisComponentState, updateState] = useState({

        toUpdate: false,
        branchId: props.branchId,
        code: props.code,
        value: props.value
       
    });

    const updateValue = (name,newValue) => {



        props.updateValue(thisComponentState.branchId, thisComponentState.code,name,newValue);

    }


    if (thisComponentState.toUpdate) {

        return (      
                 <div>
                    <input type="text" className="form-control" style={{ float: 'left' }} placeholder="Quantity" value={thisComponentState.value} onChange={(e) => updateState({ ...thisComponentState, value: e.currentTarget.value })} onBlur={(e) => { updateValue("quantity", e.currentTarget.value); updateState({...thisComponentState,toUpdate:false})}} />
                    <input type="text" className="form-control" style={{ float: 'left' }} placeholder="Price"  onChange={(e) => updateValue("price", e.currentTarget.value)} onBlur={() => updateState({ ...thisComponentState, toUpdate: false })} />
                </div>
            )

    } else {
        
        return <div onDoubleClick={() => updateState({...thisComponentState,toUpdate:true })}>{props.value}</div>

    }


}