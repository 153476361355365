import { faArrowLeft, faArrowRight, faFilter, faListAlt, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import OpenPoFilter from "./OpenPoFilter";
import PrDetailsForPoReview from "./PoDetailsForGrReview";

export default function ListOfOpenPO(props) {

    const [thisComponentState, updateState] = useState({
        details: [],
        isLoading: false,
        showFilter:false,
        showDetails: false,
        selectedGuidId: ""
    });

   
    const generate = (params) => {

        updateState({ ...thisComponentState, isLoading: true});

        fetch("/GoodsReceipts/GetListOfOpenPO?"+new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.showFilter = false;

                updateState(tempData);

            });
    }



    
    if (thisComponentState.isLoading) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <span>Loading... </span>

                </div>
            </div>
        )
    }



    const filter = (criterias) => {

        
        generate(criterias);


    }


    const doubleBack = () => {

        updateState({ ...thisComponentState, showDetails: false });

        props.back();
    }
    


    if (thisComponentState.showDetails) {

        return (<PrDetailsForPoReview doubleBack={doubleBack} guidId={thisComponentState.selectedGuidId} back={() => updateState({...thisComponentState,showDetails:false,retrieve:1})}/>)
    }


    if (thisComponentState.showFilter) {

        return (<OpenPoFilter generate={generate} filter={filter} />)
    }
    


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">

                    <div className="col">

                        <div style={{ float:'left'}}>
                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
                            <FontAwesomeIcon icon={faFilter} title="show filter criteria" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer', transform: [{ rotateY: '90deg' }] }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showFilter: true })} />
        

                        </div>      
                    </div>

                    <div className="col">
                       
                       

                    </div>

                    <div className="col">

                    </div>


                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="2"> Action</th>
                                    <th> PO Date </th>
                                    <th> Required Date </th>
                                    <th> Doc Id </th>
                                    <th> Vendor </th>
                                    <th> Branch</th>
                                    <th> Note </th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={uuid()}>

                                            <td>                                                                                           
                                                <input value={val.isPick ? "UnPick" : "Pick"} className="form-control" type="button" onClick={() => val.isPick   ? unPick(val.guidId) : pick(val.guidId)} />
                                            </td>

                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedGuidId:val.guidId,showDetails:true}) } />
                                            </td>

                                            <td> {val.date}</td>
                                            <td> {val.requiredDate}</td>
                                            <td> {val.docId}</td>
                                            <td> {val.vendor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.note}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
