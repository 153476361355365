import { faArrowLeft, faArrowRight, faFilter, faListAlt, faRotate, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import InventoryDetails from "./InventoryDetails";
import OpenInventoryFilter from "./OpenInventoryFilter";

export default function InventoryList(props) {


    const [thisComponentState, updateState] = useState({

        isLoading: true,
        data: [],
        filters: {},
        showDetails:false,
        selectedCode:"",
        toReload:0

    });


    useEffect(() => {

        fetch("/Inventories/Index?" + new URLSearchParams(thisComponentState.filters))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.originalValues = response;

                updateState(tempData);

            });

    }, []);

    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    const filter = (criterias) => {


        let tempDatas = [...thisComponentState.details];

        if (typeof criterias.categoryId !=='undefined')
        {
            tempDatas = tempDatas.filter(val => val.categoryId === criterias.categoryId);
        };

        if (typeof criterias.itemCode !== 'undefined') {

            tempDatas = tempDatas.filter(val => val.code === criterias.itemCode);

        };

        updateState({ ...thisComponentState, details: tempDatas,filterDetails:false});
    }


    const clearFilter = () => {

        let tempDatas = [...thisComponentState.details];

        tempDatas = thisComponentState.originalValues;

        updateState({ ...thisComponentState, details: tempDatas, isFilterApplied: false });
      
    }

    if (thisComponentState.filterDetails) {

        return (
            <div>
     
                <OpenInventoryFilter clearFilter={clearFilter} filter={filter} />

             </div>
     
         )
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.showDetails) {

        return (<InventoryDetails code={thisComponentState.selectedCode } back={() => updateState({...thisComponentState,showDetails:false})}/>)


    }

    return (

        <div>
            <div>
                <div className="row">
                  
                    <div className="col">

                        <div style={{ float: 'left' }}>

                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />

                            <FontAwesomeIcon icon={faFilter} title="show filter criteria" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer', transform: [{rotateY:'90deg'}] }} size="2x" color="orange" onClick={() => updateState({...thisComponentState,filterDetails:true})} />
                            <FontAwesomeIcon icon={faX} title="clear filter" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer'}} size="2x" color="green" onClick={clearFilter} />
                            
                        </div>
                       
                    </div>

                    <div className="col">
                       
                       
                    </div>

                    <div className="col">

                    </div>


                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th > Action</th>
                                    <th> Code </th>
                                    <th> Name </th>
                                    <th> Category</th>
                                    <th> Sell Uom</th>  
                                    <th> Inventory Uom </th>
                                    <th> Purchase Uom </th>
                                    <th> Is Sell Item</th>
                                    <th> Is Purchase Item</th>
                                    <th> Is Inventory Item </th>
                                    <th> Is Service</th>
                                    <th> Is Manufactured</th>
                                    <th> Is Forecastable</th>
                                    <th> Is Active</th>



                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={val.code}>

                                        

                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedCode:val.code,showDetails:true}) } />
                                            </td>

                                            <td> {val.code}</td>
                                            <td> {val.name}</td>
                                            <td> {val.category}</td>
                                            <td> {val.sellUom}</td>
                                            <td> {val.inventoryUom}</td>
                                            <td> {val.purchaseUom}</td>
                                            <td> {val.sellItem}</td>
                                            <td> {val.purchaseItem}</td>
                                            <td> {val.inventoryItem}</td>
                                            <td> {val.isService.toString()}</td>
                                            <td> {val.isManufactured.toString()}</td>
                                            <td> {val.isForecastable.toString()}</td>
                                            <td> {val.isActive.toString()}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
