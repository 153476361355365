import { useEffect } from "react";
import { useState } from "react"

export default function CreateVendor() {

    const [thisComponentState, updateState] = useState({

        code: "",
        name: "",
        email: "",
        address: "",
        phoneNumber: "",
        country: "",
        bank: "",
        bankAccount: "",
        bankAccountName: "",
        tinId: "",
        fatherCode: "",
        isActive:"",
        toRefresh :0
    });

    useEffect(() => {

        fetch("/Vendors/GetNextVendorCode").then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, code: response.value });

            });

    }, [thisComponentState.toRefresh]);


    

    const save = () => {


        let confirm = window.confirm("Make sure your data are correct. Please click ok to continue saving.")

        if (!confirm) {
            return;
        }

        if (thisComponentState.code === "") {

            return alert("Code is required.");
        };

        if (thisComponentState.name === "") {

            return alert("Name is required.");
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Vendors/Create", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation:false,toRefresh:thisComponentState.toRefresh+1 });

                return alert(response.message);
            });

    }
    return (

        <div>
            <hr />

            <div className="row">

                <div className="col">
                    <input type="button" className="form-control" value="Save" onClick={save} />
                </div>

                <div className="col">      
                </div>

                <div className="col">
            
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Code
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, code: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Name
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState,name: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Email
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, email: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Address
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, address: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Phone Number
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, phoneNumber: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />
            <div className="row">
                <div className="col">
                    <div>
                        Country
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, country: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />
            <div className="row">
                <div className="col">
                    <div>
                        Bank
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, bank: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Bank Account
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, bankAccount: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Bank Account Name
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, bankAccountName: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Tin Id
                    </div>
                </div>

                <div className="col">
                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, tinId: e.currentTarget.value })} className="form-control" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Father Code
                    </div>
                </div>

                <div className="col">

                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, fatherCode: e.currentTarget.value })} className="form-control" />

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Is Active
                    </div>
                </div>

                <div className="col">
                    <input type="text" onChange={(e) => updateState({ ...thisComponentState, isActive: e.currentTarget.value })} className="form-control" />
                </div>
            </div>

            <hr />

          </div>
    )

}
