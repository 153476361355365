import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from '../../StandardAutocomplete';
import JobOrderDetails from "./JobOrderDetails";
import TakePictureOfFinishedJob from "./TakePictureOfFinishedJob";

export default function JobOrderList(props) {


    const [thisComponentState, updateState] = useState({

        isLoading: false,
        projectUserId: "",
        start: "",
        end: "",
        generalDescription:"",
        approvalStatus: 0,
        completionStatus: 0,
        jobOrderId: 0,
        takePicture: false,
        showDetails :false,
        list: [],
        refresh:0
       
    });


    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            projectUserId: "",
            start: "",
            end: "",
            generalDescription: "",
            approvalStatus: 0,
            completionStatus: 0,
            jobOrderId: 0,
            takePicture: false,
            showDetails: false,
            list: []
        })
    }


    const retrieve = () => {

        let params = {};

        let hasParams = false;

        if (thisComponentState.projectUserId !== "" && thisComponentState.projectUserId !== null) {

            hasParams = true;

            params.projectUserId = thisComponentState.projectUserId;
        };

        if (thisComponentState.start !== "" && thisComponentState.start !== null) {

            hasParams = true;
            params.start = thisComponentState.start;
        }

        if (thisComponentState.end !== "" && thisComponentState.end !== null) {

            hasParams = true;
            params.end = thisComponentState.end;
        }

        if (thisComponentState.approvalStatus !== "" && thisComponentState.approvalStatus !== null && thisComponentState.approvalStatus !==0) {

            hasParams = true;
            params.approvalStatus = thisComponentState.approvalStatus;
        }

        if (thisComponentState.completionStatus !== "" && thisComponentState.completionStatus !== null && thisComponentState.completionStatus !==0) {

            hasParams = true;
            params.completionStatus = thisComponentState.completionStatus;

        }


        if (thisComponentState.generalDescription !== "" ) {

            hasParams = true;

            params.generalDescription = thisComponentState.generalDescription;
        }

        if (!hasParams) {

            return alert("Please defined atleast one filter.");
        }


        fetch("/StockRequisitions/GetJobOrderList?" + new URLSearchParams(params))

            .then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(response => {

                updateState({
                    ...thisComponentState,
                    list:response
                })
            });
    }


    const updateProjectUserId = (code) => {

        updateState({ ...thisComponentState, projectUserId: code });

    };

   
    const updateDescription = (code) => {

        updateState({ ...thisComponentState, generalDescription: code });

    };

    const close = (jobOrderId) => {

        updateState({ ...thisComponentState, jobOrderId: jobOrderId, takePicture: true });
    }


    if (thisComponentState.showDetails) {

        return <JobOrderDetails jobOrderId={thisComponentState.jobOrderId} back={() => updateState({...thisComponentState,showDetails:false})}/>
    }

  
    return (

        <div>





            <div className="row">

                <div className="col">
                  
                </div>

                <div className="col">
                    <input type="button" className="form-control" onClick={() => props.back()} value="Back" />
                </div>

            </div>


            <div className="row">

                <div className="col">
                    Project Manager
                </div>

                <div className="col" title="Double click to clear">
                    <StandardAutoComplete key={200} url="/StockRequisitions/GetUsersForAutocomplete" clear={() => updateState({...thisComponentState, projectUserId:""})} value={thisComponentState.projectUserId} updateValue={updateProjectUserId} placeholder="Employee Search" />
                </div>

            </div>

            <div className="row">

                <div className="col">
                    General Description
                </div>

                <div className="col" title="Double click to clear">
                    <StandardAutoComplete key={200} url="/StockRequisitions/GetDescriptions" clear={() => updateState({...thisComponentState,generalDescription:""})} value={thisComponentState.generalDescription} updateValue={updateDescription} placeholder="Description Search" />
                </div>

            </div>

            <div className="row">
                <div className="col">
                    Start
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.start} className="form-control" onChange={(e) => updateState({...thisComponentState,start:e.currentTarget.value}) } />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    End
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.end} className="form-control" onChange={(e) => updateState({ ...thisComponentState, end: e.currentTarget.value })} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Approval Status
                </div>

                <div className="col">

                    <select className="form-control" value={thisComponentState.approvalStatus} onChange={(e) => updateState({...thisComponentState,approvalStatus:e.currentTarget.value}) }>

                        <option value="0">All</option>
                        <option value="1">Approved</option>
                        <option value="2">Unapproved</option>

                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Completion Status
                </div>

                <div className="col">
                    <select className="form-control" value={thisComponentState.completionStatus} onChange={(e) => updateState({ ...thisComponentState,completionStatus: e.currentTarget.value })}>

                        <option value="0">All</option>
                        <option value="1">Finished</option>
                        <option value="2">Unfinished</option>

                    </select>

                </div>
            </div>


            <br/>

            <div className="row">

                <div className="col">
               
                </div>

                <div className="col">
                    <div className="row">

                        <div className="col">
                            <input type="button" className="form-control" value="Go" onClick={retrieve}/>
                        </div>

                        <div className="col">
                            <input type="button" className="form-control" value="Clear" onClick={clear }  />
                        </div>
                    </div>
                </div>
            </div>

            <br/>

            <div className="row">
                <table className="table table-bordered">

                    <thead>
                        <tr>
                            <th colSpan="1">Action</th>
                            <th>Job Order Id</th>
                            <th>Request Date</th>
                            <th>Start Date</th>
                            <th>Description</th>
                            <th>Budget</th>
                            <th>Estimated Days To Complete</th>
                        </tr>

                    </thead>

                    <tbody>

                        {
                         typeof thisComponentState.list !=='undefined' && thisComponentState.list.map(val =>

                                <tr key={val.id}>

                                    <td >
                                        <input type="button" className="form-control" value="Details" onClick={() => updateState({...thisComponentState,jobOrderId:val.id,showDetails:true})} />
                                    </td>

                                

                                    <td >
                                        {val.id}
                                    </td>


                                    <td >
                                        {val.requestDate}
                                    </td>

                                    <td >
                                        {val.startDate}
                                    </td>

                                    <td >
                                        {val.description}
                                    </td>

                                    <td >
                                        {val.estimatedBudget}
                                    </td>

                                    <td >
                                        {val.estimatedDaysToComplete}
                                    </td>

                                </tr>

                            )
                        }

                    </tbody>
                </table>
            </div>

        </div>
    )
}
