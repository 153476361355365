import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useRef } from 'react';
import Recoveries from './Recoveries';


export default function CratesToPay(props) {

    const [thisComponentState, updateState] = useState({

        isLoading: true,
        agentId:props.agentId,
        isPerformingOperation:false,
        prevWashedDate: null,
        chargedDate: null,
        date: null,
        issuedCrates: [],
        missingCratesOnFirstWashed: [],
        missingCratesOnSecondWashed: [],
        missingCratesOnThirdWashed: [],
        chargesWithValue:[],
        cratesForCharging: [],
        totalCharged:0,
        agentName: "",
        operationSuccessful: false,
        showRecoveries: false,
        toReload:0
    });

    const saveInput = useRef(null);

    useEffect(() => {

        fetch("/Crates/GetCratesToPay?" + new URLSearchParams({

            washedId:props.washedHistoryId
        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                    updateState({

                        ...thisComponentState,
                        isLoading: false,
                        issuedCrates: response.issuedCrates,
                        missingCratesOnFirstWashed: response.missingCratesOnFirstWashed,
                        missingCratesOnSecondWashed: response.missingCratesOnSecondWashed,
                        missingCratesOnThirdWashed: response.missingCratesOnThirdWashed,
                        cratesForCharging: response.cratesForCharging,
                        firstWashedDate: response.firstWashedDate,
                        secondWashedDate: response.secondWashedDate,
                        thirdWashedDate: response.thirdWashedDate,
                        foundCrates: response.foundCrates,
                        chargedDate: response.chargedDate,
                        chargesWithValue: response.chargesWithValue,
                        totalCharged: response.totalCharged,
                        washedHistoryId: response.washedHistoryId,
                        agentName:response.agentName

                    });

            });
    }, [thisComponentState.toReload]);

    const onFirstWashed = (missingCratesOnFirstWashed,crateId) => {

        if (!missingCratesOnFirstWashed.includes(crateId)) {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            );

        } else {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faX} color="red" />
                </div>
            );

        }
    }


    const onSecondWashed = (missingCratesOnSecondWashed,crateId) => {

       
        if (!missingCratesOnSecondWashed.includes(crateId)) {

            return (

                <div style={{textAlign:"center"}}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>   
            );

        } else {

            return (

                    <div style={{textAlign:"center"}}>
                        <FontAwesomeIcon icon={faX} color="red" />
                    </div>
            );
        }
    }


    const onThirdWashed = (missingCratesOnThirdWashed, crateId) => {

        if (thisComponentState.thirdWashedDate === null) {
            return (
                <div style={{ textAlign: "center" }}>
                   
                </div>
            );
        }
       

        if (!missingCratesOnThirdWashed.includes(crateId)) {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            );

        } else {

            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faX} color="red" />
                </div>
            );
        }
    }


    const forCharging = (cratesForCharging, crateId) => {

        if (thisComponentState.foundCrates.includes(crateId)) {
            return (

                <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faX} color="red" />
                </div>
            );
        }

        if (cratesForCharging.includes(crateId)) {

            return (
              
                <div style={{textAlign:"center"}}>
                    <FontAwesomeIcon icon={faCheck} color="red" />
                </div>
            );

        } else {

            return (

                  <div style={{textAlign:"center"}}>
                        <FontAwesomeIcon icon={faX} />
                </div>
            );
        }
    }

    const updateValue = (agentId) => {

        updateState({ ...thisComponentState, agentId: agentId });

    }

    const getState = (crateId) => {

        return thisComponentState.foundCrates.includes(crateId) ? "Found" : "Active";

    }


    const getChargedValue = (crateId) => {

        var item = thisComponentState.chargesWithValue.find(x => x.key == crateId);

        if (typeof item !== 'undefined') {

            return item.value.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits: 2 });
        }

        return 0;
 
    }


    const pay = () => {

        var isConfirm = window.confirm("Are you sure? \nThis action is irreversible \nIf yes click ok. ");

        if (!isConfirm) {

            return;
        };

        if (thisComponentState.chargedDate === null)
        {
            return alert("Charged date should be defined.");
        }

        if (thisComponentState.operationSuccessful) {

            return alert("You have already submitted the content of this form. Operation will be aborted.");

        }
    
        const postBody = {
            value: {
                item1: props.washedHistoryId,
                item2: thisComponentState.issuedCrates.map(x=>x.id)
            }
        }

        updateState({ ...thisComponentState,isPerformingOperation: true })

        fetch("/Crates/Pay", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status == 401 ? window.location = "/Login" : response.json())
          .then(response => {

              alert(response.message);

              if (response.success) {

                  updateState({ ...thisComponentState, isPerformingOperation: false, operationSuccessful: true });

                  return;
              }

                updateState({ ...thisComponentState,isPerformingOperation: false })

            });
    }


    const deleteCrate = (id) => {

        let isConfirm = window.confirm("This operation will make the crate inactive and will not be part of charges in the future.\nIf this crate is still active and should be  part of future charges please do recovery of crates instead.\nClick ok to continue.");

        if (!isConfirm) {

            return;
        };

 
        const postBody = {
            value: id
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        fetch("/Crates/Inactivate", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                alert(response.message);

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false, operationSuccessful: true,toReload:thisComponentState.toReload+1 });

                    return;
                }

                updateState({ ...thisComponentState, isPerformingOperation: false })

            });
    }

    if (thisComponentState.isLoading) {
        return (
        <div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.showRecoveries) {

        return (<Recoveries agentId={thisComponentState.agentId} washedHistoryId={props.washedHistoryId} back={() => updateState({ ...thisComponentState, showRecoveries: false })} />)
    }


    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" className="form-control" type="button" onClick={()=>props.back()} />
                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div> Agent </div>
                </div>

                <div className="col">

                    <div>{thisComponentState.agentName} </div>

                </div>

            </div>

            <div className="row">
                <div className="col">
                    <div> Charge Date</div>
                </div>

                <div className="col">
                    <input value={thisComponentState.chargedDate} onChange={(e) => updateState({...thisComponentState, chargedDate:e.currentTarget.value }) } type="date" className="form-control" />
                </div>
            </div>
 
            <br />

            <div className="row">

                <div className="col">
                  
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            <input value="Pay" ref={saveInput} className="form-control" type="button" onClick={pay} />
                        </div>

                        <div className="col">
                            <input value="Recoveries" className="form-control" type="button" onClick={() => updateState({...thisComponentState,showRecoveries:true})} />
                        </div>
                    </div>
                </div>

            </div>

            <br />


            <div className="row">
                <div className="col-sm-12">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <td>Action</td>
                                <td>SerialNumber</td>
                                <td> State</td>
                                <td>
                                    <p>First Washed</p>
                                    <br/>
                                    <p>{thisComponentState.firstWashedDate}</p>

                                </td>
                                <td>
                                    <p>Second Washed</p>
                                    <br/>
                                    <p>{thisComponentState.secondWashedDate}</p>
                                </td>
                                <td>
                                    <p>Third Washed</p>
                                    <br/>
                                    <p>{thisComponentState.thirdWashedDate}</p>
                                </td>
                                <td>For Charging</td>
                                <td>Charged Value </td>
                            </tr>

                        </thead>

                        <tbody>

                            {
                                thisComponentState.issuedCrates.map(data =>
                                  
                                    <tr key={uuid()}>

                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => deleteCrate(data.id)} />
                                        </td>

                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {data.description}  </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {getState(data.id)} </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {onFirstWashed(thisComponentState.missingCratesOnFirstWashed,data.id)}  </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {onSecondWashed(thisComponentState.missingCratesOnSecondWashed, data.id)}  </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {onThirdWashed(thisComponentState.missingCratesOnThirdWashed, data.id)}  </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {forCharging(thisComponentState.cratesForCharging,data.id)}  </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {getChargedValue(data.id)}  </td>
                                    </tr>)
                            }

                        </tbody>

                        <tfoot>

                            <tr>

                                <td colSpan="7"> Grand Total </td>
                    
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}> {typeof thisComponentState.totalCharged !== 'undefined' && thisComponentState.totalCharged.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>

                            </tr>

                        </tfoot>

                    </table>

                </div>
            </div>
        </div>                      
 )
}

