import React from 'react';
import Order from '../img/order.jpg';
import Production from '../img/production.jpg';
import BreadOut from '../img/breadout.jpg';
import Invoice from '../img/invoice.jpg';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';



export default function Home() {

    const mystate = useSelector((state) => state.action);

    return (

        <div>

            <div className="row Jury">

                {
                    mystate === 1 && <h1>Welcome to valley bread enterprise resource planning system. </h1>
                }

            </div>

            <hr/>
            <div className="row">
                
                <div>
                </div>
            </div>

            <hr />

            <hr/>

            <div className="row">
                <div className="col">
                    What's new?
                </div>
                <div>
                </div>
             </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <span>
                        On hand inventory on all warehouse can now be viewable by clicking this icon   <FontAwesomeIcon icon={faBuilding} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" /> . The reported inventory is a SAP inventory.                     
                    </span>
                    <br/>                  
                </div>
             
            </div>
           
         </div>

    );

}



