export default function exportToCSV(fileName,csvString) {


    // Create a data URI for the CSV file
    const uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = uri;
    link.download = fileName;

    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);




    //var a = document.createElement('a');
    //a.href = 'data:attachment/csv,' + csvString;
    //a.target = '_blank';
    //a.download = fileName + '.csv';

    //document.body.appendChild(a);
    //a.click();

}