
function getDisplayName (property,metaData) {


    var displayName = "";

    for (var i = 0; i < metaData.length; i++) {

        if (metaData[i].name.toLowerCase() === property.toLowerCase()) {

            displayName = metaData[i].displayName;

            break;

        }
    }

    return displayName;

}

export default getDisplayName;