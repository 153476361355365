import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import exportToCSV from '../Functions/exportToCSV';


export default function InventoryTransferSummary(props) {


    const [thisComponentState, updateState] = useState({

        data: [],
        isInitial: true,
        isLoading: false,
        transferType: "0",
        fromDate: "",
        toDate: "",
        isExporting:false

    });


    async function generate(area) {

        var fromDate = thisComponentState.fromDate;

        var toDate = thisComponentState.toDate;


        if (fromDate === "" || toDate === "") {

            return alert("Please defined a valid date.");
        };

        await fetch("/BreadOuts/BreadOutSummary?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate,
            transferType: thisComponentState.transferType

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.data.length > 0) {

                    updateState({ ...thisComponentState, data: responseData, isLoading: false, isInitial: false });

                } else {

                    updateState({ ...thisComponentState, data: [] });

                    return alert("No available data.");
                }
            });

    };

    const getQty = (array, name) => {

        var filtered = array.find(x => x.shortCode == name);

        if (typeof (filtered) !== 'undefined') {

            return filtered.quantity;
        }
    }



    const exports = () => {

        var fromDate = thisComponentState.fromDate;

        var toDate = thisComponentState.toDate;


        if (fromDate === "" || toDate === "") {

            return alert("Please defined a valid date.");
        };

        updateState({ ...thisComponentState, isExporting: true });

         fetch("/BreadOuts/Export?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate,
    
            transferType: thisComponentState.transferType

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.success) {

                    exportToCSV("BreadOutSummary",responseData.value)

                    updateState({ ...thisComponentState, isExporting: false });


                } else {

                    updateState({ ...thisComponentState, isExporting: false });

                    return alert("No available data.");
                }
            });

    }

    const getTotalPerBread = (shortCode) => {

        var total = 0;

        thisComponentState.data.data.map(perAgent => {

            perAgent.details.map(detail => {

                if (detail.shortCode === shortCode) {

                    var parseValue = parseFloat(detail.quantity);

                    if (!isNaN(parseValue)) {

                        total += parseValue;
                    };
                };
            });

        });


        return total;

    }


    if (thisComponentState.isExporting) {


        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Exporting...
                    </button>
                </div>
            </div>
        </div>)


    }


    if (thisComponentState.isInitial) {


        return (
            <div style={{ float: "left", display: "inline-block" }}>
                <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Date Range</div>

                <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                <input type="button" className="form-control" style={{ width: "80px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                <select class="form-control" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, transferType: e.currentTarget.value })}  >
                    <option value="7">ALL</option>
                    <option selected value="0">Bread Out</option>
                    <option value="1">Returns From Agent</option>
                    <option value="2">Left Over</option>
                    <option value="3">Bad Orders</option>
                    <option value="4">Bread Out Adjustment</option>
                    <option value="5">Agent Inter-Transfer</option>
                    <option value="6">Stock Transfer</option>
                </select>

                <select class="form-control" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, transferType: e.currentTarget.value })}  >
                    <option value="ALL">ALL</option>
                    <option selected value="0">RM</option>
                    <option value="1">OT</option>
                    <option value="2">Left Over</option>
                    <option value="3">Bad Orders</option>
                    <option value="4">Bread Out Adjustment</option>
                    <option value="5">Agent Inter-Transfer</option>
                    <option value="6">Stock Transfer</option>
                </select>



                <br />
                <br />

            </div>
        )
    }

    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {


        if (typeof (thisComponentState.data) != 'undefined' && typeof (thisComponentState.data.data) != 'undefined' && thisComponentState.data.data.length != 0) {

            return (

                <div>
                    <div style={{ float: "left", display: "inline-block" }}>

                        <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Date Range</div>

                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                   
                        <input type="button" className="form-control" style={{ width: "80px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                        <select class="form-select" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, transferType: e.currentTarget.value })}  >
                            <option value="7">ALL</option>
                            <option selected value="0">Bread Out</option>
                            <option value="1">Returns From Agent</option>
                            <option value="2">Left Over</option>
                            <option value="3">Bad Orders</option>
                            <option value="4">Bread Out Adjustment</option>
                            <option value="5">Agent Inter-Transfer</option>
                            <option value="6">Stock Transfer</option>
                        </select>
                        <br />
                        <br />

                    </div>

                    <br />

                    <div>


                        <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                            <thead className="thead-dark">

                                <th>Agent</th>


                                {
                                    Object.entries(thisComponentState.data.shortCodes).map(y =>

                                        <th> {(y[1])}</th>
                                    )
                                }

                            </thead>

                            <tbody>

                                {
                                    thisComponentState.data.data.map(agent =>


                                        <tr>

                                            <td> {agent.agentName}</td>


                                            {
                                                thisComponentState.data.shortCodes.map(shortCode =>

                                                    <td> {getQty(agent.details, shortCode)}

                                                    </td>

                                                )
                                            }



                                        </tr>




                                    )
                                }

                            </tbody>


                            <tfoot>
                                <tr className='thead-dark'>

                                    <td colSpan="1">Grand Total</td>

                                    {
                                        thisComponentState.data.shortCodes.map(shortCode =>

                                            <td> {getTotalPerBread(shortCode)} </td>
                                        )
                                    }

                                </tr>


                            </tfoot>

                        </table>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ float: "left", display: "inline-block" }}>
                    <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Date Range</div>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                    <input type="button" className="form-control" style={{ width: "80px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />
     
                    <select class="form-select" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, transferType: e.currentTarget.value })}  >
                        <option value="7">ALL</option>
                        <option selected value="0">Bread Out</option>
                        <option value="1">Returns From Agent</option>
                        <option value="2">Left Over</option>
                        <option value="3">Bad Orders</option>
                        <option value="4">Bread Out Adjustment</option>
                        <option value="5">Agent Inter-Transfer</option>
                        <option value="6">Stock Transfer</option>
                    </select>


                    <br />
                    <br />

                </div>
            )



        }


    }
}

