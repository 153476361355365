import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { supportsGoWithoutReloadUsingHash } from "history/DOMUtils";
import { useEffect } from "react";
import { useState } from "react";


export default function EditTimeTravel(props) {

    function formatTime(floatingNumber) {
        // Split the floating number into hours and minutes
        const hours = Math.floor(floatingNumber);
        const minutes = Math.round((floatingNumber - hours) * 60);

        // Convert hours to 24-hour format
        const formattedHours = hours < 10 ? '0' + hours : hours;

        // Add leading zeros if necessary
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Construct the time string
        const timeStr = `${formattedHours}:${formattedMinutes}`;

        return timeStr;
    }

    const [isPerformingOperation, setIsPerformingOperation] = useState(false);

    const [thisComponentState, updateState] = useState({
        id: props.id,
        start:props.start,
        end:props.end,
        
    });

    useEffect(() => {

        let startTime = parseFloat (props.start.split(":")[0]) + parseFloat(props.start.split(":")[1])/60;

        let endTime = parseFloat(props.end.split(":")[0]) + parseFloat(props.end.split(":")[1]) / 60;

        updateState({ ...thisComponentState, start: startTime, end: endTime });

    }, []);


    const getStartOption = () => {

        let items = [];

        for (let i = 7; i <= 24; i++) {

            items.push(i);

            i = i - 1;
            i = i + .25;
        };

        return (
            <select className="form-control" value={thisComponentState.start} onChange={(e) => updateState({ ...thisComponentState, start: e.currentTarget.value })}>
                {
                    items.map(val => <option value={val}> {formatTime(val)} {val >= 12 ? "PM" : "AM"}  </option>)
                }

            </select>
        )
    }


    const getEndOption = () => {

        let items = [];

        for (let i = 7; i <= 24; i++) {

            items.push(i);

            i = i - 1;
            i = i + .25;

        };

        return (
            <select className="form-control" value={thisComponentState.end} onChange={(e) => updateState({ ...thisComponentState, end: e.currentTarget.value })}>
                {
                    items.map(val =>

                        <option value={val}> {formatTime(val)} {val >= 12 ? "PM" : "AM"}  </option>
                    )
                }

            </select>
        )
    }

    const editStartTime = async () => {

        let confirmation = window.confirm("Please check your input. Click ok to continue")

        if (!confirmation) {
            return;
        }

        try {

            setIsPerformingOperation(true);

            const postObject = {
                value: {
                    item1:parseInt(thisComponentState.id),
                    item2:parseFloat(thisComponentState.start)
                }
            }

            await fetch(`Trips/EditStartTime`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postObject)
            }).then(response => response.json())
                .then(respsonse => {

                    alert(respsonse.message);

                    if (respsonse.success) {

                        props.back();

                    }
                })

            setIsPerformingOperation(false);

        } catch (error) {
            console.error('Error saving travel distance:', error);
        }
    };


    const editEndTime = async () => {

        let confirmation = window.confirm("Please check your input. Click ok to continue")

        if (!confirmation) {
            return;
        }

        try {

            setIsPerformingOperation(true);

            const postObject = {
                value: {
                    item1:parseInt(thisComponentState.id),
                    item2:parseFloat(thisComponentState.end)
                }
            }

            await fetch(`Trips/EditEndTime`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postObject)
            }).then(response => response.json())
                .then(respsonse => {

                    alert(respsonse.message);

                    if (respsonse.success) {

                        props.back();
                    }

                })

            setIsPerformingOperation(false);

        } catch (error) {
            console.error('Error saving travel distance:', error);
        }
    };


    if (isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (

        <div>

            <div className="row">
                <div className="col-sm-5">
                   
                </div>

                <div className="col-sm-5">
                    <input value="Back" type="button" onClick={()=>props.back()} className="form-control"  />
                </div>

                <div className="col-sm-2">
       
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-sm-5">
                    Start
                </div>

                <div className="col-sm-5">
                    {getStartOption()}
                </div>
                <div className="col-sm-2">
                    <FontAwesomeIcon icon={faSave} title="Save start odo" style={{ marginLeft: "10x", cursor: 'pointer' }} size="2x" color="blue" onClick={() => editStartTime()} />
                </div>

            </div>
            <div className="row">
                <div className="col-sm-5">
                    End
                </div>

                <div className="col-sm-5">
                    {getEndOption()}
                </div>
                <div className="col-sm-2">
                    <FontAwesomeIcon icon={faSave} title="Save end odo" style={{ marginLeft: "10x", cursor: 'pointer' }} size="2x" color="blue" onClick={() => editEndTime()} />
                </div>
            </div>
        </div>

         
        
        )


}