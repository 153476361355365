import React, { useState } from 'react';

import * as signalR from '@microsoft/signalr';
import { useEffect } from 'react';
import { useRef } from 'react';
import UserProfile from './UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileText, faFileVideo, faForward, faPaperclip, faSearch, faTimes, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import GroupChat from './GroupChat';
import UserSelection from './UserSelection';
import uuid from 'react-uuid';


export default function ChatBox(props) {


    const styles = {

        container: {

            position: 'fixed',
            bottom: '16px',
            right: `${16 + props.index * 520}px`, // Adjust the right position based on the index
            minWidth: '500px',
            maxWidth: '500px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            overflow: 'hidden',
            fontFamily: 'Arial, sans-serif',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        },
        header: {
            backgroundColor: '#4267B2',
            color: '#fff',
            padding: '10px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
        },
        chatContainer: {
            padding: '10px',
            minHeight: '400px',
            overflowY: 'auto',
        },

        senderMessage: {
            backgroundColor: '#4267B2',
            color: '#fff',
            padding: '8px',
            borderRadius: '8px',
            marginBottom: '8px',
            maxWidth: '70%',
            marginRigth: 'auto', // Push sender's message to the left
        },
        receiverMessage: {
            backgroundColor: '#eee',
            padding: '8px',
            borderRadius: '8px',
            marginBottom: '8px',
            maxWidth: '70%',
            marginLeft: 'auto', // Push receiver's message to the right
        },



        inputContainer: {
            display: 'flex',
            borderTop: '1px solid #ccc',
            padding: '10px',
            width: '100%'
        },
        input: {
            flex: '1',
            padding: '8px',
            borderRadius: '4px',
            marginRight: '8px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            width: "484px"
        },
        button: {
            padding: '8px',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: '#4267B2',
            color: '#fff',
            border: 'none',
        },
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContent: {
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '400px',
            width: '100%',
        },
        modalButton: {
            backgroundColor: '#4267B2',
            color: '#fff',
            padding: '8px',
            borderRadius: '4px',
            cursor: 'pointer',
            border: 'none',
        },
        filesContainer: {
            marginBottom: '8px',
            display: 'flex',
            flexDirection: 'column', // Change the direction to column
            alignItems: 'flex-start', // Align items to the start of the container
            width: '100%', // Set width to 100%
        },
        inputWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%', // Set width to 100%
        },

    };




    const connectionRef = useRef(null);

    const [messages, setMessages] = useState([]);

    const [message, setMessage] = useState({ ownMessage: false, value: '', messageAttachmentModels: [] });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [ShowGroupManager, showGroupManager] = useState(false);

    const [user, setUser] = useState(props.user);

    const [allUsers, setAllUsers] = useState([]);

    const [connectedUsers, setConnectedUsers] = useState([]);

    const [ShowUserSelection, showUserSelection] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState([]);


    useEffect(() => {

        fetch("/Identity/GetAllUsers")
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                setAllUsers(response);

            });

    }, []);


    useEffect(() => {

        connectionRef.current = new signalR.HubConnectionBuilder()

            .withUrl('/signalRHub')  // Update this to match your SignalR endpoint
            .configureLogging(signalR.LogLevel.Error)
            .withAutomaticReconnect()
            .build();

        const startConnection = async () => {
            try {
                if (connectionRef.current.state === signalR.HubConnectionState.Disconnected) {
                    await connectionRef.current.start();
                    console.log('Connected to SignalR');
                }
            } catch (err) {
                console.error('Error connecting to SignalR:', err);
            }
        };

        startConnection();

        connectionRef.current.on('ReceiveMessage', (message) => {

            let tempData = [...messages];

            tempData.push(message);

            setMessages(tempData);

            fetch('/Chat/OnReceived', {
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                body:JSON.stringify({
                    value: message.guidId
                })
            });
               
        });

        connectionRef.current.on('UpdateConnectedUsers', (connectedUsers) => {

            console.log(connectedUsers);

            setConnectedUsers(connectedUsers);

            props.updateConnectedUsers(connectedUsers);

        });

        connectionRef.current.on('GetPendingMessages', (guidsOfPendingMessages) => {

            fetch('/Chat/ReceivedPendingMessages', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(guidsOfPendingMessages)
            });

        });


        return () => {
            // Cleanup: Stop the connection when the component unmounts
            if (connectionRef.current && connectionRef.current.state === signalR.HubConnectionState.Connected) {

                connectionRef.current.stop();
            }
        };
    }, [messages]);


    const sendMessage = async () => {

        if (message.Message === '' && message.files === 0) {
            return;
        }

        let tempData = [...messages];

        tempData.push(message);

        setMessages(tempData);

        console.log("message to send", message);

        if (connectionRef.current && connectionRef.current.state === signalR.HubConnectionState.Connected) {
            connectionRef.current.invoke('SendToCaller', user, message)
                .then(() => {
                    console.log(user);
                    setMessage({});
                })
                .catch((error) => {
                    console.error('Error sending message:', error);
                });
        }
    };


    const handleSendMessage = (event) => {

        if (event.key === 'Enter' || event.keyCode === 13) {

            sendMessage();
        }

    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (ShowGroupManager) {

        return (<GroupChat />)
    }

    if (ShowUserSelection) {
        return (<UserSelection addUsersToChat={props.addUsersToChat} users={props.users} />)
    }

    // Helper function to get file extension
    const getFileExtension = (fileName) => {
        return fileName.split('.').pop().toLowerCase();
    };


    const [base64Files, set64BaseFiles] = useState([]);

    const handleFileChange = async (event) => {

        const files = event.target.files;

        let tempDatas = [...base64Files];

        for (let i = 0; i < files.length; i++) {

            const selectedFile = files[i];

            // Create a FileReader
            const reader = new FileReader();

            // Set up the FileReader onload event
            reader.onload = (event) => {
                // Get the Base64 string from the result
                const base64String = event.target.result;

                const val = {
                    fileName: uuid(),
                    base64:base64String
                }

                tempDatas.push(val);
            };

        }

        let message = { ...message };

        message.attachments = tempDatas;

        set64BaseFiles(tempData);

        // Create an array of objects with file and extension properties
        const updatedSelectedFiles = Array.from(files).map(file => (
            {
                file: file,
                fileName: file.name,
                extension: getFileExtension(file.name),
            }));

        let tempData = { ...message };

        for (let i = 0; i < updatedSelectedFiles.length; i++) {

            const file = updatedSelectedFiles[i];

            // Read the file as an ArrayBuffer
            const arrayBuffer = await file.file.arrayBuffer();
            const fileData = new Uint8Array(arrayBuffer);

            const fileInfo = {
                fileName: file.fileName,
                file: fileData
            }

            tempData.messageAttachmentModels.push(fileInfo);
        }

        setMessage(tempData);

        setSelectedFiles(files);
    };


    const fileInputRef = useRef(null);

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    const getFileType = (ext) => {

        let fileTypeExtensions = [
            { type: "csv", value: faFileExcel },
            { type: "doc", value: faFileText },
            { type: "docm", value: faFileText },
            { type: "docx", value: faFileText },
            { type: "dot", value: faFileText },
            { type: "gif", value: faFileImage },
            { type: "jpg", value: faFileImage },
            { type: "jpeg", value: faFileImage },
            { type: "mp3", value: faFileVideo },
            { type: "mp4", value: faFileVideo },
            { type: "pdf", value: faFilePdf },
            { type: "pot", value: faFilePowerpoint },
            { type: "potx", value: faFilePowerpoint },
            { type: "pps", value: faFilePowerpoint },
            { type: "ppsm", value: faFilePowerpoint },
            { type: "ppsx", value: faFilePowerpoint },
            { type: "txt", value: faFileText },
            { type: "xlm", value: faFileExcel },
            { type: "xls", value: faFileExcel },
            { type: "xlsx", value: faFileExcel },
            { type: "ltm", value: faFileExcel },
            { type: "lsm", value: faFileExcel }
        ];

        let type = fileTypeExtensions.find(val => val.type === ext);

        return type.value
    }


    const handleUpload = () => {

        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);
        }

        formData.append("receiverId", user.userId);
        formData.append("message", message.message);

        console.log(selectedFiles);

        console.log(formData);

        console.log("ok message value is", JSON.stringify(message));

        fetch('/Chat/SendMessage', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('Files uploaded successfully', data);
            })
            .catch(error => {
                console.error('Error uploading files:', error);
            });
    };


    return (
        <div style={styles.container}>
            <div style={styles.header}>

                <FontAwesomeIcon icon={faUserGroup} title="Create Group" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => showGroupManager(true)} />
                <FontAwesomeIcon icon={faPaperclip} title="Attach file" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={openFileDialog} />
                <FontAwesomeIcon icon={faSearch} title="Search to chat" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => window.location.reload()} />
                <FontAwesomeIcon icon={faTimes} title="Close chat" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => window.location.reload()} />
                <input value="Send" onClick={handleUpload} />

            </div>
            <div style={styles.chatContainer}>
                {messages.map((message, index) => (
                    <div key={uuid()} style={message.ownMessage ? styles.senderMessage : styles.receiverMessage}>

                        <div>
                            {typeof message.urls !== 'undefined' && message.urls.map(url => <div> <a href={url}> {url.split('_')[1]} </a> </div>)}
                        </div>

                        {message.message}

                    </div>
                ))}
            </div>

            <div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    multiple
                />
            </div>

            <div style={styles.inputContainer}>
                <div style={styles.inputWrapper}>
                    {/*<div id="files" style={styles.filesContainer}>*/}
                    {/*    {selectedFiles.length > 0 &&*/}
                    {/*        selectedFiles.map((file) => (*/}
                    {/*            <div key={file.fileName}>*/}
                    {/*                <FontAwesomeIcon*/}
                    {/*                    icon={getFileType(file.extension)}*/}
                    {/*                    title="Search to chat"*/}
                    {/*                    style={{ paddingTop: '5px', cursor: 'pointer' }}*/}
                    {/*                    size="2x"*/}
                    {/*                    color="orange"*/}
                    {/*                />*/}
                    {/*                <span> {file.fileName} </span>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*</div>*/}
                    <div>
                        <input
                            id="message"
                            type="text"
                            style={styles.input}
                            value={message.message}
                            onChange={(e) => setMessage({ ...message, ownMessage: true, message: e.currentTarget.value })}
                            onKeyUp={handleSendMessage}
                            placeholder="Type your message..."
                        />
                    </div>
                </div>
            </div>


            {isModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <button onClick={handleCloseModal}>Close Modal</button>
                    </div>
                </div>
            )}
        </div>
    );
};
