import { useEffect, useState } from "react";



export default function PoDeliveryStatus(props)
{
    const [thisComponentState, updateState] = useState({

        details:[]


    });

    useEffect(() => {

        fetch("/PurchaseOrders/GetPoDeliveryStatus?" + new URLSearchParams({

            guidId: props.guidId

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                var tempData = [...thisComponentState.details];

                tempData = response;

                updateState({ ...thisComponentState, details: tempData });

            });

    }, []);

    return (



        <div>

            <div className="row">

                <div className="col">
                    <input type="button" value="Back" className="form-control" onClick={()=>props.back()}/>
                </div>
                <div className="col">

                </div>
                <div className="col">

                </div>
                <div className="col">

                </div>

            </div>

            <br/>

            <div className="row">

                <div className="col">

                    <table className="table table-bordered">

                        <thead>
                            <tr>
                                <th>ItemCode</th>
                                <th>Name</th>
                                <th>PoQuantity</th>
                                <th>Delivered</th>
                                <th>Open</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.details.map(val =>

                                    <tr>
                                        <td> {val.itemCode} </td>
                                        <td> {val.name} </td>
                                        <td> {val.poQuantity} </td>
                                        <td> {val.poDelivered}</td>
                                        <td> {val.openQty}</td>

                                    </tr>)
                            }

                        </tbody>

                    </table>

                </div>

              

            </div>

        </div>

        )

}