import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react"
import uuid from "react-uuid";
import IncentiveBasedSchedules from "./IncentiveBasedSchedules";


export default function IncentiveSummary(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: false,
        selectedEmployee: "",
        showAttendance: false,
        isInitialized:false,
        data: {
            total: 0,
            incentiveDetailModels:[]
        },
        from: "",
        to: ""
    });



    const generate = () => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/Invoices/GetIncentiveSummary?" + new URLSearchParams({
            start: thisComponentState.from,
            end: thisComponentState.to
        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false,isInitialized:true});

            });
    }


    const move = () => {

        if (!thisComponentState.isInitialized) {
            return alert("Please generate first.");
        }

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/Invoices/SendForApproval", {
            method: "post",
            body: JSON.stringify({

                value: {
                    item1: thisComponentState.from,
                    item2: thisComponentState.to
                }
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    alert(response.message);

                } else {

                    alert("Moving failed.");
                }

                updateState({ ...thisComponentState, isLoading: false });
            });

    }



    if (thisComponentState.isLoading) {

        return (<div>Computating...</div>)
    }


    if (thisComponentState.showAttendance) {

        return (<IncentiveBasedSchedules code={thisComponentState.selectedEmployee} from={thisComponentState.from} to={thisComponentState.to} back={() => updateState({...thisComponentState,showAttendance:false})} />);
    }

    return (

        <div>
            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={() => props.back()} />
                </div>
                <div className="col">
                    <input type="button" className="form-control" value="Move" onClick={move} />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    From
                </div>
                <div className="col">
                    <input type="date" className="form-control" value={thisComponentState.from} onChange={(e) => updateState({ ...thisComponentState, from: e.currentTarget.value })} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    To
                </div>
                <div className="col">
                    <input type="date" className="form-control" value={thisComponentState.to} onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">

                </div>
                <div className="col">
                    <input type="button" className="form-control" value="Generate" onClick={generate} />
                </div>
            </div>

            <hr />

            <div>

                <table className="table table-bordered">
                    <thead>
                        <th>Employee</th>
                        <th>Attendance</th>
                        <th>Incentives</th>

                    </thead>

                    <tbody>

                        {
                            thisComponentState.data.incentiveDetailModels.map(data =>
                                <tr>
                                    <td>{data.name }</td>
                                    <td>
                                        <FontAwesomeIcon icon={faInfoCircle} title="See attendance" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState,selectedEmployee : data.code, showAttendance:true})} />                                     
                                    </td>
                                    <td>
                                        {data.incentive.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}

                                    </td>
                            </tr>)
                        }

                    </tbody>


                    <tfoot>
                        <tr>
                            <td>Grand Total</td>
                            <td></td>
                            <td> {thisComponentState.data.total.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</td>
                        </tr>
                    </tfoot>

                </table>             
            </div>
        </div>

    )

}