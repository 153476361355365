import createDetailProperties from "./createDetailProperties";
import createParentProperties from "./createParentProperties";


async function setMetaData(url, updateStatus, updateMetaData) {

 
    await fetch(url)

        .then(response => response.status == 401 ? window.location = "/Login" : response.json())
        .then(data => {
            
            var tempMetaData = {};

            tempMetaData.parentMetaData = data.parentMetaData;
            tempMetaData.parentProperties = createParentProperties(data.parentMetaData);
            tempMetaData.settings = data.settings;
            tempMetaData.agentId = data.agentId;


            if (Array.isArray(data.detailMetaData[0])) {

                tempMetaData.detailMetaData = data.detailMetaData[0];

            } else {

                tempMetaData.detailMetaData = data.detailMetaData;

            };

            tempMetaData.detailProperties = [];

            tempMetaData.updateStatus = updateStatus;

            updateMetaData({ metaData: tempMetaData });

            updateStatus(true);

        });


};


export default setMetaData;