import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFile, faFolderOpen, faList12, faMoneyBill, faPercent, faPlus, faRedo, faTable, faTimes } from '@fortawesome/free-solid-svg-icons';

import React, {  useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import uuid from 'react-uuid';
import GrPoDrafts from './OpDrafts';
import OutgoingPaymentComponents from './OutgoingPaymentComponents';
import WithholdingTaxComputer from '../AccountsPayable/WithholdingTaxComputer';



export default function CreateOutgoingPaymentNavigator(props) {

    const { data, updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        rawDetails: [],
        showDrafts: false,
        showListOfOutgoingComponents:false,
        showWithholdingTax:false
    });


   
    const draftIt = () => {

        if (typeof data.metaData.parentProperties.code === 'undefined' || data.metaData.parentProperties.code === null) {

            return alert("Please select a vendor.");
        }

        var toSave = window.confirm("Are you sure you want to save this as draft?");

        if (!toSave) {

            return;

        }

        var draft = {};

        
        draft.guidId = uuid();
        draft.preceedingTransactionGuids = data.metaData.preceedingTransactionGuids;

        draft.parentProperties = data.metaData.parentProperties;
        draft.detailProperties = data.metaData.detailProperties;

        var drafts = localStorage.getItem("grPoDrafts");

        var masterfiles = localStorage.getItem("masterfiles");

        if (masterfiles !== null) {

            var pMasterfiles = JSON.parse(masterfiles);

            var vendor = pMasterfiles.vendors.find(val => val.code === draft.parentProperties.code);

            draft.vendor =vendor.name;
        }

        if (drafts !== null) {

            var tempDrafts = JSON.parse(drafts);

            tempDrafts.push(draft);

            localStorage.setItem("grPoDrafts", JSON.stringify(tempDrafts));

            return;
        }

        drafts = [];

        localStorage.setItem("grPoDrafts", JSON.stringify(drafts));

    }



    if (thisComponentState.showDrafts) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <GrPoDrafts back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )

    }


    if (thisComponentState.showListOfOutgoingComponents) {
        
        return (<OutgoingPaymentComponents  back={() => updateState({ ...thisComponentState, showListOfOutgoingComponents:false })}/>)
    };



    return (
  
        <div style={{ float:'left', whiteSpace:'nowrap'}}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faList12} title="List of outgoing components" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showListOfOutgoingComponents: true })} />            
           
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
 
        </div>

    )
}

