
import { useState } from "react";
import uuid from "react-uuid";

export default function DefinedTasks(props) {


    const [thisComponentState, updateState] = useState({

        list: props.initialState,
        budgetTotal: function (data) {

            let grandTotal = 0;

            data.forEach(val => {

                grandTotal = grandTotal +parseFloat(val.budget);

            });

            return grandTotal;
        },
        estimatedDaysTotal: function (data) {

            let grandTotal = 0;

            data.forEach(val => {

                grandTotal = grandTotal +parseFloat(val.estimatedDaysToComplete);

            });

            return grandTotal;
        }

    });


    const onChange = (guidId,name,value) => {

        let tempData = [...thisComponentState.list];

        let item = tempData.find(item => item.guidId === guidId);

        item[name] = value;

        updateState({ ...thisComponentState, list: tempData });

    }

    const add = () => {

        var tempData = [...thisComponentState.list];

        tempData.push({
            guidId: uuid(),
            description: "",
            budget: 0,
            estimatedDaysToComplete : 0
        })

        updateState({ ...thisComponentState, list: tempData });

    }

    const deleteItem = (guidId) => {

        var tempData = [...thisComponentState.list];

        tempData = tempData.filter(list => list.guidId !== guidId);

        updateState({ ...thisComponentState, list: tempData });


    }

    const back = () => {

        //before going back make budget and estimated days to complete to double

        thisComponentState.list.forEach(val => {

            val["budget"] = Number.isNaN(parseFloat(val.budget)) ? 0 : parseFloat(val.budget);
            val["estimatedDaysToComplete"] = Number.isNaN(parseFloat(val.estimatedDaysToComplete)) ? 0 : parseFloat(val.estimatedDaysToComplete);

        });

        props.updateTasks(thisComponentState.list);

        props.back();


    }

    return (

        <div className="row">

            <table className="table table-bordered">

                <thead>
                    <tr>
                        <th className="col-sm-1">Action</th>
                        <th className="col-sm-7">Description</th>
                        <th className="col-sm-2">Budget</th>
                        <th className="col-sm-2">Estimated Days</th>

                    </tr>
                </thead>

                <tbody>

                    {
                        thisComponentState.list.map(val =>

                            <tr key={val.guidId }>

                                <td >
                                    <input type="button" className="form-control" value="Delete" onClick={()=>deleteItem(val.guidId)} />
                                </td>

                                <td >
                                    <input type="text" className="form-control" value={val.description} onChange={(e)=>onChange(val.guidId,"description",e.currentTarget.value)} />
                                </td>

                                <td >
                                    <input type="number" className="form-control" value={val.budget} onChange={(e) => onChange(val.guidId, "budget", e.currentTarget.value)} />
                                </td>

                                <td >
                                    <input type="number" className="form-control" value={val.estimatedDaysToComplete} onChange={(e) => onChange(val.guidId, "estimatedDaysToComplete", e.currentTarget.value)} />
                                </td>

                            </tr>

                        )
                    }
 
                </tbody>

                <tfoot>
                    <tr>
                        <td colSpan="2">
                             Grand Total
                        </td>
                        <td>
                            <input type="number" className="form-control" value={thisComponentState.budgetTotal(thisComponentState.list)} />
                        </td>
                        <td >
                            <input type="number" className="form-control" value={thisComponentState.estimatedDaysTotal(thisComponentState.list)}  />
                        </td>

                    </tr>

                    <tr>
                        <td colSpan="2">

                            <input type="button" value="Add" className="form-control" onClick={add} />

                        </td>
                        <td colSpan="2">

                            <input type="button" value="Back" className="form-control" onClick={back} />

                        </td>
                    </tr>
                        
                </tfoot>


            </table>


        </div>
    );
}

