import { faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react"
import uuid from "react-uuid";
import PureInput from "../../PureInput";

export default function SliceDefinitions(props) {

    const [thisComponentState, updateState] = useState({
        definitions:props.definitions

    });


    const add = () => {

        let tempDatas = [...thisComponentState.definitions ];

        tempDatas.push({
            guidId: uuid(),
            sliceItemCode: "",
            slicePerWhole:0
        });

        updateState({...thisComponentState,definitions:tempDatas});


    }

    const deleteItem = (guidId) => {

        let tempData = [...thisComponentState.definitions];

        tempData = tempData.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, definitions: tempData });

    }

    const onUpdate = (guidId, name, value) => {

        let tempDatas = [...thisComponentState.definitions];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData[name] = name === "sliceItemCode" ? value : parseFloat(value);

        updateState({ ...thisComponentState, definitions: tempDatas });

    }



    return (

        <div>

            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={()=> props.set(thisComponentState.definitions)} />
                </div>
            </div>

            <hr/>

            <div className="row">

                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <FontAwesomeIcon icon={faQuestionCircle} title="Slice Item code should be code in the POS. An item code in SAP can contain multiple pos code." style={{  cursor: 'pointer' }} size="1x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />

                                </th>

                                <th>Slice Item Code</th>
                                
                                <th>Slice </th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.definitions.length > 0 && thisComponentState.definitions.map((val, index) => <tr>
                                    <td >
                                        <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => deleteItem(val.guidId)} />
                                    </td>
                             
                                    <td >
                                        <div>
                                            {index + 1}
                                        </div>
                                    </td>

                                    <td >
                                        <div>
                                            <PureInput type="text" value={val.sliceItemCode} code={val.guidId} name="sliceItemCode" updateValue={onUpdate} />

                                        </div>
                                    </td>
                                    <td >
                                        <div>
                                            <PureInput type="text" value={val.slicePerWhole} code={val.guidId} name="slicePerWhole" updateValue={onUpdate} />
                                        </div>
                                    </td>

                                </tr>)
                            }


                        </tbody>

                        <tfoot>
                            <tr>

                                <td>
                                    <input type="button" value="Add" className="form-control" onClick={add} />
                                </td>

                                <td>
                                </td>

                                <td>

                                </td>
                                <td>

                                </td>
                            </tr >

                        </tfoot>

                    </table>


                </div>

            

            </div>
        </div>

    )

}