import React, { useRef, useState } from 'react';


export default function (props)
{
    const inputRef = useRef();

    const back = () => {

        if (props.commentAssociatedValue === 1 && (inputRef.current.value === "" || inputRef.current.value.length < 20)) {

            return alert("A comment of alteast 20 characters is required for 1 or 2 score.");
        }

        if (props.commentAssociatedValue === 2 && (inputRef.current.value === "" || inputRef.current.value.length < 20)) {

            return alert("A comment of alteast 20 characters is required for 1 or 2 score.");
        }

        if (props.commentAssociatedValue === 5 && (inputRef.current.value === "" || inputRef.current.value.length < 20)) {

            return alert("A comment of alteast 20 characters is required for 1 or 2 score.");
        }

        props.back()
    }


    return (
        <div>

            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={back}/>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <textarea ref={inputRef} className="form-control" style={{minHeight:"400px"}} onChange={(e) => props.onChange(props.mainPerformanceFactorId, props.subPerformanceFactorId,e.currentTarget.value)}>
                    </textarea>
                </div>
            </div>
        </div>
)}