
import { faArrowDown, faArrowRight, faFile, faFileExcel, faFolderOpen, faHand,faListAlt, faPlus,faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import exportToCSV from '../../Functions/exportToCSV';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { toCsvString } from '../../Functions/toCsvString';
import OnHandInventory from '../../Reports/OnHandInventory';
import Drafts from '../Drafts';
import { TransactionContext } from '../TransactionContext';
import ListOfOpenPO from './ListOfOpenPO';
import ListOfOpenSr from './ListOfOpenSr';
import PrForClosure from './PrForClosure';
import PrForMoving from './PrForMoving';
import PrOwnCapture from './PrOwnCapture';
import RevertedPr from './RevertedPr';


export default function CreatePurchaseRequisitionNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isPerformingOperation:false,
        showPrOwnCapture: false,
        showPrForClosure: false,
        showPrForMoving: false,
        showSrForClosure: false,
        showOpenSrForPr: false,
        showOpenSrForSt: false,
        showRevertedPr: false,
        showDrafts: false,
        showRelationshipMap: false,
        showOpenPo:false,
        data: {}
    });

    const pullStockRequest = () => {

        const guidId = JSON.parse(localStorage.getItem("stockRequisitionGuidId"));

        if (guidId === "" || typeof guidId === 'undefined' || guidId === null) {

            return alert("Please move a request.")
        };

        fetch("/PurchaseRequisitions/GetRequest?" + new URLSearchParams({

            guidId: guidId

        })).then(response => response.status == 401 ? window.location = "/login" : response.json())
            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);

                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, stockRequestGuidId: guidId });

                    return;
                }

                return alert(dataFromServer.message);

            })
    }



    const draftIt = () => {

        let value = data.metaData.parentProperties;

        value.details = data.metaData.detailProperties;

        let draft = {
            type: 2,
            value: JSON.stringify(value)
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Base/SaveDraft", {
            method: "post",
            body: JSON.stringify(draft),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }



    if (thisComponentState.showPrOwnCapture) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <PrOwnCapture data={thisComponentState.data.details} back={() => updateState({ ...thisComponentState, showPrOwnCapture: false })} />

                </div>
            </div>
        )
    }

    if (thisComponentState.showPrForClosure) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <PrForClosure back={() => updateState({ ...thisComponentState, showPrForClosure: false })} />

                </div>
            </div>

        )
    }


    if (thisComponentState.showPrForMoving) {
        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <PrForMoving back={() => updateState({ ...thisComponentState, showPrForMoving: false })} />

                </div>
            </div>
        )
    }


    if (thisComponentState.showOpenSrForPr) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ListOfOpenSr type="PR" back={() => updateState({ ...thisComponentState, showOpenSrForPr: false })} />

                </div>
            </div>
        )
    }

    if (thisComponentState.showOpenSrForSt)
    {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <ListOfOpenSr type="ST" back={() => updateState({ ...thisComponentState, showOpenSrForSt: false })} />
                </div>
            </div>
        )
    }


    if (thisComponentState.showRelationshipMap) {

        return (<PurchaseRequisitionRelationshipMap />)
    }

    if (thisComponentState.showRevertedPr) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <RevertedPr back={() => updateState({ ...thisComponentState, showRevertedPr: false })} />
                </div>
            </div>
        )
    }



    if (thisComponentState.showDrafts) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <Drafts type={2} back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )
    }

    if (thisComponentState.showOnHand) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <OnHandInventory
                        back={() => updateState({ ...thisComponentState, showOnHand: false })}
                    />
                </div>
            </div>
        )
    }

    if (thisComponentState.showOpenPo) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <ListOfOpenPO
                        back={() => updateState({ ...thisComponentState,showOpenPo: false })}
                    />
                </div>
            </div>
        )
    }



    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }

    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailProperties);

            exportToCSV("data", csvString);
        };
    }




    return (
        <div style={{ float: "left" }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
            <FontAwesomeIcon icon={faHand} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showOnHand: true })} />
            <FontAwesomeIcon icon={faListAlt} title="List of Pr to Close" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showPrForClosure: true })} />
            <FontAwesomeIcon icon={faArrowDown} title="Pull Request" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={pullStockRequest} />
            <FontAwesomeIcon icon={faListAlt} title="List of Open Sr for PR" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showOpenSrForPr: true })} />
            <FontAwesomeIcon icon={faArrowRight} title="List of Pr to move" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showPrForMoving: true })} />
            <FontAwesomeIcon icon={faListAlt} title="List of Open Sr for ST" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showOpenSrForSt: true })} />
            <FontAwesomeIcon icon={faUndo} title="List of Reverted Pr" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, showRevertedPr: true })} />
            <FontAwesomeIcon icon={faListAlt} title="List of Po to receive" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="maroon" onClick={() => updateState({ ...thisComponentState, showOpenPo: true })} />
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="aqua" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="purple" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
            <FontAwesomeIcon icon={faFileExcel} title="Download excel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

        </div>
    )
}


