import { useEffect } from "react";
import { useState } from "react";



export default function PrDetails(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        data: {
            guidId:"",
            requestor:"",
            requestDate: "",
            requiredDate: "",
            documentId:"",
            isServed:false,
            details: []
        },
        toApprove:props.toApprove
    });


    useEffect(() => {

        pullPurchaseRequisition();

    }, []);


    const pullPurchaseRequisition = () => {

        fetch("/StockRequisitions/GetSrDetails?" + new URLSearchParams({

            guidId: props.guidId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())

            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });
            })
    };

    


    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    return (

        <div>

            <div className="row">
                <div className="col">

                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />

                </div>

                <div className="col">

                    {!thisComponentState.data.isServed && <input type="button" value="Move" className="form-control" onClick={() => props.move(props.guidId)} />}

                </div>

                <div className="col">
                    {thisComponentState.toApprove && <input type="button" value="Approved" className="form-control" onClick={()=>props.approvedOne(props.guidId)} />}
                </div>

                <div className="col"></div>

            </div>

            <br />

            <div className="row">
                <div className="col">
                    <div>Requestor</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestor}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Request Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requiredDate}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.note}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Is Served</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.isServed ? "Y" : "N" }</div>
                </div>
            </div>


            <br />

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Item Name</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Pr Qty</th>
                                <th>Served Qty</th>
                                <th>UomDesc</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.details.map(val =>
                                    <tr key={val.guidId}>
                                        <td>{val.code}</td>
                                        <td>{val.itemName}</td>
                                        <td>{val.description}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.prQty}</td>
                                        <td>{val.servedQty}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>
                                        <td>{val.amount}</td>

                                    </tr>
                                )

                            }

                        </tbody>

                    </table>
                </div>
            </div>
        </div>

    )
}