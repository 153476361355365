import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import EditTimeTravel from "./EditTimeTravel";


export default function ShowEvent(props)
{

    const [toEdit, setToEdit] = useState(false);
    const [reload, toReload] = useState(false);

    const [thisComponentState, updateState] = useState({
        data: {},
        isLoading:true
    });

  

    useEffect(() => {

        fetch('Bookings/GetDetails?id=' + props.id)
            .then(response => {
                // Check if response is successful (status code 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse response as JSON
                return response.json();
            })
            .then(data => {

                updateState({ ...thisComponentState, data: data, isLoading: false });

            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Fetch error:', error);
            });

    }, [reload]);


    const deleteBooking = () => {

        let confirmation = window.confirm("You can only delete your own booking? Click ok to continue.");

        if (!confirmation) {
            return;
        }

        fetch("/Bookings/Delete", {
            method: "POST", // Use "POST" method for sending data
            headers: {
                "Content-Type": "application/json" // Specify the content type as JSON in the headers
            },
            body: JSON.stringify({
                value: thisComponentState.data.id
            })
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/login";
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    return response.json();
                }
            })
            .then(response => {

                alert(response.message);

                if (response.success) {
                    props.back();
                }
            })
            .catch(error => {
                console.error("Error:", error);
                // Handle error
            });

    }

    const back = () => {

        setToEdit(false);
        toReload(reload + 1);

    }
   

    if (toEdit) {


        return (
            
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <EditTimeTravel id={thisComponentState.data.id} start={props.timeValue.start} end={props.timeValue.end} back={back}/>

                </div>
            </div>
            )
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>

            <div className="row">
                <div className="col">
                </div>

                <div className="col">
                    <input value="Back" type="button" onClick={()=>props.back()} className="form-control"/>
                </div>

                <div className="col">
                    <input value="Delete" type="button" onClick={deleteBooking} className="form-control" />
                </div>

                <div className="col">

                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    Time
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col-sm-10">
                            {thisComponentState.data.time}
                        </div>

                        <div className="col-sm-2">
                            <FontAwesomeIcon icon={faEdit} title="Edit event time" style={{ cursor: 'pointer' }} color="blue" onClick={()=> setToEdit(true)} />                       
                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col">
                    Event
                </div>
                <div className="col">
                    {thisComponentState.data.description}
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Book By
                </div>
                <div className="col">
                    {thisComponentState.data.bookBy}
                </div>
            </div>

        </div>
    )
}