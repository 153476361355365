import React, {useState } from 'react';

import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import exportToCSV from '../Functions/exportToCSV';

export default function PrSummary(props) {


    const [thisComponentState, updateState] = useState({

        data: [],
        fromDate: "",
        toDate: "",
        isExporting: false

    });




    const exports = () => {

        var fromDate = thisComponentState.fromDate;

        var toDate = thisComponentState.toDate;

        if (fromDate === "" || toDate === "") {

            return alert("Please defined a valid date.");
        };

        updateState({ ...thisComponentState, isExporting: true });

        fetch("/PurchaseRequisitions/ExportPrSummary?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate

        }),
            {
                method: "GET",
                headers: {
                    "Accept": "text/csv"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {


                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isExporting: false });

            });
    }



    if (thisComponentState.isExporting) {

        return (<div>Please wait </div>)

    } else {


        return (

            <div>
                <div style={{ float: "left", display: "inline-block" }}>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />              
                    <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                    <br />
                    <br />

                </div>

                <br />

                <div>

                    <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                        <thead className="thead-dark">

                            {
                              thisComponentState.data.length > 0 &&  Object.entries(thisComponentState.data[0]).map(y =>

                                    y[0] !== "guidId" ? <td>{capitalizeFirstLetter(y[0])}  </td> : <td> Actions  </td>
                                )
                            }

                        </thead>

                        <tbody>

                            {
                              thisComponentState.data.length > 0 &&  thisComponentState.data.map(x =>
                                    <tr>

                                        {
                                            Object.entries(x).map(y =>

                                                y[0] !== "guidId" ? <td>{y[1]}</td> : <td> <input className="btn btn-success" style={{ marginLeft: "15px", marginRight: "15px", width: "120px" }} value="Details" type="button" /> </td>

                                            )
                                        }
                                    </tr>
                                )
                            }

                        </tbody>

                    </table>
                </div>
            </div>
        )

    }
}

