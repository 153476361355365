import React from 'react';
import { Route } from 'react-router';

import Home  from './components/Home';
import './custom.css'
import OrderForm from './components/Transactions/Ordering/OrderForm';
import ProductionForm from './components/Transactions/Producing/ProductionForm';
import BreadOutForm from './components/Transactions/BreadOut/BreadOutForm';
import InvoiceForm from './components/Transactions/Invoicing/InvoiceForm';
import Details from './components/Details';
import PurchaseRequisitionForm from './components/Transactions/PurchaseRequisition/PurchaseRequisitionForm';
import Departments from './components/Masterfiles/Departments';
import TransactionList from './components/Transactions/TransactionList';
import StockRequisitionForm from './components/Transactions/StockRequisition/StockRequisitionForm';
import GoodsIssueForm from './components/Transactions/GoodsIssue/GoodsIssueForm';
import RecipientAssets from './components/Masterfiles/RecipientAssets';
import PurchaseOrderForm from './components/Transactions/PurchaseOrder/PurchaseOrderForm';
import ReturnForm from './components/Transactions/Return/ReturnForm';
import CauseOfBO from './components/Masterfiles/CauseOfBO';
import BadOrderForm from './components/Transactions/BadOrder/BadOrderForm';
import Approvers from './components/Masterfiles/Approvers';
import TransactionForApproval from './components/Transactions/TransactionForApproval';
import Branches from './components/Masterfiles/Branches';
import Login from './components/Identity/Login';
import Register from './components/Identity/Register';
import IdentityClaim from './components/Identity/IdentityClaim';
import ArCreditMemoForm from './components/Transactions/ArCreditMemo/ArCreditMemoForm';
import RoleClaims from './components/Identity/RoleClaim';
import RoleAssignments from './components/Identity/RoleAssignment';
import Logout from './components/Identity/Logout';
import ProductionComparer from './components/DashBoard/ProductionComparer';
import ProductionSummary from './components/Reports/ProductionSummary';
import DailyOrderSummary from './components/Reports/DailyOrderSummary';
import PeriodOrderSummary from './components/Reports/PeriodOrderSummary';
import DailyInvoiceSummary from './components/Reports/DailyInvoiceSummary';
import DailyProductionPlanned from './components/Reports/DailyProductionPlanned';
import AuthorizationCode from './components/Masterfiles/AuthorizationCode';
import PushForm from './components/Reports/PushForm';
import OrderSummary from './components/Reports/OrderSummary';
import PushFormWithCustomer from './components/Reports/PushFormWithCustomer';
import EndingInventory from './components/Reports/EndingInventory';
import BreadOutSummary from './components/Reports/BreadOutSummary';
import InventoryTransferSummary from './components/Reports/InventoryTransferSummary';
import StockTransferForm from './components/Transactions/StockTransfer/StockTransferForm';
import OnHandInventory from './components/Reports/OnHandInventory';
import { GoodsReceiptForm } from './components/Transactions/GoodsReceipt/GoodsReceiptForm';
import Announce from './components/Announce';
import Inventories from './components/Masterfiles/Inventories';
import ProductionBreadOutReconciliation from './components/Reports/ProductionBreadOutReconciliation';
import NotGoodSummary from './components/Reports/NotGoodSummary';
import CrateInventory from './components/Transactions/Crates/CrateInventory';
import WashedCrate from './components/Transactions/Crates/WashedCrate';
import Disassemble from './components/Transactions/Producing/Disassemble';
import ChangePassword from './components/Identity/ChangePassword';
import Trip from './components/Transactions/ConferenceBooking/ConferenceBooking';
import SelectTypeRequest from './components/Transactions/StockRequisition/SelectTypeRequest';
import JobOrder from './components/Transactions/JobOrders/JobOrder';
import DieselRequest from './components/Transactions/StockRequisition/DieselRequest';
import CreateVendor from './components/Masterfiles/Vendor/CreateVendor';
import CreateInventory from './components/Masterfiles/Inventory/CreateInventory';
import AgentsToCharge from './components/Transactions/Crates/AgentsToCharge';
import PrSummary from './components/Reports/PrSummary';
import PrDetailSummary from './components/Reports/PrDetailSummary';
import PoSummary from './components/Reports/PoSummary';
import PoDetailSummary from './components/Reports/PoDetailSummary';
import ReturnSummary from './components/Reports/ReturnSummary';
import ReturnDetailSummary from './components/Reports/ReturnDetailSummary';
import BoSummary from './components/Reports/BoSummary';
import BoDetailSummary from './components/Reports/BoDetailSummary';
import { AccountsPayableForm } from './components/Transactions/AccountsPayable/AccountsPayableForm';
import { ApCreditMemoForm } from './components/Transactions/ApCreditMemo/ApCreditMemoForm';
import { OutgoingPaymentForm } from './components/Transactions/OutgoingPayments/OutgoingPaymentForm';
import PerformanceHome from './components/Transactions/Performance/PerformanceHome';
import Layout from './components/Layout';
import ChatBoxContainer from './Chat/ChatBoxContainer';
import EvaluationForm from './components/Transactions/Performance/EvaluateForm';
import CashAdvance from './components/Transactions/CashAdvances/Create';
import SelectConference from './components/SelectConference';
import Administration from './components/Administration';
import ConferenceBooking from './components/Transactions/ConferenceBooking/ConferenceBooking';
import TripBooking from './components/Transactions/TripBooking/TripBooking';


export default function App() {

    return (

          <div>
              <Layout>
                  {<Route exact path='/Login' component={Login} />}
                  {<Route exact path='/' component={Login} />}
                  {<Route path='/Home' component={Home} />}    
                  <Route path='/Order' component={OrderForm} />
                  <Route path='/Production' component={ProductionForm} />
                  <Route path='/BreadOut' component={BreadOutForm} />
                  <Route path='/GoodsIssue' component={GoodsIssueForm} />
                  <Route path='/Invoice' component={InvoiceForm} />
                  <Route path='/Details' component={Details} />
                  <Route path='/IdentityClaim' component={IdentityClaim} />
                  <Route path='/RoleClaim' component={RoleClaims} />
                  <Route path='/RoleAssignment' component={RoleAssignments} />                
                  <Route path='/Logout' component={Logout} />
                  <Route path='/ProductionComparer' component={ProductionComparer} />
                  <Route path='/ProductionSummary' component={ProductionSummary} />
                  <Route path='/DailyOrderSummary' component={DailyOrderSummary} />
                  <Route path='/PeriodOrderSummary' component={PeriodOrderSummary} />
                  <Route path='/DailyInvoiceSummary' component={DailyInvoiceSummary} />
                  <Route path='/NotGoodSummary' component={NotGoodSummary} />
                  <Route path='/EndingInventory' component={EndingInventory} />
                  <Route path='/OnHandInventory' component={OnHandInventory} />
                  <Route path='/ProdBreadOutRecon' component={ProductionBreadOutReconciliation} />
                  <Route path='/DailyProductionPlan' component={DailyProductionPlanned} />
                  <Route path='/PushForm' component={PushForm} />
                  <Route path='/OrderSummary' component={OrderSummary} />
                  <Route path='/PushFormWithCustomer' component={PushFormWithCustomer} />
                  <Route path='/BreadOutSummary' component={BreadOutSummary} />
                  <Route path='/InventoryTransferSummary' component={InventoryTransferSummary} />
                  <Route path='/Departments' component={Departments} />
                  <Route path='/Inventories' component={Inventories} />
                  <Route path='/TransactionList' component={TransactionList} />
                  <Route path='/PurchaseRequisition' component={PurchaseRequisitionForm} />
                  <Route path='/PurchaseOrder' component={PurchaseOrderForm} />
                  <Route path='/StockRequisition/:parameter' key={Date.now()} component={StockRequisitionForm} />
                  <Route path='/GoodsReceipt'  component={GoodsReceiptForm} />
                  <Route path='/StockTransfer'  component={StockTransferForm} />
                  <Route path='/RecipientAssets' component={RecipientAssets} />
                  <Route path='/Approvers' component={Approvers} />
                  <Route path='/Branches' component={Branches} />
                  <Route path='/Return' component={ReturnForm }/>
                  <Route path='/BadOrder' component={BadOrderForm} />
                  <Route path='/TransactionForApproval' component={TransactionForApproval} />        
                  <Route path='/CauseOfBO' component={CauseOfBO} />
                  <Route path='/Register' component={Register }/>
                  <Route path='/ArCreditMemo' component={ArCreditMemoForm} />
                  <Route path='/AuthorizationCode' component={AuthorizationCode} />
                  <Route path='/Announce' component={Announce} />
                  <Route path='/CratesInventory' component={CrateInventory} />
                  <Route path='/WashedCrates' component={WashedCrate} />
                  <Route path='/Disassemble' component={Disassemble} />
                  <Route path='/AccountsPayable' component={AccountsPayableForm} />
                  <Route path='/ChangePassword' component={ChangePassword} />
                  <Route path='/Trip' component={Trip} />
                  <Route path="/SelectTypeRequest" component={SelectTypeRequest} />
                  <Route path="/JobOrder" component={JobOrder} />
                  <Route path="/DieselRequest" component={DieselRequest} />
                  <Route path="/Vendors" component={CreateVendor} />
                  <Route path="/Inventories" component={CreateInventory} />
                  <Route path="/AgentsToCharge" component={AgentsToCharge} />
                  <Route path="/PrSummary" component={PrSummary} />
                  <Route path="/PrDetailSummary" component={PrDetailSummary} />
                  <Route path="/PoSummary" component={PoSummary} />
                  <Route path="/PoDetailSummary" component={PoDetailSummary} />
                  <Route path="/ReturnSummary" component={ReturnSummary} />
                  <Route path="/ReturnDetailSummary" component={ReturnDetailSummary} />
                  <Route path="/BoSummary" component={BoSummary} />
                  <Route path="/BoDetailSummary" component={BoDetailSummary} />
                  <Route path="/OutgoingPayment" component={OutgoingPaymentForm}/>
                  <Route path="/ApCreditMemo" component={ApCreditMemoForm} />
                  <Route path="/PerformanceHome" component={PerformanceHome} />
                  <Route path="/ChatBoxContainer" component={ChatBoxContainer}/>
                  <Route path="/Evaluate/:id" component={EvaluationForm} />
                  <Route path="/CashAdvance" component={CashAdvance} />
                  <Route path="/Conference" component={SelectConference} />
                  <Route path="/Administration" component={Administration} />
                  <Route path="/ConferenceBooking/:parameter" component={ConferenceBooking} />
                  <Route path="/TripBooking" component={TripBooking} />


              </Layout>
          </div>
    );
}
