import { useState } from "react";
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";





export default function (props) {
    const [thisComponentState, updateState] = useState({

        documentId: "",
        sapId: "",
        itemCode: "",
        requestorId: "",
        departmentId: "",
        requestDate: "",
        requiredDate: ""

    })

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, requestorId: code });
    }

    const updateDepartmentId = (code) => {

        updateState({ ...thisComponentState, departmentId: code });

    }
    const updateItemCode = (code) => {

        updateState({ ...thisComponentState,itemCode: code });

    }
    const back = () => {

        let criterias = {};

        if (thisComponentState.documentId !== "") {
            criterias.documentId = thisComponentState.documentId;
        };

        if (thisComponentState.requestorId !== "") {
            criterias.requestorId = thisComponentState.requestorId;
        };

        if (thisComponentState.departmentId !== "") {
            criterias.departmentId = thisComponentState.departmentId;
        };

        if (thisComponentState.requestDate !== "") {
            criterias.requestDate = thisComponentState.requestDate;
        };

        if (thisComponentState.requiredDate !== "") {
            criterias.requiredDate = thisComponentState.requiredDate;
        };

        if (thisComponentState.sapId !== "") {
            criterias.requiredDate = thisComponentState.requiredDate;
        };

        if (thisComponentState.itemCode !== "") {
            criterias.requiredDate = thisComponentState.requiredDate;
        };

        props.filter(criterias);


    }


    return (
        <div>
            <br />
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("500px", "500px")}>

                    <div className="row">
                        <div className="col">
                            Filter By Document Id
                        </div>
                        <div className="col">
                            <input type="text" value={thisComponentState.documentId} className="form-control" onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            Filter By Sap Id
                        </div>
                        <div className="col">
                            <input type="text" value={thisComponentState.sapId} className="form-control" onChange={(e) => updateState({ ...thisComponentState, sapId: e.currentTarget.value })} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Filter By Item Code
                    </div>
                    <div className="col">
                        <div className="col">
                            <StandardAutoComplete key={uuid()} url="/Inventories/GetInventories" value={thisComponentState.itemCode} clear={() => updateState({ ...thisComponentState,itemCode: "" })} updateValue={updateItemCode} placeholder="Item Search" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Filter By Requestor
                    </div>
                    <div className="col">
                        <StandardAutoComplete key={uuid()} url="/Employees/GetEmployees" value={thisComponentState.requestorId} clear={() => updateState({ ...thisComponentState, requestorId: "" })} updateValue={updateEmployeeId} placeholder="Employee Search" />
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        Filter By Department
                    </div>

                    <div className="col">
                        <StandardAutoComplete key={uuid()} url="/Departments/GetDepartments" value={thisComponentState.departmentId} clear={() => updateState({ ...thisComponentState, departmentId: "" })} updateValue={updateDepartmentId} placeholder="Department Search" />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Filter By Request Date
                    </div>

                    <div className="col">
                        <input type="date" value={thisComponentState.requestDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requestDate: e.currentTarget.value })} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Filter By Required Date
                    </div>

                    <div className="col">
                        <input type="date" value={thisComponentState.requiredDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">

                    </div>

                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={back} />
                    </div>
                </div>

            </div>
        </div>
  

       

        
   )
}