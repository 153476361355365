
import React, { useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import StandardAutoComplete from '../../StandardAutocomplete';
import InvoiceDetails from './InvoiceDetails';




export default function SyncBranch(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: false,
        isSyncing: false,
        isDeleting:false,
        from: "",
        to:"",
        values: [],
        branchId: "",
        showDetails: false,
        guidId:""

    });


    const retrieve = () => {

        updateState({ ...thisComponentState, isLoading: true });

        let params = {
            from: thisComponentState.from,
            to :thisComponentState.to
        };

        if (thisComponentState.branchId !== "") {
            params.branchId = thisComponentState.branchId;
        }

        fetch("/Invoices/GetBranchInvoices?" + new URLSearchParams(params)).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.length === 0) {

                    return alert("No data found.");
                }

                updateState({
                    ...thisComponentState,
                    isLoading:false,
                    values : response
                })
            });
    }

    const sync = (guidId) => {

        updateState({ ...thisComponentState, isSyncing: true });

        fetch("/Invoices/Forward", {
            method: "post",
            body: JSON.stringify({

                value: guidId
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState.values];

                    let tempData = tempDatas.find(val => val.guidId === guidId);

                    tempData.sapId = response.docNum;

                    updateState({ ...thisComponentState, values: tempDatas,isSyncing: false });

                    return;

                } else {

                    updateState({ ...thisComponentState, isSyncing: false, values:[]});

                   return alert(response.message);

                }
         });

    }

    const deleteInvoice = (guidId) => {

        let confirmation = window.confirm("Are you sure you want to delete this? Click ok to continue.");

        if (!confirmation) {
            return;
        }

        updateState({ ...thisComponentState, isDeleting: true });

        fetch("/Invoices/DeleteUnsyncInvoice", {
            method: "post",
            body: JSON.stringify({

                value: guidId
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState.values];

                    let result = tempDatas.filter(val => val.guidId !== guidId);

                    updateState({ ...thisComponentState, values:result,isDeleting: false });

                    return;

                } else {

                    
                    return alert(response.message);
                }
            });
    }



    if (thisComponentState.isSyncing || thisComponentState.isDeleting) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });

    }


    if (thisComponentState.showDetails) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <InvoiceDetails back={() => updateState({ ...thisComponentState, showDetails: false })} guidId={thisComponentState.guidId}/>

                    </div>
                </div>
            )
    }





    return (

        <div style={getModalStyle()} >

            <div style={getModalContentStyle()}>

                <div className="row">

                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={()=> props.back()}/>
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" onChange={(e) => updateState({...thisComponentState,from:e.currentTarget.value})}/>
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} />
                    </div>

                    <div>
                        <StandardAutoComplete url="/Branches/GetBranches" clear={() => updateState({ ...thisComponentState, branchId: "" })} updateValue={updateBranchId} />
                    </div>
             
                    <div className="col">
                        <input value="Retrieve" type="button" onClick={retrieve } className="form-control"/>
                    </div>
                  
                </div>

                <hr/>

                <div className="row">

                    <div className="col">
                        <table className="table table-bordered">

                            <thead>
                                <tr>
                                    <th colSpan="3">Action</th>
                                    <th>Date</th>
                                    <th>Sap Id</th>
                                    <th>Document Id</th>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Agent</th>  
                                    <th>Amount</th>
                             
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.values !== 'undefined' && thisComponentState.values.map((val) =>

                                        <tr key={val.guidId}>

                                            <td>
                                                <input type="button" className="form-control" value="Sync" onClick={()=>sync(val.guidId)}/>
                                            </td>
                                            <td>
                                                <input type="button" className="form-control" value="Delete" onClick={()=>deleteInvoice(val.guidId)} />
                                            </td>
                                            <td>
                                                <input type="button" className="form-control" value="Details" onClick={() => updateState({...thisComponentState,guidId:val.guidId,showDetails:true})}/>
                                            </td>
                                            <td> {val.date}</td>
                                            <td> {val.sapId}</td>
                                            <td> {val.documentId}</td>
                                            <td> {val.code}</td>
                                            <td> {val.name}</td>
                                            <td> {val.agent}</td>
                                            <td> {val.amount}</td>
                                         
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>  
                </div>
            </div>
        </div>      
   )
}