import { faBatteryFull, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type } from 'jquery';
import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from "react";
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import StandardAutoComplete from '../../StandardAutocomplete';
import PoChangeValue from './CreatePO';


export default function RawMatSummaryRequest(props)
{
    const [thisComponentState, updateState] = useState({

        data : {
            codes: [],
            branches: [],
            rawMatSummaryRequestModels: []      
        },
        date: "",
        isGeneratingPDF: false,
        toPost:false,
        poDate: "",
        supplierId: "",
        isPosting: false,
        documentId : null
    });


    const getValue = (data,branchId,guidId) => {
   
        const branch = data.find(x => x.branchId === branchId);

        const item = branch.itemCodeQuantities.find(x => x.guidId === guidId);

        return typeof item !== 'undefined' ? item.qty +"  "+ item.uom +" @ " +item.price : "";

    }

    useEffect(() => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today =yyyy + '-' + mm + '-' + dd;

        updateState({ ...thisComponentState, date: today });


    }, []);

    const retrieve = () => {

        updateState({...thisComponentState,isRetrieving:true})

         fetch("/StockRequisitions/Retrieve"+ "?" + new URLSearchParams({

                date: thisComponentState.date

            }))
            .then(response =>

                response.status == 401 ? window.location = "/Login" : response.json()

            ).then(response => {

                let tempData = { ...thisComponentState.data};

                tempData.branches = response.branches;
                tempData.codes = response.codes;
                tempData.rawMatSummaryRequestModels = response.rawMatSummaryRequestModels;

                updateState({ ...thisComponentState,data: tempData })


            });
    }

    const preview = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        fetch("/PurchaseOrders/PrintErrandPo?date="+thisComponentState.date,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);

                window.open(file);

            });
    }

    if (thisComponentState.isGeneratingPDF) {

        return <div>Please wait... </div>

    }


    if (thisComponentState.isRetrieving) {

        return <div>Please wait... </div>

    }

    const refreshConnection = () => {

        fetch("Generics/RefreshConnection").then(response => response.status === 401 ? window.location = "/login" : response.json());
    }


    const updateValue = (branchId,guidId,name,value) => {

        let tempData = { ...thisComponentState.data };

        let  branch = tempData.rawMatSummaryRequestModels.find(x => x.branchId === branchId);

        let item = branch.itemCodeQuantities.find(x => x.guidId ===guidId);

        if (name === "quantity") {

            item.qty = parseFloat(value);

        } else {

            item.price = parseFloat(value);
        }

        updateState({ ...thisComponentState, data: tempData });

        refreshConnection();
    }


    const createPO = () => {

        //Remove branch with zero item code quantities.

        thisComponentState.data.rawMatSummaryRequestModels = thisComponentState.data.rawMatSummaryRequestModels.filter(val => val.itemCodeQuantities.length !== 0);

        var postBody = {

            date: thisComponentState.date,
            supplierId: thisComponentState.supplierId,
            rawMatSummaryRequestModels: thisComponentState.data.rawMatSummaryRequestModels
        };
   
        updateState({ ...thisComponentState, isPosting: true });

        fetch("/PurchaseOrders/CreateDailyErrandPo", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

        // Adding headers to the request
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(response => response.status === 401 ? window.location = "/Login" : response.json())

        .then(response => {

            alert(response.message);

            updateState({ ...thisComponentState, isPosting: false,documentId:response.documentId});

        });
    }


    const getSum = () => {

        let sum = 0;

        for (let i = 0; i < thisComponentState.data.rawMatSummaryRequestModels.length;i ++)
        {
            for (let j = 0; j < thisComponentState.data.rawMatSummaryRequestModels[i].itemCodeQuantities.length; j++) {

                let qty =parseFloat(thisComponentState.data.rawMatSummaryRequestModels[i].itemCodeQuantities[j].qty);
                let price = parseFloat(thisComponentState.data.rawMatSummaryRequestModels[i].itemCodeQuantities[j].price);

                sum += qty * price;

            }
        }

        return sum;
    }

    const updateSupplierId = (code) => {

        updateState({ ...thisComponentState, supplierId: code });

    };


    const savingComponent = () => {
        return (

            <div style={getModalStyle(10)}>
                <div style={getModalContentStyle()}>
                    <div style={{ textAlign: "center" }}>
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Saving...
                        </button>
                    </div>
                </div>

            </div>

        )
    };


    if (thisComponentState.toPost)
    {
        return (

         
            <form>
                <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-2 col-form-label">Supplier</label>
                    <div className="col-sm-10">

                        <StandardAutoComplete key={200} url="/Vendors/GetVendors" value={thisComponentState.supplierId} updateValue={updateSupplierId} placeholder="Enter Vendor" />
                    </div>
                </div>

                <div className="form-group row">
                    <label for="inputPassword3" className="col-sm-2 col-form-label">Date</label>
                    <div className="col-sm-10">
                        <input type="date" className="form-control"  onChange={(e) => updateState({ ...thisComponentState, date: e.currentTarget.value })} />
                    </div>
                </div>


                <div className="form-group row">
                    <label for="inputPassword3" className="col-sm-2 col-form-label">Grand Total</label>
                    <div className="col-sm-10">
                        <input type="text" value={getSum()} className="form-control" placeholder="Password"/>
                    </div>
                </div>

                <div className="form-group row">
                    <label for="inputPassword3" className="col-sm-2 col-form-label"></label>
                    <div className="col-sm-5">
                        <input type="button" value="Save" className="form-control"  onClick={createPO} />
                    </div>
                    <div className="col-sm-5">
                        <input type="button" value="Back" className="form-control"  onClick={() => updateState({...thisComponentState,toPost:false})} />
                    </div>
                </div>

                {
                    thisComponentState.documentId !== null ? <div>DocumentId is {thisComponentState.documentId} </div> : <Fragment/>
                }



                {
                    thisComponentState.isPosting ? savingComponent() : <Fragment/>
                }

            </form>  
        )
    }





    const deleteItem = (guidId) => {

        let tempDatas = thisComponentState.data;

        let tempData = tempDatas.rawMatSummaryRequestModels;

        for (let i = 0; i < tempData.length; i++) {

            for (let j = 0; j < tempData[i].itemCodeQuantities.length; j++) {

                if (tempData[i].itemCodeQuantities[j].guidId === guidId) {

                    tempData[i].itemCodeQuantities = tempData[i].itemCodeQuantities.filter(val => val.guidId !== guidId);

                    break;

                }               
            }
        }

        tempDatas.codes = tempDatas.codes.filter(val => val.guidId !== guidId);

        updateState({ thisComponentState, data: tempDatas });


    }


    return (

        <div>        
                <input type='date' value={thisComponentState.date} style={{ float: 'left', width: '200px' }} className='form-control' onChange={(e) => updateState({ ...thisComponentState, date: e.currentTarget.value })} />

                <input type='button' value='Back' style={{ float: 'left', width: '100px', marginLeft: "5px" }} className='form-control' onClick={() => props.back()} />

                <input type='button' value='Retrieve' style={{ float: 'left', width: '100px', marginLeft: "5px" }} className='form-control' onClick={retrieve} />

                <input type='button' value='Print' style={{ float: 'left', width: '100px', marginLeft: "5px" }} className='form-control' onClick={preview} />

                <input type='button' value='Create PO' style={{ float: 'left', width: '100px', marginLeft: "5px" }} className='form-control' onClick={() => updateState({ ...thisComponentState, toPost: true })} />
   
            <br/>
            <br/>

            <table className='table table-bordered'>

                <thead className='thead-dark'>
                    <th>Action</th>
                    <th>Item</th>

                    {
                        thisComponentState.data.branches.map(branch => <th>  {branch} </th>)
                    }
                </thead>

                <tbody>

                    {
                        thisComponentState.data.codes.map(item =>
                            <tr key={item.guidId}>
                                <td>
                                    <FontAwesomeIcon icon={faTrash} title="Relationship Map" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={()=>deleteItem(item.guidId)} />
                                </td>

                                <td> { item.itemName }</td>
                                {
                                    thisComponentState.data.branches.map(branchId => <td key={item.guidId+branchId}> <PoChangeValue updateValue={updateValue} branchId={branchId} code={item.guidId} value={getValue(thisComponentState.data.rawMatSummaryRequestModels, branchId, item.guidId)} /></td>)
                                }
                            </tr>
                        )
                    }

                </tbody>

            </table>

            <input type='text' value="Total" style={{ float: 'left', width: '100px', marginLeft: "5px" }} className='form-control' />

            <input type='text' value={getSum()} style={{ float: 'left', width: '100px', marginLeft: "5px" }} className='form-control' />


       </div>
             
    )

}

