import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCross, faX } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import StandardAutoComplete from '../../StandardAutocomplete';



export default function DailyDiesel(props) {

    const [thisComponentState, updateState] = useState({

        isLoading:false,
        requiredDate:"",
        values:[]
    });


    const generate = () => {

        let confirm = window.confirm("Make sure you are selecting the correct required date?\nClick ok to continue." );

        if (!confirm) {
            return;
        }

        updateState({ ...thisComponentState,isLoading:true});

        fetch("/StockRequisitions/GetDailyDiesel?" + new URLSearchParams({

            requiredDate: thisComponentState.requiredDate

        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    isLoading: false,
                    values: response

                });

            });
       
    }


    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" id="backButton" className="form-control" type="button" onClick={()=>props.back()} />
                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div>Required Date </div>
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.requiredDate} type="date" onChange={(e) => updateState({...thisComponentState,requiredDate:e.currentTarget.value })}/>
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                </div>

                <div className="col">

                    <input className="form-control" value="Generate" type="button" onClick={generate} />

                </div>

            </div>

            <hr/>

            <br />

            <div className="row">
                <div className="col-sm-12">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                           
                                <td>
                                    <p>Doc Id</p>
                                </td>
                                <td>
                                    <p>Description</p>
                                </td>
                                <td>
                                    <p>Po Number</p>
                                </td>
                            
                            </tr>

                        </thead>

                        <tbody>

                            {
                                thisComponentState.values.map(data =>
                                 
                                    <tr>
                                        <td> {data.docId}  </td>
                               
                                        <td> {data.description}</td>

                                        <td>{data.sapId}</td>

                                    </tr>)
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
 )
}

