

function getUrl(property, metaData,data) {

    var url = "";

    for (var i = 0; i < metaData.length; i++) {

        if (metaData[i].name.toLowerCase() === property.toLowerCase()) {

            url = metaData[i].url;

            break;

        }
    }

    return url;

}

export default getUrl;