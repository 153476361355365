
import React, {  Fragment, useContext, useState } from 'react';

import { Link } from 'react-router-dom';

import getModalContentStyle from '../../Functions/getModalContentStyle';

import getModalStyle from '../../Functions/getModalStyle';

import { TransactionContext } from '../TransactionContext';

import uuid from 'react-uuid';
import Detail from '../Detail';
import createDetailProperties from '../../Functions/createDetailProperties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdjust,faEye, faForward, faPlus, faPrint, faReceipt, faWindowClose } from '@fortawesome/free-solid-svg-icons';

export default function DetailsAccountsPayableNavigator(props) {

    const {data, guidId,updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        
        send: false,
        isEmailed: false,
        isCancelling: false,
        isApproving: false,
        isGeneratingPDF: false,
        isPerformingOperation:false,
        showOnHand: false,
        isShowingOnHand: false,
        toEdit:false,
        data: [],
        toScan:false
 
    });
   
    const email = () => {

        if (thisComponentState.isEmailed) {

            updateState(prevState => ({
                ...prevState,
                send: false,
                isEmailed: false

            }));

        } else {


            fetch("/PurchaseOrders/Send", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify({ guidId: guidId}),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => {

                    if (!response.ok) {
                        response.text().then(text => console.log(text))
                    }
                    else {
                        return response.json();
                    }

                })

                .then(response => {

                    alert("Purchase order has been sent.")

                    updateState(prevState => ({
                        ...prevState,
                        isEmailed: true

                    }));

                });
        }
    }



    const cancel = () => {

        var isConfirm = window.confirm("Are you sure you want to cancel this Goods Receipts?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isCancelling: true })

        fetch("/AccountsPayables/Cancel", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({ value: guidId }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    response.text().then(text => console.log(text))
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isCancelling: false })

                return alert(response.message);

            });
    }




    const preview = () => {

        updateState({ ...thisComponentState, isGeneratingPDF: true });

        let url = "/AccountsPayables/Preview?id=";
       // let url = "https://testerpsystems.azurewebsites.net/PurchaseOrders/Test?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                updateState({ ...thisComponentState, isGeneratingPDF: false });

                var file = window.URL.createObjectURL(blob);
                window.open(file);

            });
    }

    if (thisComponentState.isGeneratingPDF) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Generating PDF...
                    </button>
                </div>
            </div>
        </div>)

    }





    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div style={{ float: "left" }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

            <FontAwesomeIcon icon={faWindowClose} title="Cancel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={cancel} />

            <FontAwesomeIcon icon={faAdjust} title="Create Ap Credit Memo" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" />
            
            <FontAwesomeIcon icon={faPrint} title="Ap" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={preview} />

            <FontAwesomeIcon icon={faPrint} title="2307" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={preview} />


            {
                thisComponentState.toEdit &&

                    <div style={getModalStyle()}>
                        <div style={getModalContentStyle()}>

                            <input className='btn btn-primary' onClick={getEditableData} value='Back' />

                            <br />
                            <br />

                            <Detail />

                        </div>
                    </div>      
            }


           
        </div>

    )

}


