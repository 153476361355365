import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"


export default function SetMeetingSchedule(props) {


    function formatTime(floatingNumber) {
        // Split the floating number into hours and minutes
        const hours = Math.floor(floatingNumber);
        const minutes = Math.round((floatingNumber - hours) * 60);

        // Convert hours to 24-hour format
        const formattedHours = hours < 10 ? '0' + hours : hours;

        // Add leading zeros if necessary
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Construct the time string
        const timeStr = `${formattedHours}:${formattedMinutes}`;

        return timeStr;
    }


    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        data:props.data,
        date:"",
        description: "",
        start:7,
        end:7,
        resource :props.location
    });

    const save = () => {

        let parseStart= parseFloat(thisComponentState.start);
        let parseEnd= parseFloat(thisComponentState.end);

        console.log(parseStart);
        console.log(parseEnd);

        if (parseEnd < parseStart) {

            return alert("Start cannot be greater than end.")
        }

        if (parseEnd === parseStart) {

            return alert("Please define a valid time range.")
        }

        if (thisComponentState.description === "") {

            return alert("Description is required.")
        }

        if (thisComponentState.date === "") {

            return alert("Date is required.")
        }


        
        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Bookings/Create", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify({
                eventDate: thisComponentState.date,
                start:parseStart,
                end:parseEnd,
                description: thisComponentState.description,
                resource:thisComponentState.resource
            }),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {
                if (!response.ok) {
                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })
            .then(response => {

                updateState({ ...thisComponentState, position: response.position, isPerformingOperation: false });

                if (response.success) {

                    props.back();
                  
                }

                return alert(response.message);
       
            });
    }

   

    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const getStartOption = () => {

        let items = [];

        for (let i = 7; i <= 24; i++) {

            items.push(i);

            i = i - .50;
        };

        return (
            <select className="form-control"  onChange={(e) => updateState({...thisComponentState,start:e.currentTarget.value}) }>
                    {
                        items.map(val => <option value={val}> {formatTime(val)} {val >=12 ? "PM" : "AM" }  </option>)
                    }
           
                </select>
            )
    }


    const getEndOption = () => {

        let items = [];

        for (let i = 7; i <= 24; i++) {

            items.push(i);

            i = i - .50;

        };

        return (
            <select className="form-control" onChange={(e) => updateState({ ...thisComponentState, end: e.currentTarget.value })}>
                {
                    items.map(val =>
                        
                             <option value={val}> {formatTime(val)} {val>=12 ? "PM" : "AM"}  </option>
                    )
                }

            </select>
        )
    }

    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={() => props.back()} className="form-control" type="button" />
                </div>              
            </div>

            <hr />

            <div className="row">
                <div className="col">
                   Date
                </div>
                <div className="col">
                    <input type="date" className="form-control" onChange={(e) => updateState({ ...thisComponentState, date: e.currentTarget.value })} />
                </div>
            </div>

           <hr/>

            <div className="row">
                <div className="col">
                   Description
                </div>
                <div className="col">
                    <input className="form-control" onChange={(e) => updateState({...thisComponentState,description:e.currentTarget.value}) }/>
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                   Start
                </div>
                <div className="col">

                    {getStartOption()}

                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    End
                </div>
                <div className="col">
                    {getEndOption()}
                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col">

                </div>
                <div className="col">
                    <input value="Save" onClick={save} className="form-control" type="button" />
                </div>
            </div>
        </div>
    )
}

