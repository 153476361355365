
import { faBars, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import PrDetails from "./PrDetails";


export default function PrForClosure(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        details: [],
        showDetails: false,
        selectedGuidId:null

    });

    useEffect(() => {

        fetch("/PurchaseRequisitions/GetPrForClosure")
            .then(response=> response.status===401? window.location = "/Login" : response.json())
            .then(response => {

                updateState({...thisComponentState,details:response, isLoading:false});

            })
    }, []);


    const close = (guidId) => {

        fetch("/PurchaseRequisitions/Close", {
            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {

                'content-type' : 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempData = [...thisComponentState.details];

                    tempData = tempData.filter(u => u.guidId !== response.guidId);

                    updateState({ ...thisComponentState, details: tempData });

                    return alert("Closing Successful...");

                } else {

                    return alert(response.message);
                }
            });

    }

    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    if (thisComponentState.showDetails) {

        return (<PrDetails guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })}/>)
    }




    return (

            <div>

                <div className="row">

                    <div className="col">

                         <input type="button" value="Back" onClick={()=>props.back()} className="form-control" />

                    </div>
                </div>

                <br/>

                <div className="row">

                    <div className="col">
                        <table className="table">

                        <thead>

                            <tr>
                                <th colSpan="2"> Action </th>
                                <th>Requestor</th>
                                <th>Request Date</th>
                                <th>Required Date</th>
                                <th>DeptName</th>
                                <th>Note</th>

                                </tr>

                               
                            </thead>

                            <tbody>

                            {
                                thisComponentState.details.map(val =>

                                    <tr key={val.guidId}>

                                             <td>                                             
                                                <FontAwesomeIcon icon={faLockOpen} title="List of Pr to Close" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={()=> close(val.guidId) } />
                                            </td>

                                            <td>
                                                <FontAwesomeIcon icon={faBars} title="List of Open St" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({...thisComponentState,showDetails:true,selectedGuidId: val.guidId})} />
                                            </td>

                                            <td className="align-middle">{val.requestor}</td>
                                            <td className="align-middle">{val.requestDate}</td>
                                            <td className="align-middle">{val.requiredDate}</td>
                                            <td className="align-middle">{val.deptName}</td>
                                            <td className="align-middle">{val.note}</td>
                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>

                    
        )
}