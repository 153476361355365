
import { faBackward, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useContext } from 'react';
import uuid from 'react-uuid';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import getModalContentStyle from '../Functions/getModalContentStyle';
import getModalStyle from '../Functions/getModalStyle';
import { TransactionContext } from './TransactionContext';



 export default function TransactionForApproval(props) {

    const { data } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        data: [],
        dataToPost:[],
        isLoading: true,
        currentlyApproving:false

    });

    useEffect(() => {

        getData();

    }, []);

    const onChange = (e, guidId) => {
        
        let isChecked = e.target.checked;

        if (isChecked) {

            var tempData = [...thisComponentState.dataToPost];

            if (!tempData.some(x => x.guidId === guidId)) {

                tempData.push({
                    guidId: guidId,
                    value: data.metaData.transactionCode
                });
                
                updateState({ ...thisComponentState, dataToPost: tempData });
            };

        } else {

            var tempData = [...thisComponentState.dataToPost];

            if (tempData.some(x => x.guidId === guidId)) {

                tempData = tempData.filter(x => x.guidId != guidId);

                updateState({ ...thisComponentState, dataToPost: tempData });
            };

        }
    }

    async function getData() {

        await fetch(props.url)

            .then(response => response.json())
            .then(response => {

                updateState({ ...thisComponentState, data: response, isLoading: false });

            });
    };

    const approved = () => {

        updateState({...thisComponentState,currentlyApproving:true})

        let pResult = window.confirm("Are you sure you want to approve these transactions?");

        if (pResult) {

            fetch("/Base/ApprovedTransactions", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(thisComponentState.dataToPost),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => response.status == 401 ? window.location = "/Login" : response.json())

                .then(response => {

                    getData();

                    updateState({ ...thisComponentState, currentlyApproving: false });

                });

        } else {

            updateState({ ...thisComponentState, currentlyApproving: false });

        }
    }


    const disApproved = () => {

        updateState({ ...thisComponentState, currentlyApproving: true })

        let pResult = window.confirm("Are you sure you want to disapprove these transactions?");

        if (pResult) {

            fetch("/Base/DisapprovedTransactions", {

                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(thisComponentState.dataToPost),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
                .then(response => response.status == 401 ? window.location = "/Login" : response.json())

                .then(response => {

                    getData();

                    updateState({...thisComponentState, currentlyApproving: false });

                });

        } else {

            updateState({ ...thisComponentState, currentlyApproving: false });

        }
    }


    if (thisComponentState.currentlyApproving) {

        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle() }>

                    Approving.....

                </div>

            </div>
           
            )
    }


    if (thisComponentState.isLoading) {

        return (<div>Please wait...</div>);

    } else {

        return (

            <div>
                <div>
                         
                    <FontAwesomeIcon icon={faBackward} style={{ cursor:'pointer'}} onClick={() => props.updateState({ ...thisComponentState, approvedTransaction: false })} color='blue' size='2x' />

                    <FontAwesomeIcon icon={faThumbsUp} style={{ marginLeft: "10px", cursor: 'pointer' }} size='2x' color='green' onClick={() => approved()} />

                    </div>

                    <br/>

                <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                    <thead className="thead-dark">

                        {
                           thisComponentState.data.length> 0 ? 

                            Object.entries(thisComponentState.data[0]).map(y =>

                                y[0] !== "guidId" ? <td>  {capitalizeFirstLetter(y[0])}  </td> : <td> Actions  </td>

                            ) : <td>Nothing to approve...</td>
                        }

                    </thead>

                    <tbody>

                        {

                            thisComponentState.data.length > 0 &&

                            thisComponentState.data.map(x =>

                                <tr key={uuid()}>
                                    {
                                        Object.entries(x).map(y =>
                                            y[0] !== "guidId" ? <td> {y[1]} </td> :
                                                    <td>
                                                        <input type="checkbox"  onChange={(e) => onChange(e, y[1])} />
                                                    </td>                                          
                                        )
                                    }
                                </tr>

                                ) 

                        }

                    </tbody>
                </table>
            </div>            
        )
    }
}

