import { faForward, faPlus, faRedo, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';



export default function DetailsBadOrderNavigator(props) {

    const { data, updateMetaData, guidId } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false

    });


    const accept = () => {

        updateState({ ...thisComponentState, accepting: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/BreadOuts/Accept", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(json => {

                if (json.accepted) {

                    var tempData = { ...data.metaData };

                    tempData.detailsFromDb.acceptedBy = json.acceptedBy;

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, accepting: false });

                } else {

                    return alert("Something went wrong...");

                }

            });
    }


    const cancel = () => {

        var toDelete = window.confirm("Are you sure...You want to cancel this?");

        if (!toDelete) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/BreadOuts/Cancel", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }

    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/BreadOuts/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }


    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle()}>
            <div style={getModalContentStyle()} >
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div style={{ float: "left" }}>

    
            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

            <FontAwesomeIcon icon={faWindowClose} title="Cancel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={cancel} />

            <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={forward} />

          
        </div>

    )
}


