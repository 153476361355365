
import React, { useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import StandardAutoComplete from '../../StandardAutocomplete';




export default function Disassemble(props) {

    const [thisComponentState, updateState] = useState({

        date:"",
        itemCode: "",
        good: "",
        reject: 0,
        bargain: 0,
        isSaving:false  

    });

    const updateValue = (itemCode) => {

        updateState({ ...thisComponentState, itemCode: itemCode });

    }

    const save = () => {

        if (thisComponentState.date === "") {

            return alert("Please select date.");
        }

        if (thisComponentState.itemCode === "") {

            return alert("Please select item");
        };

        if (thisComponentState.good === 0 && thisComponentState.reject ===0 && thisComponentState.bargain===0) {

            return alert("Please set values for good, reject or bargain.");
        };

        updateState({ ...thisComponentState, isSaving: true });

        fetch("/Productions/Disassemble",
            {
                method: "post",
                body: JSON.stringify(thisComponentState),
                headers: {
                    'content-type': 'application/json; charset:UTF-8'
                }
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {


                alert(response.message);

                updateState({ ...thisComponentState, isSaving: false });

            });
    }

    if (thisComponentState.isSaving) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div style={getModalStyle() }>

            <div style={getModalContentStyle() }>
                <div className="row">

                    <div className="col">
                        <div> Date </div>
                    </div>

                    <div className="col">
                        <input type="date" className="form-control" value={thisComponentState.date} onChange={(e) => updateState({ ...thisComponentState, date: e.currentTarget.value })} />
                    </div>

                </div>

                <div className="row">

                    <div className="col">
                        <div> Item </div>
                    </div>

                    <div className="col">
                        <StandardAutoComplete url="/Productions/GetProducts" updateValue={updateValue} />
                    </div>
                </div>

                <div className="row">

                    <div className="col">
                        <div> Good </div>
                    </div>

                    <div className="col">
                        <input className="form-control" type="number" value={thisComponentState.good}  onChange={(e) => updateState({ ...thisComponentState, good: e.currentTarget.value })} />
                    </div>
                </div>


                <div className="row">

                    <div className="col">
                        <div> Reject </div>
                    </div>

                    <div className="col">
                        <input type="number" className="form-control" value={thisComponentState.reject} onChange={(e) => updateState({ ...thisComponentState, reject: e.currentTarget.value })} />

                    </div>
                </div>

                <div className="row">

                    <div className="col">
                        <div> Bargain </div>
                    </div>

                    <div className="col">

                        <input type="number" className="form-control" value={thisComponentState.bargain} onChange={(e) => updateState({ ...thisComponentState, bargain: e.currentTarget.value })} />

                    </div>
                </div>

                <br />

                <div className="row">

                    <div className="col">

                    </div>

                    <div className="col">

                        <div className="row">

                            <div className="col">

                            </div>

                            <div className="col">
                                <input type="button" onClick={save} className="form-control" value="Save" />
                            </div>

                            <div className="col">
                                <input type="button" onClick={() =>props.updateState({...thisComponentState,toDisassemble:false})} className="form-control"  value="Back" />
                            </div>

                        </div>
                         
                    </div>

                </div>

            </div>

        </div>        
   )
}