import React, { useContext, useState } from 'react';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import ProduceOrder from './ProduceOrder';
import Released from './Released';
import ProductionPlanned from './ProductionPlanned';
import { Link } from 'react-router-dom';
import Disassemble from './Disassemble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCodePullRequest, faCookie, faPalette, faPlus, faPrint, faRedo, faRetweet, faScrewdriver, faSync, faTruck } from '@fortawesome/free-solid-svg-icons';
import SyncProduction from './SyncProduction';
import PrintBalanceSheet from './PrintBalanceSheet';
import CommissaryProduction from './CommissaryProduction';
import ReconciliationViewer from './ReconciliationViewer';


function CreateProductionNavigator() {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        showOrders: false,
        toReleased: false,
        toDassemble: false,
        toSync:false,
        createProductionPlanned: false,
        releasedProductionPlanned: false,
        toPrintBalanceSheet: false,
        showCommissaryProduction: false,
        showReconciliationModel:false


    });


    if (thisComponentState.toDassemble) {

        return (<Disassemble updateState={updateState} thisComponentState={thisComponentState } />)
    }

   
    if (thisComponentState.createProductionPlanned) {

        return <ProductionPlanned updateState={updateState} thisComponentState={thisComponentState }/>
    };


    if (thisComponentState.releasedProductionPlanned) {

        return <Released updateState={updateState} thisComponentState={thisComponentState} />
    };


    if (thisComponentState.toSync)
    {
        return <SyncProduction back={() => updateState({...thisComponentState,toSync:false})} />
    }

    if (thisComponentState.showOrders) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <ProduceOrder updateState={updateState} fromBreadOut={false} fromProduction={true} thisComponentState={thisComponentState} url="Orders/ViewOrders?" />

                </div>                        
           </div>
        )
    }

    if (thisComponentState.toPrintBalanceSheet)
    {
        return (<PrintBalanceSheet back={() => updateState({...thisComponentState,toPrintBalanceSheet:false}) }/>)
    }

    if (thisComponentState.showCommissaryProduction) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle() }>
                    <CommissaryProduction back={() => updateState({ ...thisComponentState, showCommissaryProduction: false })} />
                </div>
            </div>
        )

    }


    if (thisComponentState.showReconciliationModel) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <ReconciliationViewer back={() => updateState({ ...thisComponentState, showReconciliationModel: false })} />
                </div>
            </div>
        )
    }



    return (
        
        <div style={{ float: 'left', whiteSpace: 'nowrap' }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

            <FontAwesomeIcon icon={faCartShopping} title="Orders" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, showOrders: true })} />

            <FontAwesomeIcon icon={faPalette} title="Plan" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateState({ ...thisComponentState, createProductionPlanned: true })}  />

            <FontAwesomeIcon icon={faTruck} title="Released" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, releasedProductionPlanned: true })} />

            <FontAwesomeIcon icon={faScrewdriver} title="Disassemble" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, toDassemble: true })} />

            <FontAwesomeIcon icon={faSync} title="Sync" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, toSync: true })} />

            <FontAwesomeIcon icon={faCookie} title="Commissary" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showCommissaryProduction: true })} />

            <FontAwesomeIcon icon={faPrint} title="Print balance sheet" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, toPrintBalanceSheet: true })} />

            <FontAwesomeIcon icon={faRetweet} title="Reconciliation Viewer" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState,showReconciliationModel: true })} />

        </div>
   )
    
}


export default CreateProductionNavigator;