import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import Details from '../Details';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import { TransactionContext } from './TransactionContext';


export default function TransactionList(props) {

    const { data } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        
        goToDetails: false,
        isLoading: false,
        detailsUrl: "",
        editDetailUrl: "",
        deleteDetailUrl:"",
        guidId:""

    });


    const goToDetails = (detailsUrl,guidId,editDetailUrl,deleteDetailUrl) => {

        updateState(prevState => ({
            ...prevState,
            goToDetails:true,
            detailsUrl: detailsUrl,
            editDetailUrl: editDetailUrl,
            deleteDetailUrl: deleteDetailUrl,
            guidId: guidId
        }));
    }

  
    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {

        if (thisComponentState.goToDetails) {

            return (<Details state={{ guidId: thisComponentState.guidId, deleteDetailUrl: thisComponentState.deleteDetailUrl, detailsUrl: thisComponentState.detailsUrl, editDetailUrl: thisComponentState.editDetailUrl, listUrl: props.state.listUrl }} listNavigator={props.listNavigator} detailsNavigator={props.detailsNavigator} state={{ guidId: thisComponentState.guidId, deleteDetailUrl:thisComponentState.deleteDetailUrl, detailsUrl: thisComponentState.detailsUrl, editDetailUrl: thisComponentState.editDetailUrl, listUrl: props.state.listUrl }} />)

        } else {
           
            if (data.metaData.listData.length === 0) {

                return (

                    <div>
                        <div className="row">

                            {props.listNavigator}
                           
                        </div>
                        <br />
                        <br/>
                        <div className="row" style={{marginLeft:"15px"}}> Please defined your filters...</div>
                    </div>
            
                )

            } else {

                return (

                    <div>
               
                        {props.listNavigator}

                     

                        <br />

                        <div>

                            <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                                <thead className="thead-dark">

                                    <tr>
                                        {
                                            Object.entries(data.metaData.listData[0]).map(y =>

                                                y[0] !== "guidId" ? <td>{capitalizeFirstLetter(y[0])}  </td> : <td> Actions  </td>
                                            )
                                        }

                                      </tr>

                                </thead>

                                <tbody>

                                    {
                                        data.metaData.listData.map(x =>

                                            <tr>

                                                {
                                                    Object.entries(x).map(y =>

                                                        y[0] !== "guidId" ? <td style={{ whiteSpace:'nowrap' }}>{y[1]}</td> : <td> <input className="btn btn-success"  value="Details" type="button" onClick={() => goToDetails(props.state.detailsUrl, y[1], props.state.editDetailUrl,props.state.deleteDetailUrl)} /> </td>

                                                    )
                                                }

                                            </tr>

                                        )
                                    }

                                </tbody>

                            </table>
                        </div>

                    </div>
                )
            }
        }
    }
}

