import { faArrowLeft, faArrowRight, faFilter, faL, faListAlt, faRotate, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";
import StDetails from "./StDetails";


export default function ListOfUnAcceptedSt(props)
{
    const [thisComponentState, updateState] = useState({
        from: "",
        to:"",
        details: [],
        pickItems: [],
        showDetails: false,
        selectedGuidId:"",
        isLoading: false,
        branchId :"",
        selectedData: {}
    });

    useEffect(() => {

        generate();


    }, [])


    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }


    if (thisComponentState.showDetails) {

        return (<StDetails data={thisComponentState.selectedData}  back={() => updateState({ ...thisComponentState, showDetails: false})} />)
    }


    const generate = () => {

        let params = {};

        if (thisComponentState.from !== "" && thisComponentState.to !== "") {

            params.from = thisComponentState.from;
            params.to = thisComponentState.to;
        }

        if (thisComponentState.branchId !== "") {

            params.branchId = thisComponentState.branchId;
        }

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/StockTransfers/ListOfUnacceptedSt?" + new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.originalValues = response;

                updateState(tempData);

            });
    }


   



    const onChangeFrom = (e) => {

        updateState({ ...thisComponentState, from: e.currentTarget.value });
 
    }

    const onChangeTo = (e) => {

        updateState({ ...thisComponentState, to: e.currentTarget.value});

    }


    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });
    }


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                  
                    <div className="col">
                        <div style={{ float: 'left' }}>
                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />                                                  
                        </div>                       
                    </div>

                    <div className="col">
                        <input className="form-control" type="date" value={thisComponentState.from} onChange={onChangeFrom} />
                    </div>
                 
                    <div className="col">
                        <input className="form-control" type="date" value={thisComponentState.to} onChange={onChangeTo} />
                    </div>
                    <div className="col">
                        <StandardAutoComplete url="/Branches/GetBranches" value={thisComponentState.branchId} clear={() => updateState({ ...thisComponentState, branchId: "" })} updateValue={updateBranchId}/>
                    </div>

                    <div className="col">
                        <input className="form-control" type="button" value="Retrieve" onClick={generate} />
                    </div>


                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th > Action</th>
                                    <th> Required Date </th>
                                    <th> Doc Id </th>
                                    <th> Requestor </th>
                                    <th> Branch</th>
 
                                    <th> Remarks </th>
                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={val.guidId}>
                                        
                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedData:val,showDetails:true}) } />
                                            </td>

                                            <td> {val.requiredDate}</td>
                                            <td> {val.docId}</td>
                                            <td> {val.requestor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.remarks}</td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
