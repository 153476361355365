import { faBackward, faEdit, faList, faSave, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";
import AddSlice from "./AddSlice";
import AddVariation from "./AddVariation";

export default function InventoryDetails(props) {

    const [thisComponentState, updateState] = useState({

        type: "",
        code: props.code,
        name: "",
        prevName: "",
        saleUomEntry: "",
        purchaseUomEntry: "",
        inventoryUomEntry: "",
        sellItem: "Y",
        purchaseItem: "Y",
        inventoryItem: "Y",
        shortCode: "",
        expenseCode: "",
        isManufactured: false,
        isService: false,
        isForecastable: false,
        isChanging: false,
        toEdit: false,
        toReload: 0,
        addVariation: false,
        addSlices:false
    });


    useEffect(() => {

        fetch("/Inventories/Details?" + new URLSearchParams({

            code: thisComponentState.code

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {


                let saleUomDesc = response.saleUomEntryDesc !== null && response.saleUomEntryDesc.uomName !== null ? response.saleUomEntryDesc.uomName : "";
                let purchaseUomDesc = response.purchaseUomEntryDesc !== null && response.purchaseUomEntryDesc.uomName !== null ? response.purchaseUomEntryDesc.uomName : "";
                let inventoryUomDesc = response.inventoryUomEntryDesc !== null && response.inventoryUomEntryDesc.uomName !== null ? response.inventoryUomEntryDesc.uomName : "";


                updateState({
                    ...thisComponentState,
                    type: response.type,
                    code: response.code,
                    name: response.name,
                    prevName: response.name,
                    categoryId: response.categoryId,
                    category: response.category,
                    saleUomEntryDesc: saleUomDesc,
                    purchaseUomEntryDesc: purchaseUomDesc,
                    inventoryUomEntryDesc: inventoryUomDesc,
                    saleUomEntry: response.saleUomEntry,
                    purchaseUomEntry: response.purchaseUomEntry,
                    inventoryUomEntry: response.inventoryUomEntry,
                    sellItem: response.sellItem,
                    purchaseItem: response.purchaseItem,
                    inventoryItem: response.inventoryItem,
                    isService: response.isService,
                    isForecastable: response.isForecastable,
                    isManufactured: response.isManufactured,
                });

            });
    }, [thisComponentState.toReload]);


    const updateSaleUomEntry = (value) => {
        onFieldEdit("saleUomEntry", value);
    }

    const updatePurchaseUomEntry = (value) => {

        onFieldEdit("purchaseUomEntry", value);
    }

    const updateInventoryUomEntry = (value) => {

        onFieldEdit("inventoryUomEntry", value);

    }

    const updateCategoryId = (value) => {

        onFieldEdit("categoryId", value);

    }


    const updateIsManufactured = (value) => {

        onFieldEdit("isManufactured", value);
    }

    const updateIsService = (value) => {
        onFieldEdit("isService", value);
    }

    const updateIsForecastable = (value) => {

        onFieldEdit("isForecastable", value);
    }

    const onChangeName = () => {

        if (thisComponentState.name !== thisComponentState.prevName) {
            onFieldEdit("name", thisComponentState.name);
        }
    }


    const onFieldEdit = (name, value) => {

        let toPost = {

            code: thisComponentState.code,
            name: name,
            value: value
        }

        updateState({ ...thisComponentState, isChanging: true });

        fetch("/Inventories/Edit", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(toPost),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isChanging: false, toReload: thisComponentState.toReload + 1 });

                return alert(response.message);
            });
    }


    const modifyState = (value) => {

        let toPost =
        {
            value:thisComponentState.code
        }

        updateState({ ...thisComponentState, isChanging: true });

        fetch("/Inventories/ModifyState", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(toPost),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isChanging: false, toReload: thisComponentState.toReload + 1 });

                return alert(response.message);
            });
    }

    if (thisComponentState.isChanging) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Changing...
                    </button>
                </div>
            </div>
        </div>)
    }



    if (thisComponentState.addVariations) {
        return (<AddVariation code={thisComponentState.code} name={thisComponentState.name}/>)
    }

    if (thisComponentState.addSlices) {

        return (<AddSlice code={thisComponentState.code} name={thisComponentState.name} />)
    }

    return (

        <div>

            <hr />

            <div className="row">
                <div className="col">

                    <FontAwesomeIcon icon={faBackward} title="Go Back" style={{cursor: 'pointer' }} size="2x" color="green" onClick={() => props.back()} />

                </div>

                <div className="col">

                    <FontAwesomeIcon icon={faEdit} title="Go Back" style={{  cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, toEdit: true })} />

                </div>

                <div className="col">
                    {
                        thisComponentState.isActive ? <FontAwesomeIcon icon={faToggleOff} title="deactivate" style={{  cursor: 'pointer' }} size="2x" color="green" onClick={modifyState} /> :
                            <FontAwesomeIcon icon={faToggleOn} title="activate" style={{ cursor: 'pointer' }} size="2x" color="green" onClick={modifyState} />

                    }


                </div>

            </div>

            <hr />



            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Code
                    </div>
                </div>
                <div className="col">
                    {thisComponentState.code}
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Name
                    </div>
                </div>

                <div className="col">
                    {
                        thisComponentState.toEdit ? <input type="text" value={thisComponentState.name} onChange={(e) => updateState({ ...thisComponentState, name: e.currentTarget.value })} onBlur={onChangeName} className="form-control" /> : thisComponentState.name
                    }
                </div>
            </div>


            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Category
                    </div>
                </div>

                <div className="col">

                    {
                        thisComponentState.toEdit ?

                            <StandardAutoComplete value={thisComponentState.categoryId} url="/Categories/GetCategories" updateValue={updateCategoryId} />

                            : thisComponentState.category
                    }

                </div>

            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Sale Uom Entry
                    </div>
                </div>

                <div className="col">
                    {
                        thisComponentState.toEdit ? <StandardAutoComplete url="/UomValues/GetUomValues" value={thisComponentState.saleUomEntry} updateValue={updateSaleUomEntry} /> : thisComponentState.saleUomEntryDesc
                    }
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Purchase Uom Entry
                    </div>
                </div>

                <div className="col">

                    {
                        thisComponentState.toEdit ? <StandardAutoComplete url="/UomValues/GetUomValues" value={thisComponentState.purchaseUomEntry} updateValue={updatePurchaseUomEntry} /> : thisComponentState.purchaseUomEntryDesc
                    }

                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Inventory Uom Entry
                    </div>
                </div>

                <div className="col">
                    {
                        thisComponentState.toEdit ? <StandardAutoComplete url="/UomValues/GetUomValues" value={thisComponentState.inventoryUomEntry} updateValue={updateInventoryUomEntry} /> : thisComponentState.inventoryUomEntryDesc
                    }
                </div>

            </div>

   

            {
                !thisComponentState.toEdit &&
   
                <div>

                    <hr />

                    <div className="row">

                        <div className="col">
                            <div>
                                Sell Item
                            </div>
                        </div>

                        <div className="col">
                            {thisComponentState.sellItem}
                        </div>

                    </div>


                    <hr />
                    <div className="row">
                        <div className="col">
                            <div>
                                Purchase Item
                            </div>
                        </div>
                        <div className="col">

                            {thisComponentState.purchaseItem}

                        </div>
                    </div>

                    <hr />

                    <div className="row">

                        <div className="col">
                            <div>
                                Inventory Item
                            </div>
                        </div>

                        <div className="col">

                            {thisComponentState.inventoryItem}


                        </div>
                    </div>



                </div>
            }


            <hr />


            <div className="row">

                <div className="col">
                    <div>
                        Is Service
                    </div>
                </div>

                <div className="col">
                    {
                        thisComponentState.toEdit ?

                            <select value={thisComponentState.isService.toString()} onChange={(e) => updateIsService(e.currentTarget.value)} className="form-control" >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>

                            : thisComponentState.isService.toString()
                    }


                </div>

            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Forecastable
                    </div>
                </div>

                <div className="col">

                    {
                        thisComponentState.toEdit ?

                            <select value={thisComponentState.isForecastable.toString()} onChange={(e) => updateIsForecastable(e.currentTarget.value)} className="form-control" >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>

                            : thisComponentState.isForecastable.toString()
                    }


                </div>

            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Manufactured
                    </div>
                </div>

                <div className="col">
                    {
                        thisComponentState.toEdit ?

                            <select value={thisComponentState.isManufactured.toString()} onChange={(e) => updateIsManufactured(e.currentTarget.value)} className="form-control" >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>

                            : thisComponentState.isManufactured.toString()
                    }

                </div>

            </div>


            <hr/>

            {
                thisComponentState.toEdit &&

                <div className="row">
                    <div className="col">
                        <div>
                            <input type="button" className="form-control" value="Add Variations" onClick={() => updateState({...thisComponentState,addVariations:true}) }/>
                        </div>
                    </div>
                    <div className="col">
                        <input type="button" className="form-control" value="Add Slices" onClick={() => updateState({...thisComponentState,addSlices:true }) } />
                    </div>
                </div>

            }
        </div>
    )

}
