import { useEffect, useState } from "react";
import { useContext } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import { TransactionContext } from "../TransactionContext";




export default function WithholdingTaxComputer(props)
{
    const { data,updateMetaData} = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        isLoading:true,
        actType: "0",
        taxBase: 0,
        rate: 0,
        vatability:"0",
        ewt: 0,
        toReload:0
    });

    useEffect(() => {

        let tempData = { ...data.metaData };

        let taxBase = 0;

        tempData.detailProperties.forEach(val => {

            taxBase = taxBase + val.quantity * val.price;

        });

        fetch("/AccountsPayables/ComputeWithholdingTax?" + new URLSearchParams({

            code: tempData.parentProperties.code,
            actType: thisComponentState.actType,
            vatability:thisComponentState.vatability,
            taxBase: taxBase

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({
                        ...thisComponentState,
                        actType: response.actType,
                        taxBase: response.taxBase,
                        rate: response.rate,
                        vatability: response.isVatable ? "Y" : "N",
                        ewt: response.ewt,
                        isLoading: false
                    });

                    return;

                } else {

                    alert(response.message);
                }

                updateState({ ...thisComponentState, list: response, isLoading:false });

            });

    }, [thisComponentState.toReload]);


    const apply = () => {

        let tempData = { ...data.metaData };

        tempData.parentProperties.ewt = thisComponentState.ewt;

        updateMetaData({...data,metaData : tempData });

        props.back();

    }

  
    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Computating...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                    <div className="col">
                        <input type="button" className="form-control" value="Back" onClick={()=> props.back()} />
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col">
                        Tax Type
                    </div>
                    <div className="col">

                        <select value={thisComponentState.actType} className="form-control" onChange={(e) => updateState({ ...thisComponentState, actType: e.currentTarget.value,toReload:thisComponentState.toReload+1 })} >
                            <option value="0"></option>
                            <option value="WC100">Rentals</option>
                            <option value="WC158">Goods</option>
                            <option value="WC160">Service</option>

                        </select>

                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        Vatability
                    </div>
                    <div className="col">
                        <select value={thisComponentState.vatability} className="form-control" onChange={(e) => updateState({...thisComponentState,vatability:e.currentTarget.value, toReload:thisComponentState.toReload+1}) }>
                            <option value="0"></option>
                            <option value="Y">Vatable</option>
                            <option value="N">Non-Vatable</option>                            
                        </select>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col">
                        Tax Base
                    </div>
                    <div className="col">
                        <input type="number" style={{textAlign:"right"}} value={thisComponentState.taxBase} readOnly className="form-control" />
                    </div>
                </div>

                <br/>

                <div className="row">
                    <div className="col">
                       Rate
                    </div>
                    <div className="col">
                        <input type="text" style={{ textAlign: "right" }} value={thisComponentState.rate} readOnly className="form-control" />
                    </div>
                </div>

                <br/>

                <div className="row">
                    <div className="col">
                        Ewt
                    </div>
                    <div className="col">
                        <input type="number" style={{ textAlign: "right" }} value={ thisComponentState.ewt} readOnly className="form-control"/>
                    </div>
                </div>


                <hr/>

                <div className="row">
                    <div className="col">
                        
                    </div>
                    <div className="col">
                        <input value="Apply" type="button" onClick={apply} className="form-control" />
                    </div>
                </div>

            </div>
        </div>      
  )
}