
import { faArrowDown, faFeather, faFile, faFire, faFolderOpen, faSeedling } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useState } from 'react';
import uuid from 'react-uuid';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import BreadOutDrafts from './BreadOutDrafts';


export default function CreateBreadOutNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        showOrders: false,
        retrieving: false,
        shipReturns: false,
        branchId: null,
        warehouseId: null,
        deliveryDate: null,
        isPerItem: false,
        showDrafts:false,
        perItemData: []
        
    });

    const pullOrder = (area) => {

        var tempData = {...data.metaData};

        tempData.detailProperties = [];

        updateMetaData({ metaData: tempData });

        updateState({ ...thisComponentState, retrieving: true });

        fetch("/BreadOuts/GetOrders?" + new URLSearchParams({

            deliveryDate: data.metaData.parentProperties.date,
            agentCode: data.metaData.parentProperties.agentCode,
            area: area,


        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);

                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, retrieving: false });

                    console.log("this component state is",data);

                    return;
                }

                updateState({ ...thisComponentState, retrieving: false });

                return alert("No data available.");

            })
    }


    if (thisComponentState.retrieving) {

        return (<div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const draftIt = () => {

        if (typeof data.metaData.parentProperties.agentCode === 'undefined' || data.metaData.parentProperties.agentCode === null) {

            return alert("Please select a requestor.");
        }

        var toSave = window.confirm("Are you sure you want to save this as draft?");

        if (!toSave) {

            return;
        }

        var draft = {};

        draft.guidId =uuid();
        draft.preceedingTransactionGuids = data.metaData.preceedingTransactionGuids;

        draft.parentProperties = data.metaData.parentProperties;
        draft.detailProperties = data.metaData.detailProperties;

        var drafts = localStorage.getItem("breadOutDrafts");

        var masterfiles = localStorage.getItem("masterfiles");

        if (masterfiles !== null) {

            var pMasterfiles = JSON.parse(masterfiles);

            var employee = pMasterfiles.agents.find(val => val.code === draft.parentProperties.agentCode);

            draft.agent = employee.name;
        }

        if (drafts !== null) {

            var tempDrafts = JSON.parse(drafts);

            tempDrafts.push(draft);

            localStorage.setItem("breadOutDrafts", JSON.stringify(tempDrafts));

            return;
        }

        drafts = [];

        drafts.push(draft);

        localStorage.setItem("breadOutDrafts", JSON.stringify(drafts));
    }


    if (thisComponentState.showDrafts)
    {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <BreadOutDrafts back={() => updateState({ ...thisComponentState, showDrafts: false })} />
                </div>
            </div>
        )
    }



    return (

        <div style={{ float: "left" }}>
            <FontAwesomeIcon icon={faArrowDown} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => pullOrder("NEW")} />
            <FontAwesomeIcon icon={faArrowDown} title="OLD" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => pullOrder("OLD")} />
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />      
        </div>

    )

}
