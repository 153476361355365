import React, { Fragment }  from 'react';
import { useState } from "react"
import StandardAutoComplete from './StandardAutocomplete';
import getModalStyle from './Functions/getModalStyle';
import getModalContentStyle from './Functions/getModalContentStyle'


export default function EditableData(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation:false,
        value: props.value,
        toUpdate: false,
        code: props.code,
        editables:["code","uomDesc","quantity","price","description","branchId","warehouseCode","warehouseId"]
    });

   
   const  update = () => {

       const confirm = window.confirm("Are you you want to edit this item?")

       if (!confirm) {
           return;
       }

       updateState({ ...thisComponentState, isPerformingOperation: true });

       fetch(props.editDetailUrl, {
           // Adding method type
           method: "POST",

           // Adding body or contents to send
           body: JSON.stringify({
               guidId: props.guidId,
               name: props.name==="uomDesc" ? "uomEntry" :props.name,
               value: thisComponentState.value
           }),

           // Adding headers to the request
           headers: {
               "Content-type": "application/json; charset=UTF-8"
           }
       })
           .then(response => {

               if (!response.ok) {

                   return alert("Something went wrong.")
               }
               else {
                   return response.json();
               }

           })

           // Displaying results to console
           .then(json => {

               if (json.success) {

                   props.getDetails(props.url);

                   updateState({ ...thisComponentState, isPerformingOperation: false, toUpdate: false});

               } else {

                   updateState({ ...thisComponentState, isPerformingOperation: false, toUpdate: false, value:props.value});

                   alert(json.message);
               };

           });
    }

    const updateValue = (code) => {

        updateState({ ...thisComponentState, value: code, code: code });

    };


    const getComponent = () => {

        if (props.name === "code") {

            return (<StandardAutoComplete url="/Inventories/GetInventories" updateValue={updateValue} />);
        };

        if (props.name === "uomDesc") {

            return (<StandardAutoComplete url={"/UomValues/GetUomValuesByCode"} params={{ code: thisComponentState.code }} updateValue={updateValue} />);
        }

        if (props.name === "branchId") {

            return (<StandardAutoComplete url="/Branches/GetBranches" updateValue={updateValue} />);
        };

        if (props.name === "warehouseId" || props.name ==="warehouseCode") {

            return (<StandardAutoComplete url="/Warehouses/GetWarehouses" updateValue={updateValue} />);
        };


        return (<input value={thisComponentState.value} className="form-control" style={{ width: "150px" }} onChange={(e) => updateState({ ...thisComponentState, value: e.currentTarget.value })} />);
    };




    const onDoubleClick = () => {

        if (props.name === "uomDesc") {

            let reminder = window.confirm("Remember that changing uom may require changing also the quantity or the price. Please analyze carefully.\nClick ok to continue.")

            if (!reminder) {
                return;
            }
        }

        if (thisComponentState.editables.includes(props.name))
        {
            updateState({ ...thisComponentState, toUpdate: true });
        }
    }

 
    if (thisComponentState.toUpdate) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle("200px", "400px")}>
                    {
                        thisComponentState.isPerformingOperation ? <div>Editing...</div> :

                            <div>
                                <div className="row">

                                    <div className="col-sm-12">
                                        <input type="button" value="Back" className="form-control" onClick={() => updateState({...thisComponentState,toUpdate:false})}/>
                                     </div>

                                </div>

                                <hr/>

                                <div className="row">
                                    <div className="col-sm-6">

                                        {
                                            getComponent(props.name)
                                        }
                                    </div>
                                    <div className="col-sm-6">
                                        <input value="Update" onClick={update} type="button" style={{ width: "150px" }} className="btn btn-primary" />
                                    </div>
                                </div>
                            </div>


                          
                    }

                </div>
            </div>  
        )

    } else {

        return (<div onDoubleClick={onDoubleClick}> {thisComponentState.value } </div>)
    }
}
