import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import uuid from "react-uuid";
import StandardAutoComplete from "../../StandardAutocomplete";
import DefineEmployeePosition from "./EmployeePosition";


export default function CreateCycle(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        showList: false,
        employeeId: "",
        evaluators: [],
        defineEmployeePosition: false,
        toReload: 0
    });

    useEffect(() => {

        if (thisComponentState.employeeId !== "") {
            updateEmployeeId(thisComponentState.employeeId);
        }

    }, [thisComponentState.toReload]);

    const add = () => {

        let tempDatas = [...thisComponentState.evaluators];

        tempDatas.push({ guidId: uuid(), evaluatorId: "", evaluatorType: "" });

        updateState({ ...thisComponentState, evaluators: tempDatas });
    }

    const updateEmployeeId = (id) => {

        fetch("/PerformanceEvaluations/GetEmployeePosition?" + new URLSearchParams({ employeeId: id }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.isPositionDefined) {

                    updateState({ ...thisComponentState, employeeId: id, position: response.position });

                    return;

                } else {

                    let confirmation = window.confirm("There is no position defined for this employee? Do you want define a position for this employee?")

                    if (confirmation) {

                        updateState({ ...thisComponentState,employeeId:id, defineEmployeePosition: true })

                        return;
                    }

                    return alert("No position is defined for this employee.")

                }
            });
    }


    const deleteItem = (guidId) => {

        let tempDatas = [...thisComponentState.evaluators];

        tempDatas = tempDatas.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, evaluators: tempDatas });
    }


    const updateEvaluatorId = (value, guidId) => {

        let tempDatas = [...thisComponentState.evaluators];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.evaluatorId = value;

        updateState({ ...thisComponentState, evaluators: tempDatas });

    }

    const updateEvaluatorType = (value, guidId) => {

        let tempDatas = [...thisComponentState.evaluators];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.evaluatorType = parseInt(value);

        updateState({ ...thisComponentState, evaluators: tempDatas });
    }


    const save = () => {

        if (thisComponentState.employeeId === "") {
            return alert("Employee cannot be empty.");
        }

     
        var isConfirm = window.confirm("Make sure your data are correct, wrong data leads to wrong computation of performance rating. Click ok to continue.");

        if (!isConfirm) {

            return;
        };

        let postBody = {
            evalueeId: thisComponentState.employeeId,
            evaluationCycleDetails: thisComponentState.evaluators
        }


        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PerformanceEvaluations/SaveCycle", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }



    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.showList) {

        return (<ListOfCycles back={() => updateState({ ...thisComponentState, showList: false })} />);

    }

    if (thisComponentState.defineEmployeePosition) {

        return (<DefineEmployeePosition employeeId={thisComponentState.employeeId} back={() => updateState({ ...thisComponentState, defineEmployeePosition: false,toReload:thisComponentState.toReload+1 })} />)
    }


    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" onClick={() => props.back()} className="form-control" type="button" />
                </div>
                <div className="col">
                    <input value="List" onClick={() => updateState({ ...thisComponentState, showList: true })} className="form-control" type="button" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Employee
                </div>
                <div className="col">
                   
                    <div className="row">
                        <div className="col-md-10">
                            <StandardAutoComplete url="Employees/GetEmployees" value={thisComponentState.employeeId} updateValue={updateEmployeeId} />
                        </div>

                        <div className="col-md-2">
                            <FontAwesomeIcon icon={faAdd} title="Add employee position" style={{ cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, defineEmployeePosition: true })} />
                        </div>
                    </div>


                </div>
                
            </div>


            <hr />

            <div className="row">
                <div className="col-md-5">
                    Position
                </div>
                <div className="col-md-5">

                    {
                        thisComponentState.position
                    }

                </div>
                <div className="col-md-2">

                   

                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col-md-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="col-md-2">Action</th>
                               
                                <th className="col-md-5">Evaluator Type</th>

                                <th className="col-md-5">Evaluator</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.evaluators.length > 0 && thisComponentState.evaluators.map((val, index) =>
                                    <tr key={val.guidId}>

                                        <td >
                                            <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => deleteItem(val.guidId)} />
                                        </td>

                                        <td >
                                            <div>
                                                <StandardAutoComplete url="PerformanceEvaluations/GetEvaluatorTypes" rowIdentifier={val.guidId} value={val.evaluatorType} updateValue={updateEvaluatorType} />
                                            </div>
                                        </td>

                                        <td >
                                            <div>
                                                <StandardAutoComplete url="Employees/GetEmployees" rowIdentifier={val.guidId} value={val.employeeId} updateValue={updateEvaluatorId} />
                                            </div>
                                        </td>

                                    </tr>)
                            }

                        </tbody>

                        <tfoot>
                            <tr>
                                <td>
                                    <input type="button" value="Add" className="form-control" onClick={add} />
                                </td>
                                <td>
                                    <input type="button" value="Save" className="form-control" onClick={save} />
                                </td>
                                <td>
                                </td>
                            </tr >
                        </tfoot>

                    </table>
                </div>
            </div>
        </div>
    )
}