export default function computeGrandTotal(data) {

    var grandTotal = 0;

    for (var i = 0; i < data.length; i++) {

        var qty = data[i].hasOwnProperty("quantity") ?  data[i].quantity : 0;
        var price = data[i].hasOwnProperty("price") ? data[i].price : 0;


        var result = qty * price;

        grandTotal = grandTotal + result;

    }

    return grandTotal;

}