import { useEffect, useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import EditTimeTravel from "./EditTimeTravel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function ShowTrip(props)
{
    const [employeeId, updateEmployeeId] = useState(props.employeeId);

    const [vehicleId, updateVehicleId] = useState("");

    const [reload, toReload] = useState(0);

    const [thisComponentState, updateState] = useState({

        isLoading: true,
        data: {},
        startOdometer: 0,
        endOdometer: 0,
        toEditTimeTravel:false
    });






    useEffect(() => {

        fetch('Trips/GetDetails?id='+props.id)
            .then(response => {
                // Check if response is successful (status code 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse response as JSON
                return response.json();
            })
            .then(data => {

                updateState({...thisComponentState,data:data,isLoading:false});

            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Fetch error:', error);
            });

    }, [reload]);


    const deleteBooking = () => {

        let confirmation = window.confirm("You can only delete your own booking? Click ok to continue.");

        if (!confirmation) {
            return;
        }

        fetch("/Trips/Delete", {
            method: "POST", // Use "POST" method for sending data
            headers: {
                "Content-Type": "application/json" // Specify the content type as JSON in the headers
            },
            body: JSON.stringify({
                value: props.id
            })
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/login";
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    return response.json();
                }
            })
            .then(response => {
                alert(response.message);
                if (response.success) {
                    props.back();
                }
            })
            .catch(error => {
                console.error("Error:", error);
                // Handle error
            });

    }

    const closeBooking = () => {

        let confirmation = window.confirm("Are you sure you want to close this? Click ok to continue");

        if (!confirmation) {
            return;
        };

        fetch("/Trips/Close", {
            method: "POST", // Use "POST" method for sending data
            headers: {
                "Content-Type": "application/json" // Specify the content type as JSON in the headers
            },
            body: JSON.stringify({
                value: props.id
            })
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/login";
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    return response.json();
                }
            })
            .then(response => {
                alert(response.message);
                if (response.success) {
                    props.back();
                }
            })
            .catch(error => {
                console.error("Error:", error);
                // Handle error
            });

    }

    const authorized = () => {

        let confirmation = window.confirm("Are you sure you want to authorized this trip? Click ok to continue");

        if (!confirmation) {
            return;
        };

        fetch("/Trips/Authorized", {
            method: "POST", // Use "POST" method for sending data
            headers: {
                "Content-Type": "application/json" // Specify the content type as JSON in the headers
            },
            body: JSON.stringify({
                value: props.id
            })
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/login";
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    return response.json();
                }
            })
            .then(response => {
                alert(response.message);
                if (response.success) {
                    props.back();
                }
            })
            .catch(error => {
                console.error("Error:", error);
                // Handle error
            });

    }

    const assigned = () => {

        let confirmation = window.confirm("Are you sure you want to assign this driver? Click ok to continue");

        if (!confirmation) {
            return;
        }

        if (employeeId === "" || vehicleId === "") {

            return alert("Driver or vehicle cannot be empty.");
        }

        fetch("/Trips/AssignedDriver", {
            method: "POST", // Use "POST" method for sending data
            headers: {
                "Content-Type": "application/json" // Specify the content type as JSON in the headers
            },
            body: JSON.stringify({
                value: {
                    item1: props.id,
                    item2: employeeId,
                    item3: vehicleId
                }
            })
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/login";
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    return response.json();
                }
            })
            .then(response => {
                alert(response.message);
                if (response.success) {
                    props.back();
                }
            })
            .catch(error => {
                console.error("Error:", error);
                // Handle error
            });

    }

    const saveTravelDistance = () => {

        let confirmation = window.confirm("Are you sure you want to save this? Click ok to continue");

        if (!confirmation) {
            return;
        }

        if (thisComponentState.startOdometer === 0 || thisComponentState.endOdometer ===0) {

            return alert("Start or end odometer cannot be zero.");
        }

        fetch("/Trips/SaveTravelDistance", {
            method: "POST", // Use "POST" method for sending data
            headers: {
                "Content-Type": "application/json" // Specify the content type as JSON in the headers
            },
            body: JSON.stringify({
                value: {
                    item1: props.id,
                    item2: parseFloat(thisComponentState.startOdometer),
                    item3: parseFloat(thisComponentState.endOdometer)
                }
            })
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/login";
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    return response.json();
                }
            })
            .then(response => {
                alert(response.message);
                if (response.success) {
                    props.back();
                }
            })
            .catch(error => {
                console.error("Error:", error);
                // Handle error
            });

    }


    const updateDriver = (code) => {

        updateEmployeeId(code);

    }


    const updateVehicle = (code) => {

        updateVehicleId(code);

    }

    if (thisComponentState.isLoading) {

        <div>Loading....</div>

    }

    const back = () => {

        updateState({ ...thisComponentState, toEditTimeTravel: false });

        toReload(reload + 1);
    }

    if (thisComponentState.toEditTimeTravel) {

        return (
            <div style={getModalStyle()}>

                <div style={getModalContentStyle() }>

                    <EditTimeTravel start={thisComponentState.data.start}  end={ thisComponentState.data.end} id={props.id} back={back}/>

                </div>

                </div>
            
            )
    }

    

    return (

        <div>

            <div className="row">

                <div className="col">

                </div>

                <div className="col">
                    <input value="Back" type="button" onClick={()=>props.back()} className="form-control"/>
                </div>

                <div className="col">
                    <input value="Delete" type="button" onClick={deleteBooking} className="form-control" />
                </div>

                <div className="col">
                    <input value="Close" type="button" onClick={closeBooking} className="form-control" />
                </div>

                <div className="col">
                    <input value="Authorize" type="button" onClick={authorized} className="form-control" />
                </div>


            </div>

            <hr/>
            <div className="row">
                <div className="col">
                    Date
                </div>
                <div className="col">
                    {thisComponentState.data.tripDate}
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Time
                </div>

                <div className="col">
                    <div className="row">                     
                        <div className="col-sm-10">
                            {thisComponentState.data.start} to {thisComponentState.data.end}
                        </div>

                        <div className="col-sm-2">
                            <FontAwesomeIcon icon={faEdit} title="Edit time travel" style={{cursor: 'pointer' }}  color="blue" onClick={() => updateState({ ...thisComponentState, toEditTimeTravel: true })} />
                        </div>
                    </div>                
                </div>

            </div>

            <div className="row">
                <div className="col">
                    Purpose
                </div>
                <div className="col">
                    {thisComponentState.data.description}
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Book By
                </div>
                <div className="col">
                    {thisComponentState.data.bookBy}
                </div>
            </div>


            <div className="row">
                <div className="col">
                    Destinations
                </div>
                <div className="col">
                    {typeof thisComponentState.data.trip !=='undefined' && thisComponentState.data.trip.destinations}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    passengers
                </div>
                <div className="col">
                    {typeof thisComponentState.data.trip !== 'undefined' &&  thisComponentState.data.trip.passengers}
                </div>
            </div>

            <hr/>

          

                {
                typeof thisComponentState.data.trip !== 'undefined' && thisComponentState.data.trip.driver !== null ?
                    <div>

                        <div className="row">
                            <div className="col">Driver</div>

                            <div className="col">{thisComponentState.data.trip.driver}</div>
                        </div>



                        <div className="row">
                            <div className="col">Vehicle</div>

                            <div className="col">{thisComponentState.data.trip.vehicle}</div>
                        </div>

                    </div>

                    



                    :

                    <div>
                        <div className="row">
                            <div className="col">
                                Driver
                            </div>
                            <div className="col">

                                <StandardAutoComplete url="Employees/GetEmployees" value={employeeId} clear={() => updateEmployeeId("")} updateValue={updateDriver} />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                Vehicle
                            </div>
                            <div className="col">
                                <StandardAutoComplete url="Generics/GetVehicles" value={vehicleId} clear={() => updateVehicleId("")} updateValue={updateVehicle} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">

                            </div>
                            <div className="col">
                                <input value="Assigned" type="button" className="form-control" onClick={assigned} />
                            </div>
                        </div>

                    </div>

                
                }


            <hr/>


            <div>
                <div className="row">
                    <div className="col">
                        Start Odo
                    </div>
                    <div className="col">

                        {
                          typeof thisComponentState.data.trip !=='undefined' &&   thisComponentState.data.trip.startOdometer !== 0 ? <div> {thisComponentState.data.trip.startOdometer}</div> : <input   className="form-control" onChange={(e) => updateState({ ...thisComponentState, startOdometer: e.currentTarget.value })} value={thisComponentState.startOdometer} />
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                       End Odo
                    </div>
                    <div className="col">
                        {
                            typeof thisComponentState.data.trip !== 'undefined' &&  thisComponentState.data.trip.endOdometer !== 0 ? <div> {thisComponentState.data.trip.endOdometer}</div> : <input  className="form-control" onChange={(e) => updateState({ ...thisComponentState, endOdometer: e.currentTarget.value })} value={thisComponentState.endOdometer} />
                        }
                    </div>
                </div>

                {
                    typeof thisComponentState.data.trip !== 'undefined' && (thisComponentState.data.trip.startOdometer === 0 || thisComponentState.data.trip.endOdometer === 0) &&
                    <div className="row">
                        <div className="col">
                        </div>
                        <div className="col">
                            <input value="Save" type="button" className="form-control" onClick={saveTravelDistance} />
                        </div>
                    </div>
                }

               

            </div>


        
        </div>
    )
}