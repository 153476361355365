import React, { Fragment, useEffect, useState } from 'react';

import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import ArCreditMemoListNavigator from './ArCreditMemoListNavigator';
import CreateArCreditMemoNavigator from './CreateArCreditMemoNavigator';
import CreateReturnNavigator from './CreateArCreditMemoNavigator';
import DetailsArCreditMemoNavigator from './DetailsArCreditMemoNavigator';
import DetailsReturnNavigator from './DetailsArCreditMemoNavigator';


export default function ArCreditMemoForm() {


    const transactionCode = "ArCreditMemo";
    const postUrl = "/ArCreditMemos/Create";
    const detailsUrl = "/ArCreditMemos/Details/?id=";
    const editDetailUrl = "/ArCreditMemos/EditItem";
    const listUrl = "/ArCreditMemos/Index";
    const validatorUrl = "/ArCreditMemos/Validate";

    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['99', '107', '108', '109', '110', '100'],
        type: "S"

    };

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, [])

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<ArCreditMemoListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsArCreditMemoNavigator/>} />);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={ validatorUrl} context={context} transactionCode={transactionCode} metaData={data.metaData} postUrl={postUrl} listUrl={listUrl} detailsUrl={detailsUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList } >

                <TransactionForm createNavigator={<CreateArCreditMemoNavigator/>} detailsNavigator={<DetailsArCreditMemoNavigator/>} transactionList={transactionList }/>

            </TransactionContextProvider>

        )
    }

}

