
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandardAutoComplete from '../../StandardAutocomplete';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function ProductionListNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        manufacturingStatus: null,
        documentId: null,
        fromDate: null,
        toDate: null

    });


    async function search() {

        updateState({ ...thisComponentState, isLoading: true })

        let params = {};

        if (typeof thisComponentState.fromDate !== 'undefined' && thisComponentState.fromDate !== "" && thisComponentState.fromDate !== null && typeof thisComponentState.toDate !== 'undefined' && thisComponentState.toDate !== "" && thisComponentState.toDate !== null) {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (typeof thisComponentState.documentId !== 'undefined' && thisComponentState.documentId !== "" && thisComponentState.documentId !== null) {

            params.documentId = thisComponentState.documentId;
        }

        if (typeof thisComponentState.manufacturingStatus !== 'undefined' && thisComponentState.manufacturingStatus != null) {

            params.manufacturingStatus = thisComponentState.manufacturingStatus;
        }

        if (typeof thisComponentState.branchId !== 'undefined' && thisComponentState.branchId != null) {

            params.branchId = thisComponentState.branchId;
        }


        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params))
            .then(response =>

                response.status == 401 ? window.location = "/Login" : response.json()

            ).then(response => {

                const dataFromServer = response;

                var tempData = { ...data.metaData };

                tempData.listData = dataFromServer;

                updateMetaData({ metaData: tempData });

                updateState({ ...thisComponentState, isLoading: false });

                clear();

            });

    };



    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });

    };


    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            documentId: null,
            fromDate: null,
            toDate: null,
            manufacturingStatus: null,
            branchId: null
        });

    }

    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle("100px", "30%")}>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
                </div>
            </div>
        )
    };



    return (

        <div>

            <div>
                <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
                <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search} type="button" />
                <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />
            </div>

        
            <br />
            <br />

            <div className="row">

                <div className="col">

                    <table className='table table-bordered'>

                        <tbody>
                            <tr>
                                <td>
                                    <div>From</div>
                                </td>
                                <td>
                                    <input className="form-control" title="From Date" type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>To</div>
                                </td>
                                <td>
                                    <input className="form-control" title="To Date" type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Document Id</div>
                                </td>
                                <td>
                                    <input className="form-control" title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Enter Document Id" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div>Branch</div>
                                </td>
                                <td>
                                    <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Branch Search" />

                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <div>Manufacturing Status</div>
                                </td>
                                <td>
                                    <select className="form-control" value="" onChange={(e) => updateState({ ...thisComponentState, transactionStatus: e.currentTarget.value })} >
                                        <option value="">All</option>
                                        <option value="0">Not Planned</option>
                                        <option value="1">Released</option>
                                        <option value="2">Not Released</option>
                                    </select>
                                </td>
                            </tr>
                         </tbody>

                     
                    </table>
                </div>

            </div>

        </div>


    )

}


export default ProductionListNavigator;