import { useEffect } from "react";
import { useState } from "react"
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";


export default function InstallationProcess(props) {


    const [thisComponentState, updateState] = useState({
        isLoading: true,
        from: "",
        to: "",
        items: [],
        showDetails: false,
        item: {}
    });



    useEffect(() => {

        generate();

    }, []);

    const generate = () => {

        let params = {};

        if (thisComponentState.from !== "" && thisComponentState.to !== "") {

            params.from = thisComponentState.from;
            params.to = thisComponentState.to;
        }

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/GoodsIssues/GetInstallationProcesses?" + new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.from = thisComponentState.from;
                tempData.to = thisComponentState.to;
                tempData.isLoading = false;
                tempData.items = response

                updateState(tempData);

            });
    }


    const downLoad = () => {

        var fromDate = thisComponentState.from;
        var toDate = thisComponentState.to;

        if (fromDate === "" || toDate === "") {
            return alert("Please define a valid date range.");
        }

        updateState({ ...thisComponentState, isExporting: true });

        fetch("/GoodsIssues/ExportInstallationProcesses?" + new URLSearchParams({
            fromDate: fromDate,
            toDate: toDate
        }), {
            method: "GET",
            headers: {
                "Accept": "text/csv" // Set appropriate content type for CSV
            }
        })
            .then(response => {
                if (response.status === 401) {
                    window.location = "/Login";
                } else {
                    return response.blob();
                }
            })
            .then(responseData => {
                // Create a temporary anchor element
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(responseData);
                link.setAttribute('download', 'InstallationProcesses.csv'); // Set filename with .csv extension

                // Simulate a click on the anchor element to trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);

                updateState({ ...thisComponentState, isExporting: false });
            })
            .catch(error => {
                console.error('Error:', error);
                updateState({ ...thisComponentState, isExporting: false });
            });
    }



    return (

        <div>

            {
                thisComponentState.showDetails && (
                    <div style={getModalStyle()}>
                        <div style={getModalContentStyle()}>

                            <div className="row">
                                <input type="button" className="form-control" value="Back" onClick={() => updateState({...thisComponentState,showDetails:false}) }/>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className="col">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Uom</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                thisComponentState.item.details.map(val => <tr>

                                                    <td>{val.name}</td>
                                                    <td>{val.quantity}</td>
                                                    <td>{val.uom}</td>

                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                    </div>                            
                            </div>



                        </div>

                    </div>)

            }


            <div className="row">
                <div className="col">
                    <input value="Back" type="button" className="form-control" onClick={()=>props.back()} />
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.from} onChange={(e) => updateState({ ...thisComponentState, from: e.currentTarget.value })} className="form-control" />
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.to} onChange={(e) => updateState({ ...thisComponentState, to: e.currentTarget.value })} className="form-control" />
                </div>

                <div className="col">
                    <input value="Generate" type="button" onClick={generate} className="form-control" />
                </div>

                <div className="col">
                    <input value="Export" type="button" onClick={downLoad} className="form-control" />
             </div>
 
            <hr/>

            <div className="row">

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Machine</th>
                            <th>Date</th>
                            <th>Stock Issue Id</th>
                        </tr>
                    </thead>

                    <tbody>

                        {
                            thisComponentState.items.map(val =>
                                <tr key={uuid()}>
                                    <td>
                                        <input value="Details" type="button" onClick={() => updateState({ ...thisComponentState, item: val, showDetails: true })} className="form-control" />
                                    </td>
                                    <td>{val.machine}</td>
                                    <td>{val.date}</td>
                                    <td>{val.stockIssueId}</td>
                                </tr>)
                        }

                    </tbody>

                </table>
            </div>

            </div>

        </div>
    )
}