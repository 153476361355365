import React from 'react';
import { useState } from 'react';


 export default function Announce ()
 {
     const [message, updateMessage] = useState("");

     async function announce() {

         var toPost = {
             Value:message
         }
         await fetch("/generics/announce",
             {
                    method:'post',
                    body: JSON.stringify(toPost),
                     // Adding headers to the request
                     headers: {
                         "Content-type": "application/json; charset=UTF-8"
                     }
             })

             .then(response => response.status === 401 ? window.location = "/Login" : response.json())
             .then(response => {

                 //updateValue({ ...value, isLoaded: true, suggestions: response });

             });

     }


     return (

         <div>

             <textarea className="form-control" onChange={(e)=> updateMessage(e.currentTarget.value)} placeholder="Enter message...">

             </textarea>

             <br/>

             <input value="Announce" className="btn btn-success" onClick={announce } type="button"/>

         </div>

     )

}

