import { end } from "@popperjs/core";
import { useEffect, useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import getTotal from "../../Functions/getTotal";
import ItemTransactionHistory from "./ItemTransactionHistory";
import SourceVariance from "./SourceVariance";

export default function VarianceMaxtrix(props) {

    const [items, setItems] = useState([]);
    const [agents, setAgents] = useState([]);

    const [data] = useState(props.data);

    const [sourceVariance, updateVariance] = useState({});
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");

    const [seeSourceVariance, setSourceVarianceState] = useState(false);
    const [seeItemTransactionHistory,setItemTransactionHistoryState] = useState(false);


    useEffect(() => {

        if (props.data && props.data.length > 0) {

            const uniqueItemCodes = Array.from(new Set(props.data.map(val => val.itemCode)));

            const uniqueItems = uniqueItemCodes.map(itemCode => {
                const item = props.data.find(val => val.itemCode === itemCode);
                return { itemCode: item.itemCode, itemName: item.itemName };
            });

            setItems(uniqueItems);

            const uniqueSlpCodes = Array.from(new Set(props.data.map(val => val.slpCode)));
            const uniqueAgents = uniqueSlpCodes.map(slpCode => {
                const item = props.data.find(val => val.slpCode === slpCode);
                return { slpCode: item.slpCode, slpName: item.slpName };
            });

            setAgents(uniqueAgents);

        }
    }, [data]);

    const getBegInv = (slpCode,itemCode) => {

        let result = data.filter(val =>val.slpCode===slpCode &&  val.itemCode === itemCode && (val.identifier === "BegInventory" || val.identifier==="YesterdaysReturns")).map(val=>val.quantity); 

        return getTotal(result);
    }

    const getTransfer = (slpCode, itemCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.itemCode === itemCode && val.identifier === "BreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getEndInv = (slpCode, itemCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.itemCode === itemCode && (val.identifier === "EndingInventory" || val.identifier==="Returns")).map(val => val.quantity);

        return getTotal(result);
    }
    const getNetTransfer = (slpCode, itemCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.itemCode === itemCode && val.identifier === "NetBreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getInvoice = (slpCode, itemCode) => {



        let result = data.filter(val => val.slpCode === slpCode && val.itemCode === itemCode && val.identifier === "Invoice").map(val => val.quantity);

        return getTotal(result);
    }

    const getVariance = (slpCode, itemCode) => {

        let begInv = getBegInv(slpCode, itemCode);
        let transfer = getTransfer(slpCode, itemCode);
        let invoice = getInvoice(slpCode, itemCode);
        let endInv = getEndInv(slpCode, itemCode);

        return endInv - (begInv + transfer -invoice);
    }

    const setSourceVariance = (slpCode, itemCode) => {

        let begInv = getBegInv(slpCode, itemCode);
        let transfer = getTransfer(slpCode, itemCode);
        let invoice = getInvoice(slpCode, itemCode);
        let endInv = getEndInv(slpCode, itemCode);

        updateVariance({
            BegInv: begInv,
            Transfer: transfer,
            Invoice: invoice,
            BookInv: (begInv+transfer-invoice),
            EndInv: endInv,
            Variance: endInv - (begInv + transfer - invoice)
        });

        setSourceVarianceState(true);
    }

    const deleteAgentsWithNoVariance = () => {
        let tempData = [...agents];

        let agentsWithVariance = tempData.filter(function (val) {
            let hasVariance = false;
            items.forEach(item => {
                let variance = getVariance(val.slpCode, item.itemCode);
                if (variance !== 0) {
                    hasVariance = true;
                    return; // Breaks out of the inner forEach loop
                }
            });
            return hasVariance;
        });

        const uniqueItemCodes = Array.from(new Set(agentsWithVariance.map(val => val.slpCode)));

        const filteredAgents = uniqueItemCodes.map(slpCode => {
            const item = data.find(val => val.slpCode === slpCode);
            return { slpCode: item.slpCode, slpName: item.slpName };
        });

        setAgents(filteredAgents);
       
    }

    const deleteItemsWithNoVariance = () => {

        let tempData = [...items];

        let itemsWithVariance = tempData.filter(function (val) {
            let hasVariance = false;
            agents.forEach(item => {
                let variance = getVariance(item.slpCode, val.itemCode);
                if (variance !== 0) {
                    hasVariance = true;
                    return; // Breaks out of the inner forEach loop
                }
            });
            return hasVariance;
        });

        const uniqueItemCodes = Array.from(new Set(itemsWithVariance.map(val => val.itemCode)));

        const filteredItems = uniqueItemCodes.map(itemCode => {
            const item = data.find(val => val.itemCode === itemCode);
            return { itemCode: item.itemCode, itemName: item.itemName };
        });

        setItems(filteredItems);

    }


    if (seeSourceVariance) {

        return (
                <div style={getModalStyle()}>
                    <div style={getModalContentStyle("500px","500px")}>

                        <SourceVariance back={()=>setSourceVarianceState(false)} data={sourceVariance}/>

                    </div>
                </div>
            )
    }
    if (seeItemTransactionHistory) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle("1000px", "1000px")}>
                    <ItemTransactionHistory from={props.from} to={props.to} back={() => setItemTransactionHistoryState(false)} itemName={itemName} itemCode={itemCode} />
                </div>
            </div>
        )
    }




    return (
        <div>
            <table className="table table-bordered">
                <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }} >
                    <tr onClick={()=>deleteAgentsWithNoVariance()}>
                        <th>Item Code</th>
                        <th>Item Name </th>
                        {
                            agents.map && agents.length > 0 && agents.map(val => <th>{val.slpName}</th>)
                        }

                    </tr>
                </thead>
                <tbody>
                    {items.map(item =>
                        <tr key={item.itemCode} onDoubleClick={() => { setItemName(item.itemName); setItemCode(item.itemCode); setItemTransactionHistoryState(true)}}>
                            <td onClick={()=> deleteItemsWithNoVariance()} >{item.itemCode}</td>
                            <td style={{ position: "sticky", left: 0 }}>{item.itemName}</td>
                            {
                                agents.map && agents.length > 0 && agents.map(val => <td onDoubleClick={()=>setSourceVariance(val.slpCode,item.itemCode)}>{getVariance(val.slpCode,item.itemCode)}</td>)
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
