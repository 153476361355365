import { useState } from "react";
import getModalContentStyle from "./Functions/getModalContentStyle";
import getModalStyle from "./Functions/getModalStyle";


export default function EditParent(props) {

    const [thisComponentState, updateState] = useState({

        isEditing: false,
        guidId: props.guidId,
        name: props.name,
        value:props.value,
        toUpdate: false,
        editables: ["docDiscount","requiredDate","note"]

    });

    const update = () => {

        const confirm = window.confirm("Are you you want to edit this item?")

        if (!confirm) {
            return;
        }

        updateState({ ...thisComponentState, isEditing: true });

        fetch(props.editDetailUrl, {
            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                guidId: props.guidId,
                name: props.name === "uomDesc" ? "uomEntry" : props.name,
                value: thisComponentState.value
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong.")
                }
                else {
                    return response.json();
                }

            })

            // Displaying results to console
            .then(json => {

                if (json.success) {

                    props.getDetails(props.url);

                    updateState({ ...thisComponentState, isEditing: false, toUpdate: false });

                } else {

                    updateState({ ...thisComponentState, isEditing: false, toUpdate: false, value: props.value });

                    alert(json.message);
                };

            });
    }





    const getComponent = () => {

        if (!thisComponentState.editables.includes(props.name)) {

            updateState({...thisComponentState,toUpdate:false})

            return alert("This field is not editable.");
        }


        if (props.name === "docDiscount") {

            return (<input type="number" value={thisComponentState.value} className="form-control" style={{ width: "150px" }} onChange={(e) => updateState({ ...thisComponentState, value: e.currentTarget.value })} />);
            
        }

        return (<input value={thisComponentState.value} className="form-control" style={{ width: "150px" }} onChange={(e) => updateState({ ...thisComponentState, value: e.currentTarget.value })} />);
    };


    if (thisComponentState.toUpdate) {

        return (

            <div style={getModalStyle()}>
                <div style={getModalContentStyle("200px", "400px")}>
                    {
                        thisComponentState.isPerformingOperation ? <div>Editing...</div> :

                            <div>
                                <div className="row">

                                    <div className="col-sm-12">
                                        <input type="button" value="Back" className="form-control" onClick={() => updateState({ ...thisComponentState, toUpdate: false })} />
                                    </div>

                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-6">

                                        {
                                            getComponent(props.name)
                                        }
                                    </div>
                                    <div className="col-sm-6">
                                        <input value="Update" onClick={update} type="button" style={{ width: "150px" }} className="btn btn-primary" />
                                    </div>
                                </div>
                            </div>



                    }

                </div>
            </div>
        )

    } else {

        return (<div onDoubleClick={() => updateState({...thisComponentState,toUpdate:true})}> {thisComponentState.value} </div>)
    }

}
