import { faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import EditableData from './EditableData';
import capitalizeFirstLetter from './Functions/capitalizedFirstLetter';
import computeGrandTotal from './Functions/computeGrandTotal';
import { TransactionContext } from './Transactions/TransactionContext';
import uuid from 'react-uuid';
import EditParent from './EditParent';

export default function Details(props) {

   const { data, updateMetaData,updateGuidId } = useContext(TransactionContext);

   const [goTransactionList, updateGoToTransactionList] = useState(false);

   const [isPerformingOperation, updateOperation] = useState(false);

   const getUrl = props.state.detailsUrl + props.state.guidId;

   const editUrl = props.state.editDetailUrl;

    const deleteDetailUrl = props.state.deleteDetailUrl;

   const [isLoading, updateState] = useState(true);

    useEffect(() => {

        getDetails(getUrl);

    }, [data.metaData.reloadDetails]);


    async function getDetails(url) {

        await fetch(url)

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

               const tempData = {...data.metaData};

               tempData.detailsFromDb = responseData;

               updateMetaData({ metaData: tempData });

               updateGuidId(responseData.guidId);

               updateState(false);

           });
    };                                                                                                                                                                                                                                                                                                  

 
    const qrCodeContainer = (rowSpan,qrCodeData) => {

        // Base64 string data
        const qrCode = qrCodeData["qrCode"];

        const QrCode = () => <img className="img-fluid" style={{ height: '200px',width:'200px'}} src={`data:image/jpeg;base64,${qrCode}`} />

        return (<td rowSpan={rowSpan}> <QrCode/> </td> )

    }

    const deleteItem = (guidId) => {

        const confirm = window.confirm("Are you you want to delete this item? This action is irreversible.")

        if (!confirm) {
            return;
        }

        updateOperation(true);

        fetch(deleteDetailUrl, {

            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (response.success) {

                    const tempData = { ...data.metaData };
                    const detailsFromDb = { ...tempData.detailsFromDb };

                    detailsFromDb.details = detailsFromDb.details.filter(x => x.guidId !== guidId);

                    tempData.detailsFromDb = detailsFromDb;

                    updateMetaData({ metaData: tempData });

                    updateOperation(false)

                } else {

                    updateOperation(false);

                    return alert(response.message);
                }
            });
    }



    if (isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Deleting... 
                    </button>
                </div>
            </div>
        </div>)
    }


    if (isLoading) {

        return (<div>Please wait...</div>)

    } else {

        if (goTransactionList) {
    
            return (data.metaData.transactionList)

        } else {

            return (
                <div>
                    <div className="row">
                        <table className="table table-bordered" >
                            <tbody>

                                { 
                                   Object.entries(data.metaData.detailsFromDb)
                                        .filter(property => property[0].toLowerCase() !== "details")
                                        .filter(property => property[0].toLowerCase() !== "guidid")
                                        .filter(property => property[0].toLowerCase() !== "qrcode")
                                        .map(
                                            (parentData, index) =>

                                                <tr key={uuid()}>
                                                    <td className="col-lg-3"> {capitalizeFirstLetter(parentData[0])} </td>

                                                    <td className="col-lg-6">  
                                                   
                                                        <EditParent name={parentData[0]} value={parentData[1]} getDetails={getDetails} guidId={props.state.guidId} editDetailUrl={editUrl} url={getUrl} />
                                           
                                                    </td>

                                                    {index === 0 && qrCodeContainer(Object.entries(data.metaData.detailsFromDb)
                                                        .filter(property => property[0] !== "details")
                                                        .filter(property => property[0].toLowerCase() !== "guidid").length, data.metaData.detailsFromDb)}
                                                </tr>

                                            )
                                }
                            </tbody>
                        </table>
                    </div>

                    <br />

                    <FontAwesomeIcon icon={faList} title="List" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => updateGoToTransactionList(true)} />
    
                    { props.detailsNavigator }

                    <div className="table table-responsive">
                        <br />

                        <table className="table table-bordered">

                            <thead className="thead-dark">

                                <tr>
                                    <th>Action</th>
                                    
                                    {
                                        Object.entries(data.metaData.detailsFromDb.details[0]).filter(property => property[0].toLowerCase() !== "guidid").map(header =>

                                            <th key={uuid()}>
                                                {capitalizeFirstLetter(header[0])}
                                            </th>
                                        )
                                    }
                                </tr>

                              
                            </thead>

                            <tbody>

                                {
                                    data.metaData.detailsFromDb.details.map(x =>
                                        <tr key={Math.random()}>

                                            <td>
                                                <FontAwesomeIcon icon={faTrash} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }}  size="2x" color="blue" onClick={() => deleteItem(x.guidId)} />                                            
                                            </td>

                                            {
                                                Object.entries(x).filter(property => property[0].toLowerCase() !== "guidid").map(data =>
                                                    <td key={uuid() }>
                                                        <EditableData code={data[0]==="uomDesc" ? x.code: "" } name={data[0]} value={ isNaN(data[1]) ? data[1] : data[1]!==null ? data[1].toLocaleString() : ""} getDetails={getDetails} guidId={x.guidId} editDetailUrl={editUrl} url={getUrl} />
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )
                                }

                            </tbody>

                            <tfoot className="thead-dark">

                                <tr>
                                    <td>Grand Total</td>
                                    <td colSpan={Object.entries(data.metaData.detailsFromDb.details[0]).length - 2} style={{ textAlign: "right" }} >

                                    </td>
                                    <td>
                                        {computeGrandTotal(data.metaData.detailsFromDb.details).toLocaleString()}
                                    </td>
                                </tr>


                                <tr>
                                    <td>Discount</td>
                                    <td colSpan={Object.entries(data.metaData.detailsFromDb.details[0]).length - 2} style={{ textAlign: "right" }} >

                                    </td>
                                    <td>
                                        {typeof data.metaData.detailsFromDb.docDiscount == 'undefined' ? 0 : data.metaData.detailsFromDb.docDiscount }
                                    </td>
                                </tr>

                                <tr>
                                    <td>Net Amount</td>
                                    <td colSpan={Object.entries(data.metaData.detailsFromDb.details[0]).length - 2} style={{ textAlign: "right" }} >

                                    </td>
                                    <td>
                                        {
                                            (computeGrandTotal(data.metaData.detailsFromDb.details) - (typeof data.metaData.detailsFromDb.docDiscount == 'undefined' ? 0 : data.metaData.detailsFromDb.docDiscount  ) ).toLocaleString()
                                        }
                                    </td>
                                </tr>
                          
                            </tfoot>

                        </table>
                    </div>
                </div>
            )
        }
    }
}


