import getParentName from "./getParentName";
import getParentValue from "./getParentValue";


export default function searchPrice(detailProperty,metaData,trIndex,updateDetailValue) {


    
    if (metaData === undefined) {

        return 0;

    };

    let inventoryGuidId = detailProperty["code"];

    if (inventoryGuidId !== undefined) {

        let inventory = metaData.inventories.find(x => x.inventoryGuidId == inventoryGuidId);

        console.log("Inventory is ", inventory);

        if (inventory !== undefined) {

            let customerGuidId = metaData.parentProperties["code"];

            let listPrice = inventory.listPrices.find(x => x.customerGuidId == customerGuidId);

            console.log("List price is ", listPrice);

            if (listPrice !== undefined) {

             
                updateDetailValue(trIndex, "price", listPrice.price);

          
            }

            return 0;
        }

        return 0;

    }
    return 0;
}