import { faArrowLeft, faArrowRight, faFilter, faL, faListAlt, faMessage, faRotate, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import OpenSrFilter from "./OpenSrFilter";
import SrDetails from "./SrDetails";


export default function Messages(props) {


    const [thisComponentState, updateState] = useState({
        from: "",
        to:"",
        details: [],
        pickItems: [],
        showDetails: false,
        selectedGuidId:null,
        isLoading:false,
        retrieve: 0,
        multipleGuidIds: [],
        showSummary: false,
        filterDetails: false,
        originalValues: []
    });

    useEffect(() => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/StockRequisitions/GetDefaultDateRange?")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.from = response.from;
                tempData.to = response.to;
                tempData.isLoading = false;
                
                updateState(tempData);

            });
       
        
    }, [thisComponentState.retrieve]);



    const pick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = true;

        updateState({ ...thisComponentState, details: items });

    }

    const unPick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = false;

        updateState({ ...thisComponentState, details: items });

    }


    const showSummary = () => {

        let selectedGuidIds = thisComponentState.details.filter(x => x.isPick).map(y => y.guidId);

        let items = [...thisComponentState.multipleGuidIds];

        items = selectedGuidIds;

        updateState({ ...thisComponentState, multipleGuidIds: items,showSummary:true});
    }

    
    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

 
    const doubleBack = () => {

        //The previous
        props.back();

        //This component

        updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })

    }


    if (thisComponentState.showDetails) {

        return (<SrDetails doubleBack={doubleBack} guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })} />)
    }


   
    const generate = () => {

        if (thisComponentState.from === "" || thisComponentState.to === "") {

            return alert("Defined a valid date range.")
        }

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/StockRequisitions/GetListOfST" + new URLSearchParams({

            from: thisComponentState.from,
            to: thisComponentState.to

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.originalValues = response;

                updateState(tempData);

            });
    }




    const onChangeFrom = (e) => {

        updateState({ ...thisComponentState, from: e.currentTarget.value });
 
    }

    const onChangeTo = (e) => {

        updateState({ ...thisComponentState, to: e.currentTarget.value});

    }



    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                  
                    <div className="col">
                        <div>
                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
               
                        </div>
                    </div>

                    <div className="col">
                        <input className="form-control" type="date" value={thisComponentState.from} onChange={onChangeFrom} />
                    </div>
                 
                    <div className="col">
                        <input className="form-control" type="date" value={thisComponentState.to} onChange={onChangeTo} />
                    </div>
                    <div className="col">
                        <input className="form-control" type="button" value="Retrieve" onClick={generate} />
                    </div>


                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="2"> Action</th>
                                    <th> Request Date </th>
                                    <th> Required Date </th>
                                    <th> Age</th>  
                                    <th> Doc Id </th>
                                    <th> Requestor </th>
                                    <th> Branch</th>
                                    <th> Department</th>
                                    <th> Remarks </th>
                                    <th> Action </th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={val.guidId}>

                                            <td>                                                                                           
                                                <input value={val.isPick ? "UnPick" : "Pick"} className="form-control" type="button" onClick={() => val.isPick   ? unPick(val.guidId) : pick(val.guidId)} />
                                            </td>

                                            <td>
                                               <input value="Details" className="form-control" type="button" onClick={() => updateState({...thisComponentState,selectedGuidId:val.guidId,showDetails:true}) } />
                                            </td>

                                            <td> {val.requestDate}</td>
                                            <td> {val.requiredDate}</td>
                                            <td> {val.age}</td>
                                            <td> {val.documentId}</td>
                                            <td> {val.requestor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.department}</td>
                                            <td> {val.remarks}</td>
                                            <td>
                                                <FontAwesomeIcon icon={faMessage} title="Messages from warehouse" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showMessages: true })} />
                                            </td>

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
