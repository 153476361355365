
import { createStore } from 'redux';

import rootReducer from './Reducer';

import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/lib/persistStore';

//import { rootReducer } from 'react-redux';

const persistConfig = {

    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


const storer  = () => {

    let store = createStore(persistedReducer);
    let persistor = persistStore(store);

    let jStore = {
        store: store,
        persistor: persistor
    };

    return jStore;
}


export default storer;

 //const  store = createStore(rootReducer,
 //   window.__REDUX_DEVTOOLS_EXTENSION__ &&
 //   window.__REDUX_DEVTOOLS_EXTENSION__());

//export default store;