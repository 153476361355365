import { faCopy, faInfo, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";

export default function CycleDetails(props)
{
    
    const [thisComponentState, updateState] = useState({

        isLoading: false,
        showQrCode:false,
        list: []
   
    });

    useEffect(() => {

        updateState({...thisComponentState,isLoading:true})

        fetch("/PerformanceEvaluations/GetCycleDetails?" + new URLSearchParams({
            id:props.id
        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (!response.success) {

                     updateState({ ...thisComponentState, isLoading: false });

                     return alert(response.message);
                };

                updateState({ ...thisComponentState, list: response.model, isLoading: false });

            });

    },[])

  
    const copyToClipboard = (text) => {
        
        navigator.clipboard.writeText(text)
            .then(() => alert("This link is very confidential.\n Do not share with anyone.\n Share with the appropriate person.\n The link has been copied."))
            .catch((error) => console.error("Unable to copy text: ", error));
    };

    if (thisComponentState.isLoading)
    {
        return (<div>Please wait...</div>)
    }

    const generateQrCodes = () => {

        updateState({ ...thisComponentState, isLoading: true })

        fetch("/PerformanceEvaluations/GetQrCodes?" + new URLSearchParams({
            id: props.id
        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (!response.success) {

                    updateState({ ...thisComponentState, isLoading: false });

                    return alert(response.message);
                };

                let tempData = [...thisComponentState.list];

                for (let i = 0; i < tempData.length; i++)
                {
                    let qrCode = response.model.find(item => item.id === tempData[i].id);

                    if (typeof qrCode !== 'undefined') {

                        tempData[i].qrCode = qrCode.qrCode;
                    }
                }

                updateState({ ...thisComponentState, list: tempData, isLoading: false,showQrCode:true });

            });

    }


    return (
        <div >
            <div >

                <div className="row">

                    <div className="col">
                        <input value="Back" type="button" className="form-control" onClick={()=>props.back()}/>
                    </div>

                    <div className="col">
                        <input value="Qr Code" type="button" className="form-control" onClick={generateQrCodes} />
                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th> Id</th>
                                    <th> Employee Code</th>
                                    <th> Evaluator </th>
                                    <th> Evaluator Type</th>
                                    <th> Valid Until</th>
                                    <th> Link</th>

                                    {
                                       thisComponentState.showQrCode && <th> Qr Code</th>
                                    }

                                    
                                </tr>
                            </thead>

                            <tbody>

               

                                {
                                   thisComponentState.list.length > 0 && thisComponentState.list.map(val =>

                                        <tr>                
                                          
                                            <td> {val.id}</td>
                                            <td> {val.code}</td>
                                            <td> {val.evaluator}</td>
                                            <td> {val.evaluatorType}</td>
                                            <td> {val.validUntil}</td>
                                            <td style={{display:'flex',flexDirection:'row'}}>

                                               <div>
                                                   {val.url}
                                               </div>
                                               <div>
                                                   <FontAwesomeIcon icon={faCopy} title="Copy" style={{ cursor: 'pointer' }} size="2x" color="red" onClick={(e)=> copyToClipboard(val.url)}/>              
                                               </div>
                                            </td>

                                           {
                                               thisComponentState.showQrCode &&
                                               <td>
                                                   <div style={{height:'240px',width:'240px'}}>
                                                       <img className="img-fluid" style={{ height: '100%', width: "100%" }} src={`data:image/jpeg;base64,${val.qrCode}`} />
                                                   </div>     
                                               </td>
                                           }
                                             

                                        </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
