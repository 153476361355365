import { useState } from "react";

import getModalContentStyle from "./components/Functions/getModalContentStyle";
import getModalStyle from "./components/Functions/getModalStyle";


export default function UploadFile(props) {

    const [thisComponentState, updateState] = useState({

        selectedFiles: [],
        uploading: false,
        isSeparateUpload: props.isSeparateUpload

    });

    const  onFileChange = (e) => {

        let chosenFiles = Array.prototype.slice.call(e.target.files);

        updateState({ ...thisComponentState, selectedFiles:chosenFiles});

        if (typeof props.action !== 'undefined') {

            props.action(chosenFiles);

        }

    };

    // On file upload (click the upload button)
  const onFileUpload = () => {

      if (!thisComponentState.isSeparateUpload)
      {
          return alert("Separate upload is not permitted.");
      }

      if (thisComponentState.selectedFiles.length > props.fileLimit) {

          return alert("You can only upload upto 3 files.");
      }

      let formData = new FormData();

      thisComponentState.selectedFiles.forEach(val => formData.append("files", val));

      formData.append("GuidId", props.identifier);

      updateState({...thisComponentState,uploading:true})

      fetch(props.uploadUrl,

          {
              method: "post",
              body: formData

          }).then(response => response.status === 401 ? window.location = "/login" : response.json())
          .then(response => {

              alert(response.message);

              updateState({ ...thisComponentState, uploading: false })

          });
  };


    if (thisComponentState.uploading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Uploading...
                    </button>
                </div>
            </div>
        </div>)
    }



    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">
                    <div className="col">
                        <input type="button" value="Back" className="form-control" onClick={()=>props.back()}  />
                    </div>
                    <div className="col">
                        <input type="file" className="form-control" onChange={onFileChange} multiple/>
                    </div>
                    <div className="col">
                        <button className="form-control" onClick={onFileUpload}>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
    
}