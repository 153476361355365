import { useEffect, useState } from "react";
import getTotal from "../../Functions/getTotal";
import StandardAutoComplete from "../../StandardAutocomplete";

export default function PerItem(props) {

    const [items, setItems] = useState([]);

    const [itemCode, setItemCode] = useState("");

    const [data, updateData]= useState([]);

    useEffect(() => {

        filter(itemCode);
        
    }, [itemCode]);

    const filter = (itemCode) => {

        if (props.data && props.data.length > 0) {

            let filteredData = props.data.filter(val => val.itemCode === itemCode);

            updateData(filteredData);

            const uniqueSlpCodes = Array.from(new Set(props.data.map(val => val.slpCode)));
            const uniqueItems = uniqueSlpCodes.map(slpCode => {
                const item = props.data.find(val => val.slpCode === slpCode);
                return { slpCode: item.slpCode, slpName: item.slpName };
            });

            setItems(uniqueItems);
        }
    }


    const getBegInv = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && (val.identifier === "BegInventory" || val.identifier==="YesterdaysReturns")).map(val=>val.quantity); 

        return getTotal(result);
    }

    const getTransfer = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.identifier === "BreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getEndInv = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && (val.identifier === "EndingInventory" || val.identifier==="Returns")).map(val => val.quantity);

        return getTotal(result);
    }
    const getNetTransfer = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.identifier === "NetBreadOut").map(val => val.quantity);

        return getTotal(result);
    }

    const getInvoice = (slpCode) => {

        let result = data.filter(val => val.slpCode === slpCode && val.identifier === "Invoice").map(val => val.quantity);

        return getTotal(result);
    }

    const getVariance = (slpCode) => {

        return getEndInv(slpCode) - (getBegInv(slpCode) + getTransfer(slpCode) - getInvoice(slpCode));
    }

    const updateItemCode = (code) => {

        setItemCode(code);
    }

    const computeWithVarianceOnly = () => {

        let tempData = [...data];

        let withVarianceOnly = tempData.filter(function (val) {

            return getVariance(val.slpCode) !== 0;

        });

        const uniqueItemCodes = Array.from(new Set(withVarianceOnly.map(val => val.slpCode)));

        const uniqueItems = uniqueItemCodes.map(slpCode => {
            const item = data.find(val => val.slpCode === slpCode);
            return { slpCode: item.slpCode, slpName: item.slpName };
        });

        setItems(uniqueItems);

        updateData(withVarianceOnly);

    }
    return (
        <div>

            <div className="row">
                <div className="col">
                    <StandardAutoComplete placeholder="Select Item" value={itemCode} url="/Inventories/GetInventories" updateValue={updateItemCode} />
                </div>        
            </div>

            <div className="row">
                <div className="col">

                    <table className="table table-bordered">
                        <thead>
                            <tr onClick={()=>computeWithVarianceOnly()}>
                                <th>Slp Code </th>
                                <th>Slp Name </th>
                                <th>Beg Inv</th>
                                <th>Transfer </th>
                                <th>End Inv </th>
                                <th>Net Transfer </th>
                                <th>Invoice</th>
                                <th>Variance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item =>
                                <tr key={item.slpCode}>
                                    <td>{item.slpCode}</td>
                                    <td>{item.slpName}</td>
                                    <td>{getBegInv(item.slpCode)}</td>
                                    <td>{getTransfer(item.slpCode)}</td>
                                    <td>{getEndInv(item.slpCode)}</td>
                                    <td>{getNetTransfer(item.slpCode)}</td>
                                    <td>{getInvoice(item.slpCode)}</td>
                                    <td>{getVariance(item.slpCode)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
