import { faList, faQuestionCircle, faSave, faToolbox, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";
import NameDimension from "./NameDimension";
import SliceDefinitions from "./SliceDefinitions"
import getModalStyle from '../../Functions/getModalStyle';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import ItemRequestList from "./ItemRequestList";
import InventoryList from "./InventoryList";
import InventoryVariation from "./InventoryVariation";
import InventoryVariantDefinitions from "./InventoryVariantDefinitions";
import CreateNewUom from "./CreateNewUom";
import AssignedUom from "./AssignedUom";

export default function CreateInventory(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        categoryId: "",
        ugpEntry: "",
        type: "",
        code: "",
        name: "",
        saleUomEntry: "",
        purchaseUomEntry: "",
        inventoryUomEntry: "",
        sellItem: "Y",
        purchaseItem: "Y",
        inventoryItem: "Y",
        defaultAccountCode: "",
        shortCode: "",
        area: "",
        expenseCode: "",
        isManufactured: "",
        isService: "",
        isForecastable: "",
        sliceDefinitions: [],
        variantDefinitions:[],
        showSliceDefinition: false,
        showVariantDefinition:false,
        showNameDimension: false,
        showItemRequestList: false,
        createNewUom: false,
        assignedNewUom: false,
        showList:false,
        nameDimension: {

            description: "",
            brand: "",
            shape: "",
            color: "",
            size: "",
            length: "",
            width: "",
            height: "",
            diameter: "",
            getFullName: function () {

                let brand = this.brand !== "" ? " " + this.brand : "";
                let shape = this.shape !== "" ? " " + this.shape : "";

                let color = this.color !== "" ? " " + this.color : "";

                let length = this.length !== "" ? " " + this.length : "";
                let width = this.width !== "" ? " " + this.width : "";
                let height = this.height !== "" ? " " + this.height : "";

                let size = length + width + height;


                let diameter = this.diameter !== "" ? " " + this.diameter : "";

                let name = this.description + brand + shape + color + size + diameter;

                return name;
            }
        }
    });


    useEffect(() => {

        fetch("/Inventories/GetNextInventoryCode?" + new URLSearchParams({
            type: thisComponentState.type

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, code: response.value });

            });

    }, [thisComponentState.type]);



    const save = () => {


        let confirm = window.confirm("Make sure your data are correct. Please click ok to continue saving.")

        if (!confirm) {
            return;
        }

        if (thisComponentState.code === "") {

            return alert("Code is required.");
        };

        if (thisComponentState.categoryId === "") {

            return alert("Category group required.");
        };

        if (thisComponentState.ugpEntry === "") {

            return alert("Uom group is required.");
        };

        if (thisComponentState.name === "") {

            return alert("Name is required.");
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Inventories/Create", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false, type: "NONE" });

                return alert(response.message);
            });

    }

    const updateSaleUomEntry = (code) => {
        updateState({ ...thisComponentState, saleUomEntry: code });
    }
    const updatePurchaseUomEntry = (code) => {
        updateState({ ...thisComponentState, purchaseUomEntry: code });
    }
    const updateInventoryUomEntry = (code) => {
        updateState({ ...thisComponentState, inventoryUomEntry: code });
    }
    const setNameDefinition = (data) => {
        updateState({ ...thisComponentState, name: data.getFullName(), showNameDimension: false, nameDimension: data });
    }
    const setSliceDefinitions = (data) => {
        updateState({ ...thisComponentState, sliceDefinitions: data, showSliceDefinition: false });
    }

    const setVariantDefinitions = (data) => {
        updateState({ ...thisComponentState, variantDefinitions: data, showVariantDefinition: false });
    }


    const updateCategoryId = (id) => {
        updateState({ ...thisComponentState, categoryId: id });
    }
    const updateUgpEntry = (entry) => {
        updateState({ ...thisComponentState, ugpEntry: entry });
    }

   

    if (thisComponentState.showNameDimension) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <NameDimension nameDimension={thisComponentState.nameDimension} set={setNameDefinition} />
                </div>
            </div>
        )
    }




    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }

    const finalize = (guidId) => {

        fetch("/StockRequisitions/GetItemRequestDetails?" + new URLSearchParams({
            guidId: guidId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    response.model.nameDimension.getFullName = thisComponentState.nameDimension.getFullName;

                    updateState(response.model);

                } else {

                    alert(response.message);                 
                }
            });
    }



    if (thisComponentState.showItemRequestList) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <ItemRequestList finalize={finalize} back={() => updateState({ ...thisComponentState, showItemRequestList: false })} />
                </div>
            </div>
        )
    }


    if (thisComponentState.showSliceDefinition) {

        return (<SliceDefinitions set={setSliceDefinitions} definitions={thisComponentState.sliceDefinitions} />)

    }



    if (thisComponentState.showVariantDefinition) {

        return (<InventoryVariantDefinitions name={thisComponentState.name} set={setVariantDefinitions} definitions={thisComponentState.variantDefinitions} />)

    }



    if (thisComponentState.showList) {
        return (
            <InventoryList back={() => updateState({...thisComponentState,showList:false }) }/>
        )
    }

    if (thisComponentState.createNewUom) {

        return (<CreateNewUom back={() => updateState({...thisComponentState,createNewUom:false})}/>)
    }

    if (thisComponentState.assignedNewUom) {

        return (<AssignedUom back={() => updateState({ ...thisComponentState,assignedNewUom: false })} />)
    }





    return (

        <div>

            <hr />

            <div className="row">
                <div className="col">

                    <FontAwesomeIcon icon={faSave} title="click to save" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={save} />
                    <FontAwesomeIcon icon={faList} title="list of inventories for coding" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, showItemRequestList: true })} />
                    <FontAwesomeIcon icon={faList} title="list of inventories" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showList: true })} />
                    <FontAwesomeIcon icon={faTools} title="Create new Uom" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, createNewUom: true })} />
                    <FontAwesomeIcon icon={faToolbox} title="Uom assignment" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, assignedNewUom: true })} />


                </div>

                <div className="col">
                </div>

                <div className="col">
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">

                    <div className="row">
                        <div className="col">
                            <div>
                                Type
                            </div>
                        </div>
                        <div className="col">
                            <select defaultValue="NONE" onChange={(e) => updateState({ ...thisComponentState, type: e.currentTarget.value })} className="form-control" >
                                <option value="NONE">Select Prefix</option>
                                <option value="RM">RM</option>
                                <option value="OT">OT</option>
                                <option value="GR">GR</option>
                                <option value="JW">JW</option>
                                <option value="KM">KM</option>
                            </select>
                        </div>
                    </div>

                    <br />



                    <div className="row">

                        <div className="col">
                            <div title="Choose prefix to generate the value of this field">
                                Code
                            </div>
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.code} disabled  className="form-control" />
                        </div>

                    </div>

                    <br />

                    <div className="row">

                        <div className="col">
                            <div>
                                Name
                            </div>
                        </div>

                        <div className="col">
                            <input type="text" value={thisComponentState.name} onClick={() => updateState({ ...thisComponentState, showNameDimension: true })} className="form-control" />
                        </div>

                    </div>
                    <hr />

                </div>

                <div className="col">


                    <div className="row">

                        <div className="col">
                            <div>
                                Sell Item
                            </div>
                        </div>

                        <div className="col">

                            <select value={thisComponentState.sellItem} onChange={(e) => updateState({ ...thisComponentState, sellItem: e.currentTarget.value })} className="form-control" >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>

                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            <div>
                                Purchase Item
                            </div>
                        </div>
                        <div className="col">

                            <select value={thisComponentState.purchaseItem} onChange={(e) => updateState({ ...thisComponentState, purchaseItem: e.currentTarget.value })} className="form-control" >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>


                        </div>
                    </div>

                    <br />

                    <div className="row">

                        <div className="col">
                            <div>
                                Inventory Item
                            </div>
                        </div>

                        <div className="col">
                            <select value={thisComponentState.inventoryItem} onChange={(e) => updateState({ ...thisComponentState, inventoryItem: e.currentTarget.value })} className="form-control" >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>



                    <hr />
                </div>
            </div>





            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Category Group
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.categoryId} url="/Categories/GetCategories" updateValue={updateCategoryId} />
                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div title="The base uom for this item.">
                        Uom Group
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.ugpEntry} url="/UomValues/GetUgpEntries" updateValue={updateUgpEntry} />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div>
                        Sale Uom Entry
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete mountCounter={thisComponentState.ugpEntry} params={{ugpEntry:thisComponentState.ugpEntry}} value={thisComponentState.saleUomEntry} url="/UomValues/GetUomValues" updateValue={updateSaleUomEntry} />
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Purchase Uom Entry
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete mountCounter={thisComponentState.ugpEntry} params={{ ugpEntry: thisComponentState.ugpEntry }}  value={thisComponentState.purchaseUomEntry} url="/UomValues/GetUomValues" updateValue={updatePurchaseUomEntry} />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div>
                        Inventory Uom Entry
                    </div>
                </div>

                <div className="col">

                    <StandardAutoComplete mountCounter={thisComponentState.ugpEntry} params={{ ugpEntry: thisComponentState.ugpEntry }}  value={thisComponentState.inventoryUomEntry} url="/UomValues/GetUomValues" updateValue={updateInventoryUomEntry} />

                </div>
            </div>



         

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Service
                    </div>
                </div>

                <div className="col">
                    <select value={thisComponentState.isService} onChange={(e) => updateState({ ...thisComponentState, isService: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>
            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Forecastable
                    </div>
                </div>

                <div className="col">
                    <select value={thisComponentState.isForecastable} onChange={(e) => updateState({ ...thisComponentState, isForecastable: e.currentTarget.value })} className="form-control" >
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>

            </div>

            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Manufactured
                    </div>
                </div>


                <div className="col">

                    <div className="row">
                        <div className="col">

                            <select value={thisComponentState.isManufactured } onChange={(e) => updateState({ ...thisComponentState, isManufactured: e.currentTarget.value })} className="form-control" >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>

                        <div className="col">
                            <input type="button" value="Slice Definition" className="form-control" onClick={() => updateState({ ...thisComponentState, showSliceDefinition: true })} />
                        </div>
                    </div>

                </div>

            </div>


            <hr />

            <div className="row">

                <div className="col">
                    <div>
                        Is Bill of Materials
                    </div>
                </div>


                <div className="col">
                    <div className="row">
                        <div className="col">
                            <select value={thisComponentState.value} onChange={(e) => updateState({ ...thisComponentState, isBom: e.currentTarget.value })} className="form-control" >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>


                        <div className="col">
                            <input type="button" value="Add Variation" className="form-control" onClick={() => updateState({ ...thisComponentState, showVariantDefinition: true })} />
                        </div>
                    </div>
                </div>

             

            </div>




          

        </div>
    )

}
