
import React, {useContext, useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { TransactionContext } from '../TransactionContext';
import TransactionForApproval from '../TransactionForApproval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSpinner} from '@fortawesome/free-solid-svg-icons'
import StandardAutoComplete from '../../StandardAutocomplete';
import { useEffect } from 'react';
import uuid from 'react-uuid';
import exportToCSV from '../../Functions/exportToCSV';
import { toCsvString } from '../../Functions/toCsvString';


export default function PurchaseOrderListNavigator(props)
{ 

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isLoading : false,
        documentId: "",
        sapId:"",
        code: "",
        fromDate: "",
        toDate: "",
        needDate :"",
        transactionStatus: "",
        notingStatus:"",
        docStatus: "",
        branchId: "",
        requestorId: "",
        note:"",
        itemCode :""
        
    });


    async function search()
    {

        updateState({ ...thisComponentState,isLoading:true })

        let params = {};

        if (thisComponentState.fromDate !== "" && thisComponentState.toDate !== "")
        {
            params.fromDate = thisComponentState.fromDate;
            params.toDate = thisComponentState.toDate;
        };

        if (thisComponentState.documentId !== "" ) {
            params.documentId = thisComponentState.documentId;
        }

        if (thisComponentState.requestorId !== "")
        {
            params.requestorId = thisComponentState.requestorId;
        }

        if ( thisComponentState.code !== "" ) {

            params.code = thisComponentState.code;
        }

        if ( thisComponentState.transactionStatus !== "") {

            params.transactionStatus = thisComponentState.transactionStatus;

        }


        if (thisComponentState.notingStatus !=="") {

            params.notingStatus = thisComponentState.notingStatus;

        }

        if (thisComponentState.docStatus !== "") {

            params.docStatus = thisComponentState.docStatus;
        }

        if ( thisComponentState.branchId !== "") {

            params.branchId = thisComponentState.branchId;
        }

        if ( thisComponentState.needDate !=="") {

            params.needDate = thisComponentState.needDate;
        }

        if ( thisComponentState.sapId !== "") {

            params.sapId = thisComponentState.sapId

        }

        if (thisComponentState.itemCode !== "") {

            params.itemCode = thisComponentState.itemCode;
        }


        if (thisComponentState.note !== "") {

            params.note = thisComponentState.note;
        }



        await fetch(data.metaData.listUrl + "?" + new URLSearchParams(params))
        .then(response =>

            response.status == 401 ? window.location = "/Login" : response.json()

        ).then(response => {

            const dataFromServer = response;

            var tempData = { ...data.metaData };

            tempData.listData = dataFromServer;

            updateMetaData({ metaData: tempData });

            updateState({ ...thisComponentState, isLoading: false });

        });

    };




    const clear = () => {

        updateState({
            ...thisComponentState,
            isLoading: false,
            documentId: "",
            code: "",
            fromDate: "",
            toDate: "",
            needDate :"",
            transactionStatus: "",
            notingStatus:"",
            docStatus: "",
            branchId: "",
            guidId : uuid()
        })
    }



    if (thisComponentState.isLoading) {

        return (
            <div style={getModalStyle()}>
                <div style={ getModalContentStyle("100px","30%")}>

                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    &ensp; Loading...
               
                </div>
            </div>
        )
    };


    if (thisComponentState.approvedTransaction) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle()}>
                    <TransactionForApproval url="/StockRequisitions/GetTransactionsForApproval" updateState={updateState} />
                </div>         
            </div>
        );

    };

    const updateCodeId = (code) => {

        updateState({ ...thisComponentState, code: code });
    };

    const updateRequestorId = (code) => {

        updateState({ ...thisComponentState,requestorId: code });
    };

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });
    };

    const updateItemCode = (code) => {
        updateState({ ...thisComponentState, itemCode: code });
    };



    const exportItems = () => {

        if (data.metaData.listData.length === 0) {
            return alert("No items queried.");
        }

        exportToCSV("data", toCsvString(data.metaData.listData))

    }
  

 
    return (

        <div style={{ textAlign: 'center' }}>

            <input className="form-control" style={{ width: "150px", float: "left" }} value="New" onClick={() => window.location.reload()} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Go" onClick={search}type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Clear" onClick={clear} type="button" />
            <input className="form-control" style={{ width: "150px", float: "left", marginLeft: "10px" }} value="Export" onClick={exportItems} type="button" />

            <br />
            <br />

            <table className='table table-bordered'>

                <tbody>

                    <tr>
                        <td>
                            <div>From</div>
                        </td>
                        <td>
                            <input className="form-control" value={thisComponentState.fromDate} title="From Date" type="date" onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>To</div>
                        </td>
                        <td>
                            <input className="form-control" value={thisComponentState.toDate} title="To Date" type="date" onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Need Date</div>
                        </td>
                        <td>
                            <input className="form-control" value={thisComponentState.needDate } title="Need Date" type="date" onChange={(e) => updateState({ ...thisComponentState, needDate: e.currentTarget.value })} />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Document Id</div>
                        </td>
                        <td>
                            <input className="form-control" value={thisComponentState.documentId}  title="This will override all other criterias." onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} placeholder="Enter Document Id" />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Sap Id</div>
                        </td>
                        <td>
                            <input className="form-control" value={thisComponentState.sapId} title="This will override all other criterias" onChange={(e) => updateState({ ...thisComponentState, sapId: e.currentTarget.value })} placeholder="Enter Sap Id" />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <div>Vendor</div>
                        </td>
                        <td>
                            <StandardAutoComplete url="/Vendors/GetVendors" value={thisComponentState.code} updateValue={updateCodeId} placeholder="Enter Vendor" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Branch</div>
                        </td>
                        <td>
                            <StandardAutoComplete url="/Branches/GetBranches" value={thisComponentState.branchId} updateValue={updateBranchId} placeholder="Branch Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div> Requestor </div>
                        </td>
                        <td>
                            <StandardAutoComplete url="/Employees/GetEmployees" value={thisComponentState.requestorId} updateValue={updateRequestorId} placeholder="Requestor Search" />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Doc Status</div>
                        </td>
                        <td>
                            <select className="form-control" value={thisComponentState.docStatus} onChange={(e) => updateState({ ...thisComponentState, docStatus: e.currentTarget.value })}>
                                <option value="">All</option>
                                <option value="Closed">Closed</option>
                                <option value="Open">Open</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div>Approval Status</div>
                        </td>
                        <td>
                            <select className="form-control" value={thisComponentState.transactionStatus} onChange={(e) => updateState({ ...thisComponentState, transactionStatus: e.currentTarget.value })} >
                                <option value="">All</option>
                                <option value="0">UnApproved</option>
                                <option value="1">Approved</option>
                                <option value="2">DisApproved</option>

                            </select>
                        </td>
                    </tr>

                    <tr>

                        <td>
                            <div>Noting Status</div>
                        </td>

                        <td>
                            <select className="form-control" value={thisComponentState.notingStatus} onChange={(e) => updateState({ ...thisComponentState, notingStatus: e.currentTarget.value })} >
                                <option value="">All</option>
                                <option value="3">Noted</option>
                                <option value="4">UnNoted</option>
                            </select>
                        </td>
                    </tr>

                    <tr>

                        <td>Note</td>

                        <td>
                            <input value={thisComponentState.note} onChange={(e) => updateState({...thisComponentState,note:e.currentTarget.value})} className="form-control"/>
                        </td>

                    </tr>

                    <tr>
                        <td>
                            <div>Item Code</div>
                        </td>
                        <td>
                            <StandardAutoComplete key={uuid()} url="/Inventories/GetInventories" value={thisComponentState.itemCode} updateValue={updateItemCode} placeholder="All transactions that has this item" />
                        </td>
                    </tr>



                </tbody>

             
            </table>

            <br />
        </div>

    )

}

