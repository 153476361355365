import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import capitalizeFirstLetter from '../Functions/capitalizedFirstLetter';
import exportToCSV from '../Functions/exportToCSV';


export default function BreadOutSummary(props) {


    const [thisComponentState, updateState] = useState({

        data: [],
        isInitial: true,
        isLoading: false,
        transferType: "",
        fromDate: "",
        toDate: "",
        isExporting: false,
        area:"ALL"

    });


    async function generate(area) {

        var fromDate = thisComponentState.fromDate;

        var toDate = thisComponentState.toDate;


        if (fromDate === "" || toDate === "") {

            return alert("Please defined a valid date.");
        };

        await fetch("/BreadOuts/BreadOutSummary?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate,
            area:thisComponentState.area,
            transferType: thisComponentState.transferType

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                updateState({ ...thisComponentState, data: [], isLoading: false, isInitial: false });

                if (responseData.data.length > 0) {

                    updateState({ ...thisComponentState, data: responseData, isLoading: false, isInitial: false });

                } else {

                    updateState({ ...thisComponentState, data: [] });

                    return alert("No available data.");
                }
            });

    };

    const getQty = (array, name) => {

        var filtered = array.find(x => x.shortCode == name);

        if (typeof (filtered) !== 'undefined') {

            return filtered.quantity;
        }
    }



    const exports = () => {

        var fromDate = thisComponentState.fromDate;

        var toDate = thisComponentState.toDate;

        if (fromDate === "" || toDate === "") {

            return alert("Please defined a valid date.");
        };

        updateState({ ...thisComponentState, isExporting: true });

         fetch("/BreadOuts/Export?" + new URLSearchParams({

            fromDate: fromDate,
            toDate: toDate,
            area:thisComponentState.area,
            transferType: thisComponentState.transferType

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.success) {

                    exportToCSV("BreadOutSummary",responseData.value)

                    updateState({ ...thisComponentState, isExporting: false });

                } else {

                    updateState({ ...thisComponentState, isExporting: false });

                    return alert("No available data.");
                }
            });

    }

    const getTotalPerBread = (shortCode) => {

        var total = 0;

        thisComponentState.data.data.map(perAgent => {

            perAgent.details.map(detail => {

                if (detail.shortCode === shortCode) {

                    var parseValue = parseFloat(detail.quantity);

                    if (!isNaN(parseValue)) {

                        total += parseValue;
                    };
                };
            });

        });


        return total;

    }


    const areas = () => {
        var contents = ["ALL", "OLD", "NEW"];
        return (
            <select class="form-control" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState, area: e.currentTarget.value })}  >
                { contents.map(area => area === thisComponentState.area ? <option selected value={area}>{area}</option> : <option value={area}>{area }</option> )}
            </select>        
       )
    }

    const transferTypes = () => {
        var contents = [
            { "value": "", "text": "ALL" },
            { "value": "0", "text": "Bread Out" },
            { "value": "1", "text": "Returns From Agent" },
            { "value": "2", "text": "Left Over" },
            { "value": "3", "text": "Bad Orders" },
            { "value": "4", "text": "Inter-Transfers" },
            { "value": "5", "text": "Bread Out Adjustment" },
            { "value": "8", "text": "BReturns Adjustment" },
            { "value": "9", "text": "Stock Transfer" }
        ];
        return (
            <select class="form-control" aria-label="Default select example" style={{ width: "150px", float: "left", marginLeft: "15px" }} onChange={(e) => updateState({ ...thisComponentState,transferType: e.currentTarget.value })}  >
                {contents.map(type => type.value === thisComponentState.transferType ? <option selected value={type.value}>{type.text}</option> : <option value={type.value}>{type.text}</option>)}
            </select>
        )
    }

    if (thisComponentState.isExporting)
    {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Exporting...
                    </button>
                </div>
            </div>
        </div>)

    }


    if (thisComponentState.isInitial) {

        return (

            <div style={{ float: "left", display: "inline-block" }}>

                <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Date Range</div>
                <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />
                
                {areas()}

                {transferTypes()}

                <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => generate()} value="Generate" />
                <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                <br />
                <br />

            </div>

        )
    }

    if (thisComponentState.isLoading) {

        return (<div>Please wait </div>)

    } else {


        if (typeof (thisComponentState.data) != 'undefined' && typeof (thisComponentState.data.data) != 'undefined' && thisComponentState.data.data.length != 0) {

            return (

                <div>
                    <div style={{ float: "left", display: "inline-block" }}>
                        <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Date Range</div>

                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                        <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                        {areas()}
                       
                        {transferTypes()}

                        <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => generate()} value="Generate" />

                        <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                        <br />
                        <br />
                       
                    </div>

                    <br />

                    <div>

                        <table className='table table-bordered' style={{ marginLeft: "15px" }} >

                            <thead className="thead-dark">

                                <th>Agent</th>

                                {
                                    Object.entries(thisComponentState.data.shortCodes).map(y =>

                                        <th> {(y[1])}</th>
                                    )
                                }

                            </thead>

                            <tbody>

                                {
                                    thisComponentState.data.data.map(agent =>

                                        <tr>
                                            <td> {agent.agentName}</td>

                                            {
                                                thisComponentState.data.shortCodes.map(shortCode =>

                                                    <td> {getQty(agent.details, shortCode)}

                                                    </td>

                                                )
                                            }

                                        </tr>
                                    )
                                }

                            </tbody>


                            <tfoot>
                                <tr className='thead-dark'>

                                    <td colSpan="1">Grand Total</td>

                                    {
                                        thisComponentState.data.shortCodes.map(shortCode =>

                                            <td> {getTotalPerBread(shortCode)} </td>
                                        )
                                    }

                                </tr>

                            </tfoot>

                        </table>
                    </div>
                </div>
            )

        } else {

            return (
                <div style={{ float: "left", display: "inline-block" }}>
                    <div style={{ width: "150px", float: "left", marginLeft: "15px" }} className='form-control'>Date Range</div>

                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.fromDate} onChange={(e) => updateState({ ...thisComponentState, fromDate: e.currentTarget.value })} />
                    <input type="date" className="form-control" style={{ width: "150px", float: "left", marginLeft: "15px" }} value={thisComponentState.toDate} onChange={(e) => updateState({ ...thisComponentState, toDate: e.currentTarget.value })} />

                    {areas()}

                    {transferTypes()}

                    <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => generate()} value="Generate" />

                    <input type="button" className="form-control" style={{ width: "120px", float: "left", marginLeft: "15px" }} onClick={() => exports()} value="Export" />

                    <br />
                    <br />
                </div>
            )
        }
    }
}

