import React, { Fragment, useEffect, useState } from 'react';

import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import BreadOutListNavigator from './BreadOutListNavigator';
import CreateBreadOutNavigator from './CreateBreadOutNavigator';
import DetailsBreadOutNavigator from './DetailsBreadOutNavigator';



export default function BreadOutForm() {

    const transactionCode = "InventoryTransfer";
    const postUrl = "/BreadOuts/Create";
    const detailsUrl = "/BreadOuts/Details/?id=";
    const editDetailUrl = "/BreadOuts/EditItem";
    const listUrl = "/BreadOuts/Index";
    const validatorUrl = "/BreadOuts/Validate";


    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['103', '104', '105', '107', '108', '109', '110', '129', '132', '138', '139', '140', '141', '142', '143', '144', '145'],
        type: "S",
        formDiscriminator: "BreadOut"
    };


    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, [])

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<BreadOutListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsBreadOutNavigator />} />);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl } context={context} transactionCode={transactionCode} metaData={data.metaData} postUrl={postUrl} listUrl={listUrl} detailsUrl={detailsUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList} >
                <TransactionForm createNavigator={<CreateBreadOutNavigator />} detailsNavigator={<DetailsBreadOutNavigator />} transactionList={transactionList }/>
            </TransactionContextProvider>

        )
    }
}
