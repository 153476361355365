import { faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import uuid from "react-uuid";
import PureInput from "../../PureInput";
import InventoryVariation from "./InventoryVariation";


export default function AddVariation(props) {

    const [thisComponentState, updateState] = useState({


        code: props.code,
        definitions:[],
        isPerformingOperation: false,

    });

    useEffect(() => {

        fetch("Inventories/GetVariations?" + new URLSearchParams({
            itemCode: thisComponentState.code
         })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let modifiedResponse = [];
 
                for (let i = 0; i < response.length; i++)
                {
                    let definition = {
                        name: response[i].name,
                        brand: "",
                        size: "",
                        shape: "",
                        color: "",
                        length: "",
                        width: "",
                        height: "",
                        diameter: "",
                        getFullName: function () {

                            let name = this.size + this.length + this.width + this.height + this.shape + this.color + this.name + this.diameter + this.brand;

                            return name;
                        }
                    };

                    response[i].definition = definition;

                    modifiedResponse.push(response[i]);
                }

                updateState({
                    ...thisComponentState,
                    definitions: modifiedResponse
                });

            });
    },[]);

    const save = () => {

        let isConfirm = window.confirm("Are you sure with your data?");

        if (!isConfirm) {
            return;
        }


        fetch("/Inventories/AddVariation",
            {
                method: "Post",
                headers: {
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(thisComponentState.definitions) 

            }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                alert(response.message);

            })
    }


    const add = () => {

        let tempDatas = [...thisComponentState.definitions ];

        tempDatas.push({
            guidId: uuid(),
            name: "",
            itemCode:thisComponentState.code,
            definition: {
                name: props.name,
                brand: "",
                size: "",
                shape: "",
                color: "",
                length: "",
                width: "",
                height: "",
                diameter:"",
                getFullName: function () {

                    let name = this.size + this.length + this.width + this.height + this.shape + this.color + this.name + this.diameter + this.brand;

                    return name;
                }
            }

        });
        updateState({...thisComponentState,definitions:tempDatas});
    }

    const deleteItem = (guidId) => {

        let tempData = [...thisComponentState.definitions];

        tempData = tempData.filter(val => val.guidId !== guidId);

        updateState({ ...thisComponentState, definitions: tempData });
    }

    const onUpdate = (guidId, name, value,definition) => {

        let tempDatas = [...thisComponentState.definitions];

        let tempData = tempDatas.find(val => val.guidId === guidId);

        tempData.name = value;

        tempData.definition = definition;

        updateState({ ...thisComponentState, definitions: tempDatas,showInventoryVariation:false });

    }

    
    if (thisComponentState.showInventoryVariation) {

        return (<InventoryVariation name={props.name} definition={thisComponentState.definition} back={() => updateState({ ...thisComponentState, showInventoryVariation: false })} set={onUpdate} selectedGuidId={thisComponentState.selectedGuidId} />)
    }

       
    return (

        <div>

            <div className="row">
                <div className="col">
                    <input type="button" className="form-control" value="Back" onClick={()=> props.set(thisComponentState.definitions)} />
                </div>
            </div>

            <hr/>

            <div className="row">

                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <FontAwesomeIcon icon={faQuestionCircle} title="An item in sap can have multiple variant" style={{  cursor: 'pointer' }} size="1x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />

                                </th>

                                <th>Name</th>
                                
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.definitions.length > 0 && thisComponentState.definitions.map((val, index) => <tr>
                                    <td >
                                        <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => deleteItem(val.guidId)} />
                                    </td>
                             
                                    <td >
                                        <div>
                                            {index + 1}
                                        </div>
                                    </td>

                                    <td >
                                        <div>
                                            <input onClick={() => updateState({ ...thisComponentState, selectedGuidId: val.guidId, showInventoryVariation: true,definition:val.definition })} value={val.name }  className="form-control"/>
                                        </div>
                                    </td>
                                  
                                </tr>)
                            }


                        </tbody>

                        <tfoot>
                            <tr>
                                <td>
                                    <input type="button" value="Add" className="form-control" onClick={add} />
                                </td>

                                <td>
                                    <input type="button" value="Save" className="form-control" onClick={save} />
                                </td>

                                <td>

                                </td>
     
                            </tr >
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    )

}