import { faChargingStation, faClock, faDraftingCompass, faFileArchive, faHandsWash, faList, faMinus, faMoneyBill, faPalette, faPlus, faSchool } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useState } from "react"
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import ClockSvg from '../../../SGV/ClockSvg';
import CrateSvg from '../../../SGV/CrateSvg';
import WashedSvg from '../../../SGV/WashedSvg';
import getPropertyValueFromAutoCompleteSuggestions from '../../Functions/getPropertyValue';
import StandardAutoComplete from '../../StandardAutocomplete';
import AgentsToCharge from './AgentsToCharge';
import CrateInventory from './CrateInventory';
import CratesStatus from './CratesStatus';
import CrateWashedAutoComplete from './CrateWashedAutoComplete';
import CycleCounts from './CycleCounts';
import EditCrate from './EditCrate';
import EditWashedHistory from './EditWashedHistory';
import PendingWashes from './PendingWashes';
import StatusType from './StatusType';
import ToPayCharges from './ToPayCharges';
import WashedCratesDrafts from './WashedCratesDrafts';
import WashedList from './WashedList';
import WashedStatus from './WashedStatus';


export default function WashedCrate() {

    const [thisComponentState, updateState] = useState({

        washedDate: "",
        isLoading :true,
        agentId:"",   
        serialNumber: "",
        washedCrates: [],
        isSaving: false,
        crateUrl:"",
        crates: [],
        showStatus: false,
        showWashStatus: false,
        showCrateStatus:false,
        toPay: false,
        toCharge:false,
        editWashes: false,
        editCrate: false,
        showWashedList: false,
        toEnterWashes :false
    });


    useEffect(() => {

        getCrates();

        var masterfiles = localStorage.getItem("masterfiles");

        if (masterfiles === null) {

            updateState({ ...thisComponentState, isLoading: true });

             fetch("/Generics/GetMasterfiles")
                .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                .then(responseData => {

                    localStorage.setItem("masterfiles", JSON.stringify(responseData));

                    updateState({ ...thisComponentState, isLoading: false });

                });
        }
    }, [thisComponentState.agentId]);

    const getCrates = () => {

        updateState({ ...thisComponentState, isLoading: true })

        fetch("/Crates/GetCrates?" + new URLSearchParams({

            agentId: thisComponentState.agentId
        }))

            .then(response => response.status===401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({
                    ...thisComponentState,
                    isLoading:false,
                    crates:response                   
                });         
            });
    }

    const deleteCrate = (crateId) => {

        var tempData = [...thisComponentState.washedCrates];

        tempData = tempData.filter(value => value !== crateId);

        updateState({ ...thisComponentState, washedCrates: tempData });

    }

    const add = (crateId) => {

        var tempData = [...thisComponentState.washedCrates];

        tempData.push(parseInt(crateId));

        updateState({ ...thisComponentState, washedCrates: tempData });

        fetch("/Generics/RefreshConnection").then(response => response.status === 401 ? window.location = "/login" : response.json());

    }


    const clear = (pfunction) => {

        pfunction();

    }

    const updateValue = (agentId) => {

        const url = "/Crates/GetCrates?agentId="+agentId;

        updateState({ ...thisComponentState, agentId: agentId,crateUrl :url});

    }

    const save = () => {

        var isConfirm = window.confirm("Make sure that your date is correct. \nThe agent selected is correct. \nIf yes click ok. ");

        if (!isConfirm) {

            return;
        };

        if (thisComponentState.date === null) {

            return alert("Invalid date.");
        }

        const washedCrates = thisComponentState.washedCrates.map(val => parseInt(val));

        const postBody = {

            agentId: thisComponentState.agentId,
            washedDate: thisComponentState.washedDate,
            washedCrates : washedCrates
        }

        updateState({ ...thisComponentState, isSaving: true })

        fetch("/Crates/Washed", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isSaving: false, washedCrates: [] });

            });
    }


    const getSerialNumber = (crateId) => {

       var crate = thisComponentState.crates.find(val => parseInt(val.code) === parseInt(crateId));

       return crate.description;

    }



    const draftIt = () => {

        if (thisComponentState.agentId === "") {

            return alert("Please select an agent.");
        }

        if (thisComponentState.washedDate === "") {
            return alert("Please choose washed date.");
        }

        let toSave = window.confirm("Are you sure you want to save this as draft?");

        if (!toSave)
        {
            return;
        }

        const postBody = {

            agentId: thisComponentState.agentId,
            washedDate: thisComponentState.washedDate,
            washedCrates:thisComponentState.washedCrates
        }

        updateState({ ...thisComponentState, isSaving: true })

        fetch("/Crates/SaveAsDraft", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isSaving: false })

            });

    }

    const onSelect = (chosenDraft) =>{

        console.log(chosenDraft);

        //updateState({
        //    ...thisComponentState,
        //    washedCrates: chosenDraft.washedCrates,
        //    agentId: chosenDraft.agentId,
        //    washedDate: chosenDraft.washedDate.split("T")[0],
        //    showDrafts:false
        //});

        updateState(prevState => ({
            ...prevState,
            washedCrates: chosenDraft.washedCrates,
            agentId: chosenDraft.agentId,
            washedDate: chosenDraft.washedDate.split("T")[0],
            showDrafts: false
        }));


    }



    

    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }

    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        loading...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.showDrafts) {
        return (         
           <WashedCratesDrafts onSelect={onSelect} back={() => updateState({ ...thisComponentState, showDrafts: false })} />
        )
    }
    if (thisComponentState.showWashStatus)
    {
        return (<WashedStatus back={() => updateState({ ...thisComponentState, showWashStatus: false })} />);

    }
    if (thisComponentState.showCrateStatus) {

        return (<CratesStatus back={() => updateState({ ...thisComponentState, showCrateStatus: false })} />);

    }


    if (thisComponentState.toPay) {

        return (<ToPayCharges back={() => updateState({...thisComponentState,toPay:false}) }/> )

    }

    if (thisComponentState.editWashes) {
        return (<EditWashedHistory back={() => updateState({ ...thisComponentState, editWashes: false })} />)
    }

    if (thisComponentState.toCharge) {

        return (<AgentsToCharge back = {() => updateState({...thisComponentState,toCharge:false})}/>)
    }


    if (thisComponentState.editCrate) {

        return (<EditCrate back={() => updateState({ ...thisComponentState, editCrate: false })} />)
    }

 
    if (thisComponentState.showStatus) {

        return (<StatusType back={() => updateState({...thisComponentState,showStatus:false})} washStatus={() => updateState({ ...thisComponentState, showWashStatus: true, showStatus: false })}  crateStatus={() => updateState({ ...thisComponentState,showCrateStatus: true, showStatus: false })}  />)

    }
    if (thisComponentState.showScheduler) {

        return (<CycleCounts back={()=> updateState({...thisComponentState,showScheduler:false})}  />)

    }

    if (thisComponentState.showWashedList) {

        return (<WashedList back={() => updateState({ ...thisComponentState, showWashedList: false })} />)
    }

    if (thisComponentState.showMasterfile) {

        return (<CrateInventory back={() => updateState({ ...thisComponentState, showMasterfile:false })}/>)

    }


    if (thisComponentState.showPendingWashes) {

        return (<PendingWashes back={() => updateState({ ...thisComponentState, showPendingWashes: false })} />)

    }


    return (   
             
        <div>

            <div className="row">

                <div className="col">

                    {
                        thisComponentState.toEnterWashes ? <FontAwesomeIcon icon={faMinus} title="Enter washes" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, toEnterWashes: !thisComponentState.toEnterWashes })} /> : <FontAwesomeIcon icon={faPlus} title="Enter washes" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, toEnterWashes: !thisComponentState.toEnterWashes })} />

                    }

                </div>


                <div className="col">
                    <FontAwesomeIcon icon={faClock} title="List of schedules" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, showScheduler: true })} />
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faSchool} title="Show wash status" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showStatus: true })} />
                </div>

                <div className="col">  
                    <FontAwesomeIcon icon={faHandsWash} title="Edit washes" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, editWashes: true })} />                    
                </div>
                                 
                <div className="col">
                    <FontAwesomeIcon icon={faChargingStation} title="Charge agent" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateState({ ...thisComponentState, toCharge: true })}/>
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faMoneyBill} title="Collect payment" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, toPay: true })} />
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faList} title="List of washes" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="purple" onClick={() => updateState({ ...thisComponentState, showWashedList: true })}/>
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faClock} title="Pending Washes" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="purple" onClick={() => updateState({ ...thisComponentState,showPendingWashes: true })} />
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faDraftingCompass} title="List of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="brown" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
                </div>

                <div className="col">
                    <FontAwesomeIcon icon={faFileArchive} title="Masterfile" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="gray" onClick={() => updateState({ ...thisComponentState, showMasterfile: true })}/>
                </div>
            </div>

            <hr/>

            {
                thisComponentState.toEnterWashes && <div>


                    <div className="row">
                        <div className="col">
                            <div> Washed Date </div>
                        </div>
                        <div className="col">
                            <input type="date" value={thisComponentState.washedDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, washedDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div> Agent </div>
                        </div>
                        <div className="col">
                            <StandardAutoComplete value={thisComponentState.agentId} url="/Agents/GetAgents" updateValue={updateValue} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div> Serial Number </div>
                        </div>
                        <div className="col">
                            <CrateWashedAutoComplete mountCounter={thisComponentState.agentId} url={thisComponentState.crateUrl} updateValue={add} onChangeCallBack={clear} washedCrates={thisComponentState.washedCrates} />
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <input value="Save" className="form-control" type="button" onClick={save} />
                                </div>

                                <div className="col">
                                    <input value="Draft" className="form-control" type="button" onClick={draftIt} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <br />
                    <br />



                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <td>
                                            Action
                                        </td>

                                        <td>
                                            Serial Number
                                        </td>

                                    </tr>

                                </thead>

                                <tbody>

                                    {
                                        typeof thisComponentState.washedCrates !== 'undefined' && thisComponentState.washedCrates.map(crateId =>

                                            <tr>
                                                <td> <input type='button' value='Delete' className="form-control" onClick={() => deleteCrate(crateId)} />  </td> <td>  <div> {getSerialNumber(crateId)} </div>   </td>
                                            </tr>)
                                    }

                                </tbody>

                            </table>

                        </div>
                    </div>


                </div>
            
            }

           

        
        </div>
   
  )

}