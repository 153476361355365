import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";





export default function OpenPrFilter (props)
{
    const [thisComponentState, updateState] = useState({

        requestorId: "",
        branchId: "",
        departmentId: "",
        itemCode: "",
        requestDate: "",
        requiredDate: "",
        docId: "",
 

    })

    console.log(props);

    const updateRequestorId = (code) => {
        updateState({ ...thisComponentState, requestorId: code });
    }

    const updateDepartmentId = (code) => {

        updateState({ ...thisComponentState, branchId: code });
    }

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState, branchId: code });
    }

    const updateItemCode = (code) => {

        updateState({ ...thisComponentState, itemCode: code });
    }

    const back =() => {

        let criterias = {};

        if (thisComponentState.requestorId !== "") {
            criterias.requestorId = thisComponentState.requestorId;
        };

        if (thisComponentState.branchId !== "") {

            criterias.branchId = thisComponentState.branchId;

        };

        if (thisComponentState.departmentId !== "") {

            criterias.departmentId = thisComponentState.departmentId;

        };

        if (thisComponentState.itemCode !== "") {

            criterias.itemCode = thisComponentState.itemCode;

        };

        if (thisComponentState.requestDate !== "") {

            criterias.requestDate = thisComponentState.requestDate;

        };

        if (thisComponentState.requiredDate !== "")
        {
            criterias.requiredDate = thisComponentState.requiredDate;
        };

        if (thisComponentState.docId !== "") {

            criterias.docId =parseInt(thisComponentState.docId);
        };


        props.generate(criterias);
    }


    return (
        <div>
            <br/>
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("500px", "500px")}>



                    <div className="row">
                        <div className="col">
                            Filter By PR Number
                        </div>

                        <div className="col">
                            <input type="number" value={thisComponentState.docId} className="form-control" onChange={(e) => updateState({ ...thisComponentState, docId: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Requestor
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Employees/GetEmployees" value={thisComponentState.requestorId} clear={() => updateState({ ...thisComponentState, requestorId: "" })} updateValue={updateRequestorId} placeholder="Employee Search" />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Branch
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} clear={() => updateState({ ...thisComponentState, branchId: "" })} updateValue={updateBranchId} placeholder="Branch Search" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Department
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Departments/GetDepartments" value={thisComponentState.departmentId} clear={() => updateState({ ...thisComponentState, departmentId: "" })} updateValue={updateDepartmentId} placeholder="Department Search" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Filter By Item
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Inventories/GetInventories" value={thisComponentState.departmentId} clear={() => updateState({ ...thisComponentState,itemCode: "" })} updateValue={updateItemCode} placeholder="Item Search" />
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            Filter By Request Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.requestDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requestDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Required Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.requiredDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">

                        </div>
                        <div className="col">
                            <input value="Back" type="button" className="form-control" onClick={back} />
                        </div>
                    </div>

                </div>
            </div>
            </div>

       

        
   )
}