
import { useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";

export default function AssignedUom(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        ugpEntry: "",
        uomEntry:"",
        altQty: "",
        baseQty:""
    });

    const save = () => {

        let confirm = window.confirm("Make sure your data are correct. Please click ok to continue saving.")

        if (!confirm)
        {
            return;
        }

        if (thisComponentState.ugpEntry === "") {

            return alert("Code is required.");
        };

      
        if (thisComponentState.uomEntry === "") {

            return alert("Name is required.");
        };
        if (thisComponentState.altQty ===0 || thisComponentState.baseQty===0) {

            return alert("Base or alt quantity cannot be zero.");
        };
      


        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Inventories/AssignedUom", {
            // Adding method type
            method: "POST",
            // Adding body or contents to send
            body: JSON.stringify(thisComponentState),
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);
            });
    }


    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </button>
                </div>
            </div>
        </div>)
    }

    const updateUgpEntry = (code) => {

        updateState({ ...thisComponentState, ugpEntry: parseInt(code) });

    }

    const updateUomEntry = (code) => {

        updateState({ ...thisComponentState, uomEntry:parseInt(code)});

    }
    return (

        <div>

            <hr />

            <div className="row">
                <div className="col">
                </div>
                <div className="col">
                    <input type="button" value="Back" onChange={()=>props.back()} className="form-control" />
                </div>
                <div className="col">
                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col">

                    <div className="row">

                        <div className="col">
                            <div title="Choose prefix to generate the value of this field">
                                Uom Group
                            </div>
                        </div>

                        <div className="col">

                            <StandardAutoComplete value={thisComponentState.ugpEntry} clear={() => updateState({...thisComponentState,ugpEntry:""}) } url="/Inventories/GetUgpEntries" updateValue={updateUgpEntry} />
                      

                        </div>

                    </div>


                    <div className="row">

                        <div className="col">
                            <div>
                              Uom Entry
                            </div>
                        </div>

                        <div className="col">

                            <StandardAutoComplete value={thisComponentState.uomEntry} clear={() => updateState({ ...thisComponentState, uomEntry: "" })} url="/Inventories/GetUomEntries" updateValue={updateUomEntry} />


                        </div>

                    </div>

                    <div className="row">

                        <div className="col">
                            <div>
                                Alt Qty
                            </div>
                        </div>

                        <div className="col">
                            <input type="number" value={thisComponentState.altQty} onChange={(e) => updateState({ ...thisComponentState, altQty: parseFloat (e.currentTarget.value) })} className="form-control" />

                        </div>

                    </div>

                    <div className="row">

                        <div className="col">
                            <div>
                                Base Qty
                            </div>
                        </div>

                        <div className="col">
                            <input type="number" value={thisComponentState.baseQty} onChange={(e) => updateState({ ...thisComponentState, baseQty:parseFloat(e.currentTarget.value) })} className="form-control" />

                        </div>

                    </div>


                </div>

            </div>

            <hr />

            <div className="row">
                <div className="col" >

                    <input type="button" value="Save" onClick={save} className="form-control" />

                </div>
            </div>

       
        </div>
    )

}
