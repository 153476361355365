import { useState } from "react";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";





export default function (props)
{
    const [thisComponentState, updateState] = useState({

        documentId:"",
        requestorId: "",
        departmentId: "",
        requestDate: "",
        requiredDate:""
    })

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, requestorId: code });
    }

    const updateDepartmentId = (code) => {

        updateState({ ...thisComponentState, departmentId: code });

    }

    const updateBranchId = (code) => {

        updateState({ ...thisComponentState,branchId: code });

    }

    const back =() => {

        let criterias = {};


        if (thisComponentState.documentId !== "") {
            criterias.documentId = thisComponentState.documentId;
        };

        if (thisComponentState.requestorId !== "") {
            criterias.requestorId = thisComponentState.requestorId;
        };

        if (thisComponentState.departmentId !== "") {
            criterias.departmentId = thisComponentState.departmentId;
        };

        if (thisComponentState.requestDate !== "") {
            criterias.requestDate = thisComponentState.requestDate;
        };

        if (thisComponentState.requiredDate !== "") {
            criterias.requiredDate = thisComponentState.requiredDate;
        };

        if (thisComponentState.branchId !== "") {
            criterias.branchId = thisComponentState.branchId;
        };

        props.filter(criterias);

    }


    return (
        <div>
            <br/>
            <div style={getModalStyle()}>

                <div style={getModalContentStyle("500px", "500px")}>

                    <div className="row">
                        <div className="col">
                            Filter By Document Id
                        </div>
                        <div className="col">
                            <input type="text" value={thisComponentState.documentId} className="form-control" onChange={(e) => updateState({ ...thisComponentState, documentId: e.currentTarget.value })} />
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            Filter By Requestor
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Employees/GetEmployees" value={thisComponentState.requestorId} clear={() => updateState({ ...thisComponentState, requestorId: "" })} updateValue={updateEmployeeId} placeholder="Employee Search" />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Department
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Departments/GetDepartments" value={thisComponentState.departmentId} clear={() => updateState({ ...thisComponentState, departmentId: "" })} updateValue={updateDepartmentId} placeholder="Department Search" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            Filter By Branch
                        </div>

                        <div className="col">
                            <StandardAutoComplete key={200} url="/Branches/GetBranches" value={thisComponentState.branchId} clear={() => updateState({ ...thisComponentState,branchId: "" })} updateValue={updateBranchId} placeholder="Branch Search" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Request Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.requestDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requestDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Filter By Required Date
                        </div>

                        <div className="col">
                            <input type="date" value={thisComponentState.requiredDate} className="form-control" onChange={(e) => updateState({ ...thisComponentState, requiredDate: e.currentTarget.value })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">

                        </div>

                        <div className="col">
                            <input value="Back" type="button" className="form-control" onClick={back} />
                        </div>
                    </div>

                </div>
            </div>
            </div>

       

        
   )
}