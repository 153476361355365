
import { faBackward, faInfoCircle, faListAlt, faPlus, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import PureInput from '../../PureInput';
import PureTextArea from '../../PureTextAreas';
import CategorySelection from './CategorySelection';
import NotGood from './NotGood';

export default function Released(props) {

    const [thisComponentState, updateState] = useState({
        productionDate: "",
        details: [],
        isSaving: false,
        saved: false,
        area: '0',
        showNotGood: false,
        descriptions: [],
        currentlySelectedItem: '',
        currentlySelectedName:''

    });

    useEffect(() =>
    {
        setDescriptions();

    },[]);

    const [selectedCategories, updateSelectedCategories] = useState([]);

    const [showCategorySelection, updateShowCategorySelection] = useState(false);

    const getProductionPlanned = () => {

        var productionDate = thisComponentState.productionDate;

        if (productionDate === "" || productionDate === undefined) {

            return alert("Please defined a date.");

        };

        if (selectedCategories.length === 0) {

            return alert("Please select atleast one category.");
        }

        updateState({ ...thisComponentState, details: [] });
     
        fetch("/Productions/GetProductionPlannedToRelease?" + new URLSearchParams({

            productionDate: productionDate,
            pCategories: selectedCategories.join(":")

        }))

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.length !== 0) {

                    updateState({ ...thisComponentState, details: responseData });

                    return;

                } else {

                    return alert("No data available.");
                }
            });
    }



    const setDescriptions = () => {

        fetch("/Productions/GetDescriptions")

            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(responseData => {

                if (responseData.length !== 0) {

                    updateState({ ...thisComponentState, descriptions: responseData });

                    return;

                } else {

                    return alert("Unable to get factors for not good items.");
                }
            });
    };

    const saved = () => {

        var pConfirm = window.confirm("Are you sure you want to update entire production?");

        if (!pConfirm) {

            return;
        }

        let modifiedDetails = [];

        thisComponentState.details.forEach(category => {

            category.details.forEach(detail => {

                var newDetail =
                {
                    productionDate: thisComponentState.productionDate,
                    guidId:detail.guidId,
                    itemCode : detail.itemCode,
                    remarks: detail.remarks,
                    good: detail.good,
                    reject: detail.reject,
                    bargain: detail.bargain,
                    pollBoss: detail.pollBoss,
                    doughCount: detail.doughCount
                }

                modifiedDetails.push(newDetail);

            });
        });

        updateState({ ...thisComponentState, isSaving: true });

        fetch("/Productions/UpdateProductionPlanned", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(modifiedDetails),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(json => {

                var tempData = [...thisComponentState.details];

                tempData.forEach(category => {

                    category.details.forEach(detail=>{

                        let detailFromDb = json.filter(x => x.guidId === detail.guidId);

                        for (var property in detailFromDb) {

                            detail[property] = detailFromDb[property];
                        }
                        
                    });
                });

                updateState({ ...thisComponentState, details: tempData , isSaving:false,saved:true});

            });
    }



    const updateValue = (code, name, value) => {

        let tempData = [...thisComponentState.details];

        let finalValue = "";

        if (name !== "remarks") {

            finalValue = parseFloat(value);

        } else {

            finalValue = value;

        }
        
    
        tempData.forEach(category => {

            category.details.forEach(data => {

                if (data.itemCode === code) {

                    data[name] = finalValue

                    return;
                }

            })

        });

        updateState({ ...thisComponentState, details: tempData })

    }

 

    const saving = () => {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

       
    }


    const updatePerItem = (code) => {


        let good = 0;
        let reject = 0;
        let bargain = 0;

        thisComponentState.details.forEach(data => {

            var item = data.details.find(x => x.itemCode == code);

            if (typeof item !== 'undefined' && item !==null) {

                good = item.good;
                reject = item.reject;
                bargain = item.bargain;

            };

        });


        var postBody = {

            date: thisComponentState.productionDate,
            itemCode: code,
            good: good,
            reject: reject,
            bargain: bargain

        };

        fetch("/Productions/UpdatePerItem",
            {
                method: "post",
                body: JSON.stringify(postBody),
                headers: {
                    'content-type': 'application/json; charset:UTF-8'
                }
            })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                alert(response.message);

            });

    }


    if (showCategorySelection) {
        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>

                <CategorySelection selectedCategories={selectedCategories} updateSelectedCategories={updateSelectedCategories} updateShowCategorySelection={updateShowCategorySelection} />

            </div>
        </div>)

    }

    const back = () => {

        updateState({ ...thisComponentState, showNotGood: false });

    }


    if (thisComponentState.showNotGood) {

        return (<NotGood goBack={back}  descriptions={thisComponentState.descriptions} date={thisComponentState.productionDate} itemCode={thisComponentState.currentlySelectedItem} name={thisComponentState.currentlySelectedName } />)
    }


    return (

        <div>

            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <div className="row">
                        <div className="col">
                            <input type="date" className="form-control" value={thisComponentState.productionDate } onChange={(e) => updateState({ ...thisComponentState, productionDate: e.currentTarget.value })} style={{ width: "150px"}} />
                        </div>

                        <div className="col">
                            <FontAwesomeIcon icon={faListAlt} title="Select category"  size="2x" color="black" onClick={() => updateShowCategorySelection(true)}/>
                        </div>

                        <div className="col">
                            <FontAwesomeIcon icon={faInfoCircle} title="Generate data"  size="2x" color="black" onClick={getProductionPlanned}/>
                        </div>

                        <div className="col">
                            <FontAwesomeIcon icon={faBackward} title="Go Back" size="2x" color="black" onClick={() => props.updateState({ ...props.thisComponentState, releasedProductionPlanned: false })} />
                        </div>

                        <div className="col">
                            <FontAwesomeIcon icon={faPlus} title="Update" size="2x" color="black" onClick={() => saved()} />
                         </div>

                        {
                            thisComponentState.isSaving ? saving() : ""
                        }
                    </div>

                    <br />
                    <br />

                    <table className="table table-bordered">

                        <thead className="thead-dark">
  
                            <th style={{ position: 'sticky', top: 0 }}>
                                Description
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                Yield Per mix
                            </th>
                            <th style={{ position: 'sticky', top: 0 }}>
                                Standard Mix
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                Proposed Mix
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                Planned
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                For Production
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                Good
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                Reject
                            </th>

                            <th style={{ position: 'sticky', top: 0 }}>
                                Bargain
                            </th>
                            <th style={{ position: 'sticky', top: 0 }}>
                                PollBoss
                            </th>
                            <th style={{ position: 'sticky', top: 0 }}>
                                Dough Count

                            </th>
                            <th style={{ position: 'sticky', top: 0 }}>
                                Remarks
                            </th>

                            <th colSpan="2" style = {{ position: 'sticky', top: 0 }}>
                                  Action
                            </th>

                        </thead>

                        <tbody>


                            {
                                thisComponentState.details.map(category =>

                                    <Fragment>

                                        <tr>
                                            <td>
                                                {category.category}
                                            </td>

                                        </tr>

                                        {category.details.map((detail, trIndex) =>

                                            <tr>                  
                                                <td style={{marginLeft:'20px', position: 'sticky', left: 0,whiteSpace :'nowrap' }}> {detail.name}       </td>
                                                <td> {detail.yieldPerMix}  </td>
                                                <td> {detail.standardMix.toFixed(2)}  </td>
                                                <td> {detail.proposedMix.toFixed(2)}  </td>
                                                <td> {detail.plannedQty}   </td>
                                                <td> {detail.forProduction}   </td>
                                                <td> {thisComponentState.saved ? detail.good : <PureInput style={{ width: "150px" }} value={detail.good} type="number" category={category.category} code={detail.itemCode} name="good" updateValue={updateValue} />} </td>
                                                <td> {thisComponentState.saved ? detail.reject : <PureInput style={{ width: "150px" }} value={detail.reject} type="number" category={category.category} code={detail.itemCode} name="reject" updateValue={updateValue} />} </td>
                                                <td> {thisComponentState.saved ? detail.bargain : <PureInput style={{ width: "150px" }} value={detail.bargain} type="number" category={category.category} code={detail.itemCode} name="bargain" updateValue={updateValue} />} </td>
                                                <td> {thisComponentState.saved ? detail.pollBoss : <PureInput style={{ width: "150px" }} value={detail.pollBoss} type="number" category={category.category} code={detail.itemCode} name="pollBoss" updateValue={updateValue} />} </td>
                                                <td> {thisComponentState.saved ? detail.doughCount : <PureInput style={{ width: "150px" }} value={detail.doughCount} type="number" category={category.category} code={detail.itemCode} name="doughCount" updateValue={updateValue} />} </td>
                                                <td> {thisComponentState.saved ? detail.remarks : <PureTextArea style={{ width: "200px" }} value={detail.remarks} trIndex={trIndex} code={detail.itemCode} name="remarks" updateValue={updateValue} />}</td>
                                                <td>
                                                    <input value="NG" style={{width:'100px'}} type="button" title="Click here to defined not good items." className="btn btn-success" onClick={() => updateState({...thisComponentState,showNotGood:true,currentlySelectedItem:detail.itemCode,currentlySelectedName:detail.name })} />
                                                </td>

                                                <td>
                                                    <input value="Add" style={{ width: '100px' }} type="button" tooltip="Adds to existing production" className="btn btn-success"  onClick={()=> updatePerItem(detail.itemCode)} />
                                                </td>

                                            </tr>

                                        )}

                                    </Fragment>

                                )

                            }


                        </tbody>

                        <tfoot>

                            <tr>

                            </tr>

                            <tr>
                                <td>


                                </td>

                                <td>
                                   
                                </td>

                                <td>
                                   
                                </td>


                            </tr>


                        </tfoot>

                    </table>

                </div>
            </div>
        </div>
    )

}

