import { faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react"
import CreateInventory from "./CreateInventory";


export default function ItemRequestList(props) {

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false,
        selectedGuidId:"",
        details:[]

    });

    useEffect(() => {

         fetch("/StockRequisitions/GetItemRequests")

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({...thisComponentState,details:response});

            });

    }, [])

    const deleteItem = (guidId) => {

        fetch("/StockRequisitions/DeleteItemRequest", {
            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempDatas = [...thisComponentState.details];

                    tempDatas = tempDatas.filter(u => u.guidId !== guidId);

                    updateState({...thisComponentState,details:tempDatas});

                    return alert(response.message);

                } else {

                    return alert(response.message);
                }
            });
    }



    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Deleting...
                    </button>
                </div>
            </div>
        </div>)
    }

   

    return (

            <div>
                <div>

                <div>

                    <input type="button" className="form-control" value="Back" onClick={()=> props.back()}/>

                </div>

                <hr/>

                <div >
                    <table className="table table-bordered">

                        <thead>
                            <th colSpan="2">Action</th>
                            
                            <th>Request Id</th>   
                            <th>Item Name</th>


                        </thead>

                        <tbody>

                            {
                                thisComponentState.details.length > 0 && thisComponentState.details.map(val =>
                                    <tr>
                                        <td>
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: 'pointer' }} size="2x" color="violet" onClick={()=>props.finalize(val.guidId)} />
                                        </td>

                                        <td>
                                            <FontAwesomeIcon icon={faTrash}  style={{ cursor: 'pointer' }} size="2x" color="violet" onClick={()=>deleteItem(val.guidId)} />

                                        </td>

                                        <td>
                                            {
                                                val.id
                                            }
                                        </td>

                                        <td>
                                            {
                                                val.name
                                            }
                                        </td>
                                    </tr>
                                )

                            }

                        </tbody>
                    </table>
                </div>

                 


                </div>
            </div>
        
        )
}