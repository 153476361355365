import { useState } from "react"
import StandardAutoComplete from "../../StandardAutocomplete";
import ListCashAdvances from "./ListOfCashAdvances";
import SignaturePad from "./SignaturePad";




export default function CashAdvance(props) {

    const [thisComponentState, updateState] = useState({
        isPerformingOperation: false,
        employeeId: "",
        amount: 0,
        purpose: "",
        type: "",
        methodOfReleased:"",
        bimonthlyPayment:0,
        goToList: false,
        toSave: false
    });

    const updateEmployeeId = (code) => {

        updateState({ ...thisComponentState, employeeId: code });

    }

    function showNotification() {
        // Create notification element
        var notification = document.createElement('div');
        notification.textContent = 'This is a notification!';

        // Dynamically attach styles
        notification.style.position = 'fixed';
        notification.style.bottom = '20px'; // Adjust as needed
        notification.style.right = '20px'; // Adjust as needed
        notification.style.backgroundColor = '#333';
        notification.style.color = '#fff';
        notification.style.padding = '15px';
        notification.style.borderRadius = '5px';
        notification.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.3)';
        notification.style.zIndex = '9999';

        // Append notification to the body
        document.body.appendChild(notification);

        // Remove notification after a delay (e.g., 5 seconds)
        setTimeout(function () {
            notification.remove();
        }, 5000);
    }


    const add = (image) => {

        let postBody = {
            employeeId: thisComponentState.employeeId,
            amount: parseFloat(thisComponentState.amount),
            purpose: thisComponentState.purpose,
            type: thisComponentState.type,
            methodOfReleased:thisComponentState.methodOfReleased,
            bimonthlyPayment: parseFloat(thisComponentState.bimonthlyPayment),
            signature:image
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/CashAdvances/Create", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({...thisComponentState,isPerformingOperation:false})

                return alert(response.message);

            });
    }




    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Performing operation...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.goToList)
    {
        return (<ListCashAdvances back={() => updateState({...thisComponentState,goToList:false})}/>)
    }

    if (thisComponentState.toSave) {

        if (thisComponentState.amount === 0 || thisComponentState.employeeId === "" || thisComponentState.purpose === "" || thisComponentState.methodOfReleased === "") {

            alert("Please fill required fields.");

            updateState({ ...thisComponentState, toSave: false });

            return;
        }


        return (

                <SignaturePad back={() => updateState({...thisComponentState,toSave:false})} add={add}/>
            
         )
    }
    


    return (

        <div>


            <div className="row">
                <div className="col">
                    <input value="Back" onClick={()=>props.back()} className="form-control" type="button" />
                </div>
                <div className="col">
                    <input value="List" onClick={() => updateState({...thisComponentState,goToList:true})} className="form-control" type="button" />
                </div>
            </div>

            <hr/>

            <div className="row">

                <div className="col">
                    Type
                </div>

                <div className="col">

                    <select className="form-control" onChange={(e) => updateState({ ...thisComponentState, type: e.currentTarget.value })} value={thisComponentState.type} >

                        <option value="">Please select Type</option>
                        <option value="Business">Business</option>
                        <option value="Personal">Personal</option>

                    </select>

                </div>

            </div>


            <div className="row">

                <div className="col" style={{ verticalAlign: 'middle' }}>
                    Employee
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.employeeId} clear={() => updateState({ ...thisComponentState, employeeId: "" })} url="/Employees/GetEmployees" updateValue={updateEmployeeId} />
                </div>

            </div>
 
            <div className="row">

                <div className="col" style={{verticalAlign:'middle'}}>
                   Amount
                </div>

                <div className="col">
                    <input  value={thisComponentState.amount} className="form-control" type="number" onChange={(e) => updateState({ ...thisComponentState, amount: e.currentTarget.value })} />
                </div>
            </div>

            {
                thisComponentState.type === "Personal" &&

                <div className="row">
                    <div className="col" style={{ verticalAlign: 'middle' }}>
                        Bimonthy Deduction
                    </div>

                    <div className="col">
                        <input value={thisComponentState.bimonthlyPayment} className="form-control" type="number" onChange={(e) => updateState({ ...thisComponentState,bimonthlyPayment: e.currentTarget.value })} />
                    </div>
                </div>

            }

            <div className="row">
                <div className="col" style={{ verticalAlign: 'middle' }}>
                    Purpose
                </div>
                <div className="col">

                    <textarea type="number" value={thisComponentState.purpose} className="form-control" type="input" onChange={(e) => updateState({ ...thisComponentState, purpose: e.currentTarget.value })}>

                    </textarea>

                </div>
            </div>

            <div className="row">

                <div className="col" style={{ verticalAlign: 'middle' }}>
                    Thru 
                </div>

                <div className="col">

                    <select className="form-control" onChange={(e) => updateState({ ...thisComponentState, methodOfReleased: e.currentTarget.value })} value={thisComponentState.methodOfReleased} >

                        <option value="">Please Select Released Method</option>
                        <option value="Revolving Fund">Revolving Fund</option>
                        <option value="ATM">ATM</option>

                    </select>

                </div>
            </div>


            <hr/>

            <div className="row">
                <div className="col">
              
                </div>
                <div className="col">
                    <input type="button" value="Save" className="form-control" onClick={() => updateState({...thisComponentState,toSave:true})} />
                </div>
            </div>

        </div>


    )
}