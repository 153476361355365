export default function getModalStyle(zIndex) {

    let modal = {

        display: "block", /* Hidden by default */
        position: "fixed", /* Stay in place */
        zIndex: typeof (zIndex) === 'undefined' || zIndex == null ? 1 : zIndex, 
        left: 0,
        top: 0,
        width: "100%", /* Full width */
        height: "100%", /* Full height */
        overflow: "auto" /* Enable scroll if needed */

    }

    return modal;
}