import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import StandardAutoComplete from "../../StandardAutocomplete";

export default function AssignedAuditors(props)

{

    const [thisComponentState, updateState] = useState({

        userId: "",
        selectedUsers:[],
        users: []
    });

    useEffect(() => {
        
        fetch("/Identity/GetAllUsers").then(response => response === 401 ? window.location = "/login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, users: response });
                
            });

    }, []);

    const updateValue = (code) => {

        let user = thisComponentState.users.find(val => val.userId === code);

        let tempData = [...thisComponentState.selectedUsers];

        tempData.push({ userId: code, userName: user.userName });

        updateState({ ...thisComponentState, selectedUsers: tempData });

    }


    const save = () => {

        var isConfirm = window.confirm("Are you sure you?");

        if (!isConfirm) {

            return;
        };

        let postBody = thisComponentState.selectedUsers.map(x => x.userId);

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PurchaseRequisitions/AssignedAuditors", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                value:postBody
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {

                    return response.json();
                }
            })

            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }


    return (

         <div className="row">
            <div className="col">

                <div className="row">
                    <div className="col">
                        <input value="Back" className="form-control" type="button" onClick={()=>props.back()} />
                      
                    </div>
                </div>

                <br/>

                <div className="row">
                    <div className="col">

                        <StandardAutoComplete value={thisComponentState.userId} url="/Identity/GetUsersForAutocomplete" updateValue={updateValue} />
               
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col">
                        {
                            thisComponentState.selectedUsers.map(val => <div>{val.userName}</div>)                           
                        }
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col">
                        <input value="Save" className="form-control" type="button" onClick={save}/>
                    </div>
                </div>

            </div>
        </div>
        
        
   );
}