

export default function searchUomName(detailProperty,metaData,updateDetailValue) {

    if (metaData === undefined) {

        return 0;

    };

    let inventoryGuidId = detailProperty["code"];

    console.log("Inventory guid is ", inventoryGuidId)
    console.log("data is", metaData)

    if (inventoryGuidId !== undefined) {

        let inventory = metaData.inventories.find(x => x.inventoryGuidId === inventoryGuidId);

        console.log("Inventory is ", inventory);

        if (inventory !== undefined) {

            let uomValues = inventory.uomValues.find(x => x.uomEntry ===inventory.defaultUom);

            console.log("List price is ",uomValues);


            if (uomValues !== undefined) {

                return uomValues.uomName;

            };

            return 0;
        }

        return 0;

    }
    return 0;


}