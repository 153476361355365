import { faComments, faFileExcel, faHand,  faLink,  faRedo, faStopCircle, faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {  useContext, useEffect, useState } from 'react';
import exportToCSV from '../../Functions/exportToCSV';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { toCsvString } from '../../Functions/toCsvString';
import OnHandQty from '../../OnHandQty';
import { TransactionContext } from '../TransactionContext';
import MyPivotTable from './PivotPr';
import RelationshipMap from './RelationshipMap';

export default function DetailsPurchaseRequisitionNavigator(props) {

    const {data,guidId,updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        showRelationshipMap: false,
        isPerformingOperation: false,
        toAudit: false,
        toPivot:false,
        auditorsNote:""
    })

    useEffect(() => {

        localStorage.setItem("purchaseRequisitionGuidId", guidId);

    }, []);


   
    const close = () => {

        let confirm = window.confirm("Are you sure want to close this?");

        if (!confirm) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PurchaseRequisitions/Close", {
            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempData = { ...data.metaData };

                    tempData.parentProperties.docStatus = "Closed";
                    tempData.reloadDetails = tempData.reloadDetails + 1;

                    updateMetaData({ metaData: tempData });
                }

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false })

            });
    }

    const audit = () => {

        let confirm = window.confirm("Are you sure with your audit note this?");

        if (!confirm) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PurchaseRequisitions/Audit", {
            method: "post",
            body: JSON.stringify({
                value: {
                    item1: guidId,
                    item2: thisComponentState.auditorsNote
                }
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempData = { ...data.metaData };

                    tempData.reloadDetails = tempData.reloadDetails + 1;

                    updateMetaData({ metaData: tempData });
                }

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false })

            });
    }


    const deletePurchaseRequistion = () => {

        let confirm = window.confirm("Are you sure want to delete this?.This will open related stock requisitions.");

        if (!confirm) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/PurchaseRequisitions/CancelByCookie", {
            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempData = { ...data.metaData };

                    tempData.parentProperties.docStatus = "Deleted";
                    tempData.reloadDetails = tempData.reloadDetails + 1;

                    updateMetaData({ metaData: tempData });
                }

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false })

            });
    }

    if (thisComponentState.showRelationshipMap)
    {
        return (<RelationshipMap guidId={guidId} back={() => updateState({...thisComponentState,showRelationshipMap:false})} />)
    }

    if (thisComponentState.showOnHand) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <OnHandQty
                        back={() => updateState({ ...thisComponentState, showOnHand: false })}
                    />
                </div>
            </div>
        )
    }


    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailsFromDb !== 'undefined' && typeof data.metaData.detailsFromDb.details !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailsFromDb.details);

            exportToCSV("data", csvString);
        }
    }

    if (thisComponentState.toAudit) {

        return (

                <div style={getModalStyle()}>
                    <div style={getModalContentStyle()}>
                    <div className="row">
                        <div className="col">

                        </div>
                        <div className="col">
                            <input value="Back" className="form-control" type="button" onClick={() => updateState({...thisComponentState,toAudit:false})}/>
                        </div>
                        <div className="col">

                        </div>

                    </div>

                    <br/>

                        <div className="row">
                            <div className="col">
                            <textarea style={{minHeight:"500px"}} className="form-control" onChange={(e) => updateState({...thisComponentState,auditorsNote:e.currentTarget.value})}>

                                </textarea>
                            </div>
                        </div>

                    <br/>

                    <div className="row">
                        <div className="col">
                           
                        </div>
                        <div className="col">
                            <input value="Save" className="form-control" type="button" onClick={audit}/>
                        </div>
                        <div className="col">

                        </div>

                    </div>

                    </div>
                </div>    
            )
    }


    if (thisComponentState.toPivot) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <MyPivotTable/>

                </div>
            </div>
            )
    }

    
    return (
            <div style={{ float: "left" }}>
                <FontAwesomeIcon icon={faRedo} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />                          
                <FontAwesomeIcon icon={faHand} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({...thisComponentState,showOnHand:true })} />
                <FontAwesomeIcon icon={faTrash} title="Delete" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={deletePurchaseRequistion} />
                <FontAwesomeIcon icon={faStopCircle} title="Close" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="black" onClick={close} />
                <FontAwesomeIcon icon={faLink} title="Relationship Map" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showRelationshipMap: true, })} />
                <FontAwesomeIcon icon={faComments} title="Audit" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState,toAudit: true, })} />
                <FontAwesomeIcon icon={faTable} title="pivot" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, toPivot: true, })} />

                <FontAwesomeIcon icon={faFileExcel} title="Download to excel" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

            </div> 
    )
}

  