import React from 'react'
import { useEffect } from 'react';

import { useState } from "react";

import StandardAutoComplete from '../../StandardAutocomplete';
import AddCrate from './AddCrate';
import EditCrate from './EditCrate';


export default function CrateInventory(props)
{
    const [thisComponentState, updateState] = useState({

        agentId: "",
        crateId: "",
        serialNumber:"",
        data: [],
        toEdit: false,
        toAdd: false,
        toReload:0

    });


    useEffect(() => {

        fetch("/Crates/GetCratesPerAgent?" + new URLSearchParams({

            agentId: thisComponentState.agentId

        })).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {
                updateState({ ...thisComponentState, data: response });
            });

    }, [thisComponentState.agentId,thisComponentState.toReload]);



    const  deleteCrate = (id)=> {

        let postBody = {
            value: parseInt(id)
        };

        fetch("/Crates/DeleteCrate", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {
                    return alert("Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isPerformingOperation: false });
                }

                return alert(response.message);

            });





    }

    const updateValue = (agentId) => {

        updateState({ ...thisComponentState, agentId:agentId });
    }


    if (thisComponentState.toEdit) {
        return (<EditCrate crateId={thisComponentState.crateId} serialNumber={thisComponentState.serialNumber } back={() => updateState({...thisComponentState,toEdit:false,toReload: thisComponentState.toReload+1 })}/>)
    }
    

    if (thisComponentState.toAdd)
    {
        return (<AddCrate  back={() => updateState({ ...thisComponentState, toAdd: false,toReload:thisComponentState.toReload+1 })} />)
    }


    return (

        <div>

            <div className="row">
                <div className="col">
                    <input value="Back" type="button" className="form-control" onClick={()=> props.back()}/>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <span>Agent </span>
                </div>
                <div className="col">
                    <StandardAutoComplete value={thisComponentState.agentId} url="/Agents/GetAgents" updateValue={updateValue} />  
                </div>
            </div>

            <hr/>
            <div className="row">
                <div className="col">
                    <input value="Add" type="button" className="form-control" onClick={() => updateState({...thisComponentState,toAdd:true})} />
                </div>
                <div className="col">
                   
                </div>
                <div className="col">

                </div>
                <div className="col">

                </div>
            </div>



            <hr/>

            <div className="row">
                <div className="col">
                    <table className='table table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <td colSpan="2"> Action </td>
                                <td> Serial number </td>
                                <td> Status </td>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.data.map(crate =>
                                    <tr>
                                        <td>
                                            <input type='button' className="form-control" value='Delete' onClick={() => deleteCrate(crate.id)} />
                                        </td>

                                        <td>
                                            <input type='button' className="form-control" value='Edit' onClick={() => updateState({ ...thisComponentState, crateId: crate.id, serialNumber: crate.serialNumber, toEdit: true })} />
                                        </td>


                                        <td>  <div>  {crate.serialNumber}  </div>  </td>

                                        <td>  <div>  {crate.isActive ? "Active" : "Inactive"}  </div>  </td>

                                    </tr>)
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
   )
}
