import { faTemperatureHalf } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import StandardAutoComplete from "../../StandardAutocomplete";
import ListOfDieselRequest from "./ListOfDieselRequest";


export default function DieselRequest() {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        isPerformingOperation: false,
        code:"",
        requestDate: "",
        requiredDate: "",
        branchId :"",
        dieselDetailModels: [],
        listOfDieselRequest:[],
        showListOfDieselRequest: false,
        toRetrieve: 0,
        filterParams: {}
    });


    useEffect(() => {


        fetch("/StockRequisitions/GetDieselModels?"+new URLSearchParams(thisComponentState.filterParams))
           .then(response => response.status === 401 ? window.location = "/login" : response.json())
           .then(response => {

               updateState({ ...thisComponentState, dieselDetailModels: response, isLoading: false });

            })
    }, [thisComponentState.toRetrieve]);

    const save = () => {

        if (thisComponentState.code === "") {

            return alert("Requestor is Required.");
        };

        if (thisComponentState.branchId === "") {

            return alert("Branch is Required.");
        };

        if (thisComponentState.requestDate === "") {

            return alert("Request Date is required.");
        };

        if (thisComponentState.requiredDate === "") {

            return alert("Required Date is required.");
        };

        let toPost = thisComponentState;

        toPost.dieselDetailModels = thisComponentState.dieselDetailModels.filter(val => val.quantity !== 0);

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/StockRequisitions/SaveDieselRequest", {
            method: "post",
            body: JSON.stringify(toPost),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({

                        ...thisComponentState,
                        listOfDieselRequest: response.listOfDieselRequest,                        
                        isPerformingOperation: false,
                        showListOfDieselRequest: true
                    })

                    return alert(response.message);

                } else {

                    updateState({ ...thisComponentState, isPerformingOperation: false });

                    return alert(response.message);
                }
            });

    }


    const onChange = (plateNumber,value) => {

        let tempDatas = [...thisComponentState.dieselDetailModels];

        let tempData = tempDatas.find(val => val.plateNumber === plateNumber);

        tempData.quantity = parseFloat(value);

        updateState({ ...thisComponentState, dieselDetailModels: tempDatas });

    }

    const updateCode = (value) => {

        updateState({ ...thisComponentState, code: value });

    }

    const updateBranchId = (value) => {

        updateState({ ...thisComponentState, branchId: value });

    }

    const updateOilType = (plateNumber, value) => {

        let tempDatas = [...thisComponentState.dieselDetailModels];

        let tempData = tempDatas.find(val => val.plateNumber === plateNumber);

        tempData.description = value;

        updateState({ ...thisComponentState, dieselDetailModels: tempDatas });

    }

    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>
                </div>
            </div>
        </div>)
    }

    const filter = () => {

        if (thisComponentState.requiredDate === "") {

            return alert("Please define a required date.")
        };

        updateState({ ...thisComponentState, filterParams: { requiredDate: thisComponentState.requiredDate},toRetrieve:thisComponentState.toRetrieve+1})

    }



    if (thisComponentState.showListOfDieselRequest) {


        return (<ListOfDieselRequest back={() => updateState({ ...thisComponentState, showListOfDieselRequest: false })} values={thisComponentState.listOfDieselRequest}/>)
    }

    return (

        <div>

            <div className="row">
                <div className="col">
                    <input type="button" value="Save" className="form-control" onClick={save} />
                </div>
                <div className="col">
                    <input type="button" value="List" className="form-control" onClick={() => updateState({...thisComponentState,showListOfDieselRequest:true})} />
                </div>
                <div className="col">
                    <input type="button" value="Filter" className="form-control" onClick={filter} />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    Requestor
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.code} url="/Employees/GetEmployees" updateValue={updateCode} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Request Date
                </div>

                <div className="col">
                    <input value={thisComponentState.requestDate} className="form-control" type="date" onChange={(e) => updateState({...thisComponentState,requestDate:e.currentTarget.value}) }  />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Required Date
                </div>

                <div className="col">
                    <input value={thisComponentState.requiredDate} className="form-control" type="date" onChange={(e) => updateState({ ...thisComponentState,requiredDate: e.currentTarget.value })}  />
                </div>
            </div>


            <div className="row">
                <div className="col">
                    Branch
                </div>

                <div className="col">
                    <StandardAutoComplete value={thisComponentState.branchId} url="/Branches/GetBranches" updateValue={updateBranchId} />
                </div>
            </div>


            <hr/>

            <div className="row">
                <div className="col">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                thisComponentState.dieselDetailModels.map(val =>
                                    <tr>
                                        <td>
                                            {
                                                val.name
                                            }
                                        </td>

                                        <td>
                                           
                                            <select className="form-control" value={val.description} onChange={(e)=>updateOilType(val.plateNumber,e.currentTarget.value)}>

                                                <option value="DIESEL"> Diesel </option>
                                                <option value="UNLEADED"> Unleaded</option>
                                                <option value="PREMIUM"> Premium</option>

                                            </select>

                                        </td>

                                        <td>
                                            <input type="number" className="form-control" value={val.quantity} onChange={(e) => onChange(val.plateNumber, e.currentTarget.value)} />
                                        </td>

                                    </tr>
                                )

                            }

                        </tbody>
                    </table>
                </div>
            </div>
           
        </div>
        
        )
}