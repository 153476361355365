
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DieselRequestDetails from './DieselRequestDetails';

export default function CreatePoForDieselRequest(props)
{
    const [thisComponentState, updateState] = useState({

        isLoading: false,
        isPerformingOperation: false,
        requiredDate: "",
        purchaseRequisitions: [],
        purchaseRequisition: {},
        showDetails:false

    });


    useEffect(() => {

        let params = {};

        if (thisComponentState.requiredDate !== "") {

            params.requiredDate = thisComponentState.requiredDate;

        }

        fetch("/PurchaseOrders/GetDieselRequestForPo?" + new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({

                    ...thisComponentState,
                    purchaseRequisitions: response,
                    isLoading: false

                });

            });
    }, [thisComponentState.requiredDate]);



    const print = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/Crates/PrintWashedCrates?washedHistoryId=";

        fetch(url + props.washedHistoryId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {

                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }



    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }



    const showDetails = (val) => {

        updateState({ ...thisComponentState, purchaseRequisition: val, showDetails: true });

    }

    if (thisComponentState.showDetails) {

        return (<DieselRequestDetails value={thisComponentState.purchaseRequisition} back={() => updateState({...thisComponentState,showDetails:false })}/>)

    }
    return (

        <div>

            <div>
                <div className="row">
                    <div className="col">
                        <input type='button' className="form-control" value="Back" onClick={() => props.back()} />
                    </div>
                    <div className="col">
                        <input type='button' className="form-control" value="Print" onClick={print} />
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col">
                        <div> Required Date</div>
                    </div>
                    <div className="col">
                        <input value={thisComponentState.requiredDate} onChange={(e) => updateState({ ...thisComponentState,requiredDate: e.currentTarget.value })} type="date" className="form-control" />
                    </div>
                </div>

                <hr/> 

                <div className="row">


                    <div className="col">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th> Action </th>
                                    <th> Requestor </th>
                                    <th> Request Date </th>
                                    <th> Required Date </th>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                   thisComponentState.purchaseRequisitions.map(val =>
                                        <tr>
                                           <td>

                                               <input value="Details" type="button" className="form-control" onClick={(e)=> showDetails(val)}/>

                                           </td>
                                            <td> {val.requestor}</td>
                                            <td> {val.prequestDate}</td>
                                            <td> {val.prequiredDate}</td>

                                        </tr>)
                                }

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>

    )
}