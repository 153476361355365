import { useState } from "react"



export default function NameDimension(props) {

    const [thisComponentState, updateState] = useState(props.nameDimension);


    return (
        <div>
            <div className="row">
                <div className="col">
                </div>
                <div className="col">
                    <input value="Back" className="form-control text-center" onClick={() => props.set(thisComponentState)} />
                </div>
                <div className="col">
                </div>
            </div>

            <hr/>


            <div className="row">

                <div className="col">
                    Name
                </div>

                <div className="col">
                    <input className="form-control" disabled value={thisComponentState.getFullName()} />
                </div>
            </div>

            <br/>

            <div className="row">

                <div className="col">
                    Description
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.description} onChange={(e) => updateState({...thisComponentState,description:e.currentTarget.value})} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                    Brand
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.brand} onChange={(e) => updateState({ ...thisComponentState, brand: e.currentTarget.value })} />
                </div>
            </div>

            <br/>
            <div className="row">
                <div className="col">
                    Shape
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.shape} onChange={(e) => updateState({ ...thisComponentState, shape: e.currentTarget.value })} />
                </div>
            </div>

            <br /> 
            <div className="row">
                <div className="col">
                    Color
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.color} onChange={(e) => updateState({ ...thisComponentState, color: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                    Length
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.length} onChange={(e) => updateState({ ...thisComponentState, length: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                    Width
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.width} onChange={(e) => updateState({ ...thisComponentState, width: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                    Height
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.height} onChange={(e) => updateState({ ...thisComponentState, height: e.currentTarget.value })} />
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                    Diameter
                </div>

                <div className="col">
                    <input className="form-control" value={thisComponentState.diameter} onChange={(e) => updateState({ ...thisComponentState, diameter: e.currentTarget.value })} />
                </div>
            </div>


        </div>

    )

}