import { faForward, faPlus, faRedo, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { TransactionContext } from '../TransactionContext';


export default function DetailsBadOrderNavigator(props) {

    const { guidId } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({

        isPerformingOperation: false

    });
    

    const cancel = () => {

        var toDelete = window.confirm("Are you sure...You want to cancel this?");

        if (!toDelete) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/BreadOuts/Cancel", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }


    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/BadOrders/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }
   
            return (

                    <div style={{ float: "left" }}>
 
                        <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
                        <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={forward} />

                    </div>
        
            )
    
}


