
import React, { Fragment, useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import getPropertyValueInAnArrayBasedOnName from '../../Functions/getPropertyValueInAnArrayBasedOnName';
import getValueInArray from '../../Functions/getValueInArray';
import SimpleModal from '../../Modal';
import { TransactionContext } from '../TransactionContext';


export default function ReviewPrice(props) {

   
    const { data } = useContext(TransactionContext);

    const [thisComponentState,updateState] = useState({info:[],isLoading:true});


    useEffect(() => {

        LoadData("/PurchaseOrders/ReviewPrice");

    }, []);

    async function LoadData(url) {

        var parameters = [];

        data.metaData.detailProperties.map(x => {

            parameters.push(x.code);

        });


        parameters = parameters.join(":");

        const response = await fetch(url + "?" + new URLSearchParams({

            items: parameters

        }));

        const dataFromServer = await response.json();

        if (dataFromServer.length != 0) {

            updateState(prevState => ({

                ...prevState,
                info: dataFromServer,
                isLoading:false

            }));
        }

    };

    var counter = 1;

    if (thisComponentState.isLoading) {

        return <div>Loading...</div>

    } else {

        return (

            <div style={getModalStyle()}>

                <div style={getModalContentStyle()}>

                    <table>

                        <thead>


                        </thead>  

                        <tbody>


                        </tbody>


                    </table>

                </div>

            </div>
        )

    }   
}

