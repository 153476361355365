import React, {Fragment, useEffect, useState } from 'react';
import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';

import CreateStockTransferNavigator from './CreateStockTransferNavigator';
import DetailsStockTransferNavigator from './DetailsStockTransferNavigator';
import StockTransferListNavigator from './StockTransferListNavigator';



export default function StockTransferForm(props) {

    const transactionCode = "InventoryTransfer";
    const postUrl = "/StockTransfers/Create";
    const detailsUrl = "/StockTransfers/Details?id=";
    const editDetailUrl = "/StockTransfers/EditItem";
    const listUrl = "/StockTransfers/Index";
    const validatorUrl = "/StockTransfers/Validate";

    const [data, updateMetaData] = useState({ metaData: {} });

    const [isLoaded, updateStatus] = useState(false);

    var context = {

        categories: ['100', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112', '113', '114', '115', '116', '117', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132'],
        type: "ST"
    };

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<StockTransferListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsStockTransferNavigator/>} />);

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, []);


    const additionalDetail = (<td> <input type="button" className="btn btn-danger" style={{ width: "100px" }} value="LPP"  /> </td>);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider validatorUrl={validatorUrl} transactionCode={transactionCode} context={context} metaData={data.metaData} postUrl={postUrl} detailsUrl={detailsUrl} listUrl={listUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList}>

                <TransactionForm  createNavigator={<CreateStockTransferNavigator  previousTransactionGuidId={props.location.previousTransactionGuidId} />} detailsNavigator={<DetailsStockTransferNavigator/>} transactionList={transactionList} additionalDetail={additionalDetail } />

            </TransactionContextProvider>

        )
    }

}

