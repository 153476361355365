import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"

export default function (props)
{

    const [thisComponentState, updateState] = useState({

        isLoading: true,
        values: [],
        showChargedCrates: false,
        chargedCrates :[],

    });

   
    useEffect(() => {

        fetch("/Crates/GetCratesStatus") 

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    isLoading: false,
                    values : response
                });

            });
    }, []);


    const showChargedCrates = (agentId) => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/Crates/GetChargedCratesPerAgent?" + new URLSearchParams({

            agentId:agentId

        }))

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({
                    isLoading: false,
                    showChargedCrates:true,
                    chargedCrates: response,
                    values: thisComponentState.values
                });

            });
    }




    if (thisComponentState.showChargedCrates)
    {

        return (
            <div >

                <div className="row">
                    <div className="col">

                        <input value="Back" type="button" onClick={() => updateState({ ...thisComponentState, showChargedCrates: false })} className="form-control" />

                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">

                            <thead>

                                <tr>
                                    <th>Crate Id</th>
                                    <th>Serial Number</th>
                                    <th>Is Paid</th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    thisComponentState.chargedCrates.map(val =>

                                        <tr>
                                            <td>{val.crateId}</td>
                                            <td>{val.serialNumber}</td>
                                            <td>{val.isPaid ? "Y" : "N"}</td>

                                        </tr>
                                    )
                                }

                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
                  
            )

    }


    return (

        <div >

            <div className="row">
                <div className="col">

                    <input value="Back" type="button" onClick={()=>props.back()} className="form-control" />

                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <table className="table table-bordered">

                        <thead>

                            <tr>
                                <th>Action</th>
                                <th>Agent</th>
                                <th>Issued</th>
                                <th>Missing</th>
                                <th>Charged</th>
                                <th>Paid</th>
                            </tr>

                        </thead>

                        <tbody>

                            {
                                thisComponentState.values.map(val =>

                                    <tr>
                                        <td>
                                                
                                            <FontAwesomeIcon icon={faInfoCircle} title="Details" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => showChargedCrates(val.agentId)}/>
                                         
                                        </td>
                                        <td>{val.agent}</td>
                                        <td>{val.issued}</td>
                                        <td>{val.missing}</td>
                                        <td>
                                            {val.charged}
                                        </td>
                                        <td>{val.paid}</td>

                                    </tr>
                                )
                            }

                        </tbody>

                    </table>

                </div>
            </div>
        </div>

          

            
     )
}

