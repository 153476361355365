import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CratesToCharge from './CratesToCharge';
import CratesToPay from './CratesToPay';


export default function ToPayCharges(props) {

    const [thisComponentState, updateState] = useState({

        isLoading:true,
        washedHistoryId:0,
        values: [],
        agentId:"",
        toPay: false

    });

    useEffect(() => {


        fetch("/Crates/GetAgentsThatHasToPay")

            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {
        
                updateState({...thisComponentState,values:response,isLoading:false})
             
            });
    }, []);

    if (thisComponentState.toPay) {
        return (

            <CratesToPay washedHistoryId={thisComponentState.washedHistoryId} agentId={thisComponentState.agentId} back={() => updateState({ ...thisComponentState, toPay: false })} />
       )
    };

    if (thisComponentState.isLoading) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (

        <div>
            <div className="row">
                <div className="col">
                    <input value="Back" type="button" className="form-control" onClick={()=> props.back()}/>
                </div>
            </div>
       
            <br />

            <div className="row">
                <div className="col-sm-12">
                    <table className="table table-bordered">

                        <thead className="thead-dark">
                            <tr>
                                <td>Action</td>
                                <td>Washed Id</td>
                                <td>Name</td>
                                <td>Washed Date</td>                             
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.values.map(data =>
                                 
                                    <tr>
                                        <td>
                                            <input value="Details" type="button" className="form-control" onClick={() => updateState({...thisComponentState,washedHistoryId:data.id,agentId:data.agentId, toPay:true})}/>
                                        </td>

                                        <td> {data.id}  </td>
                                        <td> {data.name} </td>
                                        <td> {data.washedDate} </td>
                                     
                                    </tr>
                                )
                            }

                        </tbody>                    
                    </table>
                </div>
            </div>
        </div>        
 )
}

