import React, { useEffect, useState } from 'react';
import createParentProperties from '../Functions/createParentProperties';
import MasterfileContextProvider from './MasterfileContextProvider';
import MasterfileForm from './MasterfileForm';


export default function Inventories() {





    return (


        <div>

            <div className="row">

                <div className="col">

                </div>

                <div className="col">

                </div>
            </div>


        </div>  
      
   )
}


