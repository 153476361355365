import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionContext } from '../TransactionContext';
import ViewOnHand from './ViewOnHand';


function DetailsOrderNavigator(props) {

    const { guidId } = useContext(TransactionContext);


    const [thisComponentState, updateState] = useState({ viewOnHand: false });


    if (thisComponentState.viewOnHand) {

        return <ViewOnHand guidId={guidId}/>

    };


            return (

                    <div style={{ float: "left" }}>
           
                            <input type="button" className="btn btn-success" style={{width:"120px"}} onClick={()=>window.location.reload()} value="Create New"/>

                            <input type="button" className="btn btn-success" style={{ width: "120px",marginLeft:"15px"}} onClick={() => window.location.reload()} value="Analysis" />

                            <input type="button" className="btn btn-success" style={{ width: "120px", marginLeft: "15px" }} onClick={() => updateState({...thisComponentState,viewOnHand:true})} value="On Hand" />

                            {/*<input type="button" className="btn btn-success" style={{ width: "120px", marginLeft: "15px" }} onClick={() => updateState({ ...thisComponentState, viewOnHand: true })} value="Approve" />*/}

                            <Link to={{ pathname: "/Production", previousTransactionGuidId: guidId }}>
                                <button style={{ marginLeft: "15px", marginRight: "15px", width: "120px" }} type="button" className="btn btn-success">
                                    Manufacture
                                </button>
                            </Link>


                    </div>
        
            )
    
}


export default DetailsOrderNavigator;