import React, {  useState } from 'react';

export default function StandardInput(props) {
   
    const [value, updateValue] = useState(props.value===null ? '' : props.value);

    const [id] = useState(props.id);
    
    const onChange = (e) => {

        if (props.htmlType === "date") {

            var curr = new Date(e.currentTarget.value);

            var date = curr.toISOString().substr(0, 10);

            updateValue(date);

        } else {

            let noCommaValue = e.currentTarget.value.replace(/,/g, "");

            updateValue(noCommaValue);
        }
    }

    const onBlur = (e) => {
    
        if (props.isParent) {

            if (typeof props.updateValue === 'function') {

                props.updateValue(e.currentTarget.name, value)
            }
        } else {

            if (typeof props.updateValue === 'function') {

                props.updateValue(props.index, props.name, value);

            }
        }

        if (typeof props.focus === "function") {

              props.focus();
        }

      
    }

    const getWidth = () => {

        return typeof props.width !== 'undefined' && props.width !== null ? props.width : "150px";
    }

    if (props.htmlType !== "checkbox" && props.htmlType !== "textarea" ) {

        return (

            <input
                key={id} id={id}
                type={props.htmlType}
                name={props.name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                style={{ minWidth: getWidth() }}
                className="form-control"
            />
        )

    } else if (props.htmlType === "textarea") {
        
        return (
            <textarea key={id} name={props.name} value={value} onChange={onChange} onBlur={onblur} className="form-control" ></textarea>
        )
    }

    else {

        return (
            <input key={id}  type="checkbox" checked={value} name={props.name} onChange={()=> updateValue(!value) } onBlur={onBlur} style={{marginLeft:"20px" }} className="form-control" />
        )
    }
}

