import React, { Fragment, useEffect, useState } from 'react';

import setMetaData from '../../Functions/setMetaData';

import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import TransactionList from '../TransactionList';
import CreateReturnNavigator from './CreateReturnNavigator';
import DetailsReturnNavigator from './DetailsReturnNavigator';
import ReturnListNavigator from './ReturnListNavigator';


export default function ReturnForm() {


    const transactionCode = "InventoryTransfer";
    const postUrl = "/Returns/Create";
    const detailsUrl = "/Returns/Details/?id=";
    const editDetailUrl = "/Returns/EditItem";
    const listUrl = "/Returns/Index";
    const validatorUrl = "/Returns/Validate";

    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    var context = {
        categories: ['100', '107', '108', '109', '110', '129', '132', '138', '139', '140', '141', '142', '143', '144', '145'],
        type: "S",
        formDiscriminator:"Return"
    };

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    }, [])

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<ReturnListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsReturnNavigator/>} />);

    if (!isLoaded)
    {
        return (<div>Loading...</div>);

    } else {
        return (
            <TransactionContextProvider context={context} validatorUrl={validatorUrl } transactionCode={transactionCode} metaData={data.metaData} postUrl={postUrl} listUrl={listUrl} detailsUrl={detailsUrl} editDetailUrl={editDetailUrl} functions={[]} transactionList={transactionList } >
                <TransactionForm createNavigator={<CreateReturnNavigator/>} detailsNavigator={<DetailsReturnNavigator/>} transactionList={transactionList }/>
            </TransactionContextProvider>
        )
    }
}

