import { useState } from "react";
import DefinedTasks from "./DefinedTasks";
import StandardAutoComplete from '../../StandardAutocomplete';
import JobOrderList from "./JobOrderList"
import TakePictureOfFinishedJob from "./TakePictureOfFinishedJob";
import PerProjectManager from "./PerProjectManager";
import UploadFile from "../../../UploadFile";
import getModalStyle from "../../Functions/getModalStyle";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import JobOrderDetails from "./JobOrderDetails";

export default function JobOrder() {

    const [thisComponentState, updateState] = useState({
        projectManager: "",
        deptInCharge: "",    
        requestDate: "",
        expectedDueDate: "",
        beforeImages: [],
        requestedBy: "",
        estimatedDaysToComplete: "",
        generalDescription: "",
        isTakingPicture: false,
        isDefiningTask: false,
        isSaving: false,
        projectUserId: "",
        jobOrderId: "",
        showJobOrderFilter: false,
        showJobOrderList: false,
        takePictureOfFinishedJob:false,  
        isClosing: false,
        completed: false,
        uploadPicture: false,
        files: [],
        showCurrentlySavedJobOrder:false,
        showPerProjectManager:false

       
    })

    const save = () => {

        if (thisComponentState.generalDescription === "") {

            return alert("General Description is required.");
        }

        if (thisComponentState.beforeImages.length > 4) {

            return alert("You can upload up to four images only.");
        }

        if (thisComponentState.requestDate === "") {

            return alert("Request date is required.");
        }

        if (thisComponentState.projectUserId === "") {

            return alert("Project Manager is required.")
        }

        updateState({ ...thisComponentState, isSaving: true });


        let formData = new FormData();

        thisComponentState.files.forEach(val => formData.append("files", val));

        formData.append("requestDate", thisComponentState.requestDate);
        formData.append("expectedDueDate", thisComponentState.expectedDueDate);
        formData.append("deptInCharge", thisComponentState.deptInCharge);
        formData.append("projectUserId", thisComponentState.projectUserId);
        formData.append("generalDescription", thisComponentState.generalDescription);

        if (thisComponentState.beforeImages.length > 0) {

            formData.append("beforeImages", thisComponentState.beforeImages);
        }

        fetch("/StockRequisitions/CreateJobOrder", {

            method: "post",
            body:formData
 
        }).then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                if (response.success) {

                    updateState({ ...thisComponentState, isSaving: false,showCurrentlySavedJobOrder:true,jobOrderId:response.jobOrderId });

                    return alert(response.message);

                } else {

                    updateState({ ...thisComponentState, isSaving: false });

                    return alert(response.message);
                }              
            });
    }


    document.addEventListener("keydown", (e) => {

        if (e.ctrlKey && e.key === "l") {

            e.preventDefault();

            updateState({ ...thisComponentState, showJobOrderFilter: true });
        }
    });


    const initializeVideo = () => {

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

            let video = document.getElementById('beforeImages');

            (function (video) {
                navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: {
                            exact: 'environment'
                        }
                     }}).then(function (stream) {
                    video.srcObject = stream;
                    video.play();
                });

            })(video)

        }
    }


    const capture = () => {

        let video = document.getElementById('beforeImages');

        let canvas = document.createElement("canvas");

        canvas.width = 500;
        canvas.height = 500;

         let context = canvas.getContext('2d');

         context.drawImage(video, 0, 0, 500, 500);

         let base64Value = canvas.toDataURL();

         let tempData = [...thisComponentState.beforeImages];

         tempData.push(base64Value);

         updateState({ ...thisComponentState, beforeImages: tempData });
    }

    //const updateTasks = (taskArray) => {

    //    let tempData = [...thisComponentState.tasksToBeExecuted];

    //    tempData = taskArray;
    //    updateState({...thisComponentState, tasksToBeExecuted: tempData,isDefiningTask:false});
    //}

    const updateProjectUserId = (code) => {
        updateState({ ...thisComponentState, projectUserId: code });
    };


    const clearProjectUserId = () => {
        updateState({ ...thisComponentState, projectUserId:""});
    }
    const updatedeptInCharge = (code) => {

        updateState({ ...thisComponentState,deptInCharge: code });
    };

    const cleardeptInCharge = () => {

        updateState({ ...thisComponentState, deptInCharge: "" });
    }

   
    if (thisComponentState.isDefiningTask) {

        return (<DefinedTasks updateTasks={updateTasks} initialState={thisComponentState.tasksToBeExecuted} />)
    }



    if (thisComponentState.isSaving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }




   


    if (thisComponentState.takePictureOfFinishedJob) {

        return (<TakePictureOfFinishedJob jobOrderId={thisComponentState.jobOrderId} back={() => updateState({...thisComponentState,takePictureOfFinishedJob:false})}/>)
    }
    

    if (thisComponentState.showJobOrderList) {

        return (<JobOrderList back={() => updateState({ ...thisComponentState, showJobOrderList: false })}/>)
    }

    if (thisComponentState.showPerProjectManager) {

        return <PerProjectManager back={() => updateState({...thisComponentState,showPerProjectManager:false })}/>
    }


    const action = (chosenFiles) => {

        updateState({
            ...thisComponentState,
            files: chosenFiles
        });

    }

    if (thisComponentState.uploadFile) {

        return (<UploadFile action={action} isSeparateUpload={false }  back={() => updateState({ ...thisComponentState, uploadFile: false })} />)
    }



    if (thisComponentState.isClosing) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Marking as complete...Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    if (thisComponentState.isTakingPicture) {

        return (

            <div>

                <div>

                    <div>
                        <input onClick={() => updateState({ ...thisComponentState, isTakingPicture: false })} type="button" className="form-control" value="Back" />
                    </div>

                    <div className="row">
                        <div className="col">
                            <video id="beforeImages" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div>


                    <div>
                        <input onClick={initializeVideo} type="button" className="form-control" value="Initialize" />
                    </div>

                    <div>
                        <input onClick={capture} type="button" className="form-control" value="Add" />
                    </div>

                    <div>
                        <input onClick={() => updateState({ ...thisComponentState, tasksToBeExecuted: [] })} type="button" className="form-control" value="Clear" />
                    </div>

                    <br />

                    <div className="row">

                        <div className="col text-center" >

                            {thisComponentState.beforeImages.map((imageBase, index) => <img key={index} src={imageBase} alt="Trulli" width="500" height="500" />)}

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    if (thisComponentState.showCurrentlySavedJobOrder) {

        return (

            <div style={getModalStyle() }>
                <div style={getModalContentStyle() }>
                    <JobOrderDetails jobOrderId={thisComponentState.jobOrderId} back={() => updateState({...thisComponentState,showCurrentlySavedJobOrder :false})}  />
                </div>
            </div>
       )
    }



    return (

        <div>
             <hr/>

                <div className="row"> 
                     <div className="col">
                        <input type="button" className="form-control" value="Find" onClick={() => updateState({...thisComponentState,showJobOrderList:true})} />
                     </div>
                    <div className="col">
                        <input type="button" className="form-control" value="Filtered List" onClick={() => updateState({ ...thisComponentState, showPerProjectManager: true })} />
                    </div>
                </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div >
                        Project Manager
                    </div>
                </div>

                <div className="col">
                    <StandardAutoComplete key={200} url="/StockRequisitions/GetUsersForAutocomplete" value={thisComponentState.projectUserId} clear={clearProjectUserId} updateValue={updateProjectUserId} placeholder="Employee Search" />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="col">
                    <div >
                        Department In Charge
                    </div>
                </div>
                <div className="col">
                    <StandardAutoComplete key={200} url="/Departments/GetDepartments" value={thisComponentState.deptInCharge} clear={cleardeptInCharge} updateValue={updatedeptInCharge} placeholder="Deparment Search" />
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div >
                        Request Date
                    </div>
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.requestDate } onChange={(e) => updateState({ ...thisComponentState, requestDate: e.currentTarget.value })}  className="form-control" />
                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div >
                        Expected Due Date
                    </div>
                </div>

                <div className="col">
                    <input type="date" value={thisComponentState.expectedDueDate } onChange={(e) => updateState({ ...thisComponentState, expectedDueDate: e.currentTarget.value })} className="form-control" />
                </div>
            </div>

           <hr/>

            <div className="row">

                <div className="col">
                    <div >
                        Before State
                    </div>
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            <input type="button" onClick={() => updateState({ ...thisComponentState, isTakingPicture: true })} value="Take Picture" className="form-control bg-info" />
                        </div>

                        <div className="col">
                            <input type="button" onClick={() => updateState({ ...thisComponentState, uploadFile: true })} value="Upload Picture" className="form-control bg-info" />
                        </div>
                    </div>  
                </div>

            </div>

            <hr/>

            <div className="row">
                <div className="col">
                    <div >
                        General Description
                    </div>
                </div>

                <div className="col">
                    <input type="text" value={thisComponentState.generalDescription} onChange={(e) => updateState({ ...thisComponentState, generalDescription: e.currentTarget.value })} className="form-control" />
                </div>
            </div>

            <br/>

            <div className="row">
                <div className="col">
                   
                </div>

                <div className="col">

                    <div className="row">
                        <div className="col">

                        </div>

                        <div className="col">
                            <input type="button" className="form-control" value="Save" onClick={save} />
                        </div>

                        <div className="col">

                        </div>
                    </div>

                </div>
          
            </div>
        </div>
    )
}