import { faFileExcel, faForward, faListAlt, faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import App from '../../../App';
import exportToCSV from '../../Functions/exportToCSV';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { toCsvString } from '../../Functions/toCsvString';
import { TransactionContext } from '../TransactionContext';
import InstallationProcess from './InstallationProcess';


export default function DetailsGoodsIssueNavigator(props) {


    const {data, guidId } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        send: false,
        isEmailed: false,
        isCancelling: false,
        showInstallationTransactions: false
    });


    if (thisComponentState.isCancelling) {

        return (<div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const preview = () => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        let url = "/GoodsIssues/Print?id=";

        fetch(url + guidId,
            {
                method: "GET",
                headers: {
                    "Accept": "application/pdf"
                },
            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.blob())
            .then(blob => {


                var file = window.URL.createObjectURL(blob);

                window.open(file);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });
    }


    const forward = () => {

        var yes = window.confirm("Are you sure...You want to tranfers this to SAP server?");

        if (!yes) {

            return;
        }

        updateState({ ...thisComponentState, isPerformingOperation: true });

        var dataToPost = {

            value: guidId
        };

        fetch("/GoodsIssues/forward", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, isPerformingOperation: false });

                return alert(response.message);

            });
    }

    if (thisComponentState.showInstallationTransactions) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle() }>
                    <InstallationProcess back={() => updateState({ ...thisComponentState, showInstallationTransactions: false })} />
                </div>
            </div>
        )
    }



    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailsFromDb !== 'undefined' && typeof data.metaData.detailsFromDb.details !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailsFromDb.details);

            exportToCSV("data", csvString);
        }
    }




    return (

        <div style={{ float: "left" }}>

            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />

            <FontAwesomeIcon icon={faForward} title="Forward to SAP" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={forward} />

            <FontAwesomeIcon icon={faPrint} title="To PDF" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="brown" onClick={preview} />

            <FontAwesomeIcon icon={faListAlt} title="List of Installation process" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="brown" onClick={() => updateState({ ...thisComponentState, showInstallationTransactions: true })} />

            <FontAwesomeIcon icon={faFileExcel} title="Download to excel" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

        </div>

    )
}
