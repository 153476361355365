import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import uuid from 'react-uuid';
import { TransactionContext } from './Transactions/TransactionContext';


export default function OnHandQty(props)
{
    const { data } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        retrieving:true,
        wareHouses: [],
        onHandInventories: []
    });


    useEffect(() => {

        let tempData = { ...data.metaData};

        if (typeof tempData.detailProperties === 'undefined' ) {

            alert("Please defined inventories.");

            props.back();
        }

        let codes = tempData.detailProperties.map(x => x.code);

        if (codes.length === 0) {

            alert("Please defined inventories.");

            props.back();
         
        };

        if (typeof tempData.parentProperties.date === 'undefined' || tempData.parentProperties.date === "" || tempData.parentProperties.date === null)
        {
            alert("Please define a date.");

            props.back();
        };

        var dataToPost = {

            Value: {
                item1: tempData.parentProperties.date,
                item2: codes.join(",")
            }
        }

        
        fetch("Generics/GetOnHandQty",
            {
                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(dataToPost),

                // Adding headers to the request
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }

            })
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState,wareHouses:response.wareHouses,onHandInventories:response.onHandInventories,retrieving: false })

            });

    }, []);

    const getWareHouseQty = (warehouseQtys, wareHouse) => {

        var wareHouseQty = warehouseQtys.find(x => x.wareHouse === wareHouse);

        if (typeof wareHouseQty !== 'undefined') {

            return wareHouseQty.quantity.toLocaleString() + " " + wareHouseQty.uom;

        } else
        {
            return 0;
        }
    }

    if (thisComponentState.retrieving) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Retrieving...
                    </button>
                </div>
            </div>
        </div>)
    }

    return (
     
            <div>

                <div>

                    <div style={{ float: "left" }}>
                        <input value="Back" onClick={props.back} className="btn btn-primary" style={{ width: "150px" }} type="button" />
                    </div>
                </div>

                <br />
                <br />

                <table className="table table-bordered">

                    <thead className="thead-dark">

                        <th style={{ position: 'sticky', top: 0 }}>

                            ItemCode

                        </th>  <th style={{ position: 'sticky', top: 0 }}>
                            ItemName
                        </th>

                        {
                            thisComponentState.wareHouses.map(warehouse =>
                                <th key={uuid} style={{ position: 'sticky', top: 0 }}>
                                    {
                                        warehouse
                                    }
                                </th>
                            )
                        }

                    </thead>

                    <tbody>

                        {
                            thisComponentState.onHandInventories.map(detail =>

                                <tr key={uuid}>

                                    <td> {detail.itemCode} </td>
                                    <td> {detail.itemName} </td>

                                    {
                                       thisComponentState.wareHouses.map(warehouse =>

                                            <td key={uuid}>{getWareHouseQty(detail.details, warehouse)}</td>
                                        )
                                    }

                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>  
    )
}