import { faArrowLeft, faArrowRight, faFilter, faListAlt, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import getModalContentStyle from "../../Functions/getModalContentStyle";
import getModalStyle from "../../Functions/getModalStyle";
import StandardAutoComplete from "../../StandardAutocomplete";
import MultiplePrDetailsForPoReview from "./MulitplePrDetailsForPoReview";
import OpenPrFilter from "./OpenPrFilter";
import PrDetailsForPoReview from "./PrDetailsForPoReview";

export default function AssignTask(props) {

    const [thisComponentState, updateState] = useState({

        details: [],
        pickItems: [],
        showDetails: false,
        selectedGuidId: null,
        isLoading:false,
        isPerformingOperation: false,
        retrieve: 0,
        multipleGuidIds: [],
        showSummary: false,
        originalValues: [],
        filterDetails: false,
        showReassignButton: false,
        userId: "",
        counter :1

    });

    useEffect(() => {
        //Check if can assign

        fetch("/PurchaseOrders/CanAssign")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (!response.success) {

                    props.back();

                    return alert("You are not authorized to assign task.");
                }

            });

        getUnAssignedPr();

    }, []);



    const getUnAssignedPr = () => {

        updateState({ ...thisComponentState, isLoading: true });

        fetch("/PurchaseOrders/GetUnAssignedPr?")
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.originalValues = response;
                tempData.userId = "";

                updateState(tempData);

            });
    }

    const generate = (params) => {


        updateState({ ...thisComponentState, isLoading: true });

        fetch("/PurchaseOrders/GetListOfOpenPR?" + new URLSearchParams(params))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isLoading = false;
                tempData.filterDetails = false;

                updateState(tempData);

            });
    }

    const pick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = true;

        updateState({ ...thisComponentState, details: items });

    }

    const unPick = (guidId) => {

        let items = [...thisComponentState.details];

        let item = items.find(val => val.guidId === guidId);

        item.isPick = false;

        updateState({ ...thisComponentState, details: items });
    }


    const showSummary = () => {

        let selectedGuidIds = thisComponentState.details.filter(x => x.isPick).map(y => y.guidId);

        let items = [...thisComponentState.multipleGuidIds];

        items = selectedGuidIds;

        updateState({ ...thisComponentState, multipleGuidIds: items, showSummary: true });
    }


    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }


    if (thisComponentState.showDetails) {

        return (<PrDetailsForPoReview guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })} />)
    }

    const doubleBack = () => {

        updateState({ ...thisComponentState, showSummary: false, retrieve: 1 })

        props.back();

    }

    if (thisComponentState.showSummary) {

        return (<MultiplePrDetailsForPoReview guidIds={thisComponentState.multipleGuidIds} doubleBack={doubleBack} back={() => updateState({ ...thisComponentState, showSummary: false, retrieve: 1 })} />)
    }

    const compareDate = (paramA, paramB) => {

        let paramAYear = paramA.getFullYear();
        let paramAMonth = paramA.getMonth();
        let paramADay = paramA.getDate();

        let paramBYear = paramB.getFullYear();
        let paramBMonth = paramB.getMonth();
        let paramBDay = paramB.getDate();

        return paramAYear === paramBYear && paramAMonth === paramBMonth && paramADay === paramBDay;
    }

   


    const clearFilter = () => {

        let tempDatas = [...thisComponentState.details];

        tempDatas = thisComponentState.originalValues;

        updateState({ ...thisComponentState, details: tempDatas, isFilterApplied: false });

    }

    if (thisComponentState.filterDetails) {

        return (
            <div>

                <OpenPrFilter generate={generate} />

            </div>

        )
    }


    const updateAssignTo = (code) => {

        updateState({ ...thisComponentState, userId: code });

    }



    const assign = () => {

        var isConfirm = window.confirm("Are you sure you want to assign these purchase requisitions?");

        if (!isConfirm) {

            return;
        };

        if (thisComponentState.userId === "") {

            return alert("Please select purchaser.");
        }

        updateState({ ...thisComponentState, isPerformingOperation: true })

        var postBody = {

            value: thisComponentState.details.filter(val => val.isPick).map(val => ({ item1: val.guidId, item2: thisComponentState.userId }))
        }

        fetch("/PurchaseOrders/Assign", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {


                    return alert(" Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (response.success) {

                    getUnAssignedPr();
                };

                updateState({ ...thisComponentState, isPerformingOperation: false })

                return alert(response.message);

            });
    }

    const reassign = (guidId) => {

        var isConfirm = window.confirm("Are you sure you want to reassign these purchase requisitions?");

        if (!isConfirm) {

            return;
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        var postBody = {

            value: guidId
        }

        fetch("/PurchaseOrders/Reassign", {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(postBody),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => {

                if (!response.ok) {

                    return alert(" Something went wrong...Please contact your system administrator.");
                }
                else {
                    return response.json();
                }
            })

            .then(response => {

                if (response.success) {

                    getUnAssignedPr();

                } else {

                    return alert(response.message);
                }
            });
    }


    if (thisComponentState.isPerformingOperation) {
        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)
    }


    const retrieve = () => {

        if (thisComponentState.userId === "") {

            return alert("Please select a purchaser.");
        };

        updateState({ ...thisComponentState, isPerformingOperation: true })

        fetch("/PurchaseOrders/GetListOfOpenPrPerUser?" + new URLSearchParams({

            userId: thisComponentState.userId

        }))
            .then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                let tempData = { ...thisComponentState };

                tempData.details = response;
                tempData.isPerformingOperation = false;
                tempData.originalValues = response;
                tempData.showReassignButton = true;

                updateState(tempData);


            });
    }




    return (

        <div style={getModalStyle()}>
            <div style={getModalContentStyle()}>
                <div className="row">

                    <div className="col">

                        <div style={{ float: 'left' }}>

                            <FontAwesomeIcon icon={faArrowLeft} title="click to go back" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => props.back()} />
                            <FontAwesomeIcon icon={faListAlt} title="show summary" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={showSummary} />
                            <FontAwesomeIcon icon={faFilter} title="show filter criteria" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer', transform: [{ rotateY: '90deg' }] }} size="2x" color="orange" onClick={() => updateState({ ...thisComponentState, filterDetails: true })} />
                            <FontAwesomeIcon icon={faX} title="clear filter" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={clearFilter} />

                        </div>

                    </div>

                    <div className="col">

                        <input className="form-control" type="button" value="Pending" onClick={retrieve} />

                    </div>

                    <div className="col">

                        <StandardAutoComplete key={200} url="/PurchaseOrders/GetPurchasers" value={thisComponentState.userId} clear={() => updateState({ ...thisComponentState, userId: "" })} updateValue={updateAssignTo} placeholder="Purchaser Search" />

                    </div>

                    <div className="col">

                        <input className="form-control" type="button" value="Assign" onClick={assign} />

                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th> Series</th>
                                    <th colSpan="2"> Action</th>
                                    <th> Requisition Date </th>
                                    <th> Required Date </th>
                                    <th> Aged</th>
                                    <th> Doc Id </th>
                                    <th> Requestor </th>
                                    <th> Branch</th>
                                    <th> Remarks </th>

                                </tr>

                            </thead>

                            <tbody>

                                {
                                    typeof thisComponentState.details !== 'undefined' && thisComponentState.details.map(val =>

                                        <tr key={uuid()}>

                                            <td>
                                                {
                                                    thisComponentState.counter++
                                                }
                                            </td>

                                            {
                                                !thisComponentState.showReassignButton && <td><input value={val.isPick ? "UnPick" : "Pick"} className="form-control" type="button" onClick={() => val.isPick ? unPick(val.guidId) : pick(val.guidId)} /></td>
                                            }

                                         
                                            <td>
                                                <input value="Details" className="form-control" type="button" onClick={() => updateState({ ...thisComponentState, selectedGuidId: val.guidId, showDetails: true })} />
                                            </td>

                                            {
                                                thisComponentState.showReassignButton && <td><input value="Reassign" className="form-control" type="button" onClick={() => reassign(val.guidId)} /></td>
                                            }

                                            <td> {val.requestDate}</td>
                                            <td> {val.requiredDate}</td>
                                            <td> {val.aged}</td>
                                            <td> {val.docId}</td>
                                            <td> {val.requestor}</td>
                                            <td> {val.branch}</td>
                                            <td> {val.remarks}</td>

                                        </tr>)
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}


