
import { useState } from "react";



export default function StDetails(props) {

    const [thisComponentState, updateState] = useState({
        data: {
            requestor:props.data.requestor,
            requiredDate:props.data.requiredDate,
            documentId:props.data.docId,
            branch:props.data.branch,
            details: props.data.details
        }
    });


    return (

        <div>

            <div className="row">
                <div className="col">

                    <input type="button" value="Back" className="form-control" onClick={() => props.back()} />

                </div>

                <div className="col">

                </div>
                <div className="col">
                  
                </div>
                <div className="col"></div>

            </div>

            <br />

            <div className="row">
                <div className="col">
                    <div>Requestor</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requestor}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Document Id</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.documentId}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Branch</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.branch}</div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div>Required Date</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.requiredDate}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div>Note</div>
                </div>

                <div className="col">
                    <div> {thisComponentState.data.note}</div>
                </div>
            </div>
            <br />

            <div className="row">

                <div className="col">

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Inv Qty</th>
                                <th>UomDesc</th>
                                <th>Price</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                              typeof thisComponentState.data !=='undefined' && typeof thisComponentState.data.details !=='undefined' &&  thisComponentState.data.details.map(val =>
                                    <tr key={val.code}>
                                        <td>{val.description}</td>
                                        <td>{val.quantity}</td>
                                        <td>{val.invQty}</td>
                                        <td>{val.uomDesc}</td>
                                        <td>{val.price}</td>                      
                                    </tr>
                                )

                            }

                        </tbody>

                    </table>
                </div>
            </div>
        </div>

    )
}