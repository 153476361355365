
import { faBars, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import SrDetails from "./SrDetails";


export default function SrForClosure(props) {

    const [thisComponentState, updateState] = useState({
        isLoading: true,
        isPerformingOperation:false,
        details: [],
        showDetails: false,
        selectedGuidId: null

    });

    useEffect(() => {

        fetch("/StockRequisitions/GetSrForClosure")
            .then(response => response.status === 401 ? window.location = "/Login" : response.json())
            .then(response => {

                updateState({ ...thisComponentState, details: response, isLoading: false });

            })
    }, []);


    const close = (guidId) => {

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/StockRequisitions/Close", {
            method: "post",
            body: JSON.stringify({
                value: guidId
            }),
            headers: {

                'content-type': 'application/json; charset:UTF-8'
            }
        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                if (response.success) {

                    let tempData = [...thisComponentState.details];

                    tempData = tempData.filter(u => u.guidId !== response.guidId);

                    updateState({ ...thisComponentState, details: tempData,isPerformingOperation:false });

                    return alert("Closing Successful...");

                } else {

                    updateState({ ...thisComponentState, details: tempData, isPerformingOperation: false });

                    return alert(response.message);
                }
            });

    }

    if (thisComponentState.isLoading) {

        return (<div>Loading...</div>)
    }

    if (thisComponentState.showDetails) {

        return (<SrDetails guidId={thisComponentState.selectedGuidId} back={() => updateState({ ...thisComponentState, showDetails: false, retrieve: 1 })} />)
    }

    if (thisComponentState.isPerformingOperation) {

        return (<div className="modal-wrapper">
            <div id="open-modal" className={`modal-window ${"open"}`}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Closing...
                    </button>
                </div>
            </div>
        </div>)
    }


    return (

        <div>

            <div className="row">

                <div className="col">

                    <input type="button" value="Back" onClick={() => props.back()} className="form-control" />

                </div>
            </div>

            <br />

            <div className="row">

                <div className="col">
                    <table className="table">

                        <thead>
                            <tr>
                                <th colSpan="2"> Action </th>
                                <th>Document Id</th>
                                <th>Requestor</th>
                                <th>Request Date</th>
                                <th>Required Date</th>
                                <th>DeptName</th>
                                <th>Note</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                thisComponentState.details.map(val =>

                                    <tr>

                                        <td>
                                            <FontAwesomeIcon icon={faLockOpen} title="Close this Sr" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => close(val.guidId)} />
                                        </td>

                                        <td>
                                            <FontAwesomeIcon icon={faBars} title="Show Details" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="red" onClick={() => updateState({ ...thisComponentState, showDetails: true, selectedGuidId: val.guidId })} />
                                        </td>
                                        <td className="align-middle">{val.documentId}</td>
                                        <td className="align-middle">{val.requestor}</td>

                                        <td className="align-middle">{val.requestDate}</td>
                                        <td className="align-middle">{val.requiredDate}</td>
                                        <td className="align-middle">{val.deptName}</td>
                                        <td className="align-middle">{val.note}</td>
                                    </tr>
                                )
                            }

                        </tbody>

                    </table>
                </div>
            </div>
        </div>


    )
}