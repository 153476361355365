import React, { useContext, useEffect } from 'react';
import { TransactionContext } from './TransactionContext';
import AutoCompleteInput from './AutocompleteInputForTransaction';
import isAutocomplete from '../Functions/isAutocomplete';
import getUrl from '../Functions/getUrl';
import { Fragment } from 'react';
import getHtmlType from '../Functions/getHtmlType';
import StandardInput from '../StandardInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import computeGrandTotal from '../Functions/computeGrandTotal';
import uuid from 'react-uuid';
import getWidth from '../Functions/getWidth';



export default function Detail() {

    const { data, updateDetailValue, addRow, deleteRow } = useContext(TransactionContext)

    const focus = () => {
       
        // Scroll to the right
        window.scrollTo(document.body.scrollWidth, 0);

        // After a slight delay to ensure the scroll has completed, scroll to the bottom
        setTimeout(() => {
            window.scrollTo(document.body.scrollWidth, document.body.scrollHeight);
        }, 100); // Adjust delay as necessar
    }

    const createTdInput = (valueObject, trIndex) => {

        let inputId = uuid();

        if (valueObject[0] !== "presentationValue" || valueObject[0] !== "GuidOfDetail" || valueObject[0] !== "guidId" || valueObject[0] !== "GuidOfPrDetail" || valueObject[0] !== "GuidIdOfSrDetail" || valueObject[0] !== "GuidOfPoDetail") {

            if (isAutocomplete(valueObject[0], data.metaData.detailMetaData)) {

                if (data.metaData.context.type === "PR" || data.metaData.context.type === "ST" || data.metaData.context.type === "GI") {
                    return <td key={uuid()}> <AutoCompleteInput width={valueObject[0] === "code" ? "450px" : "250px"} value={valueObject[1]} name={valueObject[0]} isParent={false} url={getUrl(valueObject[0], data.metaData.detailMetaData, valueObject)} index={trIndex} hierachyType="detail" /> </td>

                } else {
                    return <td key={uuid()}> <AutoCompleteInput width={"250px"} value={valueObject[1]} name={valueObject[0]} isParent={false} url={getUrl(valueObject[0], data.metaData.detailMetaData, valueObject)} index={trIndex} hierachyType="detail" /> </td>
                }

            } else {


                return <td key={uuid()}> <StandardInput id={inputId} focus={()=>focus()} width={getWidth(valueObject[0], data.metaData.detailMetaData)} htmlType={getHtmlType(valueObject[0], data.metaData.detailMetaData)} name={valueObject[0]} value={valueObject[1]} index={trIndex} updateValue={updateDetailValue} className="form-control" /> </td>

            }
        }
    }

    const CreateTr = (valueArray, trIndex) => {

        valueArray.id = uuid();

        return (

            <tr key={valueArray.id}>

                <td>
                    <FontAwesomeIcon icon={faTrash} size='2x' color='red' onClick={() => deleteRow(valueArray.id)} style={{ cursor: 'pointer' }} />
                </td>

                {
                    (Object.entries(valueArray)).filter(val => val[0] !== "id")
                        .filter(val => val[0] !== "presentationValue")
                        .filter(val => val[0] !== "guidId")
                        .filter(val => val[0] !== "guidOfPrDetail")
                        .filter(val => val[0] !== "guidIdOfSrDetail")
                        .filter(val => val[0] !== "guidOfPoDetail")
                        .filter(val => val[0] !== "guidOfDetail")
                        .filter(val => val[0] !== "acctCode").map((valueObject, tdIndex) =>

                            <Fragment key={uuid()}>

                                {createTdInput(valueObject, trIndex, tdIndex)}

                            </Fragment>

                        )
                }

                <td>

                    <div>
                        {
                            computeLineTotal(valueArray.quantity, valueArray.price)
                        }
                    </div>
                </td>
            </tr>
        );

    }

    useEffect(() => {


    }, [data.metaData.chosenCategory]);


    const computeLineTotal = (pQty, pPrice) => {

        let qty = parseFloat(pQty);
        let price = parseFloat(pPrice);

        let finalQty = Number.isNaN(qty) ? 0 : qty;
        let finalPrice = Number.isNaN(price) ? 0 : price;

        return finalQty * finalPrice;

    }


    return (

     
            <table className="table table-bordered" style={{width:"100%"}}>

                <thead>
                    <tr>

                        <th ></th>
                        {
                            typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailMetaData !== 'undefined' && data.metaData.detailMetaData.map((data, index) => !data.isHidden &&



                                <th style={{ zIndex: 0 }} key={"head-" + index}> {data.displayName}</th>

                            )
                        }

                        <th>Amount</th>

                    </tr>

                </thead>

                <tbody>

                    {
                        typeof data !== 'undefined' &&
                        typeof data.metaData !== 'undefined' &&
                        typeof data.metaData.detailProperties !== 'undefined' && data.metaData.detailProperties.map((valueArray, trIndex) => valueArray.presentationValue.toShow && CreateTr(valueArray, trIndex))
                    }

                </tbody>

                <tfoot>

                    <tr>

                        <td>
                            <FontAwesomeIcon icon={faPlus} id="addRow" size='2x' color='blue' onClick={addRow} style={{ cursor: 'pointer' }} />
                        </td>

                        {
                            typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailMetaData !== 'undefined' && data.metaData.detailMetaData.map((data, index) => !data.isHidden &&

                                <td key={uuid()}> </td>
                            )
                        }

                        <td> {typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined' ? computeGrandTotal(data.metaData.detailProperties) : 0}</td>

                    </tr>


                    {
                        typeof data.metaData.parentProperties.docDiscount !== 'undefined' &&

                        <tr>

                            <td>
                                Discount
                            </td>

                            {
                                typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailMetaData !== 'undefined' && data.metaData.detailMetaData.map((data, index) => !data.isHidden &&

                                    <td key={uuid()}> </td>
                                )
                            }

                            <td> {data.metaData !== 'undefined' && typeof data.metaData.parentProperties !== 'undefined' && typeof data.metaData.parentProperties.docDiscount !== 'undefined' && data.metaData.parentProperties.docDiscount}</td>

                        </tr>

                    }



                    {
                        typeof data.metaData.parentProperties.ewt !== 'undefined' &&

                        <tr>

                            <td>
                                Withholding Tax
                            </td>

                            {
                                typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailMetaData !== 'undefined' && data.metaData.detailMetaData.map((data, index) => !data.isHidden &&

                                    <td key={uuid()}> </td>
                                )
                            }

                            <td> {data.metaData !== 'undefined' && typeof data.metaData.parentProperties !== 'undefined' && typeof data.metaData.parentProperties.ewt !== 'undefined' && data.metaData.parentProperties.ewt}</td>

                        </tr>

                    }


                    <tr>

                        <td>
                            Net Amount
                        </td>

                        {
                            typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailMetaData !== 'undefined' && data.metaData.detailMetaData.map((data, index) => !data.isHidden &&

                                <td key={uuid()}> </td>
                            )
                        }

                        <td> {typeof typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.parentProperties !== 'undefined' && (computeGrandTotal(data.metaData.detailProperties) - (typeof data.metaData.parentProperties.docDiscount !== 'undefined' ? data.metaData.parentProperties.docDiscount : 0) - (typeof data.metaData.parentProperties.ewt !== 'undefined' ? data.metaData.parentProperties.ewt : 0))}</td>

                    </tr>

                </tfoot>


            </table>


    )


}
