import { faArrowDown, faBuilding, faFile, faFileExcel, faFolderOpen, faListAlt, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import createDetailProperties from '../../Functions/createDetailProperties';
import createParentProperties from '../../Functions/createParentProperties';
import exportToCSV from '../../Functions/exportToCSV';
import getModalContentStyle from '../../Functions/getModalContentStyle';
import getModalStyle from '../../Functions/getModalStyle';
import { toCsvString } from '../../Functions/toCsvString';
import OnHandInventory from '../../Reports/OnHandInventory';
import Drafts from '../Drafts';
import CategorySelection from '../Producing/CategorySelection';
import { TransactionContext } from '../TransactionContext';
import ListOfOpenSR from './ListOfOpenSr';
import ListOfUnAcceptedSt from './ListOfUnAcceptedSt';


export default function CreateStockTransferNavigator(props) {

    const { data, updateMetaData } = useContext(TransactionContext);

    const [thisComponentState, updateState] = useState({
        data: {},
        isPerformingOperation:false,
        reviewPrice: false,
        stockRequestGuidId: "",
        showOnHand: false,
        retrieving: false,
        showDrafts: false,
        showOpenSr: false
        //showUnacceptedSt:false
    });

    const [selectedCategories, updateSelectedCategories] = useState([]);

    const [showCategorySelection, updateShowCategorySelection] = useState(false);

    const pullStockRequest = () => {

       //const guidId = props.previousTransactionGuidId;

        const guidId = JSON.parse(localStorage.getItem("stockRequisitionGuidId"));


        if (guidId === "" || typeof guidId === 'undefined' || guidId === null) {

            return alert("Please move a request.")
        };

        fetch("/StockTransfers/GetRequest?" + new URLSearchParams({

            guidId: guidId

        })).then(response => response.status == 401 ? window.location = "/Login" : response.json())

            .then(dataFromServer => {

                if (dataFromServer.success) {

                    var tempData = { ...data.metaData };

                    tempData.parentProperties = createParentProperties(dataFromServer.parentMetaData);
                    tempData.detailProperties = createDetailProperties(dataFromServer.detailMetaData);
         
                    tempData.parentMetaData = dataFromServer.parentMetaData;
                    tempData.detailMetaData = dataFromServer.detailMetaData[0];

                    //toShowProperty

                    var presentationValue = {

                        toShow: true,
                        categoryId: ""
                    };

                    tempData.detailProperties.forEach(detail => detail.presentationValue = presentationValue);

                    tempData.preceedingTransactionGuids = dataFromServer.preceedingTransactionGuids;

                    updateMetaData({ metaData: tempData });

                    updateState({ ...thisComponentState, stockRequestGuidId: guidId });


                    return;
                }

                return alert(dataFromServer.message);

            })
    }

   
    const draftIt = () => {

        let value = data.metaData.parentProperties;

        value.details = data.metaData.detailProperties;

        let draft = {
            type: 1,
            value: JSON.stringify(value)
        };

        updateState({ ...thisComponentState, isPerformingOperation: true });

        fetch("/Base/SaveDraft", {
            method: "post",
            body: JSON.stringify(draft),
            headers: {
                "content-type": "application/json; charset:UTF-8"
            }

        }).then(response => response.status === 401 ? window.location = "/login" : response.json())
            .then(response => {

                alert(response.message);

                updateState({ ...thisComponentState, isPerformingOperation: false });

            });    
    }



    if (showCategorySelection) {

        return (<div style={getModalStyle(2)}>
            <div style={getModalContentStyle()}>
                <CategorySelection selectedCategories={selectedCategories} updateSelectedCategories={updateSelectedCategories} updateShowCategorySelection={updateShowCategorySelection} />
            </div>
        </div>)
    }



    if (thisComponentState.retrieving) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Retrieving...Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }


    if (thisComponentState.isPerformingOperation) {

        return (<div style={getModalStyle(2)} >
            <div style={getModalContentStyle()}>
                <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                    </button>
                </div>
            </div>
        </div>)

    }



    if (thisComponentState.showDrafts) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>

                    <Drafts type={1} back={() => updateState({...thisComponentState,showDrafts:false})}/>

                </div>
            </div>
        )
    }


    if (thisComponentState.showOpenSr)
    {
        return (
                <div style={getModalStyle()}>
                    <div style={getModalContentStyle()}>
                        <ListOfOpenSR  back={() => updateState({...thisComponentState,showOpenSr:false})} />
                    </div>
                </div>
            )
    }

    //if (thisComponentState.showUnacceptedSt) {

    //    return (
    //        <div style={getModalStyle()}>
    //            <div style={getModalContentStyle()}>
    //                <ListOfUnAcceptedSt back={() => updateState({ ...thisComponentState, showUnacceptedSt: false })} />
    //            </div>
    //        </div>   
    // )}

    if (thisComponentState.showOnHand) {
        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <OnHandInventory
                        back={() => updateState({ ...thisComponentState, showOnHand: false })}
                    />

            
                </div>
            </div>
        )
    }

    const downLoadToExcel = () => {

        if (typeof data !== 'undefined' && typeof data.metaData !== 'undefined' && typeof data.metaData.detailProperties !== 'undefined') {

            var csvString = toCsvString(data.metaData.detailProperties); 

            exportToCSV("data", csvString); 
        };
    }


    return (
    
          <div style={{ float: "left" }}>
         
            <FontAwesomeIcon icon={faPlus} title="New" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="blue" onClick={() => window.location.reload()} />
           
            <FontAwesomeIcon icon={faBuilding} title="On Hand Inventory" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState,showOnHand: true })} />
            <FontAwesomeIcon icon={faArrowDown} title="Pull Request" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={pullStockRequest} />
            <FontAwesomeIcon icon={faFile} title="Save as Draft" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={draftIt} />
            <FontAwesomeIcon icon={faFolderOpen} title="Open list of drafts" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="violet" onClick={() => updateState({ ...thisComponentState, showDrafts: true })} />
            <FontAwesomeIcon icon={faListAlt} title="List of Open Sr" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState, showOpenSr: true })} />
           {/* <FontAwesomeIcon icon={faListAlt} title="List of Unaccepted St" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="yellow" onClick={() => updateState({ ...thisComponentState,showUnacceptedSt: true })} />*/}
            <FontAwesomeIcon icon={faFileExcel} title="Download excel" style={{ marginLeft: "20x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={downLoadToExcel} />

        </div>
            
    )

}

