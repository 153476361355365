import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auto } from '@popperjs/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import uuid from 'react-uuid';
import Details from '../Details';
import convertToDataType from '../Functions/convertToDataType';
import getModalContentStyle from '../Functions/getModalContentStyle';
import getModalStyle from '../Functions/getModalStyle';
import SimpleModal from '../Modal';
import Detail from './Detail';
import Parent from './Parent';
import { TransactionContext } from './TransactionContext';


export default function TransactionForm(props) {

    const { data, updateMetaData } = useContext(TransactionContext);
    const { guidId, updateGuidId } = useContext(TransactionContext);
    const [stillSaving, updateSaving] = useState(false);
    const [goToTransactionList, updateGoToTransactionList] = useState(false);


    const [errors, updateErrors] = useState({
        hasErrors: false,
        showErrors: false,
        errors: []
    });

    function submitForm(e) {

        const tempData = { ...data.metaData };

        let message = "Make sure all your data are correct.Please click ok to continue saving.";

        if (tempData.context.formDiscriminator === "StockRequisition") {

            if (tempData.parentProperties.requestType === "") {

                return alert("Request type is required.");
            }

            message = "Make sure all your data are correct?\nFor cafes requesting raw materials that are purchase daily, please do not forget to prefix note with P_G_RM.\nFor cafes requesting for commisary production, please do not forget to prefix note with M_C_FG.\nMoreover, stockable raw materials or auto part, electro parts must be requested to warehouse directly and separately and with a note of B_R_WH. Please click ok to continue saving."
        };

        var isConfirm = window.confirm(message);

        if (!isConfirm) {

            return;
        }

        updateSaving(true);

        if (data.metaData.detailProperties.length === 0) {

            updateSaving(false);

            return alert("There are no data to save.")
        };


        //Ensure that everything has correct data type
        for (var property in data.metaData.parentProperties) {

            var value = data.metaData.parentProperties[property];

            data.metaData.parentProperties[property] = convertToDataType(data.metaData.parentMetaData, property, value);
        };

        for (var i = 0; i < data.metaData.detailProperties.length; i++) {

            for (var property in data.metaData.detailProperties[i]) {

                var value = data.metaData.detailProperties[i][property];

                data.metaData.detailProperties[i][property] = convertToDataType(data.metaData.detailMetaData, property, value);
            };
        };

        //Remove details that has empty code

        data.metaData.detailProperties = data.metaData.detailProperties.filter(detailProperty => detailProperty.hasOwnProperty("code") ? detailProperty.code !== "" : false);

        if (data.metaData.detailProperties.length === 0) {

            tempData.detailProperties = [];

            updateMetaData({ ...data, metaData: tempData });

            updateSaving(false);

            return alert("There are no data to save.")

        };

        //check uom entry

        data.metaData.detailProperties.forEach(detail => {

            if (detail.hasOwnProperty("uomEntry")) {

                if (detail.uomEntry === "" || typeof detail.uomEntry === 'undefined') {

                    updateSaving(false);

                    return alert("Invalid uom entry. Uom Entry is required.");

                }
            }
        });

        data.metaData.parentProperties.Details = data.metaData.detailProperties;

        var dataToPost = {

            Transaction: data.metaData.parentProperties,
            PreceedingTransactionGuids: data.metaData.preceedingTransactionGuids
        };

        //Validate model

        fetch(data.metaData.validatorUrl, {

            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify(dataToPost),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(response => response.status == 401 ? window.location = "/Login" : response.json())
            .then(json => {


                if (json.length > 0) {

                    updateErrors({ ...errors, hasErrors: true, showErrors: true, errors: json });

                    updateSaving(false);

                    return;

                } else {

                    fetch(data.metaData.postUrl, {

                        // Adding method type
                        method: "POST",

                        // Adding body or contents to send
                        body: JSON.stringify(dataToPost),

                        // Adding headers to the request
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                        .then(response => response.status == 401 ? window.location = "/Login" : response.json())
                        .then(json => {

                            if (json.success) {

                                updateSaving(false);

                                updateGuidId(json.guidId);

                                const updatedData = { ...data.metaData };

                                updatedData.isFormSubmitted = true;

                                updateMetaData({ metaData: updatedData });

                            } else {

                                updateSaving(false);

                                alert(json.message);
                            }

                        });

                }

            });

    }

    if (errors.showErrors) {

        return (
            <div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    {
                        errors.errors.map(error =>

                            <div key={uuid()} className="form-control"> {error} </div>
                        )
                    }
                    <br />
                    <input type="button" value="Ok" style={{ width: "120px" }} className="btn btn-success" onClick={() => updateErrors({ ...errors, showErrors: false })} />
                </div>
            </div>
        )
    }

    if (goToTransactionList) {
        return props.transactionList;
    };


    if (data.metaData.isFormSubmitted) {

        return (
            <Details state=
                {{

                    detailsUrl: data.metaData.detailsUrl,
                    guidId: guidId,
                    createUrl: data.metaData.postUrl,
                    editDetailUrl: data.metaData.editDetailUrl,
                    deleteDetailUrl: data.metaData.deleteDetailUrl,
                    nextTransactionPath: props.nextTransactionPath,
                    nextTransactionGuidId: props.guidId,
                    nextTransactionLabel: props.nextTransactionLabel,
                    listUrl: data.metaData.listUrl
                }}
                detailsNavigator={props.detailsNavigator}
                transactionList={props.transactionList}
            />
        )

    } else {

        if (stillSaving) {
            return (<div style={getModalStyle()}>
                <div style={getModalContentStyle()}>
                    <div style={{ textAlign: "center" }}>
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Processing...
                        </button>
                    </div>
                </div>
            </div>)

        } else {

            return (

                <div>

                    <div>
                        <div className="row">
                            <div className="col">
                                <Parent />
                            </div>
                            <div className="col">

                            </div>
                        </div>

                         

                        <div className="row" >
                            <div className="col">
                                <br />
                                {
                                    props.createNavigator
                                }

                             

                                <FontAwesomeIcon icon={faList} title="List" style={{ marginLeft: "10x", paddingTop: '10px', cursor: 'pointer' }} size="2x" color="green" onClick={() => updateGoToTransactionList(true)} />

                                <br />

                                <hr/>

                                <Detail />

                                <br />
                                <input type="button" id="Save" value="Save" className="btn btn-primary" style={{ width: "120px", marginLeft: "10px" }} onClick={submitForm} />
                                {errors.hasErrors && <input type="button" value="Show Errors" className="btn btn-primary" style={{ width: "120px", marginLeft: "10px" }} onClick={() => updateErrors({ ...errors, showErrors: true })} />}
                            </div>

                        </div>
                    </div>

                </div>




            )
        }
    }
}


