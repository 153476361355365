
import React, { Fragment, useContext, useState } from 'react';


function CreateOrderNavigator() {


    return (

         <div>
            <input className="btn btn-success" style={{ width: "100px", float: "left", marginLeft: "15px" }} value="New"  type="button" />

        </div>

    )

}


export default CreateOrderNavigator;