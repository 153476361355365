
import React, {useEffect, useState } from 'react';
import CreateProductionNavigator from './CreateProductionNavigator';
import setMetaData from '../../Functions/setMetaData';
import TransactionContextProvider from '../TransactionContextProvider';
import TransactionForm from '../TransactionForm';
import DetailsProductionNavigator from './DetailsProductionNavigator';
import TransactionList from '../TransactionList';
import ProductionListNavigator from './ProductionListNavigator';


export default function ProductionForm(props) {

    const transactionCode = "Production";
    const postUrl = "/Productions/Create";
    const detailsUrl = "/Productions/Details/?id=";
    const editDetailUrl = "/Productions/EditItem";
    const listUrl = "/Productions/Index";
    const validatorUrl = "/Productions/Validate";
    const [data, updateMetaData] = useState({ metaData: {} });
    const [isLoaded, updateStatus] = useState(false);

    const [refresh, refreshForm] = useState(false);

    var context = {

        categories: ['132','107','108','109','110','127'],
        type: "ALL"
    };

    useEffect(() => {

        setMetaData(postUrl, updateStatus, updateMetaData);

    },[data.refresh]);

    const transactionList = (<TransactionList state={{ listUrl: listUrl, detailsUrl: detailsUrl, editDetailUrl: editDetailUrl }} listNavigator={<ProductionListNavigator listUrl={listUrl} />} detailsNavigator={<DetailsProductionNavigator refreshForm={refreshForm} />} />);

    if (!isLoaded) {

        return (<div>Loading...</div>);

    } else {

        return (

            <TransactionContextProvider
                validatorUrl={validatorUrl}
                context={context}
                transactionCode={transactionCode}
                metaData={data.metaData}
                postUrl={postUrl}
                detailsUrl={detailsUrl}
                listUrl={listUrl}
                editDetailUrl={editDetailUrl}
                functions={[]}
                transactionList={transactionList}>

                <TransactionForm createNavigator={<CreateProductionNavigator />} detailsNavigator={<DetailsProductionNavigator />} transactionList={transactionList} />

            </TransactionContextProvider>

        )
    };
}

